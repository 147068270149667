import MessageService from 'api/MessageService';
import priceRequestService from 'api/PriceRequestService';
import ProvidePriceDialog from 'components/marketplace/pre-order/dialog/ProvidePriceDialog';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import TradeInfoDialog from 'components/messaging/chat/messagefactory/messageitemdata/TradeDataDialog';
import MessageItemButtonComponent from 'components/messaging/chat/TradeActionButtonsComponent';
import TradeActionButtonsComponent from 'components/messaging/chat/TradeActionButtonsComponent';

import { MessageDirection } from 'model/Message';
import { MessageSentNotification, NotificationInfo } from 'model/NearbuyNotification';
import { now } from 'moment/moment';
import { TFunction } from 'i18next';
import { PriceRequest, PriceRequestStatus } from 'model/PriceRequest';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import { PreOrderTranslationMapping } from 'components/marketplace/PreOrderTranslationMapping';
import Grid from '@mui/material/Grid';
import PreOrderInfoCard from 'components/messaging/chat/messagefactory/infocards/PreOrderInfoCard';
import * as React from 'react';
import { NotificationStore } from 'store';
import { getQueryStringStringified } from 'util/helpers';

/* eslint-disable */
export function addPriceRequestMessageItemData(
    baseData: MessageItemData,
    priceRequest: PriceRequest,
    t: TFunction,
    mapping: PreOrderTranslationMapping,
    onShowDialog: (value: boolean) => void,
    onShowProvidePriceDialog: (value: boolean) => void,
    onShowTradeInfoDialog: (value: boolean) => void,
    isOpen: boolean,
    isProvidePriceDialogOpen: boolean,
    isTradeInfoOpen: boolean
): MessageItemData {
    /* eslint-enable */
    const data: MessageItemData = {
        ...baseData,
        infoBox: {},
    };

    const queryString: Record<string, string> = {};
    queryString['price_request'] = priceRequest.links.self;
    const priceRequestQueryString = '?' + getQueryStringStringified(queryString);
    const contextStatus = baseData.message.contextType.toString().split('__')[1];

    const infoCard = (
        <Grid>
            <PreOrderInfoCard
                withShowOfferOrDetailsButton={true}
                hasBackground={true}
                priceRequestRef={priceRequest.links.self}
                onDecline={onShowDialog}
            />
        </Grid>
    );

    const tradeInfoDialog = (
        <TradeInfoDialog
            key={2}
            isOpen={isTradeInfoOpen}
            title={t('purchaseIntent:details')}
            content={infoCard}
            onClose={onShowTradeInfoDialog}
        />
    );

    const toDetailsButton = (justifyHorizontal: 'start' | 'end') => (
        <TradeActionButtonsComponent
            justifyHorizontal={justifyHorizontal}
            buttons={[
                {
                    text: t('purchaseIntent:toDetails'),
                    type: 'primaryLight',
                    onClick: () => onShowTradeInfoDialog(true),
                    tooltip: t('tooltips:toTradeDetails'),
                },
            ]}
            marginTop={'full'}
        />
    );

    switch (contextStatus) {
        case 'PENDING':
            return addPendingData();
        case 'PRICE_ADDED':
            return addPriceAddedData();
        case 'REJECTED':
            return addRejectedData();
        case 'CANCELED_BY_BUYER':
            return addCanceledByBuyerData();
        case 'CANCELED_BY_SELLER':
            return addCanceledBySellerData();
        default:
            return data;
    }

    function updateStatus(status: PriceRequestStatus): void {
        const isBuyerSender = status == 'CANCELED_BY_BUYER';
        priceRequestService.updateStatus(priceRequest.links.self, { status }).subscribe({
            next: () => {
                NotificationStore.setOne(new NotificationInfo(MessageSentNotification(), now()));
                onShowDialog(false);
                MessageService.invalidateDialogByPreOrder(priceRequest, isBuyerSender);
            },
        });
    }

    function addPendingData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                data.infoBox!.topic = t('priceRequest:priceRequestSellerTitle', mapping);
                data.infoBox!.content = t('priceRequest:priceRequestSellerMessage');
                data.infoBox!.card = infoCard;
                data.dialogs = [
                    <ConfirmationDialog
                        key={baseData.message.links.self + 'confirmation'}
                        isOpen={isOpen}
                        onClose={onShowDialog}
                        title={t('dialogs:rejectPriceRequestTitleSeller', mapping)}
                        content={t('dialogs:rejectPriceRequestContentSeller', mapping)}
                        buttonText1={t('dialogs:rejectPriceRequestButton')}
                        buttonAction1={() => updateStatus(PriceRequestStatus.REJECTED)}
                    />,
                    <ProvidePriceDialog
                        key={baseData.message.links.self + 'providePrice'}
                        priceRequestRef={priceRequest.links.self}
                        infoCard={infoCard}
                        onClose={onShowProvidePriceDialog}
                        isOpen={isProvidePriceDialogOpen}
                        mapping={mapping}
                    />,
                ];
                data.infoBox!.buttons =
                    priceRequest.status == 'PENDING' ? (
                        <MessageItemButtonComponent
                            justifyHorizontal={'start'}
                            buttons={[
                                {
                                    text: t('priceRequest:reject'),
                                    type: 'transparent',
                                    tooltip: t('priceRequest:rejectNoticeSeller'),
                                    onClick: () => {
                                        onShowDialog(true);
                                    },
                                },
                                {
                                    onClick: () => {
                                        onShowProvidePriceDialog(true);
                                    },
                                    text: t('priceRequest:addPriceRequestSeller'),
                                    type: 'primaryLight',
                                },
                            ]}
                            marginTop={'full'}
                        />
                    ) : undefined;
                break;
            case MessageDirection.OUTBOUND:
                if (baseData.originalReceiver) {
                    mapping.firstNameSeller = baseData.originalReceiver.firstname;
                    mapping.lastNameSeller = baseData.originalReceiver.lastname;
                }
                data.infoBox!.topic = t('priceRequest:priceRequestBuyerTitle', mapping);
                data.infoBox!.content = t('priceRequest:priceRequestBuyerMessage', mapping);
                data.infoBox!.card = infoCard;
                break;
        }
        return data;
    }

    function addPriceAddedData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.OUTBOUND:
                data.infoBox!.card = infoCard;
                data.infoBox!.topic = t('priceRequest:priceAddedTitle', mapping);
                data.infoBox!.content = t('priceRequest:priceAddedSellerMessage', mapping);
                break;
            case MessageDirection.INBOUND:
                data.infoBox!.card = infoCard;
                data.dialogs = [
                    <ConfirmationDialog
                        key={baseData.message.links.self}
                        isOpen={isOpen}
                        onClose={onShowDialog}
                        title={t('dialogs:rejectPriceRequestTitleBuyer', mapping)}
                        content={t('dialogs:rejectPriceRequestContentBuyer')}
                        buttonText1={t('dialogs:rejectPriceProposalButton')}
                        buttonAction1={() => updateStatus(PriceRequestStatus.CANCELED_BY_BUYER)}
                    />,
                ];
                data.infoBox!.buttons =
                    priceRequest.status == 'PRICE_ADDED' ? (
                        <MessageItemButtonComponent
                            justifyHorizontal={'start'}
                            buttons={[
                                {
                                    text: t('priceRequest:reject'),
                                    type: 'transparent',
                                    tooltip: t('priceRequest:cancelNoticeBuyer'),
                                    onClick: () => {
                                        onShowDialog(true);
                                    },
                                },
                                {
                                    link: `/order${priceRequestQueryString}`,
                                    text: t('priceRequest:acceptAndBuy'),
                                    type: 'secondaryMain',
                                },
                            ]}
                            marginTop={'full'}
                        />
                    ) : undefined;
                data.infoBox!.topic = t('priceRequest:priceAddedTitle', mapping);
                data.infoBox!.content = t('priceRequest:priceAddedBuyerMessage', mapping);

                break;
        }
        return data;
    }

    function addRejectedData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                data.infoBox!.topic = t('priceRequest:rejectedBuyerTitle', mapping);
                data.infoBox!.content = t('priceRequest:rejectedBuyerMessage', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('start');
                break;
            case MessageDirection.OUTBOUND:
                data.infoBox!.topic = t('priceRequest:rejectedSellerTitle', mapping);
                data.infoBox!.content = t('priceRequest:rejectedSellerMessage', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('end');
                break;
        }
        return data;
    }

    function addCanceledByBuyerData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                data.infoBox!.topic = t('priceRequest:canceledByBuyerSellerTitle', mapping);
                data.infoBox!.content = t('priceRequest:canceledByBuyerSellerMessage', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('start');
                break;
            case MessageDirection.OUTBOUND:
                data.infoBox!.topic = t('priceRequest:canceledByBuyerBuyerTitle', mapping);
                data.infoBox!.content = t('priceRequest:canceledByBuyerBuyerMessage', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('end');
                break;
        }
        return data;
    }

    function addCanceledBySellerData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                data.infoBox!.topic = t('priceRequest:canceledBySellerBuyerTitle', mapping);
                data.infoBox!.content = t('priceRequest:canceledBySellerBuyerMessage', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('start');
                break;
            case MessageDirection.OUTBOUND:
                data.infoBox!.topic = t('priceRequest:canceledBySellerSellerTitle', mapping);
                data.infoBox!.content = t('priceRequest:canceledBySellerSellerMessage', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('start');
                break;
        }
        return data;
    }
}
