import { PurchaseIntent } from 'model/PurchaseIntent';
import { Order } from 'model/Order';
import { PriceRequest } from 'model/PriceRequest';
import { Moment } from 'moment/moment';

export enum TradeType {
    PURCHASE_REQUESTS = 'PURCHASE_REQUESTS',
    SALES_REQUESTS = 'SALES_REQUESTS',
    PURCHASES = 'PURCHASES',
    SALES = 'SALES',
}

export enum TradeTableTab {
    ACTIVE = 'ACTIVE',
    TERMINATED = 'TERMINATED',
}

export interface TradeTableFilter {
    tradeType: TradeType;
    dateFrom: Moment;
    dateUntil: Moment;
    tab: TradeTableTab;
}

export interface TradeTableItems {
    itemsToShow: (PurchaseIntent | PriceRequest | Order)[];
    purchaseIntents: readonly PurchaseIntent[];
    priceRequests: readonly PriceRequest[];
    orders: readonly Order[];
}
