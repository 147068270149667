import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import NotificationService from 'api/NotificationService';
import UserInitializer from 'components/initialization/initializers/UserInitializer';

export class NotificationListenerInitializer implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        return NotificationService.startAcceptingNotifications();
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new NotificationListenerInitializer(),
    [UserInitializer],
);
