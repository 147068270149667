import React, { PropsWithChildren } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import SmallScreenDialog from 'components/mobile/SmallScreenDialog';
import { InitializationPhase } from 'components/initialization';
import InitialisationAssurance from 'components/initialization/InitialisationAssurance';

const SmallScreenGuard: React.FunctionComponent<PropsWithChildren<unknown>> = (
    props: PropsWithChildren<unknown>,
): React.ReactElement | null => {
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down('md'),
        //  nossr needed, or else both would be rendered and  only the correct shown,
        // but we only want to load the correct one
        { noSsr: true },
    );
    if (!isMobile) return <>{props.children}</>;
    return (
        <InitialisationAssurance oneOf={[InitializationPhase.LOADED]}>
            <SmallScreenDialog>{props.children}</SmallScreenDialog>
        </InitialisationAssurance>
    );
};

export default SmallScreenGuard;
