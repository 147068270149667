import { ComponentBase } from 'resub';
import { Address } from 'model/Address';
import { AddressStore } from 'store';
import Typography from '@mui/material/Typography';
import * as React from 'react';

class CompanyPopupAdressContent extends ComponentBase<{ address: string }, { address: Address }> {
    protected _buildState(
        props: { address: string },
        initialBuild: boolean,
        incomingState: Readonly<{ address: Address }> | undefined,
    ): Partial<{ address: Address }> | undefined {
        return {
            address: AddressStore.getOne(props.address),
        };
    }

    render(): React.ReactNode {
        if (!this.state.address) return null;
        return (
            <>
                <Typography
                    variant={'subtitle1'}
                    sx={{ maxWidth: '96%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {this.state.address.street}
                </Typography>
                <Typography
                    variant={'subtitle1'}
                    sx={{ maxWidth: '96%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {this.state.address.zipcode} {this.state.address.city}
                </Typography>
            </>
        );
    }
}
export default CompanyPopupAdressContent;
