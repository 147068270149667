import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { getButtonVariant } from 'shared/buttons';
import { theme } from 'style/NearbuyStyle';
import { wrapButtonWithTooltip } from 'util/style-helpers';

function SplitButtonComponent(props: {
    options: string[];
    actions: (() => void)[];
    optionIcons?: Map<number, React.ReactElement>;
    toolTips?: Map<number, string>;
    buttonDefaultText?: string;
    buttonType?: 'transparent' | 'primaryLight' | 'secondaryMain';
    justifyHorizontal?: 'start' | 'center' | 'end';
    sxPropsButtonGroup?: SxProps<Theme>;
    disabled?: boolean;
}) {
    if (props.options.length != props.actions.length) {
        console.error('[SplitButton]: Number of actions must fit number of options!');
        return null;
    }
    /* Hooks */
    const anchorRef = React.useRef<HTMLDivElement>(null);

    /* States and Store Subscribed Constants */
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(props.buttonDefaultText ? undefined : 0);

    /* Effects */
    /* Styles und StyledComponents */

    /* Handler */
    const handleClick = () => {
        if (props.buttonDefaultText) {
            if (selectedIndex !== undefined) {
                props.actions[selectedIndex]();
            } else {
                handleToggle();
            }
        } else {
            props.actions[selectedIndex!]();
        }
    };
    const handleMenuItemClick = (index: number) => {
        setSelectedIndex(index);
        setIsOpen(false);
    };
    const handleToggle = () => {
        setIsOpen((isOpen: boolean) => !isOpen);
    };
    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setIsOpen(false);
    };

    /* Util Functions */
    const getIcon = (): React.ReactElement | undefined => {
        return selectedIndex !== undefined ? props.optionIcons?.get(selectedIndex) : undefined;
    };

    /* Styles und StyledComponents */

    /* JSX */
    const getLeftButton = () => {
        const button = (
            <Button
                disabled={props.disabled}
                variant={getButtonVariant(props.buttonType)}
                onClick={handleClick}
                startIcon={getIcon()}
                data-testid={'split-button-left'}
            >
                {props.buttonDefaultText
                    ? selectedIndex !== undefined
                        ? props.options[selectedIndex]
                        : props.buttonDefaultText
                    : props.options[selectedIndex!]}
            </Button>
        );
        const toolTip = selectedIndex != undefined ? props.toolTips?.get(selectedIndex) : undefined;
        if (toolTip) {
            return wrapButtonWithTooltip(button, toolTip);
        } else {
            return button;
        }
    };

    const buttonGroup = (
        <ButtonGroup data-testid={'split-button'} ref={anchorRef}>
            {getLeftButton()}
            <Button
                disabled={props.disabled}
                variant={'contained'}
                size="small"
                onClick={handleToggle}
                data-testid={'split-button-right'}
                sx={{ marginRight: theme.spacing(1) }}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
    );

    const menuList = (
        <MenuList autoFocusItem>
            {props.options.map((option: string, index: number) => (
                <MenuItem
                    sx={{
                        ':hover': {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.primary.light,
                        },
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.primary.lighter,
                            ':hover': {
                                backgroundColor: theme.palette.primary.light,
                            },
                        },
                    }}
                    key={option}
                    selected={index === selectedIndex}
                    onClick={() => handleMenuItemClick(index)}
                >
                    {option}
                </MenuItem>
            ))}
        </MenuList>
    );

    const popper = (
        <Popper
            sx={{
                zIndex: 1,
            }}
            open={isOpen}
            anchorEl={anchorRef.current}
        >
            <Paper>
                <ClickAwayListener onClickAway={handleClose}>{menuList}</ClickAwayListener>
            </Paper>
        </Popper>
    );

    return (
        <>
            {buttonGroup}
            {popper}
        </>
    );
}
export default SplitButtonComponent;
