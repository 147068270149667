import * as React from 'react';
import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { NotificationInfo } from 'model/NearbuyNotification';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { theme } from 'style/NearbuyStyle';
import CompanyStore from 'store/CompanyStore';
import { getUuidFromString } from 'util/helpers';

const _styles = (theme: Theme) =>
    createStyles({
        infoLink: {
            textDecoration: 'none',
        },
        bold: {
            fontWeight: 'bold',
            color: theme.palette.primary.lighter,
        },
    });

interface OrderSnackbarActionProps extends WithStyles<typeof _styles>, RouteComponentProps, WithTranslation {
    info: NotificationInfo;
    isSeller: boolean;
    isOrderCompleted: boolean;
    handleClose: () => void;
}

interface OrderSnackbarActionState {
    currentCompanyId?: string;
}

class OrderSnackbarAction extends ComponentBase<OrderSnackbarActionProps, OrderSnackbarActionState> {
    protected _buildState(): OrderSnackbarActionState {
        return {
            currentCompanyId: getUuidFromString(CompanyStore.getSelected()?.links.self),
        };
    }

    navigateToOrders(isCompletedTab: boolean, isSeller: boolean): void {
        this.props.history.replace(
            `/marketplace/${this.state.currentCompanyId}/tradeTable/${
                isSeller ? 'sales' : 'purchases'
            }?completed=${isCompletedTab}`,
        );
    }

    render(): React.ReactElement {
        return (
            <div>
                <Button
                    onClick={() => {
                        this.navigateToOrders(this.props.isOrderCompleted, this.props.isSeller);
                    }}
                >
                    <Typography className={this.props.classes.bold}>{this.props.t('dashboard:show')}</Typography>
                </Button>
                <IconButton
                    sx={{
                        '&:hover': {
                            color: theme.palette.common.black,
                            backgroundColor: 'transparent',
                        },
                    }}
                    size="small"
                    aria-label="close"
                    onClick={() => {
                        this.props.handleClose();
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }
}

export default withRouter(
    withTranslation(['notification'])(withStyles(_styles, { withTheme: true })(OrderSnackbarAction)),
);
