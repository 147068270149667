import { SystemMessage } from 'model/SystemMessage';
import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';

@AutoSubscribeStore
class SystemMessageSnackbarStoreClass extends StoreBase {
    protected systemMessages: SystemMessage[] = [];

    @autoSubscribe
    getAll(): SystemMessage[] {
        return this.systemMessages;
    }

    addAll(systemMessages: SystemMessage[]): void {
        this.systemMessages = this.systemMessages.concat(systemMessages);
        this.trigger();
    }

    add(systemMessage: SystemMessage) {
        this.addAll([systemMessage]);
    }

    remove(systemMessage: SystemMessage) {
        this.systemMessages = this.systemMessages.filter((sm) => sm !== systemMessage);
        this.trigger();
    }

    removeByRef(ref: string) {
        const smToRemove = this.systemMessages.filter((sm) => sm.links.self == ref).pop();
        if (smToRemove) this.remove(smToRemove);
    }

    clear() {
        this.systemMessages = [];
        this.trigger();
    }
}

export const SystemMessageSnackbarStore = new SystemMessageSnackbarStoreClass();
export default SystemMessageSnackbarStore;
