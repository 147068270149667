import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material';
import { ComponentBase } from 'resub';
import StarIcon from '@mui/icons-material/Star';
import PersonService from 'api/PersonService';
import { PersonFavouritesStore, PersonStore } from 'store';
import { classNames } from 'util/style-helpers';
import { PersonFavourites } from 'model/Person';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        infoButton: {
            marginRight: theme.spacing(1),
            minWidth: 10,
        },
        infoButtonFav: {
            backgroundColor: theme.palette.primary.dark,
            marginRight: theme.spacing(1),
            minWidth: 10,
        },
        icon: {
            margin: theme.spacing(0.5),
        },
        iconSmall: {
            width: 24,
            height: 24,
        },
        slimButton: {
            padding: 0,
            margin: 0,
            textTransform: 'none',
            justifyContent: 'left',
        },
        iconFavourite: {
            width: 24,
            height: 24,
            color: theme.palette.secondary.main,
        },
    });

interface StarComponentProps extends WithStyles<typeof _styles> {
    companyRef: string;
    personRef?: string;
    calledFrom: string;
}

interface StarComponentState {
    personCompanies?: PersonFavourites;
}

class StarComponent extends ComponentBase<StarComponentProps, StarComponentState> {
    protected _buildState(): Partial<StarComponentState> | undefined {
        const person = PersonStore.getSelected();
        if (!this.props.personRef) {
            if (person?.links.self) {
                return {
                    personCompanies: PersonFavouritesStore.getOne(person.links.self),
                };
            }
        } else
            return {
                personCompanies: PersonFavouritesStore.getOne(this.props.personRef),
            };
    }

    starCompany(): void {
        if (this.state.personCompanies?.personRef) {
            PersonService.setFavouriteCompany(this.state.personCompanies?.personRef, this.props.companyRef).subscribe();
        }
    }

    unstarCompany(): void {
        if (this.state.personCompanies?.personRef) {
            PersonService.deleteFavouriteCompany(
                this.state.personCompanies?.personRef,
                this.props.companyRef,
            ).subscribe();
        }
    }

    render(): React.ReactElement {
        return (
            <>
                <IconButton
                    className={
                        this.state.personCompanies?.companyRefs.includes(this.props.companyRef)
                            ? classNames(this.props.classes.infoButtonFav)
                            : classNames(this.props.classes.infoButton)
                    }
                    onClick={
                        this.state.personCompanies?.companyRefs.includes(this.props.companyRef)
                            ? (): void => {
                                  captureWebEvent('unstar-company-button', {
                                      unstarFrom: this.props.calledFrom,
                                  });
                                  this.unstarCompany();
                              }
                            : (): void => {
                                  captureWebEvent('star-company-button', {
                                      starFrom: this.props.calledFrom,
                                  });
                                  this.starCompany();
                              }
                    }
                    size={'small'}
                >
                    <StarIcon
                        className={
                            this.state.personCompanies?.companyRefs.includes(this.props.companyRef)
                                ? classNames(this.props.classes.icon, this.props.classes.iconFavourite)
                                : classNames(this.props.classes.icon, this.props.classes.iconSmall)
                        }
                    />
                </IconButton>
            </>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(StarComponent);
