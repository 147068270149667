import { ResultFormat, ResultItem } from 'model/ResultItem';
import http from 'api/http';
import { Company, CompanyWriteView } from 'model/Company';
import { catchError, map, tap } from 'rxjs/operators';
import { AxiosResponse } from 'axios';
import { CompanyRolesStore, CompanyStore, PersonEmploymentStore } from 'store';
import { lastValueFrom, Observable, of } from 'rxjs';
import { Employment } from 'model/Employment';
import { CompanyRoles, NearbuyRole } from 'model/NearbuyRole';
import qs from 'qs';

export interface CompanySearchQuery {
    format?: ResultFormat;
    name?: string;
    limit?: number;
    offset?: number;
    lat1?: number;
    lon1?: number;
    lat2?: number;
    lon2?: number;
    role?: NearbuyRole[];
    category?: string[];
    showOwnData?: boolean;
    showOnlyFavourites?: boolean;
    certifiedOrganic?: boolean;
    transitioning?: boolean;
}

class CompanyService {
    /**
     * @deprecated use Observable version instead
     * @param companyName
     */
    searchWithPromise(companyName: string): Promise<Company[]> {
        return lastValueFrom(this.searchCompaniesAsViews({ name: companyName }));
    }

    searchCompaniesAsViews(query: CompanySearchQuery): Observable<Company[]> {
        return http
            .getAxios()
            .get<Company[]>(
                `companies?${qs.stringify(
                    { ...query, format: ResultFormat.VIEW },
                    { arrayFormat: 'repeat', encode: true },
                )}`,
            )
            .pipe(map((value: AxiosResponse<Company[]>) => value.data));
    }

    searchCompaniesAsRefs(query: CompanySearchQuery): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>(
                `companies?${qs.stringify(
                    { ...query, format: ResultFormat.REFERENCE },
                    { arrayFormat: 'repeat', encode: true },
                )}`,
            )
            .pipe(map((value: AxiosResponse<string[]>) => value.data));
    }

    searchCompaniesAsResults(query: CompanySearchQuery): Observable<ResultItem[]> {
        return http
            .getAxios()
            .get<ResultItem[]>(
                `companies?${qs.stringify(
                    { ...query, format: ResultFormat.SEARCH_RESULT },
                    { arrayFormat: 'repeat', encode: true },
                )}`,
            )
            .pipe(map((value: AxiosResponse<ResultItem[]>) => value.data));
    }

    createAndSelectCompany(companyWriteView: CompanyWriteView): Observable<Company> {
        return http
            .getAxios()
            .post<Company>('companies', companyWriteView)
            .pipe(
                map((response) => response.data),
                tap((company) => CompanyStore.setOne(company)),
                tap((company) => CompanyStore.setSelected(company.links.self)),
                tap(() => {
                    const userId = PersonEmploymentStore.getSelected()?.userRef;
                    if (userId) {
                        PersonEmploymentStore.loadOne(userId);
                    }
                }),
            );
    }

    createCompany(companyWriteView: CompanyWriteView): Observable<Company> {
        return http
            .getAxios()
            .post<Company>('companies', companyWriteView)
            .pipe(map((response) => response.data));
    }

    apply(companySelfString: string, personIdString: string): Observable<Employment> {
        return http
            .getAxios()
            .post<Employment>(`${companySelfString}/employees`, {
                self: personIdString,
            })
            .pipe(map((response) => response.data));
    }

    getCompany(companyRef: string): Observable<Company> {
        return http
            .getAxios()
            .get<Company>(`${companyRef}`)
            .pipe(map((value) => value.data));
    }

    updateCompany(company: Company): Observable<boolean> {
        if (company.links.self) {
            return http
                .getAxios()
                .put<Company>(company.links.self, company, { headers: { 'Current-Company': company.id } })
                .pipe(
                    tap((value) => (value.data ? CompanyStore.setOne(value.data) : true)),
                    map((response) => response.status < 400),
                    catchError(() => of(false)),
                );
        } else {
            throw new Error('company has no reference..');
        }
    }

    deleteCompany(companyRef: string): Observable<Company> {
        return http
            .getAxios()
            .delete<Company>(companyRef)
            .pipe(map((value) => value.data));
    }

    getRolesOfCompany(companyRef: string): Observable<CompanyRoles> {
        return http
            .getAxios()
            .get<NearbuyRole[]>(companyRef + '/roles')
            .pipe(
                map((response) => response.data),
                map((roles) => new CompanyRoles(companyRef, roles)),
            );
    }

    updateRoles(companyRoles: CompanyRoles): Observable<CompanyRoles> {
        return http
            .getAxios()
            .put<NearbuyRole[]>(companyRoles.companyRef + '/roles', companyRoles.roles)
            .pipe(
                map((response) => response.data),
                map((roles) => new CompanyRoles(companyRoles.companyRef, roles)),
                tap((roles) => CompanyRolesStore.setOne(roles)),
            );
    }

    setVerified(companyRef: string, verified: boolean): Observable<Company> {
        return http
            .getAxios()
            .put<Company>(`${companyRef}/verified`, { verified: verified })
            .pipe(
                map((response) => response.data),
                tap((company: Company) => {
                    CompanyStore.setOne(company);
                }),
            );
    }
}

export default new CompanyService();
