import UserSettingsService from 'api/UserSettingsService';
import { default as Leaflet, LatLngBoundsLiteral } from 'leaflet';
import { areArraysEqual, getProductNameFromString, getQueryStringArray, getQueryStringStringified } from 'util/helpers';
import moment, { Moment } from 'moment';
import { Feature } from 'model/Feature';
import { MinMaxAmount } from 'model/MinMaxAmount';
import { NearbuyRole } from 'model/NearbuyRole';
import { ResultType } from 'model/ResultItem';
import { SavedSearchFilters } from 'model/Search';
import { SearchArea } from 'model/SearchArea';
import { SearchFilter } from 'model/SearchFilter';
import { SettingsKey } from 'model/UserSettings';
import qs, { ParsedQs } from 'qs';
import { AutoSubscribeStore, autoSubscribeWithKey, StoreBase } from 'resub';
import { lastValueFrom } from 'rxjs';
import FeatureStore from 'store/FeatureStore';
import { getBoundsByDistanceFromHomeAddress } from 'util/geo-helpers';
import { AddressStore, CompanyRolesStore, CompanyStore, ProductStore, UserSettingsStore, UserStore } from 'store/index';

const triggerAll = 'TRIGGER_ALL';
const amountTrigger = 'TRIGGER_AMOUNT';
const areaTrigger = 'TRIGGER_AREA';
const boundsTrigger = 'TRIGGER_BOUNDS';
const categoryTrigger = 'TRIGGER_CATEGORY';
const dateFromTrigger = 'TRIGGER_FROM_DATE';
const dateUntilTrigger = 'TRIGGER_UNTIL_DATE';
const showOnlyOffers = 'TRIGGER_SHOW_ONLY_OFFERS';
const showOnlyRequests = 'TRIGGER_SHOW_ONLY_REQUESTS';
const showOnlyFavouritesTrigger = 'TRIGGER_SHOW_ONLY_FAVOURITES';
const showOwnDataTrigger = 'TRIGGER_SHOW_OWN_DATA';
const nameTrigger = 'TRIGGER_NAME';
const companyNameTrigger = 'TRIGGER_COMPANY_NAME';
const companyRefTrigger = 'TRIGGER_COMPANY_REF';
const resultTypeTrigger = 'TRIGGER_RESULT_TYPE';
const roleTrigger = 'TRIGGER_ROLE';
const searchFilterTrigger = 'TRIGGER_SEARCH_FILTER';
const triggerIsDoneParsing = 'TRIGGER_IS_DONE_PARSING';
const triggerIsOnSearch = 'TRIGGER_IS_ON_SEARCH';
const showIsOrganicTrigger = 'TRIGGER_IS_ORGANIC';
const showIsInTransitionTrigger = 'TRIGGER_IS_IN_TRANSITION';

const amountPrefix = 'amount';
const categoryPrefix = 'category';
const dateFromPrefix = 'after';
const dateUntilPrefix = 'before';
const showOnlyFavouritesPrefix = 'showOnlyFavourites';
const lat1Prefix = 'lat1';
const lat2Prefix = 'lat2';
const lon1Prefix = 'lon1';
const lon2Prefix = 'lon2';
const namePrefix = 'name';
const marketCompanyNamePrefix = 'marketCompanyName';
const marketCompaniesPrefix = 'marketCompanies';
const resultTypePrefix = 'type';
const rolePrefix = 'role';
const showOwnDataPrefix = 'showOwnData';
const certifiedOrganicPrefix = 'certifiedOrganic';
const transitioningPrefix = 'transitioning';

interface GetQueryParamsOptions {
    getForApiCall?: boolean;
}

@AutoSubscribeStore
export class SearchFilterStore extends StoreBase {
    private _currentlyOnSearch = false;
    private roles: NearbuyRole[] = new Array<NearbuyRole>();
    private categories: string[] = new Array<string>();
    private name = '';
    private companyName = '';
    private companies: string[] = new Array<string>();
    private bounds: Leaflet.LatLngBoundsLiteral | undefined;
    private dateFrom: Moment | undefined;
    private dateUntil: Moment | undefined;
    private resultTypes: ResultType[] = [];
    private amount: MinMaxAmount | undefined;
    private searchArea: SearchArea = SearchArea.COMPANIES;
    private activeFilters: SearchFilter[] = [];
    private showOffers = true;
    private showRequests = true;
    private showOnlyFavourites = false;
    private showOwnData = false;
    private _doneParsing = true;
    private queryParams = '';
    private isOrganic = false;
    private isInTransition = false;
    private _shouldKeepCategories = false;

    static calculateRoles(roles: NearbuyRole[] | undefined): NearbuyRole[] {
        if (!roles) {
            return [];
        }
        const result: NearbuyRole[] = new Array<NearbuyRole>();
        if (roles.includes(NearbuyRole.SUPPLIER)) {
            result.push(NearbuyRole.CONSUMER);
        }
        if (roles.includes(NearbuyRole.CONSUMER)) {
            result.push(NearbuyRole.SUPPLIER);
        }
        return result;
    }

    hasMarketplacePermission(): boolean {
        return FeatureStore.hasPermission(new Set([Feature.SEARCH_OFFERS_AND_REQUESTS]), 'oneOf') || false;
    }

    @autoSubscribeWithKey(triggerIsOnSearch)
    public isCurrentlyOnSearch(): boolean {
        return this._currentlyOnSearch;
    }

    setCurrentlyOnSearch(value: boolean): void {
        this._currentlyOnSearch = value;
    }

    @autoSubscribeWithKey(triggerIsDoneParsing)
    public isDoneParsing(): boolean {
        return this._doneParsing;
    }

    setDoneParsing(value: boolean): void {
        this._doneParsing = value;
    }

    getProductsForLabel(label: string): string[] {
        return ProductStore.search(label).map((product) => product.links.self);
    }

    @autoSubscribeWithKey(triggerAll)
    getQueryParams(options?: GetQueryParamsOptions): string {
        const params: Record<string, any> = {};
        if (this.activeFilters.includes(SearchFilter.ROLES) && this.roles.length > 0) {
            params[rolePrefix] = this.roles;
        }
        if (this.activeFilters.includes(SearchFilter.NAME) && this.name !== '') {
            params[namePrefix] = this.name;
            // add products that match the label
            if (options && options.getForApiCall && this.name.length > 2) {
                params[categoryPrefix] = this.getProductsForLabel(this.name).map((category) =>
                    getProductNameFromString(category),
                );
            }
        }
        if (this.categories.length > 0) {
            if (params[categoryPrefix] !== undefined) {
                params[categoryPrefix].push(this.categories.map((category) => getProductNameFromString(category)));
            } else {
                params[categoryPrefix] = this.categories.map((category) => getProductNameFromString(category));
            }
        }
        if (this.activeFilters.includes(SearchFilter.RESULT_TYPE) && this.getResultTypes().length > 0) {
            params[resultTypePrefix] = this.getResultTypes();
        }
        if (this.activeFilters.includes(SearchFilter.FROM_DATE) && this.dateFrom) {
            params[dateFromPrefix] = this.dateFrom.format('YYYY-MM-DD');
        }
        if (this.activeFilters.includes(SearchFilter.UNTIL_DATE) && this.dateUntil) {
            params[dateUntilPrefix] = this.dateUntil.format('YYYY-MM-DD');
        }
        if (this.activeFilters.includes(SearchFilter.AMOUNT) && this.amount) {
            params[amountPrefix] = this.amount;
        }
        if (this.activeFilters.includes(SearchFilter.SHOW_ONLY_FAVOURITES) && this.showOnlyFavourites) {
            params[showOnlyFavouritesPrefix] = this.showOnlyFavourites;
        }
        if (this.bounds) {
            params[lat1Prefix] = this.bounds[0][0];
            params[lon1Prefix] = this.bounds[0][1];
            params[lat2Prefix] = this.bounds[1][0];
            params[lon2Prefix] = this.bounds[1][1];
        }
        if (this.activeFilters.includes(SearchFilter.SHOW_OWN_DATA) && this.showOwnData) {
            params[showOwnDataPrefix] = true;
        }

        if (this.activeFilters.includes(SearchFilter.ORGANIC) && this.isOrganic) {
            params[certifiedOrganicPrefix] = true;
        }

        if (this.activeFilters.includes(SearchFilter.ORGANIC) && this.isInTransition) {
            params[transitioningPrefix] = true;
        }

        if ((this.activeFilters.includes(SearchFilter.COMPANIES) && this.companies) || this.companyName) {
            if (this.companyName) params[marketCompanyNamePrefix] = this.companyName;
            if (this.companies) params[marketCompaniesPrefix] = this.companies;
        }
        return '?' + getQueryStringStringified(params);
    }

    parseQueryParams(queryParams: string): void {
        if (this.queryParams === queryParams) {
            this.setDoneParsing(true);
            return;
        }
        this.queryParams = queryParams;

        let params = qs.parse(queryParams);
        if (params && (params['category'] as string)?.includes('#')) {
            const encodedCategory = encodeURIComponent(params['category'] as string);
            params = {
                ...params,
                category: encodedCategory,
            };
        }

        const roles = getQueryStringArray(params[rolePrefix], NearbuyRole) || new Array<NearbuyRole>();

        const resultTypes = getQueryStringArray(params[resultTypePrefix], ResultType) || new Array<ResultType>();

        const categoryRefs = new Array<string>();
        const categoriesParam = params[categoryPrefix];
        if (categoriesParam && Array.isArray(categoriesParam)) {
            categoriesParam.forEach((category: string | ParsedQs) => {
                if (typeof category === 'string') {
                    categoryRefs.push(category);
                }
            });
        } else if (categoriesParam && typeof categoriesParam === 'string') {
            categoryRefs.push(categoriesParam);
        }

        const name = params[namePrefix] || '';
        const marketCompanyName = params[marketCompanyNamePrefix] || '';
        const marketCompanyRefs = new Array<string>();
        const dateFrom = params[dateFromPrefix] || '';
        const dateUntil = params[dateUntilPrefix] || '';
        const amount: MinMaxAmount | undefined = params[amountPrefix] as unknown as MinMaxAmount;
        const area: SearchArea = this.getSearchArea();
        const lat1: number | undefined = Number(params[lat1Prefix]);
        const lon1: number | undefined = Number(params[lon1Prefix]);
        const lat2: number | undefined = Number(params[lat2Prefix]);
        const lon2: number | undefined = Number(params[lon2Prefix]);
        const showOnlyFavourites = params[showOnlyFavouritesPrefix];
        const showOwnData = params[showOwnDataPrefix];
        const certifiedOrganic = params[certifiedOrganicPrefix];
        const transitioning = params[transitioningPrefix];

        let bounds: LatLngBoundsLiteral | undefined = [lat1, lon1, lat2, lon2].every((x) => !!x)
            ? ([
                  [lat1, lon1],
                  [lat2, lon2],
              ] as LatLngBoundsLiteral)
            : undefined;

        if (!bounds) {
            const address = AddressStore.getSelected();
            if (address) {
                bounds = getBoundsByDistanceFromHomeAddress(20, address);
            }
        }

        StoreBase.pushTriggerBlock();
        this.setRoles(roles);
        this.setResultTypes(resultTypes);
        this.setCategories(categoryRefs);
        this.setName(typeof name === 'string' ? name : '');
        this.setMarketCompanyName(typeof marketCompanyName === 'string' ? marketCompanyName : '');
        this.setMarketCompanies(marketCompanyRefs);
        this.setDateFrom(dateFrom && typeof dateFrom === 'string' ? moment(dateFrom) : undefined);
        this.setDateUntil(dateUntil && typeof dateUntil === 'string' ? moment(dateUntil) : undefined);
        this.setAmount(amount);
        this.setSearchArea(area);
        this.setBounds(bounds);
        this.setFavourites(typeof showOnlyFavourites === 'string' ? Boolean(showOnlyFavourites) : false);
        this.setShowOwnData(typeof showOwnData === 'string' ? Boolean(showOwnData) : false);
        this.setIsOrganic(typeof certifiedOrganic === 'string' ? Boolean(certifiedOrganic) : false);
        this.setIsInTransition(typeof transitioning === 'string' ? Boolean(transitioning) : false);
        this.setDoneParsing(true);
        StoreBase.popTriggerBlock();
    }

    @autoSubscribeWithKey(resultTypeTrigger)
    getResultTypes(): ResultType[] {
        if (!this.showRequests) {
            return [ResultType.OFFER];
        } else if (!this.showOffers) {
            return [ResultType.REQUEST];
        } else if (this.hasMarketplacePermission()) {
            return this.resultTypes;
        } else {
            return [ResultType.COMPANY];
        }
    }

    setResultTypes(rt: ResultType[]): void {
        if (areArraysEqual(this.resultTypes, rt)) {
            return;
        }
        this.resultTypes = rt;

        if (!(rt.indexOf(ResultType.COMPANY) >= 0)) {
            // reset role filters
            this.setRoles([]);
        } else {
            this.setDefaultRoles();
        }
        this.trigger(resultTypeTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(roleTrigger)
    getRoles(): NearbuyRole[] {
        return this.roles;
    }

    setRoles(roles: NearbuyRole[]): void {
        if (areArraysEqual(this.roles, roles)) {
            return;
        }
        this.roles = roles;

        // persist new saved roles
        this.persistNewSearchSetting('roles', roles);

        this.trigger(roleTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(categoryTrigger)
    getCategories(): string[] {
        return this.categories;
    }

    setCategories(categories: string[]): void {
        if (areArraysEqual(this.categories, categories)) {
            return;
        }
        this.categories = categories.filter((it): it is string => it != undefined);
        this.trigger(categoryTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(nameTrigger)
    getName(): string {
        return this.name;
    }

    setName(name: string): void {
        if (this.name === name) {
            return;
        }
        this.name = name;
        this.trigger(nameTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(nameTrigger)
    getMarketCompanyName(): string {
        return this.companyName;
    }

    setMarketCompanyName(companyName: string): void {
        if (this.companyName === companyName) {
            return;
        }
        this.companyName = companyName;
        this.trigger(companyNameTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(nameTrigger)
    getMarketCompanies(): string[] {
        return this.companies;
    }

    setMarketCompanies(companyRefs: string[]): void {
        if (areArraysEqual(this.companies, companyRefs)) {
            return;
        }
        this.companies = companyRefs;
        this.trigger(companyRefTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(boundsTrigger)
    getBounds(): Leaflet.LatLngBoundsLiteral | undefined {
        if (!this.bounds) {
            const address = AddressStore.getSelected();
            if (address) {
                const bounds = getBoundsByDistanceFromHomeAddress(20, address);
                this.setBounds(bounds);
                return bounds;
            }
        }
        return this.bounds;
    }

    setBounds(bounds: Leaflet.LatLngBoundsLiteral | undefined): void {
        if (bounds) {
            // validate bounds:
            for (const latLng of bounds) {
                if (Math.abs(latLng[0]) > 90 || Math.abs(latLng[1]) > 180) {
                    // incorrect bounds, we just ignore it for now
                    return;
                }
            }
        }

        this.bounds = bounds;
        this.trigger(boundsTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(dateFromTrigger)
    getDateFrom(): Moment | undefined {
        if (this.dateFrom) {
            return this.dateFrom;
        }
    }

    setDateFrom(date: Moment | undefined): void {
        this.dateFrom = date;
        this.trigger(dateFromTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(dateUntilTrigger)
    getDateUntil(): Moment | undefined {
        if (this.dateUntil) {
            return this.dateUntil;
        }
    }

    setDateUntil(date: Moment | undefined): void {
        this.dateUntil = date;
        this.trigger(dateUntilTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(amountTrigger)
    getAmount(): MinMaxAmount | undefined {
        return this.amount;
    }

    setAmount(amount: MinMaxAmount | undefined): void {
        this.amount = amount;
        this.trigger(amountTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(showOnlyOffers)
    getOffers(): boolean {
        return this.showOffers;
    }

    setOffers(enabled: boolean): void {
        this.showOffers = enabled;
        this.trigger(showOnlyOffers);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(showOnlyRequests)
    getRequests(): boolean {
        return this.showRequests;
    }

    setRequests(enabled: boolean): void {
        this.showRequests = enabled;
        this.trigger(showOnlyRequests);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(showOnlyFavouritesTrigger)
    getFavourites(): boolean {
        return this.showOnlyFavourites;
    }

    setFavourites(enabled: boolean): void {
        this.showOnlyFavourites = enabled;
        this.trigger(showOnlyFavouritesTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(showIsOrganicTrigger)
    getIsOrganic(): boolean {
        return this.isOrganic;
    }

    setIsOrganic(isOrganic: boolean): void {
        this.isOrganic = isOrganic;
        this.trigger(showIsOrganicTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(showIsInTransitionTrigger)
    getIsInTransition(): boolean {
        return this.isInTransition;
    }

    setIsInTransition(isInTransition: boolean): void {
        this.isInTransition = isInTransition;
        this.trigger(showIsInTransitionTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(showOwnDataTrigger)
    getShowOwnData(): boolean {
        return this.showOwnData;
    }

    setShowOwnData(enabled: boolean): void {
        this.showOwnData = enabled;
        this.trigger(showOwnDataTrigger);
        this.trigger(triggerAll);
    }

    @autoSubscribeWithKey(areaTrigger)
    getSearchArea(): SearchArea {
        return this.searchArea;
    }

    setSearchArea(searchArea: SearchArea): void {
        this.searchArea = searchArea;
        this.startBatchUpdate();

        // persist new setting
        this.persistNewSearchSetting('area', searchArea);

        switch (this.searchArea) {
            case SearchArea.COMPANIES:
                this.setResultTypes([ResultType.COMPANY]);
                this.setActiveSearchFilters([
                    SearchFilter.NAME,
                    SearchFilter.AREA,
                    SearchFilter.ROLES,
                    SearchFilter.RESULT_TYPE,
                    SearchFilter.SHOW_ONLY_FAVOURITES,
                    SearchFilter.SHOW_OWN_DATA,
                    SearchFilter.ORGANIC,
                ]);
                break;
            case SearchArea.MARKETPLACE:
                this.setResultTypes([ResultType.OFFER, ResultType.REQUEST]);
                this.setActiveSearchFilters([
                    SearchFilter.NAME,
                    SearchFilter.AREA,
                    SearchFilter.AMOUNT,
                    SearchFilter.FROM_DATE,
                    SearchFilter.UNTIL_DATE,
                    SearchFilter.RESULT_TYPE,
                    SearchFilter.SHOW_ONLY_FAVOURITES,
                    SearchFilter.SHOW_OWN_DATA,
                    SearchFilter.COMPANIES,
                ]);
                break;
        }

        this.trigger(areaTrigger);
        this.trigger(triggerAll);
        this.stopBatchUpdate();
    }

    @autoSubscribeWithKey(searchFilterTrigger)
    getActiveSearchFilters(): SearchFilter[] {
        return this.activeFilters;
    }

    clear(): void {
        this.startBatchUpdate();
        this.setName('');
        this.setMarketCompanyName('');
        this.setMarketCompanies([]);
        this.setCategories([]);
        this.setDateFrom(undefined);
        this.setDateUntil(undefined);
        this.bounds = undefined;
        this.setAmount(undefined);
        this.setFavourites(false);

        this.setDefaultFilters();
        this.stopBatchUpdate();
        this.setIsOrganic(false);
        this.setIsInTransition(false);
    }

    setDefaultRoles(): void {
        const savedSearchFilters = UserSettingsStore.getOne(SettingsKey.SEARCH_FILTERS);
        if (savedSearchFilters && savedSearchFilters.settings !== null) {
            const filters = JSON.parse(savedSearchFilters.settings) as SavedSearchFilters;
            this.setRoles(filters.roles || []);
        } else {
            const selectedCompany = CompanyStore.getSelected();
            const selectedRoles = selectedCompany
                ? CompanyRolesStore.getOne(selectedCompany.links.self)?.roles
                : undefined;
            this.setRoles(SearchFilterStore.calculateRoles(selectedRoles));
        }
    }

    setDefaultArea(): void {
        const savedSearchFilters = UserSettingsStore.getOne(SettingsKey.SEARCH_FILTERS);
        if (savedSearchFilters && savedSearchFilters.settings !== null) {
            const filters = JSON.parse(savedSearchFilters.settings) as SavedSearchFilters;
            this.setSearchArea(filters.area || SearchArea.MARKETPLACE);
        } else {
            this.setSearchArea(SearchArea.COMPANIES);
        }
    }

    setDefaultFilters(): void {
        this.startBatchUpdate();

        this.setDefaultRoles();
        this.setDefaultArea();

        this.stopBatchUpdate();
    }

    startBatchUpdate(): void {
        StoreBase.pushTriggerBlock();
    }

    stopBatchUpdate(): void {
        StoreBase.popTriggerBlock();
    }

    setShouldKeepCategories(value: boolean): void {
        this._shouldKeepCategories = value;
    }

    shouldKeepCategories(): boolean {
        return this._shouldKeepCategories;
    }

    async persistNewSearchSetting<k extends keyof SavedSearchFilters>(
        key: k,
        value: SavedSearchFilters[k],
    ): Promise<void> {
        if (!UserStore.isUserExisting()) {
            // on registration, the user does not exist, so we can't save something for now..
            return;
        }

        const oldUserSettingsObject = UserSettingsStore.getOne(SettingsKey.SEARCH_FILTERS);

        const newSearchSettings: SavedSearchFilters = {};

        if (oldUserSettingsObject && oldUserSettingsObject.settings !== null) {
            // todo: add custom reviver if we use Sets
            const oldSearchSettings = JSON.parse(oldUserSettingsObject.settings);

            // newSearchSettings = oldSearchSettings;
            Object.assign(newSearchSettings, oldSearchSettings);
        }
        newSearchSettings[key] = value;

        if (JSON.stringify(newSearchSettings) !== oldUserSettingsObject?.settings) {
            // now save it to backend if it has changed
            await lastValueFrom(UserSettingsService.saveSetting(SettingsKey.SEARCH_FILTERS, newSearchSettings));
        }
    }

    private setActiveSearchFilters(searchFilters: SearchFilter[]): void {
        this.activeFilters = searchFilters;
        this.trigger(searchFilterTrigger);
        this.trigger(triggerAll);
    }
}

export default new SearchFilterStore();
