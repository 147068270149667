import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { ThemeProvider, WithStyles } from '@mui/styles';
import Dashlet from 'components/dashboard/Dashlet';
import FeatureToggle from 'components/feature/FeatureToggle';
import { NavLinkItem } from 'components/routing/NavLinkItem';
import { DefaultDashletProperties } from 'model/DashletProperties';
import { Feature } from 'model/Feature';
import * as React from 'react';
import { adminTheme } from 'style/NearbuyStyle';
import { withTranslation, WithTranslation } from 'react-i18next';

const _styles = () => createStyles({});

type AdminNavigationDashletProps = WithStyles<typeof _styles> & WithTranslation & DefaultDashletProperties;

class AdminNavigationDashlet extends React.Component<AdminNavigationDashletProps> {
    render(): React.ReactElement {
        return (
            <FeatureToggle requiredFeatures={[Feature.BE_ADMIN]} type={'allOf'}>
                <ThemeProvider theme={adminTheme}>
                    <Dashlet {...this.props.dashletProperties}>
                        <List component="nav">
                            <NavLinkItem button color="primary" key="Ontofood_Edit" to={`admin/ontofood`}>
                                <ListItemText>{this.props.t('admin:editOntofood')}</ListItemText>
                            </NavLinkItem>
                            <NavLinkItem
                                button
                                color="primary"
                                key="Association List"
                                to={`/admin/ontofood/seals-certifications`}
                            >
                                <ListItemText>{this.props.t('admin:editAssociations')}</ListItemText>
                            </NavLinkItem>

                            <NavLinkItem button color="primary" key="Company" to={`/admin/company`}>
                                <ListItemText>{this.props.t('admin:editCompanies')}</ListItemText>
                            </NavLinkItem>
                            <NavLinkItem button color="primary" key="Persons" to={`/admin/persons`}>
                                <ListItemText>{this.props.t('admin:editPersons')}</ListItemText>
                            </NavLinkItem>
                            <NavLinkItem button color="primary" key="Buttons" to={`/admin/buttons`}>
                                <ListItemText>{this.props.t('admin:testButtons')}</ListItemText>
                            </NavLinkItem>
                            <NavLinkItem button color="primary" key="Scheduler" to={`/admin/scheduler`}>
                                <ListItemText>{this.props.t('admin:scheduler')}</ListItemText>
                            </NavLinkItem>
                            <NavLinkItem
                                button
                                color="primary"
                                key="GlobalSystemMessages"
                                to={`/admin/globalSystemMessages`}
                            >
                                <ListItemText>{this.props.t('admin:globalSystemMessages')}</ListItemText>
                            </NavLinkItem>
                        </List>
                    </Dashlet>
                </ThemeProvider>
            </FeatureToggle>
        );
    }
}

export default withTranslation('admin')(withStyles(_styles, { withTheme: true })(AdminNavigationDashlet));
