import AssociationService from 'api/AssociationService';
import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import { AssociationStore } from 'store';
import { lastValueFrom } from 'rxjs';

export class AssociationsConsumer implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        const data = await lastValueFrom(AssociationService.listAssociations());
        AssociationStore.setAll(data);
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new AssociationsConsumer(),
);
