import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { ExpiringMembershipSystemMessage, SystemMessageType } from 'model/SystemMessage';
import * as React from 'react';
import { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { AssociationStore, MembershipStore } from 'store';
import { getUuidFromString } from 'util/helpers';

interface ExpiringMembershipSystemMessageItemProps extends RouteComponentProps {
    systemMessage: ExpiringMembershipSystemMessage;
}

interface ExpiringMembershipSystemMessageItemMapping extends Record<string, string> {
    associationName: string;
}

interface ExpiringMembershipSystemMessageItemState {
    mapping?: ExpiringMembershipSystemMessageItemMapping;
    companyId?: string;
}

class ExpiringMembershipSystemMessageItem extends ComponentBase<
    ExpiringMembershipSystemMessageItemProps,
    ExpiringMembershipSystemMessageItemState
> {
    protected _buildState(
        props: ExpiringMembershipSystemMessageItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<ExpiringMembershipSystemMessageItemState>,
    ): Partial<ExpiringMembershipSystemMessageItemState> | undefined {
        const newState: Partial<ExpiringMembershipSystemMessageItemState> = {};
        const membership = MembershipStore.getOne(props.systemMessage.links.membership);

        if (membership?.links.association) {
            const association = AssociationStore.getOne(membership.links.association);

            if (association?.description) {
                newState.mapping = {
                    associationName: association?.description,
                };
            }
        } else if (membership?.description) {
            newState.mapping = { associationName: membership?.description };
        }

        newState.companyId = getUuidFromString(membership?.links.company);

        return newState;
    }

    linkToCompanyFullDetail(): void {
        if (this.state.companyId) {
            this.props.history.push('/company/' + this.state.companyId + '/detail');
        }
    }

    render(): ReactNode {
        // if (!this.state.mapping) return null;
        // TODO This check should be used again when NEARBUY-3679 is implemented -
        // TODO condition is that no memberships with empty description or association can be created.
        return (
            <SystemMessageItem
                onClick={() => this.linkToCompanyFullDetail()}
                systemMessage={
                    this.state.mapping
                        ? this.props.systemMessage
                        : { ...this.props.systemMessage, type: SystemMessageType.EXPIRING_MEMBERSHIP_WITHOUT_INPUT }
                }
                mapping={this.state.mapping ?? {}}
            />
        );
    }
}

export default withRouter(ExpiringMembershipSystemMessageItem);
