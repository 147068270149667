import { initReactI18next } from 'react-i18next';
import i18n, { BackendModule } from 'i18next';
import I18NextChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { TmpResourceBackend } from 'i18n/TmpResourceBackend';
import Locize from 'i18next-locize-backend';
import I18NextLocizeBackend from 'i18next-locize-backend';
import { AdminSettingsStore } from 'store';
import { AdminSetting } from 'store/AdminSettingsStore';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import UserLanguageDetector from 'i18n/UserLanguageDetector';
import BrowserLanguageDetector from 'i18n/BrowserLanguageDetector';

let locize: I18NextLocizeBackend;

export function initI18Next(useSuspense?: boolean, overwriteLanguage?: string): void {
    const backends: (BackendModule | unknown)[] = [];

    const backendOptions: Record<string, unknown>[] = [];

    if (process.env.NODE_ENV === 'production' && process.env.NODE_ENV !== 'production') {
        // Add i18nCache only in production environment.
        // TODO: cache backend is disabled for now, so that translations will be visible immediately for translators
        backends.push(LocalStorageBackend);
        backendOptions.push({
            // expiration
            expirationTime: 7 * 24 * 60 * 60 * 1000, // one week
        });
    }

    if ('true' !== process.env.REACT_APP_I18N_USE_LOCIZE || 'true' === process.env.REACT_APP_I18N_USE_LOCIZE_ONTOFOOD) {
        backends.push(TmpResourceBackend);
        backendOptions.push({});
    }
    if ('true' === process.env.REACT_APP_I18N_USE_LOCIZE) {
        locize = new Locize();
        const backendOption = {
            // the id of your locize project
            projectId: 'ad439f20-6ec0-41f8-af94-ebd3cf1b9b90',
            // the reference language of your project
            referenceLng: 'de',
            // hostnames that are allowed to create, update keys
            // please keep those to your local system, staging, test servers (not production)
            // can be array of allowed hosts or a function (hostname) => { return true; // or false if not allowed }
            // allowedAddOrUpdateHosts: ['localhost:3000', 'staging.nearbuy-food.de'],
            allowedAddOrUpdateHosts: ['mitmachen.nearbuy-food.de'],
            // add an api key if you want to send missing keys
            // apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
        };
        if (AdminSettingsStore.hasOne(AdminSetting.LOCIZE_API_KEY)) {
            const apiKey = AdminSettingsStore.getOne(AdminSetting.LOCIZE_API_KEY)?.value;
            if (apiKey && apiKey !== '') {
                const apiBackendOptions = {
                    ...backendOption,
                    apiKey,
                };
                locize.init(undefined, apiBackendOptions);
            }
        } else {
            locize.init(undefined, backendOption);
        }
        backends.push(locize);
    }

    const detector: I18nextBrowserLanguageDetector = new I18nextBrowserLanguageDetector();
    detector.addDetector(new UserLanguageDetector());
    detector.addDetector(new BrowserLanguageDetector());

    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .use(I18NextChainedBackend)
        .use(detector)
        .init({
            backend: {
                backends: backends,
                backendOptions: backendOptions,
            },
            detection: {
                order: ['localStorage', 'UserLanguageDetector', 'BrowserLanguageDetector', 'navigator'],
                caches: ['localStorage', 'UserLanguageDetector'],
            },
            fallbackLng: 'de',
            fallbackNS: 'common',
            load: 'languageOnly',
            interpolation: {
                escapeValue: false,
            },
            nsSeparator: ':',
            react: {
                useSuspense: useSuspense ?? true,
            },
            saveMissing: AdminSettingsStore.hasOne(AdminSetting.LOCIZE_API_KEY),
            saveMissingTo: 'current',
        });

    i18n.setDefaultNamespace('common');

    if (overwriteLanguage) {
        i18n.changeLanguage(overwriteLanguage);
    }
}

export function getLocize(): I18NextLocizeBackend | undefined {
    return locize;
}
