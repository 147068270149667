import * as React from 'react';
import { ComponentBase } from 'resub';
import { MatchingOfferSystemMessage } from 'model/SystemMessage';
import { withTranslation, WithTranslation } from 'react-i18next';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { OfferStore, ProductStore, RequestStore } from 'store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LocalisationProviderWrapper from 'components/routing/LocalisationProviderWrapper';
import OfferFullDetailDialog from 'components/search/result-items/offers/OfferFullDetailDialog';
import { Offer } from 'model/Offer';
import ExpiredProductDialog from 'components/search/result-items/ExpiredProductDialog';

interface MatchingOfferSystemMessageItemProps extends WithTranslation, RouteComponentProps {
    systemMessage: MatchingOfferSystemMessage;
}

interface MatchingOfferSystemMessageItemState {
    mapping?: MatchingOfferSystemMessageItemMapping;
    offer?: Offer;
    isDialogOpen?: boolean;
}

interface MatchingOfferSystemMessageItemMapping extends Record<string, string> {
    productName: string;
}

class MatchingOfferSystemMessageItem extends ComponentBase<
    MatchingOfferSystemMessageItemProps,
    MatchingOfferSystemMessageItemState
> {
    protected _buildState(
        props: MatchingOfferSystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<MatchingOfferSystemMessageItemState>,
    ): Partial<MatchingOfferSystemMessageItemState> | undefined {
        const request = RequestStore.getOne(props.systemMessage.links.request);
        const offer = OfferStore.getOne(props.systemMessage.links.offer);
        const product = request && ProductStore.getOne(request.links.category)?.label;
        if (request && offer) {
            return {
                offer: offer,
                mapping: {
                    productName: props.t('ontofood:' + product),
                },
            };
        }
    }

    openOfferDialog() {
        this.setState({ isDialogOpen: true });
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping || !this.state.offer) return null;

        return (
            <>
                <LocalisationProviderWrapper>
                    <OfferFullDetailDialog
                        offerRef={this.props.systemMessage.links.offer}
                        isOpen={(this.state.isDialogOpen && this.state.offer.active) || false}
                        onClose={() => {
                            this.setState({ isDialogOpen: false });
                        }}
                    />
                    <ExpiredProductDialog
                        expiredProductTitle={this.props.t('dialogs:expiredProductTitleOffer')}
                        isOpen={(this.state.isDialogOpen && !this.state.offer.active) || false}
                        onClose={() => {
                            this.setState({ isDialogOpen: false });
                        }}
                    />
                </LocalisationProviderWrapper>
                <SystemMessageItem
                    onClick={() => this.openOfferDialog()}
                    systemMessage={this.props.systemMessage}
                    mapping={this.state.mapping}
                />
            </>
        );
    }
}

export default withRouter(withTranslation(['dialogs', 'ontofood'])(MatchingOfferSystemMessageItem));
