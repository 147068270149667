import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Location } from 'history';
import { ComponentBase } from 'resub';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Observable } from 'rxjs';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { WithStyles } from '@mui/styles';
import { Transition } from 'util/style-helpers';

const styles = () => createStyles({});

interface SaveLeavePreventionButton {
    text: string;
    action?: () => void;
    observableAction?: () => Observable<void>;
    saveAction?: () => void;
    variant?: 'text' | 'outlined' | 'contained';
    disabled?: boolean;
}

interface SaveLeavePreventionPromptProps extends RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
    prevent: boolean;
    title: string;
    description: string;
    buttons: SaveLeavePreventionButton[];
    overrideIsOpen?: boolean;
}

interface SaveLeavePreventionPromptState {
    isOpen: boolean;
    nextLocation?: Location<unknown>;
    unblock?: () => void;
}

class SaveLeavePreventionPrompt extends ComponentBase<SaveLeavePreventionPromptProps, SaveLeavePreventionPromptState> {
    componentDidMount() {
        super.componentDidMount();
        this.props.history.action;
        const unblock = this.props.history.block((nextLocation) => {
            if (this.props.prevent) {
                this.setState({ isOpen: true, nextLocation });
                return false;
            }
            return;
        });
        this.setState({ unblock });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.state.unblock?.();
    }

    closeDialog = () => this.setState({ isOpen: false });

    leave = () => {
        this.state.unblock?.();
        this.state.nextLocation && this.props.history.push(this.state.nextLocation);
    };

    getButtonAction(button: SaveLeavePreventionButton): () => void {
        if (button.action) {
            return () => {
                button.action?.();
                this.leave();
            };
        } else if (button.observableAction) {
            return () => {
                button.observableAction?.().subscribe({
                    next: this.leave,
                    error: this.closeDialog,
                });
            };
        } else if (button.saveAction) {
            return () => {
                button.saveAction?.();
                this.leave();
            };
        } else {
            return this.closeDialog;
        }
    }
    render() {
        return (
            <Dialog
                open={(this.state.isOpen || this.props.overrideIsOpen) ?? false}
                TransitionComponent={Transition}
                maxWidth="md"
            >
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>{this.props.description}</Typography>
                </DialogContent>
                <DialogActions>
                    {this.props.buttons.map(
                        (button: SaveLeavePreventionButton, index: number, array: SaveLeavePreventionButton[]) => {
                            return (
                                <Button
                                    variant={button.variant ?? 'outlined'}
                                    disabled={button.disabled ?? false}
                                    onClick={this.getButtonAction(button)}
                                    key={`${index + 1}/${array.length}`}
                                >
                                    {button.text}
                                </Button>
                            );
                        },
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(
    withTranslation(['dialogs'])(withRouter(SaveLeavePreventionPrompt)),
);
