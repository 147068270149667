import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import { OfferStore } from 'store';
import { Offer } from 'model/Offer';
import OfferReferenceItem from 'components/dashboard/marketplace/offer/OfferReferenceComponent';
import OfferFullDetailDialog from 'components/search/result-items/offers/OfferFullDetailDialog';
import { Theme } from '@mui/material';
import ExpiredProductDialog from 'components/search/result-items/ExpiredProductDialog';
import { withTranslation, WithTranslation } from 'react-i18next';

const _styles = (theme: Theme) =>
    createStyles({
        content: {
            width: '85%',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    });

interface OfferReferenceProps extends WithTranslation, WithStyles<typeof _styles> {
    offerRef: string;
}

interface OfferReferenceState {
    offer?: Offer;
    isDialogOpen?: boolean;
}

class OfferReference extends ComponentBase<OfferReferenceProps, OfferReferenceState> {
    protected _buildState(
        props: OfferReferenceProps,
        initialBuild: boolean,
        incomingState?: Readonly<OfferReferenceState>,
    ): Partial<OfferReferenceState> | undefined {
        return {
            offer: OfferStore.getOne(props.offerRef),
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.offer) {
            return null;
        }
        return (
            <>
                <OfferFullDetailDialog
                    offerRef={this.state.offer.links.self}
                    isOpen={(this.state.isDialogOpen && this.state.offer.active) || false}
                    onClose={() => {
                        this.setState({ isDialogOpen: false });
                    }}
                />
                <ExpiredProductDialog
                    expiredProductTitle={this.props.t('dialogs:expiredProductTitleOffer')}
                    isOpen={(this.state.isDialogOpen && !this.state.offer.active) || false}
                    onClose={() => {
                        this.setState({ isDialogOpen: false });
                    }}
                />
                <div className={this.props.classes.content}>
                    <OfferReferenceItem
                        offerRef={this.props.offerRef}
                        onClick={() => this.setState({ isDialogOpen: true })}
                    />
                </div>
            </>
        );
    }
}

export default withTranslation('dialogs')(withStyles(_styles, { withTheme: true })(OfferReference));
