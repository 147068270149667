import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SystemMessageService from 'api/SystemMessageService';
import { NearbuySnackBarItem } from 'components/notification/NearbuySnackBarItem';
import i18n from 'i18next';
import { NotificationInfo, NotificationType } from 'model/NearbuyNotification';
import * as React from 'react';
import { ReactNode } from 'react';
import { SystemMessageSnackbarStore, SystemMessageStore } from 'store';
import { theme } from 'style/NearbuyStyle';

class SystemMessageSnackBarItem implements NearbuySnackBarItem {
    getButtonAction(_notificationInfo: NotificationInfo, _closeAction: () => void): () => void {
        if (_notificationInfo.notification.type === NotificationType.DELETED_SYSTEM_MESSAGE) {
            return () => {
                SystemMessageService.setSystemMessageIsDeleted(
                    _notificationInfo.notification.systemMessageRef!,
                    false,
                ).subscribe();
                _closeAction();
            };
        } else if (_notificationInfo.notification.type === NotificationType.HIDE_GLOBAL_SYSTEM_MESSAGE_SNACKBAR) {
            return () => {
                if (!_notificationInfo.notification.systemMessageRef) return;
                SystemMessageService.getSystemMessage(_notificationInfo.notification.systemMessageRef).subscribe(
                    (sm) => {
                        SystemMessageSnackbarStore.add(sm);
                        _closeAction();
                    },
                );
            };
        } else
            return () => {
                // do nothing.
            };
    }
    getAction(_notificationInfo: NotificationInfo, _closeAction: () => void): ReactNode {
        if (_notificationInfo.notification.systemMessageRef) {
            return (
                <div>
                    <Button
                        sx={{
                            fontWeight: 'bold',
                            textDecoration: 'none',
                            color: theme.palette.primary.lighter,
                            backgroundColor: 'transparent',
                            '&:hover': {
                                color: theme.palette.primary.lighter,
                            },
                        }}
                        onClick={this.getButtonAction(_notificationInfo, _closeAction)}
                    >
                        {i18n.t('notification:undo')}
                    </Button>
                    <IconButton
                        sx={{
                            color: theme.palette.common.white,
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        size="small"
                        aria-label="close"
                        onClick={() => {
                            _closeAction();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            );
        } else return null;
    }

    getContent(_notificationInfo: NotificationInfo): React.ReactElement | string | null {
        return _notificationInfo.notification.value;
    }

    getSideEffect(): (notificationInfo: NotificationInfo) => void {
        return (notificationInfo) => {
            SystemMessageStore.invalidateSearch();
            SystemMessageStore.loadOne(notificationInfo.notification.value);
        };
    }
}

export default new SystemMessageSnackBarItem();
