import * as React from 'react';
import { ComponentBase } from 'resub';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ExtendedTheme } from 'style/NearbuyStyle';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';

const styles = (theme: ExtendedTheme) =>
    createStyles({
        showMoreButton: {
            color: theme.palette.primary.dark,
            fontWeight: 600,
            padding: `0 ${theme.spacing(2)}`,
            fontSize: 14,
            borderRadius: 6,
            '&:hover': {
                backgroundColor: theme.palette.primary.lighter,
                borderRadius: 6,
                color: theme.palette.primary.dark,
            },
        },
    });

interface ShowMoreButtonProps extends WithStyles<typeof styles>, WithTranslation {
    onClick: () => void;
    type: 'text' | 'items' | 'cards';
}

interface ShowMoreButtonState {
    isToggled: boolean;
}

class ShowMoreButton extends ComponentBase<ShowMoreButtonProps, ShowMoreButtonState> {
    render(): React.ReactElement | null {
        return (
            <Button
                variant={'text'}
                className={this.props.classes.showMoreButton}
                onClick={() => {
                    this.props.onClick();
                    this.setState({ isToggled: !this.state.isToggled });
                }}
            >
                {' '}
                {this.props.t(
                    this.state.isToggled
                        ? 'common:showLess'
                        : this.props.type == 'cards'
                          ? 'common:showAll'
                          : `common:showMore${this.props.type == 'text' ? 'Text' : ''}`,
                )}
            </Button>
        );
    }
}

export default withTranslation('common')(withStyles(styles, { withTheme: true })(ShowMoreButton));
