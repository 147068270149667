export enum NearbuyRole {
    SUPPLIER = 'SUPPLIER',
    PROCESSOR = 'PROCESSOR',
    CONSOLIDATOR = 'CONSOLIDATOR',
    SHIPPER = 'SHIPPER',
    CONSUMER = 'CONSUMER',
    WHOLESALER = 'WHOLESALER',
    NETWORKER = 'NETWORKER',
}

export class CompanyRoles {
    constructor(
        public companyRef: string,
        public roles: NearbuyRole[],
    ) {}
}
