import * as React from 'react';
import { PureComponent } from 'react';
import { Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import { Person } from 'model/Person';
import { Company } from 'model/Company';
import { SubstitutePerson } from 'model/SubstitutePerson';
import { classNames } from 'util/style-helpers';

const _styles = () =>
    createStyles({
        drawerListItem: {
            fontSize: '15px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        },
        bold: {
            fontWeight: 'bold',
        },
        title: {
            fontSize: 'large',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
        },
    });

interface MessageHeaderProps extends WithStyles<typeof _styles> {
    company?: Company;
    person?: Person | SubstitutePerson;
    isUnseen?: boolean;
    isAHeader?: boolean;
}

class MessageHeader extends PureComponent<MessageHeaderProps> {
    render(): React.ReactElement {
        let personName: string | undefined;
        let companyName: string | undefined;
        if (this.props.person) {
            personName = `${this.props.person.firstname} ${this.props.person.lastname}`;
        }
        if (this.props.company) {
            companyName = this.props.company.name;
        }

        return (
            <Grid container item direction={'column'}>
                <Typography
                    sx={{ fontWeight: 600 }}
                    className={
                        this.props.isAHeader
                            ? classNames(
                                  this.props.classes.title,
                                  this.props.isUnseen ? this.props.classes.bold : undefined,
                              )
                            : this.props.classes.drawerListItem
                    }
                >
                    {personName}
                </Typography>
                <Typography
                    sx={this.props.isAHeader ? { whiteSpace: 'normal' } : { whiteSpace: 'nowrap' }}
                    className={
                        this.props.isAHeader
                            ? classNames(
                                  this.props.classes.title,
                                  this.props.isUnseen ? this.props.classes.bold : undefined,
                              )
                            : this.props.classes.drawerListItem
                    }
                >
                    {companyName}
                </Typography>
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(MessageHeader);
