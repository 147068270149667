import { Observable } from 'rxjs';
import http from 'api/http';
import { NearbuyNotification, NotificationType } from 'model/NearbuyNotification';
import { map } from 'rxjs/operators';
import { NotificationStore } from 'store';
import { EventSource } from 'extended-eventsource';
import { now } from 'moment';

class NotificationService {
    public async startAcceptingNotifications() {
        const eventSource = new EventSource(`${http.getBaseUrl()}/notifications`, {
            headers: http.getCurrentHeaders(),
        });
        eventSource.onerror = (event) => {
            // this should happen in case the connection is closed, but a retry is done automatically
            console.warn(event);
        };
        eventSource.onmessage = (event) => {
            if (!event.data) {
                // empty connection message, just ignore it..
                return;
            }
            const messageInfo = {
                notification: JSON.parse(event.data),
                timestamp: now(),
            };
            if (messageInfo.notification.type !== NotificationType.HEARTBEAT) {
                NotificationStore.setOne(messageInfo);
            }
        };
    }

    public triggerTestNotification(): Observable<NearbuyNotification> {
        return http
            .getAxios()
            .post<NearbuyNotification>('/notifications/')
            .pipe(map((response) => response.data));
    }
}

export default new NotificationService();
