import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import ProductService from 'api/ProductService';
import { lastValueFrom } from 'rxjs';

export class OntofoodConsumer implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        await Promise.all([
            lastValueFrom(ProductService.getAllProducts()),
            lastValueFrom(ProductService.getTopCategory()),
        ]);
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new OntofoodConsumer(),
);
