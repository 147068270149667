import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { withResubAutoSubscriptions } from 'resub';
import { ExtendedTheme } from 'style/NearbuyStyle';

interface InformationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    content: string;
    link?: never[];
}

function InformationDialog(props: InformationDialogProps) {
    /* Hooks */
    const { t } = useTranslation(['dialogs']);
    const theme = useTheme<ExtendedTheme>();
    /* States and Store Subscribed Constants */
    /* Effects */
    /* Styles und StyledComponents */
    /* JSX */

    const title = <DialogTitle>{props.title}</DialogTitle>;
    const contentWithLink = () => {
        return <Trans components={props.link ?? []}>{props.content}</Trans>;
    };

    const content = <DialogContent>{props.link ? contentWithLink() : props.content}</DialogContent>;
    const okayButton = (
        <Button variant={'outlined'} onClick={() => props.onClose()}>
            {t('dialogs:OKAY')}
        </Button>
    );
    const actions = (
        <DialogActions>
            <Grid container direction={'row'} justifyContent={'end'} spacing={theme.spacing(1)}>
                <Grid item>{okayButton}</Grid>
            </Grid>
        </DialogActions>
    );

    return (
        <Dialog
            onClose={(event, reason) => {
                if (event && reason === 'backdropClick') {
                    props.onClose();
                }
            }}
            open={props.isOpen !== undefined ? props.isOpen : false}
        >
            {props.title ? title : null}
            {content}
            {actions}
        </Dialog>
    );
}

export default withResubAutoSubscriptions(InformationDialog);
