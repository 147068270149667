import { WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as React from 'react';
import { PureComponent } from 'react';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { getNumberCurrency } from 'util/helpers';
import { Offer } from 'model/Offer';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) =>
    createStyles({
        heading: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        contentText: {
            fontSize: '16px',
            color: theme.palette.text.primary,
        },
    });

interface PricePerUnitComponentProperties extends WithStyles<typeof styles>, WithTranslation {
    offer: Offer;
}

export class PricePerUnitComponent extends PureComponent<PricePerUnitComponentProperties> {
    render(): React.ReactElement {
        return (
            <>
                <Grid container item justifyContent={'left'} alignItems={'center'}>
                    <Grid item>
                        <Typography className={this.props.classes.heading}>{this.props.t('offer:price')}</Typography>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={5} id={'pricePerUnit'}>
                        <Typography className={this.props.classes.contentText} data-testid={'pricePerUnit'}>
                            {getNumberCurrency(this.props.offer!.pricePerUnit!) +
                                ' ' +
                                this.props.t('offer:per') +
                                ' ' +
                                (this.props.offer.totalAmount.unit &&
                                    this.props.t('shortunits:' + this.props.offer.totalAmount.unit))}
                        </Typography>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withTranslation(['shortunits', 'offer'])(withStyles(styles, { withTheme: true })(PricePerUnitComponent));
