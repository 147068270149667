import http from 'api/http';
import { AxiosResponse } from 'axios';
import { ContainerType } from 'model/ContainerView';
import { ResultItem } from 'model/ResultItem';
import { Unit } from 'model/Unit';
import moment from 'moment/moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface CompanyImportCsvResponse {
    imported: string[];
    errors: string[];
}

class MetaService {
    public search(query: string): Observable<ResultItem[]> {
        return http
            .getAxios()
            .get<ResultItem[]>(`meta/search${query}`)
            .pipe(
                map((value: AxiosResponse<ResultItem[]>) => value.data),
                map((resultItems) => {
                    return resultItems.map((resultItem) => {
                        if (resultItem.product) {
                            resultItem.product.dateStart = moment(resultItem.product.dateStart);
                            resultItem.product.dateEnd = moment(resultItem.product.dateEnd);
                        }
                        return resultItem;
                    });
                }),
            );
    }

    public getLocizeKey(): Observable<string | undefined> {
        return http
            .getAxios()
            .get<string>('meta/locize_api_key')
            .pipe(
                map((response) => response.data),
                catchError(() => of(undefined)),
            );
    }

    public getContainerTypes(): Observable<ContainerType[]> {
        return http
            .getAxios()
            .get<ContainerType[]>(`meta/container_types`)
            .pipe(map((value: AxiosResponse<ContainerType[]>) => value.data));
    }

    public getCompanyCSVHeaders(): Observable<string> {
        return http
            .getAxios()
            .get<string>('meta/company_csv')
            .pipe(map((response) => response.data));
    }

    public getPersonDataCSV(): Observable<string> {
        return http
            .getAxios()
            .get<string>('meta/persons_csv')
            .pipe(map((response) => response.data));
    }

    public importCompanyCSV(csvFile: File): Observable<CompanyImportCsvResponse> {
        const data = new FormData();
        data.append('file', csvFile);
        return http
            .getAxios()
            .post<CompanyImportCsvResponse>('meta/company_csv', data)
            .pipe(map((value) => value.data));
    }

    public getUnits(): Observable<Unit[]> {
        return http
            .getAxios()
            .get<Unit[]>('meta/units')
            .pipe(map((value) => value.data));
    }

    /**
     * Cannot guarantee that this also works for URLs different from the Google Cloud Storage!
     * @param url
     */
    public getFileSize(url: string): Observable<number> {
        return http
            .getAxios()
            .head(url)
            .pipe(
                map((value) => value.headers['content-length']),
                map((value) => value as unknown as number),
            );
    }
}

export default new MetaService();
