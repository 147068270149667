import * as React from 'react';
import { ComponentBase } from 'resub';
import Link from '@mui/material/Link';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Link as RouterLink } from 'react-router-dom';
import { AddressStore, CompanyStore } from 'store';
import { Company } from 'model/Company';
import { Address } from 'model/Address';
import { DefaultDashletProperties } from 'model/DashletProperties';
import Dashlet from 'components/dashboard/Dashlet';
import { Grid, Typography } from '@mui/material';
import CompanyAvatarComponent from 'components/company/view/CompanyAvatarComponent';
import { classNames } from 'util/style-helpers';

const styles = () =>
    createStyles({
        overflowRules: {
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            textOverflow: 'ellipsis',
            minWidth: '25px',
            width: '98%',
        },
        textResponsiveness: {
            wordWrap: 'break-word',
            hyphens: 'auto',
        },
    });

interface CompanyDashletProperties extends DefaultDashletProperties, WithStyles<typeof styles> {
    companyRef: string;
}

interface CompanyDashletState {
    company?: Company;
    mainAddress?: Address;
}

class CompanyDashlet extends ComponentBase<CompanyDashletProperties, CompanyDashletState> {
    protected _buildState(props: CompanyDashletProperties): Partial<CompanyDashletState> | undefined {
        if (!props.companyRef) {
            return undefined;
        }
        const newState: Partial<CompanyDashletState> = {
            company: CompanyStore.getOne(props.companyRef),
        };
        if (newState.company && newState.company.address) {
            newState.mainAddress = AddressStore.getOne(newState.company.address);
        } else {
            newState.mainAddress = undefined;
        }
        return newState;
    }

    render(): React.ReactElement | null {
        if (!this.state.company) return null;
        return (
            <Dashlet {...this.props.dashletProperties}>
                <Link
                    component={RouterLink}
                    to={'/edit/company/' + this.state.company.id + '/detail'}
                    className={this.props.classes.overflowRules}
                >
                    <Grid container direction="column" spacing={1}>
                        <Grid item container>
                            <Typography
                                color={'textPrimary'}
                                fontWeight={600}
                                className={classNames(
                                    this.props.classes.overflowRules,
                                    this.props.classes.textResponsiveness,
                                )}
                            >
                                {`${this.state.company.name} ${this.state.company.legalstatus}`}
                            </Typography>
                        </Grid>
                        {this.state.mainAddress && (
                            <>
                                <Grid item container>
                                    <Typography
                                        noWrap
                                        color={'textPrimary'}
                                        className={classNames(
                                            this.props.classes.overflowRules,
                                            this.props.classes.textResponsiveness,
                                        )}
                                    >
                                        {this.state.mainAddress.street}
                                    </Typography>
                                </Grid>
                                <Grid item container direction={'row'}>
                                    <Typography
                                        sx={{ marginRight: 1 }}
                                        color={'textPrimary'}
                                        className={classNames(
                                            this.props.classes.textResponsiveness,
                                            this.props.classes.overflowRules,
                                        )}
                                    >
                                        {this.state.mainAddress.zipcode} {this.state.mainAddress.city}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        <Grid container item justifyContent="flex-end">
                            <CompanyAvatarComponent companyRef={this.state.company.links.self} isLogoShown />
                        </Grid>
                    </Grid>
                </Link>
            </Dashlet>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CompanyDashlet);
