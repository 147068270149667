import http from 'api/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { EmptySetting, SettingsKey, UserSetting, UserSettingWriteView } from 'model/UserSettings';
import { UserSettingsStore } from 'store';

class UserSettingsService {
    public getSetting(key: SettingsKey): Observable<UserSetting | EmptySetting> {
        return http
            .getAxios()
            .get<UserSetting>(`settings/${key}`)
            .pipe(
                map((response) => (response.status === 204 ? { key, settings: null } : response.data)),
                catchError(() => of({ key, settings: null })),
            );
    }

    public saveSetting(key: SettingsKey, setting: unknown): Observable<UserSetting> {
        const writeView: UserSettingWriteView = {
            key: key,
            // todo: if there is any Set, we need to add replacer and reviver for it
            setting: JSON.stringify(setting),
        };
        return http
            .getAxios()
            .post(`settings`, writeView)
            .pipe(
                map((response) => response.data),
                tap((setting) => UserSettingsStore.setOne(setting)),
            );
    }

    public deleteSetting(key: SettingsKey): Observable<UserSetting | null> {
        return http
            .getAxios()
            .delete<UserSetting | null>(`settings/${key}`)
            .pipe(map((response) => response.data));
    }
}

export default new UserSettingsService();
