import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import LocalStorageMigrator from 'components/initialization/initializers/migration/LocalStorageMigrator';

export class MigrationInitializer implements InitializationEventConsumer {
    async onInitializationEvent(_phase: InitializationPhase): Promise<void> {
        await LocalStorageMigrator.migrate();
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.LOADING,
    new MigrationInitializer(),
);
