import { theme } from 'style/NearbuyStyle';
import moment from 'moment/moment';
import * as React from 'react';
import { ReactElement } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';

interface StyledDatePickerComponentProps {
    value: string | moment.Moment | null | undefined;
    label: string;
    onChange: (_date: any) => void;
    renderInput: (_props: any) => ReactElement;
    readOnly?: boolean;
    disabled?: boolean;
    disablePast?: boolean;
    disableMaskedInput?: boolean;
    minDate?: moment.Moment | null | undefined;
    maxDate?: moment.Moment | null | undefined;
}

function StyledDatePickerComponent(props: StyledDatePickerComponentProps) {
    return (
        <DesktopDatePicker
            PopperProps={{
                sx: {
                    '& .MuiPaper-root': {
                        borderRadius: 4,
                        boxShadow: '1px 1px 10px 5px rgba(0, 0, 0, 0.08)',
                    },
                    '& .MuiButtonBase-root': {
                        '&.Mui-selected': {
                            color: theme.palette.common.white,
                            fontWeight: 600,
                            '&: hover': {
                                backgroundColor: theme.palette.primary.lighter,
                                color: theme.palette.primary.dark,
                                fontWeight: 900,
                            },
                        },
                    },
                    '& .MuiPickersDay-root': {
                        color: theme.palette.primary.dark,
                        fontWeight: 600,
                        '&: hover': {
                            backgroundColor: theme.palette.primary.lighter,
                            color: theme.palette.primary.dark,
                            fontWeight: 900,
                        },
                    },
                    '& .MuiIconButton-root': {
                        backgroundColor: 'transparent',
                        '&: hover': {
                            color: theme.palette.primary.dark,
                            backgroundColor: theme.palette.primary.lighter,
                        },
                    },
                    '& .PrivatePickersYear-yearButton': {
                        color: theme.palette.primary.dark,
                        fontWeight: 600,
                        '&.Mui-selected': {
                            color: theme.palette.common.white,
                        },
                        '&: hover': {
                            fontWeight: 900,
                            backgroundColor: theme.palette.primary.lighter,
                        },
                    },
                },
            }}
            disablePast={props.disablePast}
            renderInput={props.renderInput}
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            minDate={props.minDate}
            maxDate={props.maxDate}
            disabled={props.disabled}
            readOnly={props.readOnly}
            disableMaskedInput={props.disableMaskedInput}
        />
    );
}

export default StyledDatePickerComponent;
