import * as React from 'react';
import { ComponentBase } from 'resub';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import DialogContent from '@mui/material/DialogContent';
import { Switch, Theme } from '@mui/material';
import { WithStyles, WithTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Dialog from '@mui/material/Dialog';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import 'moment/locale/de';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { Person } from 'model/Person';
import { Company } from 'model/Company';
import { Offer, OfferContainers } from 'model/Offer';
import { CompanyStore, ContactsStore, NotificationStore, OfferContainersStore, OfferStore, ProductStore } from 'store';
import PersonStore from 'store/PersonStore';
import { Contact } from 'model/Contact';
import ContainerSelectionComponent from 'components/marketplace/pre-order/ContainerSelectionComponent';
import GraduatedPricesTable from 'components/search/result-items/offers/GraduatedPricesTable';
import PricePerUnitComponent from 'components/search/result-items/offers/PricePerUnitComponent';
import AmountComponent from 'components/marketplace/pre-order/AmountComponent';
import { PurchaseIntent, PurchaseIntentWriteView } from 'model/PurchaseIntent';
import { Product } from 'model/Product';
import PurchaseIntentService from 'api/PurchaseIntentService';
import { NotificationInfo, PreOrderSentNotification } from 'model/NearbuyNotification';
import { now } from 'moment/moment';
import { Amount, getAmountValidationValue } from 'model/Amount';
import { PriceRequest, PriceRequestWriteView } from 'model/PriceRequest';
import { Observable } from 'rxjs';
import PriceRequestService from 'api/PriceRequestService';
import { AxiosError } from 'axios';
import { Transition } from 'util/style-helpers';
import MessageService from 'api/MessageService';
import DialogTitle from '@mui/material/DialogTitle';
import { captureWebEvent } from 'util/AnalyticUtils';
import { theme } from 'style/NearbuyStyle';

const styles = (theme: Theme) =>
    createStyles({
        sendButton: {
            marginLeft: theme.spacing(1),
        },
        textField: {
            '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.secondary,
            },
            spacing: theme.spacing(2),
        },
        smallTypography: {
            fontSize: 'small',
        },
    });

export enum PreOrderDialogType {
    PurchaseIntent,
    PriceRequest,
}

interface PreOrderDialogProps extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    type: PreOrderDialogType;
    isOpen: boolean;
    onClose: () => void;
    offerRef: string;
    contactRef: string;
}

interface PreOrderDialogState {
    person?: Person;
    ownCompany?: Company;
    foreignCompany?: Company;
    contact?: Contact;
    showContainerSelection?: boolean;
    offer?: Offer;
    offerContainer?: OfferContainers;
    category?: Product;
    amountInput: number;
    message?: string;
    containers?: Map<string, number>;
    goodsAmount?: number;
    showSentError: boolean;
    statusCode?: number;
    isAmountSelectionSwitchChecked?: boolean;
}

export class PreOrderDialog extends ComponentBase<PreOrderDialogProps, PreOrderDialogState> {
    protected _buildState(
        props: PreOrderDialogProps,
        initialBuild: boolean,
        incomingState: Readonly<PreOrderDialogState> | undefined,
    ): Partial<PreOrderDialogState> | undefined {
        const newState: Partial<PreOrderDialogState> = {
            contact: ContactsStore.getOne(props.contactRef),
            offer: OfferStore.getOne(this.props.offerRef),
            offerContainer: OfferContainersStore.getOne(this.props.offerRef),
        };

        if (initialBuild) {
            OfferStore.loadOne(this.props.offerRef);
            newState.isAmountSelectionSwitchChecked = false;
            newState.message = undefined;
            newState.amountInput = 0;
        }

        newState.showContainerSelection =
            !incomingState?.isAmountSelectionSwitchChecked &&
            newState.offerContainer &&
            newState.offerContainer.containerRefs.length >= 1;

        if (newState.contact && newState.offer) {
            newState.person = PersonStore.getOne(newState.contact.links.person);
            newState.foreignCompany = CompanyStore.getOne(newState.offer.links.company);
            newState.ownCompany = CompanyStore.getSelected();
            newState.category = ProductStore.getOne(newState.offer.links.category);
        }
        return newState;
    }

    amountInputChangeHandler = (amountIn: number): void => {
        this.setState({ amountInput: amountIn });
    };

    containersChangeHandler = (amountContainers: Map<string, number>): void => {
        this.setState({ containers: amountContainers });
    };

    onClose(): void {
        this.props.onClose();
        this.setState({
            showContainerSelection: false,
            message: undefined,
            showSentError: false,
            statusCode: undefined,
            containers: undefined,
            goodsAmount: undefined,
            amountInput: 0,
        });
    }

    showToggle(): React.ReactFragment | null {
        if (this.state.offerContainer && this.state.offerContainer.containerRefs.length > 0) {
            return (
                <Grid container item alignItems={'center'}>
                    <Grid item>
                        <Switch
                            data-testid={'switch'}
                            checked={this.state.isAmountSelectionSwitchChecked}
                            color={'primary'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                this.setState({
                                    isAmountSelectionSwitchChecked: event.target.checked,
                                    containers: undefined,
                                    goodsAmount: undefined,
                                    amountInput: 0,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>{this.props.t('offer:amountInputWithoutContainer')}</Typography>
                    </Grid>
                </Grid>
            );
        } else return null;
    }

    send(): void {
        if (!this.state.ownCompany) {
            return;
        }
        let containers = undefined;
        let totalAmount = undefined;
        if (this.state.offer && this.state.amountInput) {
            // in case the total amount is not given directly, but there are containers, the total amount is calculated in the backend
            if (!this.state.showContainerSelection) {
                totalAmount = new Amount(this.state.amountInput, this.state.offer.totalAmount.unit);
            }
            if (this.state.containers) {
                containers = Array.from(this.state.containers).map(([second, first]) => ({ first, second }));
            }
        }
        let observable: Observable<PurchaseIntent | PriceRequest>;
        if (this.props.type == PreOrderDialogType.PurchaseIntent) {
            const purchaseIntentWrite = new PurchaseIntentWriteView(
                this.props.offerRef,
                this.state.message,
                containers,
                totalAmount,
            );
            observable = PurchaseIntentService.sendPurchaseIntent(purchaseIntentWrite);
        } else {
            const priceRequestWrite = new PriceRequestWriteView(
                this.props.offerRef,
                this.state.message,
                containers,
                totalAmount,
            );
            observable = PriceRequestService.sendPriceRequest(priceRequestWrite);
        }
        observable.subscribe({
            next: (value) => {
                this.onClose();
                NotificationStore.setOne(new NotificationInfo(PreOrderSentNotification(), now()));
                MessageService.invalidateDialogByPreOrder(value, true);
                OfferStore.loadOne(this.props.offerRef);
            },
            error: (error: AxiosError) => {
                if (error.response?.status === 409) {
                    OfferStore.loadOne(this.props.offerRef);
                } else {
                    this.setState({
                        showSentError: true,
                        statusCode: error?.response?.status,
                    });
                }
            },
        });
    }

    getTitleLocale(): string {
        if (this.props.type == PreOrderDialogType.PurchaseIntent) {
            return 'offer:purchaseIntent';
        } else {
            return 'offer:priceRequest';
        }
    }

    showReceiverAndOrderTextFields(): React.ReactFragment | undefined {
        if (!this.state.foreignCompany || !this.state.category) {
            return;
        }
        return (
            <Grid
                container
                item
                direction={'column'}
                spacing={1}
                sx={{
                    borderRadius: 4,
                    backgroundColor: theme.palette.grey[100],
                    padding: 0,
                    paddingBottom: theme.spacing(3),
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                }}
            >
                <Grid container item justifyContent={'start'} spacing={2} padding={0}>
                    <Grid item xs={3.3}>
                        <Typography align={'left'}>{this.props.t('messaging:receiver')}</Typography>
                    </Grid>

                    <Grid item>
                        <Typography fontWeight={600}>
                            {this.state.person?.firstname +
                                ' ' +
                                this.state.person?.lastname +
                                ' ' +
                                this.props.t('offer:fromCompany') +
                                ' ' +
                                (this.state.foreignCompany.labelName
                                    ? this.state.foreignCompany.labelName
                                    : this.state.foreignCompany.name)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container justifyContent={'start'} spacing={2} padding={0}>
                    <Grid item xs={3.3}>
                        {' '}
                        <Typography align={'left'}>{this.props.t('offer:offer')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontWeight={600}>
                            {this.props.t(this.state.category.label, {
                                ns: 'ontofood',
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    showPrices(): React.ReactFragment | null {
        if (this.props.type == PreOrderDialogType.PurchaseIntent && this.state.offer) {
            return (
                <>
                    {this.state.offer.graduatedPrices.length > 0 ? (
                        <GraduatedPricesTable offerRef={this.state.offer.links.self} showBasePrice />
                    ) : (
                        <Grid container item>
                            <PricePerUnitComponent offer={this.state.offer} />
                        </Grid>
                    )}
                </>
            );
        } else return null;
    }

    showAmountSelection(): React.ReactFragment | null {
        if (this.state.offer) {
            return (
                <Grid container item>
                    {this.state.offerContainer &&
                    this.state.offerContainer.containerRefs.length >= 1 &&
                    this.state.showContainerSelection ? (
                        <ContainerSelectionComponent
                            withPrice={this.props.type == PreOrderDialogType.PurchaseIntent}
                            offerContainer={this.state.offerContainer}
                            changeHandler={this.containersChangeHandler}
                            goodsAmountCallback={(goodsAmountIn) => {
                                this.setState({
                                    goodsAmount: goodsAmountIn,
                                    amountInput: goodsAmountIn,
                                });
                            }}
                        />
                    ) : (
                        <AmountComponent
                            offer={this.state.offer}
                            usedInContainerSelection={false}
                            isPriceShown={this.props.type == PreOrderDialogType.PurchaseIntent}
                            changeHandler={this.amountInputChangeHandler}
                        />
                    )}
                </Grid>
            );
        } else return null;
    }

    showMandatoryHint(): React.ReactFragment {
        return <Typography fontWeight={500}>{this.props.t('common:mandatory')}</Typography>;
    }

    showMessageTextField(): React.ReactFragment {
        return (
            <Grid item>
                <TextField
                    fullWidth
                    hiddenLabel
                    label={this.props.t(
                        this.props.type == PreOrderDialogType.PriceRequest
                            ? 'offer:priceRequestMessageLabel'
                            : 'offer:purchaseIntentMessageLabel',
                    )}
                    value={this.state.message}
                    multiline
                    rows={4}
                    onChange={(evt) => {
                        this.setState({
                            message: evt.target.value,
                        });
                    }}
                />
            </Grid>
        );
    }

    showHint(): React.ReactFragment {
        return (
            <Grid item>
                <Typography>
                    {this.props.t(
                        this.props.type == PreOrderDialogType.PurchaseIntent
                            ? 'offer:purchaseIntentHint'
                            : 'offer:priceRequestHint',
                    )}
                </Typography>
            </Grid>
        );
    }

    showError(): React.ReactFragment {
        return (
            <Grid item>
                <Typography
                    color={'red'}
                    className={this.props.classes.smallTypography}
                    hidden={!this.state.showSentError}
                >
                    <Trans
                        i18nKey="offer:purchaseIntentError"
                        t={this.props.t}
                        values={{
                            status: this.state.statusCode || this.props.t('common:unknownError'),
                        }}
                        components={[
                            <a
                                key={0}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={'mailto:support@nearbuy-food.de'}
                                style={{ color: 'red' }}
                            />,
                        ]}
                    />
                </Typography>
            </Grid>
        );
    }

    render(): React.ReactElement | null {
        if (!this.state.person || !this.state.ownCompany || !this.state.offer) {
            return null;
        }
        const amountValidationValue = getAmountValidationValue(
            new Amount(this.state.amountInput, this.state.offer.totalAmount.unit),
            this.state.offer.minAmount,
            this.state.offer.totalAmount,
        );
        return (
            <Dialog
                open={this.props.isOpen}
                maxWidth="lg"
                fullWidth
                TransitionComponent={Transition}
                onClose={(event, reason) => {
                    if (event && reason === 'backdropClick') {
                        this.props.onClose();
                    }
                }}
            >
                <DialogTitle>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        {this.props.t(this.getTitleLocale())} {this.showMandatoryHint()}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Container>
                        <Grid container direction={'column'} spacing={2}>
                            {this.showReceiverAndOrderTextFields()}
                            {this.showPrices()}
                            {this.showToggle()}
                            <Grid item>
                                <Divider sx={{ color: 'red', height: '10px' }} />
                            </Grid>
                            {this.showAmountSelection()}
                            <Divider />
                            <Grid item container direction={'column'}>
                                {this.showMessageTextField()}
                                {this.showHint()}
                                {this.showError()}
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button
                            variant={'outlined'}
                            onClick={(): void => {
                                captureWebEvent('marketplace-cancel-purchase-request-button');
                                this.onClose();
                            }}
                        >
                            {this.props.t('dialogs:CANCEL')}
                        </Button>
                        <Button
                            variant={'contained'}
                            className={this.props.classes.sendButton}
                            onClick={() => {
                                if (this.state.message) {
                                    captureWebEvent('marketplace-send-purchase-request-with-message');
                                }
                                captureWebEvent('marketplace-send-purchase-request-button');
                                this.send();
                            }}
                            disabled={amountValidationValue !== true}
                        >
                            {this.props.t('dialogs:SEND')}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['offer', 'common', 'dialogs', 'messaging', 'ontofood'])(
    withStyles(styles, { withTheme: true })(PreOrderDialog),
);
