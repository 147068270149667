import { TileLayer } from 'react-leaflet';
import * as React from 'react';
import { ReactElement } from 'react';

export enum TileLayerType {
    DEFAULT,
    HEAVYMETAL,
}

function getTileLayerForType(tileLayerType: TileLayerType) {
    if (tileLayerType === TileLayerType.HEAVYMETAL) {
        return 'https://{s}.tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png';
    }
    return 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
}

export const NearbuyTileLayer = (properties: { url?: string; id?: string; type?: TileLayerType }): ReactElement => {
    const layerUrl = properties.url || getTileLayerForType(properties.type || TileLayerType.DEFAULT);
    return (
        <TileLayer
            key={layerUrl}
            id={properties.id}
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={layerUrl}
        />
    );
};
