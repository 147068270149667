import { LevelOfProcessing } from 'model/LevelOfProcessing';
import { ContainerType } from 'model/ContainerView';
import { MessageTermsStatus } from 'model/Person';
import { Unit } from 'model/Unit';

export class MetaStaticEntity {
    constructor(
        public data: (LevelOfProcessing | ContainerType | Unit | MessageTermsStatus)[],
        public type: MetaStaticEntityType,
    ) {}
}

export enum MetaStaticEntityType {
    CONTAINER_TYPE,
    UNIT,
    MESSAGE_TERMS_STATUS,
}
