import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';

const _styles = (theme: Theme) =>
    createStyles({
        placeholder: {
            padding: theme.spacing(1),
            display: 'inline',
        },
    });

type PlaceholderProps = WithStyles<typeof _styles>;

class Placeholder extends React.Component<PlaceholderProps> {
    render(): React.ReactElement {
        return (
            <>
                <Box className={this.props.classes.placeholder}>{this.props.children}</Box>
            </>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(Placeholder);
