import { PurchaseIntent } from 'model/PurchaseIntent';
import { Order } from 'model/Order';
import { SettingsKey } from 'model/UserSettings';
import { Location } from 'history';
import {
    TradeTableFilter,
    TradeTableItems,
    TradeTableTab,
    TradeType,
} from 'components/marketplace/tradetable/TradeTableTypes';
import { TFunction } from 'i18next';
import { CompanyStore, PersonStore, ProductStore } from 'store';
import { PriceRequest } from 'model/PriceRequest';
import { sortByDateCreated, sortByValueMap } from 'util/TableHelpers';

export function getPlaceHolderLocaleTag(filter: TradeTableFilter): string {
    switch (filter.tradeType) {
        case TradeType.SALES_REQUESTS:
            return filter.tab == TradeTableTab.ACTIVE
                ? 'sales:emptyTablePlaceholderTextSalesRequestsActive'
                : 'sales:emptyTablePlaceholderTextSalesRequestsTerminated';
        case TradeType.PURCHASE_REQUESTS:
            return filter.tab == TradeTableTab.ACTIVE
                ? 'sales:emptyTablePlaceholderTextPurchaseRequestsActive'
                : 'sales:emptyTablePlaceholderTextPurchaseRequestsTerminated';
        case TradeType.PURCHASES:
            return filter.tab == TradeTableTab.ACTIVE
                ? 'sales:emptyTablePlaceholderTextPurchasesActive'
                : 'sales:emptyTablePlaceholderTextPurchasesTerminated';
        case TradeType.SALES:
            return filter.tab == TradeTableTab.ACTIVE
                ? 'sales:emptyTablePlaceholderTextSalesActive'
                : 'sales:emptyTablePlaceholderTextSalesTerminated';
    }
}

export function getTradeItemType(tradeType: TradeType): 'PreOrder' | 'Order' {
    return tradeType.toString().endsWith('REQUESTS') ? 'PreOrder' : 'Order';
}

export function getActiveNavBarTitleLocaleTag(tradeType: TradeType): string {
    switch (tradeType) {
        case TradeType.PURCHASE_REQUESTS:
            return 'sales:tabTitlePurchaseRequests';
        case TradeType.SALES_REQUESTS:
            return 'sales:tabTitleSalesRequests';
        case TradeType.PURCHASES:
            return 'sales:tabTitlePurchases';
        case TradeType.SALES:
            return 'sales:tabTitleSales';
    }
}

export function getTradeType(location: Location<unknown>): TradeType {
    switch (true) {
        case location.pathname.endsWith('purchaseRequests'):
            return TradeType.PURCHASE_REQUESTS;
        case location.pathname.endsWith('salesRequests'):
            return TradeType.SALES_REQUESTS;
        case location.pathname.endsWith('purchases'):
            return TradeType.PURCHASES;
        case location.pathname.endsWith('sales'):
            return TradeType.SALES;
        default:
            return TradeType.PURCHASE_REQUESTS;
    }
}

export function getTradeRole(
    item: PurchaseIntent | PriceRequest | Order,
    ownCompanyRef: string,
): 'BUYER' | 'SELLER' | 'NONE' {
    return item.links.buyingCompany == ownCompanyRef
        ? 'BUYER'
        : item.links.sellingCompany == ownCompanyRef
          ? 'SELLER'
          : 'NONE';
}

export function getSwitchSettingKey(tradeType: TradeType): SettingsKey {
    switch (tradeType) {
        case TradeType.PURCHASE_REQUESTS:
            return SettingsKey.SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASE_REQUESTS;
        case TradeType.PURCHASES:
            return SettingsKey.SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASES;
        case TradeType.SALES:
            return SettingsKey.SHOW_ONLY_OWN_TRADE_RESULTS_SALES;
        case TradeType.SALES_REQUESTS:
            return SettingsKey.SHOW_ONLY_OWN_TRADE_RESULTS_SALES_REQUESTS;
    }
}

export function getStatusSortOptions(filter: TradeTableFilter): string[] {
    switch (filter.tradeType) {
        case TradeType.PURCHASE_REQUESTS:
        case TradeType.SALES_REQUESTS:
            return filter.tab == 'ACTIVE'
                ? ['PENDING', 'ACCEPTED', 'PRICE_ADDED', 'NONE']
                : ['REJECTED', 'CANCELED', 'CANCELED_BY_BUYER', 'NONE'];
        case TradeType.PURCHASES:
        case TradeType.SALES:
            return ['PENDING', 'INVOICE', 'NONE'];
    }
}

export function getSortSettingKey(filter: TradeTableFilter): SettingsKey {
    switch (filter.tradeType) {
        case TradeType.PURCHASE_REQUESTS:
            return filter.tab == 'ACTIVE'
                ? SettingsKey.SORT_PURCHASE_REQUESTS_ACTIVE
                : SettingsKey.SORT_PURCHASE_REQUESTS_TERMINATED;
        case TradeType.PURCHASES:
            return filter.tab == 'ACTIVE' ? SettingsKey.SORT_PURCHASES_ACTIVE : SettingsKey.SORT_PURCHASES_TERMINATED;
        case TradeType.SALES_REQUESTS:
            return filter.tab == 'ACTIVE'
                ? SettingsKey.SORT_SALES_REQUESTS_ACTIVE
                : SettingsKey.SORT_SALES_REQUESTS_TERMINATED;
        case TradeType.SALES:
            return filter.tab == 'ACTIVE' ? SettingsKey.SORT_SALES_ACTIVE : SettingsKey.SORT_SALES_TERMINATED;
    }
}

export function applyFilter(
    items: TradeTableItems,
    filter: TradeTableFilter,
    ownCompanyRef: string,
): (PurchaseIntent | PriceRequest)[] | Order[] {
    function matchesWindowTab(preOrder?: PurchaseIntent | PriceRequest, order?: Order): boolean {
        switch (filter.tab) {
            case TradeTableTab.ACTIVE:
                return preOrder
                    ? ['PENDING', 'ACCEPTED', 'PRICE_ADDED'].includes(preOrder.status.toString())
                    : order
                      ? !order.isCompleted
                      : false;
            case TradeTableTab.TERMINATED:
                return preOrder
                    ? ['REJECTED', 'CANCELED_BY_SELLER', 'CANCELED_BY_BUYER'].includes(preOrder.status.toString())
                    : order
                      ? order.isCompleted
                      : false;
        }
    }

    function getFilteredPreOrdersByTradeRole(tradeRole: 'BUYER' | 'SELLER'): (PurchaseIntent | PriceRequest)[] {
        const filteredItems = new Array<PurchaseIntent | PriceRequest>();
        items.purchaseIntents
            .filter((pi) => getTradeRole(pi, ownCompanyRef) == tradeRole && matchesWindowTab(pi, undefined))
            .forEach((pi) => filteredItems.push(pi));
        items.priceRequests
            .filter((pr) => getTradeRole(pr, ownCompanyRef) == tradeRole && matchesWindowTab(pr, undefined))
            .forEach((pr) => filteredItems.push(pr));
        return filteredItems;
    }

    function getFilteredOrdersByTradeRole(tradeRole: 'BUYER' | 'SELLER'): Order[] {
        return items.orders.filter(
            (order) => getTradeRole(order, ownCompanyRef) == tradeRole && matchesWindowTab(undefined, order),
        );
    }

    switch (filter.tradeType) {
        case TradeType.PURCHASE_REQUESTS:
            return getFilteredPreOrdersByTradeRole('BUYER');
        case TradeType.SALES_REQUESTS:
            return getFilteredPreOrdersByTradeRole('SELLER');
        case TradeType.PURCHASES:
            return getFilteredOrdersByTradeRole('BUYER');
        case TradeType.SALES:
            return getFilteredOrdersByTradeRole('SELLER');
    }
}

export function sortItems(
    items: (PurchaseIntent | PriceRequest | Order)[],
    sortSettingPrefix: string | undefined,
    sortSettingValue: string | undefined,
    refsToCompanies: Map<string, string>,
    refsToProducts: Map<string, string>,
): (PurchaseIntent | PriceRequest | Order)[] {
    function sortByLocalCompare(
        array: (PurchaseIntent | PriceRequest | Order)[],
    ): (PurchaseIntent | PriceRequest | Order)[] {
        let valueMap: Map<string, string> | undefined = undefined;
        switch (sortSettingPrefix) {
            case 'PRODUCT':
                valueMap = refsToProducts;
                break;
            case 'COMPANY':
                valueMap = refsToCompanies;
                break;
        }
        return sortByValueMap(array, valueMap, sortSettingValue);
    }

    if (sortSettingPrefix && sortSettingValue) {
        switch (sortSettingPrefix) {
            case 'STATUS':
                return sortByDateCreated(items, 'DESC').filter((item) => {
                    if (['CANCELED', 'INVOICE'].includes(sortSettingValue)) {
                        if (sortSettingValue == 'CANCELED') {
                            return (
                                item.links.self.includes('purchase_intents') &&
                                ['CANCELED_BY_BUYER', 'CANCELED_BY_SELLER'].includes(item.status)
                            );
                        } else {
                            return ['INVOICE_ADDED', 'INVOICE_EXCHANGED'].includes(item.status);
                        }
                    } else if (sortSettingValue == 'CANCELED_BY_BUYER') {
                        return item.links.self.includes('price_requests') && item.status.toString() == sortSettingValue;
                    } else {
                        return item.status.toString() == sortSettingValue;
                    }
                });
            case 'PRODUCT':
            case 'COMPANY':
                return sortByLocalCompare(sortByDateCreated(items, 'DESC'));
            default:
                return sortByDateCreated(items, sortSettingValue);
        }
    } else {
        return sortByDateCreated(items, sortSettingValue);
    }
}

export function getItemsToShowSearchMap(
    itemsToShow: (PurchaseIntent | PriceRequest | Order)[],
    isUserBuyerInCurrentLocation: () => boolean,
    isUserSellerInCurrentLocation: () => boolean,
    t: TFunction,
): Map<string, string[]> {
    const itemsToShowMap = new Map();
    itemsToShow.forEach((item) => {
        const buyingCompany = item.links.buyingCompany ? CompanyStore.getOne(item.links.buyingCompany) : undefined;
        const sellingCompany = item.links.sellingCompany ? CompanyStore.getOne(item.links.sellingCompany) : undefined;
        const buyingPerson = item.links.buyingPerson ? PersonStore.getOne(item.links.buyingPerson) : undefined;
        const sellingPerson = item.links.sellingPerson ? PersonStore.getOne(item.links.sellingPerson) : undefined;
        const category = item.links.category ? ProductStore.getOne(item.links.category) : undefined;
        const isUserBuyer = isUserBuyerInCurrentLocation();
        const isUserSeller = isUserSellerInCurrentLocation();

        const searchValues = new Array<string>();
        if (isUserSeller && buyingCompany?.name) searchValues.push(buyingCompany.name);
        if (isUserSeller && buyingCompany?.labelName) searchValues.push(buyingCompany.labelName);
        if (isUserBuyer && sellingCompany?.name) searchValues.push(sellingCompany.name);
        if (isUserBuyer && sellingCompany?.labelName) searchValues.push(sellingCompany.labelName);
        if (buyingPerson?.firstname) searchValues.push(buyingPerson.firstname);
        if (buyingPerson?.lastname) searchValues.push(buyingPerson.lastname);
        if (sellingPerson?.firstname) searchValues.push(sellingPerson.firstname);
        if (sellingPerson?.lastname) searchValues.push(sellingPerson.lastname);
        if (category?.label) searchValues.push(t('ontofood:' + category.label));

        itemsToShowMap.set(item.links.self, searchValues);
    });
    return itemsToShowMap;
}

export function filterMapBySearchString(
    itemsToShow: (PurchaseIntent | PriceRequest | Order)[],
    map: Map<string, string[]>,
    searchString: string,
): (PurchaseIntent | PriceRequest | Order)[] {
    const filteredList = new Array<string>();

    for (const [key, entities] of map.entries()) {
        const filteredEntities = entities.filter((entity) => entity.toLowerCase().includes(searchString.toLowerCase()));

        if (filteredEntities.length > 0) {
            filteredList.push(key);
        }
    }

    return itemsToShow.filter((entity) => filteredList.includes(entity.links.self));
}
