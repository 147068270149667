import { ListItemProps } from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
import React, { ReactElement } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ListItemButton, Theme } from '@mui/material';
import * as H from 'history';
import withStyles from '@mui/styles/withStyles';
import { styles } from 'components/dashboard/Dashlet';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            backgroundColor: theme.palette.primary.lighter,
            text: theme.palette.getContrastText(theme.palette.primary.light),
            marginTop: theme.spacing(0.7),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            borderRadius: 5,
            '&.Mui-selected': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.getContrastText(theme.palette.primary.dark),
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.action.disabledBackground,
            },
            '&:hover, &.Mui-selected:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.getContrastText(theme.palette.primary.main),
            },
        },
    }),
);

interface NavLinkItemProps extends ListItemProps<'a', { button?: true }> {
    to: H.LocationDescriptor | ((location: H.Location) => H.LocationDescriptor);
    replace?: boolean;
}

export const NavLinkItem = (props: NavLinkItemProps): ReactElement => {
    /**
     * Some kind of a hack. The Problem is, that ListItemProps and WithStyles both have classes component,
     * so we need to inject the style directly here..
     * Please don't use if other Possibilities left.. :)
     */
    const listItemStyles = useStyles();
    return <ListItemButton className={`${listItemStyles.listItem}`} component={Link as any} {...props} />;
};

export default withStyles(styles, { withTheme: true })(NavLinkItem);
