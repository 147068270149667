import http from 'api/http';
import PersonService from 'api/PersonService';
import SystemMessageService from 'api/SystemMessageService';
import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import UserInitializer from 'components/initialization/initializers/UserInitializer';
import { Employment } from 'model/Employment';
import { SystemMessageDisplayType } from 'model/SystemMessage';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AddressStore,
    CompanyRolesStore,
    CompanyStore,
    EmploymentStore,
    LocalStorageSettingsStore,
    PersonStore,
    SystemMessageSnackbarStore,
    SystemMessageStore,
} from 'store';
import { SettingsKey } from 'store/LocalStorageSettingsStore';
import UserStore from 'store/UserStore';
import { getUuidFromString } from 'util/helpers';

export class SettingsLoader implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        if (UserStore.isUserExisting() === false) {
            // Person is not created
            return;
        }
        if (UserStore.isUserExisting() === undefined) {
            // there was an error loading the user from Backend
            return;
        }

        const user = PersonStore.getSelected();
        if (!user) {
            return;
        }

        let companyFromStore = false;
        let savedCompanyRef: string | undefined;
        const savedValue = LocalStorageSettingsStore.getOne(SettingsKey.SELECTED_COMPANY);
        if (savedValue) {
            savedCompanyRef = savedValue.value as string | undefined;
            if (savedCompanyRef) {
                companyFromStore = true;
            }
        }

        const personEmployments: Employment[] = await lastValueFrom(
            PersonService.getPersonEmployments(user.links.self).pipe(map((data) => data.employments)),
        );

        const companies: string[] = personEmployments.map((employment: Employment) => employment.links.company);

        let companyRef: string | undefined;
        if (companyFromStore && savedCompanyRef) {
            // if load from store, check if in own employments
            companies?.forEach((ref) => {
                if (savedCompanyRef && ref === savedCompanyRef) {
                    companyRef = ref;
                }
            });
        }

        // if not from store, use the first from own employments
        if (!companyRef) {
            if (!CompanyStore.getSelected()) {
                if (companies.length >= 1) {
                    companyRef = companies[0];
                }
            }
        }

        if (!companyRef) {
            return;
        }

        const personEmployment = personEmployments.filter((employment) => employment.links.company == companyRef).pop();

        if (personEmployment) {
            EmploymentStore.setSelected(personEmployment.links.self);
            const isEmploymentInactive: boolean =
                ['INVITE_PENDING', 'APPLICATION_PENDING', 'TERMINATED'].includes(personEmployment.status.toString()) ??
                false;
            if (isEmploymentInactive) return;
        }

        await firstValueFrom(CompanyStore.loadOne(companyRef));
        CompanyStore.setSelected(companyRef);
        LocalStorageSettingsStore.setOne({
            key: SettingsKey.SELECTED_COMPANY,
            value: companyRef,
        });
        LocalStorageSettingsStore.persist();
        CompanyRolesStore.setSelected(companyRef);
        http.setCurrentCompany(getUuidFromString(companyRef));
        // if there is an address, we select it also, so that we have a point to calculate the distances
        const company = CompanyStore.getSelected();

        if (company && company.address) {
            AddressStore.setSelected(company.address);
            await firstValueFrom(AddressStore.loadOne(company.address));
        }

        // asynchronously load roles
        await firstValueFrom(CompanyRolesStore.loadOne(companyRef));

        if (personEmployment) {
            const systemMessageRefs = await firstValueFrom(SystemMessageService.getSystemMessageList(1000));
            systemMessageRefs.map(async (smRef) => {
                const sm = await firstValueFrom(SystemMessageStore.loadOne(smRef));
                if (sm.systemMessageDisplayType == SystemMessageDisplayType.BANNER) SystemMessageSnackbarStore.add(sm);
            });
        }
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new SettingsLoader(),
    [UserInitializer],
);
