export enum Flavours {
    NEARBUY = 'nearbuy',
    AROMA = 'aroma',
}

export let flavour: Flavours;

export function getFlavour(): Flavours {
    if (flavour === undefined) {
        const flavourString = process.env.REACT_APP_FLAVOUR || 'nearbuy';

        flavour = Object.entries(Flavours).find(([_, value]) => value === flavourString)?.[1] || Flavours.NEARBUY;
    }

    return flavour;
}

export function getFlavouredPublicImport(): string {
    return getFlavour();
}

export function getFlavouredTitle(): string {
    switch (getFlavour()) {
        case Flavours.AROMA:
            return 'AROMA';
        case Flavours.NEARBUY:
        default:
            return 'Nearbuy';
    }
}
