import React, { PureComponent } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Dialog from '@mui/material/Dialog';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DeleteIcon } from 'components/shared/Icons';
import DialogTitle from '@mui/material/DialogTitle';
import { captureWebEvent } from 'util/AnalyticUtils';

const styles = (theme: Theme) =>
    createStyles({
        deleteButton: {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: 'rgb(238, 85, 33, 10%)',
                color: theme.palette.error.main,
                borderColor: theme.palette.error.main,
            },
        },
    });

export interface DeleteDialogProps extends WithStyles<typeof styles>, WithTranslation {
    alternativeDeleteButtonTranslationKey?: string;
    isOpen?: boolean;
    onClose: (remove: boolean) => void;
    quickRemovePhrase?: boolean; // TODO: Couple notice to functionality and decide on when this functionality is wanted. (always?)
    title: string;
    text?: string;
    calledFrom: string;
}

class DeleteDialog extends PureComponent<DeleteDialogProps> {
    render(): React.ReactElement {
        return (
            <Dialog open={!!this.props.isOpen}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    {this.props.quickRemovePhrase && (
                        <Typography>{this.props.t('dialogs:deleteModifierKeyNotice')}</Typography>
                    )}
                    {this.props.text && <Typography>{this.props.text}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'outlined'}
                        onClick={(): void => {
                            captureWebEvent(`delete-dialog-cancel-button-calledFrom-${this.props.calledFrom}`);
                            this.props.onClose(false);
                        }}
                    >
                        {this.props.t('dialogs:CANCEL')}
                    </Button>
                    <Button
                        variant={'outlined'}
                        className={this.props.classes.deleteButton}
                        onClick={(): void => this.props.onClose(true)}
                    >
                        <DeleteIcon />
                        {this.props.alternativeDeleteButtonTranslationKey
                            ? this.props.t('dialogs:' + this.props.alternativeDeleteButtonTranslationKey)
                            : this.props.t('dialogs:DELETE')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation('dialogs')(withStyles(styles, { withTheme: true })(DeleteDialog));
