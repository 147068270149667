/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';

export default class BrowserLanguageDetector implements CustomDetector {
    name = 'BrowserLanguageDetector';

    removeRegion(lang: string): string {
        let index: number = lang.indexOf('-');
        index = index > 0 ? index : lang.length;
        return lang.substring(0, index);
    }

    lookup(_options: DetectorOptions): string | undefined {
        let found = undefined;

        if (typeof navigator !== 'undefined') {
            let lang: string | undefined;
            if (navigator.languages) {
                // chrome only;
                lang = navigator.languages[0];
            }
            if (Object.prototype.hasOwnProperty.bind(navigator, 'userLanguage')) {
                // @ts-ignore
                lang = navigator.userLanguage;
            }
            if (navigator.language) {
                lang = navigator.language;
            }

            if (!lang) {
                return undefined;
            }

            found = this.removeRegion(lang);
        }

        return found;
    }
}
