import http from 'api/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SystemMessageSettings, SystemMessageSettingsWriteView } from 'model/SystemMessageSettings';
import { SystemMessageSettingsStore } from 'store';

class SystemMessageSettingsService {
    public getSettings(): Observable<SystemMessageSettings> {
        return http
            .getAxios()
            .get<SystemMessageSettings>(`system_messages/settings`)
            .pipe(map((response) => response.data));
    }

    public saveSettings(
        systemMessageSettingWriteView: SystemMessageSettingsWriteView,
    ): Observable<SystemMessageSettings> {
        return http
            .getAxios()
            .put(`system_messages/settings`, systemMessageSettingWriteView)
            .pipe(
                map((response) => response.data),
                tap((setting) => SystemMessageSettingsStore.setNewSystemMessageSettings(setting)),
            );
    }
}

export default new SystemMessageSettingsService();
