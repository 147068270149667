import Grid from '@mui/material/Grid';
import purchaseIntentService from 'api/PurchaseIntentService';
import PreOrderInfoCard from 'components/messaging/chat/messagefactory/infocards/PreOrderInfoCard';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import TradeInfoDialog from 'components/messaging/chat/messagefactory/messageitemdata/TradeDataDialog';
import TradeActionButtonsComponent from 'components/messaging/chat/TradeActionButtonsComponent';
import { MessageDirection } from 'model/Message';
import { MessageSentNotification, NotificationInfo } from 'model/NearbuyNotification';
import { PurchaseIntent, PurchaseIntentStatus } from 'model/PurchaseIntent';
import { now } from 'moment';
import * as React from 'react';
import { TFunction } from 'i18next';
import { NotificationStore } from 'store';
import { getQueryStringStringified } from 'util/helpers';
import MessageService from 'api/MessageService';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import { PreOrderTranslationMapping } from 'components/marketplace/PreOrderTranslationMapping';

export function addPurchaseIntentMessageItemData(
    baseData: MessageItemData,
    purchaseIntent: PurchaseIntent,
    t: TFunction,
    mapping: PreOrderTranslationMapping,
    onShowDialog: (value: boolean) => void,
    onShowTradeInfoDialog: (value: boolean) => void,
    isOpen: boolean,
    isTradeInfoOpen: boolean,
): MessageItemData {
    const data: MessageItemData = {
        ...baseData,
        infoBox: {},
    };

    const contextStatus = baseData.message.contextType.toString().split('__')[1];

    const infoCard = (
        <Grid>
            <PreOrderInfoCard
                withShowOfferOrDetailsButton={true}
                hasBackground={true}
                purchaseIntentRef={purchaseIntent.links.self}
                onDecline={onShowDialog}
            />
        </Grid>
    );

    const tradeInfoDialog = (
        <TradeInfoDialog
            key={2}
            isOpen={isTradeInfoOpen}
            title={t('purchaseIntent:details')}
            content={infoCard}
            onClose={onShowTradeInfoDialog}
        />
    );

    const toDetailsButton = (justifyHorizontal: 'start' | 'end') => (
        <TradeActionButtonsComponent
            justifyHorizontal={justifyHorizontal}
            buttons={[
                {
                    text: t('purchaseIntent:toDetails'),
                    type: 'primaryLight',
                    onClick: () => onShowTradeInfoDialog(true),
                    tooltip: t('tooltips:toTradeDetails'),
                },
            ]}
            marginTop={'full'}
        />
    );

    const queryString: Record<string, string> = {};
    queryString['purchase_intent'] = purchaseIntent.links.self;
    const purchaseIntentQueryString = '?' + getQueryStringStringified(queryString);

    switch (contextStatus) {
        case 'PENDING':
            return addPendingData();
        case 'ACCEPTED':
            return addAcceptedData();
        case 'REJECTED':
            return addRejectedData();
        case 'CANCELED_BY_BUYER':
            return addCanceledByBuyerData();
        case 'CANCELED_BY_SELLER':
            return addCanceledBySellerData();
        default:
            return data;
    }

    function addPendingData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                data.infoBox!.topic = t('messaging:purchaseIntentSellerTitle', mapping);
                data.infoBox!.content = t('messaging:purchaseIntentSellerMessage');
                data.infoBox!.card = infoCard;
                data.dialogs = (
                    <ConfirmationDialog
                        isOpen={isOpen}
                        onClose={onShowDialog}
                        title={t('dialogs:rejectPurchaseIntentTitleSeller', mapping)}
                        content={t('dialogs:rejectPurchaseIntentContentSeller')}
                        buttonText1={t('dialogs:rejectPurchaseIntentButton')}
                        buttonAction1={() => updatePurchaseIntent(PurchaseIntentStatus.REJECTED)}
                    />
                );
                break;
            case MessageDirection.OUTBOUND:
                if (baseData.originalReceiver) {
                    mapping.firstNameSeller = baseData.originalReceiver.firstname;
                    mapping.lastNameSeller = baseData.originalReceiver.lastname;
                }
                data.infoBox!.topic = t('messaging:purchaseIntentBuyerTitle', mapping);
                data.infoBox!.content = t('messaging:purchaseIntentBuyerMessage', mapping);
                data.infoBox!.card = infoCard;
                break;
        }
        return data;
    }

    function addAcceptedData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                if (baseData.originalSender) {
                    mapping.firstNameSeller = baseData.originalSender.firstname;
                    mapping.lastNameSeller = baseData.originalSender.lastname;
                }
                data.infoBox!.topic = t('messaging:purchaseIntentAcceptTitle');
                data.infoBox!.card = infoCard;
                data.dialogs = (
                    <ConfirmationDialog
                        isOpen={isOpen}
                        onClose={onShowDialog}
                        title={t('dialogs:cancelPurchaseIntentTitleBuyer', mapping)}
                        content={t('dialogs:cancelPurchaseIntentContentBuyer', mapping)}
                        buttonText1={t('dialogs:cancelPurchaseIntentButton')}
                        buttonAction1={() => updatePurchaseIntent(PurchaseIntentStatus.CANCELED_BY_BUYER)}
                    />
                );
                data.infoBox!.buttons =
                    purchaseIntent.status == 'ACCEPTED' ? (
                        <TradeActionButtonsComponent
                            justifyHorizontal={'start'}
                            buttons={[
                                {
                                    text: t('purchaseIntent:cancelByBuyer'),
                                    type: 'transparent',
                                    tooltip: t('purchaseIntent:cancelNoticeBuyer'),
                                    onClick: () => {
                                        onShowDialog(true);
                                    },
                                },
                                {
                                    text: t('purchaseIntent:buy'),
                                    type: 'secondaryMain',
                                    link: `/order${purchaseIntentQueryString}`,
                                },
                            ]}
                            marginTop={'full'}
                        />
                    ) : undefined;
                break;
            case MessageDirection.OUTBOUND:
                if (baseData.originalReceiver) {
                    mapping.firstNameBuyer = baseData.originalReceiver.firstname;
                    mapping.lastNameBuyer = baseData.originalReceiver.lastname;
                }
                data.infoBox!.topic = t('purchaseIntent:acceptedTopicForSeller');
                data.infoBox!.content = t('purchaseIntent:acceptedContentForSeller');
                data.dialogs = [
                    <ConfirmationDialog
                        key={1}
                        isOpen={isOpen}
                        onClose={onShowDialog}
                        title={t('dialogs:cancelPurchaseIntentTitleSeller', mapping)}
                        content={t('dialogs:cancelPurchaseIntentContentSeller', mapping)}
                        buttonText1={t('dialogs:cancelPurchaseIntentButton')}
                        buttonAction1={() => updatePurchaseIntent(PurchaseIntentStatus.CANCELED_BY_SELLER)}
                    />,
                    tradeInfoDialog,
                ];
                data.infoBox!.buttons = (
                    <TradeActionButtonsComponent
                        justifyHorizontal={'end'}
                        buttons={[
                            {
                                text: t('purchaseIntent:toDetails'),
                                type: 'primaryLight',
                                onClick: () => onShowTradeInfoDialog(true),
                                tooltip: t('tooltips:toTradeDetails'),
                            },
                            purchaseIntent.status == 'ACCEPTED'
                                ? {
                                      text: t('purchaseIntent:cancelBySeller'),
                                      type: 'transparent',
                                      onClick: () => onShowDialog(true),
                                      tooltip: t('purchaseIntent:cancelNotice'),
                                  }
                                : null,
                        ]}
                    />
                );
                break;
        }
        return data;
    }

    function addRejectedData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                if (baseData.originalSender) {
                    mapping.firstNameSeller = baseData.originalSender.firstname;
                    mapping.lastNameSeller = baseData.originalSender.lastname;
                }
                data.infoBox!.topic = t('purchaseIntent:rejectedMessageTopic', mapping);
                data.infoBox!.content = t('purchaseIntent:rejectedMessageBuyerContent', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('start');
                break;

            case MessageDirection.OUTBOUND:
                if (baseData.originalReceiver) {
                    mapping.firstNameBuyer = baseData.originalReceiver.firstname;
                    mapping.lastNameBuyer = baseData.originalReceiver.lastname;
                }
                data.infoBox!.topic = t('purchaseIntent:rejectedMessageTopic', mapping);
                data.infoBox!.content = t('purchaseIntent:rejectedMessageSellerContent', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('start');
                break;
        }
        return data;
    }

    function addCanceledByBuyerData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                if (baseData.originalSender) {
                    mapping.firstNameBuyer = baseData.originalSender.firstname;
                    mapping.lastNameBuyer = baseData.originalSender.lastname;
                }
                data.infoBox!.topic = t('purchaseIntent:canceledByBuyerMessageTopic', mapping);
                data.infoBox!.content = t('purchaseIntent:canceledByBuyerSellerContent', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('end');
                break;
            case MessageDirection.OUTBOUND:
                data.infoBox!.topic = t('purchaseIntent:canceledByBuyerMessageTopic', mapping);
                data.infoBox!.content = t('purchaseIntent:canceledByBuyerBuyerContent');
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('start');
                break;
        }
        return data;
    }

    function addCanceledBySellerData(): MessageItemData {
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                if (baseData.originalSender) {
                    mapping.firstNameSeller = baseData.originalSender.firstname;
                    mapping.lastNameSeller = baseData.originalSender.lastname;
                }
                data.infoBox!.topic = t('purchaseIntent:canceledBySellerMessageBuyerTopic', mapping);
                data.infoBox!.content = t('purchaseIntent:canceledBySellerBuyerContent', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('start');
                break;
            case MessageDirection.OUTBOUND:
                if (baseData.originalReceiver) {
                    mapping.firstNameBuyer = baseData.originalReceiver.firstname;
                    mapping.lastNameBuyer = baseData.originalReceiver.lastname;
                }
                data.infoBox!.topic = t('purchaseIntent:canceledBySellerMessageSellerTopic', mapping);
                data.infoBox!.content = t('purchaseIntent:canceledBySellerSellerContent', mapping);
                data.dialogs = [tradeInfoDialog];
                data.infoBox.buttons = toDetailsButton('end');
                break;
        }
        return data;
    }

    function updatePurchaseIntent(status: PurchaseIntentStatus): void {
        const isBuyerSender = status == 'CANCELED_BY_BUYER';
        purchaseIntentService
            .updatePurchaseIntentStatus(purchaseIntent.links.update, {
                status,
            })
            .subscribe({
                next: () => {
                    NotificationStore.setOne(new NotificationInfo(MessageSentNotification(), now()));
                    onShowDialog(false);
                    MessageService.invalidateDialogByPreOrder(purchaseIntent, isBuyerSender);
                },
            });
    }
}
