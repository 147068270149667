import { AutoSubscribeStore, autoSubscribeWithKey, StoreBase } from 'resub';
import { KeycloakInstance, KeycloakProfile } from 'keycloak-js';

const KEYCLOAK_PROFILE = 'AUTH_PROFILE';
const KEYCLOAK_ID = 'AUTH_ID';
const KEYCLOAK_ADAPTER = 'AUTH_ADAPTER';
const KEYCLOAK_LOGGED_IN = 'AUTH_LOGGED_IN';

@AutoSubscribeStore
class AuthStore extends StoreBase {
    protected keycloakProfile: KeycloakProfile | undefined = undefined;
    protected userId: string | undefined = undefined;
    protected _keycloakAdapter: KeycloakInstance | undefined;
    protected _loggedIn?: boolean = undefined;

    public setAuthProfile(value: KeycloakProfile | undefined): void {
        if (this.keycloakProfile === value) {
            return;
        }
        this.keycloakProfile = value;
        this.trigger(KEYCLOAK_PROFILE);
    }

    @autoSubscribeWithKey(KEYCLOAK_PROFILE)
    public getAuthProfile(): KeycloakProfile | undefined {
        return this.keycloakProfile;
    }

    @autoSubscribeWithKey(KEYCLOAK_PROFILE)
    public getMail(): string | undefined {
        if (this.keycloakProfile) {
            return this.keycloakProfile.email;
        } else return undefined;
    }

    public setId(id: string | undefined): void {
        if (this.userId === id) {
            return;
        }
        this.userId = id;
        this.trigger(KEYCLOAK_ID);
    }

    @autoSubscribeWithKey(KEYCLOAK_ID)
    public getId(): string | undefined {
        if (this.userId) {
            return this.userId;
        } else return undefined;
    }

    public setKeycloakAdapter(adapter: KeycloakInstance): void {
        if (adapter === this._keycloakAdapter) {
            return;
        }
        this._keycloakAdapter = adapter;
        this.trigger(KEYCLOAK_ADAPTER);
    }

    @autoSubscribeWithKey(KEYCLOAK_ADAPTER)
    public getKeycloakAdapter(): Keycloak.KeycloakInstance | undefined {
        return this._keycloakAdapter;
    }

    public setIsLoggedIn(isLoggedIn: boolean): void {
        this._loggedIn = isLoggedIn;
        this.trigger(KEYCLOAK_LOGGED_IN);
    }

    /**
     * @returns authorized, or undefined, if not initialized
     */
    @autoSubscribeWithKey(KEYCLOAK_LOGGED_IN)
    public isLoggedIn(): boolean | undefined {
        return this._loggedIn;
    }
}

export default new AuthStore();
