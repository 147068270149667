import * as React from 'react';
import { DefaultDashletProperties } from 'model/DashletProperties';
import { adminTheme } from 'style/NearbuyStyle';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import Dashlet from 'components/dashboard/Dashlet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLinkItem } from 'components/routing/NavLinkItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { Company } from 'model/Company';
import { ComponentBase } from 'resub';
import { CompanyStore, PersonEmploymentStore } from 'store';
import { EmploymentStatus } from 'model/Employment';
import FeatureToggle from 'components/feature/FeatureToggle';
import { Feature } from 'model/Feature';
import SubscriptionService from 'api/SubscriptionService';

type SubscriptionDashletProps = WithTranslation & DefaultDashletProperties;

interface SubscriptionDashletState {
    company?: Company;
    isManager?: boolean;
    hasPaidSubscriptions: boolean;
}

class SubscriptionDashlet extends ComponentBase<SubscriptionDashletProps, SubscriptionDashletState> {
    protected _buildState(): Partial<SubscriptionDashletState> | undefined {
        const company = CompanyStore.getSelected();
        return {
            company: company,
            isManager: PersonEmploymentStore.getSelected()?.employments.some(
                (employment) =>
                    employment.links.company === company?.links.self && employment.status === EmploymentStatus.MANAGER,
            ),
            hasPaidSubscriptions: SubscriptionService.hasPaidSubscriptions(),
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.company || !this.state.company.verified || !this.state.isManager) return null;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={adminTheme}>
                    <Dashlet {...this.props.dashletProperties}>
                        <List component="nav">
                            <NavLinkItem button color="primary" to={`/subscription`}>
                                <ListItemText>{this.props.t('subscription:manageSubscription')}</ListItemText>
                            </NavLinkItem>
                            {this.state.hasPaidSubscriptions && (
                                <NavLinkItem button color="secondary" to={`/subscription/invoice`}>
                                    <ListItemText>{this.props.t('subscription:invoice')}</ListItemText>
                                </NavLinkItem>
                            )}
                            <FeatureToggle requiredFeatures={[Feature.HAVE_EMPLOYEES]} type={'allOf'}>
                                <NavLinkItem
                                    button
                                    color="primary"
                                    to={`/edit/company/${this.state.company.id}/employees`}
                                >
                                    <ListItemText>{this.props.t('subscription:manageEmployees')}</ListItemText>
                                </NavLinkItem>
                            </FeatureToggle>
                            {this.state.hasPaidSubscriptions && (
                                <NavLinkItem
                                    button
                                    color="primary"
                                    to={`#`} // TODO NEARBUY-2806
                                >
                                    <ListItemText>{this.props.t('subscription:paymentMethod')}</ListItemText>
                                </NavLinkItem>
                            )}
                        </List>
                    </Dashlet>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

export default withTranslation('subscription')(SubscriptionDashlet);
