import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import ShowMoreButton from 'components/shared/ShowMoreButton';
import { Company } from 'model/Company';
import { ImageInfo } from 'model/Image';
import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { CompanyStore } from 'store';
import { theme } from 'style/NearbuyStyle';

interface AboutCompanyProps extends WithTranslation {
    companyRef: string;
    companyImages?: ImageInfo[];
}

interface AboutCompanyState {
    company?: Company;
    isTextLonger: boolean;
    isTextExpanded: boolean;
}

class AboutCompanyComponent extends ComponentBase<AboutCompanyProps, AboutCompanyState> {
    protected _buildState(props: AboutCompanyProps): Partial<AboutCompanyState> | undefined {
        if (!props.companyRef) {
            return undefined;
        }

        return {
            company: CompanyStore.getOne(props.companyRef),
        };
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.state.company != undefined && this.state.company.description != undefined) {
            this.state.company?.description.length > 350 &&
                this.setState({
                    isTextLonger: true,
                });
        }
    }

    truncateCompanyDescriptionText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '… ';
        }
        return text;
    };

    handleShowMoreButtonOnClick() {
        this.setState({
            isTextExpanded: !this.state.isTextExpanded,
        });
    }

    showEmptyStateText(emptyStateText: string): ReactNode {
        return (
            <Typography
                sx={{
                    backgroundColor: theme.palette.grey[200],
                    borderRadius: 3,
                    color: theme.palette.primary.dark,
                    fontWeight: 600,
                    padding: theme.spacing(3),
                    textAlign: 'center',
                }}
            >
                {emptyStateText}
            </Typography>
        );
    }

    render(): React.ReactElement | null {
        return (
            <Grid item container xs={12} spacing={1} direction={'column'}>
                {this.state.company && (
                    <>
                        <Grid item>
                            <Typography
                                data-testid={'titleAboutCompany'}
                                sx={{
                                    fontSize: '22px',
                                    fontWeight: 600,
                                    color: theme.palette.primary.dark,
                                    marginBottom: 1,
                                }}
                            >
                                {' '}
                                {this.props.t('company:aboutCompany')}{' '}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {this.state.company.description ? (
                                <Typography whiteSpace={'pre-line'}>
                                    {!this.state.isTextExpanded
                                        ? this.truncateCompanyDescriptionText(this.state.company.description, 350)
                                        : this.state.company.description}
                                    {this.state.isTextLonger && (
                                        <ShowMoreButton
                                            onClick={this.handleShowMoreButtonOnClick.bind(this)}
                                            type={'text'}
                                        />
                                    )}
                                </Typography>
                            ) : (
                                this.showEmptyStateText(
                                    this.props.companyImages &&
                                        this.props.companyImages.filter((image) => image.ord !== 0).length > 0
                                        ? this.props.t('company:emptyStateDescription')
                                        : this.props.t('company:emptyStateDescriptionNoImages'),
                                )
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

export default withTranslation('company')(AboutCompanyComponent);
