import { Observable } from 'rxjs';
import http from 'api/http';
import { map, tap } from 'rxjs/operators';
import { Order } from 'model/Order';
import { OrderStore } from 'store';

class OrderService {
    public getOrder(orderRef: string): Observable<Order> {
        return http
            .getAxios()
            .get<Order>(`${orderRef}`)
            .pipe(
                map((value) => value.data),
                tap((order) => OrderStore.setOne(order)),
            );
    }

    public getList(dateFrom: string, dateUntil: string): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>('orders', {
                params: {
                    dateFrom,
                    dateUntil: dateUntil,
                },
            })
            .pipe(map((value) => value.data));
    }

    public setCompleted(orderRef: string, isCompleted: boolean): Observable<Order> {
        return http
            .getAxios()
            .put(orderRef, { isCompleted })
            .pipe(
                map((value) => value.data),
                tap((order) => OrderStore.setOne(order)),
            );
    }
}

export default new OrderService();
