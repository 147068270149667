import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';

import { ResultItem } from 'model/ResultItem';
import CompanyService, { CompanySearchQuery } from 'api/CompanyService';
import qs from 'qs';

@AutoSubscribeStore
class DashboardMapSearchResultStore extends StoreBase {
    result: ResultItem[] | undefined;
    lastSearchQuery: CompanySearchQuery | undefined;

    @autoSubscribe
    getResults(searchParams: CompanySearchQuery) {
        if (qs.stringify(this.lastSearchQuery) === qs.stringify(searchParams)) {
            return this.result;
        }

        this.lastSearchQuery = searchParams;
        CompanyService.searchCompaniesAsResults(searchParams).subscribe((searchResults) => {
            this.result = searchResults;
            this.trigger();
        });
    }
}

export default new DashboardMapSearchResultStore();
