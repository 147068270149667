import Grid from '@mui/material/Grid';
import OfferInfoCard from 'components/messaging/chat/messagefactory/infocards/shared/OfferInfoCard';
import { MessageDirection } from 'model/Message';
import * as React from 'react';
import { TFunction } from 'i18next';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import { PreOrderTranslationMapping } from 'components/marketplace/PreOrderTranslationMapping';

export function addRequestMessageItemData(
    baseData: MessageItemData,
    t: TFunction,
    mapping: PreOrderTranslationMapping,
): MessageItemData {
    const data: MessageItemData = {
        ...baseData,
        infoBox: {},
    };

    const infoCard = (
        <Grid>
            <OfferInfoCard withShowOfferOrDetailsButton hasBackground offerRef={baseData.message.links.offer} />
        </Grid>
    );

    function addRequestedOfferMessageData() {
        data.messageBox.content = `${mapping.firstNameSeller} ${mapping.lastNameSeller}:\n${data.message.content}`;
        switch (data.messageDirection) {
            case MessageDirection.INBOUND:
                data.infoBox!.topic = t('messaging:requestedOfferTitleReceiver', mapping);
                data.infoBox!.content = t('messaging:requestedOfferContentReceiver', mapping);
                data.infoBox!.card = infoCard;

                break;
            case MessageDirection.OUTBOUND:
                data.infoBox!.topic = t('messaging:requestedOfferTitleSender', mapping);
                data.infoBox!.content = t('messaging:requestedOfferContentSender', mapping);
                data.infoBox!.card = infoCard;
                break;
        }
    }

    addRequestedOfferMessageData();

    return data;
}
