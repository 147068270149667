import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import { Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { WithTranslation, withTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import ProductService from 'api/ProductService';
import { captureWebEvent } from 'util/AnalyticUtils';

const styles = (theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            whiteSpace: 'pre-line',
        },
        textField: {
            marginTop: theme.spacing(4),
            width: 250,
        },
    });

interface OntofoodProposeDialogProps extends WithStyles<typeof styles>, WithTranslation {
    open?: boolean;
    close?: () => void;
    companyId: string;
    calledFrom: string;
}

interface OntofoodProposeDialogState {
    productName: string;
    successOpen?: boolean;
}

class OntofoodProposeDialog extends React.Component<OntofoodProposeDialogProps, OntofoodProposeDialogState> {
    constructor(props: Readonly<OntofoodProposeDialogProps>) {
        super(props);
        const propsToState = {
            t: props.t,
            classes: props.classes,
            i18n: props.i18n,
            tReady: props.tReady,
        };
        this.state = {
            ...propsToState,
            productName: '',
        };
    }

    closeSuccessInfo(): void {
        this.setState({ successOpen: false }, () => {
            this.props.close?.();
        });
    }

    render(): React.ReactElement {
        return (
            <Dialog open={!!this.props.open}>
                <DialogTitle>{this.props.t('dialogs:proposeDialogTitle')}</DialogTitle>
                <DialogContent className={this.props.classes.content}>
                    <div>{this.props.t('dialogs:proposeDialogText')}</div>
                    <Box>
                        <TextField
                            value={this.state.productName}
                            onChange={(evt) => {
                                this.setState({
                                    productName: evt.target.value,
                                });
                            }}
                            id={this.props.t('common:product')}
                            placeholder={this.props.t('common:product')}
                            className={this.props.classes.textField}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            captureWebEvent(
                                `my_market-add-assortment-cancel-propose-product-calledFrom-${this.props.calledFrom}`,
                            );
                            this.props.close?.();
                        }}
                    >
                        {this.props.t('dialogs:CANCEL')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            captureWebEvent(
                                `my_market-add-assortment-propose-product-calledFrom-${this.props.calledFrom}`,
                            );
                            if (this.state.productName.length > 0) {
                                ProductService.proposeProduct(this.state.productName, this.props.companyId).subscribe();
                                this.setState({ successOpen: true });
                            }
                        }}
                    >
                        {this.props.t('dialogs:PROPOSE')}
                    </Button>
                    <Dialog open={!!this.state.successOpen}>
                        <DialogTitle>
                            {this.props.t('ProposeSuccessTitle', {
                                ns: 'dialogs',
                            })}
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                {this.props.t('ProposeSuccessBody', {
                                    ns: 'dialogs',
                                })}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeSuccessInfo.bind(this)} variant={'outlined'}>
                                {this.props.t('CLOSE')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['dialogs', 'common'])(withStyles(styles, { withTheme: true })(OntofoodProposeDialog));
