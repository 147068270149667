import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { Theme } from '@mui/material';
import { ComponentBase } from 'resub';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import { classNames } from 'util/style-helpers';
import { withTranslation, WithTranslation } from 'react-i18next';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        ellipsisOverflow: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        popover: {
            textAlign: 'left',
            padding: theme.spacing(1),
            cursor: 'pointer',
        },
        infoButton: {
            marginRight: theme.spacing(1),
            minWidth: 10,
        },
        icon: {
            margin: theme.spacing(0.5),
        },
        iconSmall: {
            width: 15,
            height: 15,
        },
        slimButton: {
            padding: 0,
            margin: 0,
            textTransform: 'none',
            justifyContent: 'left',
        },
        block: {
            display: 'flex',
            alignItems: 'center',
        },
        noAction: {
            cursor: 'default',
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.black,
            },
        },
    });

interface PhoneComponentProps extends WithStyles<typeof _styles>, WithTranslation {
    phone: string;
    popover?: boolean;
    companyHint?: boolean;
    calledFrom: string;
}

interface PhoneComponentState {
    anchorEl: HTMLElement | null;
    phoneOpen: boolean;
}

class PhoneComponent extends ComponentBase<PhoneComponentProps, PhoneComponentState> {
    protected _buildState(): Partial<PhoneComponentState> | undefined {
        return undefined;
    }

    render(): React.ReactElement {
        if (this.props.popover) {
            return (
                <>
                    <Popover
                        id={'phone'}
                        anchorEl={this.state.anchorEl}
                        open={this.state.phoneOpen || false}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        onClick={() => captureWebEvent('company-detail-phone-number')}
                        onClose={(): void => this.setState({ phoneOpen: false, anchorEl: null })}
                    >
                        <div className={this.props.classes.popover}>
                            {this.props.companyHint ? (
                                <Typography>{this.props.t('messaging:companyPhone')}</Typography>
                            ) : null}
                            <Typography>{this.props.phone}</Typography>
                        </div>
                    </Popover>
                    <IconButton
                        className={this.props.classes.infoButton}
                        onClick={(event) => {
                            captureWebEvent(`company-detail-phone-button-from-${this.props.calledFrom}`);
                            this.setState({
                                phoneOpen: true,
                                anchorEl: event.currentTarget,
                            });
                        }}
                        size={'small'}
                    >
                        <PhoneIcon className={this.props.classes.icon} />
                    </IconButton>
                </>
            );
        } else {
            return (
                <>
                    <div className={this.props.classes.block}>
                        <IconButton
                            disableRipple
                            className={classNames(this.props.classes.infoButton, this.props.classes.noAction)}
                            size={'small'}
                        >
                            <PhoneIcon className={classNames(this.props.classes.icon, this.props.classes.iconSmall)} />
                        </IconButton>
                        <Typography className={this.props.classes.ellipsisOverflow}>{this.props.phone}</Typography>
                    </div>
                </>
            );
        }
    }
}

export default withTranslation('messaging')(withStyles(_styles, { withTheme: true })(PhoneComponent));
