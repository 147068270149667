import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import { Contact } from 'model/Contact';
import Button from '@mui/material/Button';
import { CompanyStore, ContactsStore, NotificationStore, PersonStore } from 'store';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { WithTranslation, withTranslation } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Person } from 'model/Person';
import MessageService from 'api/MessageService';
import { Company } from 'model/Company';
import { MessageSentNotification, NotificationInfo } from 'model/NearbuyNotification';
import { now } from 'moment';
import { FormValidationHelper, WithFormValidationHelper, withFormValidationHelper } from 'util/FormErrors';
import { throttleDecorator } from 'util/helpers';
import { Transition } from 'util/style-helpers';
import { Theme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        dialog: {
            margin: theme.spacing(0),
            padding: theme.spacing(0),
        },
        textField: {
            flex: 1,
            padding: theme.spacing(0),
        },
        messageField: {
            flex: 1,
        },
        label: {
            alignContent: 'center',
        },
        labelContainer: {
            padding: theme.spacing(0),
        },
        errorContainer: {
            flexDirection: 'row-reverse',
        },
        errorText: {
            color: 'red',
        },
    });

interface MessageWriteDialogProps extends WithStyles<typeof _styles>, WithTranslation {
    isOpen?: boolean;
    onClose: () => void;
    contactRef: string;
    topic?: string;
    offerRef?: string;
    requestRef?: string;
}

interface MessageWriteDialogState extends WithFormValidationHelper {
    contact?: Contact;
    contactPerson?: Person;
    content: string;
    topic: string;
    ownCompany?: Company;
    showErrors?: boolean;
    errorMessage?: string;
    isSending?: boolean;
}

class MessageWriteDialog extends ComponentBase<MessageWriteDialogProps, MessageWriteDialogState> {
    @withFormValidationHelper<MessageWriteDialog>('validate')
    protected _buildState(
        props: MessageWriteDialogProps,
        initialBuild: boolean,
        incomingState?: Readonly<MessageWriteDialogState>,
    ): Partial<MessageWriteDialogState> | undefined {
        const newState: Partial<MessageWriteDialogState> = {};

        if (initialBuild) newState.topic = '';
        newState.ownCompany = CompanyStore.getSelected();
        newState.contact = ContactsStore.getOne(props.contactRef);

        if (!newState.contact) return newState;
        newState.contactPerson = PersonStore.getOne(newState.contact.links.person);

        return newState;
    }

    validate(
        formHelper: FormValidationHelper,
        prevState: MessageWriteDialogState,
        props: MessageWriteDialogProps,
    ): void {
        if (!prevState.content || prevState.content === '') {
            formHelper.setError('content', props.t('messaging:emptyContent'));
        }
    }

    cancel(): void {
        this.setState({
            showErrors: false,
            topic: '',
            content: '',
            errorMessage: undefined,
            isSending: false,
        });
        this.props.onClose();
    }

    @throttleDecorator(500)
    sendMessage(): void {
        if (!this.state.contact || !this.state.ownCompany || !this.state.content || this.state.content === '') {
            this.setState({ showErrors: true });
            return;
        }
        this.setState({ isSending: true });
        MessageService.sendMessageToContact(
            this.state.contact,
            this.state.ownCompany.links.self,
            this.state.content,
            this.props.topic ?? this.state.topic,
            this.props.offerRef,
            this.props.requestRef,
        ).subscribe({
            next: () => {
                NotificationStore.setOne(new NotificationInfo(MessageSentNotification(), now()));
                this.setState({ isSending: false });
                this.props.onClose();
            },
            error: (error) => {
                this.setState({
                    isSending: false,
                    showErrors: true,
                    errorMessage: error.message,
                });
            },
        });
    }

    render(): React.ReactElement | null {
        return (
            <Dialog
                open={this.props.isOpen === true}
                className={this.props.classes.dialog}
                onClose={(event, reason) => {
                    if (event && reason === 'backdropClick') {
                        this.props.onClose();
                    }
                }}
                TransitionComponent={Transition}
            >
                <DialogTitle>{this.props.t('messaging:writeMessage')}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid container item xs={3} className={this.props.classes.label}>
                            <Container className={this.props.classes.labelContainer}>
                                <Typography>{this.props.t('messaging:receiver')}</Typography>
                            </Container>
                        </Grid>
                        <Grid container item xs={9}>
                            <TextField
                                disabled
                                className={this.props.classes.textField}
                                value={this.state.contactPerson?.firstname + ' ' + this.state.contactPerson?.lastname}
                            />
                        </Grid>
                        <Grid container item xs={3} className={this.props.classes.label}>
                            <Container className={this.props.classes.labelContainer}>
                                <Typography>{this.props.t('messaging:subject')}</Typography>
                            </Container>
                        </Grid>
                        <Grid container item xs={9}>
                            <TextField
                                disabled={!!this.props.topic}
                                value={this.props.topic}
                                onChange={(evt) =>
                                    this.setState({
                                        topic: evt.target.value,
                                    })
                                }
                                className={this.props.classes.textField}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <TextField
                                data-testid="contact-textfield"
                                className={this.props.classes.messageField}
                                onChange={(evt) =>
                                    this.setState({
                                        content: evt.target.value,
                                    })
                                }
                                multiline
                                rows={5}
                                {...this.state.formValidationHelper.getFormError('content', !this.state.showErrors)}
                            />
                        </Grid>
                        <Grid container item xs={12} className={this.props.classes.errorContainer}>
                            {this.state.errorMessage && (
                                <Typography className={this.props.classes.errorText}>
                                    {this.state.errorMessage}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            captureWebEvent('cancel-message-to-contact-person');
                            this.cancel();
                        }}
                    >
                        {this.props.t('dialogs:CANCEL')}
                    </Button>
                    <Button
                        disabled={this.state.isSending}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            captureWebEvent('send-message-to-contact-person');
                            this.sendMessage();
                        }}
                    >
                        {this.props.t('dialogs:SEND')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['dialogs', 'messaging'])(withStyles(_styles)(MessageWriteDialog));
