import http from 'api/http';
import { GlobalSystemMessage, GlobalSystemMessageWriteView, SystemMessage } from 'model/SystemMessage';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SystemMessageStore } from 'store';
import { getUuidFromString } from 'util/helpers';

class SystemMessageService {
    public sendTestSystemMessage(companyRef: string) {
        return http
            .getAxios()
            .post<SystemMessage>('system_messages/test/' + getUuidFromString(companyRef))
            .pipe(
                map((response) => response.data),
                tap((systemMessage) => SystemMessageStore.setOne(systemMessage)),
            );
    }

    public getSystemMessageList(limit: number): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>(`system_messages/list/`, {
                params: {
                    limit,
                },
            })
            .pipe(map((response) => response.data));
    }

    public getSystemMessage(systemMessageSelf: string): Observable<SystemMessage> {
        return http
            .getAxios()
            .get<SystemMessage>(systemMessageSelf)
            .pipe(
                map((response) => response.data),
                map((systemMessage) => {
                    systemMessage.createdAt = moment(systemMessage.createdAt);
                    systemMessage.expiresOn = systemMessage.expiresOn ? moment(systemMessage.expiresOn) : undefined;
                    return systemMessage;
                }),
            );
    }

    public sendGlobalSystemMessage(globalSystemMessage: GlobalSystemMessageWriteView): Observable<GlobalSystemMessage> {
        return http
            .getAxios()
            .post<GlobalSystemMessage>('system_messages/', globalSystemMessage)
            .pipe(
                map((response) => response.data),
                tap((systemMessage) => SystemMessageStore.setOne(systemMessage)),
            );
    }

    public deleteGlobalSystemMessage(globalSystemMessage: GlobalSystemMessage): Observable<void> {
        return http
            .getAxios()
            .delete('system_messages/' + getUuidFromString(globalSystemMessage.links.self))
            .pipe(
                map((response) => response.data),
                tap(() => {
                    SystemMessageStore.removeOne(globalSystemMessage);
                }),
            );
    }

    public setSystemMessageIsDeleted(systemMessageRef: string, isDeleted: boolean): Observable<SystemMessage> {
        return http
            .getAxios()
            .put<SystemMessage>(systemMessageRef, { isDeleted })
            .pipe(
                map((resp) => resp.data),
                tap((systemMessage) => SystemMessageStore.setOne(systemMessage)),
            );
    }
}

export default new SystemMessageService();
