import { Button, Card, CardContent, CardHeader, Grid, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import http from 'api/http';
import PreOrderInfoCard from 'components/messaging/chat/messagefactory/infocards/PreOrderInfoCard';
import ChangeAddressDialog from 'components/order/ChangeAddressDialog';
import OrderAddresses from 'components/order/OrderAddresses';
import { AddressType } from 'model/Address';
import { Order, OrderAddress, OrderWriteView } from 'model/Order';
import { PaymentType } from 'model/Payment';
import { Person } from 'model/Person';
import { PurchaseIntent, PurchaseIntentStatus } from 'model/PurchaseIntent';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { AddressStore, CompanyStore, PersonStore, PurchaseIntentStore } from 'store';
import { delay, getParsedQueryString } from 'util/helpers';
import PriceRequestService from 'api/PriceRequestService';
import PurchaseIntentService from 'api/PurchaseIntentService';
import MessageService from 'api/MessageService';
import UpdateManager from 'components/shared/InstantSave/FormUpdateManager';
import NearbuyWindow from 'components/shared/NearbuyWindow';
import SaveLeavePreventionPrompt from 'components/shared/SaveLeavePreventionPrompt';
import { PriceRequestStatus } from 'model/PriceRequest';

const styles = (theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.primary.dark,
            lineHeight: '25px',
            fontSize: '20px',
            fontWeight: 'bold',
        },
        titleMargin: {
            marginBottom: '20px',
            marginLeft: '18px',
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            marginBottom: theme.spacing(2),
            height: '100%',
        },
        paymentContainer: {
            paddingLeft: '0',
            paddingTop: '0',
        },
        smallTypography: {
            fontSize: 'small',
            fontWeight: 'bold',
            paddingBottom: theme.spacing(1),
        },
    });

interface OrderComponentProps extends WithStyles<typeof styles>, RouteComponentProps, WithTranslation {}

interface OrderComponentState {
    invoiceAddress: OrderAddress;
    deliveryAddress: OrderAddress;
    isChangeAddressDialogOpen: boolean;
    purchaseIntent: PurchaseIntent;
    purchaseIntentRef?: string;
    priceRequestRef?: string;
    isLeavePrevented: boolean;
    addressToEdit: OrderAddress;
    isDialogOpen: boolean;
    purchaseIntentStatus?: PurchaseIntentStatus;
    priceRequestStatus?: PriceRequestStatus;
    isErrorMessageVisible: boolean;
    isDisabled: boolean;
    sellingPerson: Person;
}

class OrderComponent extends ComponentBase<OrderComponentProps, OrderComponentState> {
    protected _buildState(
        props: OrderComponentProps,
        initialBuild: boolean,
        incomingState: Readonly<OrderComponentState> | undefined,
    ): Partial<OrderComponentState> | undefined {
        const userCompany = CompanyStore.getSelected();
        const user = PersonStore.getSelected();
        if (!userCompany || !user) {
            return;
        }

        const mainAddress = AddressStore.getOne(userCompany.address) as OrderAddress;
        if (!mainAddress) {
            return;
        }
        if (!mainAddress.name) {
            mainAddress.name = userCompany.name;
        }

        const newState: Partial<OrderComponentState> = { ...incomingState };

        if (!newState.invoiceAddress) {
            const invoiceAddressRef = Array.from(userCompany.addresses).find(
                (value) => value.type == AddressType.INVOICE,
            )?.self;
            newState.invoiceAddress = invoiceAddressRef ? AddressStore.getOne(invoiceAddressRef) : { ...mainAddress };
            if (newState.invoiceAddress) {
                newState.invoiceAddress.type = AddressType.INVOICE;
            }
        }
        if (!newState.deliveryAddress) {
            const deliveryAddressRef = Array.from(userCompany.addresses).find(
                (value) => value.type == AddressType.DELIVERY,
            )?.self;
            newState.deliveryAddress = deliveryAddressRef
                ? AddressStore.getOne(deliveryAddressRef)
                : { ...mainAddress };
            if (newState.deliveryAddress) {
                newState.deliveryAddress.type = AddressType.DELIVERY;
            }
        }

        if (newState.purchaseIntentRef) {
            PurchaseIntentStore.invalidateCache(newState.purchaseIntentRef);
            newState.purchaseIntent = PurchaseIntentStore.getOne(newState.purchaseIntentRef);
            if (newState.purchaseIntent) {
                newState.purchaseIntentStatus = newState.purchaseIntent.status;
            }
        }

        if (newState.purchaseIntent && !newState.sellingPerson) {
            newState.sellingPerson = PersonStore.getOne(newState.purchaseIntent.links.sellingPerson);
        }

        if (initialBuild) {
            newState.isErrorMessageVisible = false;
            newState.isLeavePrevented = true;
            newState.isChangeAddressDialogOpen = false;
            newState.addressToEdit = newState.invoiceAddress;
        }
        return newState;
    }

    componentDidMount() {
        super.componentDidMount();
        const parsedQueryString = getParsedQueryString(this.props.location.search);
        const purchaseIntentRef = parsedQueryString['purchase_intent'] as string | undefined;
        if (purchaseIntentRef && http.isValidRef(purchaseIntentRef)) {
            this.setState({
                purchaseIntentRef: purchaseIntentRef,
            });
        }
        const priceRequestRef = parsedQueryString['price_request'] as string | undefined;
        if (priceRequestRef && http.isValidRef(priceRequestRef)) {
            this.setState({
                priceRequestRef: priceRequestRef,
            });
        }
    }

    onShowDialogCallBack = (value: boolean) => {
        this.setState({
            isDialogOpen: value,
        });
    };

    showPurchaseDetails() {
        return (
            <Grid item>
                <Card className={this.props.classes.card}>
                    <CardHeader
                        title={
                            <Typography className={this.props.classes.title}>
                                {this.props.t('payment:orderDetails')}
                            </Typography>
                        }
                    />
                    <CardContent className={this.props.classes.paymentContainer}>
                        <PreOrderInfoCard
                            withShowOfferOrDetailsButton={false}
                            hasBackground={false}
                            purchaseIntentRef={this.state.purchaseIntentRef && this.state.purchaseIntentRef}
                            priceRequestRef={this.state.priceRequestRef && this.state.priceRequestRef}
                            onDecline={this.onShowDialogCallBack}
                        />
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    changeAddress = (address: OrderAddress) => {
        if (address.type === AddressType.DELIVERY) {
            this.setState({
                deliveryAddress: address,
            });
        } else if (address.type === AddressType.INVOICE) {
            this.setState({
                invoiceAddress: address,
            });
        }
    };

    editClick = (address: OrderAddress) => {
        this.setState({
            addressToEdit: address,
            isChangeAddressDialogOpen: true,
        });
    };

    showChangeAddressDialog(): React.ReactFragment | undefined {
        if (!this.state.isChangeAddressDialogOpen) return;
        return (
            <ChangeAddressDialog
                isOpen={this.state.isChangeAddressDialogOpen}
                onClose={async () => {
                    await delay(200);
                    this.setState({
                        isChangeAddressDialogOpen: false,
                    });
                }}
                changeAddress={this.changeAddress}
                addressToEdit={this.state.addressToEdit}
            />
        );
    }

    showError(): React.ReactFragment | undefined {
        if (!this.state.sellingPerson) return;
        return (
            <Grid item>
                <Typography
                    color={'red'}
                    className={this.props.classes.smallTypography}
                    hidden={!this.state.isErrorMessageVisible}
                >
                    {this.props.t('order:canceledErrorMessage', {
                        firstNameSeller: this.state.sellingPerson.firstname,
                        lastNameSeller: this.state.sellingPerson.lastname,
                    })}
                </Typography>
            </Grid>
        );
    }

    checkStatus(): void {
        if (
            (this.state.purchaseIntentStatus && this.state.purchaseIntentStatus != PurchaseIntentStatus.ACCEPTED) ||
            (this.state.priceRequestStatus && this.state.priceRequestStatus != PriceRequestStatus.PRICE_ADDED)
        ) {
            this.setState({
                isErrorMessageVisible: true,
                isDisabled: true,
            });
        } else {
            this.sendOrder();
        }
    }

    sendOrder(): Order | undefined {
        const orderWriteView = new OrderWriteView(
            { ...this.state.invoiceAddress },
            { ...this.state.deliveryAddress },
            PaymentType.ON_ACCOUNT,
        );

        if (this.state.priceRequestRef) {
            PriceRequestService.sendOrder(orderWriteView, this.state.priceRequestRef).subscribe({
                next: (value: Order) => {
                    MessageService.invalidateDialogByOrder(value, true);
                    history.back();
                },
            });
        } else if (this.state.purchaseIntentRef) {
            PurchaseIntentService.sendOrder(orderWriteView, this.state.purchaseIntentRef).subscribe({
                next: (value) => {
                    MessageService.invalidateDialogByOrder(value, true);
                    history.back();
                },
            });
        } else return undefined;
    }

    showTitle(): React.ReactFragment {
        return (
            <Grid item>
                <Typography
                    className={`${this.props.classes.title}
                            ${this.props.classes.titleMargin}`}
                >
                    {this.props.t('payment:title')}
                </Typography>
            </Grid>
        );
    }

    showAddresses(): React.ReactFragment | undefined {
        if (!this.state.invoiceAddress || !this.state.deliveryAddress) {
            return;
        }
        return (
            <Grid item>
                <Card className={this.props.classes.card}>
                    <CardContent>
                        <OrderAddresses
                            deliveryAddress={this.state.deliveryAddress}
                            invoiceAddress={this.state.invoiceAddress}
                            direction={'row'}
                            onEditClick={this.editClick}
                        />
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    showPaymentDetails(): React.ReactFragment {
        return (
            <Grid item>
                <Card className={this.props.classes.card}>
                    <CardHeader
                        title={
                            <Typography className={this.props.classes.title}>
                                {this.props.t('payment:paymentDetails')}
                            </Typography>
                        }
                    />
                    <CardContent>
                        <Typography fontWeight={'bold'}>{this.props.t('payment:paymentOnAccount')}</Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    showButtons(): React.ReactFragment {
        return (
            <Grid container direction={'row'} spacing={2}>
                <Grid item>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                            this.setState({ isLeavePrevented: false }, () => {
                                this.checkStatus();
                            })
                        }
                        disabled={this.state.isDisabled}
                    >
                        {this.props.t('payment:BUY')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        onClick={() => this.setState({ isLeavePrevented: false }, () => history.back())}
                    >
                        {this.props.t('dialogs:CANCEL')}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    showSaveLeavePrevention(): React.ReactFragment {
        return (
            <SaveLeavePreventionPrompt
                prevent={this.state.isLeavePrevented}
                title={this.props.t('payment:leaveOrderPreventionDialogHeading')}
                description={this.props.t('payment:leaveOrderPreventionDialogContent')}
                buttons={[
                    {
                        text: this.props.t('payment:discard'),
                        action: () => UpdateManager.clearUpdates(),
                    },
                    {
                        text: this.props.t('payment:backToOrder'),
                        variant: 'contained',
                    },
                ]}
            />
        );
    }

    render(): React.ReactElement | null {
        return (
            <NearbuyWindow title={this.props.t('payment:paymentView')} maxWidth="xlg">
                {this.showChangeAddressDialog()}
                {this.showSaveLeavePrevention()}

                <Grid container item direction={'column'} xs={8}>
                    {this.showTitle()}
                    {this.showPurchaseDetails()}
                    {this.showAddresses()}
                    {this.showPaymentDetails()}
                    {this.showError()}
                    {this.showButtons()}
                </Grid>
            </NearbuyWindow>
        );
    }
}

export default withTranslation(['payment', 'dialogs', 'order'])(
    withStyles(styles, { withTheme: true })(withRouter(OrderComponent)),
);
