import { WithDateCreated, WithDateModified, WithSelfLink } from 'util/Types';

export function getSortSettingPrefixAndValue(sortSetting: string | null | undefined): (string | undefined)[] {
    const sortSettingSplit = sortSetting?.split('_');
    return [
        sortSettingSplit ? sortSettingSplit[0] : undefined,
        sortSettingSplit && sortSettingSplit.length > 1 ? sortSettingSplit.slice(1).join('_') : undefined,
    ];
}

/** Sorts by localeCompare on values retrieved from the map by using the self link as key.<p>
 *  Note: every value except 'ASC' for direction will lead to descending sort. */
export function sortByValueMap<T extends WithSelfLink>(
    array: T[],
    valueMap: Map<string, string> | undefined,
    direction: string | undefined,
) {
    return array.sort((a, b) => {
        const stringA = valueMap?.get(a.links.self);
        const stringB = valueMap?.get(b.links.self);
        if (stringA && stringB) {
            return stringA.localeCompare(stringB) * (direction == 'ASC' ? 1 : -1);
        } else return 0;
    });
}
/** Sorts by dateCreated.<p>
 *  Note: every value except 'ASC' for direction will lead to descending sort. */
export function sortByDateCreated<T extends WithDateCreated>(array: T[], direction: string | undefined): T[] {
    return array.sort((a, b) =>
        a.dateCreated && b.dateCreated ? (a.dateCreated > b.dateCreated ? -1 : 1) * (direction == 'ASC' ? -1 : 1) : 0,
    );
}

/** Sorts by dateModified.<p>
 *  Note: every value except 'ASC' for direction will lead to descending sort. */
export function sortByDateModified<T extends WithDateModified>(array: T[], direction: string | undefined): T[] {
    return array.sort((a, b) =>
        a.dateModified && b.dateModified
            ? (a.dateModified > b.dateModified ? -1 : 1) * (direction == 'ASC' ? -1 : 1)
            : 0,
    );
}
