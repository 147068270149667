import { PropTypes, Theme } from '@mui/material';
import Chip from '@mui/material/Chip';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Product } from 'model/Product';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { ProductStore } from 'store';
import { classNames } from 'util/style-helpers';

const styles = (theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(1),
            marginLeft: theme.spacing(0),
            fontWeight: 500,
        },
        mw100: {
            maxWidth: '100%',
        },
        assortmentChip: {
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.secondary.dark}`,
            color: theme.palette.getContrastText(theme.palette.secondary.main),
        },
        demandChip: {
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.getContrastText(theme.palette.primary.light),
        },
    });

interface ProductChipProperties extends WithStyles<typeof styles>, WithTranslation {
    productRef: string;
    type: 'company' | 'market';
    style?: React.CSSProperties;
    color: PropTypes.Color;
}

interface ProductChipState {
    product?: Product;
}

class ProductChip extends ComponentBase<ProductChipProperties, ProductChipState> {
    protected _buildState(props: ProductChipProperties): Partial<ProductChipState> | undefined {
        return {
            product: ProductStore.getOne(props.productRef),
        };
    }

    render(): React.ReactElement | null {
        if (this.state.product) {
            if (
                (this.props.type === 'market' && this.state.product.market) ||
                (this.props.type === 'company' && this.state.product.company)
            ) {
                return (
                    <Chip
                        label={this.props.t('ontofood:' + this.state.product.label)}
                        style={this.props.style}
                        color={this.props.color as 'primary' | 'secondary' | 'default'}
                        className={classNames(
                            this.props.classes.chip,
                            this.props.classes.mw100,
                            this.props.color === 'secondary'
                                ? this.props.classes.assortmentChip
                                : this.props.classes.demandChip,
                        )}
                    />
                );
            }
        }
        return null;
    }
}

export default withTranslation('ontofood')(withStyles(styles, { withTheme: true })(ProductChip));
