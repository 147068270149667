import * as React from 'react';
import { ComponentBase } from 'resub';
import { ExpiringOffersSystemMessage } from 'model/SystemMessage';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ExpiringOffersSystemMessageItemProps extends RouteComponentProps {
    systemMessage: ExpiringOffersSystemMessage;
}

interface ExpiringOffersMapping extends Record<string, string> {
    expiringOffersCount: string;
}

interface ExpiringOffersSystemMessageItemState {
    mapping?: ExpiringOffersMapping;
}

class ExpiringOffersSystemMessageItem extends ComponentBase<
    ExpiringOffersSystemMessageItemProps,
    ExpiringOffersSystemMessageItemState
> {
    protected _buildState(
        props: ExpiringOffersSystemMessageItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<ExpiringOffersSystemMessageItemState>,
    ): Partial<ExpiringOffersSystemMessageItemState> | undefined {
        return {
            mapping: {
                expiringOffersCount: props.systemMessage.expiringOffersCount.toString(),
            },
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping) return null;

        return (
            <SystemMessageItem
                onClick={() => this.props.history.push('/marketplace/offers')}
                systemMessage={this.props.systemMessage}
                mapping={this.state.mapping}
            />
        );
    }
}

export default withRouter(ExpiringOffersSystemMessageItem);
