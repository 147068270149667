import * as React from 'react';
import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { MessageStore, MessagesUnseenStore } from 'store';
import { NotificationInfo } from 'model/NearbuyNotification';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogStore } from 'store/DialogStore';
import { getDialogId } from 'model/Dialog';
import { Message } from 'model/Message';
import { theme } from 'style/NearbuyStyle';

const _styles = (theme: Theme) =>
    createStyles({
        infoLink: {
            textDecoration: 'none',
        },
        bold: {
            fontWeight: 'bold',
            color: theme.palette.primary.light,
        },
    });

interface MessageSnackBarActionProps extends WithStyles<typeof _styles>, RouteComponentProps, WithTranslation {
    messageInfo: NotificationInfo;
    handleClose: () => void;
}

interface MessageSnackBarActionState {
    message: Message;
}

class MessageSnackBarAction extends ComponentBase<MessageSnackBarActionProps, MessageSnackBarActionState> {
    componentDidMount() {
        super.componentDidMount();
        MessageStore.loadOne(this.props.messageInfo.notification.value).subscribe((message) => {
            this.setState({ message });
            MessagesUnseenStore.loadOne(message.links.receiverCompany);
            if (message.links.senderPerson)
                MessageStore.invalidateSearch({
                    otherPersonRef: message.links.senderPerson,
                    otherCompanyRef: message.links.senderCompany,
                });
            DialogStore.loadOne(
                getDialogId({
                    otherPersonRef: this.state.message.links.senderPerson,
                    otherCompanyRef: this.state.message.links.senderCompany,
                    ownPersonRef: this.state.message.links.receiverPerson,
                    ownCompanyRef: this.state.message.links.receiverCompany,
                }),
            );
            DialogStore.invalidateSearch({
                ownPersonRef: message.links.receiverPerson,
                ownCompanyRef: message.links.receiverCompany,
            });
        });
    }

    navigateToChat(): void {
        DialogStore.setSelected(
            getDialogId({
                otherPersonRef: this.state.message.links.senderPerson,
                otherCompanyRef: this.state.message.links.senderCompany,
                ownPersonRef: this.state.message.links.receiverPerson,
                ownCompanyRef: this.state.message.links.receiverCompany,
            }),
        );
        this.props.history.push('/messages');
    }

    render(): React.ReactElement {
        return (
            <div>
                <Button color="primary" onClick={() => this.navigateToChat()}>
                    <Typography className={this.props.classes.bold}>{this.props.t('notification:read')}</Typography>
                </Button>
                <IconButton
                    sx={{
                        color: theme.palette.getContrastText(theme.palette.error.main),
                        backgroundColor: 'transparent',
                        '&:hover': {
                            color: theme.palette.common.black,
                            backgroundColor: 'transparent',
                        },
                    }}
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.props.handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }
}

export default withRouter(
    withTranslation(['notification'])(withStyles(_styles, { withTheme: true })(MessageSnackBarAction)),
);
