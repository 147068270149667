import * as React from 'react';
import { ComponentBase } from 'resub';
import { RequestStore } from 'store';
import { Request } from 'model/Request';
import RequestReferenceComponent from 'components/dashboard/marketplace/request/RequestReferenceComponent';
import RequestFullDetailDialog from 'components/search/result-items/requests/RequestFullDetailDialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
import ExpiredProductDialog from 'components/search/result-items/ExpiredProductDialog';
import { withTranslation, WithTranslation } from 'react-i18next';

const _styles = (theme: Theme) =>
    createStyles({
        content: {
            width: '85%',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    });

interface RequestReferenceProps extends WithTranslation, WithStyles<typeof _styles> {
    requestRef: string;
    text?: string;
}

interface RequestReferenceState {
    request?: Request;
    isDialogOpen?: boolean;
}

class RequestReference extends ComponentBase<RequestReferenceProps, RequestReferenceState> {
    protected _buildState(
        props: RequestReferenceProps,
        initialBuild: boolean,
        incomingState?: Readonly<RequestReferenceState>,
    ): Partial<RequestReferenceState> | undefined {
        return {
            request: RequestStore.getOne(props.requestRef),
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.request) {
            return null;
        }
        return (
            <>
                <RequestFullDetailDialog
                    requestRef={this.state.request.links.self}
                    isOpen={this.state.isDialogOpen || false}
                    onClose={() => {
                        this.setState({ isDialogOpen: false });
                    }}
                />
                <ExpiredProductDialog
                    expiredProductTitle={this.props.t('dialogs:expiredProductTitleRequest')}
                    isOpen={(this.state.isDialogOpen && !this.state.request.active) || false}
                    onClose={() => {
                        this.setState({ isDialogOpen: false });
                    }}
                />
                <div className={this.props.classes.content}>
                    <RequestReferenceComponent
                        requestRef={this.props.requestRef}
                        onClick={() => this.setState({ isDialogOpen: true })}
                    />
                </div>
            </>
        );
    }
}

export default withTranslation(['system', 'dialogs'])(withStyles(_styles)(RequestReference));
