import { Snackbar } from '@mui/material';
import List from '@mui/material/List';
import { getSystemMessage } from 'components/systemmessage/SystemMessageSnackbarFactory';
import { SystemMessage } from 'model/SystemMessage';
import * as React from 'react';
import { ComponentBase } from 'resub';
import { CompanyStore, PersonEmploymentStore, SystemMessageSnackbarStore } from 'store';

interface SystemMessageSnackbarState {
    systemMessages?: readonly SystemMessage[];
}

class SystemMessageSnackbarComponent extends ComponentBase<object, SystemMessageSnackbarState> {
    protected _buildState(
        props: object,
        initialBuild: boolean,
        incomingState: Readonly<SystemMessageSnackbarState>,
    ): Partial<SystemMessageSnackbarState> | undefined {
        const employments = PersonEmploymentStore.getSelected();
        const company = CompanyStore.getSelected();
        const employment = employments?.employments.find(
            (employment) => employment.links.company == company?.links.self,
        );

        if (employment && employments && company) {
            const systemMessages = SystemMessageSnackbarStore.getAll();
            return {
                systemMessages,
            };
        }
    }

    render(): React.ReactElement {
        return (
            <Snackbar open>
                <List sx={{ padding: 0 }}>
                    {this.state.systemMessages?.map((systemMessage) => {
                        return getSystemMessage(systemMessage);
                    })}
                </List>
            </Snackbar>
        );
    }
}

export default SystemMessageSnackbarComponent;
