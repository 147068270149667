import { createDynamicLoadingStore } from 'resub-entity';
import UserSettingsService from 'api/UserSettingsService';
import { EmptySetting, SettingsKey, UserSetting } from 'model/UserSettings';

/**
 * If the user logs out, the application does a complete reload, so
 * we only have to store data for one user.
 */
const UserSettingsStore = createDynamicLoadingStore<UserSetting | EmptySetting, SettingsKey>({
    selectIdFunction: (entity) => entity.key,
    loadFunction: (id) => UserSettingsService.getSetting(id),
});

export default UserSettingsStore;
