import { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';
import { PersonStore } from 'store';
import { Person } from 'model/Person';
import PersonService from 'api/PersonService';

export default class UserLanguageDetector implements CustomDetector {
    name = 'UserLanguageDetector';

    cacheUserLanguage(lng: string, _options: DetectorOptions): void {
        const currentPerson: Readonly<Person> | undefined = PersonStore.getSelected();

        if (currentPerson && currentPerson?.links) {
            if (currentPerson?.language !== lng) {
                PersonService.updatePerson(currentPerson.links.self, {
                    firstname: currentPerson.firstname,
                    lastname: currentPerson.lastname,
                    language: lng,
                }).subscribe();
            }
        }
    }

    lookup(_options: DetectorOptions): string | string[] | undefined {
        const currentPerson: Readonly<Person> | undefined = PersonStore.getSelected();
        return currentPerson?.language;
    }
}
