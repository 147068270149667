import * as React from 'react';
import { ComponentBase } from 'resub';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { MetaStore } from 'store';
import { MetaStaticEntityType } from 'model/Meta';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { theme } from 'style/NearbuyStyle';
import { defaultTradeUnitTypes, getUnitFromString, Unit, UnitType } from 'model/Unit';

const _styles = (theme: Theme) =>
    createStyles({
        textField: {
            color: theme.palette.getContrastText(theme.palette.primary.light),
            margin: 0,
        },
        textFieldFullWidth: {
            color: theme.palette.getContrastText(theme.palette.primary.light),
            flexGrow: 1,
        },
        unitSelect: {
            flexGrow: 1,
        },
        errorField: {
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.error.main,
                },
            },
        },
    });

interface UnitSelectComponentProps extends WithStyles<typeof _styles>, WithTranslation {
    isRequired?: boolean;
    selectedUnit?: Unit;
    onChange: (unit: Unit) => void;
    typeToLimitTo?: UnitType;
    conversationFactorToLimitTo?: number;
    isReadOnly?: boolean;
    isDuplicate?: boolean;
}

interface UnitSelectComponentState {
    units: Unit[];
}

class UnitSelectComponent extends ComponentBase<UnitSelectComponentProps, UnitSelectComponentState> {
    protected _buildState(
        props: UnitSelectComponentProps,
        initialBuild: boolean,
        incomingState: Readonly<UnitSelectComponentState> | undefined,
    ): Partial<UnitSelectComponentState> | undefined {
        const newState: Partial<UnitSelectComponentState> = {};
        newState.units = MetaStore.getOne(MetaStaticEntityType.UNIT)?.data as Unit[];

        if (newState.units && (!props.typeToLimitTo || defaultTradeUnitTypes.includes(props.typeToLimitTo))) {
            newState.units = newState.units.filter((unit: Unit) => defaultTradeUnitTypes.includes(unit.unitType));
        }

        if (newState.units) {
            if (props.typeToLimitTo) {
                newState.units = newState.units.filter((unit: Unit) =>
                    props.typeToLimitTo ? unit.unitType == props.typeToLimitTo : true,
                );
            }
            if (props.conversationFactorToLimitTo) {
                newState.units = newState.units.filter((unit) =>
                    props.conversationFactorToLimitTo
                        ? unit.conversionFactor <= props.conversationFactorToLimitTo
                        : true,
                );
            }
        }
        return newState;
    }

    render(): React.ReactElement | undefined {
        return (
            <Grid container item>
                <TextField
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: this.props.isDuplicate ? theme.palette.error.main : undefined,
                            },
                        },
                    }}
                    select
                    value={this.props.selectedUnit?.unit ?? ''}
                    label={this.props.t('offer:unitOfMeasurement')}
                    color="primary"
                    onChange={(event): void => {
                        const unit = getUnitFromString(event.target.value);
                        if (unit) this.props.onChange(unit);
                    }}
                    className={this.props.isDuplicate ? this.props.classes.errorField : this.props.classes.unitSelect}
                    required={this.props.isRequired}
                    disabled={this.props.isReadOnly || this.state.units.length === 1}
                >
                    {this.state.units &&
                        this.state.units.map((unit: Unit) => {
                            return (
                                <MenuItem key={unit.unit} value={unit.unit}>
                                    {this.props.t('units:' + unit.unit + 'Plural')}
                                </MenuItem>
                            );
                        })}
                </TextField>
            </Grid>
        );
    }
}

export default withTranslation(['units', 'offer'])(withStyles(_styles, { withTheme: true })(UnitSelectComponent));
