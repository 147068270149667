import * as React from 'react';
import { ComponentBase } from 'resub';
import { NewPersonSystemMessage } from 'model/SystemMessage';
import { Person } from 'model/Person';
import { CompanyStore, PersonEmploymentStore, PersonStore, SystemMessageStore } from 'store';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { Employment } from 'model/Employment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getUuidFromString } from 'util/helpers';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Flavours } from 'style/flavour-helper';

interface NewPersonSystemMessageItemProps extends RouteComponentProps, WithTranslation {
    systemMessage: NewPersonSystemMessage;
}

interface NewPersonSystemMessageItemState {
    mapping?: NewPersonSystemMessageItemMapping;
    newPerson?: Person;
    personEmployment?: Employment;
    errorState?: string;
}

interface NewPersonSystemMessageItemMapping extends Record<string, string> {
    firstName: string;
    lastName: string;
    email: string;
    tenant: string;
}

class NewPersonSystemMessageItem extends ComponentBase<
    NewPersonSystemMessageItemProps,
    NewPersonSystemMessageItemState
> {
    protected _buildState(
        props: NewPersonSystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<NewPersonSystemMessageItemState>,
    ): Partial<NewPersonSystemMessageItemState> | undefined {
        const newPerson = PersonStore.getOne(props.systemMessage.links.person);
        const employment = PersonEmploymentStore.getOne(props.systemMessage.links.person);
        let newState: Partial<NewPersonSystemMessageItemState> = {};

        if (newPerson) {
            newState = {
                ...newState,
                newPerson: newPerson,
                mapping: {
                    firstName: newPerson.firstname,
                    lastName: newPerson.lastname,
                    email: newPerson.email,
                    tenant: this.props.t(`tenant:${Flavours.NEARBUY}`),
                },
            };
        }

        if (employment) {
            newState.personEmployment = employment.employments[0];
            if (newState.personEmployment) {
                const company = CompanyStore.getOne(newState.personEmployment?.links.company);
                if (newState.mapping && company) newState.mapping.tenant = this.props.t(`tenant:${company.tenant}`);
            } else {
                SystemMessageStore.removeOne(this.props.systemMessage);
            }
        }

        return newState;
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping) return null;

        return (
            <SystemMessageItem
                onClick={() => {
                    if (this.state.personEmployment) {
                        this.props.history.push(
                            '/company/' + getUuidFromString(this.state.personEmployment.links.company) + '/detail',
                        );
                    } else {
                        this.setState({
                            errorState: 'error_companyNotExisting',
                        });
                    }
                }}
                systemMessage={this.props.systemMessage}
                mapping={this.state.mapping}
                errorState={this.state.errorState}
            />
        );
    }
}

export default withTranslation(['tenant'])(withRouter(NewPersonSystemMessageItem));
