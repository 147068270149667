import { PurchaseIntent, PurchaseIntentStatusWriteView, PurchaseIntentWriteView } from 'model/PurchaseIntent';
import { Observable } from 'rxjs';
import http from 'api/http';
import { map, tap } from 'rxjs/operators';
import { OrderStore, PurchaseIntentStore } from 'store';
import { Order, OrderWriteView } from 'model/Order';

class PurchaseIntentService {
    public sendPurchaseIntent(purchaseIntentWrite: PurchaseIntentWriteView): Observable<PurchaseIntent> {
        return http
            .getAxios()
            .post<PurchaseIntent>('/purchase_intents/', purchaseIntentWrite)
            .pipe(
                map((value) => value.data),
                tap((intent) => PurchaseIntentStore.setOne(intent)),
            );
    }

    public getPurchaseIntent(purchaseIntentRef: string): Observable<PurchaseIntent> {
        return http
            .getAxios()
            .get(purchaseIntentRef)
            .pipe(map((value) => value.data));
    }

    public updatePurchaseIntentStatus(
        purchaseIntentUpdateRef: string,
        purchaseIntentStatus: PurchaseIntentStatusWriteView,
    ): Observable<PurchaseIntent> {
        return http
            .getAxios()
            .put(purchaseIntentUpdateRef, purchaseIntentStatus)
            .pipe(
                map((value) => value.data),
                tap((intent) => PurchaseIntentStore.setOne(intent)),
            );
    }

    public sendOrder(orderWriteView: OrderWriteView, purchaseIntentRef: string): Observable<Order> {
        return http
            .getAxios()
            .post<Order>(purchaseIntentRef + '/orders', orderWriteView)
            .pipe(
                map((response) => response.data),
                tap((order) => OrderStore.setOne(order)),
            );
    }

    public getList(dateFrom: string, dateUntil: string): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>('purchase_intents', {
                params: {
                    dateFrom,
                    dateUntil: dateUntil,
                },
            })
            .pipe(map((value) => value.data));
    }
}

export default new PurchaseIntentService();
