import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import kc, { KeycloakInstance, KeycloakProfile } from 'keycloak-js';
import React, { ReactElement, ReactNode } from 'react';
import { ComponentBase } from 'resub';
import http from 'api/http';
import AuthStore from 'store/AuthStore';
import NavStore from 'store/NavStore';
import { InitializationPhase } from 'components/initialization';
import InitializationManager from 'components/initialization/InitializationManager';
import { NearbuyLoadingIndicator } from 'components/shared/NearbuyLoadingIndicator';

const styles = () =>
    createStyles({
        circularProgress: {
            margin: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
        center: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        },
    });

interface KeycloakProps extends WithStyles<typeof styles> {
    children: ReactNode;
}

interface KeycloakState {
    route: string;
    authProfile: KeycloakProfile;
    keycloakAdapter?: KeycloakInstance;
    initialized: boolean;
    loggedIn?: boolean;
    publicRoutes: string[];
}

const TOKEN_MIN_VALIDITY = 10;

/**
 * The Keycloak component ensures, that the user is logged in.
 */
class Keycloak extends ComponentBase<KeycloakProps, KeycloakState> {
    protected _buildState(props: KeycloakProps, initialBuild: boolean): Partial<KeycloakState> | undefined {
        const partialState: Partial<KeycloakState> | undefined = {
            route: NavStore.getCurrentRoute(),
            authProfile: AuthStore.getAuthProfile(),
            publicRoutes: NavStore.getPublicRoutes(),
            keycloakAdapter: AuthStore.getKeycloakAdapter(),
            loggedIn: AuthStore.isLoggedIn(),
        };

        if (initialBuild) {
            partialState.loggedIn = undefined;
            partialState.initialized = false;
        }
        return partialState;
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.init();
    }

    componentDidUpdate(prevProps: Readonly<KeycloakProps>, prevState: KeycloakState, prevContext: any): void {
        super.componentDidUpdate(prevProps, prevState, prevContext);
        if (!this.state.publicRoutes.includes(this.state.route)) {
            // we need keycloak initialized first, if not done before
            if (this.state.initialized && !this.state.loggedIn && this.state.keycloakAdapter !== undefined) {
                this.state.keycloakAdapter.login();
            }
        }
    }

    init(): void {
        InitializationManager.setPhase(InitializationPhase.AUTHORIZING);

        let keycloak: KeycloakInstance;
        if (process.env.REACT_APP_KEYCLOAK_ENV) {
            keycloak = new kc('/keycloak-' + process.env.REACT_APP_KEYCLOAK_ENV + '.json');
        } else {
            keycloak = new kc('/keycloak-staging.json');
        }

        keycloak.onTokenExpired = (): void => {
            // here we refresh our token, in case it is expired
            keycloak.updateToken(TOKEN_MIN_VALIDITY).then(() => console.info('Token refreshed'));
        };

        keycloak.onAuthRefreshSuccess = (): void => this.authSuccess(keycloak.token);

        keycloak.onAuthSuccess = (): void => this.authSuccess(keycloak.token);

        keycloak.onAuthError = (): void => {
            this.saveUserId();
            AuthStore.setIsLoggedIn(false);
        };

        keycloak.onReady = (): void => {
            this.setState({ initialized: true });
        };

        // check if logged in
        keycloak
            .init({
                onLoad: 'check-sso',
                checkLoginIframe: true,
                checkLoginIframeInterval: 1,
            })
            .then((authorized) => {
                AuthStore.setIsLoggedIn(authorized);
                InitializationManager.endAuthorizing(authorized);
            })
            .catch((): void => {
                AuthStore.setIsLoggedIn(false);
                InitializationManager.endAuthorizing(false);
            });

        AuthStore.setKeycloakAdapter(keycloak);
    }

    authSuccess(keycloakToken?: string): void {
        this.registerAuthInterceptor(keycloakToken);
        this.saveUserId();
        this.loadUserProfile();
        AuthStore.setIsLoggedIn(true);
    }

    registerAuthInterceptor(token?: string): void {
        http.setAuthToken(token);
    }

    saveUserId = (): void => {
        if (this.state.keycloakAdapter && this.state.keycloakAdapter.tokenParsed) {
            AuthStore.setId(this.state.keycloakAdapter.tokenParsed.sub);
        } else {
            AuthStore.setId(undefined);
        }
    };

    loadUserProfile = (): void => {
        if (this.state.keycloakAdapter !== undefined) {
            this.state.keycloakAdapter
                .loadUserProfile()
                .then((keycloakProfile: KeycloakProfile) => {
                    AuthStore.setAuthProfile(keycloakProfile);
                })
                .catch((): void => {
                    throw new Error('Your Profile could not be fetched form the Authprovider');
                });
        }
    };

    render(): ReactElement {
        if (this.state.publicRoutes.includes(this.state.route)) {
            return <>{this.props.children}</>;
        } else {
            // wait for keycloak first!
            if (this.state.loggedIn && this.state.authProfile !== undefined) {
                return <>{this.props.children}</>;
            } else {
                return (
                    <div className={this.props.classes.center}>
                        <NearbuyLoadingIndicator />
                    </div>
                );
            }
        }
    }
}

export default withStyles(styles)(Keycloak);
