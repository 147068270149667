import { Employment, EmploymentStatus } from 'model/Employment';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { CardActionArea, Grid, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DashletProperties, DashletType } from 'model/DashletProperties';
import { Link } from 'react-router-dom';
import { classNames } from 'util/style-helpers';
import DashletFeatureFactory from 'components/dashboard/DashletFeatureFactory';
import { CompanyFeatureStore, EmploymentStore } from 'store';
import { ComponentBase } from 'resub';

export const styles = (theme: Theme) =>
    createStyles({
        backdrop: {
            backgroundColor: 'rgba(0,0,0,0.3)',
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1000,
        },
        card: {
            height: '100%',
            minHeight: '304px',
            borderRadius: 10,
            boxShadow: ' 1px 5px 8px 2px rgba(0, 0, 0, 0.07)',
        },
        cardRoot: {
            height: '100%',
            '&:hover': {
                color: 'inherit',
            },
        },
        content: {
            height: '100%',
            padding: theme.spacing(4),
            position: 'relative',
        },
        headerRoot: {
            padding: theme.spacing(0.5) + ' ' + theme.spacing(2),
        },
        headerTitle: {
            fontSize: 'inherit',
            fontWeight: 'bold',
        },
        headerRootDisabled: {
            backgroundColor: theme.palette.grey['400'],
        },
        headerRootEnabled: {
            backgroundColor: theme.palette.primary.light,
        },
        overlayText: {
            alignItems: 'center',
            backgroundColor: 'transparent',
            bottom: '20%',
            display: 'flex',
            left: '10%',
            padding: theme.spacing(4),
            position: 'absolute',
            right: '10%',
            top: '20%',
            zIndex: 1000,
        },
    });

export interface DashletProps extends DashletProperties, WithStyles<typeof styles>, WithTranslation {}

export interface DashletState {
    isDisabled?: boolean;
    currentEmployment: Employment;
}

export class Dashlet extends ComponentBase<DashletProps, DashletState> {
    protected _buildState(): Partial<DashletState> | undefined {
        const features = DashletFeatureFactory.getRequiredFeatures(this.props.id);

        let hasPermission = true;

        if (features) {
            hasPermission =
                CompanyFeatureStore.hasPermission(
                    new Set(Array.from(features.featuresRequired)),
                    features.type || 'allOf',
                ) ?? false;
        }

        return {
            isDisabled: !hasPermission,
            currentEmployment: EmploymentStore.getSelected(),
        };
    }

    getDisabled(): React.ReactNode | null {
        if (process.env.REACT_APP_NEARBUY_FEATURE_SHOW_SUBSCRIPTION === 'true') {
            return (
                <Grid className={this.props.classes.overlayText}>
                    <Typography>{this.props.t(`dashboard:${this.props.id}_disabled`)}</Typography>
                </Grid>
            );
        } else return null;
    }

    getPlaceholder(): React.ReactNode {
        switch (this.props.id) {
            case DashletType.map:
                return (
                    <Grid position={'relative'}>
                        {this.props.children}
                        <Grid className={this.props.classes.backdrop} />
                        {this.getDisabled()}
                    </Grid>
                );
            default:
                return this.getDisabled();
        }
    }

    getInner(): React.ReactFragment | null {
        return (
            <>
                <CardHeader
                    classes={{
                        root: classNames(
                            this.props.classes.headerRoot,
                            this.state.isDisabled
                                ? this.props.classes.headerRootDisabled
                                : this.props.classes.headerRootEnabled,
                        ),
                        title: this.props.classes.headerTitle,
                    }}
                    title={this.props.t(`dashboard:${this.props.id}`)}
                />
                <CardContent className={this.props.classes.content}>
                    {this.state.isDisabled ? this.getPlaceholder() : this.props.children}
                </CardContent>
            </>
        );
    }

    getInnerWithLinkToSubscription(): React.ReactElement {
        if (
            process.env.REACT_APP_NEARBUY_FEATURE_SHOW_SUBSCRIPTION === 'true' &&
            this.state.currentEmployment &&
            this.state.currentEmployment.status != EmploymentStatus.APPLICATION_PENDING &&
            this.state.currentEmployment.status != EmploymentStatus.INVITE_PENDING
        ) {
            return (
                <CardActionArea
                    classes={{
                        root: this.props.classes.cardRoot,
                    }}
                    component={Link}
                    to={'/subscribe'}
                >
                    {this.getInner()}
                </CardActionArea>
            );
        } else {
            return (
                <CardActionArea
                    classes={{
                        root: this.props.classes.cardRoot,
                    }}
                >
                    {this.getInner()}
                </CardActionArea>
            );
        }
    }

    render(): React.ReactElement | null {
        if (!this.props.id) return null;

        return (
            <Grid item xs={4}>
                <Card className={this.props.classes.card}>
                    {this.state.isDisabled ? this.getInnerWithLinkToSubscription() : this.getInner()}
                </Card>
            </Grid>
        );
    }
}

export default withTranslation('dashboard')(withStyles(styles, { withTheme: true })(Dashlet));
