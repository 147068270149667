import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material';
import { ComponentBase } from 'resub';
import Grid from '@mui/material/Grid';
import FavouriteDashletItem from 'components/dashboard/person/favourite/FavouriteDashletItem';
import { Company } from 'model/Company';
import { CompanyStore } from 'store';

const _styles = (theme: Theme) =>
    createStyles({
        requestListBox: {
            alignItems: 'stretch',
            backgroundColor: theme.palette.common.white,
            marginBottom: theme.spacing(2),
        },
    });

interface FavouriteDashletListProps extends WithStyles<typeof _styles> {
    companyRefs: string[];
}

interface FavouriteDashletListState {
    companies?: (Company | undefined)[];
}

const FavouriteCountToShow = 5;

class FavouriteDashletList extends ComponentBase<FavouriteDashletListProps, FavouriteDashletListState> {
    protected _buildState(
        props: FavouriteDashletListProps,
        initialBuild: boolean,
        incomingState?: Readonly<FavouriteDashletListState>,
    ): Partial<FavouriteDashletListState> | undefined {
        return {
            companies: this.props.companyRefs.slice(0, FavouriteCountToShow).map((ref) => {
                return CompanyStore.getOne(ref);
            }),
        };
    }

    render(): React.ReactElement | null {
        if (!(this.state.companies && this.state.companies.length > 0)) return null;

        return (
            <Grid container spacing={1} className={this.props.classes.requestListBox}>
                {this.state.companies.map((company) => {
                    if (company) {
                        return <FavouriteDashletItem key={company.id} company={company} />;
                    } else {
                        return null;
                    }
                })}
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(FavouriteDashletList);
