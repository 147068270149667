import * as React from 'react';
import { Button, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ComponentBase } from 'resub';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { getButtonStyle, getButtonVariant } from 'shared/buttons';
import { wrapButtonWithTooltip } from 'util/style-helpers';

const _styles = (theme: Theme) =>
    createStyles({
        buttonGrid: {
            marginTop: theme.spacing(1),
        },
        buttonGridHalfTopMargin: {
            marginTop: theme.spacing(0.5),
        },
        buttonGridNoTopMargin: {
            marginTop: theme.spacing(0),
        },
    });

export interface TradeActionButton {
    type?: 'transparent' | 'primaryLight' | 'secondaryMain';
    text: string;
    iconStart?: React.ReactFragment;
    iconEnd?: React.ReactFragment;
    onClick?: () => void;
    link?: string;
    tooltip?: string;
    gridWidth?: number;
    fileInputOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fileInputAccept?: string;
    disabled?: boolean;
}

export interface TradeActionButtonsComponentProps extends WithStyles<typeof _styles> {
    buttons: (TradeActionButton | null)[];
    justifyHorizontal?: 'start' | 'center' | 'end' | 'space-around' | 'space-between';
    marginTop?: 'none' | 'half' | 'full';
}

export class TradeActionButtonsComponent extends ComponentBase<TradeActionButtonsComponentProps> {
    getTopMargin(margin: 'none' | 'half' | 'full' | undefined) {
        switch (margin) {
            case 'full':
                return this.props.classes.buttonGrid;
            case 'half':
                return this.props.classes.buttonGridHalfTopMargin;
            case 'none':
            default:
                return this.props.classes.buttonGridNoTopMargin;
        }
    }

    getButton(button: TradeActionButton): React.ReactElement {
        const defaultProps = {
            variant: getButtonVariant(button.type),
            onClick: button.onClick,
            startIcon: button.iconStart,
            endIcon: button.iconEnd,
        };
        const conditionalProps = button.link
            ? {
                  component: Link,
                  to: button.link,
              }
            : button.fileInputOnChange && button.fileInputAccept
              ? {
                    component: 'label',
                }
              : {};
        return (
            <Button
                disabled={button.disabled}
                {...defaultProps}
                {...conditionalProps}
                sx={{
                    ...getButtonStyle(button.type),
                }}
            >
                {button.text}
                {button.fileInputOnChange && button.fileInputAccept && (
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        accept={button.fileInputAccept}
                        onChange={button.fileInputOnChange}
                    />
                )}
            </Button>
        );
    }

    render(): React.ReactElement | null {
        return (
            <Grid container justifyContent={this.props.justifyHorizontal ?? 'start'}>
                {this.props.buttons
                    .filter((item) => item !== null)
                    .map((button) => {
                        return (
                            <Grid item key={button!.text} className={this.getTopMargin(this.props.marginTop)}>
                                {button!.tooltip
                                    ? wrapButtonWithTooltip(this.getButton(button!), button!.tooltip)
                                    : this.getButton(button!)}
                            </Grid>
                        );
                    })}
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(TradeActionButtonsComponent);
