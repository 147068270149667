import * as React from 'react';
import { ReactNode } from 'react';
import { NotificationInfo, NotificationType } from 'model/NearbuyNotification';
import MessageSnackBarItem from 'components/notification/message/MessageSnackBarItem';
import { VariantType } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SystemMessageSnackBarItem from 'components/notification/systemMessage/SystemMessageSnackBarItem';
import { theme } from 'style/NearbuyStyle';
import OrderSnackbarItem from 'components/notification/order/OrderSnackbarItem';

export function getAction(notificationInfo: NotificationInfo, closeAction: () => void): ReactNode {
    switch (notificationInfo.notification.type) {
        case NotificationType.NEW_MESSAGE:
            return MessageSnackBarItem.getAction(notificationInfo, closeAction);
        case NotificationType.ORDER_MARKED_ACTIVE:
            return OrderSnackbarItem.getAction(notificationInfo, closeAction);
        case NotificationType.ORDER_MARKED_COMPLETE:
            return OrderSnackbarItem.getAction(notificationInfo, closeAction);
        case NotificationType.DELETED_SYSTEM_MESSAGE:
            return SystemMessageSnackBarItem.getAction(notificationInfo, closeAction);
        case NotificationType.HIDE_GLOBAL_SYSTEM_MESSAGE_SNACKBAR:
            return SystemMessageSnackBarItem.getAction(notificationInfo, closeAction);
        default:
            return (
                <IconButton
                    sx={{
                        color: theme.palette.getContrastText(theme.palette.error.main),
                        backgroundColor: 'transparent',
                        '&:hover': {
                            color: theme.palette.common.black,
                            backgroundColor: 'transparent',
                        },
                    }}
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={closeAction}
                >
                    <CloseIcon />
                </IconButton>
            );
    }
}

export function getContent(notificationInfo: NotificationInfo): string | React.ReactElement | null {
    switch (notificationInfo.notification.type) {
        case NotificationType.NEW_MESSAGE:
            return MessageSnackBarItem.getContent(notificationInfo);
        case NotificationType.SYSTEM:
        case NotificationType.DELETED_SYSTEM_MESSAGE:
            return SystemMessageSnackBarItem.getContent(notificationInfo);
        case NotificationType.TEST:
            return 'testnotification';
        case NotificationType.MESSAGE_SENT:
        case NotificationType.PRE_ORDER_SENT:
        case NotificationType.INVOICE_EXCHANGED:
        case NotificationType.VERIFY_WITHOUT_ADDRESS:
        case NotificationType.DEFAULT_ERROR:
            return notificationInfo.notification.value;
        case NotificationType.ORDER_MARKED_COMPLETE:
            return OrderSnackbarItem.getContent(notificationInfo);
        case NotificationType.ORDER_MARKED_ACTIVE:
            return OrderSnackbarItem.getContent(notificationInfo);
        case NotificationType.HIDE_GLOBAL_SYSTEM_MESSAGE_SNACKBAR:
            return SystemMessageSnackBarItem.getContent(notificationInfo);
        default:
            return null;
    }
}

export function getVariant(notificationInfo: NotificationInfo): VariantType {
    switch (notificationInfo.notification.type) {
        case NotificationType.MESSAGE_SENT:
            return 'success';
        case NotificationType.PRE_ORDER_SENT:
            return 'success';
        case NotificationType.VERIFY_WITHOUT_ADDRESS:
            return 'error';
        default:
            return 'default';
    }
}

export function getSideEffect(
    notificationInfo: NotificationInfo,
): ((notificationInfo: NotificationInfo) => void) | undefined {
    switch (notificationInfo.notification.type) {
        case NotificationType.SYSTEM:
            return SystemMessageSnackBarItem.getSideEffect();
    }
}
