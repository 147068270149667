import OfferService from 'api/OfferService';
import MarketItemDialog from 'components/marketplace/marketitem/dialog/MarketItemDialog';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { Offer } from 'model/Offer';
import { OfferSystemMessage } from 'model/SystemMessage';
import { getUnitFromString, UnitString } from 'model/Unit';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { CompanyStore, LevelOfProcessingStore, OfferStore, OrderStore, ProductStore } from 'store';
import { getUuidFromString } from 'util/helpers';

interface OfferSystemMessageItemProps extends WithTranslation {
    systemMessage: OfferSystemMessage;
}

interface OfferSystemMessageItemMapping extends Record<string, string> {
    productLabel: string;
    offerAmountAndUnit: string;
    levelOfProcessing: string;
    orderAmountAndUnit: string;
    buyingCompanyName: string;
}

interface OfferSystemMessageItemState {
    mapping?: OfferSystemMessageItemMapping;
    editDialogOpen?: boolean;
    offer?: Offer;
}

class OfferSystemMessageItem extends ComponentBase<OfferSystemMessageItemProps, OfferSystemMessageItemState> {
    protected _buildState(
        props: OfferSystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<OfferSystemMessageItemState>,
    ): Partial<OfferSystemMessageItemState> | undefined {
        const offer = OfferStore.getOne(props.systemMessage.links.offer);
        const product = offer ? ProductStore.getOne(offer.links.category) : undefined;
        const order = props.systemMessage.links.order ? OrderStore.getOne(props.systemMessage.links.order) : undefined;
        const buyingCompany =
            order && order.links.buyingCompany ? CompanyStore.getOne(order.links.buyingCompany) : undefined;
        const levelsOfProcessing = props.systemMessage.levelOfProcessing ? LevelOfProcessingStore.getAll() : undefined;

        if (product && offer) {
            const amount = props.systemMessage.amountInBaseUnit
                ? props.systemMessage.amountInBaseUnit *
                  (getUnitFromString(offer.totalAmount.unit as UnitString)?.conversionFactor ?? 1)
                : undefined;

            return {
                offer,
                mapping: {
                    productLabel: props.t('ontofood:' + product.label),
                    offerAmountAndUnit: amount
                        ? amount.toString() + ' ' + props.t('shortunits:' + offer.totalAmount.unit)
                        : '',
                    levelOfProcessing:
                        props.systemMessage.levelOfProcessing && levelsOfProcessing
                            ? ', ' +
                              props.t(
                                  'levelsOfProcessing:' +
                                      levelsOfProcessing
                                          .filter(
                                              (lop) =>
                                                  getUuidFromString(lop.links.self) ===
                                                  props.systemMessage.levelOfProcessing,
                                          )
                                          .map((lop) => {
                                              return lop.label;
                                          })[0],
                              )
                            : '',
                    orderAmountAndUnit: order
                        ? order.amount.amount.toString() + ' ' + props.t('shortunits:' + order.amount.unit)
                        : '',
                    buyingCompanyName: buyingCompany ? buyingCompany.name : '',
                },
            };
        }
    }

    openEditDialog(): void {
        this.setState({ editDialogOpen: true });
    }

    closeEditDialog(): void {
        this.setState({ editDialogOpen: false });
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping || !this.state.offer) return null;

        return (
            <>
                <MarketItemDialog
                    companyRef={this.state.offer.links.company}
                    offerRef={this.state.offer.links.self}
                    isOpen={this.state.editDialogOpen || false}
                    onClose={() => {
                        this.closeEditDialog();
                    }}
                    onDelete={() => {
                        if (this.state.offer) {
                            OfferService.deleteOffer(this.state.offer.id, this.state.offer.links.company).subscribe(
                                () => {
                                    this.closeEditDialog();
                                },
                            );
                        }
                    }}
                    isReadOnly={false}
                    calledFrom={'systemMessage'}
                />
                <SystemMessageItem
                    onClick={() => this.openEditDialog()}
                    systemMessage={this.props.systemMessage}
                    mapping={this.state.mapping}
                />
            </>
        );
    }
}

export default withTranslation(['ontofood', 'levelsOfProcessing', 'shortunits'])(OfferSystemMessageItem);
