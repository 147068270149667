export class Assortment {
    constructor(
        public id: string,
        public links: AssortmentLinks,
    ) {}
}

export class AssortmentLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public company: string,
        public category: string,
    ) {}
}

export class AssortmentWriteView {
    constructor(public category: string) {}
}

export class CompanyAssortment {
    constructor(
        public companyRef: string,
        public assortments: string[],
    ) {}
}
