import { DashletProperties, DashletType } from 'model/DashletProperties';
import Dashlet from 'components/dashboard/Dashlet';
import React from 'react';
import OntofoodDashlet from 'components/dashboard/OntofoodDashlet';
import AdminNavigationDashlet from 'components/dashboard/admin/AdminNavigationDashlet';
import MyOffersComponent from 'components/dashboard/marketplace/offer/MyOffersDashlet';
import DashletFeatureFactory from 'components/dashboard/DashletFeatureFactory';
import MyRequestsDashlet from 'components/dashboard/marketplace/request/MyRequestsDashlet';
import CurrentCompanyDashlet from 'components/dashboard/company/CurrentCompanyDashlet';
import { CompanyFeatureStore } from 'store/index';
import MapDashlet from 'components/dashboard/marketplace/map/MapDashlet';
import MyFavouritesDashlet from 'components/dashboard/person/favourite/MyFavouritesDashlet';
import SubscriptionDashlet from 'components/dashboard/subscription/SubscriptionDashlet';
import FeatureStore from 'store/FeatureStore';

export function getWeightedPriority(dashletProperty: DashletProperties): number {
    const features = DashletFeatureFactory.getRequiredFeatures(dashletProperty.id);

    // in case, user has not the permission to see the dashlet,
    // we add 1000, so that it is shown at the bottom
    FeatureStore.invalidateCache();
    const handicap = features
        ? CompanyFeatureStore.hasPermission(features.featuresRequired, features.type)
            ? 0
            : 1000
        : 0;

    return (dashletProperty.priority || 0) + handicap;
}

export function getDashlet(dashletProperties: DashletProperties): React.ReactElement {
    switch (dashletProperties.id) {
        case DashletType.currentCompany:
            return <CurrentCompanyDashlet key={dashletProperties.id} dashletProperties={dashletProperties} />;
        case DashletType.ontofood:
            return (
                <OntofoodDashlet
                    type={dashletProperties.additionalProperties.selectionType}
                    title="ontofood"
                    key={dashletProperties.id + dashletProperties.additionalProperties.selectionType}
                    dashletProperties={dashletProperties}
                />
            );
        case DashletType.map:
            return <MapDashlet key={dashletProperties.id} dashletProperties={dashletProperties} />;
        case DashletType.myOffers:
            return <MyOffersComponent key={dashletProperties.id} dashletProperties={dashletProperties} />;
        case DashletType.myRequests:
            return <MyRequestsDashlet key={dashletProperties.id} dashletProperties={dashletProperties} />;
        case DashletType.adminNavigation:
            return <AdminNavigationDashlet key={dashletProperties.id} dashletProperties={dashletProperties} />;
        case DashletType.myFavouriteCompanies:
            return <MyFavouritesDashlet key={dashletProperties.id} dashletProperties={dashletProperties} />;
        case DashletType.subscription:
            if (process.env.REACT_APP_NEARBUY_FEATURE_SHOW_SUBSCRIPTION === 'false') return <React.Fragment />;
            return <SubscriptionDashlet key={dashletProperties.id} dashletProperties={dashletProperties} />;
        default:
            return <Dashlet key={dashletProperties.id} {...dashletProperties} />;
    }
}
