import { DynamicLoadingStore, triggerSelectedKey } from 'resub-entity';
import { CompanyFeatures, FeatureOrFeatures } from 'model/Feature';
import { autoSubscribeWithKey } from 'resub';
import { Observable } from 'rxjs';
import FeatureService from 'api/FeatureService';

class FeatureStore extends DynamicLoadingStore<CompanyFeatures, string> {
    @autoSubscribeWithKey(triggerSelectedKey)
    hasPermission(requiredFeatures: Set<FeatureOrFeatures>, type: 'allOf' | 'oneOf' = 'allOf'): boolean | undefined {
        if (requiredFeatures.size === 0) {
            return true;
        }
        const selected = this.getSelected();
        if (!selected) {
            return undefined;
        }
        const features = selected.features;
        if (!features) {
            return undefined;
        }
        const hasPermission = type === 'allOf';

        if (type === 'allOf') {
            return (
                hasPermission &&
                Array.of(...requiredFeatures)
                    .map((rf) => {
                        if (Array.isArray(rf)) {
                            // in case it is an array, only one of the array is needed
                            return rf
                                .map((f) => features.has(f))
                                .reduce((previousValue, currentValue) => previousValue || currentValue);
                        } else {
                            return features.has(rf);
                        }
                    })
                    .reduce((previousValue, currentValue) => previousValue && currentValue, true)
            );
        } else if (type === 'oneOf') {
            return (
                hasPermission ||
                Array.of(...requiredFeatures)
                    .map((rf) => {
                        if (Array.isArray(rf)) {
                            // in case it is an array, all of the array are needed
                            return rf
                                .map((f) => features.has(f))
                                .reduce((previousValue, currentValue) => previousValue && currentValue, true);
                        } else {
                            return features.has(rf);
                        }
                    })
                    .reduce((previousValue, currentValue) => previousValue || currentValue)
            );
        }
    }
}

const CompanyFeatureStore = new FeatureStore({
    selectIdFunction: (entity: Readonly<CompanyFeatures>): string => entity.companyOrPersonRef,
    loadFunction: (ref?: string): Observable<CompanyFeatures> => FeatureService.getFeatures(ref),
});

export default CompanyFeatureStore;
