export enum EmploymentStatus {
    MANAGER = 'MANAGER',
    EMPLOYEE = 'EMPLOYEE',
    INVITE_PENDING = 'INVITE_PENDING',
    APPLICATION_PENDING = 'APPLICATION_PENDING',
    TERMINATED = 'TERMINATED',
}

export class Employment {
    constructor(
        public id: string,
        public status: EmploymentStatus,
        public links: EmploymentLinks,
    ) {}
}

export class EmploymentWriteView {
    constructor(
        public company: string,
        public person: string,
        public status: EmploymentStatus,
    ) {}
}

export class EmploymentLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public person: string,
        public company: string,
        public systemMessages: string,
    ) {}
}

export class CompanyEmployments {
    constructor(
        public companyRef: string,
        public employees: string[],
    ) {}
}

export class PersonEmployments {
    constructor(
        public userRef: string,
        public employments: Employment[],
    ) {}
}

function copyLinks(links: EmploymentLinks): EmploymentLinks {
    return new EmploymentLinks(
        links.self,
        links.update,
        links.remove,
        links.person,
        links.company,
        links.systemMessages,
    );
}

export function copy(employment?: Employment): Employment {
    if (!employment) {
        return new Employment('', EmploymentStatus.EMPLOYEE, new EmploymentLinks('', '', '', '', '', ''));
    }
    return new Employment(employment.id, employment.status, copyLinks(employment.links));
}
