import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import * as React from 'react';
import { ReactElement } from 'react';
import { withResubAutoSubscriptions } from 'resub';
import { ExtendedTheme } from 'style/NearbuyStyle';
import { Transition } from 'util/style-helpers';

interface TradeDataDialogProps {
    isOpen: boolean;
    onClose: (remove: boolean) => void;
    title: string;
    content: ReactElement;
}

function TradeDataDialog(props: TradeDataDialogProps) {
    /* Hooks */
    const theme = useTheme<ExtendedTheme>();
    /* States and Store Subscribed Constants */
    /* Effects */
    /* Styles und StyledComponents */
    /* JSX */

    const closeButton = (
        <IconButton
            sx={{
                color: 'primary',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
            onClick={() => props.onClose(false)}
        >
            <CloseIcon
                sx={{
                    color: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.primary.dark,
                    },
                }}
            />
        </IconButton>
    );

    const title = (
        <DialogTitle>
            <Grid container direction={'row'} justifyContent={'space-between'} xs={12}>
                <Grid sx={{ paddingLeft: theme.spacing(1), alignSelf: 'center' }}>{props.title}</Grid>
                <Grid>{closeButton}</Grid>
            </Grid>
        </DialogTitle>
    );

    const content = <DialogContent>{props.content}</DialogContent>;

    return (
        <Dialog
            onClose={(event, reason) => {
                if (event && reason === 'backdropClick') {
                    props.onClose(false);
                }
            }}
            maxWidth="md"
            TransitionComponent={Transition}
            open={props.isOpen ?? false}
        >
            {title}
            {content}
        </Dialog>
    );
}

export default withResubAutoSubscriptions(TradeDataDialog);
