import { Slide } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { TransitionProps } from '@mui/material/transitions';
import Zoom from '@mui/material/Zoom';
import React, { ReactNode } from 'react';

export function classNames(...classNames: (string | undefined)[]): string {
    let resultingClassName = '';
    let addSpace = false;
    for (const className of classNames) {
        if (!className) continue;
        if (addSpace) {
            resultingClassName += ' ';
        } else {
            addSpace = true;
        }
        resultingClassName += className;
    }

    return resultingClassName;
}

export function wrapWithTooltip(
    element: React.ReactElement,
    tooltipTitle?: string,
    placement?:
        | 'bottom'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start'
        | undefined,
    key?: string,
    titleElement?: ReactNode,
): React.ReactElement {
    if (tooltipTitle) {
        return (
            <Tooltip
                key={key}
                TransitionComponent={Zoom}
                placement={placement ?? 'bottom'}
                title={titleElement ?? tooltipTitle}
            >
                {element}
            </Tooltip>
        );
    }
    return element;
}

export function wrapButtonWithTooltip(
    element: React.ReactElement,
    tooltipTitle?: string,
    placement?: 'top' | 'bottom' | 'left' | 'right',
    key?: string,
    style?: React.CSSProperties,
    titleElement?: ReactNode,
): React.ReactElement {
    if (tooltipTitle) {
        return (
            <Tooltip
                key={key}
                TransitionComponent={Zoom}
                placement={placement ?? 'bottom'}
                title={titleElement ?? tooltipTitle}
            >
                <span style={style}>{element}</span>
            </Tooltip>
        );
    }
    return element;
}

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
