import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { ComponentBase } from 'resub';
import { ImageInfo } from 'model/Image';
import { CompanyImagesStore, CompanyStore, ImageStore } from 'store';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { classNames } from 'util/style-helpers';
import { ExtendedTheme } from 'style/NearbuyStyle';

const imageSizeS = 33;
const imageSizeM = 70;
const imageSizeL = 150;

const _styles = (theme: ExtendedTheme) =>
    createStyles({
        wrapperNormal: {
            width: imageSizeL,
            height: imageSizeL,
        },
        wrapperSmall: {
            width: imageSizeM,
            height: imageSizeM,
        },
        logo: {
            border: `2px solid ${theme.palette.background.paper}`,
            backgroundColor: theme.palette.background.paper,
        },
        logoSizeNormal: {
            height: imageSizeM,
            width: imageSizeM,
        },
        logoSizeSmall: {
            height: imageSizeS,
            width: imageSizeS,
        },
        objectFitContain: {
            objectFit: 'contain',
        },
        wh100: {
            height: '100%',
            width: '100%',
        },
    });

interface CompanyAvatarComponentProps extends WithStyles<typeof _styles> {
    companyRef: string;
    isLogoShown?: boolean;
    isSmall?: boolean;
}

interface CompanyAvatarComponentState {
    image?: ImageInfo;
    logo?: ImageInfo;
}

class CompanyAvatarComponent extends ComponentBase<CompanyAvatarComponentProps, CompanyAvatarComponentState> {
    protected _buildState(
        props: CompanyAvatarComponentProps,
        initialBuild: boolean,
        incomingState?: Readonly<CompanyAvatarComponentState>,
    ): Partial<CompanyAvatarComponentState> | undefined {
        const company = CompanyStore.getOne(props.companyRef);
        if (!company) {
            // we need to wait at least for the company, so that we can go on
            return undefined;
        }
        const companyImages = CompanyImagesStore.getOne(company.id);
        return {
            image: companyImages?.images.filter((image: ImageInfo) => image.ord === 0)[0],
            logo: props.isLogoShown && company?.links.logo ? ImageStore.getOne(company.links.logo) : undefined,
        };
    }

    getBadgeContent() {
        const classes = this.props.classes;
        if (!this.props.isLogoShown || !this.state.logo) return undefined;

        return (
            <Avatar
                className={classNames(
                    classes.logo,
                    this.props.isSmall ? classes.logoSizeSmall : classes.logoSizeNormal,
                )}
                imgProps={{
                    className: classes.objectFitContain,
                }}
                src={this.state.logo?.source}
            />
        );
    }

    render(): React.ReactNode | null | undefined {
        const classes = this.props.classes;

        return (
            <Grid
                alignItems={'center'}
                justifyContent={'center'}
                className={this.props.isSmall ? classes.wrapperSmall : classes.wrapperNormal}
            >
                <Badge
                    overlap="circular"
                    className={classNames(
                        this.props.classes.wh100,
                        this.props.isSmall ? classes.wrapperSmall : classes.wrapperNormal,
                    )}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={this.getBadgeContent()}
                >
                    <Avatar
                        className={classes.wh100}
                        src={this.state.image ? this.state.image.source : '/images/dummyprofilepicture.png'}
                        imgProps={{
                            className: this.props.classes.objectFitContain,
                        }}
                    />
                </Badge>
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(CompanyAvatarComponent);
