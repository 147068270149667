import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import { ComponentBase } from 'resub';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MessageTermsStatus, MessageTermsStatusWriteView, Person } from 'model/Person';
import MessageService from 'api/MessageService';
import { PersonStore } from 'store';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from 'style/NearbuyStyle';

interface MessageRestrictionDialogDialogProps extends WithTranslation {
    isOpen: boolean;
    onClose?: (accepted: boolean) => void;
    isProvideOfferDialogOpen?: () => void;
}

interface MessageRestrictionDialogDialogState {
    user?: Person;
}

class MessageRestrictionDialog extends ComponentBase<
    MessageRestrictionDialogDialogProps,
    MessageRestrictionDialogDialogState
> {
    protected _buildState(
        props: MessageRestrictionDialogDialogProps,
        initialBuild: boolean,
        incomingState?: Readonly<MessageRestrictionDialogDialogState>,
    ): Partial<MessageRestrictionDialogDialogState> | undefined {
        return {
            user: PersonStore.getSelected(),
        };
    }

    accept(): void {
        if (this.state.user?.links.update) {
            MessageService.updateMessageTermsStatus(
                this.state.user.links.update,
                new MessageTermsStatusWriteView(this.state.user.links.self, MessageTermsStatus.ACCEPTED),
            ).subscribe(() => {
                this.props.onClose?.(true);
            });
        }
    }

    decline(): void {
        if (this.state.user?.links.update) {
            MessageService.updateMessageTermsStatus(
                this.state.user.links.update,
                new MessageTermsStatusWriteView(this.state.user.links.self, MessageTermsStatus.DECLINED),
            ).subscribe(() => {
                this.props.onClose?.(false);
            });
        }
    }

    render(): React.ReactElement | null {
        return (
            <Dialog
                open={this.props.isOpen}
                maxWidth="sm"
                fullWidth
                onClose={(event, reason) => {
                    if (event && reason === 'backdropClick') {
                        this.props.onClose?.(true);
                    }
                }}
            >
                <DialogTitle>{this.props.t('dialogs:hint')}</DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{
                            marginBottom: theme.spacing(1),
                            fontSize: '24px',
                            fontWeight: 600,
                            lineHeight: '140%',
                        }}
                    >
                        {this.props.t('messaging:messageRestrictionTitle')}
                    </Typography>
                    <br />
                    <Typography>{this.props.t('messaging:messageRestrictionContent1')}</Typography>
                    <br />
                    <Typography>{this.props.t('messaging:messageRestrictionContent2')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={(): void => this.decline()}>
                        {this.props.t('dialogs:DECLINE')}
                    </Button>
                    <Button
                        onClick={(): void => {
                            this.accept();
                            if (this.props.isProvideOfferDialogOpen) this.props.isProvideOfferDialogOpen();
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {this.props.t('dialogs:ACCEPT')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['company', 'dialogs', 'messaging'])(MessageRestrictionDialog);
