import * as React from 'react';
import { ComponentBase } from 'resub';
import { RequestStore } from 'store';
import { Request } from 'model/Request';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme, Typography } from '@mui/material';
import RequestFullDetailDialog from 'components/search/result-items/requests/RequestFullDetailDialog';
import Grid from '@mui/material/Grid';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { theme } from 'style/NearbuyStyle';

const _styles = (theme: Theme) =>
    createStyles({
        requestDot: {
            color: theme.palette.primary.main,
            fontSize: 27,
            verticalAlign: 'middle',
        },
    });

interface RequestTextReferenceProps extends WithStyles<typeof _styles> {
    requestRef: string;
    text: string;
    justifyContent: 'end' | 'start';
}

interface RequestTextReferenceState {
    request?: Request;
    isDialogOpen?: boolean;
}

class RequestTextReference extends ComponentBase<RequestTextReferenceProps, RequestTextReferenceState> {
    protected _buildState(
        props: RequestTextReferenceProps,
        initialBuild: boolean,
        incomingState?: Readonly<RequestTextReferenceState>,
    ): Partial<RequestTextReferenceState> | undefined {
        return {
            request: RequestStore.getOne(props.requestRef),
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.request) return null;
        return (
            <>
                <RequestFullDetailDialog
                    requestRef={this.props.requestRef}
                    isOpen={this.state.isDialogOpen ?? false}
                    onClose={() => this.setState({ isDialogOpen: false })}
                />
                <Grid
                    container
                    onClick={() => this.setState({ isDialogOpen: true })}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer',
                        },
                        paddingLeft: this.props.justifyContent === 'end' ? theme.spacing(2.5) : theme.spacing(1.5),
                    }}
                    xs={this.props.justifyContent === 'end' ? 6.5 : undefined}
                >
                    <Grid container item marginBottom={2} marginTop={1}>
                        <Grid item>
                            <FiberManualRecordIcon className={this.props.classes.requestDot} />
                        </Grid>
                        <Grid
                            item
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.lighter,
                                    borderRadius: theme.spacing(0.75),
                                },
                            }}
                        >
                            <Typography sx={{ padding: '0px 10px 0px 10px' }}>{this.props.text}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withStyles(_styles)(RequestTextReference);
