import { Download } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Association } from 'model/Association';
import { DownloadLink, ImageInfo } from 'model/Image';
import { Membership } from 'model/Membership';
import moment from 'moment/moment';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { AssociationStore, ImageStore, MembershipStore } from 'store';
import { theme } from 'style/NearbuyStyle';
import { getValidUrl } from 'util/helpers';
import Tooltip from '@mui/material/Tooltip';

const _styles = () =>
    createStyles({
        image: {
            maxWidth: '100%',
            maxHeight: '88%',
            borderRadius: '4px',
            objectFit: 'contain',
        },
    });

interface PublicAssociationPopOverComponentProps extends WithStyles<typeof _styles>, WithTranslation {
    membershipRef: string;
    anchorEl?: HTMLElement | null;
    downloadLink?: DownloadLink;
    isPopoverOpen?: boolean;
    onClose: () => void;
}

interface PublicAssociationPopOverComponentState {
    association?: Association;
    image?: ImageInfo;
    isLoading?: boolean;
    membership?: Membership;
    url?: string;
}

class PublicAssociationPopOverComponent extends ComponentBase<
    PublicAssociationPopOverComponentProps,
    PublicAssociationPopOverComponentState
> {
    protected _buildState(
        props: PublicAssociationPopOverComponentProps,
        initialBuild: boolean,
    ): Partial<PublicAssociationPopOverComponentState> | undefined {
        const newState: Partial<PublicAssociationPopOverComponentState> = {
            membership: MembershipStore.getOne(props.membershipRef),
        };

        newState.association =
            newState.membership && newState.membership.links.association
                ? AssociationStore.getOne(newState.membership.links.association)
                : undefined;
        newState.image = newState.association?.links.icon
            ? ImageStore.getOne(newState.association.links.icon)
            : undefined;
        newState.url = newState.association?.verificationUrl;

        return newState;
    }

    showAssociationLogo(): ReactNode {
        return this.state.image ? (
            <img className={this.props.classes.image} src={this.state.image.source} alt={'associationLogo'} />
        ) : null;
    }

    showDescription(): ReactNode {
        return (
            <Grid item>
                <Typography sx={{ fontWeight: 600, paddingBottom: theme.spacing(1) }}>
                    {this.state.association?.description}
                </Typography>
            </Grid>
        );
    }

    showValidUntil(): ReactNode {
        return (
            this.state.membership && (
                <Grid item>
                    <Typography noWrap>
                        {this.state.membership.validUntil
                            ? this.props.t('association:validUntilDate', {
                                  date: moment(this.state.membership.validUntil).format(
                                      this.props.t('common:DATEFORMAT') !== 'DATEFORMAT'
                                          ? this.props.t('common:DATEFORMAT')
                                          : 'DD.MM.YY',
                                  ),
                              })
                            : this.props.t('common:noData')}
                    </Typography>
                </Grid>
            )
        );
    }

    showVerificationText(): ReactNode {
        return (
            this.state.association &&
            this.state.association.verificationUrl && (
                <Grid item>
                    <Typography>{this.props.t('association:verificationText')}</Typography>
                </Grid>
            )
        );
    }

    showButtons(): ReactNode {
        return (
            <Grid item container direction={'row'} xs={12}>
                {this.props.downloadLink && (
                    <Grid
                        item
                        sx={{ paddingRight: this.state.url && theme.spacing(1) }}
                        justifyContent={'stretch'}
                        xs={this.state.url ? 6 : 12}
                    >
                        <a target="_blank" rel="noopener noreferrer" href={this.props.downloadLink.url}>
                            <Button sx={{ width: '100%' }} variant="contained" color="primary" startIcon={<Download />}>
                                {this.props.t('association:download')}
                            </Button>
                        </a>
                    </Grid>
                )}
                {this.state.url && (
                    <Grid item justifyContent={'stretch'} xs={this.props.downloadLink ? 6 : 12}>
                        <Tooltip title={this.props.t('tooltips:checkCertificate')}>
                            <Button
                                sx={{ width: '100%' }}
                                variant="contained"
                                color="primary"
                                onClick={() => window.open(this.state.url ? getValidUrl(this.state.url) : '')}
                            >
                                {this.props.t('association:verify')}
                            </Button>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
        );
    }

    render(): ReactNode {
        if (!this.state.membership || !this.props.downloadLink) return null;
        return (
            <Popover
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: 4,
                        padding: theme.spacing(2),
                        minWidth: '400px',
                        maxWidth: '400px',
                    },
                }}
                anchorEl={this.props.anchorEl}
                open={this.props.isPopoverOpen ?? false}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={(): void => this.props.onClose()}
            >
                <Grid container direction={'column'} xs={12}>
                    <Grid container item direction={'row'} spacing={2} marginBottom={theme.spacing(1)}>
                        {this.state.image && (
                            <Grid item container sx={{ alignItems: 'center', maxHeight: '100%' }} xs={4}>
                                {this.showAssociationLogo()}
                            </Grid>
                        )}
                        <Grid container item direction={'column'} xs={8}>
                            {this.showDescription()}
                            {this.showValidUntil()}
                            {this.showVerificationText()}
                        </Grid>
                    </Grid>
                    {this.showButtons()}
                </Grid>
            </Popover>
        );
    }
}

export default withTranslation(['association', 'tooltips', 'common'])(
    withStyles(_styles, { withTheme: true })(PublicAssociationPopOverComponent),
);
