export class Invitation {
    constructor(
        public firstName: string,
        public lastName: string,
        public email: string,
        public links: InvitationLinks,
    ) {}
}

export class InvitationLinks {
    constructor(
        public self: string,
        public remove: string,
        public company: string,
    ) {}
}

export class CompanyInvitations {
    constructor(
        public companyRef: string,
        public invitations: string[],
    ) {}
}
