import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles, WithTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import MarketItemDialogHandlers from 'components/marketplace/marketitem/dialog/MarketItemDialogHandlers';
import GraduatedPricesComponent from 'components/product/GraduatedPricesComponent';
import { Amount } from 'model/Amount';
import { GraduatedPrice } from 'model/Offer';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { ComponentBase } from 'resub';
import { FormError } from 'util/FormErrors';
import { MarketItemDialogHelpers } from 'components/marketplace/marketitem/dialog/MarketItemDialogHelpers';

const styles = (theme: Theme) =>
    createStyles({
        sectionHeader: {
            marginTop: theme.spacing(1),
            fontWeight: 'bold',
            LineHeight: '19px',
            fontSize: '15px',
            color: theme.palette.primary.dark,
        },
        priceUponRequestToggle: {
            paddingLeft: theme.spacing(1),
            marginTop: theme.spacing(1.5),
        },
        hintText: {
            padding: '5px',
            color: '#55430C',
        },
        hintBox: {
            backgroundColor: '#F3E1AA',
            borderRadius: '3px',
            margin: `${theme.spacing(1.5)} 0 0 7`,
        },
    });

interface PriceEditFieldsProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    marketItemDialogHandlers: MarketItemDialogHandlers;
    totalAmount: Amount;
    pricePerUnit: number | null;
    pricePerUnitFormError: FormError;
    graduatedPrices: GraduatedPrice[];
    isPriceHintVisible: boolean;
    isReadOnly?: boolean;
}

class PriceEditFieldsComponent extends ComponentBase<PriceEditFieldsProperties> {
    showFirstChooseUnit(): ReactNode {
        return <Typography style={{ margin: 4 }}>{this.props.t('offer:firstChooseUnit')}</Typography>;
    }

    showBasePriceTitle(): ReactNode {
        return <Typography className={this.props.classes.sectionHeader}>{this.props.t('offer:basePrice')}</Typography>;
    }

    showAddButton(pricePerUnit: number | null, handlers: MarketItemDialogHandlers): ReactNode {
        return (
            <Button onClick={handlers.addGraduatedPrices.bind(handlers)} disabled={!pricePerUnit} variant={'outlined'}>
                {this.props.t('offer:addGraduatedPrices')}
            </Button>
        );
    }

    showPricePerUnitInput(pricePerUnit: number | null, handlers: MarketItemDialogHandlers): ReactNode {
        return (
            <NumberFormat
                id="pricePerUnit"
                label={this.props.t('offer:pricePerUnit')}
                value={
                    pricePerUnit === 0 || pricePerUnit === null
                        ? undefined
                        : pricePerUnit.toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                          })
                }
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                customInput={TextField}
                onValueChange={handlers.onPricePerUnitChange.bind(handlers)}
                inputProps={{
                    readOnly: this.props.isReadOnly,
                }}
                {...this.props.pricePerUnitFormError}
            />
        );
    }

    showPerUnitText(unit: string): ReactNode {
        return <Typography>€ {this.props.t('offer:per') + ' ' + this.props.t(`units:${unit}`)}</Typography>;
    }

    showGraduatedPricesTitle(): ReactNode {
        return (
            <Typography className={this.props.classes.sectionHeader}>
                {this.props.t('offer:graduatedPrices')}
            </Typography>
        );
    }

    showGraduatedPrices(unit: string, handlers: MarketItemDialogHandlers): ReactNode {
        return this.props.graduatedPrices.map((gp, index) => {
            return (
                <GraduatedPricesComponent
                    graduatedPrice={gp}
                    selectedUnit={unit}
                    onRemove={handlers.removeGraduatedPrice.bind(handlers)}
                    onUpdate={handlers.updateGraduatedPrice.bind(handlers)}
                    readOnly={this.props.isReadOnly ?? false}
                    key={index}
                    isDuplicate={MarketItemDialogHelpers.findDuplicatePositions(
                        this.props.graduatedPrices,
                        'amount',
                    ).includes(index)}
                />
            );
        });
    }

    showPriceUponRequestSwitch(pricePerUnit: number | null, handlers: MarketItemDialogHandlers): ReactNode {
        return (
            <FormGroup row>
                <FormControlLabel
                    value={pricePerUnit === null}
                    control={
                        <Switch
                            checked={pricePerUnit === null}
                            onChange={handlers.priceOnRequestChange.bind(handlers)}
                        />
                    }
                    label={this.props.t('offer:priceUponRequest') as string}
                    labelPlacement="end"
                    className={this.props.classes.priceUponRequestToggle}
                />
            </FormGroup>
        );
    }

    showPriceUponRequestHint(): ReactNode {
        return (
            <Typography className={this.props.classes.hintText} hidden={!this.props.isPriceHintVisible}>
                {this.props.t('offer:priceUponRequestHint')}
            </Typography>
        );
    }

    render(): React.ReactElement {
        const handlers = this.props.marketItemDialogHandlers;
        const pricePerUnit = this.props.pricePerUnit;
        const totalAmount = this.props.totalAmount;
        if (!totalAmount.unit) {
            return (
                <Grid item md={12}>
                    {this.showFirstChooseUnit()}
                </Grid>
            );
        } else {
            return (
                <>
                    {pricePerUnit !== undefined && pricePerUnit !== null && (
                        <>
                            <Grid container>
                                <Grid item md={6}>
                                    {this.showBasePriceTitle()}
                                </Grid>
                                <Grid container item md={6} justifyContent={'end'}>
                                    {this.showAddButton(pricePerUnit, handlers)}
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        item
                                        md={2.5}
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        {this.showPricePerUnitInput(pricePerUnit, handlers)}
                                    </Grid>
                                    <Grid item md={4}>
                                        {this.showPerUnitText(totalAmount.unit)}
                                    </Grid>
                                    {this.props.graduatedPrices.length ? (
                                        <Grid container item md={12}>
                                            {this.showGraduatedPricesTitle()}
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                            {!!this.props.graduatedPrices.length &&
                                this.showGraduatedPrices(totalAmount.unit, handlers)}
                        </>
                    )}
                    <Grid container item style={{ margin: 0, padding: 0 }} md={12} alignItems="flex-start">
                        {this.showPriceUponRequestSwitch(pricePerUnit, handlers)}
                        <Grid
                            container
                            item
                            md={12}
                            alignItems="flex-start"
                            style={!this.props.isPriceHintVisible ? { marginTop: '0' } : { marginTop: '1rem' }}
                            className={this.props.classes.hintBox}
                        >
                            {this.showPriceUponRequestHint()}
                        </Grid>
                    </Grid>
                </>
            );
        }
    }
}

export default withTranslation(['offer', 'units'])(withStyles(styles, { withTheme: true })(PriceEditFieldsComponent));
