import * as React from 'react';
import { Fragment, ReactElement, ReactNode } from 'react';
import { IconButton, Skeleton, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ComponentBase } from 'resub';
import { MessageContextType, MessageDirection } from 'model/Message';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import { classNames } from 'util/style-helpers';
import QuoteReference from 'components/messaging/chat/references/QuoteReference';
import OfferReference from 'components/messaging/chat/references/OfferReference';
import RequestReference from 'components/messaging/chat/references/RequestReference';
import { Reply } from '@mui/icons-material';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import RequestTextReference from 'components/messaging/chat/references/RequestTextReference';
import HintComponent from 'components/hint/HintComponent';
import { theme } from 'style/NearbuyStyle';

const _styles = (theme: Theme) =>
    createStyles({
        container: {
            '&:hover > *': {
                '& > *': {
                    '& > *': {
                        '& > $quoteButton': {
                            opacity: 1,
                            transition: 'opacity .20s ease-in-out',
                            '-moz-transition': 'opacity .20s ease-in-out',
                            '-webkit-transition': 'opacity .20s ease-in-out',
                        },
                    },
                },
            },
            padding: '10px',
            '&:first-of-type': {
                paddingBottom: '0px',
            },
            '&:last-child': {
                paddingTop: '0px',
            },
        },
        message: {
            padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
        },
        root: {
            maxWidth: '64%',
            minWidth: '16%',
        },
        infoCard: {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        },
        skeletonOutbound: {
            height: '5.5rem',
            backgroundColor: theme.palette.primary.light,
            padding: 0,
            margin: 0,
        },
        skeletonInbound: {
            height: '5.5rem',
            padding: 0,
            margin: 0,
        },
        skeletonGrid: {
            height: '4rem',
            margin: 0,
            padding: 0,
        },
        foreignMessage: {
            borderRadius: '20px 20px 20px 4px',
            backgroundColor: theme.palette.grey['100'],
        },
        ownMessage: {
            borderRadius: '20px 20px 4px 20px',
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.getContrastText(theme.palette.primary.light),
            alignSelf: 'flex-end',
        },
        messageText: {
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line',
        },
        messageTopic: {
            display: '-webkit-box',
            fontWeight: 'bold',
            overflow: 'hidden',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 1,
            color: theme.palette.primary.dark,
        },
        infoCardTopic: {
            display: '-webkit-box',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            fontWeight: 'bold',
            overflow: 'hidden',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 1,
            color: theme.palette.primary.dark,
        },
        infoCardContent: {
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line',
            marginTop: theme.spacing(1),
        },
        // Watch out: The property $quoteButton is referenced for hover styling by another css class!
        quoteButton: {
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            opacity: 0,
            transition: 'opacity .20s ease-in-out',
            '-moz-transition': 'opacity .20s ease-in-out',
            '-webkit-transition': 'opacity .20s ease-in-out',
            padding: theme.spacing(0.5),
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
            height: '30px',
            width: '30px',
        },
        extras: {
            paddingBottom: theme.spacing(1),
        },
    });

export interface MessageItemBaseProps extends WithStyles<typeof _styles>, WithTranslation {
    data: MessageItemData;
}

export class MessageItemRenderer extends ComponentBase<MessageItemBaseProps> {
    showDate(date: Date): ReactElement {
        return <div>{moment(date).format('ll')}</div>;
    }

    showInfoBoxTopic(text?: string) {
        if (!text) return;

        return (
            <Grid item>
                <Typography className={this.props.classes.infoCardTopic}>{text}</Typography>
            </Grid>
        );
    }

    showInfoBoxContent(text?: string) {
        if (!text) return;

        return (
            <Grid item>
                <Typography className={this.props.classes.infoCardContent}>{text}</Typography>
            </Grid>
        );
    }

    showInfoBoxError(text?: string): ReactElement | undefined {
        if (!text) return;

        return (
            <Grid item>
                <Typography color={'red'}>{text}</Typography>
            </Grid>
        );
    }

    showMessageBox(): ReactElement | undefined {
        const isRequestMsg = this.props.data.message.contextType == MessageContextType.REQUEST;
        const topicOrQuote = this.props.data.quotedMessage ? (
            <QuoteReference quotedMessage={this.props.data.quotedMessage} />
        ) : this.props.data.messageBox.topic ? (
            <Typography className={this.props.classes.messageTopic}>{this.props.data.messageBox.topic}</Typography>
        ) : undefined;

        const contentSplit = this.props.data.messageBox.content?.split(':');

        const content = this.props.data.messageBox.content ? (
            this.props.data.message.contextType == 'REQUEST' && contentSplit && contentSplit.length >= 2 ? (
                <>
                    <Typography sx={{ color: theme.palette.primary.dark, fontWeight: '700' }} component={'span'}>
                        {`${contentSplit[0]}:`}
                    </Typography>
                    <Typography className={this.props.classes.messageText} component={'span'}>
                        {contentSplit.slice(1).join(':')}
                    </Typography>
                </>
            ) : (
                <Typography className={this.props.classes.messageText} component={'span'}>
                    {this.props.data.messageBox.content}
                </Typography>
            )
        ) : undefined;

        const offer =
            !isRequestMsg && this.props.data.message.links.offer ? (
                <OfferReference offerRef={this.props.data.message.links.offer} />
            ) : undefined;

        const request =
            !isRequestMsg && this.props.data.message.links.request ? (
                <RequestReference requestRef={this.props.data.message.links.request} />
            ) : undefined;

        const className =
            this.props.data.messageDirection == MessageDirection.OUTBOUND
                ? this.props.classes.ownMessage
                : this.props.classes.foreignMessage;

        if (topicOrQuote || content || offer || request) {
            return (
                <Fragment>
                    <Grid item className={classNames(this.props.classes.message, this.props.classes.root, className)}>
                        {topicOrQuote}
                        {content}
                        {offer}
                        {request}
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        textAlign={
                            this.props.data.messageDirection == MessageDirection.OUTBOUND && !isRequestMsg
                                ? 'end'
                                : 'start'
                        }
                    >
                        {content && !isRequestMsg && this.showReplyButton()}
                    </Grid>
                </Fragment>
            );
        }
    }

    showReplyButton(): ReactElement | undefined {
        if (this.props.data.isReadOnly) return;

        return (
            <IconButton
                aria-label={'quote'}
                className={this.props.classes.quoteButton}
                color="primary"
                onClick={(): void => this.props.data.onQuoteMessage(this.props.data.message)}
                size="large"
            >
                <Reply />
            </IconButton>
        );
    }

    showSkeleton(): ReactElement {
        return (
            <Grid
                container
                direction={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'row-reverse' : 'row'}
                className={this.props.classes.skeletonGrid}
            >
                <Grid item xs={9} className={this.props.classes.skeletonGrid}>
                    <Skeleton
                        variant="text"
                        animation="wave"
                        className={
                            this.props.data.messageDirection == 'INBOUND'
                                ? this.props.classes.skeletonInbound
                                : this.props.classes.skeletonOutbound
                        }
                    />
                </Grid>
            </Grid>
        );
    }

    showRequestTextReference(): ReactNode {
        if (!this.props.data.message.links.request) return null;
        const text =
            this.props.data.messageDirection == MessageDirection.OUTBOUND
                ? this.props.t('request:messageButtonTextSender')
                : this.props.t('request:messageButtonTextReceiver');
        return (
            <RequestTextReference
                requestRef={this.props.data.message.links.request}
                text={text}
                justifyContent={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'end' : 'start'}
            />
        );
    }

    showMessage(): ReactElement {
        const dateSection = (
            <Grid
                container
                item
                xs={9}
                direction={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'row-reverse' : 'row'}
            >
                <Grid
                    item
                    xs={11}
                    textAlign={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'end' : 'start'}
                >
                    {this.showDate(this.props.data.message.dateSent)}
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        );
        const infoBoxTitleAndContent = (
            <Grid
                container
                item
                xs={7}
                direction={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'row-reverse' : 'row'}
            >
                <Grid item xs={11} className={this.props.classes.infoCard}>
                    {this.showInfoBoxTopic(this.props.data.infoBox.topic)}
                    {this.showInfoBoxContent(this.props.data.infoBox.content)}
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        );
        const infoBoxCardAndButtons = (
            <Grid
                container
                item
                xs={7}
                direction={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'row-reverse' : 'row'}
                className={this.props.classes.extras}
            >
                <Grid item xs={11}>
                    {this.props.data.infoBox.card}
                    {this.props.data.infoBox.buttons}
                    {this.showInfoBoxError(this.props.data.infoBox.error)}
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        );
        const messageBox = (
            <Grid
                container
                item
                xs={this.props.data.message.contextType == 'REQUEST' ? 8.5 : 9}
                direction={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'row-reverse' : 'row'}
                sx={this.props.data.message.contextType == 'REQUEST' ? { marginBottom: 3 } : undefined}
            >
                {this.showMessageBox()}
            </Grid>
        );
        const requestNotAvailableHint = (
            <Grid
                container
                item
                xs={7}
                direction={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'row-reverse' : 'row'}
                className={this.props.classes.extras}
            >
                <HintComponent
                    text={this.props.t('messaging:requestNotAvailableHintText')}
                    isWarningSymbolShown={true}
                />
            </Grid>
        );

        const elements = new Array<ReactNode>();
        elements.push(this.props.data.dialogs);
        switch (this.props.data.message.contextType) {
            case MessageContextType.REQUEST:
                if (this.props.data.message.links.request) {
                    elements.push(dateSection);
                    elements.push(infoBoxTitleAndContent);
                    elements.push(this.showRequestTextReference());
                    this.props.data.message.content ? elements.push(messageBox) : null;
                    elements.push(infoBoxCardAndButtons);
                } else {
                    elements.push(dateSection);
                    elements.push(requestNotAvailableHint);
                }
                break;
            default:
                elements.push(dateSection);
                elements.push(infoBoxTitleAndContent);
                elements.push(infoBoxCardAndButtons);
                elements.push(messageBox);
                break;
        }

        return <>{elements}</>;
    }

    render(): React.ReactElement {
        return (
            <Grid
                item
                id={this.props.data.message.dateSent.toString()}
                container
                className={this.props.classes.container}
            >
                <Grid
                    container
                    direction={this.props.data.messageDirection == MessageDirection.OUTBOUND ? 'row-reverse' : 'row'}
                    xs={12}
                    item
                    className={this.props.classes.container}
                >
                    {this.props.data.isLoaded ? this.showMessage() : this.showSkeleton()}
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation(['messaging', 'purchaseIntent', 'order', 'error', 'request'])(
    withStyles(_styles, { withTheme: true })(MessageItemRenderer),
);
