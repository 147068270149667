import React from 'react';
import { ComponentBase } from 'resub';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Person } from 'model/Person';
import { Offer } from 'model/Offer';
import { Product } from 'model/Product';
import { PriceRequest } from 'model/PriceRequest';
import MessageItemRenderer from 'components/messaging/chat/messagefactory/MessageItemRenderer';
import { addPriceRequestMessageItemData } from 'components/messaging/chat/messagefactory/messageitemdata/PriceRequestMessageItemData';
import { OfferStore, PersonStore, PriceRequestStore, ProductStore } from 'store';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import { PreOrderTranslationMapping } from 'components/marketplace/PreOrderTranslationMapping';

interface PriceRequestMessageItemFactoryProps extends WithTranslation {
    baseData: MessageItemData;
}

interface PriceRequestMessageItemFactoryState {
    priceRequest?: PriceRequest;
    preOrderTranslationMapping?: PreOrderTranslationMapping;
    offer?: Offer;
    sellingPerson?: Person;
    buyingPerson?: Person;
    product?: Product;
    isDialogOpen: boolean;
    isProvidePriceDialogOpen: boolean;
    isTradeInfoDialogOpen: boolean;
}

class PriceRequestMessageItemFactory extends ComponentBase<
    PriceRequestMessageItemFactoryProps,
    PriceRequestMessageItemFactoryState
> {
    protected _buildState(
        props: PriceRequestMessageItemFactoryProps,
        initialBuild: boolean,
        incomingState: Readonly<PriceRequestMessageItemFactoryState> | undefined,
    ): Partial<PriceRequestMessageItemFactoryState> | undefined {
        const newState: Partial<PriceRequestMessageItemFactoryState> = {
            ...incomingState,
        };

        if (props.baseData.message.links.priceRequest) {
            newState.priceRequest = PriceRequestStore.getOne(props.baseData.message.links.priceRequest);
        }

        // OFFER
        if (newState.priceRequest && !newState.offer) {
            newState.offer = OfferStore.getOne(newState.priceRequest.links.offer);
        }

        // OFFER-MAPPING
        if (newState.offer && !newState.product) {
            newState.product = ProductStore.getOne(newState.offer.links.category);
        }

        if (newState.priceRequest && !newState.sellingPerson) {
            newState.sellingPerson = PersonStore.getOne(newState.priceRequest.links.sellingPerson);
        }

        if (newState.priceRequest && !newState.buyingPerson) {
            newState.buyingPerson = PersonStore.getOne(newState.priceRequest.links.buyingPerson);
        }

        if (
            newState.priceRequest &&
            newState.sellingPerson &&
            newState.buyingPerson &&
            newState.product &&
            !newState.preOrderTranslationMapping
        ) {
            newState.preOrderTranslationMapping = {
                firstNameSeller: newState.sellingPerson.firstname,
                lastNameSeller: newState.sellingPerson.lastname,
                firstNameBuyer: newState.buyingPerson.firstname,
                lastNameBuyer: newState.buyingPerson.lastname,
                product: this.props.t(`ontofood:${newState.product.label}`),
                totalPrice: newState.priceRequest.totalPrice?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            };
        }
        return newState;
    }

    onShowTradeInfoDialogCallBack = (value: boolean) => {
        this.setState({
            isTradeInfoDialogOpen: value,
        });
    };

    onShowDialogCallBack = (value: boolean) => {
        this.setState({
            isDialogOpen: value,
        });
    };

    onShowProvidePriceDialogCallBack = (value: boolean) => {
        this.setState({
            isProvidePriceDialogOpen: value,
        });
    };

    render(): React.ReactElement | null {
        return this.state.priceRequest && this.state.preOrderTranslationMapping ? (
            <MessageItemRenderer
                data={{
                    ...addPriceRequestMessageItemData(
                        this.props.baseData,
                        this.state.priceRequest,
                        this.props.t,
                        this.state.preOrderTranslationMapping,
                        this.onShowDialogCallBack,
                        this.onShowProvidePriceDialogCallBack,
                        this.onShowTradeInfoDialogCallBack,
                        this.state.isDialogOpen,
                        this.state.isProvidePriceDialogOpen,
                        this.state.isTradeInfoDialogOpen,
                    ),
                    isLoaded: true,
                }}
            />
        ) : !this.props.baseData.message.links.priceRequest ? (
            <MessageItemRenderer
                data={{
                    ...this.props.baseData,
                    messageBox: {
                        ...this.props.baseData.messageBox,
                        topic: this.props.t('dialogs:hint'),
                        content: this.props.t('messaging:messageNotDisplayable'),
                    },
                    isLoaded: true,
                }}
            />
        ) : (
            <MessageItemRenderer data={this.props.baseData} />
        );
    }
}

export default withTranslation('ontofood')(PriceRequestMessageItemFactory);
