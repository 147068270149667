import { forkJoin, Observable, of } from 'rxjs';
import { getUuidFromString } from 'util/helpers';
import http from 'api/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { AssortmentStore, CompanyAssortmentStore } from 'store';
import { Assortment, AssortmentWriteView, CompanyAssortment } from 'model/Assortment';

class AssortmentService {
    addAssortment(companyAssortment: AssortmentWriteView, companyId: string): Observable<Assortment> {
        return http
            .getAxios()
            .post<Assortment>('assortments/', companyAssortment, { headers: { 'Current-Company': companyId } })
            .pipe(
                map((response) => response.data),
                switchMap((assortment) =>
                    forkJoin({
                        assortment: of(assortment),
                        companyAssortment: CompanyAssortmentStore.loadOne(assortment.links.company),
                    }),
                ),
                switchMap((objects) => AssortmentStore.loadOne(objects.assortment.links.self)),
            );
    }

    getAssortment(assortmentRef: string): Observable<Assortment> {
        return http
            .getAxios()
            .get<Assortment>(`${assortmentRef}`)
            .pipe(map((value) => value.data));
    }

    getCompanyAssortment(companyRef: string): Observable<CompanyAssortment> {
        return http
            .getAxios()
            .get<string[]>('assortments/company/' + getUuidFromString(companyRef))
            .pipe(map((response) => new CompanyAssortment(companyRef, response.data)));
    }

    deleteAssortment(assortment: Assortment, companyId: string): Observable<unknown> {
        return http
            .getAxios()
            .delete(`${assortment.links.self}`, { headers: { 'Current-Company': companyId } })
            .pipe(
                tap(() =>
                    CompanyAssortmentStore.loadOne(assortment.links.company).subscribe(() =>
                        AssortmentStore.removeOneById(assortment.links.self),
                    ),
                ),
            );
    }
}

export default new AssortmentService();
