import * as React from 'react';
import { ComponentBase } from 'resub';
import { MatchingRequestSystemMessage } from 'model/SystemMessage';
import { withTranslation, WithTranslation } from 'react-i18next';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { OfferStore, ProductStore, RequestStore } from 'store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LocalisationProviderWrapper from 'components/routing/LocalisationProviderWrapper';
import RequestFullDetailDialog from 'components/search/result-items/requests/RequestFullDetailDialog';
import { Request } from 'model/Request';
import ExpiredProductDialog from 'components/search/result-items/ExpiredProductDialog';

interface MatchingRequestSystemMessageItemProps extends WithTranslation, RouteComponentProps {
    systemMessage: MatchingRequestSystemMessage;
}

interface MatchingRequestSystemMessageItemState {
    mapping?: MatchingRequestSystemMessageItemMapping;
    isDialogOpen?: boolean;
    request?: Request;
}

interface MatchingRequestSystemMessageItemMapping extends Record<string, string> {
    productName: string;
}

class MatchingRequestSystemMessageItem extends ComponentBase<
    MatchingRequestSystemMessageItemProps,
    MatchingRequestSystemMessageItemState
> {
    protected _buildState(
        props: MatchingRequestSystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<MatchingRequestSystemMessageItemState>,
    ): Partial<MatchingRequestSystemMessageItemState> | undefined {
        const offer = OfferStore.getOne(props.systemMessage.links.offer);
        const request = RequestStore.getOne(props.systemMessage.links.request);

        if (offer && request) {
            return {
                request: request,
                mapping: {
                    productName: props.t('ontofood:' + ProductStore.getOne(offer.links.category)?.label),
                },
            };
        }
    }

    openRequestDialog() {
        this.setState({ isDialogOpen: true });
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping || !this.state.request) return null;

        return (
            <>
                <LocalisationProviderWrapper>
                    <RequestFullDetailDialog
                        requestRef={this.props.systemMessage.links.request}
                        isOpen={(this.state.isDialogOpen && this.state.request.active) || false}
                        onClose={() => {
                            this.setState({ isDialogOpen: false });
                        }}
                    />
                </LocalisationProviderWrapper>
                <ExpiredProductDialog
                    expiredProductTitle={this.props.t('dialogs:expiredProductTitleRequest')}
                    isOpen={(this.state.isDialogOpen && !this.state.request.active) || false}
                    onClose={() => {
                        this.setState({ isDialogOpen: false });
                    }}
                />
                <SystemMessageItem
                    onClick={() => this.openRequestDialog()}
                    systemMessage={this.props.systemMessage}
                    mapping={this.state.mapping}
                />
            </>
        );
    }
}

export default withRouter(withTranslation(['ontofood', 'dialogs'])(MatchingRequestSystemMessageItem));
