import GradeService from 'api/GradeService';
import { Grade } from 'model/Grade';
import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';

@AutoSubscribeStore
class GradeStoreClass extends StoreBase {
    protected gradeMap: Map<string, Grade> = new Map<string, Grade>();

    @autoSubscribe
    getAll(): Grade[] | undefined {
        if (this.gradeMap.size > 0) {
            return Array.from(this.gradeMap.values());
        } else {
            this.fetchAll();
            return undefined;
        }
    }

    private fetchAll() {
        const newGradeMap = new Map<string, Grade>();
        GradeService.listGrades().subscribe((grades) => {
            grades.forEach((grade) => {
                newGradeMap.set(grade.links.self, grade);
            });
            this.gradeMap = newGradeMap;
            this.trigger();
        });
    }

    @autoSubscribe
    getOne(gradeRef: string): Grade | undefined {
        if (this.gradeMap.size > 0) {
            return this.gradeMap.get(gradeRef);
        } else {
            this.fetchAll();
            return undefined;
        }
    }

    setOne(grade: Grade): void {
        this.gradeMap.set(grade.links.self, grade);
        this.trigger();
    }

    invalidateCache() {
        this.fetchAll();
    }

    clear() {
        this.gradeMap = new Map<string, Grade>();
        this.trigger();
    }
}

export const GradeStore = new GradeStoreClass();
export default GradeStore;
