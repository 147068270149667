import { CssBaseline, StyledEngineProvider } from '@mui/material';
import NoSsr from '@mui/material/NoSsr';
import { ThemeProvider } from '@mui/material/styles';
import Keycloak from 'components/auth/Keycloak';
import InitialisationAssurance from 'components/initialization/InitialisationAssurance';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import ErrorBoundary from 'components/logging/ErrorBoundary';
import MaintenanceComponent from 'components/maintenance/MaintenanceComponent';
import SmallScreenGuard from 'components/mobile/SmallScreenGuard';
import NearbuyRouter from 'components/routing/NearbuyRouter';
import RouteListener from 'components/routing/RouteListener';
import Usersnap from 'components/usersnap/Usersnap';
import L from 'leaflet';
import 'moment/locale/de';

import 'typeface-quicksand';

import 'components/initialization';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
/* import styles for leaflet */
import 'leaflet/dist/leaflet.css';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import FlavouredTitle from 'style/FlavouredTitle';
import { NearbuyLoadingIndicator } from 'components/shared/NearbuyLoadingIndicator';
import * as serviceWorker from 'serviceWorker';
import * as nearbuyTheme from 'style/NearbuyStyle';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
});

InitializationManager.setPhase(InitializationPhase.LOADING);

if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    ReactDOM.render(
        <StyledEngineProvider injectFirst>
            <HelmetProvider>
                <ErrorBoundary>
                    <NoSsr>
                        <FlavouredTitle />
                        <Suspense fallback={<NearbuyLoadingIndicator />}>
                            <ThemeProvider theme={nearbuyTheme.theme}>
                                <CssBaseline />
                                <Usersnap />
                                <InitialisationAssurance phase={InitializationPhase.LOADED}>
                                    <SmallScreenGuard>
                                        <MaintenanceComponent />
                                    </SmallScreenGuard>
                                </InitialisationAssurance>
                            </ThemeProvider>
                        </Suspense>
                    </NoSsr>
                </ErrorBoundary>
            </HelmetProvider>
        </StyledEngineProvider>,
        document.getElementById('root'),
    );
} else {
    ReactDOM.render(
        <StyledEngineProvider injectFirst>
            <ErrorBoundary>
                <HelmetProvider>
                    <NoSsr>
                        <FlavouredTitle />
                        <ThemeProvider theme={nearbuyTheme.theme}>
                            <CssBaseline />
                            <Suspense fallback={<NearbuyLoadingIndicator />}>
                                <BrowserRouter>
                                    <RouteListener />
                                    <SmallScreenGuard>
                                        <Keycloak>
                                            <InitialisationAssurance
                                                oneOf={[
                                                    InitializationPhase.AUTHORIZED,
                                                    InitializationPhase.UNAUTHORIZED,
                                                ]}
                                            >
                                                <Usersnap />
                                                <NearbuyRouter />
                                            </InitialisationAssurance>
                                        </Keycloak>
                                    </SmallScreenGuard>
                                </BrowserRouter>
                            </Suspense>
                        </ThemeProvider>
                    </NoSsr>
                </HelmetProvider>
            </ErrorBoundary>
        </StyledEngineProvider>,
        document.getElementById('root'),
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
InitializationManager.setPhase(InitializationPhase.LOADED);
