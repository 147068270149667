import React, { PureComponent } from 'react';
import { WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            padding: '6px 12px',
            color: theme.palette.primary.dark,
            '&:hover': {
                backgroundColor: theme.palette.primary.lighter,
                padding: '6px 12px',
                color: theme.palette.primary.dark,
            },
            '&.Mui-disabled': {
                padding: '6px 12px',
                backgroundColor: theme.palette.common.white,
            },
        },
        myNearbuyButton: {
            padding: '6px 12px',
            color: theme.palette.primary.dark,
            '&.active': {
                padding: '6px 12px',
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.dark,
                '&:hover': {
                    padding: '6px 12px',
                    color: theme.palette.primary.dark,
                    backgroundColor: theme.palette.primary.lighter,
                },
            },
        },
    });

interface ToolbarButtonProps extends WithStyles<typeof styles>, WithTranslation {
    label: string;
    route?: string;
    disabled?: boolean;
    onClick?: () => void;
    onMouseEnter?: () => void;
}

class ToolbarButton extends PureComponent<ToolbarButtonProps> {
    render(): React.ReactElement {
        return this.wrapButton(
            <Typography variant={'h6'} fontWeight={'600'} textTransform={'none'} fontSize={'1.5em'}>
                {this.props.t(this.props.label)}
            </Typography>,
        );
    }

    wrapButton(child: JSX.Element): JSX.Element {
        if (this.props.route)
            return (
                <Button
                    component={NavLink}
                    className={
                        this.props.label == this.props.t('dashboard:my_nearbuy')
                            ? this.props.classes.myNearbuyButton
                            : this.props.classes.button
                    }
                    disabled={this.props.disabled}
                    to={this.props.route}
                    onClick={() => {
                        if (this.props.onClick) this.props.onClick();
                    }}
                    onMouseEnter={() => {
                        if (this.props.onMouseEnter) this.props.onMouseEnter();
                    }}
                >
                    {child}
                </Button>
            );
        return (
            <Button
                className={this.props.classes.button}
                disabled={this.props.disabled}
                onClick={() => {
                    if (this.props.onClick) this.props.onClick();
                }}
                onMouseEnter={() => {
                    if (this.props.onMouseEnter) this.props.onMouseEnter();
                }}
            >
                {child}
            </Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withTranslation('common')(ToolbarButton));
