import MarketItemDialog from 'components/marketplace/marketitem/dialog/MarketItemDialog';
import * as React from 'react';
import { ComponentBase } from 'resub';
import { OfferStore } from 'store';
import { Offer } from 'model/Offer';
import OfferService from 'api/OfferService';
import OfferReferenceItem from 'components/dashboard/marketplace/offer/OfferReferenceComponent';

interface OfferDashletItemProps {
    offerRef: string;
    isOfferProvided?: boolean;
    isChosen?: boolean;
}

interface OfferDashletItemState {
    offer?: Offer;
    editDialogOpen?: boolean;
}

class OfferDashletItem extends ComponentBase<OfferDashletItemProps, OfferDashletItemState> {
    protected _buildState(
        props: OfferDashletItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<OfferDashletItemState>,
    ): Partial<OfferDashletItemState> | undefined {
        return {
            offer: OfferStore.getOne(props.offerRef),
        };
    }

    openEditDialog(): void {
        this.setState({ editDialogOpen: true });
    }

    closeEditDialog(): void {
        this.setState({ editDialogOpen: false });
    }

    render(): React.ReactElement | null {
        if (!this.state.offer) {
            return null;
        }
        return (
            <>
                <MarketItemDialog
                    companyRef={this.state.offer.links.company}
                    offerRef={this.state.offer.links.self}
                    isOpen={this.state.editDialogOpen || false}
                    onClose={() => {
                        this.closeEditDialog();
                    }}
                    onDelete={() => {
                        if (this.state.offer) {
                            OfferService.deleteOffer(this.state.offer.id, this.state.offer.links.company).subscribe(
                                () => {
                                    this.closeEditDialog();
                                },
                            );
                        }
                    }}
                    isReadOnly={false}
                    isOfferProvided={this.props.isOfferProvided}
                    calledFrom={`OfferDashletItem-${this.props.isOfferProvided ? 'SendOffer' : 'Dashboard'}`}
                />
                <OfferReferenceItem
                    offerRef={this.props.offerRef}
                    onClick={() => this.openEditDialog()}
                    isProvideOfferListItem={this.props.isOfferProvided}
                    isChosen={this.props.isChosen}
                />
            </>
        );
    }
}

export default OfferDashletItem;
