import { Observable } from 'rxjs';
import http from 'api/http';
import { CompanyContacts, Contact, ContactWriteView } from 'model/Contact';
import { map, tap } from 'rxjs/operators';
import { CompanyContactsStore, ContactsStore } from 'store';

class ContactService {
    public getContact(contactRef: string): Observable<Contact> {
        return http
            .getAxios()
            .get<Contact>(contactRef)
            .pipe(map((value) => value.data));
    }

    public updateContact(contactRef: string, contact: ContactWriteView): Observable<Contact> {
        return http
            .getAxios()
            .put<Contact>(contactRef, contact)
            .pipe(
                map((value) => value.data),
                tap((data) => ContactsStore.setOne(data)),
                tap((data) => CompanyContactsStore.loadOne(data.links.company)),
            );
    }

    public createContact(employmentRef: string, contact: ContactWriteView): Observable<Contact> {
        return http
            .getAxios()
            .post<Contact>(employmentRef + '/contact', contact)
            .pipe(
                map((value) => value.data),
                tap((data) => CompanyContactsStore.loadOne(data.links.company)),
                tap((createdContact) => ContactsStore.invalidateSearch(createdContact.links.company)),
            );
    }

    public deleteContact(contactRef: string): Observable<number> {
        return http
            .getAxios()
            .delete<number>(contactRef)
            .pipe(
                map((value) => value.data),
                tap(() => ContactsStore.removeOneById(contactRef)),
                tap(() => CompanyContactsStore.invalidateCache()),
                tap(() => ContactsStore.invalidateSearch()),
            );
    }

    getContactIds(companyRef: string): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>(companyRef + '/contacts')
            .pipe(map((resp) => resp.data));
    }

    getContacts(companyRef: string): Observable<CompanyContacts> {
        return this.getContactIds(companyRef).pipe(map((ids) => new CompanyContacts(companyRef, ids)));
    }
}

export default new ContactService();
