import * as React from 'react';
import { ComponentBase } from 'resub';
import { RequestStore } from 'store';
import { Request } from 'model/Request';
import RequestService from 'api/RequestService';
import RequestReferenceComponent from 'components/dashboard/marketplace/request/RequestReferenceComponent';
import MarketItemDialog from 'components/marketplace/marketitem/dialog/MarketItemDialog';

interface RequestDashletItemProps {
    requestRef: string;
}

interface RequestDashletItemState {
    request?: Request;
    editDialogOpen?: boolean;
}

class RequestDashletItem extends ComponentBase<RequestDashletItemProps, RequestDashletItemState> {
    protected _buildState(
        props: RequestDashletItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<RequestDashletItemState>,
    ): Partial<RequestDashletItemState> | undefined {
        return {
            request: RequestStore.getOne(props.requestRef),
        };
    }

    openEditDialog(): void {
        this.setState({ editDialogOpen: true });
    }

    closeEditDialog(): void {
        this.setState({ editDialogOpen: false });
    }

    render(): React.ReactElement | null {
        if (!this.state.request) {
            return null;
        }
        return (
            <>
                <MarketItemDialog
                    companyRef={this.state.request.links.company}
                    requestRef={this.state.request.links.self}
                    isOpen={this.state.editDialogOpen || false}
                    onClose={() => {
                        this.closeEditDialog();
                    }}
                    onDelete={() => {
                        if (this.state.request) {
                            RequestService.deleteRequest(
                                this.state.request.links.remove,
                                this.state.request.links.self,
                                this.state.request.links.company,
                            ).subscribe(() => {
                                this.closeEditDialog();
                            });
                        }
                    }}
                    isReadOnly={false}
                    calledFrom={'RequestDashletItem'}
                />
                <RequestReferenceComponent requestRef={this.props.requestRef} onClick={() => this.openEditDialog()} />
            </>
        );
    }
}

export default RequestDashletItem;
