import { ListAlt } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Switch, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import UserSettingsService from 'api/UserSettingsService';
import TradeTableRow from 'components/marketplace/tradetable/TradeTableRow';
import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import { PurchaseIntent, PurchaseIntentStatus } from 'model/PurchaseIntent';
import { SettingsKey } from 'model/UserSettings';
import moment from 'moment';
import { Moment } from 'moment/moment';
import * as React from 'react';
import { ReactNode, SyntheticEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ComponentBase } from 'resub';
import {
    CompanyStore,
    OrderStore,
    PersonStore,
    PriceRequestStore,
    ProductStore,
    PurchaseIntentStore,
    UserSettingsStore,
} from 'store';
import { theme } from 'style/NearbuyStyle';
import { getParsedQueryString } from 'util/helpers';
import NearbuyTabsWindow from 'components/shared/NearbuyTabsWindowOld';
import { SelectChangeEvent } from '@mui/material/Select';
import TradeTableHeaderSelect from 'components/marketplace/tradetable/TradeTrableHeaderSelect';
import {
    applyFilter,
    filterMapBySearchString,
    getActiveNavBarTitleLocaleTag,
    getItemsToShowSearchMap,
    getPlaceHolderLocaleTag,
    getSortSettingKey,
    getStatusSortOptions,
    getSwitchSettingKey,
    getTradeItemType,
    getTradeRole,
    getTradeType,
    sortItems,
} from 'components/marketplace/tradetable/TradeTableHelpers';
import {
    TradeTableFilter,
    TradeTableItems,
    TradeTableTab,
    TradeType,
} from 'components/marketplace/tradetable/TradeTableTypes';
import { PriceRequest, PriceRequestStatus } from 'model/PriceRequest';
import { Order } from 'model/Order';
import { getSortSettingPrefixAndValue } from 'util/TableHelpers';
import { captureWebEvent } from 'util/AnalyticUtils';

const styles = (theme: Theme) =>
    createStyles({
        tableHeader: {
            backgroundColor: theme.palette.background.default,
        },
        tableHeaderCell: {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            padding: theme.spacing(2),
        },
        boldTypography: {
            fontWeight: 'bold',
            zIndex: 99,
            color: theme.palette.primary.dark,
        },
        searchBar: {
            width: '100%',
            marginTop: 0,
            '& .MuiOutlinedInput-root': {
                paddingLeft: theme.spacing(1),
            },
        },
        div: {
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: theme.spacing(3),
            paddingTop: 0,
        },
        selectInput: {
            paddingBottom: 0,
            paddingTop: 0,
            margin: 0,
            height: '89%',
            width: '100%',
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: 5,
        },
        searchIcon: {
            color: theme.palette.primary.dark,
        },
    });

interface TradeTableComponentProperties extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps {}

interface TradeTableComponentState {
    ownUserRef: string;
    ownCompanyRef: string;
    filter: TradeTableFilter;
    items: TradeTableItems;
    isResultsButtonShown: boolean;
    isReducedView: boolean;
    location: string;
    areOnlyCurrentUserResultsShown: boolean;
    initialBuild: boolean;
    setting: boolean;
    areForeignResultsAvailable: boolean;
    currentSwitchSettingsKey: SettingsKey;
    currentSortSettingsKey: SettingsKey;
    currentSortSetting: string | null | undefined;
    currentSortSettingPrefix: string | undefined;
    currentSortSettingValue: string | undefined;
    refToTranslatedProducts: Map<string, string>;
    refToCompanyNames: Map<string, string>;
    areItemsToShowWithoutStatusFilter: boolean;
    searchString: string;
}

class TradeTableComponent extends ComponentBase<TradeTableComponentProperties, TradeTableComponentState> {
    protected _buildState(
        props: TradeTableComponentProperties,
        initialBuild: boolean,
        incomingState: Readonly<TradeTableComponentState> | undefined,
    ): Partial<TradeTableComponentState> | undefined {
        const newState: Partial<TradeTableComponentState> = {
            ...incomingState,
        };

        if (initialBuild) {
            newState.areItemsToShowWithoutStatusFilter = false;
            newState.areForeignResultsAvailable = false;
            newState.initialBuild = initialBuild;
            newState.filter = {
                tradeType: TradeType.SALES_REQUESTS,
                dateFrom: moment().subtract(1, 'months').date(1),
                dateUntil: moment(),
                tab: TradeTableTab.ACTIVE,
            };
            newState.items = {
                itemsToShow: [],
                purchaseIntents: [],
                priceRequests: [],
                orders: [],
            };
        }

        newState.ownCompanyRef = CompanyStore.getSelected()?.links.self;

        if (
            !newState.filter ||
            !newState.items ||
            !newState.filter.dateFrom ||
            !newState.filter.dateUntil ||
            !newState.ownCompanyRef
        )
            return newState;

        if (newState.filter.tradeType) {
            newState.currentSwitchSettingsKey = getSwitchSettingKey(newState.filter.tradeType);

            newState.areOnlyCurrentUserResultsShown =
                UserSettingsStore.getOne(newState.currentSwitchSettingsKey)?.settings == 'true';

            newState.currentSortSettingsKey = getSortSettingKey(newState.filter);
            newState.currentSortSetting = UserSettingsStore.getOne(newState.currentSortSettingsKey)?.settings?.replace(
                /['"]+/g,
                '',
            );

            [newState.currentSortSettingPrefix, newState.currentSortSettingValue] = getSortSettingPrefixAndValue(
                newState.currentSortSetting,
            );
        }

        const oldTradeType = newState.filter.tradeType;
        newState.filter.tradeType = getTradeType(props.location);
        if (oldTradeType != newState.filter.tradeType) {
            newState.filter.tab = TradeTableTab.ACTIVE;
        }

        const selectedPurchaseIntent = PurchaseIntentStore.getSelected();
        newState.items.purchaseIntents = PurchaseIntentStore.search({
            dateFrom: newState.filter.dateFrom,
            dateUntil: newState.filter.dateUntil,
        });

        const selectedPriceRequest = PriceRequestStore.getSelected();
        newState.items.priceRequests = PriceRequestStore.search({
            dateFrom: newState.filter.dateFrom,
            dateUntil: newState.filter.dateUntil,
        });

        const selectedOrder = OrderStore.getSelected();
        newState.items.orders = OrderStore.search({
            dateFrom: newState.filter.dateFrom,
            dateUntil: newState.filter.dateUntil,
        });

        if (
            (selectedPurchaseIntent && selectedPurchaseIntent.status == PurchaseIntentStatus.PENDING) ||
            (selectedPurchaseIntent && selectedPurchaseIntent.status == PurchaseIntentStatus.ACCEPTED) ||
            (selectedPriceRequest && selectedPriceRequest.status == PriceRequestStatus.PENDING) ||
            (selectedPriceRequest && selectedPriceRequest.status == PriceRequestStatus.PRICE_ADDED) ||
            (selectedOrder && !selectedOrder?.isCompleted)
        ) {
            newState.filter.tab = TradeTableTab.ACTIVE;
        } else if (selectedPurchaseIntent || selectedPriceRequest || selectedOrder) {
            newState.filter.tab = TradeTableTab.TERMINATED;
        }

        if (
            !initialBuild &&
            newState.location != window.location.pathname &&
            (selectedOrder || selectedPurchaseIntent || selectedPriceRequest)
        ) {
            newState.filter.tab = TradeTableTab.ACTIVE;
            PriceRequestStore.setSelected(undefined);
            PurchaseIntentStore.setSelected(undefined);
            OrderStore.setSelected(undefined);
        }
        newState.location = window.location.pathname;

        newState.isReducedView =
            selectedOrder != undefined || selectedPurchaseIntent != undefined || selectedPriceRequest != undefined;

        const parsedQueryString = getParsedQueryString(this.props.history.location.search);
        const isCompleted = parsedQueryString['completed'] == 'true';

        newState.filter = {
            ...newState.filter,
            tab: isCompleted ? TradeTableTab.TERMINATED : TradeTableTab.ACTIVE,
        };

        newState.items.itemsToShow = applyFilter(newState.items, newState.filter, newState.ownCompanyRef);

        if (
            newState.items &&
            newState.items.itemsToShow &&
            !!newState.searchString &&
            newState.searchString.length > 1
        ) {
            const itemsToShowMap = getItemsToShowSearchMap(
                newState.items.itemsToShow,
                this.isUserBuyerInCurrentLocation.bind(this),
                this.isUserSellerInCurrentLocation.bind(this),
                props.t,
            );
            newState.items.itemsToShow = filterMapBySearchString(
                newState.items.itemsToShow,
                itemsToShowMap,
                newState.searchString,
            );
        }

        const isOrder = (item: PurchaseIntent | PriceRequest | Order): item is Order => {
            return (item as Order).productLabel !== undefined;
        };

        newState.areItemsToShowWithoutStatusFilter = newState.items.itemsToShow.length > 0;

        newState.refToCompanyNames = new Map<string, string>();
        newState.refToTranslatedProducts = new Map<string, string>();
        newState.items.itemsToShow.forEach((item) => {
            const companyName =
                getTradeRole(item, newState.ownCompanyRef!) == 'SELLER'
                    ? item.links.buyingCompany
                        ? CompanyStore.getOne(item.links.buyingCompany)?.name
                        : props.t('company:deletedCompany')
                    : item.links.sellingCompany
                      ? CompanyStore.getOne(item.links.sellingCompany)?.name
                      : props.t('company:deletedCompany');
            if (companyName) {
                newState.refToCompanyNames!.set(item.links.self, companyName);
            }
            const productLabel = item.links.category
                ? ProductStore.getOne(item.links.category)?.label
                : isOrder(item)
                  ? item.productLabel
                  : undefined;
            if (productLabel) {
                const translatedProduct = props.t(`ontofood:${productLabel}`);
                newState.refToTranslatedProducts!.set(item.links.self, translatedProduct);
            }
        });

        newState.items.itemsToShow = sortItems(
            newState.items.itemsToShow,
            newState.currentSortSettingPrefix,
            newState.currentSortSettingValue,
            newState.refToCompanyNames,
            newState.refToTranslatedProducts,
        );

        newState.isResultsButtonShown = newState.isReducedView && newState.items.itemsToShow.length > 1;

        if (newState.isReducedView) {
            newState.items.itemsToShow = [selectedPurchaseIntent ?? selectedPriceRequest ?? selectedOrder!];
        }

        const currentUser = PersonStore.getSelected();
        if (currentUser) {
            const filteredItems = newState.items.itemsToShow.filter(
                (item) =>
                    item.links.buyingPerson == currentUser.links.self ||
                    item.links.sellingPerson == currentUser.links.self,
            );
            newState.areForeignResultsAvailable =
                newState.items.itemsToShow.reduce(
                    (acc, item) =>
                        acc ||
                        item.links.buyingPerson == currentUser.links.self ||
                        item.links.sellingPerson == currentUser.links.self,
                    false,
                ) && filteredItems.length != newState.items.itemsToShow.length;
            if (newState.areOnlyCurrentUserResultsShown && newState.areForeignResultsAvailable) {
                newState.items.itemsToShow = filteredItems;
            }
        }

        if (!newState.areForeignResultsAvailable && newState.areOnlyCurrentUserResultsShown)
            newState.areOnlyCurrentUserResultsShown = false;

        return newState;
    }

    resetToNormalView() {
        PriceRequestStore.setSelected(undefined);
        PurchaseIntentStore.setSelected(undefined);
        OrderStore.setSelected(undefined);
        this.setState({
            isReducedView: false,
            isResultsButtonShown: false,
        });
    }

    handleTabChange = (_event: SyntheticEvent, value: number): void => {
        if (this.state.isReducedView) {
            this.resetToNormalView();
        }
        this.props.history.replace(`?completed=${value != 0}`);
    };

    isUserBuyerInCurrentLocation(): boolean {
        return this.state.filter.tradeType.toString().startsWith('PURCHASE');
    }

    isUserSellerInCurrentLocation(): boolean {
        return this.state.filter.tradeType.toString().startsWith('SALES');
    }

    showTableItems(): React.ReactFragment | null {
        if (!this.state.items.itemsToShow) return null;
        return this.state.items.itemsToShow.map((tradeItem, index) => (
            <TradeTableRow
                key={`${index}_${tradeItem.links.self}`}
                role={this.isUserBuyerInCurrentLocation() ? 'BUYER' : 'SELLER'}
                purchaseIntentRef={tradeItem.links.self.includes('purchase_intents') ? tradeItem.links.self : undefined}
                priceRequestRef={tradeItem.links.self.includes('price_requests') ? tradeItem.links.self : undefined}
                orderRef={tradeItem.links.self.includes('orders') ? tradeItem.links.self : undefined}
                styling={
                    index % 2
                        ? { background: theme.palette.background.default }
                        : { background: theme.palette.common.white }
                }
                isCollapseOpen={this.state.isReducedView}
            />
        ));
    }
    handleDateFromChange(date: Moment): void {
        if (date) {
            captureWebEvent('my_market-tradeTable-dateFrom-change');
            this.setState({
                filter: {
                    ...this.state.filter,
                    dateFrom: date,
                },
            });
        }
    }

    handleDateEndChange(date: Moment): void {
        if (date) {
            captureWebEvent('my_market-tradeTable-dateEnd-change');
            this.setState({
                filter: {
                    ...this.state.filter,
                    dateUntil: date,
                },
            });
        }
    }

    showDatePicker(): ReactNode {
        const itemType = getTradeItemType(this.state.filter.tradeType);
        return (
            <>
                <Grid item md={2} container sx={{ paddingBottom: theme.spacing(0.75), paddingRight: theme.spacing(1) }}>
                    <StyledDatePickerComponent
                        disableMaskedInput
                        label={this.props.t(`sales:${itemType.charAt(0).toLowerCase() + itemType.slice(1)}sFrom`)}
                        value={this.state.filter.dateFrom}
                        onChange={(date): void => this.handleDateFromChange(date as Moment)}
                        renderInput={(props) => (
                            <TextField
                                sx={{
                                    button: { backgroundColor: 'transparent' },
                                }}
                                {...props}
                            />
                        )}
                        maxDate={this.state.filter.dateUntil}
                    />
                </Grid>
                <Grid item md={2} container sx={{ paddingBottom: theme.spacing(0.75) }}>
                    <StyledDatePickerComponent
                        disableMaskedInput
                        label={this.props.t(`sales:${itemType.charAt(0).toLowerCase() + itemType.slice(1)}sUntil`)}
                        value={this.state.filter.dateUntil}
                        onChange={(date): void => this.handleDateEndChange(date as Moment)}
                        renderInput={(props) => (
                            <TextField
                                sx={{
                                    button: { backgroundColor: 'transparent' },
                                }}
                                {...props}
                            />
                        )}
                        minDate={this.state.filter.dateFrom}
                        maxDate={moment()}
                    />
                </Grid>
            </>
        );
    }

    showSearchBar(): ReactNode {
        return (
            <Grid item md={3} sx={{ paddingRight: theme.spacing(1) }}>
                <TextField
                    placeholder={this.props.t('preOrder:search')}
                    className={this.props.classes.searchBar}
                    size={'small'}
                    onClick={() => {
                        captureWebEvent('my_market-tradeTable-enter-search-field');
                    }}
                    onChange={(event) => this.setState({ searchString: event.target.value })}
                    InputProps={{
                        startAdornment: (
                            <IconButton
                                sx={{
                                    backgroundColor: 'transparent',
                                    paddingLeft: 0,
                                    paddingRight: theme.spacing(0.5),
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <SearchIcon className={this.props.classes.searchIcon} />
                            </IconButton>
                        ),
                    }}
                />
            </Grid>
        );
    }

    showHeaderActions(): React.ReactFragment {
        return (
            <Grid container className={this.props.classes.div} direction={'row'}>
                <Grid container item md={9} alignItems={'center'}>
                    {this.showSearchBar()}
                    {this.showDatePicker()}
                    {this.showSwitch()}
                </Grid>
                {this.showResultsButton()}
            </Grid>
        );
    }

    handleSortSettingChange(event: SelectChangeEvent) {
        const sortValue = [event.target.name, event.target.value].join('_');

        UserSettingsService.saveSetting(
            this.state.currentSortSettingsKey,
            event.target.value != 'NONE' ? sortValue : null,
        ).subscribe(() => {
            UserSettingsStore.invalidateCache(this.state.currentSortSettingsKey);
            this.setState({
                currentSortSetting: event.target.value != 'NONE' ? sortValue : null,
            });
        });
    }

    getTitleRow() {
        const productTitle = this.props.t('common:product');
        const dateTitle = this.props.t(this.getNameSpace() + ':requestDate');
        const companyTitle = this.props.t('common:COMPANY');
        const statusTitle = this.props.t(this.getNameSpace() + ':status');
        const titles: (string | undefined)[] = [];
        titles.push(productTitle);
        titles.push(this.props.t('offer:amount'));
        titles.push(this.props.t('offer:pricePerUnit'));
        titles.push(this.props.t('offer:totalPrice'));
        titles.push(dateTitle);
        titles.push(companyTitle);
        titles.push(statusTitle);
        titles.push(this.props.t('company:responsiblePerson'));
        titles.push(undefined);

        return (
            <TableRow>
                {titles.map((title, index) => {
                    let name = 'DEFAULT';
                    let onlyTitle = false;
                    let options: string[] = ['ASC', 'DESC', 'NONE'];
                    switch (title) {
                        case productTitle:
                            name = 'PRODUCT';
                            break;
                        case dateTitle:
                            name = 'DATE';
                            break;
                        case companyTitle:
                            name = 'COMPANY';
                            break;
                        case statusTitle:
                            name = 'STATUS';
                            options = getStatusSortOptions(this.state.filter);
                            break;
                        default:
                            onlyTitle = true;
                    }

                    return (
                        <TableCell className={this.props.classes.tableHeaderCell} key={title ?? index}>
                            {onlyTitle ? (
                                <Typography fontWeight={'bold'} fontSize={14} noWrap>
                                    {title}
                                </Typography>
                            ) : (
                                <TradeTableHeaderSelect
                                    name={name}
                                    title={title}
                                    options={options}
                                    currentSortSettingPrefix={this.state.currentSortSettingPrefix}
                                    currentSortSettingValue={this.state.currentSortSettingValue}
                                    isUserBuyer={this.isUserBuyerInCurrentLocation()}
                                    tradeItemType={this.getNameSpace() == 'preOrder' ? 'PreOrder' : 'Order'}
                                    handleSortSettingChange={this.handleSortSettingChange.bind(this)}
                                />
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }

    getNameSpace(): 'order' | 'preOrder' {
        return getTradeItemType(this.state.filter.tradeType) == 'Order' ? 'order' : 'preOrder';
    }

    showResultsButton(): React.ReactFragment | null {
        return this.state.isResultsButtonShown ? (
            <>
                <Grid sx={{ paddingTop: theme.spacing(0.5) }}>
                    <Button
                        variant={'outlined'}
                        startIcon={<ListAlt />}
                        onClick={() => {
                            this.resetToNormalView();
                        }}
                    >
                        {this.props.t('sales:showAllResults')}
                    </Button>
                </Grid>
            </>
        ) : null;
    }

    handleShowResultsByCurrentUserChange(checked: boolean): void {
        UserSettingsService.saveSetting(this.state.currentSwitchSettingsKey, checked).subscribe(() => {
            UserSettingsStore.invalidateCache(this.state.currentSwitchSettingsKey);
            this.setState({
                areOnlyCurrentUserResultsShown: checked,
            });
        });
    }

    showSwitch(): React.ReactFragment | null {
        if (!this.state.areForeignResultsAvailable) return null;

        return (
            <>
                <Grid item container md={1} justifyContent={'end'}>
                    <Switch
                        checked={
                            this.state.areOnlyCurrentUserResultsShown
                                ? this.state.areOnlyCurrentUserResultsShown
                                : false
                        }
                        onChange={(event) => this.handleShowResultsByCurrentUserChange(event.target.checked)}
                    />{' '}
                </Grid>
                <Grid item container md={4}>
                    <Typography>{this.props.t('sales:showOnlyMyResults')}</Typography>
                </Grid>
            </>
        );
    }

    showPlaceHolderText(): ReactNode {
        return (
            <Typography
                sx={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    zIndex: 99,
                    textAlign: 'center',
                    color: theme.palette.primary.dark,
                }}
                data-testid={'empty-state-text'}
            >
                {this.state.searchString
                    ? this.props.t('sales:emptyTableBySearch')
                    : this.props.t(getPlaceHolderLocaleTag(this.state.filter))}
            </Typography>
        );
    }

    render(): React.ReactElement | null {
        if (!this.state.filter.tradeType || !this.state.items) return null;
        const titles = [`${this.props.t('common:active')}`, `${this.props.t('common:terminated')}`];
        return (
            <NearbuyTabsWindow
                maxWidth={'fullWidth'}
                title={this.props.t(getActiveNavBarTitleLocaleTag(this.state.filter.tradeType))}
                titles={titles}
                handleChange={this.handleTabChange}
                activeTabIndex={this.state.filter.tab == TradeTableTab.ACTIVE ? 0 : 1}
            >
                <Grid>
                    {this.showHeaderActions()}
                    {this.state.items.itemsToShow.length > 0 || this.state.areItemsToShowWithoutStatusFilter ? (
                        <Box
                            sx={{
                                minHeight: '20vh',
                                maxHeight: '64vh',
                                width: '100%',
                                overflow: 'auto',
                                borderRadius: '12px',
                            }}
                        >
                            <Table
                                sx={{
                                    tableLayout: 'auto',
                                }}
                            >
                                <TableHead className={this.props.classes.tableHeader}>{this.getTitleRow()}</TableHead>
                                <TableBody>{this.showTableItems()}</TableBody>
                            </Table>
                        </Box>
                    ) : (
                        <Grid container justifyContent={'center'} alignItems={'center'} sx={{ padding: '16%' }}>
                            {this.showPlaceHolderText()}
                        </Grid>
                    )}
                </Grid>
            </NearbuyTabsWindow>
        );
    }
}

export default withTranslation(['offer', 'common', 'sales', 'preOrder', 'company', 'ontofood'])(
    withStyles(styles, { withTheme: true })(withRouter(TradeTableComponent)),
);
