import { ContainerView, ContainerWriteView } from 'model/ContainerView';
import { Observable } from 'rxjs';
import http from 'api/http';
import { map, tap } from 'rxjs/operators';
import OfferService from 'api/OfferService';
import { ContainerStore } from 'store';

class ContainerService {
    public getContainer(containerRef: string): Observable<ContainerView> {
        return http
            .getAxios()
            .get<ContainerView>(containerRef)
            .pipe(map((value) => value.data));
    }

    addContainer(companyRef: string, container: ContainerWriteView, offerRef: string): Observable<ContainerView> {
        return http
            .getAxios()
            .post<ContainerView>(companyRef + '/containers', container)
            .pipe(
                map((response) => response.data),
                tap((_container) => OfferService.addContainerToOffer(offerRef, _container.id).subscribe()),
                tap((container) => ContainerStore.loadOne(container.links.self)),
            );
    }
}

export default new ContainerService();
