import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Flavours, getFlavour, getFlavouredPublicImport, getFlavouredTitle } from 'style/flavour-helper';

export default class FlavouredTitle extends React.PureComponent {
    getFaviconFileEnding(): string {
        switch (getFlavour()) {
            case Flavours.AROMA:
                return 'png';
            default:
                return 'ico';
        }
    }

    render(): React.ReactNode {
        return (
            <Helmet>
                <title>{getFlavouredTitle()}</title>
                <link
                    rel="shortcut icon"
                    href={
                        process.env.PUBLIC_URL +
                        '/flavoured/' +
                        getFlavouredPublicImport() +
                        '/favicon.' +
                        this.getFaviconFileEnding()
                    }
                />
                {/*manifest.json provides metadata used when your web app is installed on a*/}
                {/*user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/*/}
                <link
                    rel="manifest"
                    href={process.env.PUBLIC_URL + 'flavoured/' + getFlavouredPublicImport() + '/manifest.json'}
                />
            </Helmet>
        );
    }
}
