import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MediationIcon from '@mui/icons-material/Mediation';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { withStyles, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ReactComponent as ProcessorIcon } from 'icons/Windmill_24px.svg';
import { Address } from 'model/Address';
import { AssociationCategory } from 'model/Association';
import { Company } from 'model/Company';
import { DownloadLink } from 'model/Image';
import { CompanyMemberships, Membership } from 'model/Membership';
import { CompanyRoles, NearbuyRole } from 'model/NearbuyRole';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import {
    AddressStore,
    AssociationStore,
    CertificateDownloadLinkStore,
    CompanyMembershipsStore,
    CompanyRolesStore,
    CompanyStore,
    MembershipStore,
} from 'store';
import { Typography } from '@mui/material';
import { Fax, LinkRounded, Mail, Phone, Verified, Warehouse } from '@mui/icons-material';
import { ExtendedTheme, theme } from 'style/NearbuyStyle';
import { doArraysIntersect, getValidUrl } from 'util/helpers';
import { classNames } from 'util/style-helpers';
import MembershipService from 'api/MembershipService';
import PublicAssociationPopOverComponent from 'components/company/view/association/PublicAssociationPopOverComponent';
import CompanyPresentationCertificationsComponent from 'components/company/view/CompanyPresentationCertificationsComponent';

const styles = (theme: ExtendedTheme) =>
    createStyles({
        boldTypography: {
            textDecoration: 'none',
            fontWeight: '600',
            color: theme.palette.primary.dark,
        },
        overflowRules: {
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            textOverflow: 'ellipsis',
            minWidth: '25px',
        },
        hoverOver: {
            '&:hover': {
                overflow: 'visible',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                hyphens: 'auto',
            },
        },
        companyNameResponsiveness: {
            maxHeight: '6em',
            maxWidth: '85%',
            marginRight: theme.spacing(0.5),
            fontSize: '20px',
            fontWeight: 700,
        },
        addressResponsiveness: {
            wordWrap: 'break-word',
            fontSize: '14px',
            hyphens: 'auto',
            maxWidth: '16rem',
        },
        companyDetailsHover: {
            padding: '6px',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: theme.palette.primary.lighter,
                borderRadius: 6,
                alignItems: 'center',
            },
        },
        roleChip: {
            margin: 0,
            paddingLeft: theme.spacing(0.5),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            borderRadius: 10,
            fontSize: '14px',
            fontWeight: '600',
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.dark,
        },
        roleIcon: {
            '& path': {
                fill: theme.palette.primary.dark,
            },
        },
        certificateContainer: {
            borderRadius: 8,
            backgroundColor: theme.palette.primary.lighter,
            justifyContent: 'start',
            alignItems: 'center',
            padding: '4% 6%',
            width: '91%',
            marginBottom: theme.spacing(1),
        },
        certificateWithIconContainer: {
            '&:hover': {
                cursor: 'pointer',
            },
            borderRadius: 8,
            backgroundColor: theme.palette.primary.lighter,
            width: '44%',
            margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4%',
        },
        '@media (max-width: 1400px)': {
            boldTypography: {
                paddingLeft: '12px',
            },
            certificateWithIconContainer: {
                width: '100%',
            },
            certificateContainer: {
                width: '100%',
            },
            companyNameResponsiveness: {
                maxHeight: '12em',
                maxWidth: '80%',
                marginRight: theme.spacing(0.4),
            },
            addressResponsiveness: {
                maxWidth: '12rem',
            },
        },
        '@media (max-width: 1100px)': {
            addressResponsiveness: {
                maxWidth: '9rem',
            },
        },
    });

interface CompanyPresentationDetailsProps extends WithStyles<typeof styles>, WithTranslation {
    companyRef: string;
}

interface CompanyPresentationDetailsState {
    company?: Company;
    mainAddress?: Address;
    companyMemberships?: CompanyMemberships;
    membershipWithLogo: Membership[];
    membershipWithoutLogo: Membership[];
    companyRoles?: CompanyRoles;
    downloadLink?: DownloadLink;
    isPopoverOpen: boolean;
    anchorEl?: HTMLElement | null;
    clickedMembershipRef?: string;
}

class CompanyPresentationDetailsComponent extends ComponentBase<
    CompanyPresentationDetailsProps,
    CompanyPresentationDetailsState
> {
    protected _buildState(
        props: CompanyPresentationDetailsProps,
        initialBuild: boolean,
        incomingState?: CompanyPresentationDetailsState,
    ): Partial<CompanyPresentationDetailsState> | undefined {
        const newState: Partial<CompanyPresentationDetailsState> = {
            ...incomingState,
            company: CompanyStore.getOne(props.companyRef),
        };

        if (!props.companyRef) return undefined;

        newState.membershipWithLogo = [];
        newState.membershipWithoutLogo = [];

        if (newState.company) {
            newState.companyRoles = CompanyRolesStore.getOne(newState.company.links.self);
            newState.companyMemberships = CompanyMembershipsStore.getOne(newState.company.links.self);
            if (newState.company.address) {
                newState.mainAddress = AddressStore.getOne(newState.company.address);
            } else {
                newState.mainAddress = undefined;
            }
        }

        if (newState.companyMemberships?.memberships && newState.membershipWithLogo) {
            newState.companyMemberships.memberships.forEach((it) => {
                const membership = MembershipStore.getOne(it);
                if (membership?.links.association && membership.links.certificate) {
                    const association = AssociationStore.getOne(membership.links.association);
                    if (newState.membershipWithLogo && newState.membershipWithoutLogo && association) {
                        if (association.links.icon) {
                            newState.membershipWithLogo.push(membership);
                        } else {
                            newState.membershipWithoutLogo.push(membership);
                        }
                    }
                }

                if (membership?.links.association && !membership.links.certificate) {
                    const association = AssociationStore.getOne(membership.links.association);
                    if (
                        newState.membershipWithLogo &&
                        newState.membershipWithoutLogo &&
                        association?.category === AssociationCategory.FEDERATION_INITIATIVE
                    ) {
                        if (association.links.icon) {
                            newState.membershipWithLogo.push(membership);
                        } else {
                            newState.membershipWithoutLogo.push(membership);
                        }
                    }
                }
            });
        }

        if (newState.clickedMembershipRef) {
            const membership = MembershipStore.getOne(newState.clickedMembershipRef);
            if (membership) {
                newState.downloadLink = membership.links.certificate
                    ? CertificateDownloadLinkStore.getOne(membership.links.certificate)
                    : undefined;
            }
        }

        return newState;
    }

    showWebsite(url: string): ReactElement {
        const processedURL = getValidUrl(url);
        const urlWithoutProtocol = url.replace(/^(https?:\/\/)?(www\.)?/, '');

        return (
            <Link href={processedURL} target="_blank" rel="noopener noreferrer" title={processedURL}>
                {urlWithoutProtocol}
            </Link>
        );
    }

    showRoleChips(): ReactNode {
        const roles = this.state.companyRoles?.roles;
        if (!roles) return null;
        if (!doArraysIntersect([NearbuyRole.CONSOLIDATOR, NearbuyRole.SHIPPER, NearbuyRole.PROCESSOR], roles))
            return null;

        return (
            <Grid container item direction={'row'} alignContent={'start'} marginBottom={theme.spacing(2)}>
                <Grid item>
                    {roles.includes(NearbuyRole.CONSOLIDATOR) && (
                        <Chip
                            icon={<MediationIcon className={this.props.classes.roleIcon} />}
                            label={this.props.t('common:CONSOLIDATOR_VERB')}
                            className={this.props.classes.roleChip}
                        />
                    )}
                </Grid>
                <Grid item>
                    {roles.includes(NearbuyRole.SHIPPER) && (
                        <Chip
                            icon={<LocalShippingIcon className={this.props.classes.roleIcon} />}
                            label={this.props.t('common:SHIPPER_VERB')}
                            className={this.props.classes.roleChip}
                        />
                    )}
                </Grid>
                <Grid item>
                    {roles.includes(NearbuyRole.PROCESSOR) && (
                        <Chip
                            icon={<ProcessorIcon className={this.props.classes.roleIcon} />}
                            label={this.props.t('common:PROCESSOR_VERB')}
                            className={this.props.classes.roleChip}
                        />
                    )}
                </Grid>
                <Grid item>
                    {roles.includes(NearbuyRole.WHOLESALER) && (
                        <Chip
                            icon={<Warehouse className={this.props.classes.roleIcon} />}
                            label={this.props.t('common:WHOLESALER_COMPANY_PRESENTATION')}
                            className={this.props.classes.roleChip}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }

    updateDownloadLink(link: string): void {
        MembershipService.getCertificateLink(link).subscribe((downloadLink) => {
            this.setState({ downloadLink });
        });
    }

    getCertificatesPopOver(): ReactNode {
        if (!this.state.clickedMembershipRef || !this.state.downloadLink) return null;
        return (
            <PublicAssociationPopOverComponent
                membershipRef={this.state.clickedMembershipRef}
                anchorEl={this.state.anchorEl}
                downloadLink={this.state.downloadLink}
                isPopoverOpen={this.state.isPopoverOpen}
                onClose={() => {
                    this.setState({
                        isPopoverOpen: false,
                    });
                }}
            />
        );
    }

    showCompanyNameWithVerifiedMark(): ReactNode {
        if (!this.state.company) return null;
        return (
            <Grid item container direction={'row'} alignItems={'center'}>
                <Typography
                    className={classNames(
                        this.props.classes.overflowRules,
                        this.props.classes.companyNameResponsiveness,
                    )}
                >
                    {this.state.company.name}
                </Typography>
                {this.state.company.verified && <Verified sx={{ fill: theme.palette.secondary.main }} />}
            </Grid>
        );
    }

    showAddress(): ReactNode {
        if (!this.state.mainAddress) return null;
        return (
            <Grid item>
                <Typography fontWeight={'500'} className={this.props.classes.overflowRules}>
                    {this.state.mainAddress.street}
                </Typography>
                <Typography
                    fontWeight={'500'}
                    className={classNames(this.props.classes.overflowRules, this.props.classes.addressResponsiveness)}
                >
                    {`${this.state.mainAddress.zipcode}  ${this.state.mainAddress.city}`}
                </Typography>
            </Grid>
        );
    }

    showMemberships(): ReactNode {
        const membershipsToShow = this.state.membershipWithLogo?.length || this.state.membershipWithoutLogo?.length;
        if (!this.state.companyMemberships || !membershipsToShow) return null;
        return (
            <Grid item container direction={'column'}>
                <Typography sx={{ fontWeight: 600, fontSize: '16px', marginBottom: theme.spacing(1) }}>
                    {this.props.t('association:certificatesAndMemberships')}
                </Typography>
                <Grid item container direction={'row'}>
                    {this.state.membershipWithLogo.map((membership) => {
                        return (
                            <Grid
                                item
                                className={this.props.classes.certificateWithIconContainer}
                                key={membership.links.self}
                                onClick={(event) => {
                                    this.setState({
                                        isPopoverOpen: true,
                                        anchorEl: event.currentTarget,
                                        clickedMembershipRef: membership.links.self,
                                    });
                                }}
                            >
                                <CompanyPresentationCertificationsComponent
                                    membershipRef={membership.links.self}
                                    withIcon
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item container direction={'row'}>
                    {this.state.membershipWithoutLogo.map((membership) => {
                        return (
                            <Grid
                                sx={{
                                    '&:hover': {
                                        cursor: !membership.links.certificate ? undefined : 'pointer',
                                    },
                                }}
                                item
                                key={membership.links.self}
                                className={this.props.classes.certificateContainer}
                                onClick={(event) => {
                                    this.setState({
                                        isPopoverOpen: true,
                                        anchorEl: event.currentTarget,
                                        clickedMembershipRef: membership.links.self,
                                    });
                                }}
                            >
                                <CompanyPresentationCertificationsComponent membershipRef={membership.links.self} />
                            </Grid>
                        );
                    })}
                </Grid>
                {this.getCertificatesPopOver()}
            </Grid>
        );
    }

    showEmail(): ReactNode {
        if (!this.state.company?.email) return null;
        return (
            <Grid item container direction={'row'} className={this.props.classes.companyDetailsHover}>
                <Grid item md={2.5} lg={2} xl={1.4}>
                    <Mail sx={{ color: theme.palette.primary.dark, alignContent: 'center', display: 'flex' }} />
                </Grid>
                <Grid item md={9.5} lg={10} xl={10.6}>
                    <Typography
                        className={classNames(
                            this.props.classes.overflowRules,
                            this.props.classes.hoverOver,
                            this.props.classes.boldTypography,
                        )}
                    >
                        <a className={this.props.classes.boldTypography} href={'mailto:' + this.state.company.email}>
                            {this.state.company.email}
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    showPhone(): ReactNode {
        if (!this.state.company?.phone) return null;
        return (
            <Grid item container direction={'row'} className={this.props.classes.companyDetailsHover}>
                <Grid item md={2.5} lg={2} xl={1.4}>
                    {' '}
                    <Phone sx={{ color: theme.palette.primary.dark, alignContent: 'center', display: 'flex' }} />
                </Grid>
                <Grid item md={9.5} lg={10} xl={10.6}>
                    <Typography className={classNames(this.props.classes.overflowRules, this.props.classes.hoverOver)}>
                        <a className={this.props.classes.boldTypography} href={'tel:' + this.state.company.phone}>
                            {this.state.company.phone}
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    showFax(): ReactNode {
        if (!this.state.company?.fax) return null;
        return (
            <Grid item container direction={'row'} className={this.props.classes.companyDetailsHover}>
                <Grid item md={2.5} lg={2} xl={1.4}>
                    {' '}
                    <Fax sx={{ color: theme.palette.primary.dark, alignContent: 'center', display: 'flex' }} />
                </Grid>
                <Grid item md={9.5} lg={10} xl={10.6}>
                    <Typography
                        className={classNames(
                            this.props.classes.overflowRules,
                            this.props.classes.hoverOver,
                            this.props.classes.boldTypography,
                        )}
                    >
                        <a className={this.props.classes.boldTypography} href={'tel:' + this.state.company.phone}>
                            {this.state.company.fax}
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    showWeb(): ReactNode {
        if (!this.state.company?.web) return null;
        return (
            <Grid item container direction={'row'} className={this.props.classes.companyDetailsHover}>
                <Grid item md={2.5} lg={2} xl={1.4}>
                    {' '}
                    <LinkRounded sx={{ color: theme.palette.primary.dark, alignContent: 'center', display: 'flex' }} />
                </Grid>
                <Grid item md={9.5} lg={10} xl={10.6}>
                    <Typography
                        className={classNames(
                            this.props.classes.overflowRules,
                            this.props.classes.hoverOver,
                            this.props.classes.boldTypography,
                        )}
                    >
                        {this.showWebsite(this.state.company.web)}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    render(): ReactNode {
        if (!this.state.company) return null;
        return (
            <Grid container direction={'column'} spacing={1}>
                {this.showCompanyNameWithVerifiedMark()}
                {this.showAddress()}
                {this.showRoleChips()}
                {this.showMemberships()}
                <Grid item container direction={'column'} spacing={1}>
                    {this.showEmail()}
                    {this.showPhone()}
                    {this.showFax()}
                    {this.showWeb()}
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation(['association', 'common'])(
    withStyles(styles, { withTheme: true })(CompanyPresentationDetailsComponent),
);
