import Axios from 'axios-observable';
import { AxiosInterceptorManager } from 'axios';

interface AxiosInterceptor<V> {
    onFulfilled?: Parameters<AxiosInterceptorManager<V>['use']>[0];
    onRejected?: Parameters<AxiosInterceptorManager<V>['use']>[1];
}

class Http {
    protected axiosInstance: Axios;
    protected headers: Record<string, string> = {
        // TODO: reenable, after allowed via cors
        //'Cache-Control': 'no-cache',
        //'Pragma': 'no-cache',
    };
    protected authToken: string | undefined = undefined;

    constructor() {
        this.axiosInstance = Axios.create({
            baseURL: process.env.REACT_APP_SERVER_BASE_URL,
            headers: this.headers,
        });
    }

    getAxios(): Axios {
        return this.axiosInstance;
    }

    getEmail(): string | undefined {
        const data = this.authToken?.split('.')[1];
        if (data) return JSON.parse(atob(data))['email'];
    }

    getCurrentHeaders(): Record<string, string> {
        return this.headers;
    }

    removeAuthToken(): void {
        delete this.axiosInstance.defaults.headers.common.Authorization;
        delete this.headers.Authorization;
    }

    setAuthToken(token: string | undefined): void {
        this.authToken = 'Bearer ' + token;
        this.axiosInstance.defaults.headers.common['Authorization'] = this.authToken;
        this.headers['Authorization'] = this.authToken;
    }

    setCurrentCompany(currentCompanyId: string | undefined): void {
        if (currentCompanyId) {
            this.headers['Current-Company'] = currentCompanyId;
            this.axiosInstance.defaults.headers.common['Current-Company'] = currentCompanyId;
        } else {
            delete this.headers['Current-Company'];
            delete this.axiosInstance.defaults.headers.common['Current-Company'];
        }
    }

    getAuthToken(): string | undefined {
        return this.authToken;
    }

    addInterceptor(interceptor: AxiosInterceptor<any>): void {
        this.axiosInstance.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected);
    }

    getBaseUrl(): string {
        return process.env.REACT_APP_SERVER_BASE_URL!;
    }

    isValidRef(ref: string): boolean {
        return ref.startsWith(this.getBaseUrl() + '/');
    }
}

export default new Http();
