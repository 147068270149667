import { ComponentBase } from 'resub';

export class FormError {
    public helperText: string | undefined;
    public error: boolean | undefined;

    constructor(helperText: string | undefined) {
        this.setHelperText(helperText);
    }

    setHelperText(value: string | undefined): void {
        this.helperText = value;
        this.error = this.helperText ? true : undefined;
    }
}

export function createFormError(helperText: string | undefined): FormError {
    return new FormError(helperText);
}

export type FormValidation = Map<string, FormError>;

const emptyFormError = createFormError(undefined);

export class FormValidationHelper {
    validation: FormValidation = new Map();
    resetOnEveryCycle: boolean;

    constructor(resetOnEveryCycle: boolean) {
        this.resetOnEveryCycle = resetOnEveryCycle;
    }

    clear(): void {
        this.validation = new Map();
    }

    setError(key: string, helperText: string | undefined): void {
        if (!this.validation.has(key)) {
            if (helperText === undefined) {
                // remove it from errors
                this.validation.delete(key);
            } else {
                this.validation.set(key, createFormError(helperText));
            }
        } else {
            this.validation.get(key)!.setHelperText(helperText);
        }
    }

    getFormError(key: string, hideError?: boolean): FormError {
        if (!hideError && this.validation.has(key)) {
            return this.validation.get(key)!;
        } else {
            return emptyFormError;
        }
    }

    hasErrors(): boolean {
        return this.validation.size > 0;
    }

    /**
     *
     * @param newState
     * @param prevState
     * @param validationFunction - In this function, you must not use this.state!
     */
    buildStateHelper<S extends WithFormValidationHelper, P = unknown>(
        validationFunction: (formHelper: FormValidationHelper, prevState: S, props: P) => void,
        newState: Partial<S>,
        prevState: S,
        props: P,
    ): void {
        if (this.resetOnEveryCycle) {
            this.clear();
        }
        validationFunction(this, { ...prevState, ...newState }, props);
        newState.formValidationHelper = this;
    }
}

export interface WithFormValidationHelper {
    formValidationHelper: FormValidationHelper;
}

/**
 *
 * @param validationFunctionName - The function, that validates the state and sets errors.
 *                                  Signature of the function:
 *                                  <code>(this: undefined, formHelper: FormValidationHelper, prevState: S) => void</code>
 * @param resetOnEveryRenderCycle
 */
export function withFormValidationHelper<
    Component extends ComponentBase,
    S extends WithFormValidationHelper = WithFormValidationHelper,
>(validationFunctionName: keyof Component, resetOnEveryRenderCycle = true) {
    return function (target: Component, _propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
        if (!Object.prototype.hasOwnProperty.call(target, validationFunctionName)) {
            throw new Error(`decorated class has no member "${validationFunctionName.toString()}"`);
        }

        const validateOffer = target[validationFunctionName] as unknown as (
            this: undefined,
            formHelper: FormValidationHelper,
            prevState: S,
        ) => void;

        const method = descriptor.value;
        descriptor.value = function (props: unknown, initialBuild: boolean, prevState: S): void {
            let newState = method.apply(this, [props, initialBuild, prevState]);
            if (!newState) {
                newState = {};
            }
            if (initialBuild) {
                newState.formValidationHelper = new FormValidationHelper(resetOnEveryRenderCycle);
                newState.formValidationHelper.buildStateHelper(validateOffer, newState, prevState, props);
            } else {
                prevState.formValidationHelper.buildStateHelper(validateOffer, newState, prevState, props);
            }
            return newState;
        };

        return descriptor;
    };
}
