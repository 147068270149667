import * as React from 'react';
import Box from '@mui/material/Box';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ComponentBase } from 'resub';
import { ProductStore } from 'store';
import ProductService from 'api/ProductService';
import { Product } from 'model/Product';
import { TreeItem, TreeView } from '@mui/lab';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Checkbox from '@mui/material/Checkbox';
import { WithTranslation, withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIcon } from 'components/shared/Icons';
import IconButton from '@mui/material/IconButton';
import { classNames } from 'util/style-helpers';

const _styles = (theme: Theme) =>
    createStyles({
        listItem: {
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
        },
        treeItemContent: {
            flexDirection: 'row-reverse',
            padding: theme.spacing(0.5),
        },
        marginRight: {
            marginRight: theme.spacing(1),
        },
        selected: {
            backgroundColor: theme.palette.action.hover,
        },
        editAddButton: {
            marginTop: theme.spacing(1),
            textTransform: 'none',
        },
    });

export type ActionType = 'check' | 'add' | 'edit' | 'delete';
export type AddProductFunctionType = (productRef: string, checked: boolean, type?: ActionType) => void;

interface OntofoodTreeViewProps extends WithStyles<typeof _styles>, WithTranslation {
    checkedCategories?: Set<string>;
    selectedCategory?: Product | null;
    /**
     *
     * @param productRef
     * @param checked
     * @param type <pre>If type is 'add', productRef is the new parent Item. If type is 'edit', the productRef is the item to change.</pre>
     */
    addProduct?: AddProductFunctionType;
    expanded?: string[];
    onToggle?: (event: React.SyntheticEvent, nodes: string[]) => void;
    preloadSubcategories?: boolean;
    // onFocus?: (focusedProduct: Product) => void;
    editMode?: boolean;
    isExpandedManaged?: boolean;
    productType: keyof Pick<Product, 'company' | 'market'> | undefined;
}

interface OntofoodTreeViewState {
    topCategory?: Product;
    expanded?: string[];
    categories: ReadonlyMap<string, Product>;
}

class OntofoodTreeView extends ComponentBase<OntofoodTreeViewProps, OntofoodTreeViewState> {
    protected _buildState(
        props: OntofoodTreeViewProps,
        initialBuild: boolean,
        incomingState?: Readonly<OntofoodTreeViewState>,
    ): Partial<OntofoodTreeViewState> | undefined {
        const newState: Partial<OntofoodTreeViewState> = {
            topCategory: ProductStore.getSelected(),
        };
        if (props.isExpandedManaged) {
            if (props.expanded !== incomingState?.expanded) {
                newState.expanded = props.expanded;
            }
        }
        newState.categories = ProductStore.getAllMapped();
        return newState;
    }

    componentDidMount(): void {
        super.componentDidMount();
        if (!this.state.topCategory) {
            ProductService.getTopCategory().subscribe();
        }
    }

    handleExpandedEvent = (event: React.SyntheticEvent, nodes: string[]): void => {
        if (this.props.isExpandedManaged && this.props.onToggle) {
            this.props.onToggle(event, nodes);
        } else {
            this.setState({ expanded: nodes });
        }
    };

    getSubcategoryTreeViewItems(references: string[]): React.ReactNode[] | null {
        const treeItems: React.ReactNode[] = new Array<React.ReactNode>();
        references.forEach((subcat) => {
            const subcategory = this.state.categories.get(subcat);
            if (subcategory) {
                treeItems.push(this.renderTreeItem(subcategory));
            }
        });
        return treeItems.length > 0 ? treeItems : null;
    }

    /**
     * Function for TreeItem
     * @param product
     */
    getCheckbox(product: Product): React.ReactElement | null {
        if (!this.props.checkedCategories) {
            return null;
        }

        if (this.props.productType && !product[this.props.productType]) {
            return null;
        }

        return (
            <Checkbox
                checked={this.props.checkedCategories.has(product.links.self)}
                onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
                    return this.props.addProduct ? this.props.addProduct(product.links.self, checked) : undefined;
                }}
                color="primary"
                value={product}
                onClick={(event): void => {
                    event.stopPropagation();
                }}
                tabIndex={-1}
            />
        );
    }

    /**
     * Function for TreeItem
     * @param product
     */
    getLabel(product: Product): React.ReactElement {
        return (
            <Box className={this.props.classes.listItem}>
                {this.getCheckbox(product)}
                {(product.links.subcategories.length === 0 ||
                    process.env.REACT_APP_NEARBUY_FEATURE_SHOW_UNFINISHED_FEATURES === 'true') &&
                    this.deleteButton(product)}
                {this.editButton(product)}
                <Box className={this.props.classes.listItem}>
                    <Typography>{this.props.t('ontofood:' + product.label)}</Typography>
                </Box>
            </Box>
        );
    }

    /**
     * Function for TreeItem
     * @param product
     */
    getTreeItemContentClass(product: Product): string {
        const result = [this.props.classes.treeItemContent];
        if (this.props.selectedCategory && this.props.selectedCategory.links.self === product.links.self) {
            result.push(this.props.classes.selected);
        }

        return classNames(...result);
    }

    addProductButton(product: Product): React.ReactElement {
        const nodeId = product.links.self + '_add';
        return (
            <TreeItem
                nodeId={nodeId}
                key={nodeId}
                classes={{
                    content: this.props.classes.treeItemContent,
                }}
                label={
                    <Button
                        className={this.props.classes.editAddButton}
                        color="primary"
                        variant={'contained'}
                        onClick={(evt): void => {
                            evt.stopPropagation();
                            return this.props.addProduct?.(product.links.self, true, 'add');
                        }}
                    >
                        {this.props.t('dialogs:ADD')}
                    </Button>
                }
            />
        );
    }

    editAction(product: Product): void {
        this.props.addProduct && this.props.addProduct(product.links.self, true, 'edit');
    }

    deleteAction(product: Product): void {
        this.props.addProduct && this.props.addProduct(product.links.self, true, 'delete');
    }

    editButton(product: Product): React.ReactElement | undefined {
        if (this.props.editMode) {
            return (
                <IconButton
                    onClick={(event): void => {
                        this.editAction(product);
                        event.stopPropagation();
                    }}
                    size="small"
                    className={this.props.classes.marginRight}
                >
                    <EditIcon />
                </IconButton>
            );
        }
        return undefined;
    }

    deleteButton(product: Product): React.ReactElement | undefined {
        if (this.props.editMode) {
            return (
                <IconButton
                    onClick={(event): void => {
                        this.deleteAction(product);
                        event.stopPropagation();
                    }}
                    size="small"
                    className={this.props.classes.marginRight}
                >
                    <DeleteIcon />
                </IconButton>
            );
        }
        return undefined;
    }

    /**
     * Function for TreeItem
     * This renders the TreeItem.
     * @param product
     */
    renderTreeItem(product?: Product): React.ReactElement | null {
        if (!product) {
            return null;
        }
        let subTreeItems = this.getSubcategoryTreeViewItems(product.links.subcategories);

        if (this.props.editMode) {
            if (!subTreeItems) {
                subTreeItems = [];
            }
            subTreeItems.push(this.addProductButton(product));
        }

        return (
            <TreeItem
                nodeId={product.links.self}
                key={product.links.self}
                label={this.getLabel(product)}
                classes={{
                    content: this.getTreeItemContentClass(product),
                }}
                // TODO: remove
                // onFocus={(): void => {
                //     if (this.props.onFocus && product) {
                //         this.props.onFocus(product);
                //     }
                // }}
            >
                {subTreeItems}
            </TreeItem>
        );
    }

    render(): React.ReactElement | null {
        if (!this.state.topCategory) {
            return null;
        }
        return (
            <TreeView
                defaultExpandIcon={<ExpandMore />}
                defaultCollapseIcon={<ExpandLess />}
                expanded={this.state.expanded}
                onNodeToggle={this.handleExpandedEvent}
            >
                {this.getSubcategoryTreeViewItems(this.state.topCategory.links.subcategories)}
                {this.props.editMode && this.addProductButton(this.state.topCategory)}
            </TreeView>
        );
    }
}

export default withTranslation(['dialogs', 'ontofood'])(withStyles(_styles, { withTheme: true })(OntofoodTreeView));
