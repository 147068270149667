export class ContainerView {
    constructor(
        public id: string,
        public containerType: string,
        public amount: number,
        public unit: string,
        public links: ContainerLinks,
    ) {}
}

export class ContainerLinks {
    constructor(
        public self: string,
        public remove: string,
    ) {}
}

export class ContainerWriteView {
    constructor(
        public containerType: string,
        public amount: number,
        public unit: string,
    ) {}
}

export class ContainerType {
    constructor(
        public id: string,
        public slug: string,
    ) {}
}

export interface ContainerSelection {
    amount: number;
    links: {
        container: string;
    };
}
