import { FeatureOrFeatures } from 'model/Feature';
import { FeatureToggleProps } from 'components/feature/FeatureToggle';

export enum DashletType {
    currentCompany = 'current_company',
    messages = 'messages',
    contacts = 'contacts',
    ontofood = 'ontofood',
    savedOffersAndRequests = 'saved_offers_and_requests',
    adminNavigation = 'admin_navigation',
    myOffers = 'my_offers',
    myRequests = 'my_requests',
    myFavouriteCompanies = 'my_favourite_companies',
    map = 'map',
    subscription = 'subscription',
}

export interface DashletProperties {
    readonly id: DashletType;
    additionalProperties?: any;
    priority?: number;
}

export interface DefaultDashletProperties {
    dashletProperties: DashletProperties;
}

export interface CompanyDashletProperties {
    companyRef: string;
    dashletProperties: DashletProperties;
}

export type RequiredFeatures = null | {
    featuresRequired: Set<FeatureOrFeatures>;
    type?: FeatureToggleProps['type'];
};
