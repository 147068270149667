import CompanyService, { CompanySearchQuery } from 'api/CompanyService';
import { Company } from 'model/Company';
import { autoSubscribeWithKey } from 'resub';
import { DynamicLoadingStore } from 'resub-entity';

const TERMINATED_PERSON_COMPANY = 'TERMINATED_PERSON_COMPANY';

class CompanyStoreClass extends DynamicLoadingStore<Company, string, CompanySearchQuery> {
    protected selectedTerminatedPersonCompany?: Company;

    @autoSubscribeWithKey(TERMINATED_PERSON_COMPANY)
    getSelectedTerminatedPersonCompany(): Company | undefined {
        return this.selectedTerminatedPersonCompany;
    }

    setSelectedTerminatedPersonCompany(company?: Company) {
        this.selectedTerminatedPersonCompany = company;
        this.trigger(TERMINATED_PERSON_COMPANY);
    }
}

export const CompanyStore = new CompanyStoreClass({
    selectIdFunction: (Company: Company) => Company.links.self || '',
    loadFunction: (ref: string) => CompanyService.getCompany(ref),
    searchLoadFunction: (companySearchQuery: CompanySearchQuery) =>
        CompanyService.searchCompaniesAsRefs(companySearchQuery),
});
export default CompanyStore;
