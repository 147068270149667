import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';
import { AlertType } from 'components/alert/Alerts';

@AutoSubscribeStore
class AlertStoreClass extends StoreBase {
    protected alerts: Set<AlertType> = new Set<AlertType>();

    @autoSubscribe
    getAlerts(): AlertType[] {
        return Array.from(this.alerts);
    }

    addAlert(type: AlertType): void {
        const alertsCopy = new Set(Array.from(this.alerts));
        alertsCopy.add(type);
        const hasChanged = alertsCopy.size != this.alerts.size;
        if (hasChanged) {
            this.alerts = alertsCopy;
            this.trigger();
        }
    }

    removeAlert(type: AlertType): void {
        const alertsCopy = new Set(Array.from(this.alerts));
        const hasChanged = alertsCopy.delete(type);
        if (hasChanged) {
            this.alerts = alertsCopy;
            this.trigger();
        }
    }

    clear(): void {
        this.alerts = new Set<AlertType>();
    }
}

export const AlertStore = new AlertStoreClass();
export default AlertStore;
