import { Dialog, getDialogId } from 'model/Dialog';
import { DialogStore, MessageStore } from 'store';

export function invalidateDialog(dialog: Dialog) {
    DialogStore.setSelected(getDialogId(dialog));
    if (dialog.otherPersonRef)
        MessageStore.invalidateSearch({
            otherPersonRef: dialog.otherPersonRef,
            otherCompanyRef: dialog.otherCompanyRef,
        });
    DialogStore.invalidateCache(getDialogId(dialog));
    DialogStore.invalidateSearch(dialog);
    DialogStore.invalidateSearch({
        ownCompanyRef: dialog.ownCompanyRef,
        ownPersonRef: dialog.ownPersonRef,
    });
}
