import { SystemMessageSettings } from 'model/SystemMessageSettings';
import SystemMessageSettingsService from 'api/SystemMessageSettingsService';
import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';

@AutoSubscribeStore
class SystemMessageSettingsStoreNew extends StoreBase {
    settings: SystemMessageSettings | undefined;
    isLoading = false;

    loadSystemMessageSettings() {
        this.isLoading = true;
        SystemMessageSettingsService.getSettings().subscribe({
            next: (settings) => {
                this.setNewSystemMessageSettings(settings);
                this.isLoading = false;
            },
        });
    }

    setNewSystemMessageSettings(settings: SystemMessageSettings) {
        this.settings = settings;
        this.trigger();
    }

    @autoSubscribe
    getSystemMessageSettings(): SystemMessageSettings | undefined {
        if (this.settings === undefined && !this.isLoading) {
            this.isLoading = true;
            this.loadSystemMessageSettings();
        }
        return this.settings;
    }
}

export default new SystemMessageSettingsStoreNew();
