import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import Dashlet from 'components/dashboard/Dashlet';
import { Link as RouterLink } from 'react-router-dom';
import { DefaultDashletProperties } from 'model/DashletProperties';
import { PersonFavouritesStore, PersonStore } from 'store';
import { withTranslation, WithTranslation } from 'react-i18next';
import FavouriteDashletList from 'components/dashboard/person/favourite/FavouriteDashletList';
import { PersonFavourites } from 'model/Person';
import { theme } from 'style/NearbuyStyle';
import Button from '@mui/material/Button';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = () => createStyles({});

type MyFavouritesDashletProps = WithStyles<typeof _styles> & DefaultDashletProperties & WithTranslation;

interface MyFavouritesDashletState {
    personFavs: PersonFavourites | undefined;
}

class MyFavouritesDashlet extends ComponentBase<MyFavouritesDashletProps, MyFavouritesDashletState> {
    protected _buildState(
        props: MyFavouritesDashletProps,
        initialBuild: boolean,
        incomingState?: Readonly<MyFavouritesDashletState>,
    ): Partial<MyFavouritesDashletState> | undefined {
        const newState: Partial<MyFavouritesDashletState> = {};
        const user = PersonStore.getSelected();
        if (user?.links.self) {
            newState.personFavs = PersonFavouritesStore.getOne(user.links.self);
        }
        return newState;
    }

    showList(): React.ReactElement | undefined {
        if (this.state.personFavs) {
            return <FavouriteDashletList companyRefs={this.state.personFavs.companyRefs} />;
        }
    }

    render(): React.ReactElement | undefined {
        return (
            <Dashlet {...this.props.dashletProperties}>
                {this.showList()}
                <Button
                    size={'medium'}
                    variant={'text'}
                    component={RouterLink}
                    onClick={() => captureWebEvent('myFavourites-dashlet-fullView-button')}
                    to={'/myFavourites'}
                    sx={{
                        fontWeight: 600,
                        padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
                        borderRadius: '8px',
                        color: theme.palette.primary.dark,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.lighter,
                            color: theme.palette.primary.dark,
                        },
                    }}
                >
                    {this.props.t('common:linkToFullView')}
                </Button>
            </Dashlet>
        );
    }
}

export default withTranslation(['common'])(withStyles(_styles)(MyFavouritesDashlet));
