import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';

interface LocalisationProviderWrapperProperties extends WithTranslation {
    children: JSX.Element | JSX.Element[];
}

class LocalisationProviderWrapper extends React.PureComponent<LocalisationProviderWrapperProperties> {
    render(): JSX.Element | null {
        const dateFormat = this.props.t('common:DATEFORMAT');
        if (!dateFormat) {
            // should never be called
            console.debug('waiting for dateformat');
            return null;
        }
        return (
            <LocalizationProvider
                dateAdapter={MomentUtils}
                dateFormats={{
                    keyboardDate: dateFormat,
                    normalDate: dateFormat,
                }}
            >
                {this.props.children}
            </LocalizationProvider>
        );
    }
}

export default withTranslation('common')(LocalisationProviderWrapper);
