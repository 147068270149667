import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import InvitationService from 'api/InvitationService';
import NavStore from 'store/NavStore';
import { ComponentBase } from 'resub';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithStyles, WithTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

const styles = () =>
    createStyles({
        circularProgress: {
            margin: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
        center: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        },
    });

interface DeclineInvitationProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {}

interface DeclineInvitationState {
    invitationId: string | null;
    deleted: boolean;
}

class DeclineInvitation extends ComponentBase<DeclineInvitationProperties, DeclineInvitationState> {
    protected _buildState(): Partial<DeclineInvitationState> | undefined {
        return {
            invitationId: NavStore.getCurrentSearchParams('id'),
            deleted: false,
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.handleInvitation();
    }

    handleInvitation(): void {
        if (!this.state.invitationId) {
            // TODO: suitable error handling (ErrorBoundary should handle this)
            throw new Error('Missing invitation id.');
        } else {
            InvitationService.declineInvitation(this.state.invitationId).subscribe((value) => {
                if (value) {
                    this.setState({ deleted: true });
                }
            });
        }
    }

    render():
        | React.ReactElement
        | string
        | number
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {
        return (
            <div className={this.props.classes.center}>
                {this.props.t('dialogs:' + !this.state.deleted ? 'willDeleteData' : 'hasDeletedData')}
                <CircularProgress className={this.props.classes.circularProgress} color="primary" />
            </div>
        );
    }
}

export default withTranslation('dialogs')(withStyles(styles)(DeclineInvitation));
