import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { NotificationInfo } from 'model/NearbuyNotification';
import { ProviderContext, withSnackbar } from 'notistack';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { NotificationStore } from 'store';
import { getAction, getContent, getSideEffect, getVariant } from 'components/notification/SnackBarFactory';
import { CompanyStore } from 'store';
import { Company } from 'model/Company';

const _styles = () => createStyles({});

interface NotificationAlertProps extends WithStyles<typeof _styles>, RouteComponentProps, ProviderContext {}

interface NotificationAlertState {
    currentCompany: Company;
    notificationSubscriptionToken: number;
}

class NotificationAlert extends ComponentBase<NotificationAlertProps, NotificationAlertState> {
    protected _buildState(): Partial<NotificationAlertState> | undefined {
        return {
            currentCompany: CompanyStore.getSelected(),
        };
    }

    componentDidMount(): void {
        super.componentDidMount();

        // move to _buildState?
        this.setState({
            notificationSubscriptionToken: NotificationStore.subscribe(() => {
                const messageInfos = NotificationStore.getAll();

                if (!messageInfos.length) {
                    return;
                }

                const newNotification: NotificationInfo = {
                    notification: messageInfos[messageInfos.length - 1].notification,
                    timestamp: new Date().getTime(),
                };

                if (
                    newNotification.notification.companyId &&
                    !this.state.currentCompany?.links.self.includes(newNotification.notification.companyId)
                ) {
                    return;
                }

                this.handleNewMessage(newNotification);
            }),
        });
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();

        NotificationStore.unsubscribe(this.state.notificationSubscriptionToken);
    }

    handleNewMessage(notificationInfo: NotificationInfo) {
        getSideEffect(notificationInfo)?.(notificationInfo);
        const snackbarKey = this.props.enqueueSnackbar(getContent(notificationInfo), {
            action: getAction(notificationInfo, () => this.props.closeSnackbar(snackbarKey)),
            variant: getVariant(notificationInfo),
        });
    }

    render(): React.ReactElement | null {
        return null;
    }
}

export default withStyles(_styles)(withRouter(withSnackbar(NotificationAlert)));
