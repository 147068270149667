import * as React from 'react';
import NearbuyWindow from 'components/shared/NearbuyWindow';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { ComponentBase } from 'resub';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Company } from 'model/Company';
import FavouritesTableComponent from 'components/favourites/FavouritesTableComponent';
import { PersonFavouritesStore, PersonStore } from 'store';
import { Person, PersonFavourites } from 'model/Person';

const styles = () =>
    createStyles({
        textField: {
            padding: 0,
            minWidth: 66,
            flexGrow: 1,
            display: 'flex',
        },
        grid: {
            display: 'flex',
            maxWidth: '100%',
        },
    });

interface MyFavouritesProps extends WithStyles<typeof styles>, WithTranslation {}

interface MyFavouritesState {
    loading: boolean;
    description?: string;
    selectedCompanyRef?: string;
    selectedCompany: Company;
    person: Person;
    searchString: string;
    favouritedCompanies: PersonFavourites;
}

class MyFavouritesComponent extends ComponentBase<MyFavouritesProps, MyFavouritesState> {
    protected _buildState(
        props: MyFavouritesProps,
        initialBuild: boolean,
        incomingState?: Readonly<MyFavouritesState>,
    ): Partial<MyFavouritesState> | undefined {
        const user = PersonStore.getSelected();
        if (user) {
            return {
                person: user,
                favouritedCompanies: PersonFavouritesStore.getOne(user.links.self),
            };
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.favouritedCompanies) return null;

        return (
            <div>
                <NearbuyWindow title={this.props.t('profile:myFavourites')}>
                    <Grid
                        container
                        sx={{
                            minHeight: '20vh',
                            maxHeight: '72vh',
                            width: '100%',
                            overflow: 'auto',
                        }}
                    >
                        <Grid>
                            <FavouritesTableComponent
                                favouritedCompanyRefs={this.state.favouritedCompanies.companyRefs}
                                person={this.state.person}
                                key={this.state.person.links.self}
                            />
                        </Grid>
                    </Grid>
                </NearbuyWindow>
            </div>
        );
    }
}

export default withTranslation('profile')(withStyles(styles)(MyFavouritesComponent));
