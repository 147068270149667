import * as React from 'react';

export class NearbuyLoadingIndicator extends React.PureComponent {
    render(): React.ReactNode {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="6vh"
                viewBox="0 0 1734 734"
                version="1.1"
                xmlSpace="preserve"
                style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 1.41421 }}
            >
                <g>
                    <circle cx="156.713" cy="156.713" r="156.713" style={{ fill: '#486f50' }}>
                        <animate
                            attributeName="opacity"
                            values="0;1;1;0"
                            keyTimes="0;0.25;0.75;1"
                            dur="1s"
                            repeatCount="indefinite"
                            begin="2.25s"
                        />
                    </circle>
                    <circle cx="511.121" cy="156.713" r="156.713" style={{ fill: '#92be9b' }}>
                        <animate
                            attributeName="opacity"
                            values="0;1;1;0"
                            keyTimes="0;0.25;0.75;1"
                            dur="1s"
                            repeatCount="indefinite"
                            begin="0.5s"
                        />
                    </circle>
                    <circle cx="865.533" cy="156.713" r="156.712" style={{ fill: '#b7d7c3' }}>
                        <animate
                            attributeName="opacity"
                            values="0;1;1;0"
                            keyTimes="0;0.25;0.75;1"
                            dur="1s"
                            repeatCount="indefinite"
                            begin="0.75s"
                        />
                    </circle>
                    <circle cx="1219.94" cy="156.713" r="156.712" style={{ fill: '#e3c44f' }}>
                        <animate
                            attributeName="opacity"
                            values="0;1;1;0"
                            keyTimes="0;0.25;0.75;1"
                            dur="1s"
                            repeatCount="indefinite"
                            begin="1s"
                        />
                    </circle>
                    <circle cx="1574.44" cy="156.713" r="156.712" style={{ fill: '#b79244' }}>
                        <animate
                            attributeName="opacity"
                            values="0;1;1;0"
                            keyTimes="0;0.25;0.75;1"
                            dur="1s"
                            repeatCount="indefinite"
                            begin="1.25s"
                        />
                    </circle>
                </g>
            </svg>
        );
    }
}
