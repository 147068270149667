export interface Dialog {
    ownCompanyRef: string;
    ownPersonRef: string;
    otherPersonRef?: string;
    otherCompanyRef: string;
    lastUpdated?: Date;
    latestMessageRef?: string;
    isChatPartnerActive?: boolean;
}

function partialMessageJSONReplacer(key: string, value: any) {
    if (key !== '') {
        // the first run of the replacer always has the whole object as value,
        // only the calls after that are the ones, with a key
        return ['ownCompanyRef', 'ownPersonRef', 'otherPersonRef', 'otherCompanyRef'].includes(key) ? value : undefined;
    } else {
        return value;
    }
}

export type DialogId = Pick<Dialog, 'ownCompanyRef' | 'ownPersonRef' | 'otherPersonRef' | 'otherCompanyRef'>;

export function getDialogId(dialog: DialogId): string {
    // Because Dialogs are dynamically generated by the Backend, they dont have a unique id.
    // Therefore we help ourself to a fake id in order to individually address dialogs in a store context
    return JSON.stringify(dialog, partialMessageJSONReplacer);
}

/**
 * This does not return the complete Dialog, because e.g. lastUpdated and latestMessageRef are not included in the id anymore
 * (because they change too often).
 * @param id
 */
export function getDialogFromId(id: string): DialogId {
    return JSON.parse(id);
}
