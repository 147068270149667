/**
 * This function walks the tree up and collects all the id's
 * @param productRef
 */
import { Product } from 'model/Product';
import { ProductStore } from 'store';
import ProductService from 'api/ProductService';
import { lastValueFrom } from 'rxjs';

function getSuperCategories(product: Product): Set<string> {
    return new Set<string>(product.links.supercategories);
}

export async function getAllIdsUp(productRef: string, refs?: Set<string>): Promise<Set<string>> {
    if (!refs) {
        refs = new Set<string>();
    } else {
        // don't add the first element
        refs.add(productRef);
    }
    let product: Product;
    if (ProductStore.hasOne(productRef)) {
        product = ProductStore.getOne(productRef) as Product;
    } else {
        await ProductService.getProduct(productRef);
        product = await lastValueFrom(ProductService.getProduct(productRef));
        ProductStore.setOne(product);
    }
    for (const sup of getSuperCategories(product)) {
        refs = await getAllIdsUp(sup, refs);
    }
    return Promise.resolve(refs);
}
