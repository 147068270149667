import * as React from 'react';
import { ReactFragment } from 'react';
import { ComponentBase } from 'resub';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Card, CardActions, Divider, Theme, Tooltip } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Order, OrderAddress } from 'model/Order';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { InvoiceStore, MetaStore, NotificationStore, OrderStore, PersonStore, ProductStore } from 'store';
import OfferTitle from 'components/messaging/chat/messagefactory/infocards/shared/MarketItemTitle';
import AmountAndPriceInfo from 'components/messaging/chat/messagefactory/infocards/shared/AmountAndPriceInfo';
import { MetaStaticEntityType } from 'model/Meta';
import { ContainerType } from 'model/ContainerView';
import { AddressType } from 'model/Address';
import Typography from '@mui/material/Typography';
import OrderAddresses from 'components/order/OrderAddresses';
import { Invoice } from 'model/Invoice';
import { theme } from 'style/NearbuyStyle';
import OrderService from 'api/OrderService';
import {
    NotificationInfo,
    OrderMarkedAsActiveNotification,
    OrderMarkedAsCompleteNotification,
} from 'model/NearbuyNotification';
import { now } from 'moment';
import { Product } from 'model/Product';

const _styles = (theme: Theme) =>
    createStyles({
        content: {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        dot: {
            color: theme.palette.primary.main,
            margin: theme.spacing(1),
        },
        lopGrid: {
            marginLeft: theme.spacing(1),
        },
        category: {
            textAlign: 'left',
            marginTop: theme.spacing(1),
            fontSize: 18,
            fontWeight: 'bold',
        },
        text: {
            textAlign: 'left',
        },
        textBold: {
            textAlign: 'left',
            fontWeight: 'bold',
        },
        orderCard: {
            backgroundColor: theme.palette.background.default,
        },
        cardContent: {
            padding: theme.spacing(1),
            '&:last-child': {
                paddingBottom: 0,
            },
            backgroundColor: theme.palette.background.default,
        },
        gridContainer: {
            display: 'inline-flex',
            flexWrap: 'nowrap',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        message: {
            borderRadius: '3px',
            padding: '0.5rem',
        },
        messageForeign: {
            border: '1px solid rgba(0, 0, 0, .12)',
        },
        messageOwn: {
            backgroundColor: theme.palette.primary.light,
        },
        messageText: {
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line',
        },
        button: {
            margin: theme.spacing(1),
            color: theme.palette.common.black,
            backgroundColor: theme.palette.primary.main,
        },
        declineButton: {
            margin: theme.spacing(1),
            color: theme.palette.primary.dark,
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        messageRoot: {
            maxWidth: '75%',
            minWidth: '50%',
        },
        infoCardItem: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    });

interface OrderInfoCardProps extends WithTranslation, WithStyles<typeof _styles> {
    orderRef: string;
    isCompletedVariant?: boolean;
}

interface OrderInfoCardState {
    order: Order;
    product: Product;
    isDialogOpen?: boolean;
    containerTypes?: ContainerType[];
    invoiceAddress?: OrderAddress;
    deliveryAddress?: OrderAddress;
    invoice?: Invoice;
    isSeller: boolean;
}

class OrderInfoCard extends ComponentBase<OrderInfoCardProps, OrderInfoCardState> {
    protected _buildState(
        props: OrderInfoCardProps,
        initialBuild: boolean,
        incomingState?: Readonly<OrderInfoCardState>,
    ): Partial<OrderInfoCardState> | undefined {
        const newState: Partial<OrderInfoCardState> = {};
        newState.order = OrderStore.getOne(props.orderRef);
        if (newState.order) {
            newState.invoiceAddress = newState.order.addresses.find((value) => value.type == AddressType.INVOICE);
            newState.deliveryAddress = newState.order.addresses.find((value) => value.type == AddressType.DELIVERY);
        }

        if (newState.order && newState.order.links.invoice) {
            newState.invoice = InvoiceStore.getOne(newState.order.links.invoice);
        }

        const metaContainerType = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);

        newState.containerTypes = metaContainerType?.data as ContainerType[];

        const currentUser = PersonStore.getSelected();
        if (newState.order && newState.order.links.sellingPerson) {
            const sellerRef = PersonStore.getOne(newState.order.links.sellingPerson);

            newState.isSeller =
                currentUser != undefined && sellerRef != undefined && currentUser.links.self == sellerRef.links.self;
        }

        newState.product = ProductStore.getOne(newState.order?.links.category ?? '');

        return newState;
    }

    showCompleteButton(): ReactFragment | null {
        return (
            <Tooltip
                title={this.props.t(
                    this.state.isSeller
                        ? this.state.order.isCompleted
                            ? 'order:markNotCompletedTooltipSeller'
                            : 'order:markCompletedTooltipSeller'
                        : this.state.order.isCompleted
                          ? 'order:markNotCompletedTooltipBuyer'
                          : 'order:markCompletedTooltipBuyer',
                )}
            >
                <Button
                    variant={'outlined'}
                    sx={{ marginRight: theme.spacing(1) }}
                    onClick={() => {
                        OrderService.setCompleted(
                            this.state.order.links.self,
                            !this.state.order.isCompleted,
                        ).subscribe();
                        NotificationStore.setOne(
                            new NotificationInfo(
                                this.state.order.isCompleted
                                    ? OrderMarkedAsActiveNotification(this.state.isSeller, false)
                                    : OrderMarkedAsCompleteNotification(this.state.isSeller, true),
                                now(),
                            ),
                        );
                    }}
                >
                    <Typography>
                        {this.state.order.isCompleted
                            ? this.props.t('order:markAsActive')
                            : this.props.t('order:markAsCompleted')}
                    </Typography>
                </Button>
            </Tooltip>
        );
    }

    showButtons(): React.ReactElement | undefined {
        if (!this.state.order) return;

        return (
            <Grid container direction={'row'} textAlign={'right'}>
                {!this.props.isCompletedVariant &&
                    process.env.REACT_APP_NEARBUY_FEATURE_SHOW_UNFINISHED_FEATURES === 'true' && (
                        <Grid item container justifyContent={'end'} xs={12}>
                            <Button variant="outlined" className={this.props.classes.declineButton}>
                                {this.props.t('order:showDetails')}
                            </Button>
                        </Grid>
                    )}
                {this.props.isCompletedVariant && this.state.invoice && (
                    <Grid item container justifyContent={'end'} xs={12} alignItems={'center'}>
                        <Grid item>{this.showCompleteButton()}</Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                className={this.props.classes.button}
                                onClick={() => {
                                    const link = document.createElement('a');
                                    link.download = this.state.invoice!.fileName as string;
                                    link.href = this.state.invoice!.downloadLink?.url as string;
                                    link.click();
                                }}
                            >
                                {this.props.t('order:downloadInvoice')}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }

    render(): React.ReactElement | null {
        if (!this.state.order || !this.state.containerTypes) return null;

        return (
            <Card className={this.props.classes.orderCard}>
                <CardContent className={this.props.classes.cardContent}>
                    <OfferTitle
                        offerRef={this.state.order.links.offer}
                        levelsOfProcessing={this.state.order.levelsOfProcessing}
                        productLabel={this.state.product.label ?? ''}
                        hasButton={true}
                        isOrderDetailsButtonShown={
                            !!(
                                process.env.REACT_APP_NEARBUY_FEATURE_SHOW_UNFINISHED_FEATURES === 'true' &&
                                this.props.isCompletedVariant
                            )
                        }
                    />
                    <AmountAndPriceInfo
                        order={this.state.order}
                        isContainerSectionShown={!this.props.isCompletedVariant}
                    />
                    {!this.props.isCompletedVariant && (
                        <>
                            <Divider />
                            <Grid container className={this.props.classes.infoCardItem}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={4}>
                                    <Typography>{this.props.t('payment:paymentDetails')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={'bold'}>
                                        {this.props.t('payment:paymentOnAccount')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider />
                            <OrderAddresses
                                className={this.props.classes.infoCardItem}
                                invoiceAddress={this.state.invoiceAddress}
                                deliveryAddress={this.state.deliveryAddress}
                                direction={'column'}
                            />
                        </>
                    )}
                </CardContent>
                <CardActions className={this.props.classes.orderCard}>{this.showButtons()}</CardActions>
            </Card>
        );
    }
}

export default withTranslation(['order', 'payment'])(withStyles(_styles)(OrderInfoCard));
