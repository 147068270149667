import * as React from 'react';
import { useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { getNumberCurrency, getNumberDecimal } from 'util/helpers';
import { OfferStore } from 'store';
import { ExtendedTheme } from 'style/NearbuyStyle';
import { withResubAutoSubscriptions } from 'resub';
import { useTranslation } from 'react-i18next';

function GraduatedPricesTable(props: { offerRef: string; showBasePrice?: boolean }) {
    /* Hooks */
    const { t } = useTranslation(['offer', 'shortunits']);
    const theme = useTheme<ExtendedTheme>();

    /* States and Store Subscribed Constants */
    const offer = OfferStore.getOne(props.offerRef);
    if (!offer || !offer.pricePerUnit) return null;

    /* Effects */

    /* Styles und StyledComponents */
    const TableCellStyled = function (props: { children: any }) {
        return (
            <TableCell
                sx={{
                    borderBottom: `1px solid ${theme.palette.common.black} !important`,
                    fontSize: '15px',
                    padding: '0px 10px',
                }}
            >
                {props.children}
            </TableCell>
        );
    };

    const CellTextDivStyled = function (props: { children: any; bold?: boolean }) {
        return (
            <div
                style={{
                    fontWeight: props.bold ? 'bold' : 'normal',
                    padding: '0px',
                }}
            >
                {props.children}
            </div>
        );
    };

    /* JSX */
    const title = (
        <Typography
            sx={{
                fontSize: '18px',
                fontWeight: 'bold',
            }}
        >
            {props.showBasePrice ? t('offer:prices') : t('offer:graduatedPrices')}
        </Typography>
    );

    const tableHeadRow = (
        <TableRow>
            <TableCellStyled>
                <CellTextDivStyled bold>{t('offer:amount')}</CellTextDivStyled>
            </TableCellStyled>
            <TableCellStyled>
                <CellTextDivStyled bold>{t('offer:pricePerUnit')}</CellTextDivStyled>
            </TableCellStyled>
        </TableRow>
    );

    const basePriceRow = (
        <TableRow>
            <TableCellStyled>
                <CellTextDivStyled>{t('offer:basePrice')}</CellTextDivStyled>
            </TableCellStyled>
            <TableCellStyled>
                <CellTextDivStyled>{getNumberCurrency(offer.pricePerUnit)}</CellTextDivStyled>
            </TableCellStyled>
        </TableRow>
    );

    const graduatedPriceRows = offer.graduatedPrices.map((graduatedPrice, index) => {
        return (
            <TableRow key={index}>
                <TableCellStyled>
                    <CellTextDivStyled>
                        {t('offer:from') +
                            ' ' +
                            getNumberDecimal(graduatedPrice.amount) +
                            ' ' +
                            t('shortunits:' + offer.totalAmount.unit)}
                    </CellTextDivStyled>
                </TableCellStyled>
                <TableCellStyled>
                    <CellTextDivStyled>{getNumberCurrency(graduatedPrice.price)}</CellTextDivStyled>
                </TableCellStyled>
            </TableRow>
        );
    });

    return (
        <Grid container item>
            <Grid item>
                {title}
                <Table>
                    <TableBody>
                        {tableHeadRow}
                        {props.showBasePrice ? basePriceRow : null}
                        {graduatedPriceRows}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}

export default withResubAutoSubscriptions(GraduatedPricesTable);
