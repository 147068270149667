import { AutoSubscribeStore, autoSubscribeWithKey, StoreBase } from 'resub';
import { PersonStore } from 'store/PersonStore';

const userExistsKey = 'USER_EXISTS';

@AutoSubscribeStore
class UserStore extends StoreBase {
    protected userExist?: boolean = undefined;

    @autoSubscribeWithKey(userExistsKey)
    public isUserExisting(): boolean | undefined {
        return this.userExist;
    }

    public setUserExists(exists?: boolean): void {
        this.userExist = exists;
        this.trigger(userExistsKey);
    }

    public refreshUser(): void {
        const user = PersonStore.getSelected();
        if (user) {
            PersonStore.loadOne(user.links.self);
        }
    }
}

export default new UserStore();
