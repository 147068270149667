import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { Company } from 'model/Company';
import { NewCompanyInRegionSystemMessage } from 'model/SystemMessage';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { CompanyStore } from 'store';

interface NewCompanyInRegionSystemMessageItemProps extends WithTranslation, RouteComponentProps {
    systemMessage: NewCompanyInRegionSystemMessage;
}

interface NewCompanyInRegionSystemMessageItemState {
    mapping: NewCompanyInRegionSystemMessageItemMapping;
    newCompany?: Company;
}

interface NewCompanyInRegionSystemMessageItemMapping extends Record<string, string> {
    company: string;
}

class NewCompanySystemMessageItem extends ComponentBase<
    NewCompanyInRegionSystemMessageItemProps,
    NewCompanyInRegionSystemMessageItemState
> {
    protected _buildState(
        props: NewCompanyInRegionSystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<NewCompanyInRegionSystemMessageItemState>,
    ): Partial<NewCompanyInRegionSystemMessageItemState> | undefined {
        const newCompany = CompanyStore.getOne(props.systemMessage.links.company);

        if (newCompany) {
            return {
                newCompany: newCompany,
                mapping: {
                    company: newCompany.name,
                },
            };
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.newCompany) return null;

        return (
            <SystemMessageItem
                onClick={() =>
                    this.state.newCompany && this.props.history.push('/company/' + this.state.newCompany.id + '/detail')
                }
                systemMessage={this.props.systemMessage}
                mapping={this.state.mapping}
            />
        );
    }
}

export default withRouter(withTranslation(['system'])(NewCompanySystemMessageItem));
