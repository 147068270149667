import { getUnitFromString } from 'model/Unit';

export class Amount {
    constructor(
        public amount: number,
        public unit: string,
    ) {}
}

export function getAmountInBaseUnit(amount: Amount): number | undefined {
    const unit = getUnitFromString(amount.unit);
    if (!unit) return undefined;
    return amount.amount * unit.conversionFactor;
}

export function getAmountInOtherUnit(amount: Amount, otherUnit: string): number | undefined {
    const thisUnit = getUnitFromString(amount.unit);
    const conversionFactor = getUnitFromString(otherUnit)?.conversionFactor;
    const thisAmountInBaseUnit = getAmountInBaseUnit(amount);
    if (!thisUnit || !thisAmountInBaseUnit || !conversionFactor) return undefined;
    return thisAmountInBaseUnit / conversionFactor;
}

export function getAmountValidationValue(amount: Amount, minAmount: Amount, maxAmount: Amount): 0 | -1 | 1 | boolean {
    const amountInBaseUnit = getAmountInBaseUnit(amount);
    const minAmountInBaseUnit = getAmountInBaseUnit(minAmount);
    const maxAmountInBaseUnit = getAmountInBaseUnit(maxAmount);

    if (minAmountInBaseUnit === undefined || maxAmountInBaseUnit === undefined || amountInBaseUnit === undefined)
        return false;

    return amountInBaseUnit === 0
        ? 0
        : amountInBaseUnit < minAmountInBaseUnit
          ? -1
          : amountInBaseUnit > maxAmountInBaseUnit
            ? 1
            : true;
}
