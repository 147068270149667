import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';

export interface ConsoleLine {
    invokedFunction: keyof Console;
    message: string;
}

const MAX_LINES = 100;

@AutoSubscribeStore
class ConsoleLineBuffer extends StoreBase {
    private consoleLines: ConsoleLine[] = new Array<ConsoleLine>(MAX_LINES);

    putLine(invokedFunction: keyof Console, message: string) {
        this.consoleLines.push({
            invokedFunction,
            message,
        });

        if (this.consoleLines.length > MAX_LINES) {
            this.consoleLines.shift();
        }
        this.trigger();
    }

    @autoSubscribe
    getAllLines(): readonly ConsoleLine[] {
        return this.consoleLines;
    }

    @autoSubscribe
    getFilteredLines(): readonly ConsoleLine[] {
        return this.consoleLines.filter((line) => line !== null);
    }

    clear() {
        this.consoleLines = new Array<ConsoleLine>(MAX_LINES);
        this.trigger();
    }
}

export default new ConsoleLineBuffer();
