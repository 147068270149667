export class Contact {
    constructor(
        public phone: string,
        public fax: string,
        public email: string,
        public responsibility: string,
        public isProcurement: boolean,
        public isSales: boolean,
        public links: ContactLinks,
    ) {}
}

export class ContactWriteView {
    constructor(
        public phone: string,
        public fax: string,
        public email: string,
        public responsibility: string,
        public isProcurement: boolean,
        public isSales: boolean,
    ) {}
}

export class ContactLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public company: string,
        public person: string,
    ) {}
}

export class CompanyContacts {
    constructor(
        public companyRef: string,
        public contactPersons: string[],
    ) {}
}

export enum ContactType {
    SALES = 'SALES',
    PROCUREMENT = 'PROCUREMENT',
}

function copyLinks(links: ContactLinks): ContactLinks {
    return new ContactLinks(links.self, links.update, links.remove, links.company, links.person);
}

export function copy(contact?: Contact): Contact {
    if (!contact) {
        return new Contact('', '', '', '', false, false, new ContactLinks('', '', '', '', ''));
    }
    return new Contact(
        contact.phone,
        contact.fax,
        contact.email,
        contact.responsibility,
        contact.isProcurement,
        contact.isSales,
        copyLinks(contact.links),
    );
}
