class SubscriptionService {
    /*
        Mocks the behaviour of a future function that checks if the company
        has any additional subscriptions besides the free one.
     */
    hasPaidSubscriptions(): boolean {
        return true;
    }

    activeSubscriptionName(): string {
        //very very mocking stuff here

        const subscriptions = ['basic', 'trader', 'voluntary'];
        const activeSubscription = subscriptions[0] + ' ' + subscriptions[Math.floor(Math.random() * 4)];
        return activeSubscription;
    }
}

export default new SubscriptionService();
