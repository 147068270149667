import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ComponentBase } from 'resub';
import React from 'react';
import { AddressStore, PersonFavouritesStore } from 'store';
import { withTranslation, WithTranslation } from 'react-i18next';
import DeleteDialog from 'components/shared/DeleteDialog';
import { DeleteIcon } from 'components/shared/Icons';
import { Company } from 'model/Company';
import { Address } from 'model/Address';
import DistanceComponent from 'components/shared/DistanceComponent';
import withStyles from '@mui/styles/withStyles';
import PersonService from 'api/PersonService';
import { Person } from 'model/Person';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const styles = (theme: Theme) =>
    createStyles({
        container: {},
        addButton: {},
        tableHeader: {
            backgroundColor: theme.palette.background.default,
        },
        tableHeaderCell: {
            color: theme.palette.text.primary,
        },
        tableRow: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.background.default,
            },
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        roundedBtn: {
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
        },
        button: {
            flex: 1,
            borderRadius: 0,
            margin: 0,
            padding: 10,
            textTransform: 'none',
        },
        gridItem: {},
        boldTypography: {
            fontWeight: 'bold',
            textTransform: 'none',
            zIndex: 99,
        },
    });

interface FavouriteListItemProps extends WithStyles<typeof styles>, WithTranslation {
    readOnly?: boolean;
    company: Company;
    person: Person;
}

interface FavouriteListItemState {
    deleteOpen: boolean;
    address: Address;
    ownAddress?: Address;
}

class FavouriteListItemComponent extends ComponentBase<FavouriteListItemProps, FavouriteListItemState> {
    protected _buildState(props: FavouriteListItemProps): Partial<FavouriteListItemState> | undefined {
        const ownAddress = AddressStore.getSelected();
        return {
            address: AddressStore.getOne(props.company.address),
            ownAddress: ownAddress,
        };
    }

    deleteFavourite(companyId: string): void {
        if (this.props.person.links.self) {
            PersonService.deleteFavouriteCompany(this.props.person.links.self, companyId).subscribe(() =>
                PersonFavouritesStore.loadOne(this.props.person.links.self),
            );
        }
    }

    showDistance(): React.ReactElement | undefined {
        if (this.state.ownAddress) {
            return <DistanceComponent address2={this.state.address} address1={this.state.ownAddress} />;
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.ownAddress || !this.state.address) {
            return null;
        }

        return (
            <>
                <TableRow className={this.props.classes.tableRow}>
                    <DeleteDialog
                        isOpen={this.state.deleteOpen}
                        quickRemovePhrase
                        onClose={(remove) => {
                            this.setState({ deleteOpen: false });
                            if (remove) {
                                this.deleteFavourite(this.props.company.id);
                            }
                        }}
                        title={this.props.t('profile:removeFavouriteQuestion')}
                        calledFrom={'FavouriteListItemComponent'}
                    />

                    <TableCell>
                        <Button
                            className={this.props.classes.button}
                            component={Link}
                            to={'/company/' + this.props.company.id + '/detail'}
                        >
                            <Typography className={this.props.classes.gridItem}>{this.props.company.name}</Typography>
                        </Button>
                    </TableCell>
                    <TableCell>{this.props.company.labelName}</TableCell>
                    <TableCell>{this.state.address.city}</TableCell>
                    <TableCell>{this.showDistance()}</TableCell>
                    {!this.props.readOnly && (
                        <TableCell align="right">
                            <IconButton
                                onClick={(
                                    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
                                ) =>
                                    event.ctrlKey || event.metaKey
                                        ? this.deleteFavourite(this.props.company.links.self)
                                        : this.setState({ deleteOpen: true })
                                }
                                className={this.props.classes.roundedBtn}
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    )}
                </TableRow>
            </>
        );
    }
}

export default withTranslation('profile')(withStyles(styles, { withTheme: true })(FavouriteListItemComponent));
