import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, AlertTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import * as React from 'react';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { withResubAutoSubscriptions } from 'resub';
import { ExtendedTheme } from 'style/NearbuyStyle';

interface NearbuyAlertProps {
    link?: never[];
    title?: string;
    content: string;
    button?: ReactElement;
}

function NearbuyAlert(props: NearbuyAlertProps) {
    /* Hooks */
    const theme = useTheme<ExtendedTheme>();
    /* States and Store Subscribed Constants */
    /* Effects */
    /* Styles und StyledComponents */
    /* JSX */

    const title = (
        <AlertTitle
            sx={{
                fontWeight: 'bold',
                paddingTop: theme.spacing(1),
                paddingLeft: theme.spacing(1),
                color: theme.palette.common.black,
            }}
        >
            {props.title}
        </AlertTitle>
    );
    const trans = () => {
        return <Trans components={props.link ? props.link : []}>{props.content}</Trans>;
    };

    const content = (
        <Typography
            sx={{
                paddingBottom: theme.spacing(1),
                paddingLeft: theme.spacing(1),
                color: theme.palette.common.black,
            }}
        >
            {props.link ? trans() : props.content}
        </Typography>
    );

    return (
        <Alert
            sx={{
                backgroundColor: theme.palette.secondary.lighter,
                textAlign: 'left',
                alignItems: 'center',
                fontSize: '1rem',
                marginBottom: theme.spacing(2),
                borderRadius: 2,
            }}
            icon={
                <InfoOutlinedIcon
                    sx={{
                        height: '100%',
                        width: 50,
                        color: theme.palette.secondary.dark,
                    }}
                />
            }
            action={props.button ? props.button : null}
        >
            {props.title ? title : null}
            {content}
        </Alert>
    );
}

export default withResubAutoSubscriptions(NearbuyAlert);
