export enum UnitType {
    WEIGHT = 'WEIGHT',
    VOLUME = 'VOLUME',
    PIECE = 'PIECE',
    LENGTH = 'LENGTH',
    ENERGY = 'ENERGY',
}

export const defaultTradeUnitTypes: UnitType[] = [UnitType.WEIGHT, UnitType.PIECE, UnitType.VOLUME];

export enum UnitString {
    kilogram = 'Kilogram',
    deciton = 'Deciton',
    gram = 'Gram',
    ton = 'Ton',
    piece = 'Piece',
    liter = 'Liter',
    meter = 'Meter',
    centimeter = 'Centimeter',
    millimeter = 'Millimeter',
    kilojoule = 'Kilojoule',
    kilocalorie = 'Kilocalorie',
}

export interface Unit {
    unit: string;
    conversionFactor: number;
    unitType: UnitType;
}

export function asUnitString(string: string): UnitString | undefined {
    return [
        'Kilogram',
        'Deciton',
        'Gram',
        'Ton',
        'Piece',
        'Liter',
        'Millimeter',
        'Centimeter',
        'Meter',
        'Kilojoule',
        'Kilocalorie',
    ].includes(string)
        ? (string as UnitString)
        : undefined;
}

export function getUnitFromString(unit?: string | null): Unit | undefined {
    switch (unit) {
        case 'Liter':
            return {
                unit: 'Liter',
                conversionFactor: 1.0,
                unitType: UnitType.VOLUME,
            };
        case 'Gram':
            return {
                unit: 'Gram',
                conversionFactor: 0.001,
                unitType: UnitType.WEIGHT,
            };
        case 'Kilogram':
            return {
                unit: 'Kilogram',
                conversionFactor: 1.0,
                unitType: UnitType.WEIGHT,
            };
        case 'Deciton':
            return {
                unit: 'Deciton',
                conversionFactor: 100.0,
                unitType: UnitType.WEIGHT,
            };
        case 'Ton':
            return {
                unit: 'Ton',
                conversionFactor: 1000.0,
                unitType: UnitType.WEIGHT,
            };
        case 'Piece':
            return {
                unit: 'Piece',
                conversionFactor: 1.0,
                unitType: UnitType.PIECE,
            };
        case 'Millimeter':
            return {
                unit: 'Millimeter',
                conversionFactor: 0.001,
                unitType: UnitType.LENGTH,
            };
        case 'Centimeter':
            return {
                unit: 'Centimeter',
                conversionFactor: 0.01,
                unitType: UnitType.LENGTH,
            };
        case 'Meter':
            return {
                unit: 'Meter',
                conversionFactor: 1.0,
                unitType: UnitType.LENGTH,
            };
        case 'Kilojoule':
            return {
                unit: 'Kilojoule',
                conversionFactor: 1.0,
                unitType: UnitType.ENERGY,
            };
        case 'Kilocalorie':
            return {
                unit: 'Kilocalorie',
                conversionFactor: 4.186,
                unitType: UnitType.ENERGY,
            };
        default:
            return undefined;
    }
}
