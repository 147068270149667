import http from 'api/http';
import {
    LevelOfProcessing,
    LevelOfProcessingExcludedWriteView,
    LevelOfProcessingWriteView,
} from 'model/LevelOfProcessing';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LevelOfProcessingStore } from 'store';

class LevelOfProcessingService {
    public getLevelOfProcessing(levelOfProcessingRef: string): Observable<LevelOfProcessing> {
        return http
            .getAxios()
            .get<LevelOfProcessing>(`${levelOfProcessingRef}`)
            .pipe(map((response) => response.data));
    }

    public listLevelsOfProcessing(): Observable<LevelOfProcessing[]> {
        return http
            .getAxios()
            .get<LevelOfProcessing[]>('levels_of_processing')
            .pipe(map((response) => response.data));
    }

    public addLevelOfProcessing(levelOfProcessingWriteView: LevelOfProcessingWriteView): Observable<LevelOfProcessing> {
        return http
            .getAxios()
            .post<LevelOfProcessing>('levels_of_processing', levelOfProcessingWriteView)
            .pipe(
                map((response) => response.data),
                tap(() => LevelOfProcessingStore.invalidateCache()),
            );
    }

    public deleteLevelOfProcessing(levelOfProcessingRef: string): Observable<number> {
        return http
            .getAxios()
            .delete<number>(`${levelOfProcessingRef}`)
            .pipe(
                map((response) => response.data),
                tap(() => LevelOfProcessingStore.invalidateCache()),
            );
    }

    public setExcludedLevelsOfProcessing(
        lopRef: string,
        lopExcludedWriteView: LevelOfProcessingExcludedWriteView,
    ): Observable<LevelOfProcessing> {
        return http
            .getAxios()
            .post<LevelOfProcessing>(`${lopRef}/exclude`, lopExcludedWriteView)
            .pipe(
                map((response) => response.data),
                tap(() => LevelOfProcessingStore.invalidateCache()),
            );
    }
}

export default new LevelOfProcessingService();
