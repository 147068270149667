import { PurchaseIntentStatus } from 'model/PurchaseIntent';

export interface Message {
    id: string;
    topic?: string | null;
    content?: string | null;
    dateSent: Date;
    seen: boolean | null;
    messageType: MessageDirection;
    links: MessageLinks;
    templateData?: Record<string, string>;
    purchaseIntentStatus?: PurchaseIntentStatus;
    contextType: MessageContextType;
}

export class MessageUnseen {
    constructor(
        public companyRef: string,
        public messagesUnseenCount: number,
        public hideMessagesUnseenBadge: boolean,
    ) {}
}

export interface MessageLinks {
    self: string;
    senderPerson?: string;
    senderCompany: string;
    receiverPerson: string;
    receiverCompany: string;
    originalSenderPerson?: string;
    originalReceiverPerson?: string;
    quote?: string;
    offer?: string;
    request?: string;
    purchaseIntent?: string;
    priceRequest?: string;
    order?: string;
}

export interface MessageWriteView {
    receiverPersonRef: string;
    receiverCompanyRef: string;
    senderCompanyRef: string;
    topic?: string;
    content?: string;
    quote?: string;
    offer?: string;
    request?: string;
    purchaseIntent?: string;
}

export enum MessageDirection {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
}

export enum MessageContextType {
    STANDARD = 'STANDARD',
    QUOTE = 'QUOTE',
    ORDER__PENDING = 'ORDER__PENDING',
    ORDER__INVOICE_ADDED = 'ORDER__INVOICE_ADDED',
    ORDER__INVOICE_EXCHANGED = 'ORDER__INVOICE_EXCHANGED',
    PRICE_REQUEST__CANCELED_BY_SELLER = 'PRICE_REQUEST__CANCELED_BY_SELLER',
    PRICE_REQUEST__CANCELED_BY_BUYER = 'PRICE_REQUEST__CANCELED_BY_BUYER',
    PRICE_REQUEST__COMPLETED = 'PRICE_REQUEST__COMPLETED',
    PRICE_REQUEST__PENDING = 'PRICE_REQUEST__PENDING',
    PRICE_REQUEST__PRICE_ADDED = 'PRICE_REQUEST__PRICE_ADDED',
    PRICE_REQUEST__REJECTED = 'PRICE_REQUEST__REJECTED',
    PURCHASE_INTENT__ACCEPTED = 'PURCHASE_INTENT__ACCEPTED',
    PURCHASE_INTENT__CANCELED_BY_SELLER = 'PURCHASE_INTENT__CANCELED_BY_SELLER',
    PURCHASE_INTENT__CANCELED_BY_BUYER = 'PURCHASE_INTENT__CANCELED_BY_BUYER',
    PURCHASE_INTENT__COMPLETED = 'PURCHASE_INTENT__COMPLETED',
    PURCHASE_INTENT__PENDING = 'PURCHASE_INTENT__PENDING',
    PURCHASE_INTENT__REJECTED = 'PURCHASE_INTENT__REJECTED',
    REQUEST = 'REQUEST',
}
