import * as React from 'react';
import { ComponentBase } from 'resub';
import { Address } from 'model/Address';
import { getDistanceBetweenAddresses } from 'util/helpers';
import Typography from '@mui/material/Typography';

interface DistanceProperties {
    address1: Address;
    address2: Address;
}

class DistanceComponent extends ComponentBase<DistanceProperties> {
    render(): React.ReactElement | null | undefined {
        if (this.props.address1 && this.props.address2)
            return (
                <>
                    <Typography fontSize={14} fontWeight={'500'}>
                        {getDistanceBetweenAddresses(this.props.address1, this.props.address2)}
                        &nbsp;km
                    </Typography>
                </>
            );
    }
}

export default DistanceComponent;
