import { ComponentBase } from 'resub';
import { Contact } from 'model/Contact';
import { Person } from 'model/Person';
import { PersonStore } from 'store';

export interface ContactSelectMenuItemState {
    person?: Person;
}

export interface ContactSelectMenuItemProps {
    contact: Contact;
}

class ContactSelectMenuItem extends ComponentBase<ContactSelectMenuItemProps, ContactSelectMenuItemState> {
    protected _buildState(
        props: ContactSelectMenuItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<ContactSelectMenuItemState>,
    ): Partial<ContactSelectMenuItemState> | undefined {
        return {
            person: PersonStore.getOne(this.props.contact.links.person),
        };
    }

    render(): string | null {
        if (!this.state.person) {
            return null;
        }
        return this.state.person.firstname + ' ' + this.state.person.lastname;
    }
}

export default ContactSelectMenuItem;
