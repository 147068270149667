import { Moment } from 'moment';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import moment from 'moment/moment';
import { Amount } from 'model/Amount';

export class Request {
    constructor(
        public id: string,
        public description: string,
        public totalAmount: number,
        public dateFrom: Moment | undefined,
        public dateEnd: Moment | undefined,
        public unit: string,
        public levelsOfProcessing: LevelOfProcessing[],
        public links: RequestLinks,
        public dateCreated: Moment | undefined,
        public dateModified: Moment | undefined,
        public active?: boolean,
    ) {}
}

export class RequestLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public company: string,
        public category: string,
        public contact: string | undefined,
    ) {}
}

export class RequestWriteViewLegacy {
    constructor(
        public dateFrom: Date | undefined,
        public dateEnd: Date | undefined,
        public description: string,
        public category: string | undefined,
        public unit: string,
        public totalAmount: number,
        public levelsOfProcessing: string[],
        public active: boolean,
        public contact: string | undefined | null,
    ) {}

    static fromRequestWriteView(requestWriteView: RequestWriteView): RequestWriteViewLegacy {
        return {
            dateFrom: requestWriteView.dateFrom,
            dateEnd: requestWriteView.dateEnd,
            description: requestWriteView.description,
            category: requestWriteView.category,
            unit: requestWriteView.totalAmount.unit,
            totalAmount: requestWriteView.totalAmount.amount,
            levelsOfProcessing: requestWriteView.levelsOfProcessing,
            active: requestWriteView.active,
            contact: requestWriteView.contact,
        };
    }
}

export class RequestWriteView {
    constructor(
        public dateFrom: Date | undefined,
        public dateEnd: Date | undefined,
        public description: string,
        public category: string | undefined,
        public totalAmount: Amount,
        public levelsOfProcessing: string[],
        public active: boolean,
        public contact: string | undefined | null,
    ) {}

    static create(request?: Request): RequestWriteView {
        if (request) {
            return new RequestWriteView(
                request.dateFrom?.toDate(),
                request.dateEnd?.toDate(),
                request.description,
                request.links.category,
                new Amount(request.totalAmount, request.unit),
                request.levelsOfProcessing.map((lop) => lop.links.self),
                request.active ?? true,
                request.links.contact,
            );
        } else {
            return new RequestWriteView(
                new Date(moment().startOf('day').toDate()),
                new Date(moment().startOf('day').add(10, 'days').toDate()),
                '',
                '',
                new Amount(1, ''),
                new Array<string>(),
                true,
                null,
            );
        }
    }
}

export class CompanyRequests {
    constructor(
        public companyRef: string,
        public requests: Request[],
    ) {}
}
