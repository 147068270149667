import Button from '@mui/material/Button';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { IconButton, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FeatureToggle from 'components/feature/FeatureToggle';
import MessageRestrictionDialog from 'components/messaging/MessageRestrictionDialog';
import ProvideOfferDialog from 'components/search/result-items/requests/ProvideOfferDialog';
import { Feature } from 'model/Feature';
import { MessageTermsStatus, Person } from 'model/Person';
import { Product } from 'model/Product';
import { Request } from 'model/Request';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { CompanyStore, ContactsStore, PersonStore, ProductStore, RequestStore } from 'store';
import withStyles from '@mui/styles/withStyles';
import CompanySummaryComponent from 'components/search/result-items/CompanySummaryComponent';
import { Transition, wrapButtonWithTooltip } from 'util/style-helpers';
import PublicContactComponent from 'components/company/view/contact/PublicContactComponent';
import Chip from '@mui/material/Chip';
import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from 'style/NearbuyStyle';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        datePicker: {
            margin: 1,
        },
        iconButton: {
            padding: 0,
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        dialog: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        heading: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        iconClose: {
            fontSize: '1.3em',
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.dark,
            },
        },
        colorPrimaryMain: {
            color: theme.palette.secondary.dark,
        },
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        headingMain: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        contentText: {
            fontSize: '15px',
            whiteSpace: 'pre-line',
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        dot: {
            fontSize: 27,
            marginRight: theme.spacing(2.8),
            fill: theme.palette.primary.main,
            verticalAlign: 'middle',
        },
    });

interface RequestFullDetailDialogProps extends WithStyles<typeof _styles>, WithTranslation {
    isOpen: boolean;
    onClose: () => void;
    requestRef: string;
}

interface RequestFullDetailDialogState {
    user: Person;
    product?: Product;
    request?: Request;
    isProvideOfferButtonDisabled: boolean;
    isProvideOfferDialogOpen: boolean;
    contactPersonRef?: string;
    contactPerson?: Person;
    isRestrictionDialogOpen: boolean;
    isProvideOfferButtonTriggered?: boolean;
}

class RequestFullDetailDialog extends ComponentBase<RequestFullDetailDialogProps, RequestFullDetailDialogState> {
    protected _buildState(): Partial<RequestFullDetailDialogState> | undefined {
        if (this.props.requestRef) {
            const newState: Partial<RequestFullDetailDialogState> = {
                request: RequestStore.getOne(this.props.requestRef),
                user: PersonStore.getSelected(),
            };

            if (newState.request && newState.request.links.category) {
                newState.product = ProductStore.getOne(newState.request.links.category);
            }

            if (newState.request) {
                if (newState.request.links.contact) {
                    const contact = ContactsStore.getOne(newState.request.links.contact);
                    if (contact) {
                        newState.contactPerson = PersonStore.getOne(contact.links.person);
                        const foreignCompany = CompanyStore.getOne(contact.links.company);
                        if (newState.contactPerson && newState.user) {
                            newState.contactPersonRef = newState.contactPerson.links.self;
                            newState.isProvideOfferButtonDisabled =
                                !foreignCompany?.verified ||
                                newState.contactPerson.messageTermsStatus != MessageTermsStatus.ACCEPTED ||
                                newState.contactPerson.links.self === newState.user.links.self;
                        }
                    }
                }
            }

            return newState;
        }
    }
    showCloseIcon(): React.ReactFragment {
        return (
            <IconButton
                onClick={(): void => {
                    captureWebEvent('close-request-dialog');
                    this.props.onClose();
                }}
                className={this.props.classes.iconButton}
                size="large"
            >
                <CloseIcon className={this.props.classes.iconClose} />
            </IconButton>
        );
    }

    showProvideOfferButton(): ReactNode {
        if (!this.state.request?.links.contact && !this.state.contactPerson) return null;
        return (
            <FeatureToggle requiredFeatures={[Feature.CAN_BUY_AND_SELL]} type={'allOf'}>
                {wrapButtonWithTooltip(
                    <Button
                        disabled={this.state.isProvideOfferButtonDisabled}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            captureWebEvent('marketplace-send-offer-button');
                            this.state.user.messageTermsStatus === MessageTermsStatus.ACCEPTED
                                ? this.setState({
                                      isProvideOfferDialogOpen: true,
                                  })
                                : this.setState({
                                      isRestrictionDialogOpen: true,
                                      isProvideOfferButtonTriggered: true,
                                  });
                        }}
                    >
                        {this.props.t('request:sendOffer')}
                    </Button>,
                    this.state.isProvideOfferButtonDisabled
                        ? this.state.contactPerson?.links.self === this.state.user.links.self
                            ? this.props.t('dialogs:noSelfProvidedOffer')
                            : this.props.t('dialogs:noMessagingInUseForRequestsContact', {
                                  firstname: this.state.contactPerson?.firstname,
                                  lastname: this.state.contactPerson?.lastname,
                              })
                        : this.props.t('dialogs:activeShowOfferButtonTooltip'),
                )}
            </FeatureToggle>
        );
    }

    showMessageRestrictionDialog(): React.ReactElement {
        return (
            <MessageRestrictionDialog
                isOpen={this.state.isRestrictionDialogOpen ?? false}
                onClose={() => this.setState({ isRestrictionDialogOpen: false })}
                isProvideOfferDialogOpen={
                    this.state.isProvideOfferButtonTriggered
                        ? () =>
                              this.setState({
                                  isProvideOfferDialogOpen: true,
                              })
                        : undefined
                }
            />
        );
    }

    render(): React.ReactElement | null {
        if (!this.state.request) {
            return null;
        }
        return (
            <Dialog
                open={this.props.isOpen}
                maxWidth="md"
                sx={{ padding: theme.spacing(1) }}
                fullWidth
                onClose={(event, reason) => {
                    if (event && reason === 'backdropClick') {
                        this.props.onClose();
                    }
                }}
                TransitionComponent={Transition}
            >
                <ProvideOfferDialog
                    requestRef={this.props.requestRef}
                    isOpen={this.state.isProvideOfferDialogOpen}
                    onClose={() => {
                        this.setState({
                            isProvideOfferDialogOpen: false,
                        });
                    }}
                />
                {this.showMessageRestrictionDialog()}
                <DialogTitle>
                    <Grid container direction={'row'} justifyContent={'space-between'} xs={12}>
                        <Grid container item direction={'row'} xs={11}>
                            <FiberManualRecordIcon className={this.props.classes.dot} />
                            <Typography className={this.props.classes.title}>
                                {this.props.t('offer:request')}
                            </Typography>
                        </Grid>
                        <Grid>{this.showCloseIcon()}</Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={this.props.classes.dialog}>
                    <Grid
                        container
                        direction={'column'}
                        spacing={2}
                        sx={{
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(2),
                        }}
                    >
                        <Grid container item>
                            <Grid item md={1} />
                            <Grid item md={6}>
                                <Typography className={this.props.classes.headingMain}>
                                    {this.state.product
                                        ? this.props.t(this.state.product.label, {
                                              ns: 'ontofood',
                                          })
                                        : ''}
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                                {this.showProvideOfferButton()}
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item md={1} />
                            <Grid container item md={5} alignContent={'flex-start'}>
                                <Grid item md={6}>
                                    <Typography className={this.props.classes.contentText}>
                                        {this.props.t('offer:amount')}
                                    </Typography>
                                </Grid>
                                <Grid item md={1} />
                                <Grid item md={5}>
                                    <Typography className={this.props.classes.contentText}>
                                        {this.state.request?.totalAmount +
                                            ' ' +
                                            (this.state.request.unit &&
                                                this.props.t('shortunits:' + this.state.request.unit))}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item md={1} />

                            <Grid item md={4}>
                                <Grid item md={12}>
                                    <StyledDatePickerComponent
                                        disabled
                                        label={this.props.t('offer:requestedFrom')}
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                helperText={undefined}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            sx={{
                                                                '&.Mui-disabled': {
                                                                    backgroundColor: 'transparent',
                                                                    color: theme.palette.action.disabled,
                                                                    padding: 0,
                                                                },
                                                            }}
                                                            disabled
                                                        >
                                                            <Calendar />
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        )}
                                        value={this.state.request.dateFrom}
                                        onChange={(): void => {
                                            return;
                                        }}
                                    />
                                    <Grid item md={12}>
                                        <StyledDatePickerComponent
                                            disabled
                                            label={this.props.t('offer:requestedUntil')}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    helperText={undefined}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton
                                                                sx={{
                                                                    '&.Mui-disabled': {
                                                                        backgroundColor: 'transparent',
                                                                        color: theme.palette.action.disabled,
                                                                        padding: 0,
                                                                    },
                                                                }}
                                                                disabled
                                                            >
                                                                <Calendar />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            value={this.state.request.dateEnd}
                                            onChange={() => {
                                                return;
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={1} />
                        </Grid>
                        {((this.state.request?.description && this.state.request?.description.length > 0) ||
                            (this.state.request?.levelsOfProcessing &&
                                this.state.request?.levelsOfProcessing.length > 0)) && (
                            <Grid container item>
                                <Grid item md={1} />
                                <Grid item md={5}>
                                    {this.state.request?.description && this.state.request?.description.length > 0 && (
                                        <>
                                            <Typography className={this.props.classes.heading}>
                                                {this.props.t('offer:productDescription')}
                                            </Typography>
                                            <Typography className={this.props.classes.contentText}>
                                                {this.state.request?.description}
                                            </Typography>
                                        </>
                                    )}
                                </Grid>
                                <Grid item md={1} />
                                <Grid item md={4}>
                                    {this.state.request?.levelsOfProcessing &&
                                        this.state.request?.levelsOfProcessing.length > 0 && (
                                            <Grid item md={12}>
                                                <Typography className={this.props.classes.heading}>
                                                    {this.props.t('offer:processing')}
                                                </Typography>
                                                {this.state.request?.levelsOfProcessing.map((lop) => {
                                                    return (
                                                        <Chip
                                                            key={lop.links.self}
                                                            label={this.props.t('levelsOfProcessing:' + lop.label)}
                                                            color="primary"
                                                            className={this.props.classes.chip}
                                                        />
                                                    );
                                                })}
                                            </Grid>
                                        )}
                                </Grid>
                                <Grid item md={1} />
                            </Grid>
                        )}
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid container item>
                            <Grid item md={1} justifyContent={'left'} alignItems={'left'} />
                            <Grid item container md={10}>
                                <Typography className={this.props.classes.heading}>
                                    {this.props.t('offer:contactHeading')}
                                </Typography>
                            </Grid>
                            <Grid item md={1} />
                        </Grid>
                        <Grid container item>
                            <Grid item md={1} />
                            <Grid container item md={5}>
                                <CompanySummaryComponent companyRef={this.state.request.links.company} />
                            </Grid>
                            <Grid item md={1} />
                            <Grid container item md={4}>
                                {this.state.request.links.contact && this.state.product && (
                                    <PublicContactComponent
                                        messageTopic={
                                            this.props.t('offer:request') +
                                            ' ' +
                                            this.props.t(this.state.product.label, { ns: 'ontofood' })
                                        }
                                        requestRef={this.state.request.links.self}
                                        isAbbreviated={true}
                                        contactRef={this.state.request.links.contact}
                                    />
                                )}
                            </Grid>
                            <Grid item md={1} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation(['shortunits', 'offer', 'request', 'levelsOfProcessing', 'dialogs', 'ontofood'])(
    withStyles(_styles, {
        withTheme: true,
    })(RequestFullDetailDialog),
);
