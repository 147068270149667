import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import InitializationManager from 'components/initialization/InitializationManager';
import i18next from 'i18next';
import { initI18Next } from 'i18n/i18n';

export class I18nInitializer implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        initI18Next();
        await i18next.loadNamespaces([
            'common',
            'ontofood',
            'dialogs',
            'system',
            'maintenance',
            'mobile',
            'messaging',
            'notification',
        ]);
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.LOADED,
    new I18nInitializer(),
    [],
);
