import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import { CompanyStore } from 'store';
import { Company } from 'model/Company';
import { DashletType, DefaultDashletProperties } from 'model/DashletProperties';
import CompanyDashlet from 'components/dashboard/company/CompanyDashlet';

const _styles = () => createStyles({});

type CurrentCompanyDashletProps = WithStyles<typeof _styles> & DefaultDashletProperties;

interface CurrentCompanyDashletState {
    company?: Company;
}

class CurrentCompanyDashlet extends ComponentBase<CurrentCompanyDashletProps, CurrentCompanyDashletState> {
    protected _buildState(): Partial<CurrentCompanyDashletState> | undefined {
        return {
            company: CompanyStore.getSelected(),
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.company) {
            return null;
        }

        return (
            <CompanyDashlet
                companyRef={this.state.company.links.self}
                dashletProperties={{ id: DashletType.currentCompany }}
            />
        );
    }
}

export default withStyles(_styles, { withTheme: true })(CurrentCompanyDashlet);
