import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import NearbuyAlert from 'components/alert/NearbuyAlert';
import DeclinedMessagingAlert from 'components/alert/DeclinedMessagingAlert';
import ForeignInboxAlert from 'components/alert/ForeignInboxAlert';
import { Company } from 'model/Company';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { CompanyStore, PersonStore } from 'store';
import { Person } from 'model/Person';
import { AlertType } from 'components/alert/Alerts';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        infoButton: {
            backgroundColor: theme.palette.secondary.lighter,
            textDecoration: 'none',
            color: theme.palette.common.black,
            marginRight: theme.spacing(2),
            borderColor: theme.palette.secondary.dark,
            whiteSpace: 'nowrap',
            '&:hover': {
                color: theme.palette.common.white,
                background: theme.palette.secondary.dark,
                borderColor: theme.palette.secondary.dark,
            },
        },
        infoLink: {
            textDecoration: 'none',
        },
    });

interface AlertFactoryProps extends WithStyles<typeof _styles>, WithTranslation {
    company?: Company;
    alert?: AlertType;
}

interface AlertFactoryState {
    terminatedChatPartner?: Person;
    terminatedChatPartnerCompany?: Company;
}

class AlertFactory extends ComponentBase<AlertFactoryProps, AlertFactoryState> {
    protected _buildState(
        props: AlertFactoryProps,
        initialBuild: boolean,
        incomingState: Readonly<AlertFactoryState> | undefined,
    ): Partial<AlertFactoryState> | undefined {
        const newState: Partial<AlertFactoryState> = {};

        newState.terminatedChatPartner = PersonStore.getSelectedTerminatedPerson();
        newState.terminatedChatPartnerCompany = CompanyStore.getSelectedTerminatedPersonCompany();

        return newState;
    }

    showAddressMissing(): ReactNode {
        if (this.props.company) {
            return (
                <NearbuyAlert
                    content={this.props.t('dialogs:addressMissingError')}
                    title={this.props.t('dialogs:addressMissingTitle')}
                    button={
                        <Button
                            component={Link}
                            to={'/edit/company/' + this.props.company.id + '/detail'}
                            variant={'outlined'}
                            className={this.props.classes.infoButton}
                            size="large"
                            onClick={() => captureWebEvent('alert-profile-complete-button')}
                        >
                            {this.props.t('dialogs:addAddress')}
                        </Button>
                    }
                />
            );
        } else return null;
    }

    showVerificationMissing(): ReactNode {
        return this.props.company ? (
            <NearbuyAlert
                button={
                    <Button
                        variant={'outlined'}
                        className={this.props.classes.infoButton}
                        component={Link}
                        to={'/edit/company/' + this.props.company.id + '/detail'}
                    >
                        {this.props.t('company:yourCompany')}
                    </Button>
                }
                content={this.props.t('company:verificationMissingAlertText')}
                title={this.props.t('company:verificationMissingAlertTitle')}
            />
        ) : null;
    }

    showForeignInbox(): ReactNode {
        return <ForeignInboxAlert />;
    }

    showDeclinedMessaging(): ReactNode {
        return <DeclinedMessagingAlert />;
    }

    showVerificationUploaded(): ReactNode {
        return (
            <NearbuyAlert
                content={this.props.t('company:alertTextUploadedVerification')}
                title={this.props.t('company:alertTitle')}
            />
        );
    }

    showApplicationPending(): ReactNode {
        return this.props.company ? (
            <NearbuyAlert
                content={this.props.t('company:applicationPendingAlertContent', {
                    companyName: this.props.company.name,
                })}
                title={this.props.t('company:applicationPendingAlertTitle')}
                link={[<a key={0} href={'mailto:' + this.props.company.email} />] as never[]}
            />
        ) : null;
    }

    showRobinsonCrusoe(): ReactNode {
        return !location.pathname.endsWith('registration') ? (
            <NearbuyAlert
                content={this.props.t('company:robinsonCrusoeAlertContent')}
                title={this.props.t('company:robinsonCrusoeAlertTitle')}
                link={[<a key={0} href={'mailto:support@nearbuy-food.de'} />] as never[]}
            />
        ) : null;
    }

    showChatPartnerTerminated(): ReactNode {
        if (this.state.terminatedChatPartner && this.state.terminatedChatPartnerCompany) {
            return (
                <NearbuyAlert
                    content={this.props.t('company:chatPartnerIsFormerEmployeeContent', {
                        firstname: this.state.terminatedChatPartner.firstname,
                        lastname: this.state.terminatedChatPartner.lastname,
                        companyName: this.state.terminatedChatPartnerCompany.name,
                    })}
                    title={this.props.t('company:chatPartnerIsFormerEmployeeTitle')}
                    button={
                        <Button
                            component={Link}
                            to={`/company/${this.state.terminatedChatPartnerCompany.id}/detail`}
                            variant={'outlined'}
                            className={this.props.classes.infoButton}
                            size="large"
                        >
                            {this.props.t('company:goToCompany')}
                        </Button>
                    }
                />
            );
        } else return null;
    }

    render(): ReactNode {
        switch (this.props.alert) {
            case AlertType.ADDRESS_MISSING:
                return this.showAddressMissing();
            case AlertType.VERIFICATION_MISSING:
                return this.showVerificationMissing();
            case AlertType.FOREIGN_INBOX:
                return this.showForeignInbox();
            case AlertType.DECLINED_MESSAGING:
                return this.showDeclinedMessaging();
            case AlertType.VERIFICATION_UPLOADED:
                return this.showVerificationUploaded();
            case AlertType.APPLICATION_PENDING:
                return this.showApplicationPending();
            case AlertType.ROBINSON_CRUSOE:
                return this.showRobinsonCrusoe();
            case AlertType.CHATPARTNER_TERMINATED:
                return this.showChatPartnerTerminated();
            default:
                return null;
        }
    }
}

export default withTranslation(['company', 'dialogs'])(withStyles(_styles, { withTheme: true })(AlertFactory));
