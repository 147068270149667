import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Grow, ImageList, ImageListItem, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ImageInfo } from 'model/Image';
import React, { useState } from 'react';
import { theme } from 'style/NearbuyStyle';

const useStyles = makeStyles(() =>
    createStyles({
        imageStyle: {
            borderRadius: 16,
            cursor: 'pointer',
            transition: 'transform 0.4s',
            transform: 'scale(0.96)',
            margin: 0,
            padding: 0,
            '&:hover': {
                transform: 'scale(1.0)',
                transition: 'transform 0.4s',
                position: 'absolute',
                boxShadow: '0 4px 6px 1px rgba(60, 64, 67, 0.209)',
            },
        },
        fullScreenImage: {
            maxWidth: '100%',
            maxHeight: '84vh',
            borderRadius: 16,
        },
        dialogIconButton: {
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
            transform: 'scale(1)',
            transition: 'transform 0.4s',
            '&:hover': {
                transform: 'scale(1.2)',
                transition: 'transform 0.4s',
                backgroundColor: 'transparent',
                color: theme.palette.common.white,
            },
        },
    }),
);

interface CompanyPresentationImagesProps {
    images?: ImageInfo[];
}

const CompanyPresentationImagesComponent: React.FC<CompanyPresentationImagesProps> = (props) => {
    const classes = useStyles();
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [selectedImage, setSelectedImage] = useState<ImageInfo | undefined>();
    const totalImages = (props.images ?? []).length;
    const [fullscreenOpen, setFullscreenOpen] = useState(false);

    const openFullscreen = (image: ImageInfo) => {
        setSelectedImage(image);
        setFullscreenOpen(true);

        const index = props.images?.findIndex((img) => img.originalFilename === image.originalFilename);
        setCurrentImageIndex(index ?? 0);
    };

    const closeFullscreen = () => {
        setSelectedImage(undefined);
        setFullscreenOpen(false);
    };

    const handleNextImage = () => {
        if (currentImageIndex < totalImages - 1) {
            const currentImage = props.images && props.images[currentImageIndex];
            const nextImage =
                currentImage && props.images?.find((image: ImageInfo) => image.ord === currentImage.ord + 1);

            if (nextImage) {
                setSelectedImage(nextImage);
                setCurrentImageIndex(currentImageIndex + 1);
            }
        }
    };

    const handlePreviousImage = () => {
        if (currentImageIndex > 0) {
            const currentImage = props.images && props.images[currentImageIndex];
            const previousImage =
                currentImage && props.images?.find((image: ImageInfo) => image.ord === currentImage.ord - 1);

            if (previousImage) {
                setSelectedImage(previousImage);
                setCurrentImageIndex(currentImageIndex - 1);
            }
        }
    };

    function showImageList() {
        const classes = useStyles();
        const isSmScreen = useMediaQuery('(max-width:1000px)');
        const isMdScreen = useMediaQuery('(max-width:1400px)');

        return (
            <>
                {props.images && (
                    <ImageList
                        variant="quilted"
                        cols={isSmScreen ? 1 : isMdScreen ? (props.images.length <= 1 ? 1 : 2) : props.images.length}
                        gap={isSmScreen ? 3 : isMdScreen ? 4 : 0}
                        rowHeight={
                            isSmScreen
                                ? 400
                                : isMdScreen
                                  ? props.images.length <= 1
                                      ? 600
                                      : 300
                                  : props.images.length <= 1
                                    ? 800
                                    : 300
                        }
                        sx={{ padding: '12px 12px 0 0' }}
                    >
                        {props.images
                            .filter((image) => image.ord !== 0)
                            .map((item) => (
                                <ImageListItem
                                    key={item.id}
                                    onClick={() => openFullscreen(item)}
                                    sx={{ margin: '12px' }}
                                >
                                    <img
                                        src={item.source}
                                        alt={item.originalFilename}
                                        loading="lazy"
                                        className={classes.imageStyle}
                                    />
                                </ImageListItem>
                            ))}
                    </ImageList>
                )}
            </>
        );
    }

    function showFullscreenImage() {
        return (
            <Dialog
                fullScreen
                TransitionComponent={Grow}
                open={fullscreenOpen}
                onClose={closeFullscreen}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        backdropFilter: 'blur(5px)',
                    },
                }}
            >
                <DialogTitle>
                    <Grid container justifyContent="end">
                        <IconButton onClick={closeFullscreen} className={classes.dialogIconButton}>
                            <CloseIcon sx={{ fontSize: '36px' }} />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="row">
                        <Grid xs={1} item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            {currentImageIndex > 0 && (
                                <IconButton onClick={handlePreviousImage} className={classes.dialogIconButton}>
                                    <ChevronLeft sx={{ fontSize: '56px' }} />
                                </IconButton>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: '84vh' }}
                        >
                            {selectedImage && (
                                <img
                                    src={selectedImage.source}
                                    className={classes.fullScreenImage}
                                    alt={selectedImage.originalFilename}
                                />
                            )}
                        </Grid>
                        <Grid item xs={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            {currentImageIndex < totalImages - 1 && (
                                <IconButton onClick={handleNextImage} className={classes.dialogIconButton}>
                                    <ChevronRight sx={{ fontSize: '56px' }} />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <>
            {showImageList()}
            {showFullscreenImage()}
        </>
    );
};

export default CompanyPresentationImagesComponent;
