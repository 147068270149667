import InvoiceService from 'api/InvoiceService';
import MessageService from 'api/MessageService';
import { addOrderMessageItemData } from 'components/messaging/chat/messagefactory/messageitemdata/OrderMessageItemData';
import MessageItemRenderer from 'components/messaging/chat/messagefactory/MessageItemRenderer';
import { Invoice } from 'model/Invoice';
import { Order } from 'model/Order';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { InvoiceStore, OrderStore, PersonStore } from 'store';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import { OrderTranslationMapping } from 'components/messaging/chat/messagefactory/OrderTranslationMapping';

interface OrderItemFactoryProps extends WithTranslation {
    baseData: MessageItemData;
}

interface OrderItemFactoryState {
    userRef: string;
    isFileSizeErrorShown: boolean;
    order?: Order;
    orderMapping?: OrderTranslationMapping;
    invoice?: Invoice;
    isTradeInfoDialogOpen: boolean;
}

class OrderMessageItemFactory extends ComponentBase<OrderItemFactoryProps, OrderItemFactoryState> {
    protected _buildState(
        props: OrderItemFactoryProps,
        initialBuild: boolean,
        incomingState: Readonly<OrderItemFactoryState> | undefined,
    ): Partial<OrderItemFactoryState> | undefined {
        const newState: Partial<OrderItemFactoryState> = {
            ...incomingState,
        };

        if (initialBuild) {
            newState.isFileSizeErrorShown = false;
        }

        if (!newState.userRef) {
            newState.userRef = PersonStore.getSelected()?.links.self;
        }

        if (props.baseData.message.links.order) {
            OrderStore.invalidateCache(props.baseData.message.links.order);
            newState.order = OrderStore.getOne(props.baseData.message.links.order);
        }

        if (newState.order && newState.order.links.invoice && !newState.invoice) {
            newState.invoice = InvoiceStore.getOne(newState.order.links.invoice);
        }

        if (
            newState.order &&
            (!newState.orderMapping || !newState.orderMapping.buyer || !newState.orderMapping.seller)
        ) {
            const sellingPerson = newState.order.links.sellingPerson
                ? PersonStore.getOne(newState.order.links.sellingPerson)
                : undefined;
            const buyingPerson = newState.order.links.buyingPerson
                ? PersonStore.getOne(newState.order.links.buyingPerson)
                : undefined;
            newState.orderMapping = {
                seller: sellingPerson ? `${sellingPerson.firstname} ${sellingPerson.lastname}` : undefined,
                buyer: buyingPerson ? `${buyingPerson.firstname} ${buyingPerson.lastname}` : undefined,
                product: props.t(`ontofood:${newState.order.productLabel}`),
                levelOfProcessing:
                    newState.order.levelsOfProcessing.length > 0
                        ? ', ' + props.t(`levelsOfProcessing:${newState.order.levelsOfProcessing[0].label}`)
                        : undefined,
            };
        }
        return newState;
    }

    isFileSizeAllowed(file: File): boolean {
        const isFileSizeOk = file.size < 5242880;
        this.setState({
            isFileSizeErrorShown: !isFileSizeOk,
        });
        return isFileSizeOk;
    }

    onShowTradeInfoDialogCallBack = (value: boolean) => {
        this.setState({
            isTradeInfoDialogOpen: value,
        });
    };

    invoiceFileInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];
            if (!this.state.order || !this.isFileSizeAllowed(file)) {
                return;
            }
            InvoiceService.uploadInvoice(
                this.state.order.links.self,
                new Blob([file], { type: file.type }),
                file.name,
            ).subscribe({
                next: (value) => {
                    MessageService.invalidateDialogByOrder(value, false);
                },
                error: (err) => {
                    // TODO: placeholder for a propper ui-error (https://youtrack.ninjaneers.de/youtrack/issue/NEARBUY-3442)
                    console.log(`[invoice] error[${err.value}]: ${err.message}`);
                },
            });
        }
    };

    render(): React.ReactElement | null {
        if (!this.state.userRef || !this.state.order) return null;
        const isUserSeller = this.state.userRef == this.state.order.links.sellingPerson;
        if (this.props.baseData.message.contextType.endsWith('INVOICE_EXCHANGED') && isUserSeller) return null;
        return (
            (this.state.orderMapping && this.props.baseData.infoBox && (
                <MessageItemRenderer
                    data={{
                        ...addOrderMessageItemData(
                            this.props.baseData,
                            this.state.order,
                            this.props.t,
                            this.state.orderMapping,
                            this.state.invoice,
                            this.invoiceFileInputOnChange,
                            this.state.isFileSizeErrorShown,
                            this.onShowTradeInfoDialogCallBack,
                            this.state.isTradeInfoDialogOpen,
                        ),
                        isLoaded: true,
                    }}
                />
            )) ?? <MessageItemRenderer data={this.props.baseData} />
        );
    }
}

export default withTranslation(['levelsOfProcessing', 'ontofood'])(OrderMessageItemFactory);
