export class Association {
    constructor(
        public id: string,
        public description: string,
        public certifiedOrganic: boolean,
        public productRelevant: boolean,
        public category: AssociationCategory | null,
        public links: AssociationLinks,
        public verificationUrl?: string,
    ) {}
}

export class AssociationLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public icon?: string,
    ) {}
}

export class AssociationWriteView {
    constructor(
        public description: string,
        public certifiedOrganic: boolean,
        public productRelevant: boolean,
        public category: AssociationCategory | null,
        public verificationUrl?: string,
    ) {}
}

export enum AssociationCategory {
    SECURITY_QUALITY = 'SECURITY_QUALITY',
    SUSTAINABILITY_REGIONALITY = 'SUSTAINABILITY_REGIONALITY',
    FEDERATION_INITIATIVE = 'FEDERATION_INITIATIVE',
}
