import * as React from 'react';
import { ComponentBase } from 'resub';
import { IconButton, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContainerType, ContainerView } from 'model/ContainerView';
import { ContainerStore, MetaStore } from 'store';
import { MetaStaticEntityType } from 'model/Meta';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { DeleteIcon } from 'components/shared/Icons';
import Grid from '@mui/material/Grid';
import { getContainerName, getNumberDecimal } from 'util/helpers';
import { getUnitFromString } from 'model/Unit';

const styles = (theme: Theme) =>
    createStyles({
        chip: {
            fontSize: '15px',
            marginBottom: theme.spacing(0),
            backgroundColor: theme.palette.primary.light,
        },
        typographyCell: {
            width: '15%',
            overflowX: 'hidden',
        },
        deleteIconCell: {
            width: '10%',
            verticalAlign: 'right',
            textAlign: 'right',
        },
        chipCell: {
            width: '75%',
            paddingBottom: theme.spacing(0.5),
        },
        table: {
            width: '100%',
            tableLayout: 'auto',
            paddingBottom: theme.spacing(1),
        },
    });

interface ContainerChipsProps extends WithStyles<typeof styles>, WithTranslation {
    containerRefs: string[];
    removeById?: (containerId: string) => void;
}

interface ContainerChipsState {
    containers: ContainerView[];
    containerTypes: ContainerType[];
}

export class ContainerChipsComponent extends ComponentBase<ContainerChipsProps, ContainerChipsState> {
    protected _buildState(
        props: ContainerChipsProps,
        initialBuild: boolean,
        incomingState?: Readonly<ContainerChipsState>,
    ): Partial<ContainerChipsState> | undefined {
        const newState: Partial<ContainerChipsState> = {
            ...incomingState,
            containers: new Array<ContainerView>(),
        };

        props.containerRefs.forEach((containerRef) => {
            const container = ContainerStore.getOne(containerRef);
            if (container) {
                newState.containers!.push(container);
            }
        });

        if (!newState.containerTypes) {
            const metaContainerType = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);

            if (!metaContainerType) return newState;

            newState.containerTypes = metaContainerType.data as ContainerType[];
        }

        if (incomingState?.containerTypes && newState.containers && newState.containers.length > 0) {
            newState.containers.sort((a: ContainerView, b: ContainerView) => {
                const nameA = getContainerName(incomingState.containerTypes, a.containerType);
                const nameB = getContainerName(incomingState.containerTypes, b.containerType);
                const sizeAinBaseUnit = a.amount * (getUnitFromString(a.unit)?.conversionFactor ?? 1);
                const sizeBinBaseUnit = b.amount * (getUnitFromString(b.unit)?.conversionFactor ?? 1);

                return nameA > nameB ? 1 : nameA < nameB ? -1 : sizeAinBaseUnit > sizeBinBaseUnit ? 1 : -1;
            });
        }

        return newState;
    }

    showDeleteButton(containerId: string): React.ReactElement | undefined {
        if (!this.props.removeById) return;

        return (
            <>
                <Grid item md={2} />
                <Grid item md={1} />
                <Grid item md={1}>
                    <IconButton
                        id={'container-chips-component-remove-by-id-button-' + containerId}
                        className={'container-chips-component-remove-by-id-button'}
                        onClick={() => this.props.removeById!(containerId)}
                        size="small"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </>
        );
    }

    render(): React.ReactElement {
        return (
            <Grid container item md={12} spacing={1}>
                <Grid item md={8}>
                    <table className={this.props.classes.table}>
                        <tbody>
                            {this.state.containers.map((container) => {
                                return (
                                    <tr key={container.id}>
                                        <td className={this.props.classes.typographyCell}>
                                            <Typography display="inline">
                                                {this.state.containerTypes &&
                                                    getContainerName(
                                                        this.state.containerTypes,
                                                        container.containerType,
                                                    )}
                                            </Typography>
                                        </td>
                                        <td className={this.props.classes.chipCell}>
                                            <Chip
                                                label={
                                                    getNumberDecimal(container.amount) +
                                                    ' ' +
                                                    this.props.t('shortunits:' + container.unit)
                                                }
                                                className={this.props.classes.chip}
                                                color={'primary'}
                                            />
                                        </td>
                                        <td className={this.props.classes.deleteIconCell}>
                                            {this.showDeleteButton(container.id)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation(['shortunits', 'containertype'])(
    withStyles(styles, { withTheme: true })(ContainerChipsComponent),
);
