import { Contact } from 'model/Contact';
import { Message, MessageWriteView } from 'model/Message';
import { Observable } from 'rxjs';
import http from 'api/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { getUuidFromString } from 'util/helpers';
import { ContactsStore, MessageStore, OfferStore, PersonStore } from 'store';
import { MessageTermsStatusWriteView, Person } from 'model/Person';
import { Dialog } from 'model/Dialog';
import i18n from 'i18next';
import { PurchaseIntent } from 'model/PurchaseIntent';
import { PriceRequest } from 'model/PriceRequest';
import { Order } from 'model/Order';
import { invalidateDialog } from 'api/MessageServiceUtil';

class MessageService {
    public sendMessageDetailed(
        receiverPersonRef: string,
        receiverCompanyRef: string,
        senderCompanyRef: string,
        content: string,
        topic: string,
        quote?: string,
    ): Observable<Message> {
        const messageWriteView: MessageWriteView = {
            quote,
            content,
            topic,
            receiverCompanyRef: receiverCompanyRef,
            receiverPersonRef: receiverPersonRef,
            senderCompanyRef: senderCompanyRef,
        };
        return this.sendMessage(messageWriteView);
    }

    public sendMessageToContact(
        contact: Contact,
        senderCompanyRef: string,
        content?: string,
        topic?: string,
        offerRef?: string,
        requestRef?: string,
        purchaseIntentRef?: string,
    ): Observable<Message> {
        const messageWriteView: MessageWriteView = {
            content,
            topic,
            receiverCompanyRef: contact.links.company,
            receiverPersonRef: contact.links.person,
            senderCompanyRef: senderCompanyRef,
            offer: offerRef,
            request: requestRef,
            purchaseIntent: purchaseIntentRef,
        };
        return this.sendMessage(messageWriteView).pipe(
            catchError((error) => {
                // if there is an error message from the Backend, we use it, else we use the default error message
                if (Object.prototype.hasOwnProperty.call(error, 'message')) {
                    if (error.message === 'Network Error') {
                        throw new Error(i18n.t('messaging:networkError'));
                    }
                    throw new Error(
                        i18n.t('messaging:generalError', {
                            errorMessage: error.message,
                        }),
                    );
                } else {
                    throw new Error(i18n.t('messaging:generalError'));
                }
            }),
        );
    }

    public setSeen(messageRef: string): Observable<Message> {
        return (
            http
                .getAxios()
                // TODO: the added inbound is subject to change, but a fix for now
                .put<Message>(messageRef + '/inbound/seen')
                .pipe(
                    map((response) => response.data),
                    map((message: Message) => message),
                    tap((message) => {
                        MessageStore.setOne(message);
                    }),
                )
        );
    }

    public getUnseenMessageCount(companyRef: string): Observable<number> {
        //TODO add message unseen link to company links and dont use id here
        return http
            .getAxios()
            .get<number>('/messages/' + getUuidFromString(companyRef) + '/inbound/unseen')
            .pipe(map((response) => response.data));
    }

    public getMessages(
        otherPerson: string,
        otherCompany: string,
        oldestMessage?: string,
        newestMessage?: string,
        limit?: number,
    ): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>('/messages', {
                params: {
                    otherPerson,
                    otherCompany,
                    oldestMessage,
                    newestMessage,
                    limit,
                },
            })
            .pipe(map((response) => response.data));
    }

    public getMessagesOfFormerEmployee(
        formerEmployee: string,
        otherPerson: string,
        otherCompany: string,
        oldestMessage?: string,
        newestMessage?: string,
        limit?: number,
    ): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>(`/messages/person/${formerEmployee}`, {
                params: {
                    otherPerson,
                    otherCompany,
                    oldestMessage,
                    newestMessage,
                    limit,
                },
            })
            .pipe(map((response) => response.data));
    }

    public getMessage(messageRef: string): Observable<Message> {
        return http
            .getAxios()
            .get<Message>(messageRef)
            .pipe(map((response) => response.data));
    }

    public getDialogs(): Observable<Dialog[]> {
        return http
            .getAxios()
            .get<Dialog[]>('messages/chats')
            .pipe(map((response) => response.data));
    }

    public getDialogsOfFormerEmployee(formerEmployeeId: string): Observable<Dialog[]> {
        return http
            .getAxios()
            .get<Dialog[]>(`messages/chats/${formerEmployeeId}`)
            .pipe(map((response) => response.data));
    }

    updateMessageTermsStatus(personId: string, messageTerm: MessageTermsStatusWriteView): Observable<Person> {
        return http
            .getAxios()
            .put<unknown>(personId + '/message_status', messageTerm)
            .pipe(
                map((response) => response.data),
                switchMap(() => PersonStore.loadOne(personId)),
            );
    }

    public invalidateDialogByPreOrder(preOrder: PurchaseIntent | PriceRequest, isBuyerSender: boolean) {
        const offer = OfferStore.getOne(preOrder.links.offer);
        if (offer && offer.links.contact) {
            const contact = ContactsStore.getOne(offer.links.contact);
            if (contact) {
                const dialog: Dialog = {
                    otherCompanyRef: isBuyerSender ? offer.links.company : preOrder.links.buyingCompany,
                    otherPersonRef: isBuyerSender ? contact.links.person : preOrder.links.buyingPerson,
                    ownCompanyRef: isBuyerSender ? preOrder.links.buyingCompany : contact.links.company,
                    ownPersonRef: isBuyerSender ? preOrder.links.buyingPerson : contact.links.person,
                };
                invalidateDialog(dialog);
            }
        }
    }

    public invalidateDialogByOrder(order: Order, isBuyerSender: boolean) {
        if (
            order.links.buyingPerson &&
            order.links.buyingCompany &&
            order.links.sellingPerson &&
            order.links.sellingCompany
        ) {
            const dialog: Dialog = {
                otherCompanyRef: isBuyerSender ? order.links.sellingCompany : order.links.buyingCompany,
                otherPersonRef: isBuyerSender ? order.links.sellingPerson : order.links.buyingPerson,
                ownCompanyRef: isBuyerSender ? order.links.buyingCompany : order.links.sellingCompany,
                ownPersonRef: isBuyerSender ? order.links.buyingPerson : order.links.sellingPerson,
            };
            invalidateDialog(dialog);
        }
    }

    private sendMessage(messageWriteView: MessageWriteView) {
        return http
            .getAxios()
            .post<Message>('messages', messageWriteView)
            .pipe(
                map((response) => response.data),
                tap((message) => {
                    MessageStore.setOne(message);
                    const currentPerson = PersonStore.getSelected();
                    if (currentPerson) {
                        const dialog = {
                            ownCompanyRef: messageWriteView.senderCompanyRef,
                            ownPersonRef: currentPerson.links.self,
                            otherCompanyRef: messageWriteView.receiverCompanyRef,
                            otherPersonRef: messageWriteView.receiverPersonRef,
                        };
                        invalidateDialog(dialog);
                    }
                }),
            );
    }
}

export default new MessageService();
