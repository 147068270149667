import SystemMessageService from 'api/SystemMessageService';
import { EmploymentStatus } from 'model/Employment';
import {
    DefaultErrorNotification,
    DeletedSystemMessageNotification,
    NotificationInfo,
} from 'model/NearbuyNotification';
import { SystemMessage } from 'model/SystemMessage';
import * as React from 'react';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, tap, throttleTime } from 'rxjs/operators';
import { Address } from 'model/Address';
import { getDistance } from 'geolib';
import qs, { ParsedQs } from 'qs';
import { Offer } from 'model/Offer';
import moment from 'moment';
import { Request } from 'model/Request';
import { ContainerType } from 'model/ContainerView';
import i18n from 'i18next';
import { AddressStore, CompanyStore, NotificationStore, PersonEmploymentStore, SystemMessageStore } from 'store';
import { Moment, now } from 'moment/moment';

// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<R>(inner: Function, ms = 0): () => Promise<R> {
    let timer: any = null;
    let resolves: any[] = [];

    return function (...args: any[]): Promise<R> {
        // Run the function after a certain amount of time
        clearTimeout(timer);
        timer = setTimeout(() => {
            // Get the result of the inner function, then apply it to the resolve function of
            // each promise that has been created since the last time the inner function was run
            const result = inner(...args);
            resolves.forEach((r) => r(result));
            resolves = [];
        }, ms);

        return new Promise<R>((r) => resolves.push(r));
    };
}

/**
 * This Decorator applies debounce to a function per instance.
 * @param waitTime
 * @param useStatic whether to debounce per instance (false), or per method descriptor (true)
 * @param resultFunctionName if provided, the function will be called after the decorated function is called.
 */
export function debounceDecorator(waitTime: number, useStatic = false, resultFunctionName?: string) {
    return (target: Record<string, any>, _propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
        if (resultFunctionName) {
            if (!(resultFunctionName in target)) {
                throw new Error('decorated class has no member "' + resultFunctionName + '"');
            }
            // TODO: check return type of Promise
        }
        const method = descriptor.value;
        // here, we use a WeakMap, so that the instances of the Components can be destroyed
        const referenceMap = new WeakMap<
            Record<string, any>,
            {
                // subject, in order to call next
                subject: Subject<any[]>;
                // observable to listen to the call
                observable: Observable<Promise<any>>;
                // listeners define which instances wait for a result
                // (if there are mor instances (useStatic == true), all result functions need to be called with the result)
                listener: Set<Record<string, any>>;
            }
        >();

        descriptor.value = function (...args: any[]): void {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let synchronizer = this;
            if (useStatic) {
                synchronizer = descriptor;
            }
            let debounced = referenceMap.get(synchronizer);
            if (!debounced) {
                const sub = new Subject<any[]>();
                const observable = sub.pipe(
                    debounceTime(waitTime),
                    map(async (a: any[]) => {
                        return await method.apply(this, a);
                    }),
                    // on execution, delete the observer, so that only newly updated are debounced
                    tap(() => referenceMap.delete(synchronizer)),
                );
                debounced = {
                    subject: sub,
                    observable: observable,
                    listener: new Set<Record<string, any>>(),
                };

                debounced.observable.subscribe(async (result) => {
                    if (!debounced) {
                        throw new Error('Could not debounce!');
                    }
                    if (resultFunctionName) {
                        // if resultFunctionName was specified, we need to call the function with the result
                        // eslint-disable-next-line @typescript-eslint/ban-types
                        const resultFunction: Function =
                            this[
                                // we already checked, that the function exists, so here we get the function, we want to call on result
                                resultFunctionName as keyof PropertyDescriptor
                            ];
                        for (const listener of debounced.listener) {
                            const synchRes = await result;
                            // now call all the waiting result functions
                            resultFunction.apply(listener, [synchRes]);
                        }
                    }
                });

                referenceMap.set(synchronizer, debounced);
            }

            if (!debounced.listener.has(this)) {
                // per instance only one subscriber!
                debounced.listener.add(this);
            }
            debounced.subject.next(args);
        };

        return descriptor;
    };
}

const THROTTLE_DECORATOR_KEY = 'ThrottleDecorator';

/**
 * this is actually a factory, but its mor handy if called Decorator instead of Factory
 * @param waitTime
 */
export function throttleDecorator(waitTime: number) {
    /*
    Basic idea:
    Create an Observable for each method that is annotated with this decorator.
    Then, every call of the annotated method is emitting a value to the observable.
    The observable the calls the original method.

    The throttleTime operator throttles the call here, so that only the first call
    is done.
     */
    return (_target: Record<string, any>, _propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
        const method = descriptor.value;

        descriptor.value = function (...args: any[]): void {
            // Reflect is used in order to store the observable per method instance
            if (!Reflect.has(this, THROTTLE_DECORATOR_KEY)) {
                const sub = new ReplaySubject<any[]>();
                const observable = sub.pipe(
                    // only allow the first one (throttle)
                    throttleTime(waitTime),
                    // after that, call the original method
                    map((a: any[]) => {
                        return method.apply(this, a);
                    }),
                );

                // now save the subject (for calling next), observable (that has the pipe), and the listener
                const throttleData: {
                    subject: typeof sub;
                    observable: typeof observable;
                } = {
                    subject: sub,
                    observable: observable,
                };
                Reflect.set(this, THROTTLE_DECORATOR_KEY, throttleData);

                // do the subscription, so that the method is invoked if needed
                throttleData.observable.subscribe(async () => {
                    if (!throttleData) {
                        throw new Error('Could not throttle!');
                    }
                });
            }

            const throttleData: {
                subject: ReplaySubject<any>;
                observable: Observable<any>;
            } = Reflect.get(this, THROTTLE_DECORATOR_KEY);

            throttleData.subject.next(args);
        };

        return descriptor;
    };
}

export function checkSetEquals(s1: Set<string>, s2: Set<string>): boolean {
    if (s1.size !== s2.size) return false;
    for (const ref of s1) {
        if (!s2.has(ref)) {
            return false;
        }
    }
    return true;
}

export function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) return false;
    for (const element of array1) {
        if (!array2.includes(element)) {
            return false;
        }
    }
    return true;
}

export function arraySwap<T>(array: T[], index1: number, index2: number): T[] {
    array[index1] = array.splice(index2, 1, array[index1])[0];
    return array;
}

export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getCompanyDistanceByLatLon(lat: number, lon: number): number | undefined {
    const selectedAddress = AddressStore.getSelected();
    if (!selectedAddress) return;

    return getDistanceBetweenAddressesAndLatLon(selectedAddress, lat, lon);
}

export function getDistanceBetweenAddresses(address1: Address, address2: Address): number | undefined {
    return getDistanceBetweenLatLon(address1.lat, address1.lon, address2.lat, address2.lon);
}

export function getDistanceBetweenAddressesAndLatLon(
    address1: Address,
    address2Lat: number,
    address2Lon: number,
): number | undefined {
    return getDistanceBetweenLatLon(address1.lat, address1.lon, address2Lat, address2Lon);
}

export function getDistanceBetweenLatLon(
    address1Lat: number,
    address1Lon: number,
    address2Lat: number,
    address2Lon: number,
): number | undefined {
    if ((address1Lat === 0.0 && address1Lon === 0.0) || (address2Lat === 0.0 && address2Lon === 0.0)) {
        return undefined;
    }
    const distance = getDistance({ lat: address2Lat, lon: address2Lon }, { lat: address1Lat, lon: address1Lon });
    return Math.round(distance / 1000);
}

//Only use this function if you know what you are doing
//We are using the HATEOAS model, so this should not be needed often
export function getUuidFromString(text?: string): string | undefined {
    const uuid = !text ? undefined : text.split('/').slice(-1).pop();
    if (uuid && uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')) {
        return uuid;
    }
}

export function getProductNameFromString(ref?: string): string | undefined {
    const encoded = !ref ? undefined : ref.split('/').pop();
    if (encoded) {
        const decoded = decodeURIComponent(encoded);
        if (/^OF#PRODUCT#.+/.test(decoded) || process.env.NODE_ENV === 'test') return decoded;
        else return undefined;
    } else return undefined;
}

export function getQueryStringArray<T>(
    params: string | string[] | ParsedQs | ParsedQs[] | undefined,
    type: Record<string, T>,
): T[] {
    const array: T[] = [];
    if (params && Array.isArray(params)) {
        params.forEach((param: string | ParsedQs) => {
            if (typeof param === 'string' && param in type) {
                array.push(type[param]);
            }
        });
    }
    return array;
}

export function getParsedQueryString(locationSearch: string): ParsedQs {
    return qs.parse(locationSearch, {
        ignoreQueryPrefix: true,
    });
}

export function getQueryStringStringified(queryString: ParsedQs): string {
    return qs.stringify(queryString, { arrayFormat: 'repeat', encode: true });
}

export function getNumberCurrency(n: number): string {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    }).format(n);
}

export function getNumberDecimal(n: number, minFrac?: number, maxFrac?: number): string {
    return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: minFrac ?? 1,
        maximumFractionDigits: maxFrac ?? 3,
    }).format(n);
}

export function convertDateOfProduct<T extends Offer | Request>(product: T): T {
    product.dateFrom = moment.utc(product.dateFrom).local();
    product.dateEnd = moment.utc(product.dateEnd).local();
    if (product.dateCreated) product.dateCreated = moment.utc(product.dateCreated).local();
    if (product.dateModified) product.dateModified = moment.utc(product.dateModified).local();
    return product;
}

/** Repeats fn() n times while predicate() does not return true. */
export function repeat(fn: (...args: any[]) => any, n: number, predicate?: () => boolean) {
    for (let x = 0; x < Math.floor(n); x++) {
        if (predicate && predicate()) return;
        fn();
    }
}

export function getContainerName(containerTypes: ContainerType[], containerTypeId: string, asSlug?: boolean): string {
    const slug = containerTypes.find((value: ContainerType) => value.id == containerTypeId)?.slug;
    return slug
        ? asSlug ?? false
            ? slug
            : i18n.t('containertype:' + slug) ?? 'containertype:' + slug
        : 'ERROR:SlugNotFound';
}

export function getMapCopy<K, V>(map: Map<K, V>): Map<K, V> {
    const newMap = new Map<K, V>();
    [...map].map((obj) => {
        newMap.set(obj[0], obj[1]);
    });
    return newMap;
}

export function xor(a: any, b: any, value?: any, strict?: boolean): boolean {
    if (strict) {
        return (a === value && b !== value) || (a !== value && b === value);
    } else {
        return (a == value && b != value) || (a != value && b == value);
    }
}

export function isUserManager(companyRef?: string): boolean {
    const ownEmployments = PersonEmploymentStore.getSelected();
    const companySelfLink = companyRef ?? CompanyStore.getSelected()?.links.self;
    if (companySelfLink !== undefined && ownEmployments !== undefined) {
        const ownEmploymentInCurrentCompany = ownEmployments.employments.filter(
            (employment) => employment.links.company === companySelfLink,
        )[0];
        return ownEmploymentInCurrentCompany
            ? ownEmploymentInCurrentCompany.status === EmploymentStatus.MANAGER
            : false;
    } else return false;
}

export function getValidUrl(url: string): string {
    return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
}

export type Modify<T, R> = Omit<T, keyof R> & R;

export function getMomentString(moment: Moment, format?: string): string {
    return moment.format(format ?? 'DD.MM.YY');
}

export function without<T, K extends keyof T>(obj: T, key: K): Omit<T, K> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [key]: deleted, ...rest } = obj;
    return rest;
}

export function deleteSystemMessage(event: React.MouseEvent, systemMessage: SystemMessage): void {
    event.stopPropagation();
    SystemMessageService.setSystemMessageIsDeleted(systemMessage.links.self, true).subscribe({
        next: (value) => {
            SystemMessageStore.invalidateCache(value.links.self);
            NotificationStore.setOne(
                new NotificationInfo(DeletedSystemMessageNotification(systemMessage.links.self), now()),
            );
        },
        error: () => {
            NotificationStore.setOne(new NotificationInfo(DefaultErrorNotification(), now()));
        },
    });
}

export function convertRefsToUuids(refs: string[]): string[] {
    return refs.map((ref) => getUuidFromString(ref)).filter((id): id is string => id !== undefined);
}

/** returns rounded down dimension values of the VisualViewport if not null or undefined.
 * Otherwise, returns 0 for both values
 * */
export function getDimensionFromVisualViewPort(vvp: VisualViewport | null | undefined) {
    return vvp ? { width: Math.floor(vvp.width), height: Math.floor(vvp.height) } : { width: 0, height: 0 };
}

export function doArraysIntersect<T>(array1: T[], array2: T[]): boolean {
    return array1.some((item) => array2.includes(item));
}

export function isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
}
