export enum SearchFilter {
    AMOUNT,
    AREA,
    FROM_DATE,
    UNTIL_DATE,
    NAME,
    RESULT_TYPE,
    ROLES,
    SHOW_ONLY_FAVOURITES,
    SHOW_OWN_DATA,
    ORGANIC,
    COMPANIES,
}
