import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import OfferDashletItem from 'components/dashboard/marketplace/offer/OfferDashletItem';
import { Offer } from 'model/Offer';
import * as React from 'react';
import { ComponentBase } from 'resub';
import { CompanyOfferStore } from 'store';

const _styles = (theme: Theme) =>
    createStyles({
        offerListBox: {
            alignItems: 'stretch',
            backgroundColor: theme.palette.common.white,
            marginBottom: theme.spacing(2),
        },
    });

interface OfferDashletListProps extends WithStyles<typeof _styles> {
    companyRef: string;
}

interface OfferDashletListState {
    offers?: Offer[];
}

const OfferCountToShow = 5;

class OfferDashletList extends ComponentBase<OfferDashletListProps, OfferDashletListState> {
    protected _buildState(
        props: OfferDashletListProps,
        initialBuild: boolean,
        incomingState?: Readonly<OfferDashletListState>,
    ): Partial<OfferDashletListState> | undefined {
        return {
            offers: CompanyOfferStore.search(props.companyRef)
                .filter((offer) => offer.active)
                .sort((a, b) => {
                    if (!a.dateModified && !b.dateModified) {
                        return 0;
                    } else if (!a.dateModified) {
                        return -1;
                    } else if (!b.dateModified) {
                        return 1;
                    } else {
                        return b.dateModified.diff(a.dateModified);
                    }
                })
                .slice(0, OfferCountToShow),
        };
    }

    render(): React.ReactElement | null {
        return (
            <Grid container spacing={1} className={this.props.classes.offerListBox}>
                {this.state.offers?.map((offer) => {
                    return <OfferDashletItem key={offer.id} offerRef={offer.links.self} />;
                })}
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(OfferDashletList);
