import { AddressLinkView } from 'model/Address';
import { VerificationType } from 'model/VerificationType';
import { Flavours } from 'style/flavour-helper';

export class Company {
    constructor(
        public name: string,
        public id: string,
        public labelName: string,
        public legalstatus: string,
        public phone: string,
        public mobile: string,
        public email: string,
        public web: string,
        public fax: string,
        public description: string,
        public visibility: string,
        public address: string,
        public taxId: string,
        public links: CompanyLinks,
        public verified = false,
        public addresses: Set<AddressLinkView> = new Set<AddressLinkView>(),
        public tenant: Flavours = Flavours.NEARBUY,
    ) {}
}

export class CompanyLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public logo?: string,
        public verification?: string,
    ) {}
}

export class CompanyWriteView {
    constructor(
        public name = '',
        public labelName = '',
        public legalstatus = '',
        public phone = '',
        public mobile = '',
        public email = '',
        public web = '',
        public fax = '',
        public description = '',
        public visibility: string = Visibility.PUBLIC,
        public address = '',
        public taxId = '',
        public verificationType?: VerificationType,
        public verificationValue?: string,
    ) {}
}

export function copy(company?: Company): Company {
    if (!company) {
        return new Company(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            Visibility.PUBLIC,
            '',
            '',
            new CompanyLinks('', '', ''),
            false,
        );
    } else {
        return new Company(
            company.name,
            company.id,
            company.labelName,
            company.legalstatus,
            company.phone,
            company.mobile,
            company.email,
            company.web,
            company.fax,
            company.description,
            company.visibility,
            company.address,
            company.taxId,
            company.links,
            company.verified,
            company.addresses,
        );
    }
}

export class AutoCompleteCompany extends Company {
    constructor(
        name: string,
        public __isNew__?: boolean,
        public createLabel?: string,
    ) {
        super(name, '', '', '', '', '', '', '', '', '', Visibility.PUBLIC, '', '', new CompanyLinks('', '', ''), false);
    }
}

export function companyToString(company: Company, full: boolean): string {
    if (full) {
        let name = '';
        if (company.name) {
            name += company.name;
            name += ' ';
        }
        if (company.legalstatus) {
            name += company.legalstatus;
        }

        let result = '';
        if (company.labelName.length > 0) {
            result += company.labelName;

            if (name.length > 0) {
                result += '(' + name.trim() + ')';
            }
            return result;
        } else {
            return name.trim();
        }
    } else {
        if (company.labelName) {
            return company.labelName;
        }
        return (company.name + ' ' + company.legalstatus).trimEnd();
    }
}

export enum Visibility {
    HIDDEN = 'HIDDEN',
    PUBLIC = 'PUBLIC',
    INTERNAL = 'INTERNAL',
    DEMO = 'DEMO',
}
