export interface Migration {
    /**
     * This function migrates from version specified in fromVersion to the
     * version Number, that is specified in the result.
     * @param version this is the int number of the version
     * of the Datastructure in the local storage.
     * @returns the new version number
     */
    migrate(version: number): Promise<number>;
}

export const VERSION_KEY = 'Version';

class LocalStorageMigrator {
    protected migrations: Map<number, Migration> = new Map<number, Migration>();

    public registerMigration(fromVersion: number, migration: Migration): Migration {
        this.migrations.set(fromVersion, migration);
        return migration;
    }

    public async migrate(): Promise<void> {
        let versionNumber = this.getVersionNumber();
        let migration = this.migrations.get(versionNumber);
        while (migration) {
            versionNumber = await migration.migrate(versionNumber);
            this.setVersionNumber(versionNumber);
            migration = this.migrations.get(versionNumber);
        }
    }

    protected getVersionNumber(): number {
        const versionString = localStorage.getItem(VERSION_KEY);
        return !versionString ? 0 : parseInt(versionString);
    }

    protected setVersionNumber(newVersion: number): void {
        localStorage.setItem(VERSION_KEY, newVersion.toString());
    }
}

export default new LocalStorageMigrator();
