import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Card, IconButton, Radio, TextField, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import MessageService from 'api/MessageService';
import OfferDashletItem from 'components/dashboard/marketplace/offer/OfferDashletItem';
import MarketItemDialog from 'components/marketplace/marketitem/dialog/MarketItemDialog';
import MarketItemTitle from 'components/messaging/chat/messagefactory/infocards/shared/MarketItemTitle';
import { Company } from 'model/Company';
import { Contact } from 'model/Contact';
import { Offer } from 'model/Offer';
import { Person } from 'model/Person';
import { Product } from 'model/Product';
import { Request } from 'model/Request';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import {
    CompanyStore,
    ContactsStore,
    DialogStore,
    EmploymentStore,
    NotificationStore,
    OfferStore,
    ProductStore,
    RequestStore,
} from 'store';
import PersonStore from 'store/PersonStore';
import { theme } from 'style/NearbuyStyle';
import { Transition } from 'util/style-helpers';
import { MessageSentNotification, NotificationInfo } from 'model/NearbuyNotification';
import { now } from 'moment/moment';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        datePicker: {
            margin: 1,
        },
        iconButton: {
            padding: 0,
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        iconClose: {
            fontSize: '1.3em',
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.dark,
            },
        },
        colorPrimaryMain: {
            color: theme.palette.secondary.dark,
        },
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        boldInfoCardText: {
            fontWeight: 'bold',
        },
        card: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        cardContent: {
            backgroundColor: theme.palette.background.default,
        },
        divider: {
            alignSelf: 'stretch',
            height: 1,
        },
    });

interface ProvideOfferDialogProps extends WithStyles<typeof _styles>, WithTranslation {
    isOpen: boolean;
    onClose: () => void;
    requestRef: string;
}

interface ProvideOfferDialogState {
    product: Product;
    request: Request;
    contact: Contact;
    contactPerson: Person;
    company: Company;
    ownCompanyRef: string;
    isProvideOfferButtonDisabled: boolean;
    filteredOffers: Offer[];
    isDialogOpen: boolean;
    offerToProvide: string;
    message: string;
}

class ProvideOfferDialog extends ComponentBase<ProvideOfferDialogProps, ProvideOfferDialogState> {
    protected _buildState(
        props: ProvideOfferDialogProps,
        initialBuild: boolean,
        incomingState: Readonly<ProvideOfferDialogState> | undefined,
    ): Partial<ProvideOfferDialogState> | undefined {
        if (this.props.requestRef) {
            const newState: Partial<ProvideOfferDialogState> = {
                ...incomingState,
                request: RequestStore.getOne(this.props.requestRef),
            };

            if (!newState.request) return newState;
            if (newState.request.links.category) {
                newState.product = ProductStore.getOne(newState.request.links.category);
            }

            if (!newState.company) newState.company = CompanyStore.getOne(newState.request.links.company);
            if (newState.request?.links.contact && !newState.contactPerson) {
                newState.contact = ContactsStore.getOne(newState.request.links.contact);
                if (newState.contact) newState.contactPerson = PersonStore.getOne(newState.contact.links.person);
            }

            const ownEmployment = EmploymentStore.getSelected();
            if (ownEmployment) newState.ownCompanyRef = ownEmployment.links.company;
            if (ownEmployment && newState.product) {
                newState.filteredOffers = OfferStore.search({
                    companies: [ownEmployment.links.company],
                    category: [newState.product.links.self],
                    includeDeactivated: true,
                }) as Offer[];
            }

            const selectedOffer = OfferStore.getSelected();
            if (selectedOffer) {
                newState.offerToProvide = selectedOffer.links.self;
            }

            return newState;
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        OfferStore.setSelected(undefined);
    }

    showOfferListItem(offer: Offer): ReactNode {
        return (
            <Grid container key={offer.id} direction={'row'} alignItems={'center'} sx={{ marginTop: theme.spacing(1) }}>
                <Grid md={1}>
                    <Radio
                        checked={this.state.offerToProvide === offer.links.self}
                        onClick={() => {
                            captureWebEvent('marketplace-send-offer-select-existing');
                            if (this.state.offerToProvide === offer.links.self) {
                                this.clear();
                            } else {
                                this.setState({
                                    offerToProvide: offer.links.self,
                                });
                            }
                            OfferStore.setSelected(undefined);
                        }}
                        sx={{
                            '&:hover': {
                                color: theme.palette.primary.main,
                            },
                            '&.Mui-checked': {
                                color: theme.palette.primary.dark,
                            },
                        }}
                        disabled={!offer.active}
                    />
                </Grid>
                <Grid md={11}>
                    <OfferDashletItem
                        offerRef={offer.links.self}
                        key={offer.id}
                        isOfferProvided={true}
                        isChosen={this.state.offerToProvide === offer.links.self}
                    />
                </Grid>
            </Grid>
        );
    }

    showOffersList(): ReactNode {
        if (!this.state.filteredOffers || !this.state.filteredOffers.length) return null;
        return (
            <Grid container>
                <Box
                    sx={{
                        minHeight: '5vh',
                        maxHeight: '20vh',
                        width: '100%',
                        overflow: 'auto',
                        paddingBottom: theme.spacing(1),
                    }}
                >
                    {this.state.filteredOffers
                        .sort((a, b) => (a.active && !b.active ? -1 : !a.active && b.active ? 1 : 0))
                        .map((offer) => {
                            return this.showOfferListItem(offer);
                        })}
                </Box>
            </Grid>
        );
    }

    showContactSection(): ReactNode {
        return (
            <Grid container item direction={'row'} xs={6} marginTop={1.5}>
                <Grid md={6} item>
                    <Typography>{this.props.t('company:company') + ': '}</Typography>
                </Grid>
                <Grid md={6} item>
                    <Typography className={this.props.classes.boldInfoCardText}>{this.state.company.name}</Typography>
                </Grid>
            </Grid>
        );
    }

    showRequestedAmountAndCompany(): ReactNode {
        return (
            <Grid item container>
                <Grid container item xs={6} paddingLeft={1.2}>
                    <Grid xs={6}>
                        <Typography>{this.props.t('request:requestedAmount')}</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography className={this.props.classes.boldInfoCardText}>
                            {this.state.request.totalAmount +
                                ' ' +
                                this.props.t('shortunits:' + this.state.request.unit)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={6}>
                    <Grid item xs={6}>
                        <Typography>{this.props.t('company:contactPerson') + ': '}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={this.props.classes.boldInfoCardText}>
                            {this.state.contactPerson?.firstname + ' ' + this.state.contactPerson?.lastname}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    showInfoCard(): ReactNode {
        if (!this.state.product) return null;
        return (
            <Card className={this.props.classes.card}>
                <CardContent className={this.props.classes.cardContent}>
                    <Grid container direction={'column'} spacing={1}>
                        <Grid container item direction={'row'} alignItems={'top'}>
                            <Grid item xs={6}>
                                <MarketItemTitle
                                    requestRef={this.props.requestRef}
                                    productLabel={this.state.product.label}
                                    levelsOfProcessing={
                                        this.state.request?.levelsOfProcessing ?? this.state.request?.levelsOfProcessing
                                    }
                                />
                            </Grid>
                            {this.showContactSection()}
                        </Grid>
                        {this.showRequestedAmountAndCompany()}
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    showCloseIcon(): React.ReactFragment {
        return (
            <IconButton
                onClick={(): void => {
                    this.props.onClose();
                    this.clear();
                }}
                className={this.props.classes.iconButton}
                size="large"
            >
                <CloseIcon className={this.props.classes.iconClose} />
            </IconButton>
        );
    }

    showMessageInput(): ReactNode {
        return (
            <Grid md={12} sx={{ paddingTop: theme.spacing(2) }}>
                <TextField
                    sx={{ width: '100%' }}
                    multiline
                    rows={4}
                    placeholder={this.props.t('request:sendMessage')}
                    label={this.props.t('request:sendMessage')}
                    onChange={(event) => {
                        this.setState({
                            message: event.target.value,
                        });
                    }}
                />
            </Grid>
        );
    }

    showMatchingOffersTitle(): ReactNode {
        return (
            <Grid container sx={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <Grid md={6} sx={{ paddingTop: theme.spacing(0.5) }}>
                    <Typography className={this.props.classes.title}>
                        {this.props.t('request:matchingOffers')}
                    </Typography>
                </Grid>
                <Grid md={6} display={'flex'} justifyContent={'right'}>
                    <Button
                        startIcon={<AddIcon />}
                        variant={'contained'}
                        onClick={() => {
                            captureWebEvent('marketplace-send-offer-add-button');
                            this.setState({
                                isDialogOpen: true,
                            });
                        }}
                    >
                        {this.props.t('dialogs:ADD')}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    send(): void {
        MessageService.sendMessageToContact(
            this.state.contact,
            this.state.ownCompanyRef,
            this.state.message,
            undefined,
            this.state.offerToProvide,
            this.state.request.links.self,
            undefined,
        ).subscribe(() => {
            NotificationStore.setOne(new NotificationInfo(MessageSentNotification(), now()));
            DialogStore.invalidateCache();
            this.props.onClose();
            this.clear();
        });
    }

    showDialogActionButtons(): ReactNode {
        return (
            <Grid sx={{ paddingRight: theme.spacing(2) }}>
                <Button
                    variant={'outlined'}
                    onClick={(): void => {
                        captureWebEvent('marketplace-cancel-send-offer-button');
                        this.props.onClose();
                        this.clear();
                    }}
                    sx={{ marginRight: theme.spacing(1) }}
                >
                    {this.props.t('dialogs:CANCEL')}
                </Button>
                <Button
                    variant={'contained'}
                    onClick={() => {
                        captureWebEvent('marketplace-send-offer-button');
                        if (this.state.message) {
                            captureWebEvent('marketplace-send-offer-with-message');
                        }
                        this.send();
                    }}
                    disabled={!this.state.offerToProvide}
                >
                    {this.props.t('dialogs:SEND')}
                </Button>
            </Grid>
        );
    }

    clear(): void {
        this.setState({
            offerToProvide: '',
        });
    }

    showHintText(): ReactNode {
        return <Typography>{this.props.t('request:requestDialogHint')}</Typography>;
    }

    render(): React.ReactElement | null {
        if (!this.state.request && !this.state.product && !this.state.contactPerson && !this.state.company) {
            return null;
        }
        return (
            <Dialog
                open={this.props.isOpen}
                maxWidth="md"
                fullWidth
                onClose={(event, reason) => {
                    if (event && reason === 'backdropClick') {
                        this.props.onClose();
                        this.clear();
                    }
                }}
                TransitionComponent={Transition}
            >
                <MarketItemDialog
                    isOpen={this.state.isDialogOpen}
                    onClose={(): void => this.setState({ isDialogOpen: false })}
                    companyRef={this.state.ownCompanyRef}
                    onDelete={(): void => this.setState({ isDialogOpen: false })}
                    isReadOnly={false}
                    offerRef={null}
                    isOfferProvided={true}
                    limitingProductRef={this.state.request.links.category}
                    calledFrom={'ProvideOfferDialog'}
                />
                <DialogTitle>
                    <Grid container direction={'row'} justifyContent={'space-between'} md={12} alignItems={'center'}>
                        <Grid container direction={'row'} md={11}>
                            <Typography className={this.props.classes.title}>
                                {this.props.t('request:showOffer')}
                            </Typography>
                        </Grid>
                        {this.showCloseIcon()}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md={12}>
                            <Typography className={this.props.classes.title}>
                                {this.props.t('request:chooseYourOffer')}
                            </Typography>
                        </Grid>
                    </Grid>
                    {this.showInfoCard()}
                    <Divider className={this.props.classes.divider} />
                    {this.showMatchingOffersTitle()}
                    {this.showOffersList()}
                    <Divider className={this.props.classes.divider} />
                    {this.showMessageInput()}
                    {this.showHintText()}
                </DialogContent>
                <DialogActions>{this.showDialogActionButtons()}</DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['dialogs', 'shortunits', 'request', 'company'])(
    withStyles(_styles, {
        withTheme: true,
    })(ProvideOfferDialog),
);
