import { WithStyles, WithTheme } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import * as React from 'react';
import { ReactFragment } from 'react';
import { Grid, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { Offer } from 'model/Offer';
import { getNumberDecimal } from 'util/helpers';
import { Amount, getAmountValidationValue } from 'model/Amount';

const styles = (theme: Theme) =>
    createStyles({
        textField: {
            '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.primary.dark,
            },
        },
        heading: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        refreshButtonIcon: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.dark,
            borderRadius: '50%',
        },
        refreshButton: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        grid: {
            marginTop: 0,
        },
        marginLeft: {
            marginLeft: '0.5rem',
        },
        marginTop: {
            marginTop: '1.5rem',
        },
        minMaxAmountText: {
            fontSize: '15px',
            marginBottom: '5px',
        },
    });

interface AmountComponentProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    offer: Offer;
    isPriceShown: boolean;
    usedInContainerSelection: boolean;
    goodsAmount?: number;
    action?: any;
    changeHandler?: (amountIn: number) => void;
}

interface AmountComponentState {
    amount: number;
    totalPrice: number;
    firstError: boolean;
}

export class AmountComponent extends ComponentBase<AmountComponentProperties, AmountComponentState> {
    protected _buildState(
        props: AmountComponentProperties,
        initialBuild: boolean,
    ): Partial<AmountComponentState> | undefined {
        const newState: Partial<AmountComponentState> = {};
        if (initialBuild) {
            newState.amount = 0;
            newState.totalPrice = 0;
            newState.firstError = true;
        }
        if (this.props.goodsAmount && this.props.goodsAmount > 0) {
            newState.firstError = false;
        }
        return newState;
    }

    validate(input: string): boolean {
        return !isNaN(parseInt(input));
    }

    componentDidUpdate(
        prevProps: Readonly<AmountComponentProperties>,
        prevState: AmountComponentState,
        prevContext: any,
    ) {
        super.componentDidUpdate(prevProps, prevState, prevContext);
        if (prevProps.goodsAmount !== this.props.goodsAmount) {
            this.calculatePrice(this.props.goodsAmount);
        }
    }

    getInputAmountValidationValue(): 0 | -1 | 1 | boolean {
        if (this.state.firstError) {
            return true;
        } else {
            const inputAmount: Amount = new Amount(this.state.amount, this.props.offer.totalAmount.unit);
            return getAmountValidationValue(inputAmount, this.props.offer.minAmount, this.props.offer.totalAmount);
        }
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.setState({
            amount: 0,
            totalPrice: 0,
        });
    }

    calculatePrice(goodsAmount: any): void {
        let tempTotalPrice = 0;
        const tempAmount = goodsAmount;

        if (this.props.offer.graduatedPrices.length > 0) {
            this.props.offer.graduatedPrices.forEach((graduatedPrice) => {
                if (tempAmount < this.props.offer.graduatedPrices[0].amount) {
                    if (this.props.offer.pricePerUnit !== null) {
                        tempTotalPrice = tempAmount * this.props.offer.pricePerUnit;
                    } else {
                        throw new Error('Price is not defined');
                    }
                } else if (tempAmount >= graduatedPrice.amount) {
                    tempTotalPrice = tempAmount * graduatedPrice.price;
                }
            });
        } else if (this.props.offer.pricePerUnit !== null) {
            tempTotalPrice = tempAmount * this.props.offer.pricePerUnit;
        }

        this.setState({
            totalPrice: tempTotalPrice,
            amount: tempAmount,
        });
    }

    /**
     * @remarks
     * This method is part of the Refresh Button which was removed. Resets values of Amount and Price fields back to 0.
     */
    // refreshValues() {
    //     if (typeof this.props.action == 'function') {
    //         this.props.action();
    //     }
    //
    //     this.setState({
    //         totalPrice: 0,
    //         amount: 0,
    //     });
    // }

    getInputHelperLocale(validationValue: boolean | 1 | -1 | 0): string {
        switch (validationValue) {
            case false:
                return '';
            case true:
                return 'common:unknownError';
            case 1:
                return 'offer:amountInputHelper_tooBig';
            case 0:
                return 'offer:amountInputHelper_zero';
            case -1:
                return 'offer:amountInputHelper_tooSmall';
        }
    }

    showTitle(): React.ReactFragment {
        return (
            <>
                {!this.props.usedInContainerSelection && (
                    <Grid item>
                        <Typography className={this.props.classes.heading}>
                            {this.props.t('offer:amountSelection')}
                        </Typography>
                    </Grid>
                )}
            </>
        );
    }

    showTotalAmount(): React.ReactElement {
        return (
            <Grid
                container
                item
                alignItems={'center'}
                justifyContent={'flex-start'}
                spacing={3}
                xs={6}
                className={this.props.classes.grid}
            >
                <Grid item>
                    <Typography>{this.props.t(this.props.t('offer:totalAmount'))}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        data-testid={'amountInput'}
                        type={'number'}
                        size={'small'}
                        hiddenLabel
                        error={this.getInputAmountValidationValue() !== true}
                        className={this.props.classes.textField}
                        disabled={this.props.usedInContainerSelection}
                        value={Number(this.state.amount.toFixed(6)).toString()}
                        onChange={(event) => {
                            if (this.state.firstError) {
                                this.setState({
                                    firstError: false,
                                });
                            }
                            if (parseInt(event.target.value) < 0) {
                                event.target.value = '0';
                            }
                            this.setState(
                                {
                                    amount: Number(event.target.value),
                                    totalPrice: Number(),
                                },
                                () => {
                                    if (this.props.isPriceShown) {
                                        this.calculatePrice(this.state.amount);
                                    }
                                    if (this.props.changeHandler) {
                                        this.props.changeHandler(this.state.amount);
                                    }
                                },
                            );
                        }}
                    />
                </Grid>
                <Grid item>
                    <Typography>{this.props.t('units:' + this.props.offer.totalAmount.unit + 'Plural')}</Typography>
                </Grid>
            </Grid>
        );
    }

    showPriceAndRefreshButton(): React.ReactElement {
        return (
            <Grid
                container
                item
                alignItems={'center'}
                justifyContent={'flex-end'}
                spacing={3}
                xs={6}
                className={this.props.classes.grid}
            >
                {this.props.isPriceShown && (
                    <>
                        <Grid item>
                            <Typography>{this.props.t('offer:totalPrice')}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                hiddenLabel
                                size={'small'}
                                disabled={true}
                                className={this.props.classes.textField}
                                value={this.state.totalPrice.toFixed(2) + this.props.t('offer:currency')}
                            />
                        </Grid>
                        {/*{this.showRefreshButton()}*/}
                    </>
                )}
            </Grid>
        );
    }

    // showRefreshButton(): React.ReactFragment {
    //     return (
    //         <Grid item>
    //             <IconButton
    //                 className={this.props.classes.refreshButton}
    //                 onClick={() => {
    //                     this.setState(
    //                         {
    //                             amount: Number(),
    //                             totalPrice: Number(),
    //                         },
    //                         () => {
    //                             this.refreshValues();
    //                         }
    //                     );
    //                 }}
    //             >
    //                 <CachedIcon
    //                     className={
    //                         this.props.classes.refreshButtonIcon
    //                     }
    //                 />
    //             </IconButton>
    //         </Grid>
    //     )
    // }

    showMinMaxPurchaseAmount(): ReactFragment {
        return (
            <Grid container direction={'column'} className={this.props.classes.marginTop}>
                <Grid item container direction={'row'}>
                    <Typography className={this.props.classes.minMaxAmountText}>
                        {this.props.t('offer:maximumAmount') +
                            ': ' +
                            getNumberDecimal(this.props.offer.totalAmount.amount) +
                            ' ' +
                            (this.props.offer.totalAmount.unit &&
                                this.props.t('shortunits:' + this.props.offer.totalAmount.unit))}
                    </Typography>
                </Grid>
                <Grid item container direction={'row'}>
                    <Typography className={this.props.classes.minMaxAmountText}>
                        {this.props.t('offer:minimumPurchase') +
                            ': ' +
                            getNumberDecimal(this.props.offer?.minAmount.amount) +
                            ' ' +
                            (this.props.offer.minAmount.unit &&
                                this.props.t('shortunits:' + this.props.offer.minAmount.unit))}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    showHelperText(): React.ReactFragment {
        const amountValidationValue = this.getInputAmountValidationValue();
        return (
            <Grid item>
                <Typography color={'red'} fontSize={'small'} data-testid={'helperText'}>
                    {amountValidationValue === true
                        ? '\u2000'
                        : this.props.t(this.getInputHelperLocale(amountValidationValue))}
                </Typography>
            </Grid>
        );
    }

    render(): React.ReactElement {
        return (
            <Grid container item direction={'column'} justifyContent={'center'}>
                {this.showTitle()}

                <Grid container item justifyContent={'center'}>
                    {this.showTotalAmount()}
                    {this.showPriceAndRefreshButton()}
                </Grid>
                {this.showMinMaxPurchaseAmount()}
                {this.showHelperText()}
            </Grid>
        );
    }
}

export default withTranslation(['offer', 'units', 'shortunits'])(
    withStyles(styles, { withTheme: true })(AmountComponent),
);
