import React, { PropsWithChildren } from 'react';
import { Button, Dialog, DialogContent, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { ReactSVG } from 'react-svg';
import { getFlavouredPublicImport } from 'style/flavour-helper';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';

const styles = (theme: Theme) =>
    createStyles({
        logo: {
            height: 'auto',
            maxWidth: '100%',
            width: '40%',
            padding: theme.spacing(0.5),
        },
        continueButton: {
            backgroundColor: theme.palette.secondary.main,
            textTransform: 'lowercase',
            color: theme.palette.common.black,
        },
    });

interface SmallScreenDialogProps extends WithStyles<typeof styles>, WithTranslation {}

class SmallScreenDialog extends React.Component<PropsWithChildren<SmallScreenDialogProps>, any> {
    render(): React.ReactNode {
        if (hasSeenAdvice()) return this.props.children;
        return (
            <Dialog open fullScreen>
                <DialogContent>
                    <Grid container direction={'column'} alignItems={'center'} spacing={3} marginTop={8}>
                        <Grid item container sx={{ justifyContent: 'center' }}>
                            <ReactSVG
                                className={this.props.classes.logo}
                                src={'/logo/' + getFlavouredPublicImport() + '.svg'}
                            />
                        </Grid>
                        <Grid item marginTop={4} marginLeft={5} marginRight={5}>
                            <Typography fontWeight={'bold'} variant={'h6'} textAlign={'center'} lineHeight={1.3}>
                                {this.props.t('mobile:header')}
                            </Typography>
                        </Grid>
                        <Grid item marginLeft={5} marginRight={5}>
                            <Typography textAlign={'center'} lineHeight={1.3}>
                                {this.props.t('mobile:description')}
                            </Typography>
                        </Grid>
                        <Grid item marginTop={3}>
                            <Button onClick={continueToNearbuy} className={this.props.classes.continueButton}>
                                <Typography fontWeight={'bold'}>{this.props.t('mobile:continueButton')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

function continueToNearbuy() {
    localStorage.setItem('mobileAdviceSeen', 'true');
    window.location.reload();
}

function hasSeenAdvice(): boolean {
    return localStorage.getItem('mobileAdviceSeen') != null;
}

export default withTranslation('mobile')(withStyles(styles, { withTheme: true })(SmallScreenDialog));
