import http from 'api/http';
import { Grade } from 'model/Grade';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class GradeService {
    public getGrade(gradeRef: string): Observable<Grade> {
        return http
            .getAxios()
            .get<Grade>(gradeRef)
            .pipe(map((response) => response.data));
    }

    public listGrades(): Observable<Grade[]> {
        return http
            .getAxios()
            .get<Grade[]>('grades')
            .pipe(map((response) => response.data));
    }
}

export default new GradeService();
