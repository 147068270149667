import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import MetaService from 'api/MetaService';
import { AdminSettingsStore } from 'store';
import { AdminSetting } from 'store/AdminSettingsStore';
import AxiosAuthInterceptorRegistration from 'components/initialization/initializers/AxiosAuthInterceptorRegistration';
import { initI18Next } from 'i18n/i18n';

export class I18nLocizeKeyLoader implements InitializationEventConsumer {
    async onInitializationEvent(_phase: InitializationPhase): Promise<void> {
        await MetaService.getLocizeKey().subscribe((apiKey) => {
            if (apiKey) {
                AdminSettingsStore.setOne({
                    key: AdminSetting.LOCIZE_API_KEY,
                    value: apiKey,
                });
                initI18Next();
            }
        });
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new I18nLocizeKeyLoader(),
    [AxiosAuthInterceptorRegistration],
);
