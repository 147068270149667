import ProductDataSheetService from 'api/ProductDataSheetService';
import { Enumber } from 'model/Additive';
import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';

@AutoSubscribeStore
class EnumberStoreClass extends StoreBase {
    protected enumbers: Enumber[] = [];

    @autoSubscribe
    getAll(): Enumber[] | undefined {
        if (this.enumbers.length > 0) {
            return this.enumbers;
        } else {
            this.fetchAll();
            return undefined;
        }
    }

    private fetchAll() {
        const newEnumbers = new Array<Enumber>();
        ProductDataSheetService.listEnumbersWithValidUsage().subscribe((enumbers) => {
            enumbers.forEach((enumber) => {
                newEnumbers.push(enumber);
            });
            this.enumbers = newEnumbers;
            this.trigger();
        });
    }

    invalidateCache() {
        this.fetchAll();
    }

    clear() {
        this.enumbers = new Array<Enumber>();
        this.trigger();
    }
}

export const EnumberStore = new EnumberStoreClass();
export default EnumberStore;
