import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import NearbuyAlert from 'components/alert/NearbuyAlert';
import MessageRestrictionDialog from 'components/messaging/MessageRestrictionDialog';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';

const _styles = (theme: Theme) =>
    createStyles({
        infoLink: {
            textDecoration: 'none',
        },
        infoButton: {
            backgroundColor: theme.palette.secondary.lighter,
            textDecoration: 'none',
            color: theme.palette.common.black,
            marginRight: theme.spacing(2),
            borderColor: theme.palette.secondary.dark,
            whiteSpace: 'nowrap',
            '&:hover': {
                color: theme.palette.common.white,
                background: theme.palette.secondary.dark,
                borderColor: theme.palette.secondary.dark,
            },
        },
    });

interface DeclinedMessagingAlertProps extends WithStyles<typeof _styles>, WithTranslation {}

interface DeclinedMessagingAlertState {
    isRestrictionDialogOpen: boolean;
}

class DeclinedMessagingAlert extends ComponentBase<DeclinedMessagingAlertProps, DeclinedMessagingAlertState> {
    render(): React.ReactElement | null {
        return (
            <>
                <MessageRestrictionDialog
                    isOpen={this.state.isRestrictionDialogOpen ?? false}
                    onClose={(): void => this.setState({ isRestrictionDialogOpen: false })}
                />
                <NearbuyAlert
                    button={
                        <Button
                            onClick={() =>
                                this.setState({
                                    isRestrictionDialogOpen: true,
                                })
                            }
                            variant={'outlined'}
                            className={this.props.classes.infoButton}
                            size="large"
                        >
                            {this.props.t('messaging:declinedMessagingButton')}
                        </Button>
                    }
                    content={this.props.t('messaging:declinedMessaging')}
                />
            </>
        );
    }
}

export default withTranslation('messaging')(withStyles(_styles, { withTheme: true })(DeclinedMessagingAlert));
