import * as React from 'react';
import Button from '@mui/material/Button';
import { WithStyles, WithTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material';
import DeleteDialog from 'components/shared/DeleteDialog';
import { DeleteIcon } from 'components/shared/Icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';

const styles = (theme: Theme) =>
    createStyles({
        deleteButton: {
            marginLeft: theme.spacing(2),
            color: theme.palette.error.main,
            marginRight: 'auto',
            borderColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.lighter,
                color: theme.palette.error.main,
                marginRight: 'auto',
                borderColor: theme.palette.error.main,
            },
        },
    });

interface DeleteButtonWithDialogProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    onDelete: () => void;
}

interface DeleteButtonWithDialogState {
    deleteDialogOpen: boolean;
}

class DeleteButtonWithDialog extends ComponentBase<DeleteButtonWithDialogProperties, DeleteButtonWithDialogState> {
    protected _buildState(
        props: DeleteButtonWithDialogProperties,
        initialBuild: boolean,
        incomingState?: Readonly<DeleteButtonWithDialogState>,
    ): Partial<DeleteButtonWithDialogState> | undefined {
        if (initialBuild) {
            return { deleteDialogOpen: false };
        }
    }

    render(): React.ReactElement {
        return (
            <>
                <DeleteDialog
                    isOpen={this.state.deleteDialogOpen}
                    title={this.props.t('dialogs:deleteDialogText')}
                    quickRemovePhrase
                    onClose={(remove) => {
                        this.setState({ deleteDialogOpen: false });
                        if (remove) {
                            this.props.onDelete();
                        }
                    }}
                    calledFrom={'DeleteButtonWithDialog'}
                />
                <Button
                    variant={'outlined'}
                    className={this.props.classes.deleteButton}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) =>
                        event.ctrlKey || event.metaKey
                            ? this.props.onDelete()
                            : this.setState({ deleteDialogOpen: true })
                    }
                >
                    <DeleteIcon />
                    {this.props.t('dialogs:DELETE')}
                </Button>
            </>
        );
    }
}

export default withTranslation('dialogs')(withStyles(styles, { withTheme: true })(DeleteButtonWithDialog));
