import http from 'api/http';
import { CompanyImages, ImageInfo } from 'model/Image';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CompanyImagesStore, CompanyStore, ImageStore } from 'store';
import { Observable, of } from 'rxjs';
import { Company } from 'model/Company';
import moment from 'moment';

class ImageService {
    stringToDate(utc: string): Date {
        const moment1 = moment(utc);
        return moment1.toDate();
    }

    public getImagesForCompany(companyId: string): Observable<CompanyImages> {
        return http
            .getAxios()
            .get<ImageInfo[]>(`images/forcompany/${companyId}`)
            .pipe(
                map((response) => response.data),
                map((images) => {
                    return images.map((image) => {
                        image.validUntil = image.validUntil
                            ? this.stringToDate(image.validUntil as unknown as string)
                            : undefined;
                        return image;
                    });
                }),
                map((imageInfos) => new CompanyImages(companyId, imageInfos)),
            );
    }

    public updateImageOrderingForCompany(companyImages: CompanyImages): Observable<unknown> {
        const oldCompanyImages = CompanyImagesStore.getOne(companyImages.companyId);
        const imageOrdering = companyImages.getImageOrdering();
        CompanyImagesStore.setOne(companyImages);
        return http
            .getAxios()
            .put<unknown>(`images/forcompany/${companyImages.companyId}`, {
                orderings: imageOrdering,
            })
            .pipe(
                tap(() => companyImages && CompanyImagesStore.setOne(companyImages)),
                catchError(() => {
                    oldCompanyImages && CompanyImagesStore.setOne(oldCompanyImages);
                    return of(oldCompanyImages);
                }),
            );
    }

    public updateImageOrder(companyId: string, companyImages: ImageInfo[]): void {
        let changed = false;

        // update ord numbers
        for (let i = 0; i < companyImages.length; i++) {
            const companyImage: ImageInfo | undefined = companyImages[i];
            if (!companyImage) {
                i--;
                continue;
            }

            if (companyImage.ord !== i) {
                changed = true;
            }
            companyImage.ord = i;
        }

        if (!changed) {
            return;
        }

        const newCompanyImages = new CompanyImages(companyId, companyImages);

        this.updateImageOrderingForCompany(newCompanyImages).subscribe();
    }

    public uploadImageForCompany(companyId: string, image: File, ord = 0): Observable<CompanyImages | undefined> {
        const data = new FormData();
        data.append('image', image, image.name);
        return http
            .getAxios()
            .post<ImageInfo>(`images/forcompany/${companyId}`, data, {
                params: {
                    ord,
                },
            })
            .pipe(
                map((response) => response.data),
                switchMap(() => {
                    return CompanyImagesStore.loadOne(companyId, undefined, (observable: Observable<CompanyImages>) =>
                        observable.pipe(
                            tap((companyImages: CompanyImages) => {
                                this.updateImageOrder(companyImages.companyId, companyImages.images);
                            }),
                        ),
                    ) as Observable<CompanyImages | undefined>;
                }),
            );
    }

    public deleteImage(image: ImageInfo): Observable<number> {
        return http
            .getAxios()
            .delete(image.links.remove)
            .pipe(map((response) => response.status));
    }

    public deleteLogo(companyId: string): Observable<number> {
        return http
            .getAxios()
            .delete(`${companyId}/logo`)
            .pipe(map((response) => response.status));
    }

    public setCompanyLogo(company: Company, image: File): Observable<Company> {
        const data = new FormData();
        data.append('image', image, image.name);
        return http
            .getAxios()
            .post<ImageInfo>(`companies/${company.id}/logo`, data)
            .pipe(
                map((response) => response.data),
                tap((companyLogo) => {
                    companyLogo.links.self && ImageStore.loadOne(companyLogo.links.self);
                }),
                switchMap(() => CompanyStore.loadOne(company.links.self)),
            );
    }

    public getImage(imageRef: string): Observable<ImageInfo> {
        return http
            .getAxios()
            .get<ImageInfo>(imageRef)
            .pipe(
                map((response) => response.data),
                map((image) => {
                    image.validUntil = image.validUntil
                        ? this.stringToDate(image.validUntil as unknown as string)
                        : undefined;
                    return image;
                }),
            );
    }
}

export default new ImageService();
