import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';
import ProductService from 'api/ProductService';
import { Product } from 'model/Product';

@AutoSubscribeStore
class ProductParentsStoreClass extends StoreBase {
    protected parentsMap: Map<string, Product[]> = new Map<string, Product[]>();

    @autoSubscribe
    get(productRef: string): Product[] | undefined {
        const parents = this.parentsMap.get(productRef);
        if (parents === undefined) this.search(productRef);
        return parents;
    }

    private search(productRef: string) {
        ProductService.getAllParents(productRef).subscribe((parents) => {
            this.parentsMap.set(productRef, parents);
            this.trigger();
        });
    }

    invalidateCache(productRef?: string) {
        if (productRef !== undefined) this.parentsMap.delete(productRef);
        else this.clear();
        this.trigger();
    }

    clear() {
        this.parentsMap = new Map<string, Product[]>();
        this.trigger();
    }
}

export const ProductParentsStore = new ProductParentsStoreClass();
export default ProductParentsStore;
