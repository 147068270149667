import * as React from 'react';
import { ComponentBase } from 'resub';
import ProductChip from 'components/product/ProductChip';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AssortmentStore, CompanyAssortmentStore, CompanyDemandStore, DemandStore } from 'store';
import { Button, Grid } from '@mui/material';

interface CompanyProductChipsProperties extends WithTranslation {
    companyRef: string;
}

interface CompanyProductChipsState {
    companyAssortmentProducts: (string | undefined)[];
    companyDemandProducts: (string | undefined)[];
    showMoreChips: boolean;
}

class CompanyProductChips extends ComponentBase<CompanyProductChipsProperties, CompanyProductChipsState> {
    protected _buildState(props: CompanyProductChipsProperties): Partial<CompanyProductChipsState> | undefined {
        const assortmentProducts =
            CompanyAssortmentStore.getOne(props.companyRef)?.assortments.map(
                (ref) => AssortmentStore.getOne(ref)?.links.category,
            ) ?? [];

        const demandProducts =
            CompanyDemandStore.getOne(props.companyRef)?.demands.map(
                (ref) => DemandStore.getOne(ref)?.links.category,
            ) ?? [];

        return {
            companyAssortmentProducts: assortmentProducts,
            companyDemandProducts: demandProducts,
        };
    }

    render(): React.ReactElement {
        return (
            <>
                <Grid>
                    {this.state.companyAssortmentProducts
                        .slice(0, this.state.showMoreChips ? undefined : 4)
                        .map(
                            (product) =>
                                product && (
                                    <ProductChip key={product} productRef={product} type="company" color="secondary" />
                                ),
                        )}

                    {this.state.companyDemandProducts
                        .slice(0, this.state.showMoreChips ? undefined : 4)
                        .map(
                            (product) =>
                                product && (
                                    <ProductChip key={product} productRef={product} type="company" color="primary" />
                                ),
                        )}

                    {this.state.companyAssortmentProducts.length > 5 &&
                        (this.state.showMoreChips ? (
                            <Button
                                size={'small'}
                                variant={'outlined'}
                                onClick={() =>
                                    this.setState({
                                        showMoreChips: false,
                                    })
                                }
                            >
                                {this.props.t('common:showLess')}
                            </Button>
                        ) : (
                            <Button
                                size={'small'}
                                variant={'outlined'}
                                onClick={() =>
                                    this.setState({
                                        showMoreChips: true,
                                    })
                                }
                            >
                                {this.props.t('common:showMore')}
                            </Button>
                        ))}
                </Grid>
            </>
        );
    }
}

export default withTranslation('common')(CompanyProductChips);
