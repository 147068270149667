import React, { Component, ReactElement, ReactNode } from 'react';

export default class ErrorBoundary extends Component<Record<string, unknown>, { hasError: boolean }> {
    constructor(props: { hasError: boolean }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): { hasError: boolean } {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, info: any): void {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
        console.error(error, info);
    }

    render(): ReactElement | ReactNode {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}
