import Typography from '@mui/material/Typography';
import * as React from 'react';

export const BoldTypography = function (props: { children: any }) {
    return (
        <Typography
            sx={{
                fontWeight: 'bold',
                border: 'none',
                paddingBottom: 0,
            }}
        >
            {props.children}
        </Typography>
    );
};
