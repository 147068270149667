import React, { ReactElement, ReactNode } from 'react';
import { ComponentBase } from 'resub';
import withStyles from '@mui/styles/withStyles';
import { withTranslation, WithTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Card, CardActions, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { ExtendedTheme, theme } from 'style/NearbuyStyle';
import createStyles from '@mui/styles/createStyles';
import { ContactsStore, PersonImagesStore, PersonStore } from 'store';
import { Contact } from 'model/Contact';
import { Person } from 'model/Person';
import { ImageInfo } from 'model/Image';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CardContent from '@mui/material/CardContent';
import { Email, Fax, Phone } from '@mui/icons-material';
import { classNames } from 'util/style-helpers';
import PublicContactMessageButton from 'components/messaging/PublicContactMessageButton';

const styles = (theme: ExtendedTheme) =>
    createStyles({
        card: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxWidth: '100%',
            borderRadius: '16px',
            padding: '16px',
        },
        cardContent: {
            flex: 1,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardActions: {
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(1),
        },
        boldTypography: {
            textDecoration: 'none',
            fontWeight: '600',
            color: theme.palette.primary.dark,
        },
        overflowRules: {
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            textOverflow: 'ellipsis',
            minWidth: '25px',
            transition: 'transform 2.9s',
        },
        hoverOver: {
            '&:hover': {
                textOverflow: 'inherit',
                overflow: 'visible',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                hyphens: 'auto',
            },
        },
        detailsHover: {
            '&:hover': {
                backgroundColor: theme.palette.primary.lighter,
                borderRadius: 6,
                alignItems: 'center',
            },
        },
        avatarPlaceholder: {
            width: 50,
            height: 50,
            backgroundColor: theme.palette.primary.light,
        },
        avatar: {
            width: 50,
            height: 50,
        },
    });

interface ContactDetailProps {
    icon: React.ElementType;
    contactInfo: string;
    hrefPrefix: string;
}

interface ContactInfoCardComponentProps extends WithStyles<typeof styles>, WithTranslation {
    contactRef: string;
}

interface ContactInfoCardComponentState {
    contact: Contact;
    person: Person;
    personPortrait?: ImageInfo | null;
}

class ContactInfoCardComponent extends ComponentBase<ContactInfoCardComponentProps, ContactInfoCardComponentState> {
    protected _buildState(
        props: ContactInfoCardComponentProps,
        initialBuild: boolean,
    ): Partial<ContactInfoCardComponentState> | undefined {
        const newState: Partial<ContactInfoCardComponentState> = {
            contact: ContactsStore.getOne(this.props.contactRef),
        };
        if (newState.contact) {
            const userId = newState.contact.links.person.split('/').pop();

            if (userId) {
                newState.person = PersonStore.getOne(newState.contact.links.person);
                newState.personPortrait = PersonImagesStore.getOne(userId)?.getLatestImage();
            }
        }
        return newState;
    }

    getProfileImage(): ReactElement {
        const { avatar, avatarPlaceholder } = this.props.classes;
        const { personPortrait } = this.state;

        return personPortrait ? (
            <Avatar className={avatar} src={personPortrait.source} />
        ) : (
            <Avatar className={avatar}>
                <PersonIcon className={avatarPlaceholder} />
            </Avatar>
        );
    }

    getResponsibility(): string {
        const { contact } = this.state;

        const responsibilities = [
            contact.isProcurement && this.props.t('company:procurement'),
            contact.isSales && this.props.t('company:sales'),
            contact.responsibility && this.props.t(contact.responsibility),
        ];

        return responsibilities.filter(Boolean).join(', ');
    }

    showContact(): ReactNode {
        const { person } = this.state;

        return (
            <Grid item container spacing={6} sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs={2}>
                    {this.getProfileImage()}
                </Grid>
                <Grid item xs={10} container direction="column">
                    {person && (
                        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
                            {`${person.firstname} ${person.lastname}`}
                        </Typography>
                    )}
                    <Typography>{this.getResponsibility()}</Typography>
                </Grid>
            </Grid>
        );
    }

    ContactDetail = ({ icon: Icon, contactInfo, hrefPrefix }: ContactDetailProps) => (
        <Grid
            item
            container
            direction={'row'}
            className={this.props.classes.detailsHover}
            sx={{ padding: theme.spacing(0.5) }}
        >
            <Grid item xs={1}>
                <Icon sx={{ color: theme.palette.primary.dark, alignContent: 'center', display: 'flex' }} />
            </Grid>
            <Grid item xs={11}>
                <Typography
                    className={classNames(
                        this.props.classes.overflowRules,
                        this.props.classes.hoverOver,
                        this.props.classes.boldTypography,
                    )}
                >
                    <a className={this.props.classes.boldTypography} href={`${hrefPrefix}:${contactInfo}`}>
                        {contactInfo}
                    </a>
                </Typography>
            </Grid>
        </Grid>
    );

    showContactDetails = (): ReactNode => {
        const { email, phone, fax } = this.state.contact;

        return (
            <Grid item container direction={'column'}>
                {email && this.ContactDetail({ icon: Email, contactInfo: email, hrefPrefix: 'mailto' })}
                {phone && this.ContactDetail({ icon: Phone, contactInfo: phone, hrefPrefix: 'tel' })}
                {fax && this.ContactDetail({ icon: Fax, contactInfo: fax, hrefPrefix: 'fax' })}
            </Grid>
        );
    };

    render(): ReactNode {
        return (
            <Card className={this.props.classes.card}>
                {this.showContact()}
                <CardContent className={this.props.classes.cardContent}>
                    <Grid container direction="column">
                        {this.showContactDetails()}
                    </Grid>
                </CardContent>
                <CardActions className={this.props.classes.cardActions}>
                    <PublicContactMessageButton
                        calledFrom={'ContactInfoCardComponent'}
                        contactRef={this.props.contactRef}
                        isOnCompanyPresentation
                    />
                </CardActions>
            </Card>
        );
    }
}

export default withTranslation('company')(withStyles(styles, { withTheme: true })(ContactInfoCardComponent));
