import { Amount } from 'model/Amount';
import {
    MarketItemDialogProperties,
    MarketItemDialogState,
} from 'components/marketplace/marketitem/dialog/MarketItemDialog';
import { ContainerWriteView } from 'model/ContainerView';
import { FormValidationHelper } from 'util/FormErrors';
import moment from 'moment/moment';
import { getUnitFromString, Unit } from 'model/Unit';

export class MarketItemDialogHelpers {
    constructor(private state: MarketItemDialogState) {}

    getTotalAmount(): Amount | undefined {
        return this.state.offerWriteView?.totalAmount ?? this.state.requestWriteView?.totalAmount;
    }

    static findDuplicatePositions<T>(array: T[], property?: keyof T): number[] {
        const set = new Set();
        const duplicatePositions: number[] = [];

        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            // Check if the property exists
            if (item && typeof item === 'object' && property && !(property in item)) {
                console.error(`Property "${property.toString()}" does not exist in item at index ${i}!`);
            }
            const value = JSON.stringify(property ? item[property] : item);

            if (set.has(value)) {
                duplicatePositions.push(i); // Add the position to the array
            } else {
                set.add(value);
            }
        }
        return duplicatePositions;
    }

    getMinAmount(): Amount | undefined {
        return this.state.offerWriteView?.minAmount;
    }

    getTotalAmountUnit(): Unit | undefined {
        return getUnitFromString(this.getTotalAmount()?.unit ?? '');
    }

    getMinAmountUnit(): Unit | undefined {
        return getUnitFromString(this.getMinAmount()?.unit ?? '');
    }

    static areAllNewContainersValid(newContainers: ContainerWriteView[] | undefined): boolean {
        if (!newContainers) return true;
        else
            return newContainers.reduce<boolean>(
                (valid, container) => valid && !!(container.amount && container.unit && container.containerType != ''),
                true,
            );
    }

    static validate(
        formHelper: FormValidationHelper,
        prevState: MarketItemDialogState,
        props: MarketItemDialogProperties,
    ): void {
        if (!prevState) return;
        if (prevState.isOffer && prevState.offerWriteView) {
            if (!prevState.offerWriteView.totalAmount.amount || prevState.offerWriteView.totalAmount.amount <= 0) {
                formHelper.setError('totalAmount', props.t('offer:errorInvalidAmount'));
            }
            if (prevState.offerWriteView.minAmount.amount && prevState.offerWriteView.minAmount.amount < 0) {
                formHelper.setError('minAmount', props.t('offer:errorInvalidAmount'));
            }
            if (
                prevState.offerWriteView.minAmount &&
                prevState.offerWriteView.totalAmount &&
                prevState.offerWriteView.minAmount.amount *
                    (getUnitFromString(prevState.offerWriteView.minAmount.unit)?.conversionFactor ?? 1) >
                    prevState.offerWriteView.totalAmount.amount *
                        (getUnitFromString(prevState.offerWriteView.totalAmount.unit)?.conversionFactor ?? 1)
            ) {
                formHelper.setError('minAmount', props.t('offer:minAmountGeTotalAmount'));
            }
            if (
                (prevState.offerWriteView.pricePerUnit && prevState.offerWriteView.pricePerUnit < 0) ||
                prevState.offerWriteView.pricePerUnit === undefined
            ) {
                formHelper.setError('pricePerUnit', props.t('offer:errorInvalidPrice'));
            }
            if (!prevState.offerWriteView.dateFrom) {
                formHelper.setError('noFromDate', props.t('offer:errorNoDate'));
            }
            if (!prevState.offerWriteView.dateEnd) {
                formHelper.setError('noUntilDate', props.t('offer:errorNoDate'));
            }
            // If graduated prices of offer has duplicates in amount
            if (
                prevState.offerWriteView.graduatedPrices.length > 0 &&
                this.findDuplicatePositions(prevState.offerWriteView.graduatedPrices, 'amount').length > 0
            ) {
                formHelper.setError('', '');
            }
            // If containers of offer has duplicates
            if (
                !!prevState.containers &&
                prevState.containers.length > 0 &&
                !!prevState.newContainers &&
                prevState.newContainers.length > 0 &&
                this.findDuplicatePositions(
                    prevState.containers
                        .filter((container) => container != undefined)
                        .map((container) => {
                            return new ContainerWriteView(container.containerType, container.amount, container.unit);
                        })
                        .concat(prevState.newContainers),
                ).length > 0
            ) {
                formHelper.setError('', '');
            }
            // If offer has been set to active and the until date is invalid
            if (
                prevState.offerWriteView.active &&
                prevState.offerWriteView.dateEnd &&
                moment(prevState.offerWriteView.dateEnd).isBefore(moment().startOf('day'))
            ) {
                formHelper.setError('noUntilDate', props.t('offer:errorNoDate'));
            }
            if (
                prevState.offerWriteView.dateFrom &&
                prevState.offerWriteView.dateEnd &&
                moment(prevState.offerWriteView.dateEnd).isBefore(prevState.offerWriteView.dateFrom)
            ) {
                formHelper.setError('noUntilDate', props.t('offer:errorUntilBeforeFrom'));
            }
        } else if (prevState.isRequest && prevState.requestWriteView) {
            if (!prevState.requestWriteView.totalAmount.amount || prevState.requestWriteView.totalAmount.amount <= 0) {
                formHelper.setError('totalAmount', props.t('offer:errorInvalidAmount'));
            }
            if (!prevState.requestWriteView.dateFrom) {
                formHelper.setError('noFromDate', props.t('offer:errorNoDate'));
            }
            if (!prevState.requestWriteView.dateEnd) {
                formHelper.setError('noUntilDate', props.t('offer:errorNoDate'));
            }

            if (
                prevState.requestWriteView.active &&
                prevState.requestWriteView.dateEnd &&
                moment(prevState.requestWriteView.dateEnd).isBefore(moment().startOf('day'))
            ) {
                formHelper.setError('noUntilDate', props.t('offer:errorNoDate'));
            }
            if (
                prevState.requestWriteView.dateFrom &&
                prevState.requestWriteView.dateEnd &&
                moment(prevState.requestWriteView.dateEnd).isBefore(moment(prevState.requestWriteView.dateFrom))
            ) {
                formHelper.setError('noUntilDate', props.t('offer:errorUntilBeforeFrom'));
            }
        }
    }
}
