import i18n from 'i18next';

export enum NotificationType {
    TEST = 'TEST',
    NEW_MESSAGE = 'NEW_MESSAGE',
    SYSTEM = 'SYSTEM',
    HEARTBEAT = 'HEARTBEAT',
    MESSAGE_SENT = 'MESSAGE_SENT',
    PRE_ORDER_SENT = 'PRE_ORDER_SENT',
    INVOICE_EXCHANGED = 'INVOICE_EXCHANGED',
    ORDER_MARKED_COMPLETE = 'ORDER_MARKED_COMPLETE',
    ORDER_MARKED_ACTIVE = 'ORDER_MARKED_ACTIVE',
    DELETED_SYSTEM_MESSAGE = 'DELETED_SYSTEM_MESSAGE',
    HIDE_GLOBAL_SYSTEM_MESSAGE_SNACKBAR = 'HIDE_GLOBAL_SYSTEM_MESSAGE_SNACKBAR',
    DEFAULT_ERROR = 'DEFAULT_ERROR',
    VERIFY_WITHOUT_ADDRESS = 'VERIFY_WITHOUT_ADDRESS',
}

export class NearbuyNotification {
    constructor(
        public type: NotificationType,
        public userId: string | undefined,
        public value: string,
        public companyId?: string,
        public isSeller?: boolean,
        public isOrderCompleted?: boolean,
        public systemMessageRef?: string,
    ) {}
}

// ist eine Funktion, da i18n erst später initialisiert wird
export const MessageSentNotification = (): NearbuyNotification =>
    new NearbuyNotification(NotificationType.MESSAGE_SENT, undefined, i18n.t('messaging:messageSent'));

export const PreOrderSentNotification = (): NearbuyNotification =>
    new NearbuyNotification(NotificationType.PRE_ORDER_SENT, undefined, i18n.t('notification:preOrderSent'));

export const InvoiceSentNotification = (): NearbuyNotification =>
    new NearbuyNotification(NotificationType.INVOICE_EXCHANGED, undefined, i18n.t('notification:invoiceSent'));

export const OrderMarkedAsCompleteNotification = (isSeller: boolean, isOrderCompleted: boolean): NearbuyNotification =>
    new NearbuyNotification(
        NotificationType.ORDER_MARKED_COMPLETE,
        undefined,
        i18n.t('notification:orderMarkedComplete'),
        undefined,
        isSeller,
        isOrderCompleted,
    );

export const OrderMarkedAsActiveNotification = (isSeller: boolean, isOrderCompleted: boolean): NearbuyNotification =>
    new NearbuyNotification(
        NotificationType.ORDER_MARKED_ACTIVE,
        undefined,
        i18n.t(`notification:orderMarkedActive`),
        undefined,
        isSeller,
        isOrderCompleted,
    );

export const DeletedSystemMessageNotification = (systemMessageRef: string): NearbuyNotification =>
    new NearbuyNotification(
        NotificationType.DELETED_SYSTEM_MESSAGE,
        undefined,
        i18n.t('notification:deletedSystemMessage'),
        undefined,
        false,
        false,
        systemMessageRef,
    );

export const HideGlobalSystemMessageSnackbarNotification = (systemMessageRef: string): NearbuyNotification =>
    new NearbuyNotification(
        NotificationType.HIDE_GLOBAL_SYSTEM_MESSAGE_SNACKBAR,
        undefined,
        i18n.t('notification:hideSystemMessageSnackbar'),
        undefined,
        false,
        false,
        systemMessageRef,
    );

export const DefaultErrorNotification = (): NearbuyNotification =>
    new NearbuyNotification(NotificationType.DEFAULT_ERROR, undefined, i18n.t('notification:defaultErrorMessage'));

export const VerifyWithoutAddress = (): NearbuyNotification =>
    new NearbuyNotification(
        NotificationType.VERIFY_WITHOUT_ADDRESS,
        undefined,
        i18n.t('notification:verifyWithoutAddress'),
    );

export class NotificationInfo {
    constructor(
        public notification: NearbuyNotification,
        public timestamp: number,
    ) {}
}
