import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import { DefaultDashletProperties } from 'model/DashletProperties';
import { MapContainer } from 'react-leaflet';
import Dashlet from 'components/dashboard/Dashlet';
import { LatLngBounds, LatLngBoundsExpression, Map } from 'leaflet';
import { timer } from 'rxjs';
import { getBoundsByDistanceFromHomeAddress, LatLonArray } from 'util/geo-helpers';
import { Link as RouterLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { AddressStore, SearchFilterStore } from 'store';
import { NearbuyTileLayer } from 'components/shared/NearbuyTileLayer';
import Link from '@mui/material/Link';
import { SearchArea } from 'model/SearchArea';
import { Skeleton } from '@mui/material';
import DashboardMapSearchResultStore from 'components/dashboard/DashboardMapSearchResultStore';
import { ResultItem } from 'model/ResultItem';
import CompanyMapMarker from 'components/search/result-items/company/CompanyMapMarker';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = () =>
    createStyles({
        mapBox: {
            width: 'auto',
            minHeight: '250px',
            pointerEvents: 'none',
            cursor: 'pointer',
            borderRadius: 10,
        },
    });

interface MapDashletProps extends WithStyles<typeof _styles>, RouteComponentProps, DefaultDashletProperties {}

interface MapDashletState {
    bounds?: LatLonArray[];
    map?: Map;
    searchResults: readonly ResultItem[];
}

class MapDashlet extends ComponentBase<MapDashletProps, MapDashletState> {
    protected _buildState(
        props: MapDashletProps,
        initialBuild: boolean,
        incomingState?: Readonly<MapDashletState>,
    ): Partial<MapDashletState> | undefined {
        const address = AddressStore.getSelected();
        const newState: Partial<MapDashletState> = address
            ? {
                  bounds: getBoundsByDistanceFromHomeAddress(20, address),
              }
            : {};
        if (newState?.bounds) {
            const bounds = newState.bounds;
            newState.searchResults = DashboardMapSearchResultStore.getResults({
                lat1: bounds[0][0],
                lon1: bounds[0][1],
                lat2: bounds[1][0],
                lon2: bounds[1][1],
                // this is needed, in case, there is no other company
                showOwnData: true,
            });
        }

        return newState;
    }

    boundsToKey(bounds: LatLngBoundsExpression | undefined): string {
        if (!bounds) {
            return 'notLoadedMap';
        }
        let key = '';
        if (!(bounds instanceof LatLngBounds)) {
            for (const bound of bounds) {
                key += '[' + bound[0] + ',' + bound[1] + ']';
            }
        }
        return key;
    }

    initializeMap(map: Map): void {
        if (!this.state.bounds) {
            timer(250).subscribe(() => {
                this.initializeMap(map);
            });
            return;
        }
        const bounds = this.state.bounds;
        // Waiting at least one tick is necessary for the map to completely load
        timer(100).subscribe(() => {
            map.invalidateSize();
            const boundsZoom = map.getBoundsZoom(bounds);
            const mapZoom = map.getBoundsZoom(map.getBounds());

            if (boundsZoom !== mapZoom) {
                // weird behaviour, but this happens, if site is reloaded
                map.fitBounds(bounds);

                // reassure, that it is now initialized
                this.initializeMap(map);
            }
        });
    }

    clearSearchFilter() {
        SearchFilterStore.clear();
        SearchFilterStore.setSearchArea(SearchArea.MARKETPLACE);
    }

    render(): React.ReactElement | null {
        return (
            <Dashlet {...this.props.dashletProperties}>
                {!this.state.bounds ||
                this.state.searchResults === undefined ||
                this.state.searchResults.length === 0 ? (
                    <Skeleton variant="rectangular" className={this.props.classes.mapBox} />
                ) : (
                    <Link
                        position={'relative'} // Required for chrome
                        component={RouterLink}
                        to={'/results/marketplace'}
                        onClick={() => {
                            captureWebEvent('mapDashlet-button');
                            this.clearSearchFilter();
                        }}
                    >
                        <MapContainer
                            id="mapDashletMap"
                            className={this.props.classes.mapBox}
                            bounds={this.state.bounds}
                            scrollWheelZoom={false}
                            zoomControl={false}
                            whenCreated={(map) => {
                                this.setState({ map });
                                this.initializeMap(map);
                            }}
                        >
                            <NearbuyTileLayer id="mapDashletTiles" />
                            {this.state.searchResults?.map((searchResult) => {
                                return <CompanyMapMarker key={searchResult.company.id} result={searchResult} />;
                            })}
                        </MapContainer>
                    </Link>
                )}
            </Dashlet>
        );
    }
}

export default withStyles(_styles)(withRouter(MapDashlet));
