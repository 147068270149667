import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import InitializationManager from 'components/initialization/InitializationManager';
import http from 'api/http';
import AuthStore from 'store/AuthStore';
import { NavStore } from 'store';

export class AxiosAuthInterceptorRegistration implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        http.addInterceptor({
            onRejected: (error: any) => {
                if (error.response && error.response.status === 401) {
                    const keycloakAdapter = AuthStore.getKeycloakAdapter();
                    if (keycloakAdapter && !NavStore.isCurrentRoutePublic()) {
                        keycloakAdapter.login();
                    }
                    return null;
                }
                throw error;
            },
        });
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new AxiosAuthInterceptorRegistration(),
);
