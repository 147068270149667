import { createDynamicLoadingStore } from 'resub-entity';
import { Message } from 'model/Message';
import MessageService from 'api/MessageService';
import { getUuidFromString } from 'util/helpers';
import { of } from 'rxjs';

interface MessageStoreSearchType {
    otherPersonRef: string;
    otherCompanyRef: string;
    formerEmployeeRef?: string;
}

export const MessageStore = createDynamicLoadingStore<Message, string, MessageStoreSearchType>({
    selectIdFunction: (message: Readonly<Message>) => message.links.self,
    loadFunction: (messageRef: string) => MessageService.getMessage(messageRef),
    sortFunction: (entity1, entity2) =>
        entity1.dateSent > entity2.dateSent ? -1 : entity2.dateSent > entity1.dateSent ? 1 : 0,
    searchLoadFunction: (search: MessageStoreSearchType) => {
        const otherPerson = getUuidFromString(search.otherPersonRef);
        const otherCompany = getUuidFromString(search.otherCompanyRef);
        const formerEmployee = getUuidFromString(search.formerEmployeeRef);

        if (!otherPerson || !otherCompany) return of([]);

        if (formerEmployee) {
            return MessageService.getMessagesOfFormerEmployee(
                formerEmployee,
                otherPerson,
                otherCompany,
                undefined,
                undefined,
                undefined,
            );
        }

        return MessageService.getMessages(otherPerson, otherCompany, undefined, undefined, undefined);
    },
});
