import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Card, Theme } from '@mui/material';
import { ComponentBase } from 'resub';
import { AddressStore } from 'store';
import Grid from '@mui/material/Grid';
import { Address } from 'model/Address';
import { Company } from 'model/Company';
import DistanceComponent from 'components/shared/DistanceComponent';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const _styles = (theme: Theme) =>
    createStyles({
        button: {
            flex: 1,
            borderRadius: 0,
            margin: 0,
            padding: 10,
            textTransform: 'none',
            color: theme.palette.text.primary,
        },
        companyProps: {
            textAlign: 'left',
        },
        dashletResultItemCard: {
            display: 'flex',
            justifyContent: 'stretch',
            flexGrow: 1,
            flexDirection: 'column',
            backgroundColor: theme.palette.background.default,
        },
        overflowRules: {
            overflow: 'hidden',
            wordWrap: 'break-word',
            hyphens: 'auto',
            textOverflow: 'ellipsis',
            minWidth: '28px',
            maxHeight: '6em',
            maxWidth: '88%',
        },
        cardContent: {
            padding: 0,
            '&:last-child': {
                paddingBottom: 0,
            },
            cursor: 'pointer',
            backgroundColor: theme.palette.background.default,
        },
        gridContainer: {
            display: 'inline-flex',
            flexWrap: 'nowrap',
        },
        distance: {
            textAlign: 'right',
        },
    });

interface FavouriteDashletItemProps extends WithStyles<typeof _styles> {
    company: Company;
}

interface FavouriteDashletItemState {
    ownAddress: Address;
    companyAddress?: Address;
    editDialogOpen?: boolean;
}

class FavouriteDashletItem extends ComponentBase<FavouriteDashletItemProps, FavouriteDashletItemState> {
    protected _buildState(
        props: FavouriteDashletItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<FavouriteDashletItemState>,
    ): Partial<FavouriteDashletItemState> | undefined {
        const address = AddressStore.getSelected();
        return {
            ownAddress: address,
            companyAddress: AddressStore.getOne(props.company.address),
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.ownAddress || !this.state.companyAddress) {
            return null;
        }
        return (
            <Grid item xs={12}>
                <Card className={this.props.classes.dashletResultItemCard}>
                    <CardContent className={this.props.classes.cardContent}>
                        <Grid container className={this.props.classes.gridContainer}>
                            <Button
                                className={this.props.classes.button}
                                component={Link}
                                to={'/company/' + this.props.company.id + '/detail'}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={6} className={this.props.classes.companyProps}>
                                        <Typography
                                            fontSize={15}
                                            fontWeight={'500'}
                                            className={this.props.classes.overflowRules}
                                        >
                                            {this.props.company.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} className={this.props.classes.companyProps}>
                                        <Typography
                                            fontSize={15}
                                            fontWeight={'500'}
                                            className={this.props.classes.overflowRules}
                                        >
                                            {this.state.companyAddress.city}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className={this.props.classes.distance}>
                                        <DistanceComponent
                                            address1={this.state.ownAddress}
                                            address2={this.state.companyAddress}
                                        />
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(FavouriteDashletItem);
