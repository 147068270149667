export enum Feature {
    HAVE_ASSORTMENT_AND_DEMAND = 'HAVE_ASSORTMENT_AND_DEMAND',
    HAVE_OFFERS_AND_REQUESTS = 'HAVE_OFFERS_AND_REQUESTS',
    SEARCH_OFFERS_AND_REQUESTS = 'SEARCH_OFFERS_AND_REQUESTS',
    HAVE_FAVORITES = 'HAVE_FAVORITES',
    USE_MESSAGING = 'USE_MESSAGING',
    RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS',
    HAVE_EMPLOYEES = 'HAVE_EMPLOYEES',
    DISPLAY_COMPANY = 'DISPLAY_COMPANY',
    SEARCH_INTERNAL_COMPANIES = 'SEARCH_INTERNAL_COMPANIES',
    HAVE_CONTACTS = 'HAVE_CONTACTS',
    MANAGE_CONTACT_ROLES = 'MANAGE_CONTACT_ROLES',
    HAVE_CERTS_AND_MEMBERSHIPS = 'HAVE_CERTS_AND_MEMBERSHIPS',
    DEFINE_CONSOLIDATOR = 'DEFINE_CONSOLIDATOR',
    BE_CONSOLIDATOR = 'BE_CONSOLIDATOR',
    BE_SHIPPER = 'BE_SHIPPER',
    BE_MANUFACTURER = 'BE_MANUFACTURER',
    BE_SUPPLIER = 'BE_SUPPLIER',
    BE_CONSUMER = 'BE_CONSUMER',
    CAN_BUY_AND_SELL = 'CAN_BUY_AND_SELL',
    BE_ADMIN = 'BE_ADMIN',
}

export type FeatureOrFeatures = Feature | Feature[];

export interface CompanyFeatures {
    companyOrPersonRef: string;
    features: Set<Feature>;
}
