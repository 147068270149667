import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, IconButton, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import PublicContactComponent from 'components/company/view/contact/PublicContactComponent';
import ContainerChipsComponent from 'components/container/ContainerChipsComponent';
import HintComponent from 'components/hint/HintComponent';
import PreOrderDialog, { PreOrderDialogType } from 'components/marketplace/pre-order/dialog/PreOrderDialog';
import MessageRestrictionDialog from 'components/messaging/MessageRestrictionDialog';
import GraduatedPricesTable from 'components/search/result-items/offers/GraduatedPricesTable';
import { Feature } from 'model/Feature';
import { CompanyRoles, NearbuyRole } from 'model/NearbuyRole';
import { Offer, OfferContainers } from 'model/Offer';
import { MessageTermsStatus, Person } from 'model/Person';
import { Product } from 'model/Product';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import {
    CompanyRolesStore,
    CompanyStore,
    ContactsStore,
    OfferContainersStore,
    OfferStore,
    OrderStore,
    PersonStore,
    PriceRequestStore,
    ProductStore,
    PurchaseIntentStore,
} from 'store';
import { getNumberCurrency, getNumberDecimal } from 'util/helpers';
import { Transition, wrapButtonWithTooltip } from 'util/style-helpers';
import FeatureToggle from 'components/feature/FeatureToggle';
import CompanySummaryComponent from 'components/search/result-items/CompanySummaryComponent';
import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from 'style/NearbuyStyle';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import IconComponent from 'components/shared/IconComponent';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5),
        },
        contentText: {
            fontSize: '15px',
            whiteSpace: 'pre-line',
        },
        heading: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: theme.spacing(2),
        },
        title: {
            fontWeight: 'bold',
            fontSize: '18px',
        },
        headingMain: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        iconClose: {
            fontSize: '1.3em',
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.dark,
            },
        },
        iconButton: {
            padding: 0,
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        shipperIcon: {
            backgroundColor: theme.palette.primary.light,
            padding: theme.spacing(0.4),
            color: theme.palette.primary.dark,
            borderRadius: '50%',
        },
        dialog: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        dot: {
            fontSize: 27,
            marginRight: theme.spacing(2.8),
            fill: theme.palette.secondary.dark,
            verticalAlign: 'middle',
        },
    });

interface OfferFullDetailDialogProps extends WithStyles<typeof _styles>, WithTranslation {
    isOpen: boolean;
    offerRef: string;
    onClose: () => void;
}

interface OfferFullDetailDialogState {
    offer?: Offer;
    offerContainers?: OfferContainers;
    product?: Product;
    roles?: CompanyRoles;
    isPreOrderDialogOpen: boolean;
    ownCompanyRef: string;
    user: Person;
    contactPersonRef: string;
    contactPerson: Person;
    isTradeButtonDisabled: boolean;
    isRestrictionDialogOpen: boolean;
    areLevelsOfProcessingAvailable: boolean;
    areGraduatedPricesAvailable: boolean;
    companyId: string;
}
class OfferFullDetailDialog extends ComponentBase<OfferFullDetailDialogProps, OfferFullDetailDialogState> {
    protected _buildState(
        props: OfferFullDetailDialogProps,
        initialBuild: boolean,
        incomingState: Readonly<OfferFullDetailDialogState> | undefined,
    ): Partial<OfferFullDetailDialogState> {
        let ownCompanyTempRef = CompanyStore.getSelected()?.links.self;
        if (ownCompanyTempRef === undefined) ownCompanyTempRef = '';
        const company = ownCompanyTempRef && CompanyStore.getOne(ownCompanyTempRef);
        const newState: Partial<OfferFullDetailDialogState> = {
            offer: OfferStore.getOne(this.props.offerRef),
            offerContainers: OfferContainersStore.getOne(this.props.offerRef),
            companyId: company && company.id,
            ownCompanyRef: ownCompanyTempRef,
            user: PersonStore.getSelected(),
        };

        if (initialBuild) {
            newState.isPreOrderDialogOpen = false;
            newState.isTradeButtonDisabled = true;
            newState.isRestrictionDialogOpen = false;
        }

        if (newState.offer) {
            if (newState.offer.links.category) {
                newState.product = ProductStore.getOne(newState.offer.links.category);
                newState.roles = CompanyRolesStore.getOne(newState.offer.links.company);
            }

            newState.areGraduatedPricesAvailable = newState.offer.graduatedPrices?.length > 0;
            newState.areLevelsOfProcessingAvailable = newState.offer.levelsOfProcessing?.length > 0;

            if (newState.offer.links.contact) {
                const contact = ContactsStore.getOne(newState.offer.links.contact);
                if (contact) {
                    newState.contactPerson = PersonStore.getOne(contact.links.person);
                    const foreignCompany = CompanyStore.getOne(contact.links.company);
                    if (newState.contactPerson && newState.user) {
                        newState.contactPersonRef = newState.contactPerson.links.self;
                        newState.isTradeButtonDisabled =
                            !foreignCompany?.verified ||
                            newState.contactPerson.messageTermsStatus != MessageTermsStatus.ACCEPTED;
                    }
                }
            }
        }
        return newState;
    }

    shouldPriceRequestButtonBeVisible(): boolean {
        return this.areButtonPrerequisitesGiven() && !!this.state.offer && this.state.offer.pricePerUnit === null;
    }

    shouldPurchaseIntentButtonBeVisible(): boolean {
        return (
            this.areButtonPrerequisitesGiven() &&
            !!this.state.offer &&
            this.state.offer.pricePerUnit !== undefined &&
            this.state.offer.pricePerUnit !== null
        );
    }

    areButtonPrerequisitesGiven(): boolean {
        return (
            !!this.state.offer &&
            !this.state.offer.isDeleted &&
            this.state.offer.active &&
            this.state.offer.links.contact != undefined &&
            this.state.contactPersonRef != undefined &&
            this.state.user != undefined &&
            this.state.offer.links.company !== this.state.ownCompanyRef &&
            this.state.user.links.self !== this.state.contactPersonRef
        );
    }

    clickAway(): void {
        if (!this.state.isPreOrderDialogOpen) {
            this.props.onClose();
        }
    }

    openMessageRestrictionDialog(): void {
        this.setState({
            isRestrictionDialogOpen: true,
        });
    }

    showPreOrderDialog(): React.ReactFragment | null {
        if (this.state.offer) {
            return (
                <>
                    {this.state.offer.links.contact && (
                        <PreOrderDialog
                            type={
                                this.state.offer.pricePerUnit === null
                                    ? PreOrderDialogType.PriceRequest
                                    : PreOrderDialogType.PurchaseIntent
                            }
                            isOpen={this.state.isPreOrderDialogOpen ?? false}
                            onClose={async (): Promise<void> => {
                                await new Promise((r) => setTimeout(r, 200));
                                this.setState({ isPreOrderDialogOpen: false });
                            }}
                            offerRef={this.state.offer.links.self}
                            contactRef={this.state.offer.links.contact}
                        />
                    )}
                </>
            );
        } else return null;
    }

    showCloseIcon(): React.ReactFragment {
        return (
            <IconButton
                onClick={(): void => {
                    captureWebEvent('close-offer-dialog');
                    this.props.onClose();
                }}
                className={this.props.classes.iconButton}
                size="medium"
            >
                <IconComponent icon={'close'} className={this.props.classes.iconClose} />
            </IconButton>
        );
    }

    showProductName(): React.ReactFragment {
        return (
            <Typography className={this.props.classes.headingMain}>
                {this.state.product ? this.props.t(this.state.product.label, { ns: 'ontofood' }) : ''}
            </Typography>
        );
    }

    showShipperIcon(): React.ReactFragment {
        return (
            <Grid item>
                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                    {this.state.roles?.roles.includes(NearbuyRole.SHIPPER) &&
                        wrapButtonWithTooltip(
                            <IconComponent icon={'local_shipping'} className={this.props.classes.shipperIcon} />,
                            this.props.t('tooltips:shipperIcon'),
                        )}
                </Box>
            </Grid>
        );
    }

    showIsPermanentOfferIcon(): React.ReactFragment {
        return (
            <Grid item>
                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                    {this.state.offer?.isPermanent &&
                        wrapButtonWithTooltip(
                            <IconComponent icon={'loop'} className={this.props.classes.shipperIcon} />,
                            this.props.t('tooltips:isPermanentOfferIcon'),
                        )}
                </Box>
            </Grid>
        );
    }

    showMessageRestrictionDialog(): React.ReactElement {
        return (
            <MessageRestrictionDialog
                isOpen={this.state.isRestrictionDialogOpen ?? false}
                onClose={() => this.setState({ isRestrictionDialogOpen: false })}
            />
        );
    }

    showPurchaseIntentButton(): React.ReactFragment | null {
        if (!this.state.user) return null;
        return this.shouldPurchaseIntentButtonBeVisible() ? (
            <FeatureToggle requiredFeatures={[Feature.CAN_BUY_AND_SELL]} type={'allOf'}>
                {wrapButtonWithTooltip(
                    <Button
                        disabled={this.state.isTradeButtonDisabled}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            captureWebEvent('marketplace-make-PurchaseIntent-button');
                            this.state.user!.messageTermsStatus === MessageTermsStatus.ACCEPTED
                                ? this.setState({
                                      isPreOrderDialogOpen: true,
                                  })
                                : this.openMessageRestrictionDialog();
                        }}
                    >
                        {this.props.t('offer:purchaseIntent')}
                    </Button>,
                    this.state.isTradeButtonDisabled
                        ? this.props.t('dialogs:noMessagingInUsePurchaseIntent', {
                              firstname: this.state.contactPerson?.firstname,
                              lastname: this.state.contactPerson?.lastname,
                          })
                        : undefined,
                )}
            </FeatureToggle>
        ) : null;
    }

    showAmountsAndPricePerUnit(): React.ReactFragment | null {
        if (this.state.offer) {
            return (
                <>
                    <Grid item md={6}>
                        <Typography className={this.props.classes.contentText}>
                            {this.props.t('offer:amount')}
                        </Typography>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={5}>
                        <Typography className={this.props.classes.contentText}>
                            {getNumberDecimal(this.state.offer.totalAmount.amount) +
                                ' ' +
                                (this.state.offer.totalAmount.unit &&
                                    this.props.t('shortunits:' + this.state.offer.totalAmount.unit))}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={this.props.classes.contentText}>
                            {this.props.t('offer:minimumPurchase')}
                        </Typography>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={5}>
                        <Typography className={this.props.classes.contentText}>
                            {getNumberDecimal(this.state.offer?.minAmount.amount) +
                                ' ' +
                                (this.state.offer.minAmount.unit &&
                                    this.props.t('shortunits:' + this.state.offer.minAmount.unit))}
                        </Typography>
                    </Grid>
                    {this.state.offer.pricePerUnit !== undefined && this.state.offer.pricePerUnit !== null && (
                        <>
                            <Grid item md={6}>
                                <Typography className={this.props.classes.contentText}>
                                    {this.props.t('offer:pricePerUnit')}
                                </Typography>
                            </Grid>
                            <Grid item md={1} />
                            <Grid item md={5} id={'pricePerUnit'}>
                                <Typography className={this.props.classes.contentText} data-testid={'pricePerUnit'}>
                                    {getNumberCurrency(this.state.offer.pricePerUnit) +
                                        ' ' +
                                        this.props.t('offer:per') +
                                        ' ' +
                                        (this.state.offer.totalAmount.unit &&
                                            this.props.t('shortunits:' + this.state.offer.totalAmount.unit))}
                                </Typography>
                            </Grid>
                        </>
                    )}
                </>
            );
        } else return null;
    }

    showDatePicker(): React.ReactFragment | null {
        if (this.state.offer) {
            return (
                <>
                    <Grid item md={12}>
                        <StyledDatePickerComponent
                            value={this.state.offer.dateFrom}
                            label={this.props.t('offer:dateFrom')}
                            onChange={(): void => {
                                return;
                            }}
                            disabled
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    helperText={undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                sx={{
                                                    '&.Mui-disabled': {
                                                        backgroundColor: 'transparent',
                                                        color: theme.palette.action.disabled,
                                                        padding: 0,
                                                    },
                                                }}
                                                disabled
                                            >
                                                <Calendar />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <StyledDatePickerComponent
                            value={this.state.offer.dateEnd}
                            label={this.props.t('offer:dateUntil')}
                            onChange={(): void => {
                                return;
                            }}
                            disabled
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    helperText={undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                sx={{
                                                    '&.Mui-disabled': {
                                                        backgroundColor: 'transparent',
                                                        color: theme.palette.action.disabled,
                                                        padding: 0,
                                                    },
                                                }}
                                                disabled
                                            >
                                                <Calendar />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </>
            );
        } else return null;
    }

    showDescription(): React.ReactFragment | null {
        return (
            <Grid item md={5}>
                {this.state.offer && this.state.offer.description && this.state.offer.description.length > 0 ? (
                    <Grid>
                        <Typography className={this.props.classes.heading}>
                            {this.props.t('offer:productDescription')}
                        </Typography>
                        <Typography className={this.props.classes.contentText}>
                            {this.state.offer?.description}
                        </Typography>
                    </Grid>
                ) : null}
            </Grid>
        );
    }

    showLevelsOfProcessing(): React.ReactFragment | null {
        return this.state.areLevelsOfProcessingAvailable ? (
            <Grid item md={12}>
                <Typography className={this.props.classes.heading}>{this.props.t('offer:processing')}</Typography>
                {this.state.offer!.levelsOfProcessing.map((lop) => {
                    return (
                        <Chip
                            key={lop.links.self}
                            label={this.props.t('levelsOfProcessing:' + lop.label)}
                            color="primary"
                            className={this.props.classes.chip}
                        />
                    );
                })}
            </Grid>
        ) : null;
    }

    showPriceUponRequestTextAndButton(): React.ReactFragment | null {
        return this.state.offer && this.state.offer.pricePerUnit === null ? (
            <>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid container item>
                    <Grid item md={1} />
                    <Grid item md={6}>
                        <Typography className={this.props.classes.heading}>{this.props.t('offer:poa')}</Typography>
                    </Grid>
                    <Grid>
                        {this.shouldPriceRequestButtonBeVisible() && (
                            <FeatureToggle requiredFeatures={[Feature.CAN_BUY_AND_SELL]} type={'allOf'}>
                                {wrapButtonWithTooltip(
                                    <Button
                                        disabled={this.state.isTradeButtonDisabled}
                                        variant="contained"
                                        onClick={() => {
                                            captureWebEvent('marketplace-make-PriceRequest-button');
                                            this.state.user!.messageTermsStatus === MessageTermsStatus.ACCEPTED
                                                ? this.setState({
                                                      isPreOrderDialogOpen: true,
                                                  })
                                                : this.openMessageRestrictionDialog();
                                        }}
                                    >
                                        {this.props.t('offer:priceRequest')}
                                    </Button>,
                                    this.state.isTradeButtonDisabled
                                        ? this.props.t('dialogs:noMessagingInUsePurchaseIntent', {
                                              firstname: this.state.contactPerson?.firstname,
                                              lastname: this.state.contactPerson?.lastname,
                                          })
                                        : undefined,
                                )}
                            </FeatureToggle>
                        )}
                    </Grid>
                    <Grid item md={1} />
                </Grid>
            </>
        ) : null;
    }

    showGraduatedPricesAndLevelsOfProcessing(): React.ReactFragment | null {
        return (
            <>
                {this.state.areLevelsOfProcessingAvailable || this.state.areGraduatedPricesAvailable ? (
                    <Grid item>
                        {' '}
                        <Divider />{' '}
                    </Grid>
                ) : null}
                <Grid container item>
                    <Grid item xs={1} />
                    <Grid item xs={5}>
                        {this.state.areGraduatedPricesAvailable ? (
                            <GraduatedPricesTable offerRef={this.props.offerRef} />
                        ) : null}
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={5}>
                        {this.showLevelsOfProcessing()}
                    </Grid>
                </Grid>
            </>
        );
    }

    showContainers(): React.ReactFragment | null {
        return this.state.offerContainers && this.state.offerContainers.containerRefs.length > 0 ? (
            <>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid container item>
                    <Grid item md={1} />
                    <Grid item md={10}>
                        <Typography className={this.props.classes.heading}>
                            {this.props.t('offer:container')}
                        </Typography>
                        <ContainerChipsComponent containerRefs={this.state.offerContainers.containerRefs} />
                    </Grid>
                </Grid>
            </>
        ) : null;
    }

    showContacts(): React.ReactFragment | null {
        if (this.state.offer) {
            return (
                <>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid container item>
                        <Grid item md={1} />
                        <Grid item container md={10}>
                            <Typography className={this.props.classes.heading}>
                                {this.props.t('offer:contactHeading')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item md={1} />
                        <Grid container item md={5}>
                            <CompanySummaryComponent companyRef={this.state.offer.links.company} />
                        </Grid>
                        <Grid item md={1} />
                        <Grid container item md={4}>
                            {this.state.offer.links.contact && this.state.product && (
                                <PublicContactComponent
                                    messageTopic={
                                        this.props.t('offer:offer') +
                                        ' ' +
                                        this.props.t(this.state.product.label, { ns: 'ontofood' })
                                    }
                                    isAbbreviated={true}
                                    offerRef={this.state.offer.links.self}
                                    contactRef={this.state.offer.links.contact}
                                />
                            )}
                        </Grid>
                    </Grid>
                </>
            );
        } else return null;
    }

    setLastTradeItem(tradeItemRef: string) {
        if (tradeItemRef.includes('purchase_intents')) {
            PriceRequestStore.setSelected(undefined);
            OrderStore.setSelected(undefined);
            PurchaseIntentStore.setSelected(this.state.offer?.links.latestTradeItem);
        } else if (tradeItemRef.includes('price_requests')) {
            PurchaseIntentStore.setSelected(undefined);
            OrderStore.setSelected(undefined);
            PriceRequestStore.setSelected(this.state.offer?.links.latestTradeItem);
        } else if (tradeItemRef.includes('orders')) {
            PriceRequestStore.setSelected(undefined);
            PurchaseIntentStore.setSelected(undefined);
            OrderStore.setSelected(this.state.offer?.links.latestTradeItem);
        }
    }

    showLatestTradeItemHint(): React.ReactFragment | null {
        return this.state.offer && this.state.offer.links.latestTradeItem ? (
            <>
                <Grid item md={1} />
                <Grid item md={3} onClick={() => this.setLastTradeItem(this.state.offer!.links.latestTradeItem!)}>
                    <HintComponent
                        link={
                            this.state.offer.links.latestTradeItem.includes('purchase_intents') ||
                            this.state.offer.links.latestTradeItem.includes('price_requests')
                                ? `/marketplace/${this.state.companyId}/tradeTable/purchaseRequests`
                                : `/marketplace/${this.state.companyId}/tradeTable/purchases`
                        }
                        isHintSmall={true}
                        isInformationSymbolShown={true}
                        text={
                            this.state.offer.links.latestTradeItem.includes('purchase_intents') ||
                            this.state.offer.links.latestTradeItem.includes('price_requests')
                                ? this.props.t('dialogs:goToTradeTable', {
                                      tradeType: this.props.t('dialogs:request'),
                                  })
                                : this.props.t('dialogs:goToTradeTable', {
                                      tradeType: this.props.t('dialogs:order'),
                                  })
                        }
                    />
                </Grid>
            </>
        ) : null;
    }

    render(): React.ReactElement | null {
        if (!this.state.offer) return null;

        return (
            <Dialog
                open={this.props.isOpen ?? false}
                maxWidth="md"
                fullWidth
                onClose={(event, reason) => {
                    if (event && reason === 'backdropClick') {
                        this.props.onClose();
                    }
                }}
                TransitionComponent={Transition}
            >
                <DialogTitle>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <FiberManualRecordIcon className={this.props.classes.dot} />
                            {this.props.t('offer:offer')}
                        </Grid>
                        <Grid item>{this.showCloseIcon()}</Grid>
                    </Grid>
                </DialogTitle>
                {this.showMessageRestrictionDialog()}
                {this.showPreOrderDialog()}
                <DialogContent className={this.props.classes.dialog}>
                    <Grid
                        container
                        direction={'column'}
                        spacing={2}
                        sx={{
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(2),
                        }}
                    >
                        <Grid container item>
                            <Grid item md={1} />
                            <Grid item md={4}>
                                {this.showProductName()}
                            </Grid>
                            <Grid container item md={1} spacing={theme.spacing(0.5)} alignContent={'flex-start'}>
                                {this.showShipperIcon()}
                                {this.showIsPermanentOfferIcon()}
                            </Grid>

                            <Grid item md={1} />
                            <Grid item>{this.showPurchaseIntentButton()}</Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item md={1} />
                            <Grid container item md={5} alignContent={'flex-start'}>
                                {this.showAmountsAndPricePerUnit()}
                            </Grid>
                            <Grid item md={1} />
                            <Grid container item md={4}>
                                {this.showDatePicker()}
                            </Grid>
                        </Grid>

                        {this.state.offer.description.length > 0 || this.state.offer.links.latestTradeItem ? (
                            <Grid container item>
                                <Grid item md={1} />
                                {this.showDescription()}
                                {this.showLatestTradeItemHint()}
                            </Grid>
                        ) : null}

                        {this.showPriceUponRequestTextAndButton()}

                        {this.showGraduatedPricesAndLevelsOfProcessing()}

                        {this.showContainers()}

                        {this.showContacts()}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation(['shortunits', 'offer', 'levelsOfProcessing', 'tooltips', 'dialogs', 'ontofood'])(
    withStyles(_styles, { withTheme: true })(OfferFullDetailDialog),
);
