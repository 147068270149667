import * as React from 'react';
import { PureComponent } from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Dashlet from 'components/dashboard/Dashlet';
import OntofoodTree, { OntofoodTreeProps } from 'components/ontofood/OntofoodTree';
import { DefaultDashletProperties } from 'model/DashletProperties';
import { Feature } from 'model/Feature';
import { adminTheme } from 'style/NearbuyStyle';
import FeatureToggle from 'components/feature/FeatureToggle';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const _styles = () => createStyles({});

interface OntofoodDashletProps extends WithStyles<typeof _styles>, DefaultDashletProperties {
    type: OntofoodTreeProps['selectionType'];
    title: string;
}

class OntofoodDashlet extends PureComponent<OntofoodDashletProps> {
    render(): React.ReactElement {
        return (
            <FeatureToggle requiredFeatures={[Feature.BE_ADMIN]} type={'allOf'}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={adminTheme}>
                        <Dashlet key={this.props.dashletProperties.id} {...this.props.dashletProperties}>
                            <OntofoodTree selectionType={this.props.type} />
                        </Dashlet>
                    </ThemeProvider>
                </StyledEngineProvider>
            </FeatureToggle>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(OntofoodDashlet);
