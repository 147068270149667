import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';
import ProductService from 'api/ProductService';
import { Product } from 'model/Product';

@AutoSubscribeStore
class ProductChildrenStoreClass extends StoreBase {
    protected childrenMap: Map<string, Product[]> = new Map<string, Product[]>();

    @autoSubscribe
    get(productRef: string): Product[] | undefined {
        const children = this.childrenMap.get(productRef);
        if (children === undefined) this.search(productRef);
        return children;
    }

    private search(productRef: string) {
        ProductService.getAllChildren(productRef).subscribe((children) => {
            this.childrenMap.set(productRef, children);
            this.trigger();
        });
    }

    invalidateCache(productRef?: string) {
        if (productRef !== undefined) this.childrenMap.delete(productRef);
        else this.clear();
        this.trigger();
    }

    clear() {
        this.childrenMap = new Map<string, Product[]>();
        this.trigger();
    }
}

export const ProductChildrenStore = new ProductChildrenStoreClass();
export default ProductChildrenStore;
