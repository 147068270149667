import { createEntityStore } from 'resub-entity';

export enum AdminSetting {
    LOCIZE_API_KEY,
}

interface AdminSettingType {
    key: AdminSetting;
    value: string;
}

export const AdminSettingsStore = createEntityStore<AdminSettingType, AdminSetting>({
    selectIdFunction: (entity) => entity.key,
});
