import http from 'api/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AxiosResponse } from 'axios';
import { CompanyInvitations, Invitation } from 'model/Invitation';
import { CompanyInvitationsStore, InvitationStore } from 'store';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { Employment } from 'model/Employment';
import { Company } from 'model/Company';
import { InvitationWriteView } from 'model/InvitationWriteView';

class InvitationService {
    acceptInvitation(invitationId: string): Observable<Employment | number> {
        return http
            .getAxios()
            .get<Employment>('invitations/accept/' + invitationId)
            .pipe(
                map((response) => response.data),
                catchError((error) => of(error.response.status)),
            );
    }

    declineInvitation(invitationId: string): AxiosObservable<unknown> {
        return http.getAxios().delete<unknown>('invitations/' + invitationId);
    }

    deleteInvitation(invitation: Invitation): Observable<unknown> {
        return http
            .getAxios()
            .delete<unknown>(invitation.links.self)
            .pipe(
                tap(() => CompanyInvitationsStore.loadOne(invitation.links.company)),
                tap(() => {
                    InvitationStore.removeOne(invitation);
                }),
            );
    }

    getInvitation(invitationRef: string): Observable<Invitation> {
        return http
            .getAxios()
            .get<Invitation>(invitationRef)
            .pipe(
                map<AxiosResponse<Invitation>, Invitation>((invitation: AxiosResponse<Invitation>) => invitation.data),
            );
    }

    getInvitations(companyRef: string): Observable<CompanyInvitations> {
        if (companyRef) {
            return http
                .getAxios()
                .get<Invitation[]>(companyRef + '/invitations')
                .pipe(
                    map((response) => response.data),
                    map(
                        (invitations) =>
                            new CompanyInvitations(
                                companyRef,
                                invitations.map((invitation) => invitation.links.self),
                            ),
                    ),
                );
        } else {
            throw new Error('Invitation has no ref..');
        }
    }

    createInvitation(company: Company, invitation: InvitationWriteView): Observable<Invitation | number> {
        return http
            .getAxios()
            .post<Invitation>(company.links.self + '/invitations', invitation, {
                headers: { 'Current-Company': company.id },
            })
            .pipe(
                map((response) => response.data),
                tap(() => CompanyInvitationsStore.loadOne(company.links.self)),
                catchError((error) => of(error.response.status)),
            );
    }
}

export default new InvitationService();
