import { Sema } from 'async-sema/lib';
import { Subject } from 'rxjs';

interface Change<T, K extends keyof T> {
    property: K;
    value: T[K];
}

type ConditionalType<T, hasObject extends boolean> = hasObject extends true ? Readonly<T> : undefined;

export class FormUpdateManager<T extends object, hasObject extends boolean = false> {
    protected changeList = new Map<keyof T, Change<T, keyof T>>();
    protected sema = new Sema(1);
    protected object: ConditionalType<T, hasObject>;
    protected saveResult = new Subject<boolean | undefined>();
    protected additionalData: Map<string, unknown> = new Map<string, unknown>();

    constructor(object: ConditionalType<T, hasObject>) {
        this.object = object;
    }

    /**
     * only for internal uses
     * @param object
     */
    _setObject(object: ConditionalType<T, hasObject>): void {
        this.object = object;
    }

    objectIsSet(object: any): object is T {
        return object !== undefined;
    }

    /**
     * This adds changes to a queue until all of them are handled
     */
    updateEntity<K extends keyof T>(property: K, newValue: /* TODO: correct type */ any): void {
        if (this.objectIsSet(this.object)) {
            // wee need to take into consideration, that textfields have only string, no undefined, but object properties can be undefined
            if (typeof this.object[property] !== 'string' && newValue === '') {
                newValue = undefined;
            }
            if (newValue === this.object[property]) {
                // change is a reset to old value
                this.removeChange(property);
                return;
            }
        }
        this.changeList.set(property, { property, value: newValue });
    }

    /**
     * This can add additional metadata, that is needed in order to save the value
     * @param key
     * @param value
     */
    addAdditionalData(key: string, value: unknown): void {
        this.additionalData.set(key, value);
    }

    removeAdditionalData(key: string): boolean {
        return this.additionalData.delete(key);
    }

    getAdditionalData<valueType>(key: string): valueType | undefined {
        return this.additionalData.get(key) as valueType;
    }

    removeChange<K extends keyof T>(property: K): void {
        this.changeList.delete(property);
    }

    resetChangeList<K extends keyof T>(): void {
        this.changeList = new Map<K, Change<T, keyof T>>();
        this.additionalData = new Map<string, unknown>();
    }

    getChangedValue<K extends keyof T>(property: K): T[K] {
        return this.changeList.get(property)?.value as T[K];
    }

    getChanges<K extends keyof T>(): Map<K, Change<T, K>> {
        return this.changeList as Map<K, Change<T, K>>;
    }

    hasChanges(property?: keyof T): boolean {
        if (property !== undefined) {
            return this.changeList.has(property);
        }
        return this.changeList.size > 0;
    }

    async handleSave(saveFunction: (result: boolean | undefined) => Promise<boolean>): Promise<boolean> {
        this.saveResult.toPromise().then(saveFunction);
        if (this.sema.nrWaiting() > 1) {
            // if there are some already waiting, don't handle it here
            await this.sema.acquire();
            this.sema.release();
            return false;
        }
        await this.sema.acquire();

        const result = await saveFunction(undefined);

        this.saveResult = new Subject<boolean | undefined>();
        this.sema.release();
        return result;
    }

    /**
     * This returns an JSON Object (!!not the same prototype!!)
     */
    getUpdatedObject(): T {
        const newObject = Object.assign({}, this.object) as T;
        this.changeList.forEach((change) => {
            Object.assign(newObject, { [change.property]: change.value });
        });
        return newObject;
    }
}

class FormUpdateManagerHandler {
    protected updateManager: Map<any, FormUpdateManager<any, true | false>> = new Map<any, FormUpdateManager<any>>();

    /**
     * @deprecated update manager should be userd per instance, thus the version with the instance as Parameter should be used
     * @param object
     */
    getUpdateManager<T extends object>(object: any): FormUpdateManager<T> {
        if (!this.updateManager.has(object)) {
            this.updateManager.set(object, new FormUpdateManager<T>(undefined));
        }
        return this.updateManager.get(object)!;
    }

    getChangeByKey<T, X extends keyof T>(objectOrKey: any, property: X): T[X] | undefined {
        if (this.updateManager.has(objectOrKey)) {
            const updateManager: FormUpdateManager<any, boolean> = this.updateManager.get(objectOrKey)!;
            if (updateManager.hasChanges(property)) {
                return updateManager.getChangedValue(property);
            }
        }
        return undefined;
    }

    getInstanceUpdateManager<T extends object>(defaultObject: T, keyOtherThanObject?: any): FormUpdateManager<T, true> {
        const key = keyOtherThanObject ? keyOtherThanObject : defaultObject;
        if (!this.updateManager.has(key)) {
            this.updateManager.set(key, new FormUpdateManager<T, true>(defaultObject));
        }
        return this.updateManager.get(key)!;
    }

    hasChanges<T>(key: any, property?: keyof T): boolean {
        if (this.updateManager.has(key)) {
            const updateManager = this.updateManager.get(key)!;
            return updateManager.hasChanges(property);
        }
        return false;
    }

    /**
     * return the value of the property of the object, if it is not updated, else it returns the updated value.
     * @param object
     * @param property
     * @param keyOtherThanObject
     */
    getValueOfObject<T extends object, K extends keyof T>(object: T, property: K, keyOtherThanObject?: any): T[K] {
        const key = keyOtherThanObject ? keyOtherThanObject : object;
        if (this.hasChanges(key, property as keyof T)) {
            return this.getInstanceUpdateManager<T>(object, key).getChangedValue(property);
        }
        return object[property];
    }

    getUpdatedObject<T extends object, hasObject extends true>(key: any): T | undefined {
        if (this.updateManager.has(key)) {
            return (this.updateManager.get(key) as FormUpdateManager<T, hasObject>).getUpdatedObject();
        }
        return undefined;
    }

    clearUpdates(keyOrObject?: any): void {
        if (keyOrObject) {
            if (this.updateManager.has(keyOrObject)) {
                const updateManager = this.updateManager.get(keyOrObject)!;
                this.updateManager.delete(keyOrObject);
                updateManager.resetChangeList();
            }
        } else {
            // clear everything
            for (const um of this.updateManager.values()) {
                um.resetChangeList();
            }
            this.updateManager.clear();
        }
    }

    /**
     * This should only be needed, if there is a updateManager with an old entity.
     * This could possibly happen, if an updateManager was created before the entity was fully loaded.
     * @param object
     * @param keyOtherThanObject
     */
    updateObjectOfUpdateManager<T extends object>(object: T, keyOtherThanObject: any): void {
        const key = keyOtherThanObject ? keyOtherThanObject : object;
        const updateManager = this.getInstanceUpdateManager(object, key);
        updateManager.getChanges().forEach((change) => {
            if (change.value === object[change.property]) {
                updateManager.removeChange(change.property);
            }
        });
        updateManager._setObject(object);
    }
}

export default new FormUpdateManagerHandler();
