import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Hidden, Menu, Skeleton, ThemeProvider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import CompanyService from 'api/CompanyService';
import http from 'api/http';
import CompanyMarketPresentation from 'components/company/view/CompanyMarketPresentation';
import { Company } from 'model/Company';
import { Employment, EmploymentStatus } from 'model/Employment';
import { Feature } from 'model/Feature';
import { ImageInfo } from 'model/Image';
import { CompanyRoles, NearbuyRole } from 'model/NearbuyRole';
import { Person } from 'model/Person';
import * as React from 'react';
import { ReactElement } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { CompanyImagesStore, CompanyRolesStore, CompanyStore, ImageStore, PersonEmploymentStore } from 'store';
import CompanyFeatureStore from 'store/FeatureStore';
import * as nearbuyTheme from 'style/NearbuyStyle';
import { adminTheme, ExtendedTheme, theme } from 'style/NearbuyStyle';
import { classNames, wrapButtonWithTooltip } from 'util/style-helpers';
import FeatureToggle from 'components/feature/FeatureToggle';
import BackButtonComponent from 'components/shared/BackButtonComponent';
import StarComponent from 'components/shared/Popovers/StarComponent';
import AboutCompanyComponent from 'components/company/view/AboutCompanyComponent';
import CompanyContactsComponent from 'components/company/view/CompanyContactsComponent';
import CompanyPresentationDetailsComponent from 'components/company/view/CompanyPresentationDetailsComponent';
import CompanyPresentationImagesComponent from 'components/company/view/CompanyPresentationImagesComponent';
import { AddressLinkView, AddressType } from 'model/Address';

const styles = (theme: ExtendedTheme) =>
    createStyles({
        '@global': {
            body: {
                backgroundColor: theme.palette.common.white,
            },
        },
        absolutePosition: {
            position: 'absolute',
            zIndex: 2,
            top: '12px',
            left: '12px',
        },
        containerPadding: {
            padding: theme.spacing(2),
        },
        adminStyle: {
            backgroundColor: theme.palette.secondary.lighter,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        avatarContainer: {
            objectFit: 'contain',
            height: '168px',
            width: '168px',
            borderRadius: 32,
            backgroundColor: 'transparent',
            position: 'absolute',
            top: '140px',
            left: '4%',
            zIndex: 1,
        },
        coverPhotoContainer: {
            objectFit: 'cover',
            height: '212px',
            minWidth: '800px',
            width: '100%',
            maxWidth: '1700px',
            borderRadius: 24,
            backgroundColor: theme.palette.background.paper,
        },
        generalButtonStyle: {
            color: theme.palette.primary.dark,
            borderRadius: 10,
            fontWeight: 600,
            fontSize: 18,
            '&:hover': {
                color: theme.palette.primary.dark,
            },
        },
        navigationButton: {
            paddingLeft: '14px',
            paddingRight: '14px',
            width: '100%',
            '&:hover': {
                borderRadius: 10,
                backgroundColor: theme.palette.primary.lighter,
            },
        },
        menuNavigationButton: {
            width: '100%',
            padding: 0,
            justifyContent: 'flex-start',
            textAlign: 'left',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        activeNavigationButton: {
            color: theme.palette.common.white,
            fontWeight: 600,
            fontSize: 18,
            borderRadius: 10,
            paddingLeft: '14px',
            width: '100%',
            paddingRight: '14px',
            backgroundColor: theme.palette.primary.dark,
            '&:hover': {
                color: theme.palette.primary.dark,
                borderRadius: 10,
                backgroundColor: theme.palette.primary.light,
            },
        },
        navigationButtonsContainer: {
            marginLeft: '22.5rem',
            marginTop: theme.spacing(3),
        },
        '@media (max-width: 1560px)': {
            navigationButtonsContainer: {
                marginLeft: '19rem',
            },
        },
        '@media (max-width: 1400px)': {
            navigationButtonsContainer: {
                marginLeft: '17rem',
            },
            avatarContainer: {
                height: '156px',
                width: '156px',
            },
        },
        '@media (max-width: 1250px)': {
            navigationButtonsContainer: {
                marginLeft: '14.5rem',
            },
            coverPhotoContainer: {
                height: '196px',
            },
        },
        '@media (max-width: 990px)': {
            navigationButtonsContainer: {
                marginLeft: '14rem',
            },
        },
        '@media (max-width: 940px)': {
            navigationButtonsContainer: {
                marginLeft: '12rem',
            },
            avatarContainer: {
                height: '136px',
                width: '136px',
                top: '120px',
                left: '3%',
            },
            coverPhotoContainer: {
                height: '164px',
            },
        },
    });

interface SearchLinkState {
    from?: string;
}
interface CompanyPresentationProps
    extends WithStyles<typeof styles>,
        RouteComponentProps<Record<any, any>, StaticContext, SearchLinkState>,
        WithTranslation {}

interface CompanyPresentationState {
    company?: Company;
    ownCompanyRef?: string;
    companyRef: string;
    currentURL: string;
    image?: ImageInfo;
    logo?: ImageInfo;
    user: Person | null;
    companyRoles?: CompanyRoles;
    ownEmployments?: Employment[];
    isAdmin: boolean;
    selectedTab: 'company' | 'cooperations' | 'assortment' | 'demand' | 'processing' | 'contactPersons' | string;
    anchorEl: HTMLElement | null;
    companyImages: ImageInfo[];
}

class CompanyPresentationComponent extends ComponentBase<CompanyPresentationProps, CompanyPresentationState> {
    protected _buildState(props: CompanyPresentationProps): Partial<CompanyPresentationState> | undefined {
        const newState: Partial<CompanyPresentationState> = {
            companyRef: `${http.getBaseUrl()}/companies/${props.match.params.companyId}`,
            ownCompanyRef: CompanyStore.getSelected()?.links.self,
        };
        if (!newState.companyRef) return;
        newState.company = CompanyStore.getOne(newState.companyRef);
        const companyImages = newState.company ? CompanyImagesStore.getOne(newState.company.id) : undefined;
        newState.image = companyImages?.images.filter((image: ImageInfo) => image.ord === 0)[0];
        newState.logo = newState.company?.links.logo ? ImageStore.getOne(newState.company.links.logo) : undefined;
        newState.ownEmployments = PersonEmploymentStore.getSelected()?.employments;
        newState.isAdmin = CompanyFeatureStore.hasPermission(new Set([Feature.BE_ADMIN]), 'allOf') ?? false;
        newState.companyRoles = newState.company && CompanyRolesStore.getOne(newState.company.links.self);
        newState.companyImages = companyImages?.images;
        return newState;
    }

    componentDidMount() {
        super.componentDidMount();
        this.setState({ selectedTab: 'company' });
    }

    isEmployee(company: Readonly<Company> | undefined, ownEmployments: Employment[] | undefined): boolean {
        if (!company || !ownEmployments) {
            return false;
        }
        const employments = ownEmployments.filter((employment) => employment.links.company === company.links.self);
        if (employments.length < 1) {
            return false;
        }
        const employment = employments[0];
        return [EmploymentStatus.EMPLOYEE, EmploymentStatus.MANAGER].includes(employment.status);
    }

    hasMainAddress(addresses: Set<AddressLinkView>) {
        return Array.from(addresses)
            .map((address: AddressLinkView) => address.type)
            .includes(AddressType.MAIN);
    }

    addTabButton(tabName: string, inMenu?: boolean) {
        return (
            <Button
                variant={'text'}
                className={classNames(
                    this.state.selectedTab == tabName
                        ? inMenu
                            ? this.props.classes.menuNavigationButton
                            : this.props.classes.activeNavigationButton
                        : inMenu
                          ? this.props.classes.menuNavigationButton
                          : this.props.classes.navigationButton,
                    this.props.classes.generalButtonStyle,
                )}
                onClick={() => {
                    this.handleTabButtonClick(tabName);
                    this.setState({ anchorEl: null });
                }}
            >
                {this.props.t(`company:${tabName}`)}
            </Button>
        );
    }

    handleTabButtonClick = (tabName: string) => {
        this.setState({ selectedTab: tabName });
    };

    showCompanyCoverPhoto() {
        return (
            <img
                src={this.state.image ? this.state.image.source : '/images/empty_banner.png'}
                className={this.props.classes.coverPhotoContainer}
                alt={'cover_photo'}
            />
        );
    }
    handleVerifiedChange(verified: boolean): void {
        if (this.state.company) {
            CompanyService.setVerified(this.state.company.links.self, verified).subscribe();
        }
    }

    showBackToButton() {
        return (
            this.props.location.state &&
            this.props.location.state.from && <BackButtonComponent className={this.props.classes.absolutePosition} />
        );
    }

    showCompanyAvatar() {
        return (
            <Grid item alignItems={'center'} justifyContent={'center'}>
                <img
                    src={
                        this.state.logo
                            ? this.state.logo.source
                            : this.state.companyRoles?.roles.includes(NearbuyRole.WHOLESALER)
                              ? '/images/wholesaler_placeholder.svg'
                              : '/images/empty_company_logo.png'
                    }
                    className={this.props.classes.avatarContainer}
                    alt={'logo'}
                />
            </Grid>
        );
    }

    showImageSkeleton() {
        const skeletons = [];
        for (let i = 0; i < this.state.companyImages.length; i++) {
            skeletons.push(
                <Skeleton
                    variant="rounded"
                    key={i}
                    sx={{ width: '22%', height: '200px', borderRadius: 6, margin: 1 }}
                />,
            );
        }
        return skeletons;
    }

    showTabAndActionButtons() {
        return (
            <Grid container className={this.props.classes.navigationButtonsContainer}>
                <Grid container item spacing={1} justifyContent={'flex-start'} xs={11}>
                    <Grid item>{this.addTabButton('company')}</Grid>
                    <Grid item>{this.addTabButton('contactPersons')}</Grid>
                    <Grid item>{this.addTabButton('cooperations')}</Grid>
                    <Hidden lgDown>
                        {this.state.companyRoles?.roles.includes(NearbuyRole.SUPPLIER) && (
                            <Grid item>{this.addTabButton('assortment')}</Grid>
                        )}
                        {this.state.companyRoles?.roles.includes(NearbuyRole.CONSUMER) && (
                            <Grid item>{this.addTabButton('demand')}</Grid>
                        )}
                        <Grid item>{this.addTabButton('processing')}</Grid>
                    </Hidden>
                    <Hidden lgUp>
                        <Grid item>
                            <Button
                                variant={'text'}
                                onClick={(event) => {
                                    this.setState({
                                        anchorEl: event.currentTarget,
                                    });
                                }}
                                className={classNames(
                                    this.props.classes.generalButtonStyle,
                                    this.state.selectedTab == 'cooperations' ||
                                        this.state.selectedTab == 'assortment' ||
                                        this.state.selectedTab == 'demand' ||
                                        this.state.selectedTab == 'processing'
                                        ? this.props.classes.activeNavigationButton
                                        : this.props.classes.navigationButton,
                                )}
                            >
                                {this.props.t('common:more')}
                                {this.state.anchorEl ? <ExpandLess /> : <ExpandMore />}
                            </Button>
                            <Menu
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={() => {
                                    this.setState({ anchorEl: null });
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {this.state.companyRoles?.roles.includes(NearbuyRole.SUPPLIER) && (
                                    <MenuItem style={{ textAlign: 'start' }}>
                                        {this.addTabButton('assortment', true)}
                                    </MenuItem>
                                )}
                                {this.state.companyRoles?.roles.includes(NearbuyRole.CONSUMER) && (
                                    <MenuItem> {this.addTabButton('demand', true)}</MenuItem>
                                )}
                                <MenuItem>{this.addTabButton('processing', true)}</MenuItem>
                            </Menu>
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid container item xs={1} justifyContent={'flex-end'}>
                    <Grid item>
                        <Link
                            to={`/edit/company/${this.state.company?.id}/detail`}
                            hidden={this.state.companyRef != this.state.ownCompanyRef && !this.state.isAdmin}
                        >
                            <IconButton
                                className={
                                    this.state.companyRef != this.state.ownCompanyRef && this.state.isAdmin
                                        ? this.props.classes.adminStyle
                                        : undefined
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Grid item>
                        {this.state.companyRef != this.state.ownCompanyRef && (
                            <StarComponent
                                calledFrom={'CompanyPresentationComponent'}
                                companyRef={this.state.companyRef}
                                personRef={this.state.user?.links.self}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    showCompanyDetailsSidebar(companyRef: string): ReactElement {
        return <CompanyPresentationDetailsComponent companyRef={companyRef} />;
    }

    render(): React.ReactElement | null {
        if (!this.state.company) {
            return null;
        }
        const { selectedTab } = this.state;
        return (
            <Grid container justifyContent={'center'} sx={{ height: '84vh', width: '100%' }}>
                <Grid container item direction={'column'} maxWidth={'1700px'}>
                    <Grid item container sx={{ position: 'relative', display: 'flex' }} justifyContent={'center'}>
                        {this.showCompanyCoverPhoto()}
                        {this.showBackToButton()}
                        {this.showCompanyAvatar()}
                        {this.showTabAndActionButtons()}
                        <FeatureToggle requiredFeatures={[Feature.BE_ADMIN]} type={'oneOf'}>
                            <Grid
                                item
                                container
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{
                                    borderRadius: 4,
                                    marginLeft: '22%',
                                    marginTop: '12px',
                                    backgroundColor: theme.palette.secondary.lighter,
                                    padding: '4px',
                                }}
                            >
                                <ThemeProvider<typeof adminTheme> theme={nearbuyTheme.adminTheme}>
                                    <Typography sx={{ marginLeft: '2%', color: theme.palette.common.black }}>
                                        {this.state.company.verified
                                            ? this.props.t('admin:verified')
                                            : this.props.t('admin:notVerified')}
                                    </Typography>
                                    {wrapButtonWithTooltip(
                                        <Checkbox
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: theme.palette.secondary.dark, // Green fill when checked
                                                },
                                                '&:not(.Mui-checked)': {
                                                    borderColor: theme.palette.secondary.dark, // Brown border when not checked
                                                },
                                            }}
                                            checked={this.state.company.verified}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                                this.handleVerifiedChange(event.target.checked)
                                            }
                                            disabled={!this.hasMainAddress(this.state.company.addresses)}
                                        />,
                                        !this.hasMainAddress(this.state.company.addresses)
                                            ? this.props.t('tooltips:IfNoAddressNoVerification')
                                            : undefined,
                                        undefined,
                                        'bottom',
                                    )}
                                </ThemeProvider>
                            </Grid>
                        </FeatureToggle>
                    </Grid>
                    <Grid
                        item
                        container
                        direction={'row'}
                        sx={{ marginTop: theme.spacing(1) }}
                        className={this.props.classes.containerPadding}
                        justifyContent={'center'}
                    >
                        <Grid item container sm={2.5} className={this.props.classes.containerPadding}>
                            {this.showCompanyDetailsSidebar(this.state.companyRef)}
                        </Grid>
                        <Grid
                            item
                            container
                            direction={'column'}
                            sm={9.5}
                            className={this.props.classes.containerPadding}
                            sx={{ paddingLeft: theme.spacing(4) }}
                        >
                            {selectedTab == 'company' && (
                                <Grid container direction={'column'} justifyContent={'center'}>
                                    <Grid item>
                                        <AboutCompanyComponent
                                            companyRef={this.state.companyRef}
                                            companyImages={this.state.companyImages}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {this.state.companyImages ? (
                                            <CompanyPresentationImagesComponent images={this.state.companyImages} />
                                        ) : (
                                            <Grid container direction={'row'} spacing={1}>
                                                {' '}
                                                {this.showImageSkeleton()}{' '}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {selectedTab == 'contactPersons' && (
                                <CompanyContactsComponent companyRef={this.state.companyRef} />
                            )}
                            {selectedTab == 'cooperations' && (
                                // <CompanyPartnersComponent>

                                //For demo purposes, delete once actual component is coded:
                                <Grid container direction={'column'} justifyContent={'center'}>
                                    <Typography variant={'h4'} data-testid={'titleCooperations'}>
                                        {' '}
                                        {this.props.t('company:cooperations')}{' '}
                                    </Typography>

                                    <Typography
                                        variant={'h1'}
                                        marginTop={theme.spacing(6)}
                                        sx={{ color: theme.palette.primary.dark }}
                                    >
                                        {' '}
                                        {'Coming Soon!'}{' '}
                                    </Typography>
                                    <img
                                        src={'/images/young-plant-vectorportal.png'}
                                        width={'280px'}
                                        style={{ marginTop: '120px', marginLeft: '120px' }}
                                        alt={'young-plant'}
                                    />
                                </Grid>
                            )}
                            {selectedTab == 'assortment' && (
                                <CompanyMarketPresentation companyRef={this.state.companyRef} type={'Assortment'} />
                            )}
                            {selectedTab == 'demand' && (
                                <CompanyMarketPresentation companyRef={this.state.companyRef} type={'Demand'} />
                            )}
                            {selectedTab == 'processing' && (
                                // <CompanyProcessingComponent>

                                //For demo purposes, delete once actual component is coded:
                                <Grid container direction={'column'} justifyContent={'center'}>
                                    <Typography variant={'h4'} data-testid={'titleProcessing'}>
                                        {' '}
                                        {this.props.t('company:processing')}{' '}
                                    </Typography>

                                    <Typography
                                        variant={'h1'}
                                        marginTop={theme.spacing(6)}
                                        sx={{ color: theme.palette.primary.dark }}
                                    >
                                        {' '}
                                        {'Coming Soon!'}{' '}
                                    </Typography>
                                    <img
                                        src={'/images/young-plant-vectorportal.png'}
                                        width={'280px'}
                                        style={{ marginTop: '120px', marginLeft: '120px' }}
                                        alt={'young-plant'}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation(['company', 'common', 'admin', 'tooltips'])(
    withStyles(styles, { withTheme: true })(withRouter(CompanyPresentationComponent)),
);
