import * as React from 'react';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GlobalSystemMessage } from 'model/SystemMessage';
import { getValidUrl } from 'util/helpers';

interface GlobalSystemMessageItemProps extends WithTranslation {
    systemMessage: GlobalSystemMessage;
}

class GlobalSystemMessageItem extends React.Component<GlobalSystemMessageItemProps> {
    render(): React.ReactElement | null {
        return (
            <SystemMessageItem
                systemMessage={this.props.systemMessage}
                mapping={{
                    text: this.props.t(this.props.systemMessage.text),
                }}
                onClick={() => window.open(getValidUrl(this.props.systemMessage.links.link))}
            />
        );
    }
}

export default withTranslation('globalSystemMessage')(GlobalSystemMessageItem);
