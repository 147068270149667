import React, { PropsWithChildren } from 'react';
import { SnackbarProvider } from 'notistack';
import withStyles from '@mui/styles/withStyles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { theme } from 'style/NearbuyStyle';

const _styles = () =>
    createStyles({
        snackBar: {
            backgroundColor: 'rgba(36, 36, 36, 0.65) !important',
            colors: theme.palette.primary.light,
        },
        successToast: {
            backgroundColor: `${theme.palette.primary.light} !important`,
            color: 'black !important',
        },
        errorToast: {
            backgroundColor: `${theme.palette.error.main} !important`,
            color: 'white !important',
        },
    });

class NearbuySnackbarProvider extends React.Component<
    PropsWithChildren<unknown> & WithStyles<typeof _styles>,
    unknown
> {
    render() {
        return (
            <SnackbarProvider
                // classname provides the style for the default variant
                className={this.props.classes.snackBar}
                classes={{
                    variantSuccess: this.props.classes.successToast,
                    variantError: this.props.classes.errorToast,
                }}
            >
                {this.props.children}
            </SnackbarProvider>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(NearbuySnackbarProvider);
