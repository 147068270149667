import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as React from 'react';
import { Card, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Offer } from 'model/Offer';
import Button from '@mui/material/Button';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';
import { withTranslation, WithTranslation } from 'react-i18next';
import { OfferStore, ProductStore } from 'store';
import { Product } from 'model/Product';
import Typography from '@mui/material/Typography';
import { theme } from 'style/NearbuyStyle';
import { wrapButtonWithTooltip } from 'util/style-helpers';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        button: {
            flex: 1,
            margin: 0,
            padding: '2 0 2 0',
            textTransform: 'none',
            color: theme.palette.text.primary,
        },
        dot: {
            maxWidth: '22px',
            maxHeight: '22px',
            color: theme.palette.secondary.dark,
        },
        category: {
            textAlign: 'left',
            margin: 0,
        },
        listItemText: {
            fontWeight: '500',
            maxHeight: '3em',
            fontSize: 15,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        dashletResultItemCard: {
            display: 'flex',
            justifyContent: 'stretch',
            flexGrow: 1,
            flexDirection: 'column',
            backgroundColor: theme.palette.background.default,
        },
        cardContent: {
            padding: theme.spacing(0.5),
            '&:last-child': {
                paddingBottom: 0,
            },
            cursor: 'pointer',
            backgroundColor: theme.palette.background.default,
        },
        gridContainer: {
            display: 'inline-flex',
            flexWrap: 'nowrap',
        },
    });

interface OfferReferenceComponentProps extends WithStyles<typeof _styles>, WithTranslation {
    offerRef: string;
    onClick?: () => void;
    isProvideOfferListItem?: boolean;
    isChosen?: boolean;
}

interface OfferReferenceComponentState {
    offer: Offer;
    category: Product;
}

class OfferReferenceComponent extends ComponentBase<OfferReferenceComponentProps, OfferReferenceComponentState> {
    protected _buildState(
        props: OfferReferenceComponentProps,
        initialBuild: boolean,
        incomingState?: Readonly<OfferReferenceComponentState>,
    ): Partial<OfferReferenceComponentState> | undefined {
        const offer = OfferStore.getOne(props.offerRef);
        return {
            offer,
            category: offer ? ProductStore.getOne(offer.links.category) : undefined,
        };
    }

    render(): React.ReactElement {
        return (
            <Grid item container xs={12}>
                <Card
                    className={this.props.classes.dashletResultItemCard}
                    sx={{
                        border: this.props.isChosen ? `2px solid ${theme.palette.primary.dark}` : 'transparent',
                    }}
                >
                    <CardContent className={this.props.classes.cardContent}>
                        <Grid container className={this.props.classes.gridContainer}>
                            <Button
                                disableRipple
                                onClick={() => {
                                    captureWebEvent('marketplace-send-offer-edit-existing-offer');
                                    this.props.onClick?.();
                                }}
                                className={this.props.classes.button}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={this.props.isProvideOfferListItem ? undefined : 1}
                                        textAlign={this.props.isProvideOfferListItem ? 'left' : undefined}
                                    >
                                        <FiberManualRecordIcon className={this.props.classes.dot} />
                                    </Grid>
                                    <Grid item xs={3} className={this.props.classes.category}>
                                        <Typography
                                            className={this.props.classes.listItemText}
                                            sx={{ paddingLeft: theme.spacing(1) }}
                                        >
                                            {this.props.t(this.state.category.label, {
                                                ns: 'ontofood',
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} textAlign={'left'}>
                                        {!this.state.offer.active && this.props.isProvideOfferListItem
                                            ? wrapButtonWithTooltip(
                                                  <InfoOutlinedIcon sx={{ color: theme.palette.secondary.main }} />,
                                                  this.props.t('offer:deactivatedOfferHint'),
                                              )
                                            : null}
                                    </Grid>
                                    <Grid item xs={3} textAlign={'end'} sx={{ paddingLeft: 1 }}>
                                        <Typography className={this.props.classes.listItemText}>
                                            {this.state.offer.totalAmount.amount.toLocaleString('de-DE')}{' '}
                                            {this.props.t(this.state.offer.totalAmount.unit, {
                                                ns: 'shortunits',
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} textAlign={'end'} sx={{ paddingLeft: 1 }}>
                                        <Box>
                                            <Typography className={this.props.classes.listItemText}>
                                                {this.state.offer.pricePerUnit
                                                    ? this.state.offer.pricePerUnit.toLocaleString('de-DE', {
                                                          style: 'currency',
                                                          currency: 'EUR',
                                                      }) +
                                                      '/' +
                                                      this.props.t(this.state.offer.totalAmount.unit, {
                                                          ns: 'shortunits',
                                                      })
                                                    : this.props.t('poa', {
                                                          ns: 'offer',
                                                      })}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default withTranslation(['ontofood', 'shortunits', 'offer'])(
    withStyles(_styles, { withTheme: true })(OfferReferenceComponent),
);
