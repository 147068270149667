import React from 'react';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ComponentBase } from 'resub';
import { Person } from 'model/Person';
import i18next from 'i18next';
import { captureWebEvent } from 'util/AnalyticUtils';

type LanguageSwitcherProps = Record<string, never>;

interface LanguageSwitcherState {
    user: Person;
    language: string;
}

export default class LanguageSwitcher extends ComponentBase<LanguageSwitcherProps, LanguageSwitcherState> {
    protected _buildState(
        props: LanguageSwitcherProps,
        initialBuild: boolean,
        incomingState: Readonly<LanguageSwitcherState> | undefined,
    ): Partial<LanguageSwitcherState> | undefined {
        return {
            language: i18next.language,
        };
    }

    captureLanguage = (language: string) => {
        captureWebEvent(`select-language-${language}`);
    };

    render(): React.ReactNode {
        return (
            <Select
                inputProps={{ name: 'language-switcher' }}
                value={this.state.language || 'de'}
                variant="standard"
                onChange={(evt) => {
                    i18next.changeLanguage(evt.target.value);
                }}
            >
                <MenuItem onClick={() => this.captureLanguage('de')} value={'de'}>
                    DE
                </MenuItem>
                <MenuItem onClick={() => this.captureLanguage('en')} value={'en'}>
                    EN
                </MenuItem>
                <MenuItem onClick={() => this.captureLanguage('fr')} value={'fr'}>
                    FR
                </MenuItem>
            </Select>
        );
    }
}
