import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import { SearchFilterStore, UserSettingsStore } from 'store';
import SettingsLoader from 'components/initialization/initializers/SettingsLoader';
import { SettingsKey } from 'model/UserSettings';
import { lastValueFrom } from 'rxjs';
import UserSettingsService from 'api/UserSettingsService';
import UserStore from 'store/UserStore';

export class SearchInitializer implements InitializationEventConsumer {
    async onInitializationEvent(_phase: InitializationPhase): Promise<void> {
        try {
            await lastValueFrom(UserSettingsStore.loadOne(SettingsKey.SEARCH_FILTERS));
        } catch (e) {
            // currently backend throws 500, if key is not already present..
            console.warn('Search Filters Settings are not yet saved, creating new ones');
        }

        if (!UserSettingsStore.hasOne(SettingsKey.SEARCH_FILTERS) && UserStore.isUserExisting()) {
            // we create a stub in order to avoid errors in backend
            await lastValueFrom(UserSettingsService.saveSetting(SettingsKey.SEARCH_FILTERS, {}));
        }

        SearchFilterStore.setDefaultFilters();
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new SearchInitializer(),
    [SettingsLoader],
);
