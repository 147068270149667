import React from 'react';
import { ComponentBase } from 'resub';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Person } from 'model/Person';
import { PurchaseIntent } from 'model/PurchaseIntent';
import { Offer } from 'model/Offer';
import { Product } from 'model/Product';
import { OfferStore, PersonStore, ProductStore, PurchaseIntentStore } from 'store';
import MessageItemRenderer from 'components/messaging/chat/messagefactory/MessageItemRenderer';
import { addPurchaseIntentMessageItemData } from 'components/messaging/chat/messagefactory/messageitemdata/PurchaseIntentMessageItemData';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import { PreOrderTranslationMapping } from 'components/marketplace/PreOrderTranslationMapping';

interface PurchaseIntentMessageItemFactoryProps extends WithTranslation {
    baseData: MessageItemData;
}

interface PurchaseIntentMessageItemFactoryState {
    purchaseIntent?: PurchaseIntent;
    preOrderTranslationMapping?: PreOrderTranslationMapping;
    offer?: Offer;
    sellingPerson?: Person;
    buyingPerson?: Person;
    product?: Product;
    isDialogOpen: boolean;
    isTradeInfoDialogOpen: boolean;
}

class PurchaseIntentMessageItemFactory extends ComponentBase<
    PurchaseIntentMessageItemFactoryProps,
    PurchaseIntentMessageItemFactoryState
> {
    protected _buildState(
        props: PurchaseIntentMessageItemFactoryProps,
        initialBuild: boolean,
        incomingState: Readonly<PurchaseIntentMessageItemFactoryState> | undefined,
    ): Partial<PurchaseIntentMessageItemFactoryState> | undefined {
        const newState: Partial<PurchaseIntentMessageItemFactoryState> = {
            ...incomingState,
        };

        if (initialBuild) {
            newState.isDialogOpen = false;
            newState.isTradeInfoDialogOpen = false;
        }

        if (props.baseData.message.links.purchaseIntent) {
            PurchaseIntentStore.invalidateCache(props.baseData.message.links.purchaseIntent);
            newState.purchaseIntent = PurchaseIntentStore.getOne(props.baseData.message.links.purchaseIntent);
        }

        // OFFER
        if (newState.purchaseIntent && !newState.offer) {
            newState.offer = OfferStore.getOne(newState.purchaseIntent.links.offer);
        }

        // PRE_ORDER_TRANSLATION-MAPPING
        if (newState.purchaseIntent && newState.purchaseIntent.links.category && !newState.product) {
            newState.product = ProductStore.getOne(newState.purchaseIntent.links.category);
        }

        if (newState.purchaseIntent && !newState.sellingPerson) {
            newState.sellingPerson = PersonStore.getOne(newState.purchaseIntent.links.sellingPerson);
        }
        if (newState.purchaseIntent && newState.purchaseIntent.links.buyingPerson && !newState.buyingPerson) {
            newState.buyingPerson = PersonStore.getOne(newState.purchaseIntent.links.buyingPerson);
        }
        if (
            newState.sellingPerson &&
            newState.product &&
            newState.buyingPerson &&
            !newState.preOrderTranslationMapping
        ) {
            newState.preOrderTranslationMapping = {
                firstNameSeller: newState.sellingPerson.firstname,
                lastNameSeller: newState.sellingPerson.lastname,
                firstNameBuyer: newState.buyingPerson.firstname,
                lastNameBuyer: newState.buyingPerson.lastname,
                product: this.props.t(`ontofood:${newState.product.label}`),
            };
        }

        return newState;
    }

    onShowDialogCallBack = (value: boolean) => {
        this.setState({
            isDialogOpen: value,
        });
    };

    onShowTradeInfoDialogCallBack = (value: boolean) => {
        this.setState({
            isTradeInfoDialogOpen: value,
        });
    };

    render(): React.ReactElement | null {
        return this.state.purchaseIntent && this.state.preOrderTranslationMapping ? (
            <MessageItemRenderer
                data={{
                    ...addPurchaseIntentMessageItemData(
                        this.props.baseData,
                        this.state.purchaseIntent,
                        this.props.t,
                        this.state.preOrderTranslationMapping,
                        this.onShowDialogCallBack,
                        this.onShowTradeInfoDialogCallBack,
                        this.state.isDialogOpen,
                        this.state.isTradeInfoDialogOpen,
                    ),
                    isLoaded: true,
                }}
            />
        ) : !this.props.baseData.message.links.purchaseIntent ? (
            <MessageItemRenderer
                data={{
                    ...this.props.baseData,
                    messageBox: {
                        ...this.props.baseData.messageBox,
                        topic: this.props.t('dialogs:hint'),
                        content: this.props.t('messaging:messageNotDisplayable'),
                    },
                    isLoaded: true,
                }}
            />
        ) : (
            <MessageItemRenderer data={this.props.baseData} />
        );
    }
}

export default withTranslation(['messaging', 'purchaseIntent', 'dialogs'])(PurchaseIntentMessageItemFactory);
