import { DownloadLink } from 'model/Image';
import { CompanyMemberships, Membership, MembershipWriteView } from 'model/Membership';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CertificateDownloadLinkStore, CompanyMembershipsStore, MembershipStore } from 'store';
import { getUuidFromString } from 'util/helpers';
import http from 'api/http';

class MembershipService {
    getMembership(membershipRef: string): Observable<Membership> {
        return http
            .getAxios()
            .get<Membership>(membershipRef)
            .pipe(map((response) => response.data));
    }

    createMembership(companyRef: string, membership: MembershipWriteView): Observable<Membership> {
        const companyId = getUuidFromString(companyRef);
        if (!companyId) {
            return of();
        }
        return http
            .getAxios()
            .post<Membership>(companyRef + '/memberships', membership, { headers: { 'Current-Company': companyId } })
            .pipe(
                map((response) => response.data),
                tap((membership: Membership): string => MembershipStore.setOne(membership)),
                tap((membership: Membership) => CompanyMembershipsStore.loadOne(membership.links.company)),
            );
    }

    deleteMembership(membership: Membership): Observable<void> {
        const companyId = getUuidFromString(membership.links.company);
        if (!companyId) return of();

        return http
            .getAxios()
            .delete('memberships/' + membership.id, { headers: { 'Current-Company': companyId } })
            .pipe(
                map((response) => response.data),
                tap(() => MembershipStore.removeOne(membership)),
                tap(() => CompanyMembershipsStore.loadOne(membership.links.company)),
            );
    }

    updateMembership(
        membership: Membership,
        certificate?: File,
        onUploadProgress?: (progressEvent: ProgressEvent) => void,
    ): Observable<boolean> {
        const companyId = getUuidFromString(membership.links.company);
        if (!companyId) return of(false);
        return http
            .getAxios()
            .put<Membership>(
                'memberships/' + membership.id,
                new MembershipWriteView(
                    membership.inTransition,
                    membership.description ? undefined : membership.links.association,
                    membership.description,
                    undefined,
                    membership.validUntil,
                ),
                { headers: { 'Current-Company': companyId } },
            )
            .pipe(
                switchMap((value) =>
                    forkJoin([
                        value.data && certificate
                            ? this.updateCertificate(value.data, certificate, onUploadProgress).pipe(map(() => value))
                            : value
                              ? MembershipStore.loadOne(value.data.links.self).pipe(map(() => value))
                              : of(value),
                    ]),
                ),
                map(([response]) => (response ? response.status < 400 : false)),
                catchError(() => of(false)),
            );
    }

    getMemberships(companyRef: string): Observable<CompanyMemberships> {
        return http
            .getAxios()
            .get<string[]>(companyRef + '/memberships')
            .pipe(map((response) => new CompanyMemberships(companyRef, response.data)));
    }

    createMembershipWithCertificate(
        companyRef: string,
        membership: MembershipWriteView,
        certificate?: File,
        onUploadProgress?: (progressEvent: ProgressEvent) => void,
    ): Observable<Membership> {
        const companyId = getUuidFromString(companyRef);
        if (!companyId) return of();

        const data = new FormData();
        if (certificate) data.append('certificate', certificate);
        data.append('membership', JSON.stringify(membership));

        return http
            .getAxios()
            .post<Membership>(`${companyRef}/membershipsWithFile`, data, {
                headers: { 'Current-Company': companyId, 'content-type': 'multipart/form-data' },
                onUploadProgress,
            })
            .pipe(
                map((response) => response.data),
                tap((membership: Membership): string => MembershipStore.setOne(membership)),
                tap((membership: Membership) => CompanyMembershipsStore.loadOne(membership.links.company)),
                tap(() => membership.certificate && CertificateDownloadLinkStore.loadOne(membership.certificate)),
            );
    }

    private updateCertificate(
        membership: Membership,
        certificate: File,
        onUploadProgress?: (progressEvent: ProgressEvent) => void,
    ): Observable<boolean> {
        const companyId = getUuidFromString(membership.links.company);
        const memberShipRef = membership.links.self;
        if (!companyId) {
            return of(false);
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Current-Company': companyId,
            },
            onUploadProgress,
        };
        const formData = new FormData();
        formData.append('file', certificate);
        return http
            .getAxios()
            .put<unknown>(memberShipRef + '/certificate', formData, config)
            .pipe(
                switchMap(() => CompanyMembershipsStore.loadOne(membership.links.company)),
                switchMap(() => MembershipStore.loadOne(memberShipRef)),
                tap(
                    () =>
                        membership.links.certificate &&
                        CertificateDownloadLinkStore.loadOne(membership.links.certificate),
                ),
                map(() => true),
                catchError(() => of(false)),
            );
    }

    getCertificateLink(certificateRef: string): Observable<DownloadLink> {
        return http
            .getAxios()
            .get<DownloadLink>(certificateRef)
            .pipe(map((response) => response.data));
    }
}

export default new MembershipService();
