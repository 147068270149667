import { ComponentBase } from 'resub';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Company } from 'model/Company';
import { AddressStore, CompanyRolesStore, CompanyStore } from 'store';
import { Address } from 'model/Address';
import { NearbuyRole } from 'model/NearbuyRole';

const styles = () => createStyles({});

type CompanySelectionProperties = WithStyles<typeof styles>;

interface CompanySelectionState {
    company?: Company;
    address?: Address;
    shouldUpdateBounds?: boolean;
    currentCompanyRoles?: NearbuyRole[];
}

class CompanySelection extends ComponentBase<CompanySelectionProperties, CompanySelectionState> {
    protected _buildState(
        props: CompanySelectionProperties,
        initialBuild: boolean,
        incomingState?: CompanySelectionState,
    ): Partial<CompanySelectionState> | undefined {
        const selectedCompany = CompanyStore.getSelected();
        const newState: Partial<CompanySelectionState> = {
            company: selectedCompany,
            address: AddressStore.getSelected(),
        };
        if (newState.company) {
            newState.currentCompanyRoles = CompanyRolesStore.getOne(newState.company.links.self)?.roles;
        } else {
            newState.currentCompanyRoles = undefined;
        }
        newState.shouldUpdateBounds = incomingState?.address !== newState.address;
        return newState;
    }

    render(): null {
        return null;
    }
}

export default withStyles(styles, { withTheme: true })(CompanySelection);
