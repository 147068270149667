import LocalStorageMigrator, { Migration } from 'components/initialization/initializers/migration/LocalStorageMigrator';
import { DashletProperties, DashletType } from 'model/DashletProperties';

class OntofoodDashletToAdminMigration implements Migration {
    async migrate(_version: number): Promise<number> {
        //Remove OntofoodDashlet from Dashboard as it has been moved to admin dashlets
        const jsonString = localStorage.getItem('PersistentStore_DashletStore');
        if (jsonString) {
            //A dashlet configuration exists, lets remove the Ontofood dashlet if it exists
            const dashlets = JSON.parse(jsonString) as DashletProperties[];
            const filteredDashlets = dashlets.filter((properties) => properties.id != DashletType.ontofood);
            localStorage.setItem('PersistentStore_DashletStore', JSON.stringify(filteredDashlets));
            return Promise.resolve(3);
        } else {
            //No dashlet configuration was previously saved, so the correct defaults will be loaded
            return Promise.resolve(3);
        }
    }
}

export default LocalStorageMigrator.registerMigration(2, new OntofoodDashletToAdminMigration());
