import * as React from 'react';
import { ComponentBase } from 'resub';
import { ExpiringRequestsSystemMessage } from 'model/SystemMessage';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ExpiringRequestsSystemMessageItemProps extends RouteComponentProps {
    systemMessage: ExpiringRequestsSystemMessage;
}

interface ExpiringRequestsMapping extends Record<string, string> {
    expiringRequestsCount: string;
}

interface ExpiringRequestsSystemMessageItemState {
    mapping?: ExpiringRequestsMapping;
}

class ExpiringRequestsSystemMessageItem extends ComponentBase<
    ExpiringRequestsSystemMessageItemProps,
    ExpiringRequestsSystemMessageItemState
> {
    protected _buildState(
        props: ExpiringRequestsSystemMessageItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<ExpiringRequestsSystemMessageItemState>,
    ): Partial<ExpiringRequestsSystemMessageItemState> | undefined {
        return {
            mapping: {
                expiringRequestsCount: props.systemMessage.expiringRequestsCount.toString(),
            },
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping) return null;

        return (
            <SystemMessageItem
                onClick={() => this.props.history.push('/marketplace/requests')}
                systemMessage={this.props.systemMessage}
                mapping={this.state.mapping}
            />
        );
    }
}

export default withRouter(ExpiringRequestsSystemMessageItem);
