import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import InitializationManager from 'components/initialization/InitializationManager';
import SettingsLoader from 'components/initialization/initializers/SettingsLoader';
import { CompanyStore, PersonStore } from 'store';
import CompanyFeatureStore from 'store/FeatureStore';

export class FeatureInitializer implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        const selected = CompanyStore.getSelected();
        const user = PersonStore.getSelected();
        if (selected) {
            const selectedCompanyRef = selected.links.self;
            CompanyFeatureStore.setSelected(selectedCompanyRef);
            CompanyFeatureStore.loadOne(selectedCompanyRef);
        } else if (user) {
            const ref = user.links.self;
            CompanyFeatureStore.setSelected(ref);
            CompanyFeatureStore.loadOne(ref);
        }
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new FeatureInitializer(),
    [SettingsLoader],
);
