import * as React from 'react';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SystemMessage } from 'model/SystemMessage';

interface InvalidDataSystemMessageItemProps extends WithTranslation {
    systemMessage: SystemMessage;
}

class InvalidDataSystemMessageItem extends React.Component<InvalidDataSystemMessageItemProps> {
    render(): React.ReactElement | null {
        return <SystemMessageItem systemMessage={this.props.systemMessage} mapping={{}} />;
    }
}

export default withTranslation()(InvalidDataSystemMessageItem);
