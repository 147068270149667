import { createPersistentEntityStore } from 'resub-entity';

export interface SystemMessagesSeenEntity {
    employmentRef: string;
    seenMessages: SystemMessageSeen[];
}

interface SystemMessageSeen {
    systemMessageRef: string;
    validUntil?: Date;
}

export const SystemMessageSeenStore = createPersistentEntityStore<SystemMessagesSeenEntity, string>({
    storageKey: 'systemMessageSeen',
    selectIdFunction: (entity) => entity.employmentRef,
    loadOnInit: true,
});
