import ConsoleLineBuffer from 'components/usersnap/ConsoleLineBuffer';

type OverwritableFunctions = keyof Pick<Console, 'log' | 'warn' | 'error' | 'dir' | 'info' | 'debug'>;

function isArray<L>(data: L | L[]): data is L[] {
    return Array.isArray(data);
}

class ConsoleLogInterceptor {
    oldFunctions = new Map<OverwritableFunctions, Console[OverwritableFunctions]>();

    overwriteFunction(console: Console, funcName: OverwritableFunctions) {
        const oldFunc = console[funcName];
        this.oldFunctions.set(funcName, console[funcName]);
        console[funcName] = function (...data: any) {
            ConsoleLineBuffer.putLine(funcName, JSON.stringify(data));
            if (isArray(data)) {
                if (data.length === 1) {
                    oldFunc(data[0]);
                } else {
                    oldFunc(data);
                }
            }
        };
    }

    startIntercepting() {
        const consoleFunctionsToOverwrite: OverwritableFunctions[] = ['log', 'warn', 'dir', 'error', 'info', 'debug'];
        consoleFunctionsToOverwrite.forEach((fnkName: OverwritableFunctions) => {
            this.overwriteFunction(console, fnkName);
        });
    }

    stopIntercepting() {
        this.oldFunctions.forEach((oldFunc, oldFuncName) => {
            console[oldFuncName] = oldFunc;
        });
        ConsoleLineBuffer.clear();
    }

    logWithoutInterceptingFunction(logType: OverwritableFunctions, data: any) {
        if (this.oldFunctions.has(logType)) {
            this.oldFunctions.get(logType)?.(data);
        } else {
            console[logType](data);
        }
    }
}

export default new ConsoleLogInterceptor();
