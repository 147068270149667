import { AutoSubscribeStore, autoSubscribeWithKey, StoreBase } from 'resub';
import * as H from 'history';
import React, { ReactNode } from 'react';

const CURRENT_LOCATION = 'NAV_LOCATION_CURRENT';
const SHOW_DRAWER = 'show_drawer';
const PUBLIC_ROUTES = 'NAV_ROUTES_PUBLIC';
const DRAWER_CONTENT = 'DRAWER_CONTENT';

@AutoSubscribeStore
class NavStore extends StoreBase {
    private _currentLocation?: H.Location<any>;
    private _showDrawer = false;
    private drawerContent?: React.ReactNode = undefined;

    private _publicRoutes: string[] = new Array<string>('/invitation/accept', '/invitation/decline');

    setLocation(location: H.Location<any>) {
        this._currentLocation = location;
        this.updateCurrentRoute(location.pathname);
        this.trigger(CURRENT_LOCATION);
    }

    @autoSubscribeWithKey(CURRENT_LOCATION)
    getLocation(): any {
        return this._currentLocation;
    }

    /**
     * Here we can logic for showing drawer or not.
     * @param _path
     */
    updateCurrentRoute(_path: string) {
        // console.log(path);
        // this.setShowDrawer(false);
    }

    @autoSubscribeWithKey(CURRENT_LOCATION)
    getCurrentRoute(): string {
        return this.currentRoute();
    }

    @autoSubscribeWithKey(CURRENT_LOCATION)
    getCurrentSearchParams(searchParam: string): string | null {
        if (!this._currentLocation || !this._currentLocation.search) {
            return null;
        }
        return new URLSearchParams(this._currentLocation.search).get(searchParam);
    }

    setShowDrawer(show: boolean): void {
        this._showDrawer = show;
        this.trigger(SHOW_DRAWER);
    }

    @autoSubscribeWithKey(SHOW_DRAWER)
    isShowDrawer(): boolean {
        return this._showDrawer;
    }

    setDrawerContent(content: React.ReactNode | undefined): void {
        this.drawerContent = content;
        this.trigger(DRAWER_CONTENT);
    }

    @autoSubscribeWithKey(DRAWER_CONTENT)
    getDrawerContent(): ReactNode {
        return this.drawerContent;
    }

    addPublicRoute(...routes: string[]): void {
        routes.forEach((value) => {
            // only add routes, that are not already present
            if (!this._publicRoutes.includes(value)) {
                this._publicRoutes.push(value);
            }
        });
        this.trigger(PUBLIC_ROUTES);
    }

    @autoSubscribeWithKey(PUBLIC_ROUTES)
    getPublicRoutes(): string[] {
        return this._publicRoutes;
    }

    isCurrentRoutePublic(): boolean {
        return this._publicRoutes.includes(this.currentRoute());
    }

    private currentRoute(): string {
        return this._currentLocation ? this._currentLocation.pathname : '';
    }
}

export default new NavStore();
