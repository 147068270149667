import PersonIcon from '@mui/icons-material/Person';
import { Theme, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import MessageService from 'api/MessageService';
import ChatInput from 'components/messaging/chat/ChatInput';
import MessageItemFactory from 'components/messaging/chat/messagefactory/MessageItemFactory';
import QuoteReference from 'components/messaging/chat/references/QuoteReference';
import MessageHeader from 'components/messaging/MessageHeader';
import PhoneComponent from 'components/shared/Popovers/PhoneComponent';
import { Company } from 'model/Company';
import { Contact } from 'model/Contact';
import { Message } from 'model/Message';
import { MessageTermsStatus, Person } from 'model/Person';
import * as React from 'react';
import { ReactElement } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import {
    AlertStore,
    CompanyContactsStore,
    CompanyStore,
    ContactsStore,
    EmploymentStore,
    MessageStore,
    PersonImagesStore,
    PersonStore,
} from 'store';
import { DialogStore } from 'store/DialogStore';
import { Employment, EmploymentStatus } from 'model/Employment';
import { getUuidFromString } from 'util/helpers';
import { getDialogId } from 'model/Dialog';
import { AlertType } from 'components/alert/Alerts';
import Card from '@mui/material/Card';
import { theme } from 'style/NearbuyStyle';

const _styles = (theme: Theme) =>
    createStyles({
        avatar: {
            flexBasis: 0,
        },
        container: {
            cursor: 'pointer',
        },
        foreignHeader: {
            fontWeight: 'bold',
            margin: theme.spacing(1),
        },
        hr: {
            margin: theme.spacing(2) + 'px 0px',
            opacity: 0.12,
        },
        messages: {
            height: '54vh',
            overflow: 'auto',
        },
        overflowEllipsis: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
        },
        person: {
            fontWeight: 'bold',
            marginRight: theme.spacing(2),
        },
        title: {
            fontSize: 'large',
        },
        topic: {
            fontWeight: 'bold',
            margin: theme.spacing(1) + 'px 0px',
        },
        postboxEmptyDescription: {
            fontSize: '15px',
            lineHeight: '19px',
            margin: theme.spacing(2) + 'px 0px',
        },
        postboxEmptyTitle: {
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: '25px',
        },
    });

interface ChatComponentProps extends WithStyles<typeof _styles>, WithTranslation {
    employmentRef: string;
}

interface ChatComponentState {
    employment: Employment;
    isFormerEmployeeChat: boolean;
    isOtherEmploymentInactive: boolean;
    messages?: readonly Message[];
    otherCompany?: Company | null;
    otherPerson?: Person | null;
    formerEmploymentRef?: string | undefined;
    ownCompanyRef?: string;
    ownPersonRef: string;
    avatarSrc?: string;
    messageTopic?: string;
    messageQuote?: Message;
    messageContent: string;
    isDisabled: boolean;
    isQuoting: boolean;
    phone?: string;
    companyHint?: boolean;
    shouldResetDialogStore: boolean;
}

class ChatComponent extends ComponentBase<ChatComponentProps, ChatComponentState> {
    protected _buildState(
        props: ChatComponentProps,
        initialBuild: boolean,
        incomingState?: Readonly<ChatComponentState>,
    ): Partial<ChatComponentState> | undefined {
        const newState: Partial<ChatComponentState> = {
            messageTopic: incomingState?.messageTopic,
        };
        const user = PersonStore.getSelected();
        if (!user) return newState;

        if (initialBuild) newState.shouldResetDialogStore = false;

        newState.employment = EmploymentStore.getOne(props.employmentRef);
        if (!newState.employment) return newState;

        newState.isFormerEmployeeChat = newState.employment.status == EmploymentStatus.TERMINATED;
        if (incomingState && incomingState.isFormerEmployeeChat != newState.isFormerEmployeeChat)
            newState.shouldResetDialogStore = true;

        const formerEmployment = newState.isFormerEmployeeChat ? newState.employment : undefined;
        if (formerEmployment) {
            EmploymentStore.setSelectedFormerEmployment(formerEmployment);
            newState.formerEmploymentRef = formerEmployment.links.person;
        }

        const dialog = DialogStore.getSelected();
        if (!dialog) {
            newState.ownCompanyRef = undefined;
            newState.ownPersonRef = undefined;
            newState.otherPerson = undefined;
            newState.otherCompany = undefined;
            newState.avatarSrc = undefined;
            return newState;
        }

        newState.isOtherEmploymentInactive = !dialog.isChatPartnerActive;
        newState.isDisabled =
            user.messageTermsStatus !== MessageTermsStatus.ACCEPTED ||
            newState.isFormerEmployeeChat ||
            newState.isOtherEmploymentInactive;

        if (!newState.isFormerEmployeeChat) {
            if (dialog.otherPersonRef && dialog.otherCompanyRef) {
                PersonStore.setSelectedTerminatedPerson(
                    newState.isOtherEmploymentInactive ? PersonStore.getOne(dialog.otherPersonRef) : undefined,
                );
                CompanyStore.setSelectedTerminatedPersonCompany(
                    newState.isOtherEmploymentInactive ? CompanyStore.getOne(dialog.otherCompanyRef) : undefined,
                );
            }
        }

        newState.ownCompanyRef = newState.employment.links.company;
        newState.ownPersonRef = newState.employment.links.person;

        newState.otherCompany = CompanyStore.getOne(dialog.otherCompanyRef);
        newState.otherPerson = dialog.otherPersonRef ? PersonStore.getOne(dialog.otherPersonRef) : undefined;

        // Clear the dialog if chat is changed.
        if (
            incomingState &&
            (newState.otherCompany?.links.self !== incomingState.otherCompany?.links.self ||
                newState.otherPerson?.links.self !== incomingState.otherPerson?.links.self)
        ) {
            newState.messages = undefined;
            newState.messageTopic = undefined;
            newState.isQuoting = false;
            newState.messageContent = undefined;
            newState.messageQuote = undefined;
            newState.phone = undefined;
            newState.companyHint = false;
        }

        // Don't load messages if data is still loading.
        if (newState.otherPerson && newState.otherCompany) {
            newState.messages = MessageStore.search({
                otherPersonRef: newState.otherPerson?.links.self,
                otherCompanyRef: newState.otherCompany.links.self,
                formerEmployeeRef: newState.formerEmploymentRef,
            });

            if (dialog.latestMessageRef) {
                const latestMessage = MessageStore.getOne(dialog.latestMessageRef);

                newState.messageTopic =
                    newState.messageTopic !== undefined ? newState.messageTopic : latestMessage?.topic || undefined;
            }

            // Get phone information.
            const companyContacts = CompanyContactsStore.getOne(newState.otherCompany.links.self);
            if (companyContacts) {
                const contact = (
                    companyContacts.contactPersons.map((c) => ContactsStore.getOne(c)).filter((c) => !!c) as Contact[]
                ).find((c) => c.links.person === newState.otherPerson!.links.self);
                if (contact && contact.phone) {
                    newState.phone = contact.phone;
                    newState.companyHint = false;
                } else {
                    newState.phone = newState.otherCompany.phone;
                    newState.companyHint = true;
                }
            }
        }

        const otherPersonId = getUuidFromString(newState.otherPerson?.links.self);

        if (otherPersonId) {
            newState.avatarSrc = PersonImagesStore.getOne(otherPersonId)?.getLatestImage()?.source;
        } else {
            newState.avatarSrc = undefined;
        }
        return newState;
    }

    setAlerts() {
        if (this.state.isOtherEmploymentInactive) {
            AlertStore.addAlert(AlertType.CHATPARTNER_TERMINATED);
        } else {
            AlertStore.removeAlert(AlertType.CHATPARTNER_TERMINATED);
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.setAlerts();
    }

    componentDidUpdate(prevProps: Readonly<ChatComponentProps>, prevState: ChatComponentState, prevContext: any) {
        super.componentDidUpdate(prevProps, prevState, prevContext);
        this.setAlerts();
        if (this.state.shouldResetDialogStore && this.state.employment) {
            const dialogs = DialogStore.search({
                ownCompanyRef: this.state.employment.links.company,
                ownPersonRef: this.state.employment.links.person,
            });

            DialogStore.clear();
            DialogStore.setAll(dialogs.slice());
            DialogStore.setSelected(getDialogId(DialogStore.getAll()[0]));

            if (!this.state.isFormerEmployeeChat) {
                EmploymentStore.setSelectedFormerEmployment(undefined);
                this.setState({ formerEmploymentRef: undefined });
            }
            this.setState({
                shouldResetDialogStore: false,
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        AlertStore.removeAlert(AlertType.CHATPARTNER_TERMINATED);
    }

    sendMessage(): void {
        if (!this.state.otherCompany || !this.state.ownCompanyRef || !this.state.otherPerson) {
            // The available data is invalid and does not allow messages to be sent.
            return;
        }
        MessageService.sendMessageDetailed(
            this.state.otherPerson.links.self,
            this.state.otherCompany.links.self,
            this.state.ownCompanyRef,
            this.state.messageContent,
            this.state.messageTopic || '',
            this.state.messageQuote ? this.state.messageQuote.links.self : undefined,
        )?.subscribe(() => {
            this.setState({
                messageContent: '',
                isQuoting: false,
                messageQuote: undefined,
            });
        });
    }

    getProfileImage(): ReactElement {
        if (this.state.avatarSrc) {
            return <Avatar src={this.state.avatarSrc} style={{ height: '56px', width: '56px' }} />;
        }

        return (
            <Avatar style={{ height: '56px', width: '56px' }}>
                <PersonIcon />
            </Avatar>
        );
    }

    getPlaceholder(): ReactElement | null {
        if (this.state.ownCompanyRef) return null;

        return (
            <Grid container direction={'row'} xs={12} gap={4} padding={theme.spacing(3)}>
                <Grid container item direction={'column'} gap={2} xs={7}>
                    <Typography className={this.props.classes.postboxEmptyTitle}>
                        {this.props.t('messaging:postboxEmptyTitle')}
                    </Typography>
                    <Typography className={this.props.classes.postboxEmptyDescription}>
                        {this.props.t('messaging:postboxEmptyDescription')}
                    </Typography>
                </Grid>
                <Grid container item xs={4} justifyContent={'end'}>
                    <img alt="postbox" src={'/images/postbox.png'} />
                </Grid>
            </Grid>
        );
    }

    abortQuote(): void {
        this.setState({
            isQuoting: false,
            messageQuote: undefined,
        });
    }

    showQuote(quoteMessage: Message): void {
        this.setState({
            isQuoting: true,
            messageQuote: quoteMessage,
            messageTopic: undefined,
        });
    }

    onChatInputSend = (content: string, topic: string | undefined) => {
        this.setState(
            {
                messageContent: content,
                messageTopic: topic,
            },
            () => {
                this.sendMessage();
            },
        );
    };

    showPhoneButton(): React.ReactElement | null {
        if (!this.state.phone) return null;
        return (
            <PhoneComponent
                phone={this.state.phone}
                popover={true}
                companyHint={this.state.companyHint}
                calledFrom={'ChatComponent'}
            />
        );
    }

    getChat(): ReactElement | null {
        if (!this.state.ownCompanyRef) return null;

        return (
            <>
                <Grid container wrap={'nowrap'} gap={2}>
                    <Grid container item alignItems={'center'} className={this.props.classes.avatar}>
                        {this.getProfileImage()}
                    </Grid>
                    <Grid item>
                        <MessageHeader
                            isAHeader
                            company={this.state.otherCompany ?? undefined}
                            person={this.state.otherPerson ?? undefined}
                        />
                    </Grid>
                    <Grid container item alignItems={'center'} justifyContent={'end'}>
                        {this.showPhoneButton()}
                    </Grid>
                </Grid>
                <Grid item>
                    <hr className={this.props.classes.hr} />
                </Grid>
                <Grid
                    container
                    item
                    className={this.props.classes.messages}
                    direction={'column-reverse'}
                    wrap={'nowrap'}
                >
                    {this.state.messages?.map((message) => (
                        <MessageItemFactory
                            isReadOnly={this.state.isDisabled}
                            messageRef={message.links.self}
                            key={message.links.self}
                            onQuoteMessage={(refMessage: Message): void => this.showQuote(refMessage)}
                        />
                    ))}
                </Grid>
                <Grid item>
                    <hr className={this.props.classes.hr} />
                </Grid>
                <Grid container direction={'column'}>
                    <QuoteReference quotedMessage={this.state.messageQuote} onAbort={(): void => this.abortQuote()} />
                    <ChatInput
                        inactiveEmployeeRef={
                            this.state.isOtherEmploymentInactive ? this.state.otherPerson?.links.self : undefined
                        }
                        isDisabled={this.state.isDisabled}
                        isQuoting={this.state.isQuoting}
                        onSend={this.onChatInputSend}
                        setTopic={(topic) => this.setState({ messageTopic: topic })}
                        topic={this.state.messageTopic}
                        // Will be actually dialog id once we have it
                        dialogId={`${this.state.otherCompany?.links.self}${this.state.otherPerson?.links.self}`}
                    />
                </Grid>
            </>
        );
    }

    render(): React.ReactElement | null {
        return (
            <Grid container justifyContent={'start'} sx={{ padding: 0 }} maxWidth={'xl'}>
                <Card
                    sx={{
                        width: '100%',
                        padding: theme.spacing(2),
                        borderRadius: '10px',
                        boxShadow: ' 0px 5px 10px 3px rgba(0, 0, 0, 0.05)',
                    }}
                >
                    {this.state.ownCompanyRef ? this.getChat() : this.getPlaceholder()}
                </Card>
            </Grid>
        );
    }
}

export default withTranslation('messaging')(withStyles(_styles, { withTheme: true })(ChatComponent));
