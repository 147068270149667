import { DashletType, RequiredFeatures } from 'model/DashletProperties';
import { Feature } from 'model/Feature';

class DashletFeatureFactory {
    getRequiredFeatures(dashletType: DashletType): RequiredFeatures {
        switch (dashletType) {
            //Admin Dashlets
            case DashletType.adminNavigation:
            case DashletType.ontofood:
                return {
                    featuresRequired: new Set([Feature.BE_ADMIN]),
                };
            //Market Feature Dashlets
            case DashletType.savedOffersAndRequests:
            case DashletType.myOffers:
            case DashletType.myRequests:
            case DashletType.map:
                return {
                    featuresRequired: new Set([
                        [Feature.SEARCH_OFFERS_AND_REQUESTS, Feature.HAVE_OFFERS_AND_REQUESTS],
                        Feature.BE_ADMIN,
                    ]),
                    type: 'oneOf',
                };
            //Normal Dashlets
            case DashletType.subscription:
            case DashletType.currentCompany:
            case DashletType.messages:
            case DashletType.contacts:
            case DashletType.myFavouriteCompanies:
                return null;
        }
    }
}

export default new DashletFeatureFactory();
