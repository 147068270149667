import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { withTranslation, WithTranslation } from 'react-i18next';
import FavouriteListItemComponent from 'components/favourites/FavouriteListItemComponent';
import { Company } from 'model/Company';
import { Person } from 'model/Person';
import { ComponentBase } from 'resub';
import { CompanyStore } from 'store';

const styles = (theme: Theme) =>
    createStyles({
        container: {},
        tableHeader: {
            backgroundColor: theme.palette.background.default,
        },
        tableHeaderCell: {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            width: 500,
        },
    });

interface FavouritesTableProps extends WithStyles<typeof styles>, WithTranslation {
    favouritedCompanyRefs?: string[];
    readOnly?: boolean;
    person: Person;
}

interface FavouritesTableState {
    favouritedCompanies?: (Company | undefined)[];
}

class FavouritesTableComponent extends ComponentBase<FavouritesTableProps, FavouritesTableState> {
    protected _buildState(
        props: FavouritesTableProps,
        initialBuild: boolean,
        incomingState?: Readonly<FavouritesTableState>,
    ): Partial<FavouritesTableState> | undefined {
        if (this.props.favouritedCompanyRefs) {
            return {
                favouritedCompanies: this.props.favouritedCompanyRefs.map((companyRef) => {
                    return CompanyStore.getOne(companyRef);
                }),
            };
        }
    }

    showCompanies(): (React.ReactElement | null | undefined)[] | null {
        if (this.state.favouritedCompanies && this.props.person) {
            return this.state.favouritedCompanies.map((company) => {
                if (company) {
                    return (
                        <FavouriteListItemComponent
                            company={company}
                            key={company.links.self}
                            readOnly={this.props.readOnly}
                            person={this.props.person}
                        />
                    );
                } else return null;
            });
        }
        return null;
    }

    getTitleRow() {
        const titles: (string | undefined)[] = [];
        titles.push(this.props.t('company:name'));
        titles.push(this.props.t('company:label'));
        titles.push(this.props.t('company:city'));
        titles.push(this.props.t('common:distance'));
        titles.push('');
        return (
            <TableRow>
                {titles.map((value, index) => (
                    <TableCell className={this.props.classes.tableHeaderCell} key={value ?? index}>
                        {value}
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    render(): React.ReactElement | null {
        if (!this.state.favouritedCompanies || !this.props.person) {
            return null;
        }

        return (
            <Table>
                <TableHead className={this.props.classes.tableHeader}>{this.getTitleRow()}</TableHead>
                <TableBody>{this.showCompanies()}</TableBody>
            </Table>
        );
    }
}

export default withTranslation(['common', 'company'])(
    withStyles(styles, { withTheme: true })(FavouritesTableComponent),
);
