import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import AlertFactory from 'components/alert/AlertFactory';
import { Company } from 'model/Company';
import { EmploymentStatus } from 'model/Employment';
import { Feature } from 'model/Feature';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { AlertStore, CompanyFeatureStore, CompanyStore, EmploymentStore, PersonEmploymentStore } from 'store/index';

const _styles = () => createStyles({});

interface AlertsProps extends WithStyles<typeof _styles> {
    alertScope: AlertScope | undefined;
}

interface AlertsState {
    company: Company | undefined;
    features: Feature;
    alert: AlertType;
}

/** number values are treated as alert priority:
 * - keep them separated by the hundreds regarding scope
 * - lower values translate to higher priority
 * */
export enum AlertType {
    ROBINSON_CRUSOE = 101,
    APPLICATION_PENDING = 102,

    ADDRESS_MISSING = 201,
    VERIFICATION_MISSING = 202,
    VERIFICATION_UPLOADED = 203,

    FOREIGN_INBOX = 301,
    DECLINED_MESSAGING = 302,
    CHATPARTNER_TERMINATED = 303,
}

export enum AlertScope {
    GLOBAL = 'GLOBAL',
    COMPANY = 'COMPANY',
    MESSAGING = 'MESSAGING',
}

const userAlerts = [
    AlertType.ROBINSON_CRUSOE,
    AlertType.APPLICATION_PENDING,
    AlertType.ADDRESS_MISSING,
    AlertType.VERIFICATION_MISSING,
    AlertType.VERIFICATION_UPLOADED,
];

const companyAlerts = [AlertType.ADDRESS_MISSING, AlertType.VERIFICATION_MISSING, AlertType.VERIFICATION_UPLOADED];

const messagingAlerts = [AlertType.CHATPARTNER_TERMINATED, AlertType.FOREIGN_INBOX, AlertType.DECLINED_MESSAGING];

class Alerts extends ComponentBase<AlertsProps, AlertsState> {
    protected _buildState(
        props: AlertsProps,
        initialBuild: boolean,
        incomingState?: AlertsState,
    ): Partial<AlertsState> | undefined {
        const newState: Partial<AlertsState> = {
            company: CompanyStore.getSelected(),
        };

        if (newState.company === undefined) {
            const employment = EmploymentStore.getSelected();
            if (employment && employment.status === EmploymentStatus.APPLICATION_PENDING) {
                newState.company = CompanyStore.getOne(employment.links.company);
            }
        }

        let alerts: AlertType[] = [];
        const alertsFromStore = AlertStore.getAlerts();

        if (props.alertScope && alertsFromStore.length) {
            alerts = alertsFromStore.filter((type) => {
                switch (props.alertScope) {
                    case AlertScope.COMPANY:
                        return companyAlerts.includes(type);
                    case AlertScope.MESSAGING:
                        return messagingAlerts.includes(type);
                    case AlertScope.GLOBAL:
                        return userAlerts.includes(type);
                }
            });
        }

        if (alerts.length) {
            newState.alert = alerts.reduce((accumulator, currentValue) => Math.min(accumulator, currentValue));
        } else {
            newState.alert = undefined;
        }

        return newState;
    }

    componentDidMount() {
        super.componentDidMount();
        this.setAlerts();
    }

    componentDidUpdate(prevProps: Readonly<AlertsProps>, prevState: AlertsState, prevContext: any) {
        super.componentDidUpdate(prevProps, prevState, prevContext);
        this.setAlerts();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        AlertStore.removeAlert(AlertType.ADDRESS_MISSING);
        AlertStore.removeAlert(AlertType.VERIFICATION_UPLOADED);
        AlertStore.removeAlert(AlertType.VERIFICATION_MISSING);
        AlertStore.removeAlert(AlertType.APPLICATION_PENDING);
        AlertStore.removeAlert(AlertType.ROBINSON_CRUSOE);
    }

    setAlerts() {
        const hasCompanySearchInternalFeature = CompanyFeatureStore.hasPermission(
            new Set<Feature>([Feature.SEARCH_INTERNAL_COMPANIES]),
            'oneOf',
        );
        const personEmployments = PersonEmploymentStore.getSelected();
        const employment = EmploymentStore.getSelected();

        if (this.state.company && personEmployments) {
            if (
                hasCompanySearchInternalFeature === false &&
                personEmployments.employments.some(
                    (employment) =>
                        employment.links.company === this.state.company?.links.self &&
                        employment.status === EmploymentStatus.MANAGER,
                )
            ) {
                AlertStore.addAlert(AlertType.ADDRESS_MISSING);
            } else {
                AlertStore.removeAlert(AlertType.ADDRESS_MISSING);
            }

            if (!this.state.company.verified && this.state.company.links.verification) {
                AlertStore.addAlert(AlertType.VERIFICATION_UPLOADED);
            } else {
                AlertStore.removeAlert(AlertType.VERIFICATION_UPLOADED);
            }

            if (
                !this.state.company.verified &&
                !this.state.company.links.verification &&
                personEmployments.employments.some(
                    (employment) =>
                        employment.links.company === this.state.company?.links.self &&
                        employment.status === EmploymentStatus.MANAGER,
                )
            ) {
                AlertStore.addAlert(AlertType.VERIFICATION_MISSING);
            } else {
                AlertStore.removeAlert(AlertType.VERIFICATION_MISSING);
            }
        }

        if (employment && employment.status === EmploymentStatus.APPLICATION_PENDING) {
            AlertStore.addAlert(AlertType.APPLICATION_PENDING);
        } else {
            AlertStore.removeAlert(AlertType.APPLICATION_PENDING);
        }

        if (!employment || employment.status === EmploymentStatus.TERMINATED) {
            AlertStore.addAlert(AlertType.ROBINSON_CRUSOE);
        } else {
            AlertStore.removeAlert(AlertType.ROBINSON_CRUSOE);
        }
    }

    render(): React.ReactElement | null {
        return <AlertFactory company={this.state.company} alert={this.state.alert} />;
    }
}

export default withTranslation()(withStyles(_styles)(Alerts));
