import { createDynamicLoadingStore } from 'resub-entity';
import { SystemMessage } from 'model/SystemMessage';
import SystemMessageService from 'api/SystemMessageService';

interface SystemMessageStoreSearchType {
    limit?: number;
}

export const SystemMessageStore = createDynamicLoadingStore<SystemMessage, string, SystemMessageStoreSearchType>({
    selectIdFunction: (systemMessage: Readonly<SystemMessage>) => systemMessage.links.self,
    loadFunction: (systemMessageRef: string) => SystemMessageService.getSystemMessage(systemMessageRef),
    sortFunction: (entity1, entity2) =>
        entity1.createdAt > entity2.createdAt ? -1 : entity2.createdAt > entity1.createdAt ? 1 : 0,
    searchLoadFunction: (searchParams: SystemMessageStoreSearchType) => {
        return SystemMessageService.getSystemMessageList(searchParams.limit ?? 1000);
    },
});
