import { createPersistentEntityStore } from 'resub-entity';

export interface Setting {
    key: SettingsKey;
    value: unknown | undefined;
}

export const LocalStorageSettingsStore = createPersistentEntityStore<Setting, SettingsKey>({
    storageKey: 'settings',
    selectIdFunction: (entity) => entity.key,
    loadOnInit: true,
});

export enum SettingsKey {
    SELECTED_COMPANY = 'SelectedCompany',
    /**
     * @Deprecated  use UserSettingsStore instead for this
     */
    SEARCH_FILTERS = 'SearchFilters',
}
