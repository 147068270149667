import { TipsAndUpdates } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { CardActions, Grid, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { HideGlobalSystemMessageSnackbarNotification, NotificationInfo } from 'model/NearbuyNotification';
import { GlobalSystemMessage } from 'model/SystemMessage';
import { now } from 'moment';
import * as React from 'react';
import { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { Subscription } from 'rxjs';
import { NotificationStore, SystemMessageSnackbarStore } from 'store';
import { theme } from 'style/NearbuyStyle';
import { getValidUrl } from 'util/helpers';

const _styles = (theme: Theme) =>
    createStyles({
        listItem: {
            padding: `${theme.spacing(1)} 0 0 0`,
        },
    });

interface GlobalSystemMessageSnackbarItemProps extends WithStyles<typeof _styles>, WithTranslation {
    systemMessage: GlobalSystemMessage;
    title?: string;
    errorState?: string;
}

interface GlobalSystemMessageSnackbarItemState {
    seenSubscription?: Subscription;
    isDeleteButtonShown?: boolean;
    mapping: Record<string, unknown>;
    isOpen: boolean;
}

class GlobalSystemMessageSnackbarItem extends ComponentBase<
    GlobalSystemMessageSnackbarItemProps,
    GlobalSystemMessageSnackbarItemState
> {
    protected _buildState(
        props: GlobalSystemMessageSnackbarItemProps,
        initialBuild: boolean,
        incomingState: Readonly<GlobalSystemMessageSnackbarItemState>,
    ): Partial<GlobalSystemMessageSnackbarItemState> | undefined {
        const newState: Partial<GlobalSystemMessageSnackbarItemState> = {};
        if (initialBuild) newState.isOpen = !this.props.systemMessage.isDeleted;

        newState.mapping = {
            text: this.props.t(this.props.systemMessage.text),
        };
        return newState;
    }

    showCardContent(): ReactNode {
        return (
            <CardContent sx={{ display: 'flex' }}>
                <Grid container direction={'row'}>
                    <Grid item xs={1}>
                        <TipsAndUpdates sx={{ color: theme.palette.secondary.darkest }} />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography sx={{ fontWeight: '600', whiteSpace: 'nowrap' }}>
                            {this.props.t('system:globalSystemMessageSnackbarTitle')}
                        </Typography>
                    </Grid>
                    <Grid item textAlign={'end'} xs={1}>
                        <CloseIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                SystemMessageSnackbarStore.remove(this.props.systemMessage);
                                NotificationStore.setOne(
                                    new NotificationInfo(
                                        HideGlobalSystemMessageSnackbarNotification(
                                            this.props.systemMessage.links.self,
                                        ),
                                        now(),
                                    ),
                                );
                                this.setState({
                                    isOpen: false,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography>
                            {this.props.t('system:' + this.props.systemMessage.type, this.state.mapping)}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        );
    }

    showCardActions(): ReactNode {
        if (!this.props.systemMessage.links.link) return null;
        return (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                    sx={{
                        borderRadius: 1.5,
                        color: theme.palette.secondary.darkest,
                        '&:hover': {
                            background: theme.palette.secondary.lighter,
                        },
                    }}
                    onClick={() => window.open(getValidUrl(this.props.systemMessage.links.link))}
                >
                    {this.props.t('system:show')}
                </Button>
            </CardActions>
        );
    }

    showCard(): ReactNode {
        return (
            <Card
                sx={{
                    backgroundColor: theme.palette.secondary.lightest,
                    width: 550,
                    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
                }}
            >
                {this.showCardContent()}
                {this.showCardActions()}
            </Card>
        );
    }

    render(): React.ReactElement | null {
        return (
            <ListItem className={this.props.classes.listItem} key={this.props.systemMessage.links.self}>
                {this.showCard()}
            </ListItem>
        );
    }
}

export default withTranslation('system')(withStyles(_styles, { withTheme: true })(GlobalSystemMessageSnackbarItem));
