import GlobalSystemMessageSnackbarItem from 'components/systemmessage/items/snackbar/GlobalSystemMessageSnackbarItem';
import { GlobalSystemMessage, SystemMessage, SystemMessageType } from 'model/SystemMessage';
import * as React from 'react';
import { ReactNode } from 'react';

export function getSystemMessage(systemMessage: SystemMessage): ReactNode {
    switch (systemMessage.type) {
        case SystemMessageType.GLOBAL:
            return (
                <GlobalSystemMessageSnackbarItem
                    key={systemMessage.links.self}
                    systemMessage={systemMessage as GlobalSystemMessage}
                />
            );
    }
}
