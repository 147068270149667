import Typography from '@mui/material/Typography';
import * as React from 'react';
import { ComponentBase } from 'resub';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Theme } from '@mui/material';
import { WithStyles, WithTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DeleteIcon } from 'components/shared/Icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ContainerType, ContainerView, ContainerWriteView } from 'model/ContainerView';
import SearchService from 'api/MetaService';
import { ContainerStore } from 'store';
import { FormValidationHelper, withFormValidationHelper, WithFormValidationHelper } from 'util/FormErrors';
import UnitSelectComponent from 'components/marketplace/UnitSelectComponent';
import NumberFormat from 'react-number-format';
import { asUnitString, getUnitFromString, Unit } from 'model/Unit';

const styles = (theme: Theme) =>
    createStyles({
        selectInput: {
            width: '100%',
            flexGrow: 1,
        },
        formControl: {
            display: 'flex',
            flexGrow: 1,
            marginBottom: theme.spacing(2),
        },
        textField: {
            maxWidth: 120,
        },
        deleteButton: {
            padding: theme.spacing(0.65),
            margin: theme.spacing(1),
        },
        errorTypography: {
            color: theme.palette.error.main,
            fontSize: 13,
            fontWeight: '500',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        errorField: {
            width: '100%',
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.error.main,
                },
            },
        },
    });
interface ContainerProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    offerUnit: Unit | undefined;
    container?: ContainerWriteView | undefined;
    onUpdate: () => void;
    remove?: (containerView: ContainerWriteView) => void;
    containerId?: string;
    isDuplicate: boolean;
}

interface ContainerState extends WithFormValidationHelper {
    containerWriteView: ContainerWriteView;
    key: string;
    containerType: ContainerType[];
    selectedContainerType: string;
    containerView?: ContainerView;
    unitFromWriteView: Unit | undefined;
}

export class ContainerComponent extends ComponentBase<ContainerProperties, ContainerState> {
    @withFormValidationHelper<ContainerComponent>('validateContainer')
    protected _buildState(
        props: ContainerProperties,
        initialBuild: boolean,
        incomingState: ContainerState | undefined,
    ): Partial<ContainerState> | undefined {
        const newState: Partial<ContainerState> = {
            ...incomingState,
            containerWriteView: props.container,
            containerView: ContainerStore.getSelected(),
        };

        const unitStringFromWriteView = asUnitString(incomingState?.containerWriteView.unit ?? '');
        newState.unitFromWriteView = unitStringFromWriteView ? getUnitFromString(unitStringFromWriteView) : undefined;
        return newState;
    }

    validateContainer(formHelper: FormValidationHelper, prevState: ContainerState, props: ContainerProperties): void {
        if (prevState) {
            if (!prevState.containerWriteView.amount || prevState.containerWriteView.amount <= 0) {
                formHelper.setError('minAmount', props.t('offer:errorInvalidAmount'));
            }
        }
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.getContainerTypes();
        if (this.props.container) {
            this.setState({
                // pseudo uuid
                key: Math.random().toFixed(length),
            });
        } else {
            this.setState({
                // pseudo uuid
                key: Math.random().toFixed(length),
            });
        }
    }

    getContainerTypes(): void {
        SearchService.getContainerTypes().subscribe((results) => {
            this.setState({
                containerType: results.sort((a, b) =>
                    this.props.t('containertype:' + a.slug).localeCompare(this.props.t('containertype:' + b.slug)),
                ),
            });
        });
    }

    handleContainerTypeChange(selectedContainerType: string): void {
        this.setState(
            (state: ContainerState) => {
                const container = state.containerWriteView;
                container.containerType = selectedContainerType;
                return { containerWriteView: container };
            },
            () => this.props.onUpdate(),
        );
    }

    removeButton(): React.ReactElement | undefined {
        if (!this.props.remove) return;
        return (
            <IconButton
                className={this.props.classes.deleteButton}
                onClick={() => (this.props.remove ? this.props.remove(this.state.containerWriteView) : null)}
                size="large"
            >
                <DeleteIcon />
            </IconButton>
        );
    }

    handleContainerUnitChange(unit: Unit) {
        this.setState(
            (state: ContainerState) => {
                const container = state.containerWriteView;
                container.unit = unit.unit;
                return { containerWriteView: container };
            },
            () => this.props.onUpdate(),
        );
    }

    render(): React.ReactElement {
        return (
            <Grid container item md={12} spacing={1}>
                <Grid item md={4}>
                    <TextField
                        label={this.props.t('offer:container')}
                        select
                        className={
                            this.props.isDuplicate ? this.props.classes.errorField : this.props.classes.selectInput
                        }
                        value={this.state.containerWriteView.containerType}
                        onChange={(selectedOption) =>
                            this.handleContainerTypeChange(selectedOption.target.value as string)
                        }
                    >
                        {this.state.containerType &&
                            this.state.containerType.map((ct: ContainerType) => {
                                return (
                                    <MenuItem key={ct.id} value={ct.id}>
                                        {this.props.t('containertype:' + ct.slug)}
                                    </MenuItem>
                                );
                            })}
                    </TextField>
                </Grid>
                <Grid item md={2}>
                    <NumberFormat
                        label={this.props.t('common:size')}
                        className={
                            this.props.isDuplicate ? this.props.classes.errorField : this.props.classes.selectInput
                        }
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={3}
                        customInput={TextField}
                        value={this.state.containerWriteView.amount}
                        onValueChange={(event) => {
                            this.setState(
                                (state: ContainerState) => {
                                    const container = state.containerWriteView;
                                    container.amount = Number(event.value);
                                    return { containerWriteView: container };
                                },
                                () => this.props.onUpdate(),
                            );
                        }}
                        required={true}
                        inputProps={{
                            min: 0,
                        }}
                        {...this.state.formValidationHelper.getFormError('minAmount')}
                    />
                </Grid>
                <Grid item md={3}>
                    <UnitSelectComponent
                        selectedUnit={this.state.unitFromWriteView ?? this.props.offerUnit}
                        onChange={(selected) => this.handleContainerUnitChange(selected)}
                        typeToLimitTo={this.props.offerUnit?.unitType}
                        isRequired={true}
                        isDuplicate={this.props.isDuplicate}
                    />
                </Grid>
                <Grid item md={1}>
                    {this.removeButton()}
                </Grid>
                {this.props.isDuplicate ? (
                    <Typography className={this.props.classes.errorTypography}>
                        {this.props.t('offer:errorDuplicateContainer')}
                    </Typography>
                ) : null}
            </Grid>
        );
    }
}

export default withTranslation(['common', 'offer', 'containertype'])(
    withStyles(styles, { withTheme: true })(ContainerComponent),
);
