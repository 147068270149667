import { theme } from 'style/NearbuyStyle';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import * as React from 'react';
import { ComponentBase } from 'resub';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

interface SearchLinkState {
    from?: string;
}
interface BackButtonProps
    extends RouteComponentProps<Record<any, any>, StaticContext, SearchLinkState>,
        WithTranslation {
    className?: string;
}

class BackButtonComponent extends ComponentBase<BackButtonProps> {
    backToLastView(): void {
        this.props.history.goBack();
    }

    getBackButtonText(): string {
        switch (this.props.location.state.from) {
            case 'MY_MARKET':
                return this.props.t('common:<MyMarket');
            case 'MARKETPLACE':
                return this.props.t('common:<Marketplace');
            case 'COMPANIES':
                return this.props.t('common:<Companies');
            case 'PRESENTATION':
                return this.props.t('common:<Presentation');
            default:
                return this.props.t('dialogs:BACK');
        }
    }
    render(): React.ReactElement {
        return (
            <Button
                sx={{
                    color: theme.palette.primary.dark,
                    borderRadius: 2,
                    marginRight: theme.spacing(1),
                    fontWeight: 600,
                    fontSize: 18,
                    backgroundColor: theme.palette.primary.lighter,
                    paddingRight: '16px',
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                    },
                }}
                className={this.props.className}
                onClick={() => this.backToLastView()}
                variant="text"
            >
                <ChevronLeft />
                {this.getBackButtonText()}
            </Button>
        );
    }
}
export default withRouter(withTranslation(['common'])(BackButtonComponent));
