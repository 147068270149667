import { Card, CardActions, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import MessageService from 'api/MessageService';
import PurchaseIntentService from 'api/PurchaseIntentService';
import AmountAndPriceInfo from 'components/messaging/chat/messagefactory/infocards/shared/AmountAndPriceInfo';
import MarketItemTitle from 'components/messaging/chat/messagefactory/infocards/shared/MarketItemTitle';
import OfferFullDetailDialog from 'components/search/result-items/offers/OfferFullDetailDialog';
import { ContainerType } from 'model/ContainerView';
import { MetaStaticEntityType } from 'model/Meta';
import { MessageSentNotification, NotificationInfo } from 'model/NearbuyNotification';
import { Product } from 'model/Product';
import { PurchaseIntent, PurchaseIntentStatus } from 'model/PurchaseIntent';
import { now } from 'moment/moment';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import {
    CompanyStore,
    MetaStore,
    NotificationStore,
    PriceRequestStore,
    ProductStore,
    PurchaseIntentStore,
} from 'store';
import { PriceRequest } from 'model/PriceRequest';

const _styles = (theme: Theme) =>
    createStyles({
        content: {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        dot: {
            color: theme.palette.primary.main,
            margin: theme.spacing(1),
        },
        category: {
            textAlign: 'left',
            marginTop: theme.spacing(1),
            fontSize: 18,
            fontWeight: 'bold',
        },
        preOrderCard: {
            backgroundColor: theme.palette.background.default,
        },
        cardContent: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.default,
        },
        message: {
            borderRadius: '3px',
            padding: '0.5rem',
        },
        messageForeign: {
            border: '1px solid rgba(0, 0, 0, .12)',
        },
        messageOwn: {
            backgroundColor: theme.palette.primary.light,
        },
        messageRoot: {
            maxWidth: '75%',
            minWidth: '50%',
        },
        messageText: {
            overflowWrap: 'break-word',
            whiteSpace: 'pre-line',
        },
        button: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            color: theme.palette.common.black,
        },
        declineButton: {
            margin: theme.spacing(1),
            color: theme.palette.primary.dark,
        },
        plainCard: {
            border: 'none',
            boxShadow: 'none',
            '& .MuiCardActions-root': {
                padding: '0',
            },
        },
    });

interface PreOrderInfoCardProps extends WithTranslation, WithStyles<typeof _styles> {
    purchaseIntentRef?: string;
    priceRequestRef?: string;
    withShowOfferOrDetailsButton?: boolean;
    hasBackground?: boolean;
    onDecline: (value: boolean) => void;
}

interface PreOrderInfoCardState {
    currentCompanyRef?: string;
    purchaseIntent?: PurchaseIntent;
    priceRequest?: PriceRequest;
    product: Product;
    containerTypes: ContainerType[];
    isSeller: boolean;
    isDialogOpen: boolean;
}

class PreOrderInfoCard extends ComponentBase<PreOrderInfoCardProps, PreOrderInfoCardState> {
    protected _buildState(
        props: PreOrderInfoCardProps,
        initialBuild: boolean,
        incomingState?: Readonly<PreOrderInfoCardState>,
    ): Partial<PreOrderInfoCardState> | undefined {
        const newState: Partial<PreOrderInfoCardState> = {
            ...incomingState,
            currentCompanyRef: CompanyStore.getSelected()?.links.self,
        };

        if (initialBuild) {
            newState.isDialogOpen = false;
        }

        if (!props.purchaseIntentRef && !props.priceRequestRef) return newState;

        if (props.purchaseIntentRef) {
            PurchaseIntentStore.invalidateCache(props.purchaseIntentRef);
            newState.purchaseIntent = PurchaseIntentStore.getOne(props.purchaseIntentRef);
        }
        if (props.priceRequestRef) {
            PriceRequestStore.invalidateCache(props.priceRequestRef);
            newState.priceRequest = PriceRequestStore.getOne(props.priceRequestRef);
        }

        const metaContainerTypes = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);
        newState.containerTypes = (metaContainerTypes?.data ?? []) as ContainerType[];

        if ((!newState.purchaseIntent && !newState.priceRequest) || !newState.currentCompanyRef) return newState;

        const sellingCompanyRef =
            newState.purchaseIntent?.links.sellingCompany ?? newState.priceRequest!.links.sellingCompany;
        newState.isSeller = sellingCompanyRef == newState.currentCompanyRef;
        newState.product = ProductStore.getOne(
            newState.purchaseIntent?.links.category ?? newState.priceRequest?.links.category ?? '',
        );

        return newState;
    }

    showPurchaseIntentAcceptButton(): ReactNode {
        return (
            <Grid container direction={'row'} textAlign={'right'}>
                <Grid item xs={5} />
                <Grid item xs={7}>
                    <Button
                        variant="outlined"
                        className={this.props.classes.declineButton}
                        onClick={() => this.props.onDecline(true)}
                    >
                        {this.props.t('dialogs:DECLINE')}
                    </Button>
                    <Button
                        variant={'contained'}
                        className={this.props.classes.button}
                        onClick={() => {
                            PurchaseIntentService.updatePurchaseIntentStatus(this.state.purchaseIntent!.links.self, {
                                status: PurchaseIntentStatus.ACCEPTED,
                            }).subscribe({
                                next: (value) => {
                                    NotificationStore.setOne(new NotificationInfo(MessageSentNotification(), now()));
                                    MessageService.invalidateDialogByPreOrder(value, false);
                                },
                            });
                        }}
                    >
                        {this.props.t('dialogs:ACCEPT')}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    showButtons(): React.ReactNode {
        switch (true) {
            case !!this.state.purchaseIntent:
                if (this.state.isSeller && this.state.purchaseIntent!.status == PurchaseIntentStatus.PENDING)
                    return this.showPurchaseIntentAcceptButton();
                else return null;
            case !!this.state.priceRequest:
                return null;

            default:
                return null;
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.purchaseIntent && !this.state.priceRequest) {
            return null;
        }

        return (
            <>
                <OfferFullDetailDialog
                    offerRef={this.state.purchaseIntent?.links.offer ?? this.state.priceRequest!.links.offer}
                    isOpen={this.state.isDialogOpen || false}
                    onClose={() => {
                        this.setState({ isDialogOpen: false });
                    }}
                />
                <Card
                    className={`${
                        this.props.hasBackground ? this.props.classes.preOrderCard : this.props.classes.plainCard
                    }`}
                >
                    <CardContent className={this.props.classes.cardContent}>
                        <MarketItemTitle
                            purchaseIntentRef={this.props.purchaseIntentRef}
                            priceRequestRef={this.props.priceRequestRef}
                            offerRef={this.state.purchaseIntent?.links.offer ?? this.state.priceRequest?.links.offer}
                            productLabel={this.state.product?.label}
                            levelsOfProcessing={
                                this.state.purchaseIntent?.levelsOfProcessing ??
                                this.state.priceRequest?.levelsOfProcessing
                            }
                            hasButton={this.props.withShowOfferOrDetailsButton}
                        />
                        <AmountAndPriceInfo
                            purchaseIntent={this.state.purchaseIntent}
                            priceRequest={this.state.priceRequest}
                            isAmountContainerMismatchHidden={!this.state.isSeller}
                            isContainerSectionShown
                        />
                    </CardContent>
                    <CardActions>{this.showButtons()}</CardActions>
                </Card>
            </>
        );
    }
}

export default withTranslation('dialogs')(withStyles(_styles)(PreOrderInfoCard));
