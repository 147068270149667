import * as React from 'react';
import { ComponentBase } from 'resub';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NewCompanySystemMessage } from 'model/SystemMessage';
import { Person } from 'model/Person';
import { Company } from 'model/Company';
import { CompanyStore, PersonStore } from 'store';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface NewCompanySystemMessageItemProps extends WithTranslation, RouteComponentProps {
    systemMessage: NewCompanySystemMessage;
}

interface NewCompanySystemMessageItemState {
    mapping?: NewCompanySystemMessageItemMapping;
    newPerson?: Person;
    newCompany?: Company;
}

interface NewCompanySystemMessageItemMapping extends Record<string, string> {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    tenant: string;
}

class NewCompanySystemMessageItem extends ComponentBase<
    NewCompanySystemMessageItemProps,
    NewCompanySystemMessageItemState
> {
    protected _buildState(
        props: NewCompanySystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<NewCompanySystemMessageItemState>,
    ): Partial<NewCompanySystemMessageItemState> | undefined {
        const newPerson = PersonStore.getOne(props.systemMessage.links.person);
        const newCompany = CompanyStore.getOne(props.systemMessage.links.company);

        if (newPerson && newCompany) {
            return {
                newPerson: newPerson,
                newCompany: newCompany,
                mapping: {
                    firstName: newPerson.firstname,
                    lastName: newPerson.lastname,
                    email: newPerson.email,
                    company: newCompany.name,
                    tenant: this.props.t(`tenant:${newCompany.tenant}`),
                },
            };
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping || !this.state.newCompany || !this.state.newPerson) return null;

        return (
            <SystemMessageItem
                onClick={() =>
                    this.state.newCompany && this.props.history.push('/company/' + this.state.newCompany.id + '/detail')
                }
                systemMessage={this.props.systemMessage}
                mapping={this.state.mapping}
            />
        );
    }
}

export default withRouter(withTranslation(['system', 'tenant'])(NewCompanySystemMessageItem));
