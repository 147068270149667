import { Observable } from 'rxjs';
import { CompanyFeatures, Feature } from 'model/Feature';
import http from 'api/http';
import { map } from 'rxjs/operators';
import { CompanyStore } from 'store';
import { getUuidFromString } from 'util/helpers';

class FeatureService {
    public getFeatures(ref?: string): Observable<CompanyFeatures> {
        const ownCompany = CompanyStore.getSelected();
        const id = getUuidFromString(ref);
        return http
            .getAxios()
            .get<Feature[]>('/feature/' + (ownCompany && ownCompany.links.self === ref ? id : 'robinson'))
            .pipe(
                map((response) => response.data),
                map((features: Feature[]) => {
                    return {
                        companyOrPersonRef: ref,
                        features: new Set(features),
                    } as CompanyFeatures;
                }),
            );
    }
}

export default new FeatureService();
