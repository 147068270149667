import React from 'react';
import { ComponentBase } from 'resub';
import { Message } from 'model/Message';
import { CompanyStore, MessageStore, MessagesUnseenStore, PersonStore, SubstitutePersonStore } from 'store';
import MessageItemRenderer from 'components/messaging/chat/messagefactory/MessageItemRenderer';
import { Subscription, timer } from 'rxjs';
import MessageService from 'api/MessageService';
import OrderMessageItemFactory from 'components/messaging/chat/messagefactory/OrderMessageItemFactory';
import PurchaseIntentMessageItemFactory from 'components/messaging/chat/messagefactory/PurchaseIntentMessageItemFactory';
import PriceRequestMessageItemFactory from 'components/messaging/chat/messagefactory/PriceRequestMessageItemFactory';
import { SubstitutePerson } from 'model/SubstitutePerson';
import { MessageItemData } from 'components/messaging/chat/messagefactory/MessageItemData';
import RequestMessageItemFactory from 'components/messaging/chat/messagefactory/RequestMessageItemFactory';

export interface MessageItemFactoryProps {
    messageRef: string;
    onQuoteMessage: (message: Message) => void;
    isReadOnly: boolean;
}

interface MessageItemFactoryState {
    message: Message;
    quotedMessage?: Message;
    seenSubscription: Subscription;
    originalReceiver?: SubstitutePerson;
    originalSender?: SubstitutePerson;
}

class MessageItemFactoryFactory extends ComponentBase<MessageItemFactoryProps, MessageItemFactoryState> {
    protected _buildState(
        props: MessageItemFactoryProps,
        initialBuild: boolean,
        incomingState: Readonly<MessageItemFactoryState> | undefined,
    ): Partial<MessageItemFactoryState> | undefined {
        const newState: Partial<MessageItemFactoryState> = {
            ...incomingState,
        };

        if (!newState.message) {
            newState.message = MessageStore.getOne(props.messageRef);
        }
        if (newState.message) {
            // QUOTE
            if (newState.message.links.quote && !newState.quotedMessage) {
                newState.quotedMessage = MessageStore.getOne(newState.message.links.quote);
            }
            // SEEN-SUBSCRIPTION
            if (newState.message.seen && incomingState && incomingState.seenSubscription) {
                incomingState.seenSubscription.unsubscribe();
            }
            if (newState.message.links.originalReceiverPerson) {
                newState.originalReceiver = SubstitutePersonStore.getOne(newState.message.links.originalReceiverPerson);
            }
            if (newState.message.links.originalSenderPerson) {
                newState.originalSender = SubstitutePersonStore.getOne(newState.message.links.originalSenderPerson);
            }
        }

        return newState;
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if (this.state.seenSubscription) {
            this.state.seenSubscription.unsubscribe();
        }
    }

    componentDidMount(): void {
        super.componentDidMount();

        const company = CompanyStore.getSelected();

        if (
            company &&
            this.state.message &&
            !this.state.message.seen &&
            this.state.message.links.receiverPerson == PersonStore.getSelected()?.links.self
        ) {
            this.setState({
                seenSubscription: timer(2000).subscribe(() =>
                    MessageService.setSeen(this.props.messageRef).subscribe((_message: Message) => {
                        MessagesUnseenStore.loadOne(company.links.self);
                    }),
                ),
            });
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.message) return null;

        const contextEntity = this.state.message.contextType.toString().split('__')[0];

        const baseData: MessageItemData = {
            isLoaded: false,
            messageDirection: this.state.message.messageType,
            onQuoteMessage: this.props.onQuoteMessage,
            isReadOnly: this.props.isReadOnly,
            infoBox: {},
            messageBox: {
                topic: this.state.message.topic,
                content: this.state.message.content,
            },
            message: this.state.message,
            quotedMessage: this.state.quotedMessage,
            originalSender: this.state.originalSender,
            originalReceiver: this.state.originalReceiver,
        };

        switch (contextEntity) {
            case 'STANDARD':
            case 'QUOTE':
                return (
                    <MessageItemRenderer
                        data={{
                            ...baseData,
                            isLoaded: true,
                        }}
                    />
                );
            case 'PURCHASE_INTENT':
                return <PurchaseIntentMessageItemFactory baseData={baseData} />;
            case 'PRICE_REQUEST':
                return <PriceRequestMessageItemFactory baseData={baseData} />;
            case 'ORDER':
                return <OrderMessageItemFactory baseData={baseData} />;
            case 'REQUEST':
                return <RequestMessageItemFactory baseData={baseData} />;
            default:
                return null;
        }
    }
}

export default MessageItemFactoryFactory;
