import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Association } from 'model/Association';
import { DownloadLink, ImageInfo } from 'model/Image';
import { Membership } from 'model/Membership';
import * as React from 'react';
import { ReactNode } from 'react';
import { ComponentBase } from 'resub';
import { AssociationStore, ImageStore, MembershipStore } from 'store';

const _styles = () =>
    createStyles({
        image: {
            maxWidth: '100%',
            height: '72px',
            borderRadius: 4,
            objectFit: 'contain',
        },
    });

interface CompanyPresentationCertificationsProps extends WithStyles<typeof _styles> {
    membershipRef: string;
    withIcon?: boolean;
}

interface CompanyPresentationCertificationsState {
    anchorEl?: HTMLElement | null;
    association?: Association;
    downloadLink?: DownloadLink;
    logo?: ImageInfo;
    isPopoverOpen?: boolean;
    companyMembership?: Membership;
}

class CompanyPresentationCertificationsComponent extends ComponentBase<
    CompanyPresentationCertificationsProps,
    CompanyPresentationCertificationsState
> {
    protected _buildState(
        props: CompanyPresentationCertificationsProps,
        initialBuild: boolean,
    ): Partial<CompanyPresentationCertificationsState> | undefined {
        const newState: Partial<CompanyPresentationCertificationsState> = {};
        if (initialBuild) {
            newState.isPopoverOpen = false;
        }
        newState.companyMembership = MembershipStore.getOne(props.membershipRef);
        if (newState.companyMembership) {
            if (newState.companyMembership.links.association)
                newState.association = AssociationStore.getOne(newState.companyMembership.links.association);
        }
        if (newState.association && newState.association.links.icon) {
            newState.logo = ImageStore.getOne(newState.association.links.icon);
        }

        return newState;
    }

    render(): ReactNode {
        if (!this.state.companyMembership || !this.state.association) return null;
        return this.props.withIcon && this.state.logo ? (
            <img
                alt={this.state.logo.originalFilename}
                src={this.state.logo.source}
                className={this.props.classes.image}
            />
        ) : (
            <Typography fontWeight={600}>{this.state.association.description}</Typography>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(CompanyPresentationCertificationsComponent);
