import * as React from 'react';
import { List, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CompanyStore, EmploymentStore, PersonStore } from 'store';
import MessagePreviewItem from 'components/messaging/MessagePreviewItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { dialogSortFunction, DialogStore } from 'store/DialogStore';
import { Dialog, getDialogId } from 'model/Dialog';

const _styles = (theme: Theme) =>
    createStyles({
        hr: {
            margin: theme.spacing(0),
            opacity: 0.12,
            width: '100%',
        },
        foreignHeader: {
            fontWeight: 'bold',
            margin: theme.spacing(1),
        },
        navHeader: {
            marginTop: theme.spacing(1),
        },
        navDivider: {
            marginBottom: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
    });

interface DialogListProps extends WithStyles<typeof _styles>, WithTranslation {}

interface DialogListState {
    dialogs?: Readonly<Dialog[]>;
    isForeignInbox: boolean;
}

class DialogList extends ComponentBase<DialogListProps, DialogListState> {
    protected _buildState(
        props: DialogListProps,
        initialBuild: boolean,
        incomingState?: Readonly<DialogListState>,
    ): Partial<DialogListState> | undefined {
        const company = CompanyStore.getSelected();

        const formerEmployment = EmploymentStore.getSelectedFormerEmployment();

        const person = formerEmployment ? PersonStore.getOne(formerEmployment.links.person) : PersonStore.getSelected();

        if (!company || !person) {
            return undefined;
        }

        const dialogs = DialogStore.search({
            ownPersonRef: person.links.self,
            ownCompanyRef: company.links.self,
        }).slice();

        if (dialogs.length > 0 && !DialogStore.getSelected()) {
            DialogStore.setSelected(getDialogId(dialogs[0]));
        }

        if (dialogs.length === 0) {
            DialogStore.setSelected(undefined);
        }

        return {
            dialogs: dialogs,
            isForeignInbox: formerEmployment !== undefined,
        };
    }

    render(): React.ReactElement {
        return (
            <>
                <Typography variant="h5" className={this.props.classes.navHeader}>
                    {!this.state.isForeignInbox
                        ? this.props.t('messaging:header')
                        : this.props.t('messaging:foreignInboxHeader')}
                </Typography>
                <Divider className={this.props.classes.navDivider} />
                <List>
                    <Grid container>
                        {this.state.dialogs &&
                            this.state.dialogs
                                .slice()
                                .sort(dialogSortFunction)
                                .map((dialog) => (
                                    <Grid item container key={getDialogId(dialog)}>
                                        <MessagePreviewItem dialog={dialog} />
                                        <hr className={this.props.classes.hr} />
                                    </Grid>
                                ))}
                    </Grid>
                </List>
            </>
        );
    }
}

export default withTranslation('messaging')(withStyles(_styles, { withTheme: true })(DialogList));
