import * as React from 'react';
import { IconButton, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import { Message } from 'model/Message';
import Grid from '@mui/material/Grid';
import { classNames } from 'util/style-helpers';
import CloseIcon from '@mui/icons-material/Close';

const _styles = (theme: Theme) =>
    createStyles({
        button: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            transition: 'opacity .20s ease-in-out',
            '-moz-transition': 'opacity .20s ease-in-out',
            '-webkit-transition': 'opacity .20s ease-in-out',
            padding: theme.spacing(0),
            height: '30px',
            marginLeft: theme.spacing(1),
            width: '30px',
        },
        icon: {
            height: '20px',
            width: '20px',
        },
        lineClamp: {
            display: '-webkit-box',
            overflow: 'hidden',
            overflowWrap: 'break-word',
            '-webkit-box-orient': 'vertical',
        },
        quoteMessage: {
            border: '1px solid #E5E5E5',
            borderRadius: '0.3rem',
            backgroundColor: theme.palette.grey['200'],
            overflow: 'hidden',
            padding: '0.5rem',
            paddingRight: '40px',
            width: '100%',
        },
        quoteMessageContent: {
            '-webkit-line-clamp': 2,
        },
        quoteMessageTopic: {
            fontWeight: 'bold',
            '-webkit-line-clamp': 1,
        },
        wrapper: {
            display: 'flex',
            flexWrap: 'nowrap',
            marginBottom: theme.spacing(0.5),
        },
    });

interface QuoteReferenceProps extends WithStyles<typeof _styles> {
    quotedMessage?: Message;
    onAbort?: () => void;
}

class QuoteReference extends ComponentBase<QuoteReferenceProps> {
    render(): React.ReactElement | null {
        if (!this.props.quotedMessage) return null;

        return (
            <Grid container item sm={12} className={this.props.classes.wrapper}>
                <div className={this.props.classes.quoteMessage}>
                    <Typography
                        className={classNames(this.props.classes.lineClamp, this.props.classes.quoteMessageTopic)}
                    >
                        {this.props.quotedMessage.topic}
                    </Typography>
                    <Typography
                        className={classNames(this.props.classes.lineClamp, this.props.classes.quoteMessageContent)}
                    >
                        {this.props.quotedMessage.content}
                    </Typography>
                </div>
                {this.props.onAbort && (
                    <IconButton
                        aria-label={'abortQuote'}
                        color={'primary'}
                        onClick={(): void => this.props.onAbort && this.props.onAbort()}
                        className={this.props.classes.button}
                        size="large"
                    >
                        <CloseIcon className={this.props.classes.icon} />
                    </IconButton>
                )}
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(QuoteReference);
