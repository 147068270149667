import { ResultFormat, ResultItem } from 'model/ResultItem';
import qs from 'qs';
import { Observable } from 'rxjs';
import { Request, RequestWriteView, RequestWriteViewLegacy } from 'model/Request';
import http from 'api/http';
import { map, tap } from 'rxjs/operators';
import moment from 'moment';
import { CompanyRequestStore, RequestStore } from 'store';
import { convertDateOfProduct, convertRefsToUuids } from 'util/helpers';

export interface RequestSearchQuery {
    format?: ResultFormat;
    limit?: number;
    offset?: number;
    lat1?: number;
    lon1?: number;
    lat2?: number;
    lon2?: number;
    category?: string[];
    afterDate?: string;
    beforeDate?: string;
    amountMax?: number;
    amountMin?: number;
    amountUnit?: string;
    description?: string;
    companyName?: string;
    companies?: string[];
    showOnlyFavourites?: boolean;
    showOwnData?: boolean;
    includeDeactivated?: boolean;
}

class RequestService {
    public getRequest(requestRef: string): Observable<Request> {
        return http
            .getAxios()
            .get<Request>(`${requestRef}`)
            .pipe(
                map((value) => value.data),
                map((request) => convertDateOfProduct(request)),
                map((request) => {
                    request.dateFrom = moment(request.dateFrom);
                    request.dateEnd = moment(request.dateEnd);
                    return request;
                }),
            );
    }

    public searchRequestsAsRefs(queryParams: RequestSearchQuery): Observable<string[]> {
        const stringifiedQueryParams = qs.stringify(
            { ...queryParams, format: ResultFormat.REFERENCE },
            { arrayFormat: 'repeat', encode: true },
        );
        return http
            .getAxios()
            .get<string[]>(`/requests?${stringifiedQueryParams}`)
            .pipe(map((response) => response.data));
    }

    public searchRequestsAsResults(queryParams: RequestSearchQuery): Observable<ResultItem[]> {
        const stringifiedQueryParams = qs.stringify(
            { ...queryParams, format: ResultFormat.SEARCH_RESULT },
            { arrayFormat: 'repeat', encode: true },
        );
        return http
            .getAxios()
            .get<ResultItem[]>(`/requests?${stringifiedQueryParams}`)
            .pipe(
                map((response) => response.data),
                map((resultItems) => {
                    return resultItems.map((resultItem) => {
                        if (resultItem.product) {
                            resultItem.product.dateStart = moment(resultItem.product.dateStart);
                            resultItem.product.dateEnd = moment(resultItem.product.dateEnd);
                        }
                        return resultItem;
                    });
                }),
            );
    }

    getRequestIds(companyRef: string): Observable<string[]> {
        return http
            .getAxios()
            .get<Request[]>(companyRef + '/requests')
            .pipe(
                map((response) => response.data),
                map((requests) => {
                    for (const request of requests) {
                        convertDateOfProduct(request);
                    }
                    return requests;
                }),
                map((responses) => responses.map((response) => response.links.self)),
            );
    }

    addRequest(request: RequestWriteView, companyRef: string): Observable<Request> {
        const requestWriteView: RequestWriteView = {
            ...request,
            levelsOfProcessing: convertRefsToUuids(request.levelsOfProcessing),
        };
        const legacyRequestWriteView = RequestWriteViewLegacy.fromRequestWriteView(requestWriteView);

        return http
            .getAxios()
            .post<Request>(companyRef + '/requests', legacyRequestWriteView)
            .pipe(
                map((response) => response.data),
                map((request) => convertDateOfProduct(request)),
                tap((request) => RequestStore.setOne(request)),
                tap((_request) => CompanyRequestStore.loadSearched(companyRef)),
                tap((request) => RequestStore.loadSearched({ companies: [request.links.company] })),
            );
    }

    deleteRequest(requestDeleteRef: string, requestRef: string, companyRef: string): Observable<unknown> {
        return http
            .getAxios()
            .delete(requestDeleteRef)
            .pipe(
                tap(() => RequestStore.removeOneById(requestRef)),
                tap(() => CompanyRequestStore.loadSearched(companyRef)),
                tap(() => RequestStore.loadSearched({ companies: [companyRef] })),
            );
    }

    updateRequest(request: RequestWriteView, requestRef: string): Observable<Request> {
        const requestWriteView: RequestWriteView = {
            ...request,
            levelsOfProcessing: convertRefsToUuids(request.levelsOfProcessing),
        };
        const legacyRequestWriteView = RequestWriteViewLegacy.fromRequestWriteView(requestWriteView);
        return http
            .getAxios()
            .put<Request>(requestRef, legacyRequestWriteView)
            .pipe(
                map((resp) => resp.data),
                map((request) => convertDateOfProduct(request)),
                tap((request) => RequestStore.setOne(request)),
                tap((request) => CompanyRequestStore.setOne(request)),
            );
    }
}

export default new RequestService();
