import { theme } from 'style/NearbuyStyle';
import { SxProps } from '@mui/material';

export function getButtonVariant(
    type: 'transparent' | 'primaryLight' | 'secondaryMain' | undefined,
): 'contained' | 'outlined' {
    let variant: 'contained' | 'outlined';
    switch (type) {
        case 'transparent':
            variant = 'outlined';
            break;
        case 'secondaryMain':
        case 'primaryLight':
        case undefined:
            variant = 'contained';
            break;
    }
    return variant;
}

/** ##Note:
 * - For the styles to work consistent, the button should live inside a grid item.
 * - And if there are several buttons, the grid items should be in a grid container.
 * - If you don't want the default greenish-nearbuy-theme, pass a custom theme or the
 * theme of your component, so that the context-dependant colors are applied.
 *  */
export function getButtonStyle(type: 'transparent' | 'primaryLight' | 'secondaryMain' | undefined): SxProps {
    switch (type) {
        case 'transparent':
            return getTransparentMainButtonStyle();
        case 'secondaryMain':
            return getSecondaryMainButtonStyle();
        case 'primaryLight':
        case undefined:
            return getPrimaryMainButtonStyle();
    }
}
const getTransparentMainButtonStyle = () => {
    return {
        marginRight: theme.spacing(1),
    };
};

const getPrimaryMainButtonStyle = () => {
    return {
        marginRight: theme.spacing(1),
    };
};

const getSecondaryMainButtonStyle = () => {
    return {
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    };
};
