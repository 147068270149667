import { Autocomplete, Theme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { closest, distance } from 'fastest-levenshtein';
import { Product } from 'model/Product';
import * as React from 'react';
import { ReactElement } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { ProductStore } from 'store';
import ProductChildrenStore from 'store/ProductChildrenStore';
import { theme } from 'style/NearbuyStyle';
import { isNullOrUndefined } from 'util/helpers';

export declare interface ProductWithTranslation {
    value: Product;
    label: string;
}

const _styles = (theme: Theme) =>
    createStyles({
        searchField: {
            display: 'flex',
            flexGrow: 0.7,
            marginRight: theme.spacing(1.5),
            '& .disabled': {
                backgroundColor: theme.palette.primary.lighter,
                borderRadius: 5,
            },
        },
    });

interface OntofoodSearchFieldProps extends WithStyles<typeof _styles>, WithTranslation {
    value: Product | null | undefined;
    onChange: (selectedOption: ProductWithTranslation | null) => void;
    productType?: keyof Pick<Product, 'company' | 'market'>;
    readOnly?: boolean;
    required?: boolean;
    limitingProductRef?: string;
    hasErrors?: boolean;
    helperText?: string;
    label?: string;
}

interface OntofoodSearchFieldState {
    searchInput?: string;
    options: ProductWithTranslation[];
    selectedOption: ProductWithTranslation | null;
}

export class OntofoodSearchField extends ComponentBase<OntofoodSearchFieldProps, OntofoodSearchFieldState> {
    protected _buildState(
        props: OntofoodSearchFieldProps,
        initialBuild: boolean,
        incomingState?: Readonly<OntofoodSearchFieldState>,
    ): Partial<OntofoodSearchFieldState> | undefined {
        const newState: Partial<OntofoodSearchFieldState> = {
            ...incomingState,
            options: incomingState?.options ?? new Array<ProductWithTranslation>(),
            selectedOption: props.value ? this.toProductWithTranslation(props.value) : null,
        };

        const allProducts = props.limitingProductRef
            ? ProductChildrenStore.get(props.limitingProductRef)
            : ProductStore.getAll();

        let companyOnlyProducts: ProductWithTranslation[] | undefined = undefined;
        let marketProducts: ProductWithTranslation[] | undefined = undefined;
        let companyProducts: ProductWithTranslation[] | undefined = undefined;
        if (allProducts) {
            companyOnlyProducts = allProducts
                .filter((it) => it.company && !it.market)
                .map((it) => this.toProductWithTranslation(it));
            marketProducts = allProducts.filter((it) => it.market).map((it) => this.toProductWithTranslation(it));
            companyProducts = allProducts.filter((it) => it.company).map((it) => this.toProductWithTranslation(it));
        }

        if (props.productType == 'market') {
            if (!newState.searchInput && marketProducts) {
                newState.options = marketProducts.sort((a, b) => a.label.localeCompare(b.label));
            } else if (newState.searchInput && marketProducts && marketProducts.length) {
                const stringToCheck = this.applySpecialCasesAndToLowerCase(newState.searchInput);

                const directOptions = marketProducts
                    .filter((it) => it.label.toLowerCase().includes(stringToCheck))
                    .sort(
                        (a, b) =>
                            distance(stringToCheck, a.label.toLowerCase()) -
                            distance(stringToCheck, b.label.toLowerCase()),
                    );

                const closestDirectOptionLabel = closest(
                    stringToCheck,
                    directOptions.map((it) => it.label.toLowerCase()),
                );
                const closestDirectOptionDistance = closestDirectOptionLabel
                    ? distance(stringToCheck, closestDirectOptionLabel)
                    : undefined;

                const closestCompanyOnlyProduct = companyOnlyProducts
                    ? companyOnlyProducts
                          .filter(
                              (it, _, array) =>
                                  it.label.toLowerCase() ==
                                  closest(
                                      stringToCheck,
                                      array.map((it) => it.label.toLowerCase()),
                                  ),
                          )
                          .pop()
                    : undefined;
                const closestCompanyOnlyProductDistance = closestCompanyOnlyProduct
                    ? distance(stringToCheck, closestCompanyOnlyProduct.label.toLowerCase())
                    : undefined;

                const indirectOptions =
                    closestCompanyOnlyProduct &&
                    !isNullOrUndefined(closestCompanyOnlyProductDistance) &&
                    closestCompanyOnlyProductDistance! < 3
                        ? ProductChildrenStore.get(closestCompanyOnlyProduct.value.links.self)
                              ?.filter((it) => it.market)
                              ?.map((it) => this.toProductWithTranslation(it))
                              ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
                        : [];

                const optionsConcat = indirectOptions
                    ? closestDirectOptionDistance
                        ? closestDirectOptionDistance > 2
                            ? indirectOptions.concat(directOptions)
                            : directOptions.concat(indirectOptions)
                        : directOptions
                    : indirectOptions;

                newState.options = [];
                optionsConcat.forEach((option) => {
                    if (!newState.options!.some((it) => it.label == option.label)) newState.options!.push(option);
                });
            }
        } else if (props.productType == 'company') {
            newState.options = companyProducts?.sort((a, b) => a.label.localeCompare(b.label));
        } else {
            newState.options = allProducts
                ?.map((it) => this.toProductWithTranslation(it))
                .sort((a, b) => a.label.localeCompare(b.label));
        }

        return newState;
    }

    applySpecialCasesAndToLowerCase(searchInput: string): string {
        let value = searchInput.toLowerCase();
        switch (value) {
            case 'ei':
                value = 'eier';
                break;
            default:
                break;
        }
        return value;
    }

    toProductWithTranslation(product: Product): ProductWithTranslation {
        return {
            value: product,
            label: this.props.t('ontofood:' + product.label),
        };
    }

    handleChange = (selectedOption: ProductWithTranslation | null) => {
        this.props.onChange(selectedOption);
        if (selectedOption) this.setState({ searchInput: undefined });
    };

    isOptionSelected(option: ProductWithTranslation, value: ProductWithTranslation): boolean {
        return option.value.links.self === value.value.links.self;
    }

    updateSearchInput(searchInput: string): void {
        if (searchInput != this.state.selectedOption?.label) {
            if (this.props.value) this.props.onChange(null);
            this.setState({ searchInput });
        }
    }

    render(): React.ReactElement {
        return (
            <Autocomplete<ProductWithTranslation>
                disabled={this.props.readOnly}
                className={this.props.classes.searchField}
                id={'OntoAutoComplete'}
                value={this.state.selectedOption}
                options={this.state.options}
                filterOptions={this.props.productType == 'market' ? (x) => x : undefined}
                getOptionLabel={(option): string => option.label}
                onChange={(_, selectedOption) => this.handleChange(selectedOption)}
                onInputChange={(_, value) => this.updateSearchInput(value)}
                isOptionEqualToValue={this.isOptionSelected.bind(this)}
                noOptionsText={this.props.t('common:noOptions')}
                renderInput={(params): ReactElement => (
                    <TextField
                        data-testid={'ontofoodSearchFieldTextField'}
                        data-producttype={this.props.productType}
                        {...params}
                        sx={{
                            button: {
                                backgroundColor: 'transparent',
                                color: theme.palette.text.secondary,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: theme.palette.text.secondary,
                                },
                                '&:disabled': {
                                    backgroundColor: 'transparent',
                                    color: theme.palette.action.disabled,
                                },
                            },
                        }}
                        label={
                            (this.props.label ?? this.props.t('common:selectProduct')) +
                            (this.props.required ? ' *' : '')
                        }
                        error={this.props.hasErrors ?? false}
                        helperText={this.props.helperText ?? ''}
                    />
                )}
            />
        );
    }
}

export default withTranslation(['common', 'ontofood'])(withStyles(_styles, { withTheme: true })(OntofoodSearchField));
