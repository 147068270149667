import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import { CompanyStore, DialogStore, PersonStore } from 'store';
import SettingsLoader from 'components/initialization/initializers/SettingsLoader';
import { getDialogId } from 'model/Dialog';

export class DialogInitializer implements InitializationEventConsumer {
    async onInitializationEvent(_phase: InitializationPhase): Promise<void> {
        const personRef = PersonStore.getSelected()?.links.self;
        const companyRef = CompanyStore.getSelected()?.links.self;
        if (personRef && companyRef) {
            //TODO: Only load newest Dialog (when a limit filter is available for dialogs endpoint)
            const dialogs = DialogStore.search({
                ownCompanyRef: companyRef,
                ownPersonRef: personRef,
            });
            DialogStore.setAll(dialogs.slice());
            DialogStore.setSelected(getDialogId(DialogStore.getAll()[0]));
        }
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new DialogInitializer(),
    [SettingsLoader],
);
