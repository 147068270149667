import { forkJoin, Observable, of } from 'rxjs';
import http from 'api/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { CompanyStore, ConsolidatorStore } from 'store';
import { Consolidator, ConsolidatorWriteView } from 'model/Consolidator';
import { AxiosResponse } from 'axios';
import { Company } from 'model/Company';
import CompanyService from 'api/CompanyService';
import { ResultItem } from 'model/ResultItem';

class ConsolidatorService {
    addConsolidator(companyRef: string, companyConsolidator: ConsolidatorWriteView): Observable<Consolidator> {
        return http
            .getAxios()
            .post<Consolidator>(companyRef + '/consolidator', companyConsolidator)
            .pipe(
                map((response) => response.data),
                tap(() => ConsolidatorStore.invalidateSearch(companyRef)),
            );
    }

    getConsolidator(consolidatorRef: string): Observable<Consolidator> {
        return http
            .getAxios()
            .get<Consolidator>(`${consolidatorRef}`)
            .pipe(map((value) => value.data));
    }

    deleteConsolidator(consolidator: Consolidator): Observable<unknown> {
        return http
            .getAxios()
            .delete(`${consolidator.links.remove}`)
            .pipe(tap(() => ConsolidatorStore.removeOneById(consolidator.links.self)));
    }

    updateConsolidator(consolidator: ConsolidatorWriteView, consolidatorRef: string): Observable<Consolidator> {
        return http
            .getAxios()
            .put<Consolidator>(consolidatorRef, consolidator)
            .pipe(
                map((resp) => resp.data),
                tap((consolidator) => ConsolidatorStore.setOne(consolidator)),
            );
    }

    getCompanyConsolidators(companyRef: string): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>(companyRef + '/consolidator')
            .pipe(map((response) => response.data));
    }

    searchAllConsolidators(searchString?: string): Observable<(Company | undefined)[]> {
        return http
            .getAxios()
            .get<ResultItem[]>(
                `meta/search?role=CONSOLIDATOR&type=COMPANY` + (searchString ? '&name=' + searchString : ''),
            )
            .pipe(
                map((value: AxiosResponse<ResultItem[]>) => value.data),
                switchMap((options: ResultItem[]) =>
                    forkJoin(
                        options.length > 0
                            ? options.map((option) => {
                                  const company = CompanyStore.getOne(option.links.company);
                                  return !company ? CompanyService.getCompany(option.links.company) : of(company);
                              })
                            : [],
                    ),
                ),
            );
    }
}

export default new ConsolidatorService();
