import { Tab, Tabs, Theme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container, { ContainerProps } from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { PropsWithChildren, SyntheticEvent } from 'react';
import { ComponentBase } from 'resub';
import { theme } from 'style/NearbuyStyle';

const _styles = (theme: Theme) =>
    createStyles({
        content: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            padding: theme.spacing(3),
            paddingTop: theme.spacing(1),
        },
        tab: {
            textTransform: 'none',
            margin: 0,
            padding: 0,
            fontWeight: 600,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            fontSize: '16px',
            '&:hover': {
                color: theme.palette.primary.dark,
            },
            '&.Mui-selected': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                fontWeight: 'bold',
                color: theme.palette.primary.dark,
                underline: `1px solid ${theme.palette.primary.dark}`,
            },
        },
        headerContainer: {
            paddingBottom: `${theme.spacing(1)}`,
            paddingTop: `${theme.spacing(1.5)}`,
            paddingLeft: `${theme.spacing(3)}`,
            paddingRight: `${theme.spacing(3)}`,
        },
        title: {
            textTransform: 'capitalize',
            color: theme.palette.primary.dark,
            fontSize: '26px',
            fontWeight: 600,
        },
        cardContainer: {
            minWidth: '200px',
            padding: 0,
        },
    });

interface NearbuyTabsWindowProps extends WithStyles<typeof _styles>, PropsWithChildren<any> {
    handleChange: (event: SyntheticEvent, value: number) => void;
    titles: string[];
    title: string;
    activeTabIndex: number;
    maxWidth?: ContainerProps['maxWidth'];
}

interface NearbuyTabsWindowState {
    currentValue: number | false;
}

/**
 * Deprecated: Use NearbuyTabsWindow instead, this component can be removed once
 *             TradeTableComponent was refactored.
 */
class NearbuyTabsWindowOld extends ComponentBase<NearbuyTabsWindowProps, NearbuyTabsWindowState> {
    protected _buildState(
        props: NearbuyTabsWindowProps,
        initialBuild: boolean,
        incomingState: Readonly<NearbuyTabsWindowState>,
    ): Partial<NearbuyTabsWindowState> | undefined {
        const newState: Partial<NearbuyTabsWindowState> = {};
        newState.currentValue = initialBuild ? false : props.activeTabIndex;
        return newState;
    }

    componentDidMount() {
        super.componentDidMount();
        // tackles known bug which throws a console-error once after reload: https://github.com/mui/material-ui/issues/32749
        // the bug does no harm besides the one-time error -> so /ignore would be another option to this here
        setTimeout(() => this.setState({ currentValue: 0 }), 250);
    }

    onChange = (event: SyntheticEvent, value: number) => {
        this.props.handleChange(event, value);
    };

    showTabs(): React.ReactFragment {
        return (
            <Tabs
                value={this.state.currentValue}
                onChange={this.onChange}
                TabIndicatorProps={{
                    style: {
                        background: theme.palette.primary.dark,
                        display: 'none',
                    },
                }}
            >
                {this.props.titles.map((title) => {
                    return <Tab label={title} key={title} className={this.props.classes.tab} />;
                })}
            </Tabs>
        );
    }

    render(): React.ReactElement {
        return (
            <Container maxWidth={this.props.maxWidth} className={this.props.classes.cardContainer}>
                <Card
                    sx={{
                        maxWidth: 'xl',
                        borderRadius: '10px',
                        boxShadow: ' 0px 5px 10px 3px rgba(0, 0, 0, 0.05)',
                    }}
                >
                    <Grid
                        container
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className={this.props.classes.headerContainer}
                    >
                        <Grid item>
                            <Typography className={this.props.classes.title}>{this.props.title}</Typography>
                        </Grid>
                        <Grid item> {this.showTabs()}</Grid>
                    </Grid>
                    <CardContent className={this.props.classes.content}>{this.props.children}</CardContent>
                </Card>
            </Container>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(NearbuyTabsWindowOld);
