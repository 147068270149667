import { Observable } from 'rxjs';
import http from 'api/http';
import { map, tap } from 'rxjs/operators';
import { PriceRequest, PriceRequestStatusWriteView, PriceRequestWriteView } from 'model/PriceRequest';
import { Order, OrderWriteView } from 'model/Order';
import { OrderStore, PriceRequestStore } from 'store';
import MessageService from 'api/MessageService';

class PriceRequestService {
    public sendPriceRequest(priceRequestWriteView: PriceRequestWriteView): Observable<PriceRequest> {
        return http
            .getAxios()
            .post<PriceRequest>('/price_requests/', priceRequestWriteView)
            .pipe(
                map((response) => response.data),
                tap((priceRequest) => PriceRequestStore.setOne(priceRequest)),
            );
    }

    public getPriceRequest(priceRequestRef: string): Observable<PriceRequest> {
        return http
            .getAxios()
            .get(priceRequestRef)
            .pipe(map((response) => response.data));
    }

    public updateStatus(
        priceRequestRef: string,
        priceRequestStatus: PriceRequestStatusWriteView,
    ): Observable<PriceRequest> {
        return http
            .getAxios()
            .put(priceRequestRef + '/status', priceRequestStatus)
            .pipe(
                map((response) => response.data),
                tap((priceRequest) => PriceRequestStore.setOne(priceRequest)),
            );
    }

    public setPrice(priceRequestRef: string, priceRequestWriteView: PriceRequestWriteView): Observable<PriceRequest> {
        return http
            .getAxios()
            .put(priceRequestRef + '/price', priceRequestWriteView)
            .pipe(
                map((response) => response.data),
                tap((priceRequest) => {
                    PriceRequestStore.setOne(priceRequest);
                    MessageService.invalidateDialogByPreOrder(priceRequest, false);
                }),
            );
    }

    public sendOrder(orderWriteView: OrderWriteView, priceRequestRef: string): Observable<Order> {
        return http
            .getAxios()
            .post<Order>(priceRequestRef + '/orders', orderWriteView)
            .pipe(
                map((response) => response.data),
                tap((order) => OrderStore.setOne(order)),
            );
    }

    public getList(dateFrom: string, dateUntil: string): Observable<string[]> {
        return http
            .getAxios()
            .get<string[]>('price_requests', {
                params: {
                    dateFrom,
                    dateUntil: dateUntil,
                },
            })
            .pipe(map((response) => response.data));
    }
}

export default new PriceRequestService();
