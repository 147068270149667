import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { ReactNode } from 'react';
import { ComponentBase } from 'resub';
import {
    AssortmentStore,
    CompanyAssortmentStore,
    CompanyDemandStore,
    CompanyOfferStore,
    CompanyRequestStore,
    CompanyStore,
    DemandStore,
} from 'store';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Company } from 'model/Company';
import { ExtendedTheme, theme } from 'style/NearbuyStyle';
import createStyles from '@mui/styles/createStyles';
import { Assortment } from 'model/Assortment';
import ProductChip from 'components/product/ProductChip';
import { Dimension } from 'util/Types';
import { getDimensionFromVisualViewPort } from 'util/helpers';
import ShowMoreButton from 'components/shared/ShowMoreButton';
import MarketItemTable from 'components/marketplace/marketitem/table/MarketItemTable';
import { Demand } from 'model/Demand';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CompanyFeatureStore from 'store/FeatureStore';
import { Feature } from 'model/Feature';
import { InterpolationMap } from 'i18next';

const styles = (theme: ExtendedTheme) =>
    createStyles({
        adminStyle: {
            backgroundColor: theme.palette.secondary.lighter,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
    });

interface CompanyNameTranslationMapping extends InterpolationMap<string> {
    companyName: string;
}

interface CompanyMarketPresentationProps extends WithStyles<typeof styles>, WithTranslation {
    companyRef: string;
    type: 'Assortment' | 'Demand';
}

interface CompanyMarketPresentationState {
    isAdmin: boolean;
    ownCompanyRef: string;
    company: Company;
    assortments: Assortment[];
    demands: Demand[];
    maxChips: number;
    appSize: Dimension;
    isShowMoreExpanded: boolean;
    hasCompanyActiveMarketItems: boolean;
    translationMapping: CompanyNameTranslationMapping;
}

class CompanyMarketPresentation extends ComponentBase<CompanyMarketPresentationProps, CompanyMarketPresentationState> {
    protected _buildState(
        props: CompanyMarketPresentationProps,
        initialBuild: boolean,
        incomingState?: CompanyMarketPresentationState,
    ): Partial<CompanyMarketPresentationState> | undefined {
        const newState: Partial<CompanyMarketPresentationState> = {
            ...incomingState,
            company: CompanyStore.getOne(props.companyRef),
            ownCompanyRef: CompanyStore.getSelected()?.links.self,
            hasCompanyActiveMarketItems:
                props.type == 'Assortment'
                    ? !!CompanyOfferStore.search(props.companyRef).filter((it) => it.active).length
                    : !!CompanyRequestStore.search(props.companyRef).filter((it) => it.active).length,
            isAdmin: CompanyFeatureStore.hasPermission(new Set([Feature.BE_ADMIN]), 'allOf') ?? false,
        };

        if (newState.company) {
            newState.translationMapping = { companyName: newState.company.name };
        }

        if (initialBuild) {
            newState.appSize = getDimensionFromVisualViewPort(window.visualViewport);
            newState.isShowMoreExpanded = false;
        }

        if (newState.appSize) {
            newState.maxChips = this.getMaxChipsFromAppSize(newState.appSize);
        }

        if (props.type == 'Assortment') {
            newState.assortments = CompanyAssortmentStore.getOne(props.companyRef)
                ?.assortments.map((it) => AssortmentStore.getOne(it))
                .filter((it): it is Assortment => it != null);
        }

        if (props.type == 'Demand') {
            newState.demands = CompanyDemandStore.getOne(props.companyRef)
                ?.demands.map((it) => DemandStore.getOne(it))
                .filter((it): it is Demand => it != null);
        }

        return newState;
    }

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    handleResize() {
        this.setState({ appSize: getDimensionFromVisualViewPort(window.visualViewport) });
    }

    handleShowMoreButtonOnClick() {
        this.setState({
            isShowMoreExpanded: !this.state.isShowMoreExpanded,
        });
    }

    getMaxChipsFromAppSize(size: Dimension): number {
        const divisor = 50 + (size.width / 100 - 5);
        return Math.floor(size.width / divisor);
    }

    getChips(productRefs: string[], isChipOverflow: boolean): ReactNode {
        return (
            isChipOverflow && !this.state.isShowMoreExpanded ? productRefs.slice(0, this.state.maxChips) : productRefs
        ).map((it) => (
            <Grid item key={it}>
                <ProductChip
                    key={it}
                    productRef={it}
                    type={'company'}
                    color={this.props.type == 'Demand' ? 'primary' : 'secondary'}
                />
            </Grid>
        ));
    }

    showAssortmentOrDemandChips(): ReactNode {
        const productRefs =
            (this.props.type == 'Assortment' ? this.state.assortments : this.state.demands)?.map(
                (it) => it.links.category,
            ) ?? [];

        const isChipOverflow = productRefs.length > this.state.maxChips;
        const chips = this.getChips(productRefs, isChipOverflow);

        return (
            <Grid container item direction={'column'}>
                <Grid container item justifyContent={'space-between'}>
                    <Grid item>
                        <Typography
                            data-testid={`title${this.props.type}`}
                            sx={{
                                fontSize: '22px',
                                fontWeight: 600,
                                color: theme.palette.primary.dark,
                                marginBottom: 2,
                            }}
                        >
                            {' '}
                            {this.props.t(`company:${this.props.type.toLowerCase()}`)}{' '}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link
                            to={{
                                pathname: `/marketplace/${this.state.company?.id}/${this.props.type.toLowerCase()}`,
                                state: { from: 'PRESENTATION' },
                            }}
                            hidden={this.props.companyRef != this.state.ownCompanyRef && !this.state.isAdmin}
                        >
                            <IconButton
                                className={
                                    this.props.companyRef != this.state.ownCompanyRef && this.state.isAdmin
                                        ? this.props.classes.adminStyle
                                        : undefined
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </Link>
                    </Grid>
                </Grid>
                {productRefs.length ? (
                    <Grid container item direction={'row'}>
                        {chips}
                        {isChipOverflow && (
                            <ShowMoreButton onClick={this.handleShowMoreButtonOnClick.bind(this)} type={'items'} />
                        )}
                    </Grid>
                ) : (
                    <Grid item>
                        <Typography
                            data-testid={`title${this.props.type == 'Assortment' ? 'Offers' : 'Requests'}`}
                            sx={{
                                fontSize: '18px',
                                fontWeight: 600,
                                color: theme.palette.primary.dark,
                                textAlign: 'center',
                                margin: '0 2% 0 2%',
                            }}
                        >
                            {this.props.t(
                                `company:${
                                    this.props.type == 'Assortment'
                                        ? 'presentationAssortmentEmptyStateText'
                                        : 'presentationDemandEmptyStateText'
                                }`,
                                this.state.translationMapping,
                            )}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    }

    showActiveCompanyMarketItemTable(): ReactNode {
        return (
            <Grid container item direction={'column'} spacing={2}>
                <Grid container item justifyContent={'space-between'}>
                    <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography
                            data-testid={`title${this.props.type == 'Assortment' ? 'Offers' : 'Requests'}`}
                            sx={{
                                fontSize: '22px',
                                fontWeight: 600,
                                color: theme.palette.primary.dark,
                                marginBottom: 2,
                            }}
                        >
                            {' '}
                            {this.props.t(
                                `offer:${this.props.type == 'Assortment' ? 'offer_plural' : 'request_plural'}`,
                            )}{' '}
                        </Typography>
                        <Link
                            to={{
                                pathname: `/marketplace/${this.state.company?.id}/${
                                    this.props.type == 'Assortment' ? 'offers' : 'requests'
                                }`,
                                state: { from: 'PRESENTATION' },
                            }}
                            hidden={this.props.companyRef != this.state.ownCompanyRef && !this.state.isAdmin}
                        >
                            <IconButton
                                className={
                                    this.props.companyRef != this.state.ownCompanyRef && this.state.isAdmin
                                        ? this.props.classes.adminStyle
                                        : undefined
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container item>
                    {this.state.hasCompanyActiveMarketItems ? (
                        <MarketItemTable
                            active
                            readOnly
                            companyRef={this.props.companyRef}
                            type={this.props.type == 'Assortment' ? 'Offer' : 'Request'}
                        />
                    ) : (
                        <Typography
                            data-testid={`title${this.props.type == 'Assortment' ? 'Offers' : 'Requests'}`}
                            sx={{
                                fontSize: '18px',
                                fontWeight: 600,
                                color: theme.palette.primary.dark,
                                textAlign: 'center',
                                margin: '0 2% 0 2%',
                            }}
                        >
                            {this.props.t(
                                `company:${
                                    this.props.type == 'Assortment'
                                        ? 'presentationOffersEmptyStateText'
                                        : 'presentationRequestsEmptyStateText'
                                }`,
                                this.state.translationMapping,
                            )}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    }

    render(): ReactNode {
        if (!this.state.company) return null;
        return (
            <Grid container direction={'column'} spacing={4}>
                {this.showAssortmentOrDemandChips()}
                {this.showActiveCompanyMarketItemTable()}
            </Grid>
        );
    }
}

export default withTranslation(['company', 'offer'])(
    withStyles(styles, { withTheme: true })(CompanyMarketPresentation),
);
