import MenuItem from '@mui/material/MenuItem';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import http from 'api/http';
import { Company } from 'model/Company';
import { Employment, EmploymentStatus } from 'model/Employment';
import * as React from 'react';
import { ComponentBase } from 'resub';
import {
    AddressStore,
    CompanyStore,
    EmploymentStore,
    LocalStorageSettingsStore,
    MessageStore,
    MessagesUnseenStore,
    PersonEmploymentStore,
} from 'store';
import CompanyFeatureStore from 'store/FeatureStore';
import { SettingsKey } from 'store/LocalStorageSettingsStore';
import CompanySelection from 'components/company/CompanySelection';
import { Theme } from '@mui/material/styles';
import { Store } from '@mui/icons-material';
import { theme } from 'style/NearbuyStyle';

const _styles = (theme: Theme) =>
    createStyles({
        companySelect: {
            backgroundColor: theme.palette.primary.lighter,
            minWidth: '100px',
            width: 'auto',
            maxWidth: '300px',
            borderRadius: '8px',
            paddingLeft: theme.spacing(1),
            padding: theme.spacing(0.5),
            paddingRight: theme.spacing(1),
        },
        '@media (min-width: 1400px)': {
            companySelect: {
                maxWidth: '400px',
            },
        },
        '@media (max-width: 1000px)': {
            companySelect: {
                width: 'auto',
                maxWidth: '120px',
            },
        },
    });

interface CurrentCompanySwitchProps extends WithStyles<typeof _styles>, RouteComponentProps {}

interface CurrentCompanySwitchState {
    ownEmployments?: Employment[];
    companies?: Company[];
    currentCompany?: Company;
    currentEmployment?: Employment;
}

class CurrentCompanySwitch extends ComponentBase<CurrentCompanySwitchProps, CurrentCompanySwitchState> {
    protected _buildState(): Partial<CurrentCompanySwitchState> | undefined {
        const newState: Partial<CurrentCompanySwitchState> = {
            ownEmployments: PersonEmploymentStore.getSelected()?.employments.filter(
                (employment) => employment.status != EmploymentStatus.TERMINATED,
            ),
            currentCompany: CompanyStore.getSelected(),
            currentEmployment: EmploymentStore.getSelected(),
        };
        if (newState.ownEmployments) {
            newState.companies = newState.ownEmployments
                .filter(
                    (employment) =>
                        employment.status === EmploymentStatus.EMPLOYEE ||
                        employment.status === EmploymentStatus.MANAGER,
                )
                .map((employment) => CompanyStore.getOne(employment.links.company))
                .filter((value) => value !== undefined) as Company[];
        }
        if (!newState.currentCompany && newState.currentEmployment) {
            const company = CompanyStore.getOne(newState.currentEmployment.links.company);
            if (company) {
                newState.currentCompany = company;
            }
        }
        return newState;
    }

    handleChange = (event: SelectChangeEvent): void => {
        const matchingEmployment = this.state.ownEmployments?.filter(
            (employment) => employment.links.company == event.target.value,
        )[0];
        if (matchingEmployment) EmploymentStore.setSelected(matchingEmployment.links.self);
        if (
            matchingEmployment &&
            matchingEmployment.status != EmploymentStatus.MANAGER &&
            matchingEmployment.status != EmploymentStatus.EMPLOYEE
        ) {
            this.setState({
                currentCompany: CompanyStore.getOne(matchingEmployment.links.company),
            });
            return;
        }
        const newCompany = event.target.value as string;
        CompanyStore.setSelected(newCompany);
        const companyToSelect = CompanyStore.getOne(newCompany);
        http.setCurrentCompany(companyToSelect?.id);
        if (companyToSelect) {
            LocalStorageSettingsStore.setOne({
                key: SettingsKey.SELECTED_COMPANY,
                value: newCompany,
            });
            LocalStorageSettingsStore.persist();
        }
        // get features for company if not existing
        CompanyFeatureStore.setSelected(newCompany);
        newCompany && CompanyFeatureStore.getOne(newCompany);
        // set address
        AddressStore.setSelected(
            companyToSelect && companyToSelect.address !== '' ? companyToSelect.address : undefined,
        );
        MessageStore.invalidateCache();
        MessagesUnseenStore.invalidateCache();
        this.props.history.replace('/');
    };

    render(): React.ReactElement | null {
        if (this.state.ownEmployments && this.state.ownEmployments.length <= 1) return null;
        return (
            <>
                <CompanySelection />
                <Select
                    startAdornment={<Store sx={{ color: theme.palette.primary.dark }} />}
                    variant={'standard'}
                    value={this.state.currentCompany?.links.self || ''}
                    onChange={this.handleChange}
                    className={this.props.classes.companySelect}
                >
                    {this.state.companies?.map((company) => {
                        return (
                            <MenuItem
                                sx={{ maxWidth: '400px', whiteSpace: 'pre-wrap' }}
                                key={company.id}
                                value={company.links.self}
                            >{`${company.name} ${company.legalstatus}`}</MenuItem>
                        );
                    })}
                </Select>
            </>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(withRouter(CurrentCompanySwitch));
