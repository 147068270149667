import http from 'api/http';
import { map } from 'rxjs/operators';
import { Verification } from 'model/Verification';
import { Observable } from 'rxjs';
import moment from 'moment';

const dateDeserializer = map((verification: Verification) => {
    if (verification.links.downloadLink?.expiresAt) {
        verification.links.downloadLink.expiresAt = moment.utc(
            verification.links.downloadLink.expiresAt as unknown as string,
        );
    }
    return verification;
});

class VerificationService {
    public getVerification(verificationRef: string): Observable<Verification> {
        return http
            .getAxios()
            .get(verificationRef)
            .pipe(
                map((response) => response.data),
                dateDeserializer,
            );
    }

    public setVerificationTextValue(companyId: string, verification: Verification): Observable<Verification> {
        return http
            .getAxios()
            .post<Verification>(`companies/${companyId}/verification/text`, {
                ...verification,
                companyId: companyId,
            })
            .pipe(
                map((response) => response.data),
                dateDeserializer,
            );
    }

    uploadVerificationFile(companyId: string, file: Blob, fileName: string): Observable<Verification> {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const formData = new FormData();
        formData.append('file', file, fileName);
        formData.append('fileName', fileName);

        return http
            .getAxios()
            .post<Verification>(`companies/${companyId}/verification/file`, formData, config)
            .pipe(
                map((response) => response.data),
                dateDeserializer,
            );
    }
}

export default new VerificationService();
