import * as React from 'react';
import { ComponentBase } from 'resub';
import { AuthStore, CompanyStore } from 'store';
import { Company } from 'model/Company';
import ConsoleLineBuffer, { ConsoleLine } from 'components/usersnap/ConsoleLineBuffer';
import ConsoleLogInterceptor from 'components/usersnap/ConsoleLogInterceptor';
import { captureWebEvent } from 'util/AnalyticUtils';

type UsersnapProps = Record<string, unknown>;

interface UsersnapState {
    userId: string;
    userMail: string;
    currentCompany: Company;
    consoleOutput: readonly ConsoleLine[];
}

declare global {
    interface Window {
        Usersnap: any;
        onUsersnapCXLoad: (api: any) => void;
    }
}

/**
 * For a documentation of the api, have a look at: https://help.usersnap.com/docs/api-for-usersnap-project
 */
class Usersnap extends ComponentBase<UsersnapProps, UsersnapState> {
    protected _buildState(
        props: UsersnapProps,
        initialBuild: boolean,
        incomingState?: Readonly<UsersnapState>,
    ): Partial<UsersnapState> | undefined {
        return {
            currentCompany: CompanyStore.getSelected(),
            userId: AuthStore.getId(),
            userMail: AuthStore.getMail(),
            consoleOutput: ConsoleLineBuffer.getAllLines(),
        };
    }

    componentDidMount(): void {
        super.componentDidMount();

        ConsoleLogInterceptor.startIntercepting();

        window.onUsersnapCXLoad = (api: any) => {
            api.init({
                custom: {
                    company: this.state.currentCompany?.name,
                    companyId: this.state.currentCompany?.id,
                },
                user: {
                    userId: this.state.userId,
                    email: this.state.userMail,
                },
            });
            api.on('open', (event: any) => {
                captureWebEvent('give-us-feedback-button');
                event.api.setValue('custom', {
                    company: this.state.currentCompany?.name,
                    companyId: this.state.currentCompany?.id,
                    consoleOutput: this.state.consoleOutput.filter((line) => line !== null),
                });
            });

            window.Usersnap = api;
            api.show(process.env.REACT_APP_USERSNAP_API_KEY);
        };
        const script = document.createElement('script');
        script.defer = true;
        script.src = `https://widget.usersnap.com/global/load/${process.env.REACT_APP_USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        window.Usersnap.destroy();
        ConsoleLogInterceptor.stopIntercepting();
    }

    render(): React.ReactElement | null {
        return null;
    }
}

export default Usersnap;
