import LevelOfProcessingService from 'api/LevelOfProcessingService';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';

@AutoSubscribeStore
class LevelOfProcessingStoreClass extends StoreBase {
    protected levelOfProcessingMap: Map<string, LevelOfProcessing> = new Map<string, LevelOfProcessing>();

    @autoSubscribe
    getAll(): LevelOfProcessing[] | undefined {
        if (this.levelOfProcessingMap.size > 0) {
            return Array.from(this.levelOfProcessingMap.values());
        } else {
            this.fetchAll();
            return undefined;
        }
    }

    private fetchAll() {
        const newLopMap = new Map<string, LevelOfProcessing>();
        LevelOfProcessingService.listLevelsOfProcessing().subscribe((levelsOfProcessing) => {
            levelsOfProcessing.forEach((lop) => {
                newLopMap.set(lop.links.self, lop);
            });
            this.levelOfProcessingMap = newLopMap;
            this.trigger();
        });
    }

    @autoSubscribe
    getOne(levelOfProcessingRef: string): LevelOfProcessing | undefined {
        if (this.levelOfProcessingMap.size > 0) {
            return this.levelOfProcessingMap.get(levelOfProcessingRef);
        } else {
            this.fetchAll();
            return undefined;
        }
    }

    setOne(levelOfProcessing: LevelOfProcessing): void {
        this.levelOfProcessingMap.set(levelOfProcessing.links.self, levelOfProcessing);
        this.trigger();
    }

    invalidateCache() {
        this.fetchAll();
    }

    clear() {
        this.levelOfProcessingMap = new Map<string, LevelOfProcessing>();
        this.trigger();
    }
}

export const LevelOfProcessingStore = new LevelOfProcessingStoreClass();
export default LevelOfProcessingStore;
