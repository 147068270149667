import { Moment } from 'moment/moment';

export enum ImageType {
    COMPANY_MAIN = 'COMPANY_MAIN',
    COMPANY_LOGO = 'COMPANY_LOGO',
    PERSON_PORTRAIT = 'PERSON_PORTRAIT',
    MISC = 'MISC',
}

export class Clipping {
    constructor(
        public xOffset: number,
        public yOffset: number,
        public height: number,
        public width: number,
    ) {}
}

export class ImageLinks {
    constructor(
        public self: string,
        public remove: string,
    ) {}
}

export class ImageInfo {
    constructor(
        public id: string,
        public originalFilename: string,
        public uploadedAt: Moment,
        public source: string,
        public type: ImageType,
        public clipping: Clipping | null,
        public ord: number,
        public links: ImageLinks,
        public validUntil?: Date,
    ) {}
}

export class ImageOrdering {
    constructor(
        public imageId: string,
        public ord: number,
    ) {}
}

export class DownloadLink {
    constructor(
        public url: string,
        public expiresAt: string,
    ) {}
}

export interface DownloadLinkWithId {
    id: string;
    url: string;
    expiresAt: string;
}

export class PersonPortraits {
    images: ImageInfo[];

    constructor(
        public userId: string,
        images: ImageInfo[],
    ) {
        this.images = images.sort((a, b) => b.uploadedAt.diff(a.uploadedAt));
    }

    public getLatestImage(): ImageInfo | null {
        if (this.images.length === 0) return null;
        return this.images[0];
    }
}

export class CompanyImages {
    constructor(
        public companyId: string,
        public images: ImageInfo[],
    ) {
        this.sortImagesByOrd();
    }

    sortImagesByOrd(): void {
        this.images.sort((a, b) => a.ord - b.ord);
    }

    getImageOrdering(): ImageOrdering[] {
        this.sortImagesByOrd();
        return this.images.map((value, index) => new ImageOrdering(value.id, index));
    }
}
