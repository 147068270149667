import * as React from 'react';
import { NotificationInfo } from 'model/NearbuyNotification';
import { NearbuySnackBarItem } from 'components/notification/NearbuySnackBarItem';
import MessageSnackBarAction from 'components/notification/message/MessageSnackBarAction';
import i18n from 'i18next';

class MessageSnackBarItem implements NearbuySnackBarItem {
    getAction(notificationInfo: NotificationInfo, closeAction: () => void): React.ReactElement | null {
        return <MessageSnackBarAction messageInfo={notificationInfo} handleClose={closeAction} />;
    }

    getContent(_notificationInfo: NotificationInfo): React.ReactElement | string | null {
        return i18n.t('notification:newMessage');
    }
}

export default new MessageSnackBarItem();
