export class Membership {
    constructor(
        public id: string,
        public inTransition: boolean,
        public links: MembershipLinks,
        public description: string | null,
        public validUntil?: string,
    ) {}
}

export class CompanyMemberships {
    constructor(
        public companyRef: string,
        public memberships: string[],
    ) {}
}

export class MembershipWriteView {
    constructor(
        public inTransition: boolean,
        public association?: string,
        public description?: string | null,
        public certificate?: string,
        public validUntil?: string,
    ) {}
}

export class MembershipLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public company: string,
        public association?: string,
        public certificate?: string,
    ) {}
}

export function copy(membership?: Membership): Membership {
    if (membership) {
        return new Membership(membership.id, membership.inTransition, membership.links, membership.description);
    } else {
        return new Membership('', false, new MembershipLinks('', '', '', ''), null);
    }
}
