import * as React from 'react';
import { NotificationInfo } from 'model/NearbuyNotification';
import { NearbuySnackBarItem } from 'components/notification/NearbuySnackBarItem';
import OrderSnackbarAction from 'components/notification/order/OrderSnackbarAction';

class OrderSnackbarItem implements NearbuySnackBarItem {
    getAction(notificationInfo: NotificationInfo, closeAction: () => void): React.ReactElement | null {
        return (
            <OrderSnackbarAction
                info={notificationInfo}
                isSeller={notificationInfo.notification.isSeller!}
                isOrderCompleted={notificationInfo.notification.isOrderCompleted!}
                handleClose={closeAction}
            />
        );
    }

    getContent(notificationInfo: NotificationInfo): React.ReactElement | string | null {
        return notificationInfo.notification.value;
    }
}

export default new OrderSnackbarItem();
