import { DynamicLoadingStore } from 'resub-entity';
import { Employment } from 'model/Employment';
import EmploymentService from 'api/EmploymentService';
import { autoSubscribeWithKey } from 'resub';

const FORMER_EMPLOYEE = 'FORMER_EMPLOYEE_INBOX';

class EmploymentStoreClass extends DynamicLoadingStore<Employment, string> {
    protected selectedFormerEmployment?: Employment;

    @autoSubscribeWithKey(FORMER_EMPLOYEE)
    getSelectedFormerEmployment(): Employment | undefined {
        return this.selectedFormerEmployment;
    }

    setSelectedFormerEmployment(employment?: Employment) {
        this.selectedFormerEmployment = employment;
        this.trigger(FORMER_EMPLOYEE);
    }
}

export const EmploymentStore = new EmploymentStoreClass({
    selectIdFunction: (employment: Employment) => employment.links.self,
    loadFunction: (ref: string) => EmploymentService.getEmployment(ref),
});
