import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { ComponentBase } from 'resub';
import { Person } from 'model/Person';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CompanyEmploymentsStore, EmploymentStore, PersonStore } from 'store';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { DialogStore } from 'store/DialogStore';
import TextField from '@mui/material/TextField';
import { theme } from 'style/NearbuyStyle';
import EmploymentService from 'api/EmploymentService';
import { Employment, EmploymentStatus } from 'model/Employment';
import DialogTitle from '@mui/material/DialogTitle';
import { getUuidFromString } from 'util/helpers';

interface ReassignmentDialogProps extends WithTranslation {
    isOpen: boolean;
    onClose: (save: boolean) => void;
    employment: Employment;
}

interface ReassignmentDialogState {
    employees?: (Person | undefined)[];
    employments?: (Employment | undefined)[];
    selectedEmploymentRef?: string;
}

class ReassignmentDialog extends ComponentBase<ReassignmentDialogProps, ReassignmentDialogState> {
    protected _buildState(): Partial<ReassignmentDialogState> | undefined {
        const companyEmployments = CompanyEmploymentsStore.getOne(this.props.employment.links.company);
        if (companyEmployments) {
            const employments = companyEmployments.employees.map((employmentRef) =>
                EmploymentStore.getOne(employmentRef),
            );
            return {
                employees: employments
                    .filter(
                        (employment) =>
                            employment?.status === EmploymentStatus.MANAGER ||
                            employment?.status === EmploymentStatus.EMPLOYEE,
                    )
                    .map((employment) => {
                        if (employment) {
                            return PersonStore.getOne(employment.links.person);
                        } else {
                            return undefined;
                        }
                    }),
                employments,
            };
        }
    }

    save(): void {
        if (!this.state.selectedEmploymentRef) return;
        const companyId = getUuidFromString(this.props.employment.links.company);
        if (companyId) {
            EmploymentService.transferResponsibilities(
                this.props.employment.links.self,
                this.state.selectedEmploymentRef,
                companyId,
            ).subscribe((status) => {
                if (status !== 200) return;
                DialogStore.invalidateCache();
                EmploymentStore.invalidateSearch();
                EmploymentStore.invalidateCache(this.props.employment.links.self);
                this.props.onClose(true);
            });
        }
    }

    render(): React.ReactElement | null {
        if (!this.props.employment || !this.state.employees) {
            return null;
        }

        return (
            <Dialog open={this.props.isOpen} maxWidth="sm" fullWidth>
                <DialogTitle>{this.props.t('dialogs:transferResponsibilities')}</DialogTitle>
                <DialogContent>
                    <Grid container direction={'column'} alignContent={'space-between'}>
                        <Grid item sm={12} sx={{ marginBottom: theme.spacing(1) }}>
                            <Typography variant="body1">
                                {this.props.t('dialogs:transferResponsibilitiesText')}
                            </Typography>
                        </Grid>
                        <Grid item container sm={12} justifyContent={'center'}>
                            <TextField
                                select
                                label={this.props.t('common:EMPLOYEE')}
                                sx={{
                                    display: 'flex',
                                    minWidth: 120,
                                    paddingLeft: theme.spacing(1),
                                    flexGrow: 1,
                                }}
                                onChange={(event): void =>
                                    this.setState({
                                        selectedEmploymentRef: this.state.employments?.find(
                                            (employment) =>
                                                employment &&
                                                employment.links.person === (event.target.value as string),
                                        )?.links.self,
                                    })
                                }
                                required
                            >
                                {this.state.employees.map((employee) => {
                                    if (employee) {
                                        return (
                                            <MenuItem key={employee.links.self} value={employee.links.self}>
                                                {employee.firstname + ' ' + employee.lastname}
                                            </MenuItem>
                                        );
                                    } else return null;
                                })}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={'outlined'} onClick={() => this.props.onClose(false)}>
                        {this.props.t('dialogs:CANCEL')}
                    </Button>
                    <Button
                        disabled={!this.state.selectedEmploymentRef}
                        onClick={() => this.save()}
                        color="primary"
                        variant="contained"
                    >
                        {this.props.t('dialogs:SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['dialogs', 'common'])(ReassignmentDialog);
