import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Address } from 'model/Address';
import { Company } from 'model/Company';
import * as React from 'react';
import { ComponentBase } from 'resub';
import { AddressStore, CompanyStore } from 'store';
import StarComponent from 'components/shared/Popovers/StarComponent';
import DistanceComponent from 'components/shared/DistanceComponent';
import { Link } from 'react-router-dom';
import { Theme } from '@mui/material/styles';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import CompanyAvatarComponent from 'components/company/view/CompanyAvatarComponent';
import { classNames } from 'util/style-helpers';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        linkLess: {
            color: theme.palette.primary.dark,
            textDecoration: 'none',
        },
        typographyBold: {
            fontWeight: 'bold',
        },
        overflowRules: {
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            textOverflow: 'ellipsis',
            minWidth: '25px',
            maxWidth: '160px',
        },
    });

interface ComponentSummaryComponentProps extends WithStyles<typeof _styles> {
    companyRef: string;
}

interface CompanySummaryComponentState {
    company?: Company;
    companyAddress?: Address;
    ownAddress?: Address;
}

class CompanySummaryComponent extends ComponentBase<ComponentSummaryComponentProps, CompanySummaryComponentState> {
    protected _buildState(): Partial<CompanySummaryComponentState> {
        const newState: Partial<CompanySummaryComponentState> = {
            company: CompanyStore.getOne(this.props.companyRef),
            ownAddress: AddressStore.getSelected(),
        };
        if (newState.company && newState.company.address) {
            newState.companyAddress = AddressStore.getOne(newState.company.address);
        }
        return newState;
    }

    render(): React.ReactElement | null {
        if (!this.state.company) return null;

        return (
            <Grid container>
                <Grid container item xs={3}>
                    <CompanyAvatarComponent companyRef={this.state.company.links.self} isLogoShown isSmall />
                </Grid>
                <Grid item container xs={9} alignContent={'start'}>
                    <Grid item xs={8}>
                        <Link
                            className={this.props.classes.linkLess}
                            onClick={() => captureWebEvent('marketplace-company-link')}
                            to={{
                                pathname: '/company/' + this.state.company.id + '/detail',
                                state: { from: 'MARKETPLACE' },
                            }}
                        >
                            <Typography
                                className={classNames(
                                    this.props.classes.typographyBold,
                                    this.props.classes.overflowRules,
                                )}
                            >
                                {this.state.company.labelName ? this.state.company.labelName : this.state.company.name}
                            </Typography>
                        </Link>
                        <Typography>{this.state.companyAddress?.street}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <StarComponent
                            companyRef={this.state.company.links.self}
                            calledFrom={'CompanySummaryComponent'}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography className={this.props.classes.overflowRules}>
                            {this.state.companyAddress &&
                                this.state.companyAddress.zipcode + ' ' + this.state.companyAddress.city}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {this.state.ownAddress && this.state.companyAddress && (
                            <Typography
                                style={{
                                    fontSize: '0.85rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <DistanceComponent
                                    address1={this.state.ownAddress}
                                    address2={this.state.companyAddress}
                                />
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(CompanySummaryComponent);
