import { DynamicLoadingStore } from 'resub-entity';
import { Person } from 'model/Person';
import PersonService from 'api/PersonService';
import { autoSubscribeWithKey } from 'resub';

const TERMINATED_PERSON = 'TERMINATED_PERSON';

class PersonStoreClass extends DynamicLoadingStore<Person, string> {
    protected selectedTerminatedPerson?: Person;

    @autoSubscribeWithKey(TERMINATED_PERSON)
    getSelectedTerminatedPerson(): Person | undefined {
        return this.selectedTerminatedPerson;
    }

    setSelectedTerminatedPerson(person?: Person) {
        this.selectedTerminatedPerson = person;
        this.trigger(TERMINATED_PERSON);
    }
}

export const PersonStore = new PersonStoreClass({
    selectIdFunction: (person: Person) => person.links.self || '',
    loadFunction: (ref: string) => PersonService.getPerson(ref),
});
export default PersonStore;
