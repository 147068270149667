import Grid from '@mui/material/Grid';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import React, { ReactNode } from 'react';
import { ComponentBase } from 'resub';
import { NearbuyLoadingIndicator } from 'components/shared/NearbuyLoadingIndicator';

const _styles = () => createStyles({});

interface InitialisationAssuranceProps extends WithStyles<typeof _styles> {
    phase?: InitializationPhase;
    oneOf?: InitializationPhase[];
    children?: JSX.Element[] | JSX.Element | null;
}

interface InitialisationAssuranceState {
    phaseDone: boolean;
}

class InitialisationAssurance extends ComponentBase<InitialisationAssuranceProps, InitialisationAssuranceState> {
    protected _buildState(props: InitialisationAssuranceProps): Partial<InitialisationAssuranceState> | undefined {
        if (!props.phase && !props.oneOf) {
            throw new Error('You need to set at least one of the properties');
        }
        if (props.phase) {
            return {
                phaseDone: InitializationManager.isInitialisationDone(props.phase),
            };
        } else if (props.oneOf) {
            return {
                phaseDone: props.oneOf
                    .map((phase) => InitializationManager.isInitialisationDone(phase))
                    .reduce((previousValue, currentValue) => previousValue || currentValue),
            };
        }
    }

    render(): ReactNode {
        if (!this.state.phaseDone) {
            return (
                <Grid container justifyContent="center" alignItems="center" height="100vh">
                    <Grid item>
                        <NearbuyLoadingIndicator />
                    </Grid>
                </Grid>
            );
        }
        return this.props.children;
    }
}

export default withStyles(_styles, { withTheme: true })(InitialisationAssurance);
