import { SelectEntityStore } from 'resub-entity';
import { CompanyStore, ProductStore, SearchFilterStore } from 'store/index';
import { Sema } from 'async-sema/lib';
import { autoSubscribeWithKey } from 'resub';
import { catchError, tap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { Marker as MarkerClass } from 'leaflet';
import { Pair } from 'util/Types';
import { ResultItem, ResultType, sortResultItems } from 'model/ResultItem';
import CompanyService from 'api/CompanyService';
import OfferService from 'api/OfferService';
import RequestService from 'api/RequestService';
import { delay } from 'util/helpers';
import {
    captureMultipleEvents,
    captureWebEvent,
    generateParamList,
    getMarketFilterDateRange,
} from 'util/AnalyticUtils';

const IS_LOADING_KEY = 'CURRENTLY_LOADING';
const SELECTED_ITEMS = 'SELECTED_ITEMS';
const HOVERED_ITEM = 'HOVERED_ITEM';

class SearchResultStoreClass extends SelectEntityStore<ResultItem, string> {
    protected currentSearch?: string = undefined;
    protected currentlyLoading?: boolean = false;
    protected isCompanyServiceLoading = false;
    protected isOfferServiceLoading = false;
    protected isRequestServiceLoading = false;

    /* Used to fold expandable ResultItem when another one is expanded. */
    protected foldingCallBackEntry: Pair<string, () => void> | undefined;
    protected selectedCompany?: string = undefined;
    protected hoveredResultItem?: ResultItem = undefined;

    /* References to all active MapMarkers.
     * Used to open/close popup by related ResultItem. */
    protected resultMapMarkers = new Map<string, MarkerClass<any>>();
    private semaphore = new Sema(1);

    private setIsCompanyServiceLoading(value: boolean) {
        this.isCompanyServiceLoading = value;
        this.updateCurrentlyLoading();
    }
    private setIsOfferServiceLoading(value: boolean) {
        this.isOfferServiceLoading = value;
        this.updateCurrentlyLoading();
    }
    private setIsRequestServiceLoading(value: boolean) {
        this.isRequestServiceLoading = value;
        this.updateCurrentlyLoading();
    }

    public triggerSearch(): Promise<void> {
        const searchParams = {
            limit: 1000,
            lat1: SearchFilterStore.getBounds()?.[0][0],
            lon1: SearchFilterStore.getBounds()?.[0][1],
            lat2: SearchFilterStore.getBounds()?.[1][0],
            lon2: SearchFilterStore.getBounds()?.[1][1],
            category: SearchFilterStore.getCategories(),
            afterDate: SearchFilterStore.getDateFrom()?.format('YYYY-MM-DD'),
            beforeDate: SearchFilterStore.getDateUntil()?.format('YYYY-MM-DD'),
            amountMax: SearchFilterStore.getAmount()?.max,
            amountMin: SearchFilterStore.getAmount()?.min,
            amountUnit: SearchFilterStore.getAmount()?.unit,
            description: SearchFilterStore.getName() ? SearchFilterStore.getName() : undefined,
            companyName: SearchFilterStore.getMarketCompanyName(),
            companies: SearchFilterStore.getMarketCompanies(),
            showOnlyFavourites: SearchFilterStore.getFavourites(),
            showOwnData: SearchFilterStore.getShowOwnData(),
        };

        const searchFilters = generateParamList(
            searchParams,
            [
                'afterDate',
                'beforeDate',
                'amountMin',
                'amountMax',
                'amountUnit',
                'companyName',
                'showOnlyFavourites',
                'showOwnData',
            ],
            true,
        );
        return this.semaphore.acquire().then(async (value) => {
            const allResults: ResultItem[] = [];
            if (value) {
                const newQueryParams = SearchFilterStore.getQueryParams();
                if (newQueryParams === this.currentSearch) {
                    this.semaphore.release(value);
                    return;
                }

                this.currentSearch = newQueryParams;
                const resultTypes = SearchFilterStore.getResultTypes();

                if (resultTypes.includes(ResultType.COMPANY)) {
                    this.setIsCompanyServiceLoading(true);
                    await firstValueFrom(
                        CompanyService.searchCompaniesAsResults({
                            name: SearchFilterStore.getName() ? SearchFilterStore.getName() : undefined,
                            limit: 1000,
                            lat1: SearchFilterStore.getBounds()?.[0][0],
                            lon1: SearchFilterStore.getBounds()?.[0][1],
                            lat2: SearchFilterStore.getBounds()?.[1][0],
                            lon2: SearchFilterStore.getBounds()?.[1][1],
                            role: SearchFilterStore.getRoles(),
                            category: SearchFilterStore.getCategories(),
                            showOwnData: SearchFilterStore.getShowOwnData(),
                            showOnlyFavourites: SearchFilterStore.getFavourites(),
                            certifiedOrganic: SearchFilterStore.getIsOrganic(),
                            transitioning: SearchFilterStore.getIsInTransition(),
                        }).pipe(
                            catchError((error, caught) => {
                                this.setIsCompanyServiceLoading(false);
                                console.error(error);
                                return caught;
                            }),
                            tap(async (results) => {
                                Array.from(results).forEach((it) => allResults.push(it));
                                this.setIsCompanyServiceLoading(false);
                            }),
                        ),
                    );
                }
                if (resultTypes.includes(ResultType.OFFER)) {
                    this.setIsOfferServiceLoading(true);
                    await firstValueFrom(
                        OfferService.searchOffersAsResults(searchParams).pipe(
                            catchError((error, caught) => {
                                this.setIsOfferServiceLoading(false);
                                console.error(error);
                                return caught;
                            }),
                            tap(async (results) => {
                                Array.from(results).forEach((it) => allResults.push(it));
                                this.setIsOfferServiceLoading(false);
                            }),
                        ),
                    );
                }
                if (resultTypes.includes(ResultType.REQUEST)) {
                    this.setIsRequestServiceLoading(true);
                    await firstValueFrom(
                        RequestService.searchRequestsAsResults(searchParams).pipe(
                            catchError((error, caught) => {
                                this.setIsRequestServiceLoading(false);
                                console.error(error);
                                return caught;
                            }),
                            tap(async (results) => {
                                Array.from(results).forEach((it) => allResults.push(it));
                                this.setIsRequestServiceLoading(false);
                            }),
                        ),
                    );
                }
                if (resultTypes.includes(ResultType.OFFER || ResultType.REQUEST)) {
                    const searchedCompanyName =
                        searchParams.companyName || searchParams.companies.length > 0
                            ? CompanyStore.getOne(searchParams.companies[0])?.name
                            : undefined;

                    const productName =
                        searchParams.category.length > 0
                            ? ProductStore.getOne(searchParams.category[0])?.label
                            : searchParams.description;

                    const isCompanyFilter =
                        !!searchParams.companies?.length && !!CompanyStore.getOne(searchParams.companies[0])?.name;

                    if (productName) {
                        captureWebEvent('marketplace-search-for-products', {
                            productName: productName,
                        });
                    }

                    if (searchedCompanyName) {
                        captureWebEvent('marketplace-most-searched-company', { companyName: searchedCompanyName });
                    }

                    if (isCompanyFilter) {
                        captureWebEvent('marketplace-filter-selectCompany');
                    }

                    if (searchParams.afterDate && searchParams.beforeDate) {
                        captureWebEvent('marketplace-filter-dateRange', {
                            dateRange: getMarketFilterDateRange(searchParams.afterDate, searchParams.beforeDate),
                        });
                    }

                    captureMultipleEvents({ params: searchFilters, eventName: 'marketplace-filter' });
                }
            }
            while (this.currentlyLoading) await delay(10);
            this.setEntities(allResults.sort(sortResultItems));
            this.semaphore.release(value);
        });
    }

    registerResultMapMarker(companyRef: string, marker: MarkerClass<any>) {
        if (!this.resultMapMarkers.has(companyRef)) {
            this.resultMapMarkers.set(companyRef, marker);
        }
    }

    getResultMapMarker(companyRef: string) {
        return this.resultMapMarkers.get(companyRef);
    }

    deRegisterResultMapMarker(companyRef: string) {
        this.resultMapMarkers.delete(companyRef);
    }

    setFoldingCallBackEntry(companyRef?: string, callback?: () => void) {
        if (companyRef && callback) {
            this.foldingCallBackEntry = { first: companyRef, second: callback };
        } else {
            this.foldingCallBackEntry = undefined;
        }
    }

    getFoldingCallBackEntry() {
        return this.foldingCallBackEntry;
    }

    getAll(): readonly ResultItem[] {
        const all = super.getAll();
        // get new QueryParams
        const newQueryParams = SearchFilterStore.getQueryParams();
        if (newQueryParams !== this.currentSearch) {
            this.triggerSearch();
        }
        if (this.currentSearch) {
            if (all.length > 0) return all;
            // else there are no result items
            if (this.isLoading()) {
                // currently loading for searchResult, so we show nothing instead
                return [];
            }
            // no search done before, so we trigger it and return empty array for now
        } else {
            this.triggerSearch();
        }
        return [];
    }

    @autoSubscribeWithKey(IS_LOADING_KEY)
    isLoading(): boolean {
        return this.currentlyLoading || false;
    }

    updateCurrentlyLoading() {
        this.currentlyLoading =
            this.isRequestServiceLoading || this.isOfferServiceLoading || this.isCompanyServiceLoading;
        this.trigger(IS_LOADING_KEY);
    }

    setCurrentlyLoading(value: boolean): void {
        this.currentlyLoading = value;
        this.trigger(IS_LOADING_KEY);
    }

    setSelectedCompany(companyRef?: string) {
        this.selectedCompany = companyRef;
        this.trigger(SELECTED_ITEMS);
    }

    @autoSubscribeWithKey(SELECTED_ITEMS)
    getSelectedCompany(): string | undefined {
        return this.selectedCompany;
    }

    @autoSubscribeWithKey(HOVERED_ITEM)
    getHoveredItem(): ResultItem | undefined {
        return this.hoveredResultItem;
    }

    setHoveredItem(resultItem: ResultItem | undefined): void {
        this.hoveredResultItem = resultItem;
        this.trigger(HOVERED_ITEM);
    }

    clear(): readonly string[] {
        this.currentSearch = undefined;
        this.currentlyLoading = undefined;
        this.semaphore.release();
        return super.clear();
    }
}

export const SearchResultStore = new SearchResultStoreClass({
    selectIdFunction: (entity) => entity.links.offer || entity.links.request || entity.links.company,
    // identity function, because we sort it on load
    sortFunction: () => 0,
});
