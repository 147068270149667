import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions, DialogTitle, Theme, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import Button from '@mui/material/Button';

const _styles = (theme: Theme) =>
    createStyles({
        heading: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        buttonActions: {
            margin: theme.spacing(2),
        },
    });

interface ExpiredProductDialogProps extends WithStyles<typeof _styles>, WithTranslation {
    isOpen: boolean;
    expiredProductTitle: string;
    onClose: () => void;
}

class ExpiredProductDialog extends ComponentBase<ExpiredProductDialogProps> {
    render(): React.ReactElement | null {
        if (!this.props.isOpen) return null;

        return (
            <Dialog open={this.props.isOpen} maxWidth="md" fullWidth>
                <DialogTitle className={this.props.classes.heading}>{this.props.expiredProductTitle}</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>{this.props.t('dialogs:expiredProductText')}</Typography>
                </DialogContent>
                <DialogActions className={this.props.classes.buttonActions}>
                    <Button variant="outlined" onClick={(): void => this.props.onClose()}>
                        {this.props.t('dialogs:CLOSE')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation('dialogs')(withStyles(_styles, { withTheme: true })(ExpiredProductDialog));
