import AssociationProposalSystemMessageItem from 'components/systemmessage/items/standard/AssociationProposalSystemMessageItem';
import ExpiringMembershipSystemMessageItem from 'components/systemmessage/items/standard/ExpiringMembershipSystemMessageItem';
import ExpiringOffersSystemMessageItem from 'components/systemmessage/items/standard/ExpiringOffersSystemMessageItem';
import ExpiringRequestsSystemMessageItem from 'components/systemmessage/items/standard/ExpiringRequestsSystemMessageItem';
import ExpiringRequestSystemMessageItem from 'components/systemmessage/items/standard/ExpiringRequestSystemMessageItem';
import GlobalSystemMessageItem from 'components/systemmessage/items/standard/GlobalSystemMessageItem';
import InvalidDataSystemMessageItem from 'components/systemmessage/items/standard/InvalidDataMessageItem';
import NewCompanyInRegionSystemMessageItem from 'components/systemmessage/items/standard/NewCompanyInRegionSystemMessageItem';
import NewCompanySystemMessageItem from 'components/systemmessage/items/standard/NewCompanySystemMessageItem';
import NewPersonSystemMessageItem from 'components/systemmessage/items/standard/NewPersonSystemMessageItem';
import OfferSystemMessageItem from 'components/systemmessage/items/standard/OfferSystemMessageItem';
import ProductProposalSystemMessageItem from 'components/systemmessage/items/standard/ProductProposalSystemMessageItem';
import TestSystemMessageItem from 'components/systemmessage/items/standard/TestSystemMessageItem';
import UpdatedMembershipSystemMessageItem from 'components/systemmessage/items/standard/UpdatedMembershipSystemMessageItem';
import {
    AssociationProposalSystemMessage,
    ExpiringMembershipSystemMessage,
    ExpiringOffersSystemMessage,
    ExpiringRequestsSystemMessage,
    ExpiringRequestSystemMessage,
    GlobalSystemMessage,
    MatchingOfferSystemMessage,
    MatchingRequestSystemMessage,
    NewCompanySystemMessage,
    NewPersonSystemMessage,
    OfferSystemMessage,
    ProductProposalSystemMessage,
    SystemMessage,
    SystemMessageType,
    UpdatedMembershipSystemMessage,
} from 'model/SystemMessage';
import * as React from 'react';
import { ReactElement } from 'react';
import MatchingOfferSystemMessageItem from 'components/systemmessage/items/standard/MatchingOfferSystemMessageItem';
import MatchingRequestSystemMessageItem from 'components/systemmessage/items/standard/MatchingRequestSystemMessageItem';

export function getSystemMessage(systemMessage: SystemMessage): ReactElement | undefined | null {
    switch (systemMessage.type) {
        case SystemMessageType.TEST:
            return <TestSystemMessageItem systemMessage={systemMessage} />;
        case SystemMessageType.OFFER_AMOUNT_REDUCED:
        case SystemMessageType.OFFER_DEACTIVATED_BY_ZERO_AMOUNT:
        case SystemMessageType.OFFER_DEACTIVATED_BY_MIN_AMOUNT:
        case SystemMessageType.EXPIRING_OFFER:
            return <OfferSystemMessageItem systemMessage={systemMessage as OfferSystemMessage} />;
        case SystemMessageType.EXPIRING_OFFERS:
            return <ExpiringOffersSystemMessageItem systemMessage={systemMessage as ExpiringOffersSystemMessage} />;
        case SystemMessageType.EXPIRING_REQUEST:
            return <ExpiringRequestSystemMessageItem systemMessage={systemMessage as ExpiringRequestSystemMessage} />;
        case SystemMessageType.EXPIRING_REQUESTS:
            return <ExpiringRequestsSystemMessageItem systemMessage={systemMessage as ExpiringRequestsSystemMessage} />;
        case SystemMessageType.EXPIRING_MEMBERSHIP:
            return (
                <ExpiringMembershipSystemMessageItem systemMessage={systemMessage as ExpiringMembershipSystemMessage} />
            );
        case SystemMessageType.NEW_COMPANY:
            return <NewCompanySystemMessageItem systemMessage={systemMessage as NewCompanySystemMessage} />;
        case SystemMessageType.NEW_PERSON:
            return <NewPersonSystemMessageItem systemMessage={systemMessage as NewPersonSystemMessage} />;
        case SystemMessageType.ASSOCIATION_PROPOSAL:
            return (
                <AssociationProposalSystemMessageItem
                    systemMessage={systemMessage as AssociationProposalSystemMessage}
                />
            );
        case SystemMessageType.PRODUCT_PROPOSAL:
            return <ProductProposalSystemMessageItem systemMessage={systemMessage as ProductProposalSystemMessage} />;
        case SystemMessageType.MATCHING_OFFER:
            return <MatchingOfferSystemMessageItem systemMessage={systemMessage as MatchingOfferSystemMessage} />;
        case SystemMessageType.MATCHING_REQUEST:
            return <MatchingRequestSystemMessageItem systemMessage={systemMessage as MatchingRequestSystemMessage} />;
        case SystemMessageType.INVALID_DATA:
            return <InvalidDataSystemMessageItem systemMessage={systemMessage} />;
        case SystemMessageType.GLOBAL:
            return <GlobalSystemMessageItem systemMessage={systemMessage as GlobalSystemMessage} />;
        case SystemMessageType.UPDATED_MEMBERSHIP:
        case SystemMessageType.CERTIFICATE_FILE_UPDATED:
            return (
                <UpdatedMembershipSystemMessageItem systemMessage={systemMessage as UpdatedMembershipSystemMessage} />
            );
        case SystemMessageType.NEW_CONSUMER_IN_REGION:
        case SystemMessageType.NEW_COMPANY_IN_REGION:
            return (
                <NewCompanyInRegionSystemMessageItem systemMessage={systemMessage as UpdatedMembershipSystemMessage} />
            );
    }
}
