import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import PersonService from 'api/PersonService';
import { firstValueFrom } from 'rxjs';
import { handleUser } from 'api/PersonServiceUtil';

export class UserInitializer implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        await firstValueFrom(handleUser(PersonService.me()));
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new UserInitializer(),
);
