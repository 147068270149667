import LocalStorageMigrator, { Migration } from 'components/initialization/initializers/migration/LocalStorageMigrator';

import * as MigrationsHelper from 'components/initialization/initializers/migration/migrations/MigrationsHelper';

/**
 * The InitialMigration should always point from the start to the latest Version.
 * This migrates from the "empty" local state to the desired state.
 */
class InitialMigration implements Migration {
    async migrate(_version: number): Promise<number> {
        /**
         * add local storage deletions here
         */
        localStorage.removeItem('PersistentStore_DashletStore');
        localStorage.removeItem('PersistentStore_settings');
        return Promise.resolve(MigrationsHelper.currentVersion);
    }
}

export default LocalStorageMigrator.registerMigration(MigrationsHelper.startVersion, new InitialMigration());
