import { Amount } from 'model/Amount';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { LevelOfProcessing } from 'model/LevelOfProcessing';

export class Offer {
    constructor(
        public id: string,
        public description: string,
        public dateFrom: Moment | undefined,
        public dateEnd: Moment | undefined,
        public totalAmount: Amount,
        public minAmount: Amount,
        public pricePerUnit: number | null,
        public graduatedPrices: GraduatedPrice[],
        public levelsOfProcessing: LevelOfProcessing[],
        public links: OfferLinks,
        public active: boolean,
        public isDeleted: boolean,
        public dateCreated: Moment | undefined,
        public dateModified: Moment | undefined,
        public isPermanent = false,
    ) {}
}

export class OfferLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public company: string,
        public category: string,
        public contact: string | undefined,
        public latestTradeItem?: string,
    ) {}
}

export class OfferWriteView {
    constructor(
        public dateFrom: Date | undefined,
        public dateEnd: Date | undefined,
        public description: string,
        public totalAmount: Amount,
        public minAmount: Amount,
        public pricePerUnit: number | null,
        public category: string,
        public graduatedPrices: GraduatedPrice[],
        public levelsOfProcessing: string[],
        public active: boolean,
        public contact: string | undefined | null,
        public isPermanent = false,
    ) {}

    static create(offer?: Offer, isActive?: boolean): OfferWriteView {
        if (offer) {
            return new OfferWriteView(
                offer.dateFrom?.toDate(),
                offer.dateEnd?.toDate(),
                offer.description,
                offer.totalAmount,
                offer.minAmount,
                offer.pricePerUnit,
                offer.links.category,
                offer.graduatedPrices,
                offer.levelsOfProcessing.map((lop) => lop.links.self),
                isActive ?? offer.active,
                offer.links.contact,
                offer.isPermanent,
            );
        } else {
            return new OfferWriteView(
                new Date(moment().startOf('day').format('YYYY-MM-DD')),
                new Date(moment().startOf('day').add(10, 'days').format('YYYY-MM-DD')),
                '',
                new Amount(1, ''),
                new Amount(0, ''),
                0,
                '',
                new Array<GraduatedPrice>(),
                new Array<string>(),
                true,
                null,
            );
        }
    }
}

export class CompanyOffers {
    constructor(
        public companyRef: string,
        public offers: Offer[],
    ) {}
}

export class GraduatedPrice {
    constructor(
        public price: number,
        public amount: number,
    ) {}
}

export class OfferContainers {
    constructor(
        public offerRef: string,
        public containerRefs: string[],
    ) {}
}
