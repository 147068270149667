import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import RequestDashletItem from 'components/dashboard/marketplace/request/RequestDashletItem';
import { Request } from 'model/Request';
import * as React from 'react';
import { ComponentBase } from 'resub';
import { CompanyRequestStore } from 'store';

const _styles = (theme: Theme) =>
    createStyles({
        requestListBox: {
            alignItems: 'stretch',
            backgroundColor: theme.palette.common.white,
            marginBottom: theme.spacing(2),
        },
    });

interface RequestDashletListProps extends WithStyles<typeof _styles> {
    companyRef: string;
}

interface RequestDashletListState {
    requests?: Request[];
}

const RequestCountToShow = 5;

class RequestDashletList extends ComponentBase<RequestDashletListProps, RequestDashletListState> {
    protected _buildState(
        props: RequestDashletListProps,
        initialBuild: boolean,
        incomingState?: Readonly<RequestDashletListState>,
    ): Partial<RequestDashletListState> | undefined {
        return {
            requests: CompanyRequestStore.search(props.companyRef)
                .filter((offer) => offer.active)
                ?.sort((a, b) => {
                    if (!a.dateModified && !b.dateModified) {
                        return 0;
                    } else if (!a.dateModified) {
                        return -1;
                    } else if (!b.dateModified) {
                        return 1;
                    } else {
                        return b.dateModified.diff(a.dateModified);
                    }
                })
                .slice(0, RequestCountToShow),
        };
    }

    render(): React.ReactElement | null {
        return (
            <Grid container spacing={1} className={this.props.classes.requestListBox}>
                {this.state.requests?.map((request) => {
                    return <RequestDashletItem key={request.id} requestRef={request.links.self} />;
                })}
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(RequestDashletList);
