import LocalStorageMigrator, { Migration } from 'components/initialization/initializers/migration/LocalStorageMigrator';
import { DashletProperties, DashletType } from 'model/DashletProperties';

class MapDashletMigration implements Migration {
    async migrate(_version: number): Promise<number> {
        //Add map dashlet to default dashlets
        const jsonString = localStorage.getItem('PersistentStore_DashletStore');
        if (jsonString) {
            //A dashlet configuration exists, lets add the map dashlet if it doesnt exists
            const dashlets = JSON.parse(jsonString) as DashletProperties[];
            if (dashlets.every((config) => config.id != DashletType.map)) {
                //Highest Priority of all active dashlets or 0 if there are no dashlets active
                const currentMaxPriority =
                    dashlets
                        .filter(
                            (properties) =>
                                properties.priority &&
                                //Priority over 1000 is reserved for disabled dashlets
                                properties.priority < 1000,
                        )
                        .sort((a, b) => (a.priority && b.priority && a.priority - b.priority) ?? 0)
                        .pop()?.priority || 0;

                dashlets.push({
                    id: DashletType.map,
                    priority: currentMaxPriority + 1,
                });
                localStorage.setItem('PersistentStore_DashletStore', JSON.stringify(dashlets));
            }
            return Promise.resolve(2);
        } else {
            //No dashlet configuration was previously saved, so the correct defaults will be loaded
            return Promise.resolve(2);
        }
    }
}

export default LocalStorageMigrator.registerMigration(1, new MapDashletMigration());
