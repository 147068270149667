import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Address, AddressSearchView, AddressSuggestionView, AddressType, AddressWriteView } from 'model/Address';
import { AddressStore, CompanyStore } from 'store';
import CompanyFeatureStore from 'store/FeatureStore';
import { getUuidFromString } from 'util/helpers';
import http from 'api/http';

class AddressService {
    getAddress(addressRef: string): Observable<Address> {
        return http
            .getAxios()
            .get<Address>(`${addressRef}`)
            .pipe(map((value) => value.data));
    }

    updateAddress(address: AddressWriteView, addressRef: string, companyRef: string): Observable<boolean> {
        const companyId = getUuidFromString(companyRef);
        if (!companyId) return of(false);
        return http
            .getAxios()
            .put<Address>(addressRef, address, { headers: { 'Current-Company': companyId } })
            .pipe(
                map((response) => {
                    if (response.status < 400) {
                        return response.data;
                    } else {
                        throw new Error(response.statusText);
                    }
                }),
                tap((address: Address) => AddressStore.setOne(address)),
                tap(() => CompanyFeatureStore.loadOne(companyRef)),
                map(() => true),
                catchError((err) => {
                    return throwError(err);
                }),
            );
    }

    searchAddressLocation(address: AddressSearchView): Observable<AddressSuggestionView[]> {
        return http
            .getAxios()
            .post<AddressSuggestionView[]>('/addresses/search', address)
            .pipe(map((value) => value.data));
    }

    createAddress(companyRef: string, address: AddressWriteView): Observable<boolean> {
        const companyId = getUuidFromString(companyRef);
        if (!companyId) return of(false);
        return http
            .getAxios()
            .post<Address>(companyRef + '/addresses', address, { headers: { 'Current-Company': companyId } })
            .pipe(
                map((response) => {
                    if (response.status < 400) {
                        return response.data;
                    } else {
                        throw new Error(response.statusText);
                    }
                }),
                tap((_address: Address) => AddressStore.setOne(_address)),
                tap(
                    (_address: Address) =>
                        address.type == AddressType.MAIN && AddressStore.setSelected(_address.links.self),
                ),
                tap(() => CompanyFeatureStore.loadOne(companyRef)),
                switchMap(() => CompanyStore.loadOne(companyRef)),
                map(() => true),
                catchError((err) => {
                    return throwError(err);
                }),
            );
    }

    deleteAddress(address: Address): Observable<boolean> {
        const companyId = getUuidFromString(address.links.company);
        if (!companyId) return of(false);
        return http
            .getAxios()
            .delete(address.links.remove, { headers: { 'Current-Company': companyId } })
            .pipe(
                map(() => true),
                tap(() => CompanyStore.loadOne(address.links.company)),
                tap(() => AddressStore.removeOne(address)),
                tap(() => CompanyFeatureStore.loadOne(address.links.company)),
                catchError(() => of(false)),
            );
    }
}

export default new AddressService();
