import { createDynamicLoadingStore } from 'resub-entity';
import { Observable } from 'rxjs';
import { getUuidFromString } from 'util/helpers';
import MessageService from 'api/MessageService';
import { map, tap } from 'rxjs/operators';
import { PersonStore } from 'store/index';
import { Dialog, getDialogFromId, getDialogId } from 'model/Dialog';

interface DialogSearchType {
    ownCompanyRef: string;
    ownPersonRef: string;
    otherCompanyRef?: string;
    otherPersonRef?: string;
}

function loadDialogs(searchParams: DialogSearchType) {
    const selectedPerson = PersonStore.getSelected();
    let dialogsObservable: Observable<Dialog[]>;
    const ownCompanyId = getUuidFromString(searchParams.ownCompanyRef);
    const ownPersonId = getUuidFromString(searchParams.ownPersonRef);
    if (!ownCompanyId) {
        throw new Error('could not extract ID of Company');
    }
    if (!ownPersonId) {
        throw new Error('could not extract ID of Person');
    }
    if (selectedPerson && searchParams.ownPersonRef === selectedPerson.links.self) {
        dialogsObservable = MessageService.getDialogs();
    } else {
        // if message not for own person, it is for a former employee
        dialogsObservable = MessageService.getDialogsOfFormerEmployee(ownPersonId);
    }
    return dialogsObservable.pipe(
        tap((dialogs) => {
            // update all dialogs
            DialogStore.setAll(dialogs);
        }),
    );
}

export const DialogStore = createDynamicLoadingStore<Dialog, string, DialogSearchType>({
    selectIdFunction: (dialog: Dialog) => getDialogId(dialog),
    loadFunction: (id: string) => {
        const searchParams = getDialogFromId(id);
        return loadDialogs(searchParams).pipe(
            map((dialogs) =>
                // get the dialog, we searched for
                dialogs
                    .filter(
                        (dialog) =>
                            dialog.ownCompanyRef === searchParams.ownCompanyRef &&
                            dialog.ownPersonRef === searchParams.ownPersonRef &&
                            dialog.otherCompanyRef === searchParams.otherCompanyRef &&
                            dialog.otherPersonRef === searchParams.otherPersonRef,
                    )
                    .pop(),
            ),
            // in case, there is no dialog, we want to tell that there is no message
            map((dialog) => (dialog ? dialog : searchParams)),
        );
    },
    // TODO
    // searchFunction: (searchParam: DialogSearchType, entity: Dialog) =>
    //     getUuidFromString(entity.ownCompanyRef) === searchParam.ownCompanyRef &&
    //     entity.ownPersonRef === searchParam.ownPersonRef,
    searchLoadFunction: (searchParam: DialogSearchType) =>
        loadDialogs(searchParam).pipe(map((dialogs) => dialogs.map(getDialogId))),
    sortFunction: dialogSortFunction,
});

export function dialogSortFunction(dialog1: Dialog, dialog2: Dialog): number {
    if (dialog1.lastUpdated) {
        if (dialog2.lastUpdated) {
            return new Date(dialog2.lastUpdated).getTime() - new Date(dialog1.lastUpdated).getTime();
        } else {
            return 1;
        }
    } else {
        if (dialog2.lastUpdated) {
            return -1;
        } else {
            return 0;
        }
    }
}
