import * as React from 'react';
import { ReactElement } from 'react';
import { CompanyFeatures, FeatureOrFeatures } from 'model/Feature';
import Placeholder from 'components/shared/Placeholder';
import Typography from '@mui/material/Typography';
import CompanyFeatureStore from 'store/FeatureStore';
import { ComponentBase } from 'resub';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface FeatureToggleProps extends WithTranslation {
    requiredFeatures: FeatureOrFeatures[];
    type: 'allOf' | 'oneOf';
    additionalPermissionChecking?: () => boolean;
    showPermissionError?: boolean;
    permissionErrorElement?: ReactElement;
    children?: React.ReactNode | React.ReactNode[];
}

interface FeatureToggleState {
    companyFeatures?: CompanyFeatures;
    permission?: boolean;
}

class FeatureToggle extends ComponentBase<FeatureToggleProps, FeatureToggleState> {
    protected _buildState(props: FeatureToggleProps): Partial<FeatureToggleState> | undefined {
        return {
            companyFeatures: CompanyFeatureStore.getSelected(),
            permission: CompanyFeatureStore.hasPermission(new Set(props.requiredFeatures), props.type),
        };
    }

    hasPermission(): boolean | undefined {
        let hasPermission = this.state.permission;

        if (hasPermission === undefined) {
            return undefined;
        }

        if (this.props.additionalPermissionChecking) {
            hasPermission = hasPermission && this.props.additionalPermissionChecking();
        }

        return hasPermission;
    }

    render(): React.ReactElement | undefined | null | React.ReactNode | React.ReactNode[] {
        const permission = this.hasPermission();

        if (permission) {
            return this.props.children ? this.props.children : null;
        } else {
            if (this.props.showPermissionError) {
                return this.props.permissionErrorElement ? (
                    this.props.permissionErrorElement
                ) : (
                    <Placeholder>
                        <Typography>{this.props.t('common:insufficientPermission')}</Typography>
                    </Placeholder>
                );
            } else {
                return null;
            }
        }
    }
}

export default withTranslation('common')(FeatureToggle);
