import { Moment } from 'moment/moment';
import { NearbuyRole } from 'model/NearbuyRole';
import { getCompanyDistanceByLatLon } from 'util/helpers';
import { UnitString } from 'model/Unit';
import { SearchFilterStore } from 'store';

export enum ResultType {
    COMPANY = 'COMPANY',
    OFFER = 'OFFER',
    REQUEST = 'REQUEST',
}

export enum ResultFormat {
    VIEW = 'VIEW',
    REFERENCE = 'REFERENCE',
    SEARCH_RESULT = 'SEARCH_RESULT',
}

export interface ResultCompany {
    id: string;
    name: string;
    label?: string;
    verified: boolean;
}

export interface ResultAddress {
    lat: number;
    lon: number;
    city: string;
}

export interface ResultProduct {
    isPermanent: boolean;
    dateStart: Moment;
    dateEnd: Moment;
    unit: UnitString;
    totalAmount: number;
}

export interface ResultLinks {
    company: string;
    offer?: string;
    request?: string;
    address?: string;
    category?: string;
}

export interface ResultItem {
    resultType: ResultType;
    company: ResultCompany;
    address: ResultAddress | null;
    product?: ResultProduct;
    roles: NearbuyRole[];
    links: ResultLinks;
}

export function sortResultItems(a: ResultItem, b: ResultItem) {
    const hasDateFilter: boolean =
        SearchFilterStore.getDateFrom() !== undefined || SearchFilterStore.getDateUntil() !== undefined;

    const aDistance = getDistance(a) ?? Number.MAX_VALUE;
    const bDistance = getDistance(b) ?? Number.MAX_VALUE;

    if (hasDateFilter && a.product && b.product) {
        const aDate = a.product.dateStart;
        const bDate = b.product.dateStart;
        return aDate?.diff(bDate) || aDistance - bDistance || sortAlphabeticallyFunction(a, b);
    } else {
        return aDistance - bDistance || sortAlphabeticallyFunction(a, b);
    }
}

export function getDistance(resultItem: ResultItem): number | undefined {
    if (resultItem.address && resultItem.address.lat && resultItem.address.lon) {
        return getCompanyDistanceByLatLon(resultItem.address.lat, resultItem.address.lon);
    }
}

export function sortAlphabeticallyFunction(a: ResultItem, b: ResultItem) {
    const aString = a.company.name;
    const bString = b.company.name;
    if (aString && bString) {
        return aString.localeCompare(bString);
    }
    // if we can get no String, we just leave the order as is
    return 0;
}
