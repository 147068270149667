import * as React from 'react';
import { Fragment, PureComponent, ReactElement } from 'react';
import { Grid, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AddressType } from 'model/Address';
import IconButton from '@mui/material/IconButton';
import { EditIcon } from 'components/shared/Icons';
import { withStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { OrderAddress } from 'model/Order';
import createStyles from '@mui/styles/createStyles';

const _styles = (theme: Theme) =>
    createStyles({
        boldTypography: {
            fontWeight: 'bold',
        },
        addressContainer: {
            marginTop: theme.spacing(3),
        },
        title: {
            color: theme.palette.primary.dark,
            lineHeight: '25px',
            fontSize: '20px',
            fontWeight: 'bold',
        },
        roundedBtn: {
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
        },
    });

interface OrderAddressesProps extends WithStyles<typeof _styles>, WithTranslation {
    invoiceAddress?: OrderAddress;
    deliveryAddress?: OrderAddress;
    direction: 'row' | 'column';
    className?: string;
    onEditClick?: (address: OrderAddress) => void;
}

class OrderAddresses extends PureComponent<OrderAddressesProps> {
    showAddressDetails(address: OrderAddress): ReactElement | undefined {
        if (this.props.direction === 'row') {
            return (
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Typography className={this.props.classes.boldTypography}>{address.name}</Typography>
                    </Grid>
                    {address && (
                        <Fragment>
                            <Grid item xs={12}>
                                <Typography>{address.street}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {address.zipcode} {address.city}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{address.suffix}</Typography>
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            );
        }

        if (this.props.direction === 'column') {
            const addressInfos: (string | undefined)[] = [];
            addressInfos.push(address.name);
            addressInfos.push(address.street);
            if (address.suffix != '' && address.suffix != null) addressInfos.push(address.suffix);
            addressInfos.push(address.zipcode + ' ' + address.city);

            return (
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        {addressInfos.map((addressInfo) => {
                            return (
                                <Typography key={addressInfo} className={this.props.classes.boldTypography}>
                                    {addressInfo}
                                </Typography>
                            );
                        })}
                    </Grid>
                </Grid>
            );
        }
    }

    showSameAddressText(): ReactElement | undefined {
        return (
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <Typography
                        className={this.props.direction === 'column' ? this.props.classes.boldTypography : undefined}
                    >
                        {this.props.t('payment:sameAddress')}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    showAddress(address: OrderAddress, isSame?: boolean): React.ReactElement | undefined {
        if (this.props.direction === 'row') {
            return (
                <Grid item xs={6}>
                    <Typography className={this.props.classes.title}>
                        {address.type == AddressType.INVOICE && this.props.t('payment:billingAddress')}
                        {address.type == AddressType.DELIVERY && this.props.t('payment:deliveryAddress')}
                    </Typography>
                    <Grid container className={this.props.classes.addressContainer}>
                        <Grid item xs={10}>
                            {isSame ? this.showSameAddressText() : this.showAddressDetails(address)}
                        </Grid>
                        <Grid item xs={2}>
                            {this.props.onEditClick != undefined && (
                                <IconButton
                                    className={this.props.classes.roundedBtn}
                                    size="large"
                                    onClick={() => this.props.onEditClick!(address)}
                                    data-testid={`edit:${address.type}`}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            );
        }

        if (this.props.direction === 'column') {
            return (
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={1} />
                        <Grid item xs={4}>
                            <Typography>
                                {address.type == AddressType.INVOICE && this.props.t('payment:billingAddress')}
                                {address.type == AddressType.DELIVERY && this.props.t('payment:deliveryAddress')}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} fontWeight={'bold'}>
                            {isSame ? this.showSameAddressText() : this.showAddressDetails(address)}
                        </Grid>
                        {this.props.onEditClick != undefined && (
                            <Grid item xs={2}>
                                <IconButton
                                    className={this.props.classes.roundedBtn}
                                    size="large"
                                    onClick={() => this.props.onEditClick!(address)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            );
        }
    }

    showAddresses(deliveryAddress?: OrderAddress, invoiceAddress?: OrderAddress): ReactElement | undefined {
        if (deliveryAddress && invoiceAddress && this.isAddressEqual(deliveryAddress, invoiceAddress)) {
            return (
                <>
                    {this.showAddress(invoiceAddress)}
                    {this.showAddress(deliveryAddress, true)}
                </>
            );
        }
        return (
            <>
                {invoiceAddress && this.showAddress(invoiceAddress)}
                {deliveryAddress && this.showAddress(deliveryAddress)}
            </>
        );
    }

    isAddressEqual(a: OrderAddress, b: OrderAddress, aNameOverwrite?: string, bNameOverwrite?: string): boolean {
        const aName = aNameOverwrite ?? a.name;
        const bName = bNameOverwrite ?? b.name;

        if (!!aName != !!bName) {
            return false;
        }
        if (!!a != !!b) {
            return false;
        }
        if (aName && bName) {
            if (aName != bName) {
                return false;
            }
        }
        if (a && b) {
            if (a.street != b.street || a.zipcode != b.zipcode || a.city != b.city || a.suffix != b.suffix) {
                return false;
            }
        }
        return true;
    }

    render(): React.ReactElement {
        return (
            <Grid container className={this.props.className}>
                {this.showAddresses(this.props.deliveryAddress, this.props.invoiceAddress)}
            </Grid>
        );
    }
}

export default withTranslation('payment')(withStyles(_styles, { withTheme: true })(OrderAddresses));
