import React from 'react';
import { ComponentBase } from 'resub';
import { KeycloakInstance } from 'keycloak-js';
import AuthStore from 'store/AuthStore';
import NavStore from 'store/NavStore';
import * as H from 'history';
import PersonService from 'api/PersonService';
import { Employment } from 'model/Employment';
import InvitationService from 'api/InvitationService';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AddressStore, CompanyFeatureStore, CompanyStore, DialogStore, LocalStorageSettingsStore } from 'store';
import { handleUser } from 'api/PersonServiceUtil';
import CompanyService from 'api/CompanyService';
import http from 'api/http';
import { SettingsKey } from 'store/LocalStorageSettingsStore';
import InformationDialog from 'components/shared/InformationDialog';
import { Redirect } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const styles = () =>
    createStyles({
        circularProgress: {
            marginBottom: '10px',
        },
        center: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            fontWeight: 'bold',
        },
    });

interface AcceptInvitationProperties extends WithStyles<typeof styles>, WithTranslation {}

interface AcceptInvitationState {
    keycloakAdapter?: KeycloakInstance;
    currentLocation: H.Location<any>;
    isLoggedIn?: boolean;
    invitationId: string | null;
    accepted: boolean;
    employment: Employment | undefined;
    isHandlingAccept: boolean;
    isLoading?: boolean;
    isDialogShown: boolean;
    dialogTitle?: string;
    dialogContent?: string;
}

class AcceptInvitation extends ComponentBase<AcceptInvitationProperties, AcceptInvitationState> {
    protected _buildState(): Partial<AcceptInvitationState> | undefined {
        return {
            keycloakAdapter: AuthStore.getKeycloakAdapter(),
            currentLocation: NavStore.getLocation(),
            isLoggedIn: AuthStore.isLoggedIn(),
            invitationId: NavStore.getCurrentSearchParams('id'),
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.handleInvitation();
    }

    componentDidUpdate(
        prevProps: Readonly<AcceptInvitationProperties>,
        prevState: AcceptInvitationState,
        prevContext: any,
    ): void {
        super.componentDidUpdate(prevProps, prevState, prevContext);
        if (!this.state.isLoading) {
            this.handleInvitation();
        }
        if (this.state.employment && !this.state.isHandlingAccept) {
            this.handleAccept();
        }
    }

    handleAccept(): void {
        if (this.state.employment) {
            this.setState({ isHandlingAccept: true });
            handleUser(PersonService.me()).subscribe((user) => {
                if (user) {
                    this.setState({
                        accepted: true,
                        isHandlingAccept: false,
                    });
                }
            });
        }
    }

    handleInvitation(): void {
        // at first, save key of invitation
        if (!this.state.invitationId) {
            // TODO: suitable error handling (ErrorBoundary should handle this)
            throw new Error('Missing invitation id.');
        } else {
            if (!this.state.isLoggedIn) {
                // send user to registration
                if (this.state.keycloakAdapter) {
                    if (this.state.isLoggedIn !== undefined) {
                        // redirect to root
                        if (!this.state.isLoggedIn) {
                            this.state.keycloakAdapter.register();
                        }
                    }
                }
            } else {
                // if already logged in, accept the invitation
                InvitationService.acceptInvitation(this.state.invitationId).subscribe(
                    (response: Employment | number) => {
                        if (typeof response === 'number') {
                            this.setState({
                                isDialogShown: true,
                                dialogTitle:
                                    response === 409
                                        ? this.props.t('dialogs:invitationEmploymentExistsTitle')
                                        : this.props.t('dialogs:invitationNotFoundTitle'),
                                dialogContent:
                                    response === 409
                                        ? this.props.t('dialogs:invitationEmploymentExistsContent')
                                        : this.props.t('dialogs:invitationNotFoundContent'),
                            });
                            return;
                        }
                        // Response is employment
                        if (response) {
                            CompanyService.getCompany(response.links.company).subscribe((company) => {
                                if (company) {
                                    http.setCurrentCompany(company.id);
                                    LocalStorageSettingsStore.setOne({
                                        key: SettingsKey.SELECTED_COMPANY,
                                        value: company,
                                    });
                                    LocalStorageSettingsStore.persist();
                                    AddressStore.setSelected(
                                        company && company.address !== '' ? company.address : undefined,
                                    );
                                    CompanyStore.setSelected(company.links.self);
                                    CompanyFeatureStore.setSelected(company.links.self);
                                }
                            });
                            DialogStore.invalidateSearch();
                            this.setState({ employment: response });
                        }
                    },
                );
                this.setState({ isLoading: true });
            }
        }
    }

    render(): React.ReactElement {
        if (this.state.isLoggedIn && this.state.accepted) {
            return <Redirect to="/" />;
        }
        return (
            <>
                <InformationDialog
                    isOpen={this.state.isDialogShown}
                    onClose={() => {
                        this.setState({
                            isDialogShown: false,
                        });
                        window.location.href = '/';
                    }}
                    title={this.state.dialogTitle ?? ''}
                    content={this.state.dialogContent ?? ''}
                />
                {!this.state.isDialogShown && (
                    <div className={this.props.classes.center}>
                        <CircularProgress className={this.props.classes.circularProgress} color="primary" />
                        {this.props.t('dialogs:willRedirect')}
                    </div>
                )}
            </>
        );
    }
}

export default withTranslation('dialogs')(withStyles(styles, { withTheme: true })(AcceptInvitation));
