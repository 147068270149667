import { Theme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container, { ContainerProps } from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { WithStyles, WithTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';

export const styles = (theme: Theme) =>
    createStyles({
        headerContainer: {
            height: '60px',
            paddingBottom: `${theme.spacing(1)}`,
            paddingTop: `${theme.spacing(1.5)}`,
            paddingLeft: `${theme.spacing(3)}`,
            paddingRight: `${theme.spacing(3)}`,
        },
        title: {
            color: theme.palette.primary.dark,
            fontSize: '26px',
            fontWeight: 600,
        },
        content: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            padding: theme.spacing(3),
            paddingTop: theme.spacing(1),
        },
    });

export interface NearbuyWindowProps
    extends WithStyles<typeof styles>,
        WithTranslation,
        WithTheme,
        PropsWithChildren<any> {
    title?: string;
    maxWidth?: ContainerProps['maxWidth'];
    fixed?: boolean;
    padding?: number;
    contentPaddingTop?: number;
    cardContentPadding?: number;
    margin?: number | 'auto';
    className?: string;
    hasTabs?: boolean;
    titles?: string[];
    showResultsByValue?: (value: string) => void;
    showMandatoryHint?: boolean;
}

class NearbuyWindow extends ComponentBase<NearbuyWindowProps> {
    render(): React.ReactElement {
        return (
            <Container
                maxWidth={this.props.maxWidth || 'xl'}
                fixed={this.props.fixed}
                style={{
                    padding: this.props.padding ? this.props.theme.spacing(this.props.padding) : 0,
                    margin: this.props.margin
                        ? typeof this.props.margin === 'number'
                            ? this.props.theme.spacing(this.props.margin)
                            : this.props.margin
                        : 0,
                    minWidth: '200px',
                }}
            >
                <Card
                    sx={{
                        borderRadius: '10px',
                        boxShadow: ' 0px 5px 10px 3px rgba(0, 0, 0, 0.05)',
                    }}
                    className={this.props.className}
                >
                    <Grid
                        container
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className={this.props.classes.headerContainer}
                    >
                        <Grid item>
                            <Typography className={this.props.classes.title}>{this.props.title}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography hidden={!this.props.showMandatoryHint} sx={{ fontWeight: 600 }}>
                                {this.props.t('common:mandatory')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <CardContent
                        style={{
                            paddingTop:
                                this.props.contentPaddingTop !== null && this.props.contentPaddingTop !== undefined
                                    ? this.props.theme.spacing(this.props.contentPaddingTop)
                                    : undefined,
                            padding:
                                this.props.cardContentPadding !== null && this.props.cardContentPadding !== undefined
                                    ? this.props.theme.spacing(this.props.cardContentPadding)
                                    : undefined,
                        }}
                        className={this.props.classes.content}
                    >
                        {this.props.children}
                    </CardContent>
                </Card>
            </Container>
        );
    }
}

export default withTranslation('common')(withStyles(styles, { withTheme: true })(NearbuyWindow));
