import { ReportProblem } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useTheme } from '@mui/styles';
import { ExtendedTheme } from 'style/NearbuyStyle';
import { withResubAutoSubscriptions } from 'resub';
import { useHistory } from 'react-router-dom';

function HintComponent(props: {
    text: string;
    isWarningSymbolShown?: boolean;
    isInformationSymbolShown?: boolean;
    isHintSmall?: boolean;
    link?: string;
}) {
    /* Hooks */
    const theme = useTheme<ExtendedTheme>();
    const history = useHistory();
    /* States and Store Subscribed Constants */
    /* Effects */
    /* Styles und StyledComponents */
    /* JSX */

    const handleClick = () => {
        if (props.link != undefined) {
            history.push(props.link);
        }
    };

    const text = (
        <Grid
            item
            md={props.isWarningSymbolShown || props.isInformationSymbolShown ? (props.isHintSmall ? 10 : 11) : 12}
        >
            <Typography
                onClick={handleClick}
                sx={{
                    fontSize: 15,
                    lineHeight: theme.spacing(2.5),
                    padding: theme.spacing(0.5),
                }}
            >
                {props.text}
            </Typography>
        </Grid>
    );

    const icon =
        props.isWarningSymbolShown || props.isInformationSymbolShown ? (
            <Grid
                item
                md={props.isHintSmall ? 2 : 1}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {props.isWarningSymbolShown ? (
                    <ReportProblem
                        sx={{
                            color: theme.palette.secondary.dark,
                        }}
                    />
                ) : (
                    <InfoOutlinedIcon
                        sx={{
                            color: theme.palette.secondary.dark,
                        }}
                    />
                )}
            </Grid>
        ) : null;

    return (
        <Grid
            container
            direction={'row'}
            sx={{
                backgroundColor: theme.palette.secondary.lighter,
                borderRadius: 4,
                margin: `${theme.spacing(1.5)} 0 0 0`,
                padding: theme.spacing(1),
                cursor: props.link ? 'pointer' : undefined,
            }}
        >
            {icon}
            {text}
        </Grid>
    );
}
export default withResubAutoSubscriptions(HintComponent);
