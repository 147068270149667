import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import { AlertStore } from 'store';

export class AlertsInitializer implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        AlertStore.clear();
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new AlertsInitializer(),
);
