import { forkJoin, Observable, of } from 'rxjs';
import { getUuidFromString } from 'util/helpers';
import http from 'api/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { CompanyDemandStore, DemandStore } from 'store';
import { CompanyDemand, Demand, DemandWriteView } from 'model/Demand';

class DemandService {
    addDemand(companyDemand: DemandWriteView, companyId: string): Observable<Demand> {
        return http
            .getAxios()
            .post<Demand>('demands', companyDemand, { headers: { 'Current-Company': companyId } })
            .pipe(
                map((response) => response.data),
                switchMap((demand) =>
                    forkJoin({
                        demand: of(demand),
                        companyDemand: CompanyDemandStore.loadOne(demand.links.company),
                    }),
                ),
                switchMap((objects) => DemandStore.loadOne(objects.demand.links.self)),
            );
    }

    getDemand(demandRef: string): Observable<Demand> {
        return http
            .getAxios()
            .get<Demand>(`${demandRef}`)
            .pipe(map((value) => value.data));
    }

    deleteDemand(demand: Demand, companyId: string): Observable<unknown> {
        return http
            .getAxios()
            .delete(`${demand.links.self}`, { headers: { 'Current-Company': companyId } })
            .pipe(
                tap(() =>
                    CompanyDemandStore.loadOne(demand.links.company).subscribe(() =>
                        DemandStore.removeOneById(demand.links.self),
                    ),
                ),
            );
    }

    getCompanyDemand(companyRef: string): Observable<CompanyDemand> {
        return http
            .getAxios()
            .get<string[]>('demands/company/' + getUuidFromString(companyRef))
            .pipe(map((response) => new CompanyDemand(companyRef, response.data)));
    }
}

export default new DemandService();
