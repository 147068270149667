import { Card, Grid, Theme } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ContainerType } from 'model/ContainerView';
import { MetaStaticEntityType } from 'model/Meta';
import { Offer } from 'model/Offer';
import { Product } from 'model/Product';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { MetaStore, OfferStore, ProductStore } from 'store';
import { theme } from 'style/NearbuyStyle';
import MarketItemTitle from 'components/messaging/chat/messagefactory/infocards/shared/MarketItemTitle';

const _styles = (theme: Theme) =>
    createStyles({
        dot: {
            color: theme.palette.primary.main,
            margin: theme.spacing(1),
        },
        textBold: {
            textAlign: 'left',
            fontWeight: 'bold',
        },
        category: {
            textAlign: 'left',
            marginTop: theme.spacing(1),
            fontSize: 18,
            fontWeight: 'bold',
        },
        offerCard: {
            backgroundColor: theme.palette.background.default,
        },
        cardContent: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.default,
        },
        text: {
            textAlign: 'left',
        },
        plainCard: {
            border: 'none',
            boxShadow: 'none',
            '& .MuiCardActions-root': {
                padding: '0',
            },
        },
    });

interface OfferInfoCardProps extends WithTranslation, WithStyles<typeof _styles> {
    offerRef?: string;
    withShowOfferOrDetailsButton?: boolean;
    hasBackground?: boolean;
}

interface OfferInfoCardState {
    offer: Offer;
    product: Product;
    containerTypes: ContainerType[];
    isSeller: boolean;
}

class OfferInfoCard extends ComponentBase<OfferInfoCardProps, OfferInfoCardState> {
    protected _buildState(
        props: OfferInfoCardProps,
        initialBuild: boolean,
        incomingState?: Readonly<OfferInfoCardState>,
    ): Partial<OfferInfoCardState> | undefined {
        const newState: Partial<OfferInfoCardState> = {
            ...incomingState,
        };

        if (props.offerRef) {
            newState.offer = OfferStore.getOne(props.offerRef);
        }

        if (newState.offer) {
            newState.product = ProductStore.getOne(newState.offer.links.category);
        }

        const metaContainerTypes = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);
        newState.containerTypes = (metaContainerTypes?.data ?? []) as ContainerType[];

        return newState;
    }

    showAmount(): ReactNode {
        if (!this.state.offer) return;

        return (
            <Grid container>
                <Grid container spacing={1}>
                    <Grid item xs={1} />
                    <Grid container item xs={4}>
                        <Typography>{this.props.t('offer:amount')}</Typography>
                    </Grid>
                    <Grid container item xs={7}>
                        <Typography className={this.props.classes.textBold}>
                            {this.state.offer.totalAmount.amount +
                                ' ' +
                                this.props.t('shortunits:' + this.state.offer.totalAmount.unit)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    showPrices(): ReactNode {
        if (!this.state.offer) return;
        return (
            <Grid container item spacing={1}>
                <Grid item xs={1} />
                {this.state.offer.pricePerUnit ? (
                    <>
                        <Grid item xs={4}>
                            <Typography className={this.props.classes.text}>
                                {this.props.t('offer:pricePer') +
                                    ' ' +
                                    this.props.t('shortunits:' + this.state.offer.totalAmount.unit)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={this.props.classes.textBold}>
                                {this.state.offer.pricePerUnit.toFixed(2) + ' ' + this.props.t('offer:currency')}
                            </Typography>
                        </Grid>
                    </>
                ) : (
                    <Grid item marginTop={theme.spacing(2)}>
                        <Grid item xs={1} />
                        <Grid item>
                            <Typography className={this.props.classes.textBold}>
                                {this.props.t('offer:priceUponRequest')}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }

    render(): ReactNode {
        if (!(this.state.offer && this.state.product)) return null;
        return (
            <>
                <Card
                    className={`${
                        this.props.hasBackground ? this.props.classes.offerCard : this.props.classes.plainCard
                    }`}
                >
                    <CardContent className={this.props.classes.cardContent}>
                        <MarketItemTitle
                            offerRef={this.props.offerRef}
                            productLabel={this.state.product?.label}
                            levelsOfProcessing={
                                this.state.offer?.levelsOfProcessing ?? this.state.offer?.levelsOfProcessing
                            }
                            hasButton={this.props.withShowOfferOrDetailsButton}
                        />
                        {this.showAmount()}
                        {this.showPrices()}
                    </CardContent>
                </Card>
            </>
        );
    }
}

export default withTranslation(['offer', 'shortunits'])(withStyles(_styles)(OfferInfoCard));
