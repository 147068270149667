import * as React from 'react';
import { AppBar, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import NearbuyWindow from 'components/shared/NearbuyWindow';
import { withTranslation, WithTranslation } from 'react-i18next';
import Toolbar from '@mui/material/Toolbar';
import { ReactSVG } from 'react-svg';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100vh',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.background.paper,
        },
        toolBar: {
            padding: 0,
            margin: 0,
            justifyContent: 'space-between',
            display: 'flex',
            flexWrap: 'nowrap',
        },
        logo: {
            maxHeight: 50,
            maxWidth: 125,
            padding: theme.spacing(0.5),
            display: 'block',
        },
        logoButton: {
            padding: 0,
            marginLeft: 8,
        },
        header: {
            fontSize: '32px',
            fontStyle: 'bold',
            textAlign: 'center',
        },
        content: {
            fontSize: 'medium',
            padding: '3%',
            textAlign: 'center',
        },
        image: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '25%',
            display: 'block',
        },
    });

interface MaintenanceComponentProps extends WithStyles<typeof styles>, WithTranslation {}

class MaintenanceComponent extends ComponentBase<MaintenanceComponentProps> {
    render(): React.ReactElement {
        return (
            <div className={this.props.classes.root}>
                <AppBar position={'fixed'} className={this.props.classes.appBar}>
                    <Toolbar className={this.props.classes.toolBar}>
                        <div className={this.props.classes.logoButton}>
                            <ReactSVG
                                src="/logo/nearbuy.svg"
                                beforeInjection={(svg: Element): void => {
                                    svg.classList.add(this.props.classes.logo);
                                }}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                <NearbuyWindow maxWidth="xl" title={this.props.t('maintenance:title')} margin={10}>
                    <Typography className={this.props.classes.header}>{this.props.t('maintenance:header')}</Typography>
                    <Typography className={this.props.classes.content}>
                        {this.props.t('maintenance:contentTop')}
                    </Typography>
                    <img
                        title={'maintenance'}
                        className={this.props.classes.image}
                        alt="maintenance"
                        src={'/images/Laptop.png'}
                    />
                    <Typography className={this.props.classes.content}>
                        {this.props.t('maintenance:contentBottom')}
                    </Typography>
                </NearbuyWindow>
            </div>
        );
    }
}

export default withTranslation('maintenance')(withStyles(styles, { withTheme: true })(MaintenanceComponent));
