import { SubstitutePerson } from 'model/SubstitutePerson';
import { Observable } from 'rxjs';
import http from 'api/http';
import { map } from 'rxjs/operators';

class SubstitutePersonService {
    getSubstitutePerson(substitutePersonRef: string): Observable<SubstitutePerson> {
        return http
            .getAxios()
            .get<SubstitutePerson>(`${substitutePersonRef}`)
            .pipe(map((value) => value.data));
    }
}

export default new SubstitutePersonService();
