import * as React from 'react';
import { Card, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ProductStore, RequestStore } from 'store';
import { Request } from 'model/Request';
import { Product } from 'model/Product';
import { withTranslation, WithTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

const _styles = (theme: Theme) =>
    createStyles({
        button: {
            flex: 1,
            margin: 0,
            padding: '2 0 2 0',
            textTransform: 'none',
            color: theme.palette.text.primary,
        },
        dot: {
            maxWidth: '22px',
            maxHeight: '22px',
            color: theme.palette.primary.main,
        },
        category: {
            textAlign: 'left',
            margin: 0,
        },
        listItemText: {
            fontWeight: '500',
            fontSize: 15,
            maxHeight: '3em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        dashletResultItemCard: {
            display: 'flex',
            justifyContent: 'stretch',
            flexGrow: 1,
            flexDirection: 'column',
            backgroundColor: theme.palette.background.default,
        },
        cardContent: {
            padding: theme.spacing(0.5),
            '&:last-child': {
                paddingBottom: 0,
            },
            cursor: 'pointer',
            backgroundColor: theme.palette.background.default,
        },
        gridContainer: {
            display: 'inline-flex',
            flexWrap: 'nowrap',
        },
    });

interface RequestReferenceComponentProps extends WithStyles<typeof _styles>, WithTranslation {
    requestRef: string;
    onClick?: () => void;
}

interface RequestReferenceComponentState {
    request?: Request;
    category?: Product;
    editDialogOpen?: boolean;
}

class RequestReferenceComponent extends ComponentBase<RequestReferenceComponentProps, RequestReferenceComponentState> {
    protected _buildState(
        props: RequestReferenceComponentProps,
        initialBuild: boolean,
        incomingState?: Readonly<RequestReferenceComponentState>,
    ): Partial<RequestReferenceComponentState> | undefined {
        const request = RequestStore.getOne(props.requestRef);
        return {
            request,
            category: request ? ProductStore.getOne(request.links.category) : undefined,
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.request || !this.state.category) {
            return null;
        }
        return (
            <Grid item container xs={12}>
                <Card className={this.props.classes.dashletResultItemCard}>
                    <CardContent className={this.props.classes.cardContent}>
                        <Grid container className={this.props.classes.gridContainer}>
                            <Button
                                disableRipple
                                onClick={() => this.props.onClick?.()}
                                className={this.props.classes.button}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <FiberManualRecordIcon className={this.props.classes.dot} />
                                    </Grid>
                                    <Grid item xs={6} className={this.props.classes.category}>
                                        <Typography className={this.props.classes.listItemText}>
                                            {this.props.t(this.state.category.label, {
                                                ns: 'ontofood',
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography
                                            className={this.props.classes.listItemText}
                                            sx={{ textAlign: 'right' }}
                                        >
                                            {this.state.request.totalAmount.toLocaleString('de-DE')}{' '}
                                            {this.props.t(this.state.request.unit, {
                                                ns: 'shortunits',
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default withTranslation(['ontofood', 'shortunits'])(
    withStyles(_styles, { withTheme: true })(RequestReferenceComponent),
);
