import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import InitializationManager from 'components/initialization/InitializationManager';
import { InitializationPhase } from 'components/initialization/InitializationPhase';
import { CompanyStore, PersonEmploymentStore, PersonStore, SystemMessageSeenStore } from 'store';
import moment from 'moment';
import UserInitializer from 'components/initialization/initializers/UserInitializer';
import SettingsLoader from 'components/initialization/initializers/SettingsLoader';

export class SystemMessageSeenInitializer implements InitializationEventConsumer {
    async onInitializationEvent(): Promise<void> {
        const currentCompany = CompanyStore.getSelected();
        const currentPerson = PersonStore.getSelected();
        if (!currentPerson) {
            return;
        }
        PersonEmploymentStore.loadOne(currentPerson.links.self).subscribe((employments) => {
            const employment = employments?.employments.find(
                (employment) => employment.links.company == currentCompany?.links.self,
            );
            if (!employment) {
                return;
            }
            const messagesEntity = SystemMessageSeenStore.getOne(employment.links.self);
            SystemMessageSeenStore.setOne({
                employmentRef: employment.links.self,
                seenMessages: [
                    ...(messagesEntity?.seenMessages.filter(
                        (message) =>
                            !message.validUntil || moment().isBefore(moment(message.validUntil).add(2, 'days')),
                    ) ?? []),
                ],
            });
            SystemMessageSeenStore.persist();
        });
    }
}

export default InitializationManager.registerInitializationPhaseConsumer(
    InitializationPhase.AUTHORIZED,
    new SystemMessageSeenInitializer(),
    [UserInitializer, SettingsLoader],
);
