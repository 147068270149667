import React, { ReactNode } from 'react';
import { ComponentBase } from 'resub';
import withStyles from '@mui/styles/withStyles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CompanyContactsStore } from 'store';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { ExtendedTheme, theme } from 'style/NearbuyStyle';
import createStyles from '@mui/styles/createStyles';
import ContactInfoCardComponent from 'components/company/view/ContactInfoCardComponent';
import ShowMoreButton from 'components/shared/ShowMoreButton';

const styles = (_theme: ExtendedTheme) =>
    createStyles({
        titleContainer: {
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
    });

interface CompanyContactsComponentProps extends WithStyles<typeof styles>, WithTranslation {
    companyRef: string;
}

interface CompanyContactsComponentState {
    contactRefs: string[] | undefined;
    isShowMoreExpanded: boolean;
}

class CompanyContactsComponent extends ComponentBase<CompanyContactsComponentProps, CompanyContactsComponentState> {
    MAX_CONTACTS = 6;

    protected _buildState(
        props: CompanyContactsComponentProps,
        initialBuild: boolean,
        incomingState?: CompanyContactsComponentState,
    ): Partial<CompanyContactsComponentState> | undefined {
        const newState: Partial<CompanyContactsComponentState> = {};

        newState.contactRefs = CompanyContactsStore.getOne(props.companyRef)?.contactPersons;

        if (initialBuild) {
            newState.isShowMoreExpanded = false;
        }

        return newState;
    }

    handleShowMoreButtonOnClick() {
        this.setState({
            isShowMoreExpanded: !this.state.isShowMoreExpanded,
        });
    }

    showButton(): ReactNode {
        const shouldShowButton = this.state.contactRefs && this.state.contactRefs.length > this.MAX_CONTACTS;
        return shouldShowButton ? (
            <ShowMoreButton
                onClick={() => {
                    this.handleShowMoreButtonOnClick();
                }}
                type={'cards'}
            />
        ) : null;
    }

    showTitleBar(): ReactNode {
        return (
            <Grid container className={this.props.classes.titleContainer}>
                <Typography
                    data-testid={'titleContactPersons'}
                    sx={{
                        fontSize: '22px',
                        fontWeight: 600,
                        color: theme.palette.primary.dark,
                    }}
                >
                    {this.props.t('company:contactPersons')}
                </Typography>
                {this.showButton()}
            </Grid>
        );
    }

    showContacts(): ReactNode {
        if (!this.state.contactRefs) return;

        const contactsToShow = this.state.isShowMoreExpanded
            ? this.state.contactRefs
            : this.state.contactRefs.slice(0, this.MAX_CONTACTS);

        return (
            <Grid container spacing={2}>
                {contactsToShow.map((contactRef) => (
                    <Grid item xl={4} lg={6} md={12} key={contactRef}>
                        <ContactInfoCardComponent contactRef={contactRef} />
                    </Grid>
                ))}
            </Grid>
        );
    }

    showDefaultText(): ReactNode {
        return (
            <Typography
                sx={{
                    fontSize: '18px',
                    fontWeight: 600,
                    color: theme.palette.primary.dark,
                    textAlign: 'center',
                    margin: '120px 2%',
                }}
            >
                {this.props.t(`company:presentationContactsEmptyStateText`)}
            </Typography>
        );
    }

    render(): ReactNode {
        const gridStyle = { backgroundColor: theme.palette.background.default, padding: '24px', borderRadius: '12px' };
        return (
            <Grid container direction="column" spacing={2} sx={gridStyle}>
                <Grid>{this.showTitleBar()}</Grid>
                <Grid>{this.state.contactRefs?.length === 0 ? this.showDefaultText() : this.showContacts()}</Grid>
            </Grid>
        );
    }
}

export default withTranslation('company')(withStyles(styles, { withTheme: true })(CompanyContactsComponent));
