import { ResultFormat, ResultItem } from 'model/ResultItem';
import qs from 'qs';
import { Observable } from 'rxjs';
import http from 'api/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { Offer, OfferContainers, OfferWriteView } from 'model/Offer';
import moment from 'moment';
import { CompanyOfferStore, ContainerStore, OfferContainersStore, OfferStore } from 'store';
import { convertDateOfProduct, convertRefsToUuids } from 'util/helpers';

export interface OfferSearchQuery {
    format?: ResultFormat;
    limit?: number;
    offset?: number;
    lat1?: number;
    lon1?: number;
    lat2?: number;
    lon2?: number;
    category?: string[];
    afterDate?: string;
    beforeDate?: string;
    amountMax?: number;
    amountMin?: number;
    amountUnit?: string;
    description?: string;
    companyName?: string;
    companies?: string[];
    showOnlyFavourites?: boolean;
    showOwnData?: boolean;
    includeDeactivated?: boolean;
}

class OfferService {
    public getOffer(offerRef: string): Observable<Offer> {
        return http
            .getAxios()
            .get<Offer>(`${offerRef}`)
            .pipe(
                map((value) => value.data),
                map((offer) => convertDateOfProduct(offer)),
                map((offer) => {
                    if (offer.pricePerUnit === undefined) {
                        offer.pricePerUnit = null;
                    }
                    offer.dateFrom = moment(offer.dateFrom);
                    offer.dateEnd = moment(offer.dateEnd);
                    return offer;
                }),
            );
    }

    public getContainerByOffer(offerRef: string): Observable<OfferContainers> {
        return http
            .getAxios()
            .get<string[]>(offerRef + '/containers')
            .pipe(map((resp) => new OfferContainers(offerRef, resp.data)));
    }

    public addContainerToOffer(offerRef: string, containerRef: string): Observable<OfferContainers> {
        return http
            .getAxios()
            .post<number>(offerRef + '/containers', containerRef)
            .pipe(
                map((value) => value.data),
                switchMap(() => OfferContainersStore.loadOne(offerRef)),
            );
    }

    public deleteContainerByOffer(offerRef: string, containerId: string): Observable<unknown> {
        return http
            .getAxios()
            .delete(offerRef + '/containers/' + containerId)
            .pipe(
                tap(() => ContainerStore.removeOneById(containerId)),
                tap(() => OfferContainersStore.loadOne(offerRef)),
            );
    }

    public updateOffer(offer: OfferWriteView, offerId: string, companyRef: string): Observable<Offer> {
        const offerWriteView: OfferWriteView = {
            ...offer,
            levelsOfProcessing: convertRefsToUuids(offer.levelsOfProcessing),
            pricePerUnit: offer.pricePerUnit ?? null,
        };
        return http
            .getAxios()
            .put<Offer>(`/offers/${offerId}`, offerWriteView)
            .pipe(
                map((resp) => resp.data),
                tap(() => OfferStore.loadSearched({ companies: [companyRef] })),
                map((offer) => convertDateOfProduct(offer)),
                tap((offer) => OfferStore.setOne(offer)),
                tap((offer) => CompanyOfferStore.setOne(offer)),
            );
    }

    public searchOffersAsRefs(queryParams: OfferSearchQuery): Observable<string[]> {
        const stringifiedQueryParams = qs.stringify(
            { ...queryParams, format: ResultFormat.REFERENCE },
            { arrayFormat: 'repeat', encode: true },
        );
        return http
            .getAxios()
            .get<string[]>(`/offers?${stringifiedQueryParams}`)
            .pipe(map((response) => response.data));
    }

    public searchOffersAsResults(queryParams: OfferSearchQuery): Observable<ResultItem[]> {
        const stringifiedQueryParams = qs.stringify(
            { ...queryParams, format: ResultFormat.SEARCH_RESULT },
            { arrayFormat: 'repeat', encode: true },
        );
        return http
            .getAxios()
            .get<ResultItem[]>(`/offers?${stringifiedQueryParams}`)
            .pipe(
                map((response) => response.data),
                map((resultItems) => {
                    return resultItems.map((resultItem) => {
                        if (resultItem.product) {
                            resultItem.product.dateStart = moment(resultItem.product.dateStart);
                            resultItem.product.dateEnd = moment(resultItem.product.dateEnd);
                        }
                        return resultItem;
                    });
                }),
            );
    }

    public getOfferIds(companyRef: string): Observable<string[]> {
        return http
            .getAxios()
            .get<Offer[]>(companyRef + '/offers')
            .pipe(
                map((response) => response.data),
                map((offers) => {
                    for (const offer of offers) {
                        offer.dateFrom = moment.utc(offer.dateFrom).local();
                        offer.dateEnd = moment.utc(offer.dateEnd).local();
                        if (offer.dateCreated) offer.dateCreated = moment.utc(offer.dateCreated).local();
                        if (offer.dateModified) offer.dateModified = moment.utc(offer.dateModified).local();
                    }
                    return offers;
                }),
                tap((offers) => {
                    offers.forEach((offer) => OfferStore.setOne(offer));
                }),
                map((offers) => {
                    return offers.map((offer) => offer.links.self);
                }),
            );
    }

    public addOffer(offer: OfferWriteView, companyRef: string): Observable<Offer> {
        const offerWriteView: OfferWriteView = {
            ...offer,
            levelsOfProcessing: convertRefsToUuids(offer.levelsOfProcessing),
        };

        return http
            .getAxios()
            .post<Offer>(companyRef + '/offers', offerWriteView)
            .pipe(
                map((response) => response.data),
                tap((_offer) => CompanyOfferStore.loadSearched(companyRef)),
                tap((_offer) => OfferStore.loadSearched({ companies: [companyRef] })),
            );
    }

    public deleteOffer(offerId: string, companyRef: string): Observable<unknown> {
        return http
            .getAxios()
            .delete(`/offers/${offerId}`)
            .pipe(
                tap(() => OfferStore.removeOneById(offerId)),
                tap(() => CompanyOfferStore.loadSearched(companyRef)),
                tap(() => OfferStore.loadSearched({ companies: [companyRef] })),
            );
    }
}

export default new OfferService();
