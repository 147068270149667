import { ImageInfo } from 'model/Image';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AssociationStore, ImageStore } from 'store';
import { Association, AssociationWriteView } from 'model/Association';
import http from 'api/http';

class AssociationService {
    public listAssociations(): Observable<Association[]> {
        return http
            .getAxios()
            .get<Association[]>('associations')
            .pipe(map((response) => response.data));
    }

    public getAssociation(associationRef: string): Observable<Association> {
        return http
            .getAxios()
            .get<Association>(associationRef)
            .pipe(map((response) => response.data));
    }

    public createAssociation(association: AssociationWriteView): Observable<Association> {
        return http
            .getAxios()
            .post<Association>('associations', association)
            .pipe(
                map((response) => response.data),
                tap((assoc) => AssociationStore.setOne(assoc)),
            );
    }

    public updateAssociation(association: Association): Observable<Association> {
        return http
            .getAxios()
            .put<Association>(
                association.links.self,
                new AssociationWriteView(
                    association.description,
                    association.certifiedOrganic,
                    association.productRelevant,
                    association.category,
                    association.verificationUrl,
                ),
            )
            .pipe(
                map((response) => response.data),
                tap((assoc) => AssociationStore.setOne(assoc)),
            );
    }

    public deleteAssociation(association: Association): Observable<unknown> {
        return http
            .getAxios()
            .delete<unknown>(association.links.self)
            .pipe(
                map((response) => response.data),
                tap(() => AssociationStore.removeOne(association)),
            );
    }

    public setLogo(association: Association, image: File): Observable<ImageInfo> {
        const data = new FormData();
        data.append('image', image, image.name);
        return http
            .getAxios()
            .post<ImageInfo>(association.links.self + '/logo', data)
            .pipe(
                map((response) => response.data),
                tap((img: ImageInfo) => ImageStore.setOne(img)),
                tap((img: ImageInfo) => {
                    //Maybe this update force can be better solved by a rxjs operator
                    association.links.icon = img.links.self;
                    AssociationStore.setOne(association);
                }),
            );
    }

    public updateAssociationAndLogo(association: Association, icon: File): Observable<Association> {
        return this.setLogo(association, icon).pipe(mergeMap(() => this.updateAssociation(association)));
    }

    public createAssociationAndLogo(association: AssociationWriteView, icon: File): Observable<ImageInfo> {
        return this.createAssociation(association).pipe(mergeMap((assoc: Association) => this.setLogo(assoc, icon)));
    }
}

export default new AssociationService();
