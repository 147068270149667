import { LatLngBoundsLiteral, LatLngTuple } from 'leaflet';
import getBoundsOfDistance from 'geolib/es/getBoundsOfDistance';
import { Address } from 'model/Address';

export type LatLonArray = [number, number];
export interface LatLon {
    lat: number;
    lon: number;
}
export interface LatLng {
    lat: number;
    lng: number;
}
export interface LatitudeLongitude {
    latitude: number;
    longitude: number;
}

export function LatLngToLatLon(coords: LatLng): LatLon {
    return { lat: coords.lat, lon: coords.lng };
}

export function LatLngToArray(coords: LatLng): LatLonArray {
    return [coords.lat, coords.lng];
}

export function LatLonToLatLng(coords: LatLon): LatLng {
    return { lat: coords.lat, lng: coords.lon };
}

export function LatLonToArray(coords: LatLon): LatLonArray {
    return [coords.lat, coords.lon];
}

export function LatitudeLongitudeToLatLon(coords: LatitudeLongitude): LatLon {
    return { lat: coords.latitude, lon: coords.longitude };
}

export function LatitudeLongitudeToLatLng(coords: LatitudeLongitude): LatLng {
    return { lat: coords.latitude, lng: coords.longitude };
}

export function LatitudeLongitudeToLeaflet(coords: LatitudeLongitude): [number, number] {
    return [coords.latitude, coords.longitude];
}

export function ArrayToLatLng(point: [number, number]): {
    lng: number;
    lat: number;
} {
    return { lat: point[0], lng: point[1] };
}

export function ArrayToLatLon(point: [number, number]): {
    lon: number;
    lat: number;
} {
    return { lat: point[0], lon: point[1] };
}

export function ArrayToLatitudeLongitude(point: [number, number]): {
    latitude: number;
    longitude: number;
} {
    return { latitude: point[0], longitude: point[1] };
}

export function LeafletLatLonToLatLng(bounds: LatLngTuple): {
    lng: number;
    lat: number;
} {
    return { lat: bounds[0], lng: bounds[1] };
}

export function LeafletLatLonToLatLngArray(bounds: LatLngTuple): [number, number] {
    return [bounds[0], bounds[1]];
}

export function LatLngBoundsToLatLonBounds(bounds: LatLng[]): LatLon[] {
    return bounds.map(LatLngToLatLon);
}

export function LatLngBoundsToArrayBounds(bounds: LatLng[]): LatLonArray[] {
    return bounds.map(LatLngToArray);
}

export function LatLonBoundsToLatLngBounds(bounds: LatLon[]): LatLng[] {
    return bounds.map(LatLonToLatLng);
}

export function LatLonBoundsToArrayBounds(bounds: LatLon[]): LatLonArray[] {
    return bounds.map(LatLonToArray);
}

export function ArrayBoundsToLatLng(bounds: [number, number][]): { lng: number; lat: number }[] {
    return bounds.map(ArrayToLatLng);
}

export function ArrayBoundsToLatLon(bounds: [number, number][]): { lon: number; lat: number }[] {
    return bounds.map(ArrayToLatLon);
}

export function ArrayBoundsToLatitudeLongitude(bounds: [number, number][]): { latitude: number; longitude: number }[] {
    return bounds.map(ArrayToLatitudeLongitude);
}

export function LeafletBoundsToLatLng(bounds: LatLngBoundsLiteral): { lng: number; lat: number }[] {
    return bounds.map(LeafletLatLonToLatLng);
}

export function LatitudeLongitudeBoundsToLatLon(bounds: LatitudeLongitude[]): LatLon[] {
    return bounds.map(LatitudeLongitudeToLatLon);
}

export function LatitudeLongitudeBoundsToLatLng(bounds: LatitudeLongitude[]): LatLng[] {
    return bounds.map(LatitudeLongitudeToLatLng);
}

export function LatitudeLongitudeBoundsToLeaflet(bounds: LatitudeLongitude[]): [number, number][] {
    return bounds.map(LatitudeLongitudeToLeaflet);
}

export function getBoundsByDistanceFromLatLon(distance: number, latLonArray: LatLonArray): LatLngBoundsLiteral {
    return getBoundsOfDistance(ArrayToLatLon(latLonArray), distance * 1000).map((point) => {
        return [point.latitude, point.longitude] as [number, number];
    });
}

export function getBoundsByDistanceFromHomeAddress(distance: number, address: Address): LatLngBoundsLiteral {
    const position: LatLon = { lat: address.lat, lon: address.lon };
    return getBoundsOfDistance(position, distance * 1000).map((point) => {
        return [point.latitude, point.longitude] as [number, number];
    });
}
