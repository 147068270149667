import * as React from 'react';
import { ComponentBase } from 'resub';
import { AssociationProposalSystemMessage } from 'model/SystemMessage';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { CompanyStore } from 'store';
import PersonStore from 'store/PersonStore';
import { Company } from 'model/Company';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface AssociationProposalSystemMessageItemProps extends RouteComponentProps {
    systemMessage: AssociationProposalSystemMessage;
}

interface AssociationProposalSystemMessageItemState {
    mapping?: AssociationProposalSystemMessageItemMapping;
    company?: Company;
}

interface AssociationProposalSystemMessageItemMapping extends Record<string, string> {
    firstName: string;
    lastName: string;
    companyName: string;
    associationName: string;
}

class AssociationProposalSystemMessageItem extends ComponentBase<
    AssociationProposalSystemMessageItemProps,
    AssociationProposalSystemMessageItemState
> {
    protected _buildState(
        props: AssociationProposalSystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<AssociationProposalSystemMessageItemState>,
    ): Partial<AssociationProposalSystemMessageItemState> | undefined {
        const person = PersonStore.getOne(props.systemMessage.links.person);
        const company = CompanyStore.getOne(props.systemMessage.links.company);

        if (person && company) {
            return {
                company,
                mapping: {
                    firstName: person.firstname,
                    lastName: person.lastname,
                    companyName: company.name,
                    associationName: props.systemMessage.text,
                },
            };
        }
    }

    linkToCompanyFullDetail(): void {
        if (this.state.company) {
            this.props.history.push('/company/' + this.state.company.id + '/detail');
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping) return null;

        return (
            <SystemMessageItem
                onClick={() => this.linkToCompanyFullDetail()}
                systemMessage={this.props.systemMessage}
                mapping={this.state.mapping}
            />
        );
    }
}

export default withRouter(AssociationProposalSystemMessageItem);
