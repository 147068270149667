import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { classNames } from 'util/style-helpers';

const IconComponent = (props: {
    icon: string;
    fill?: '0' | '1';
    weight?: '100' | '200' | '300' | '400' | '500' | '600' | '700';
    grade?: string;
    width?: string;
    className?: string;
}) => {
    const useStyles = makeStyles((theme) => ({
        symbolsVariable: {
            fontVariationSettings: `'FILL' ${props.fill || '1'}, 'wght' ${props.weight || '400'}, 'GRAD' ${
                props.grade || '0'
            }, 'opsz' ${props.width || '24'}`,
            transition: `color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
        },
    }));

    const classes = useStyles();

    return (
        <div>
            <span className={classNames('material-symbols-outlined', classes.symbolsVariable, props.className)}>
                {props.icon}
            </span>
        </div>
    );
};
export default IconComponent;
