import { Observable, of } from 'rxjs';
import { Person } from 'model/Person';
import { catchError, tap } from 'rxjs/operators';
import UserStore from 'store/UserStore';

export function handleUser(personObservable: Observable<Person | undefined>) {
    return personObservable.pipe(
        tap(() => UserStore.setUserExists(true)),
        catchError((error) => {
            if (error.response && error.response.status === 404) {
                UserStore.setUserExists(false);
            } else {
                UserStore.setUserExists(undefined);
            }
            return of(undefined);
        }),
    );
}
