import { ComponentBase } from 'resub';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NavStore from 'store/NavStore';

class RouteListener extends ComponentBase<RouteComponentProps<any>> {
    private isUpdating = false;

    componentDidUpdate(
        prevProps: Readonly<RouteComponentProps>,
        prevState: Readonly<Record<string, string>>,
        snapshot?: any,
    ): void {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        this.onRouteChanged();
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.onRouteChanged();
    }

    onRouteChanged(): void {
        if (!this.isUpdating) {
            this.isUpdating = true;
            NavStore.setLocation(this.props.location);
            this.isUpdating = false;
        }
    }

    render(): null {
        return null;
    }
}

export default withRouter(RouteListener);
