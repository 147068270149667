import * as React from 'react';
import { ComponentBase } from 'resub';
import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { TestSystemMessage } from 'model/SystemMessage';
import { PersonStore } from 'store';

interface TestSystemMessageItemProps {
    systemMessage: TestSystemMessage;
}

interface TestSystemMessageItemState {
    mapping?: TestSystemMessageItemMapping;
}

interface TestSystemMessageItemMapping extends Record<string, string> {
    name: string;
}

class TestSystemMessageItem extends ComponentBase<TestSystemMessageItemProps, TestSystemMessageItemState> {
    protected _buildState(
        props: TestSystemMessageItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<TestSystemMessageItemState>,
    ): Partial<TestSystemMessageItemState> | undefined {
        const person = PersonStore.getOne(this.props.systemMessage.links.receiverPerson);

        if (person) {
            return {
                mapping: {
                    name: person.firstname,
                },
            };
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping) return null;

        return (
            <SystemMessageItem
                onClick={() => console.log('test')}
                systemMessage={this.props.systemMessage}
                mapping={this.state.mapping}
            />
        );
    }
}

export default TestSystemMessageItem;
