import { InitializationPhase } from 'components/initialization/InitializationPhase';
import { InitializationEventConsumer } from 'components/initialization/InitializationEventConsumer';
import { autoSubscribe, AutoSubscribeStore, key, StoreBase } from 'resub';
import { delay } from 'util/helpers';

type RegistrableEvents = InitializationPhase | 'ALL';

@AutoSubscribeStore
class InitializationManager extends StoreBase {
    private phaseConsumer: Map<RegistrableEvents, Set<InitializationEventConsumer>> = new Map<
        RegistrableEvents,
        Set<InitializationEventConsumer>
    >();
    private currentPhase: InitializationPhase = InitializationPhase.NONE;
    private initializedPhases: Set<InitializationPhase> = new Set<InitializationPhase>();
    private dependencyMap: Map<InitializationEventConsumer, InitializationEventConsumer[]> = new Map<
        InitializationEventConsumer,
        InitializationEventConsumer[]
    >();

    @autoSubscribe
    isInitialisationDone(phase: InitializationPhase): boolean {
        return this.initializedPhases.has(phase);
    }

    /**
     * only for tests
     */
    _clear(): void {
        this.initializedPhases.clear();
        this.trigger();
    }

    endAuthorizing(isSuccessful: boolean): void {
        if (isSuccessful) {
            this.setPhase(InitializationPhase.AUTHORIZED);
        } else {
            this.setPhase(InitializationPhase.UNAUTHORIZED);
        }
    }

    registerInitializationPhaseConsumer(
        phase: RegistrableEvents,
        consumer: InitializationEventConsumer,
        dependsOn?: InitializationEventConsumer[],
    ): InitializationEventConsumer {
        let consumers = this.phaseConsumer.get(phase);
        if (!consumers) {
            const newSet = new Set<InitializationEventConsumer>();
            this.phaseConsumer.set(phase, newSet);
            consumers = newSet;
        }
        consumers.add(consumer);
        if (dependsOn) {
            this.dependencyMap.set(consumer, dependsOn);
        }
        return consumer;
    }

    async setPhase(phase: InitializationPhase): Promise<void> {
        this.currentPhase = phase;
        let consumers = this.phaseConsumer.get(phase);
        await this.fireEvents(phase, consumers);
        consumers = this.phaseConsumer.get('ALL');
        await this.fireEvents(phase, consumers);

        this.setPhaseDone(phase);
    }

    /**
     * This is technically not a full swap. The second array is inserted into the first one,
     * while the second one is cleared. Due to this function only being called if the first one is empty,
     * this is swapping buffers.
     * @param array1 empty array
     * @param array2
     */
    swapBuffers<t>(array1: t[], array2: t[]) {
        array1.push(...array2);
        array2.splice(0, array2.length);
    }

    async fireEvents(phase: InitializationPhase, consumers?: Set<InitializationEventConsumer>): Promise<void> {
        const mappedPromises = new Map<InitializationEventConsumer, Promise<void>>();
        const done = new Set<InitializationEventConsumer>();
        // at first do all consumers, that can be run without dependencies
        if (consumers) {
            const consumersWithoutDependencies = Array.from(consumers).filter(
                (consumer) => !this.dependencyMap.has(consumer),
            );
            for (const consumer of consumersWithoutDependencies) {
                const initDone = consumer.onInitializationEvent(phase);
                mappedPromises.set(consumer, initDone);
                // noinspection ES6MissingAwait
                initDone.then(() => done.add(consumer));
            }

            // now we need to do the others
            const consumersWithDependencies = Array.from(consumers).filter(
                (consumer) => consumersWithoutDependencies.filter((c) => c === consumer).length === 0,
            );

            const consumersToDo = Array.from<InitializationEventConsumer>(consumersWithDependencies);
            const consumersToDo2 = new Array<InitializationEventConsumer>();

            while (consumersToDo.length > 0) {
                const consumer = consumersToDo.shift();
                if (!consumer) {
                    break;
                }
                const dependencies = this.dependencyMap.get(consumer);
                if (dependencies) {
                    // if there are dependencies, we must ensure, that dependencies are met
                    const notFinishedDependencies = dependencies.filter((dependency) => !done.has(dependency));
                    if (notFinishedDependencies.length > 0) {
                        // we must wait for another dependency
                        consumersToDo2.push(consumer);
                        if (consumersToDo.length === 0) {
                            this.swapBuffers(consumersToDo, consumersToDo2);
                            // busy waiting (not ideal performance, but for now, this should work)
                            if (notFinishedDependencies.length > 0) {
                                await delay(100);
                                // Better, something like this: await mappedPromises.get(dependency);
                                // problem currently: We need to sort dependencies as they can depend on each other
                            }
                        }
                        continue;
                    }
                }

                const initDone = consumer.onInitializationEvent(phase);
                mappedPromises.set(consumer, initDone);
                // noinspection ES6MissingAwait
                initDone.then(() => done.add(consumer));
                if (consumersToDo.length === 0) {
                    // swap buffers without wait, because we have done an initialization
                    this.swapBuffers(consumersToDo, consumersToDo2);
                }
            }

            await Promise.all(mappedPromises.values());
        }
    }

    private setPhaseDone(phase: InitializationPhase): void {
        this.initializedPhases.add(phase);
        this.trigger(phase);
    }
}

key(InitializationManager.prototype, 'isInitialisationDone', 0);

export default new InitializationManager();
