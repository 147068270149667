import SystemMessageItem from 'components/systemmessage/items/standard/SystemMessageItem';
import { Company } from 'model/Company';
import { UpdatedMembershipSystemMessage } from 'model/SystemMessage';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ComponentBase } from 'resub';
import { AssociationStore, CompanyStore, MembershipStore, PersonStore } from 'store';

interface UpdatedMemberhsipSystemMessageItemProps extends WithTranslation, RouteComponentProps {
    systemMessage: UpdatedMembershipSystemMessage;
}

interface UpdatedMembershipSystemMessageItemState {
    mapping?: UpdatedMembershipSystemMessageItemMapping;
    company?: Company;
    description: string;
}

interface UpdatedMembershipSystemMessageItemMapping extends Record<string, string> {
    company: string;
    description: string;
    firstName: string;
    lastName: string;
}

class UpdatedMembershipSystemMessageItem extends ComponentBase<
    UpdatedMemberhsipSystemMessageItemProps,
    UpdatedMembershipSystemMessageItemState
> {
    protected _buildState(
        props: UpdatedMemberhsipSystemMessageItemProps,
        initialBuild: boolean,
        incomingState: Readonly<UpdatedMembershipSystemMessageItemState>,
    ): Partial<UpdatedMembershipSystemMessageItemState> | undefined {
        const newState: Partial<UpdatedMembershipSystemMessageItemState> = {};
        if (!newState.mapping) {
            const person = PersonStore.getOne(props.systemMessage.links.person);
            const company = CompanyStore.getOne(props.systemMessage.links.company);
            const membership = MembershipStore.getOne(props.systemMessage.links.membership);

            if (membership?.links.association) {
                const association = AssociationStore.getOne(membership.links.association);
                if (association && association.description) {
                    newState.description = association.description;
                }
            } else if (membership && membership.description) {
                newState.description = membership.description;
            }

            if (company && person && newState.description) {
                newState.company = company;
                newState.mapping = {
                    firstName: person.firstname,
                    lastName: person.lastname,
                    company: company.name,
                    description: newState.description,
                };
            }
        }
        return newState;
    }

    render(): React.ReactElement | null {
        if (!this.state.mapping || !this.state.company) return null;

        return (
            <SystemMessageItem
                onClick={() =>
                    this.state.company && this.props.history.push('/company/' + this.state.company.id + '/detail')
                }
                systemMessage={this.props.systemMessage}
                mapping={this.state.mapping}
            />
        );
    }
}

export default withRouter(withTranslation(['system'])(UpdatedMembershipSystemMessageItem));
