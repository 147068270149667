import { ComponentBase } from 'resub';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { WithStyles, WithTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Zoom from '@mui/material/Zoom';
import * as React from 'react';
import { ChangeEvent } from 'react';
import PersonService from 'api/PersonService';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { AutoCompleteCompany, Company, CompanyWriteView } from 'model/Company';
import CompanyService from 'api/CompanyService';
import { debounce } from 'util/helpers';
import { Person } from 'model/Person';
import { Redirect } from 'react-router-dom';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import NearbuyWindow from 'components/shared/NearbuyWindow';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CompanyFeatureStore, CompanyStore, PersonStore } from 'store/index';
import { handleUser } from 'api/PersonServiceUtil';
import Http from 'api/http';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(5),
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(5),
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
        },
        descriptionBox: {
            marginBottom: theme.spacing(5),
        },
        description: {
            textAlign: 'left',
        },
        textField: {
            backgroundColor: theme.palette.primary.light,
            margin: 0,
            marginTop: theme.spacing(2),
        },
        submit: {
            marginTop: theme.spacing(10),
            // TODO: if correct width is set, it is not centered
            // maxWidth: 130,
        },
        linebreak: {
            whiteSpace: 'pre-line',
        },
    });

interface RegistrationProps extends WithStyles<typeof styles>, WithTheme, WithTranslation {}

interface RegistrationState {
    surname?: string;
    name?: string;
    company?: AutoCompleteCompany;
    user?: Person | null;
    email?: string;
}

export class Registration extends ComponentBase<RegistrationProps, RegistrationState> {
    protected _buildState(props: RegistrationProps, initialBuild: boolean): Partial<RegistrationState> | undefined {
        if (initialBuild) {
            return {
                name: '',
                surname: '',
                company: undefined,
                email: Http.getEmail(),
            };
        }

        return {
            user: PersonStore.getSelected(),
        };
    }

    createLabel(inputValue: string): string {
        return this.props.t('dialogs:createCompany', {
            replace: { company: inputValue },
        });
    }

    getOptionLabel(company: AutoCompleteCompany): string {
        if (company.__isNew__ && company.createLabel) {
            return company.createLabel;
        } else {
            return company ? (company.labelName ? company.name + ' (' + company.labelName + ')' : company.name) : '';
        }
    }

    getNewOption(inputValue: string): AutoCompleteCompany {
        return new AutoCompleteCompany(inputValue, true, this.createLabel(inputValue));
    }

    save = (): void => {
        if (!this.state.surname || !this.state.name || !this.state.company) {
            // TODO: some kind of error message for the user...
            return;
        }
        PersonService.createNewUser({
            firstname: this.state.name,
            lastname: this.state.surname,
        }).subscribe((user: Person) => {
            const company = this.state.company;
            if (!company) {
                return;
            }
            if (!company.__isNew__ && user.links.self !== undefined) {
                // company exists already, ask for employment
                CompanyFeatureStore.setSelected(user.links.self);
                CompanyService.apply(company.links.self, user.links.self).subscribe(() => {
                    CompanyStore.setSelected(company.links.self);
                    window.location.replace('/');
                });
            } else {
                // company is new
                CompanyService.createAndSelectCompany(new CompanyWriteView(company.name)).subscribe({
                    next: (company) => {
                        CompanyFeatureStore.loadOne(company.links.self).subscribe();
                        CompanyFeatureStore.setSelected(company.links.self);
                        window.location.replace('/');
                    },
                });
            }
            handleUser(PersonService.me()).subscribe();
        });
    };

    // only singleselect, so we can assume there is no OptionTypes
    handleChangeCreate = (newValue: OnChangeValue<Company, false>): void => {
        const singleValue = newValue as Company;

        this.setState({
            company: !singleValue ? undefined : singleValue,
        });
    };

    handleOptionCreate = (inputValue: string): void => {
        const newCompany = new AutoCompleteCompany(inputValue, true);
        this.setState({
            company: newCompany,
        });
    };

    protected handleChange =
        (name: keyof RegistrationState) =>
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
            this.setState({
                ...this.state,
                [name]: event.target.value,
            });
        };

    render(): React.ReactElement {
        const { theme } = this.props;

        if (this.state.user) {
            return <Redirect to="/" />;
        }

        const selectStyles: Partial<StylesConfig<AutoCompleteCompany, false>> = {
            control: (base: any) => ({
                ...base,
                backgroundColor: theme.palette.primary.light,
                textAlign: 'left',
                flexGrow: 1,
                marginTop: theme.spacing(2),
                padding: 0,
            }),
            input: (base: any) => ({
                ...base,
                padding: 0,
                '& input': {
                    font: 'inherit',
                },
            }),
            menu: (base: any) => ({
                ...base,
                backgroundColor: theme.palette.primary.light,
                alignItems: 'center',
                position: 'revert',
                borderRadius: 0,
                marginTop: 0,
            }),
        };

        return (
            <Box className={this.props.classes.container}>
                <NearbuyWindow maxWidth="sm" margin={'auto'}>
                    <Box className={this.props.classes.descriptionBox}>
                        <span className={this.props.classes.description}>
                            {this.props.t('common:registrationDescription')}
                        </span>
                    </Box>
                    <form noValidate autoComplete="off" style={{ padding: 0 }}>
                        <Container className={this.props.classes.formContainer}>
                            <Tooltip
                                title={this.props.t('tooltips:forename') ?? 'forename'}
                                placement={'left-start'}
                                TransitionComponent={Zoom}
                            >
                                <TextField
                                    id="filled-surname"
                                    label={this.props.t('profile:firstName')}
                                    className={this.props.classes.textField}
                                    value={this.state.name}
                                    onChange={this.handleChange('name')}
                                    required
                                />
                            </Tooltip>
                            <Tooltip
                                title={this.props.t('tooltips:surname') ?? 'surname'}
                                placement={'left-start'}
                                TransitionComponent={Zoom}
                            >
                                <>
                                    <TextField
                                        id="filled-name"
                                        label={this.props.t('profile:lastName')}
                                        className={this.props.classes.textField}
                                        value={this.state.surname}
                                        onChange={this.handleChange('surname')}
                                        required
                                    />
                                    {this.state.email && (
                                        <TextField
                                            id="filled-mail"
                                            label={this.props.t('profile:email')}
                                            className={this.props.classes.textField}
                                            value={this.state.email}
                                            disabled
                                        />
                                    )}
                                </>
                            </Tooltip>
                            <Tooltip
                                title={
                                    <div className={this.props.classes.linebreak}>
                                        {this.props.t('tooltips:companyName')}
                                    </div>
                                }
                                placement={'left-start'}
                                TransitionComponent={Zoom}
                            >
                                <div>
                                    <AsyncCreatableSelect<AutoCompleteCompany, false>
                                        styles={selectStyles}
                                        value={this.state.company}
                                        getOptionLabel={this.getOptionLabel.bind(this)}
                                        getOptionValue={(company: AutoCompleteCompany): string =>
                                            `${company.links.self}`
                                        }
                                        getNewOptionData={this.getNewOption.bind(this)}
                                        formatCreateLabel={this.createLabel.bind(this)}
                                        isClearable
                                        onChange={this.handleChangeCreate}
                                        onCreateOption={this.handleOptionCreate}
                                        placeholder={this.props.t('company:companyName')}
                                        noOptionsMessage={() => this.props.t('company:companyNone')}
                                        allowCreateWhileLoading={false}
                                        loadOptions={debounce(
                                            CompanyService.searchWithPromise.bind(CompanyService),
                                            500,
                                        )}
                                    />
                                </div>
                            </Tooltip>
                            <Button
                                className={this.props.classes.submit}
                                color="primary"
                                variant="contained"
                                onClick={this.save}
                            >
                                {this.props.t('dialogs:participate')}
                            </Button>
                        </Container>
                    </form>
                </NearbuyWindow>
            </Box>
        );
    }
}

export default withTranslation(['common', 'company', 'tooltips', 'profile', 'dialogs'])(
    withStyles(styles, { withTheme: true })(Registration),
);
