import * as React from 'react';
import { ReactNode } from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { AlertStore, CompanyStore, EmploymentStore, MessagesUnseenStore, NavStore, PersonStore } from 'store';
import Box from '@mui/material/Box';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChatComponent from 'components/messaging/chat/ChatComponent';
import { MessageUnseen } from 'model/Message';
import MessageRestrictionDialog from 'components/messaging/MessageRestrictionDialog';
import { ComponentBase } from 'resub';
import { MessageTermsStatus } from 'model/Person';
import FeatureToggle from 'components/feature/FeatureToggle';
import { Feature } from 'model/Feature';
import DialogList from 'components/messaging/DialogList';
import http from 'api/http';
import { Employment } from 'model/Employment';
import { AlertType } from 'components/alert/Alerts';

const _styles = () =>
    createStyles({
        root: {
            display: 'flex',
        },
    });

interface MessageProps extends WithStyles<typeof _styles>, RouteComponentProps<MessageRoute> {}

interface MessageState {
    employment: Employment | undefined;
    isRestrictionDialogOpen: boolean;
    isRestrictionAlertShowing: boolean;
}

interface MessageRoute {
    formerEmployment?: string;
}

class MessageComponent extends ComponentBase<MessageProps, MessageState> {
    protected _buildState(
        props: MessageProps,
        initialBuild: boolean,
        incomingState?: Readonly<MessageState>,
    ): Partial<MessageState> | undefined {
        return {
            employment: props.match.params.formerEmployment
                ? EmploymentStore.getOne(http.getBaseUrl() + '/employments/' + props.match.params.formerEmployment)
                : EmploymentStore.getSelected(),
            isRestrictionDialogOpen: PersonStore.getSelected()?.messageTermsStatus === MessageTermsStatus.PENDING,
            isRestrictionAlertShowing: PersonStore.getSelected()?.messageTermsStatus === MessageTermsStatus.DECLINED,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        NavStore.setDrawerContent(<DialogList />);
        NavStore.setShowDrawer(true);
        this.setAlerts();
        this.setFormerEmployment();

        const company = CompanyStore.getSelected();
        if (company) {
            const messageUnseen = MessagesUnseenStore.getOne(company.links.self);

            if (messageUnseen) {
                MessagesUnseenStore.setOne(
                    new MessageUnseen(messageUnseen.companyRef, messageUnseen.messagesUnseenCount, true),
                );
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<MessageProps>, prevState: Readonly<never>, snapshot?: any) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        this.setAlerts();
        this.setFormerEmployment();
    }

    setAlerts() {
        if (this.state.isRestrictionAlertShowing) {
            AlertStore.addAlert(AlertType.DECLINED_MESSAGING);
        } else {
            AlertStore.removeAlert(AlertType.DECLINED_MESSAGING);
        }
        if (this.props.match.params.formerEmployment) {
            AlertStore.addAlert(AlertType.FOREIGN_INBOX);
        } else {
            AlertStore.removeAlert(AlertType.FOREIGN_INBOX);
        }
    }

    setFormerEmployment() {
        if (this.props.match.params.formerEmployment) {
            EmploymentStore.setSelectedFormerEmployment(
                EmploymentStore.getOne(`${http.getBaseUrl()}/employments/${this.props.match.params.formerEmployment}`),
            );
        } else {
            EmploymentStore.setSelectedFormerEmployment(undefined);
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        NavStore.setDrawerContent(undefined);
        NavStore.setShowDrawer(false);
        AlertStore.removeAlert(AlertType.DECLINED_MESSAGING);
        AlertStore.removeAlert(AlertType.FOREIGN_INBOX);
    }

    render(): ReactNode {
        if (!this.state.employment) return null;
        return (
            <FeatureToggle requiredFeatures={[Feature.USE_MESSAGING]} type={'allOf'}>
                <Box className={this.props.classes.root}>
                    <MessageRestrictionDialog isOpen={this.state.isRestrictionDialogOpen} />
                    <ChatComponent employmentRef={this.state.employment.links.self} />
                </Box>
            </FeatureToggle>
        );
    }
}

export default withStyles(_styles)(withRouter(MessageComponent));
