import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import NearbuyAlert from 'components/alert/NearbuyAlert';
import ReassignmentListDialog from 'components/company/edit/employees/ReassignmentDialog';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { Employment } from 'model/Employment';
import { EmploymentStore } from 'store';

const _styles = (theme: Theme) =>
    createStyles({
        infoButton: {
            backgroundColor: theme.palette.primary.main,
            textDecoration: 'none',
            color: theme.palette.common.white,
            marginRight: theme.spacing(3),
            borderColor: theme.palette.common.white,
        },
        infoLink: {
            textDecoration: 'none',
        },
    });

interface ForeignInboxAlertProps extends WithStyles<typeof _styles>, WithTranslation {}

interface ForeignInboxAlertState {
    isReassignmentViewOpen: boolean;
    formerEmployment?: Employment;
}

class ForeignInboxAlert extends ComponentBase<ForeignInboxAlertProps, ForeignInboxAlertState> {
    protected _buildState(
        props: ForeignInboxAlertProps,
        initialBuild: boolean,
        incomingState?: Readonly<ForeignInboxAlertState>,
    ): Partial<ForeignInboxAlertState> | undefined {
        return {
            formerEmployment: EmploymentStore.getSelectedFormerEmployment(),
        };
    }

    render(): React.ReactElement | null {
        if (!this.state.formerEmployment) {
            return null;
        }
        return (
            <>
                <ReassignmentListDialog
                    employment={this.state.formerEmployment}
                    isOpen={this.state.isReassignmentViewOpen}
                    onClose={(): void => this.setState({ isReassignmentViewOpen: false })}
                />
                <NearbuyAlert
                    button={
                        <Button
                            onClick={() =>
                                this.setState({
                                    isReassignmentViewOpen: true,
                                })
                            }
                            variant={'outlined'}
                            className={this.props.classes.infoButton}
                            size="large"
                        >
                            {this.props.t('messaging:foreignInboxButton')}
                        </Button>
                    }
                    content={this.props.t('messaging:foreignInboxText')}
                />
            </>
        );
    }
}

export default withTranslation('messaging')(withStyles(_styles, { withTheme: true })(ForeignInboxAlert));
