import * as React from 'react';
import Box from '@mui/material/Box';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Product } from 'model/Product';
import { ProductStore } from 'store';
import OntofoodTreeView, { ActionType, AddProductFunctionType } from 'components/ontofood/products/OntofoodTreeView';
import { ComponentBase } from 'resub';
import { getAllIdsUp } from 'components/ontofood/OntofoodHelpers';
import OntofoodSearchField from 'components/ontofood/OntofoodSearchField';

const _styles = (theme: Theme): StyleRules =>
    createStyles({
        selectField: {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(4),
        },
    });

export interface OntofoodTreeProps extends WithStyles<typeof _styles>, WithTranslation {
    addProduct?: AddProductFunctionType;
    selectionType: 'none' | 'single' | 'multi' | 'edit';
    productType?: keyof Pick<Product, 'company' | 'market'> | undefined;
    preselectedCategories?: Set<string>;
}

interface OntofoodTreeState {
    selectedCategory: Product | null;
    selectedCategoryRef?: string;
    selectedCategories?: Set<string>;
    expandedItems: string[];
    updateExpandedState: boolean;
}

class OntofoodTree extends ComponentBase<OntofoodTreeProps, OntofoodTreeState> {
    protected _buildState(
        props: OntofoodTreeProps,
        initialBuild: boolean,
        incomingState?: Readonly<OntofoodTreeState>,
    ): Partial<OntofoodTreeState> | undefined {
        const newState: Partial<OntofoodTreeState> = {};
        if (props.preselectedCategories && props.preselectedCategories !== incomingState?.selectedCategories) {
            newState.selectedCategories = props.preselectedCategories;
            if (props.selectionType === 'single') {
                // we need to set currently selectedProduct to the first item in the preselectedCategories
                if (props.preselectedCategories.size > 0) {
                    newState.selectedCategoryRef = props.preselectedCategories.values().next().value;
                } else {
                    newState.selectedCategoryRef = undefined;
                }
            }
            newState.updateExpandedState = true;
        }
        if (incomingState?.selectedCategoryRef) {
            newState.selectedCategory = ProductStore.getOne(incomingState?.selectedCategoryRef) || null;
        } else {
            newState.selectedCategory = null;
        }
        if (initialBuild) {
            newState.expandedItems = [];
        }
        return newState;
    }

    async expandToProduct(productRef: string): Promise<void> {
        const topProduct = ProductStore.getSelected();
        const expandedItems = await getAllIdsUp(productRef);

        if (!(topProduct && expandedItems.size > 0 && !expandedItems.has(topProduct.links.self))) {
            this.setState({
                updateExpandedState: false,
                expandedItems: Array.from(expandedItems),
            });
        }
    }

    handleValueChanged(newProduct: Product | null): void {
        if (this.props.selectionType === 'single') {
            if (this.props.addProduct) {
                if (newProduct) {
                    this.fireCheckedEvent(newProduct.links.self, true);
                }
            }
        }
        this.setState({
            selectedCategoryRef: newProduct ? newProduct.links.self : undefined,
            selectedCategory: newProduct,
            updateExpandedState: true,
        });
    }

    handleAddProduct(productRef: string, checked: boolean, type?: ActionType): void {
        if (checked) {
            const product = ProductStore.getOne(productRef);
            if (product) {
                // set selected product as new value
                const selectedOption = {
                    value: product.label,
                    label: this.props.t('ontofood:' + product.label),
                };

                const newState: any = {
                    selectedOption,
                };

                if (this.props.selectionType === 'single') {
                    newState.selectedCategory = product;
                    if (this.state.selectedCategoryRef) {
                        // send unchecked event of old value
                        this.callAddProduct(this.state.selectedCategoryRef, false, type);
                    }
                } else if (this.props.selectionType === 'multi') {
                    newState.selectedCategories = new Set(this.state.selectedCategories);
                    newState.selectedCategories.add(productRef);
                    newState.selectedCategory = product;
                }

                this.setState(newState);
            }
        } else {
            const selectedCategories = new Set(this.state.selectedCategories);
            selectedCategories.delete(productRef);
            this.setState({
                selectedCategories,
                selectedCategoryRef: undefined,
            });
        }

        this.callAddProduct(productRef, checked, type);
    }

    protected _componentDidRender(): void {
        super._componentDidRender();

        if (this.state.updateExpandedState && this.state.selectedCategoryRef) {
            this.expandToProduct(this.state.selectedCategoryRef);
        }
    }

    protected fireCheckedEvent(productRef: string, checked: boolean): void {
        if (this.props.addProduct) {
            if (checked && this.state.selectedCategoryRef) {
                this.callAddProduct(this.state.selectedCategoryRef, false);
            }
            if (productRef) {
                this.callAddProduct(productRef, checked);
            }
        }
    }

    protected callAddProduct(productRef: string, checked: boolean, type?: ActionType): void {
        if (this.props.addProduct) {
            this.props.addProduct(productRef, checked, type);
        } else {
            // handle state change internally
            if (checked) {
                // add newly checked to selected products:
                this.setState((prevState: OntofoodTreeState) => {
                    let selectedCategories = prevState.selectedCategories;
                    if (!selectedCategories) {
                        selectedCategories = new Set<string>();
                    } else {
                        selectedCategories = new Set<string>(selectedCategories);
                    }
                    selectedCategories.add(productRef);

                    return {
                        selectedCategories,
                        selectedCategoryRef: productRef,
                    };
                });
            } else {
                // remove previously selected
                this.setState((prevState: OntofoodTreeState) => {
                    let selectedCategories = prevState.selectedCategories;
                    if (!selectedCategories) {
                        selectedCategories = new Set<string>();
                    } else {
                        selectedCategories = new Set<string>(selectedCategories);
                    }
                    selectedCategories.delete(productRef);

                    return {
                        selectedCategories,
                        selectedCategoryRef: undefined,
                    };
                });
            }
        }
    }

    render(): React.ReactElement {
        const selectedOption = this.state.selectedCategoryRef ? [this.state.selectedCategoryRef] : [];
        let selectedOptions: Set<string> | undefined;
        if (this.props.selectionType === 'single') {
            selectedOptions = new Set<string>(selectedOption);
        } else if (this.props.selectionType === 'multi') {
            if (this.state.selectedCategories) {
                selectedOptions = new Set(Array.from(this.state.selectedCategories));
            } else {
                selectedOptions = new Set<string>();
            }
        }

        return (
            <Box>
                <OntofoodSearchField
                    value={this.state.selectedCategory}
                    onChange={(newValue): void => this.handleValueChanged(newValue?.value ?? null)}
                    productType={this.props.productType}
                />

                <Box className={this.props.classes.selectField}>
                    <OntofoodTreeView
                        selectedCategory={this.state.selectedCategory}
                        checkedCategories={selectedOptions}
                        addProduct={this.handleAddProduct.bind(this)}
                        expanded={this.state.expandedItems}
                        onToggle={(_event, nodes): void => {
                            this.setState({ expandedItems: nodes });
                        }}
                        editMode={this.props.selectionType === 'edit'}
                        isExpandedManaged
                        preloadSubcategories={false}
                        productType={this.props.productType}
                    />
                </Box>
            </Box>
        );
    }
}

export default withTranslation('ontofood')(withStyles(_styles, { withTheme: true })(OntofoodTree));
