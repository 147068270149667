import * as React from 'react';
import { ReactNode } from 'react';
import { ComponentBase } from 'resub';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormError } from 'util/FormErrors';
import NumberFormat, { type NumberFormatValues } from 'react-number-format';
import UnitSelectComponent from 'components/marketplace/UnitSelectComponent';
import { getUnitFromString, Unit, UnitType } from 'model/Unit';

export interface AmountSelectProperties {
    amountValue: string | number | undefined;
    onAmountChange: (numbers: NumberFormatValues) => void;
    amountLabel: string;
    isAmountRequired?: boolean;
    amountFormError?: FormError;

    unitValue: string | undefined;
    onUnitChange: (unit: Unit) => void;

    typeLimit?: UnitType;
    unitLimit?: Unit;

    isAmountReadOnly?: boolean;
    isUnitReadOnly?: boolean;
    maxValue?: number;

    fullLabelWidth?: boolean;
}

class AmountSelectComponent extends ComponentBase<AmountSelectProperties> {
    showAmountSelect(): ReactNode {
        return (
            <NumberFormat
                id="totalAmount"
                label={this.props.amountLabel}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={3}
                customInput={TextField}
                value={this.props.amountValue}
                onValueChange={this.props.onAmountChange}
                required={this.props.isAmountRequired}
                inputProps={{
                    readOnly: this.props.isAmountReadOnly,
                    min: 1,
                }}
                isAllowed={(values) => {
                    if (values.floatValue === undefined) {
                        return true;
                    }
                    return values.floatValue <= (this.props.maxValue ?? Number.MAX_SAFE_INTEGER);
                }}
                {...this.props.amountFormError}
                InputLabelProps={this.props.fullLabelWidth ? { style: { width: '100%' } } : undefined}
            />
        );
    }

    showUnitSelect(): ReactNode {
        return (
            <UnitSelectComponent
                isRequired={this.props.isAmountRequired}
                selectedUnit={getUnitFromString(this.props.unitValue ?? '')}
                onChange={this.props.onUnitChange}
                isReadOnly={this.props.isUnitReadOnly}
                typeToLimitTo={this.props.typeLimit}
                conversationFactorToLimitTo={this.props.unitLimit?.conversionFactor}
            />
        );
    }

    render(): React.ReactElement {
        return (
            <Grid container item sm={6} spacing={1} direction={'row'} justifyContent={'flex-start'}>
                <Grid item sm={5.1}>
                    {this.showAmountSelect()}
                </Grid>
                <Grid item container sm={5.1} alignItems={'flex-start'}>
                    {this.showUnitSelect()}
                </Grid>
            </Grid>
        );
    }
}

export default AmountSelectComponent;
