import http from 'api/http';
import { AxiosResponse } from 'axios';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Employment, PersonEmployments } from 'model/Employment';
import { PersonEmploymentStore, PersonFavouritesStore, PersonImagesStore, PersonStore } from 'store';
import { ImageInfo, ImageType, PersonPortraits } from 'model/Image';
import { getUuidFromString } from 'util/helpers';
import { Person, PersonCreateWriteView, PersonFavourites, PersonUpdateWriteView } from 'model/Person';
import moment from 'moment';
import ImageService from 'api/ImageService';

class PersonService {
    /**
     * handleUser in PersonServiceUtil should be wrapped around call of this function in case the userExists should be updated
     */
    public me(): Observable<Person | undefined> {
        return http
            .getAxios()
            .get<Person>('persons/me')
            .pipe(
                map((response) => response.data),
                tap((user) => PersonStore.setSelected(user.links.self)),
                tap((user) => PersonStore.setOne(user)),
                tap((user) => PersonEmploymentStore.setSelected(user.links.self)),
                tap((user) => PersonEmploymentStore.loadOne(user.links.self)),
            );
    }

    public createNewUser(personCreateWriteView: PersonCreateWriteView): Observable<Person> {
        return http
            .getAxios()
            .post<Person>('persons', personCreateWriteView)
            .pipe(
                map((value) => value.data),
                tap((_user: Person) => PersonStore.setSelected(_user.links.self)),
                tap((_user) => PersonStore.loadOne(_user.links.self)),
            );
    }

    public getPersonImage(userId: string, imagetype: ImageType): Observable<PersonPortraits> {
        return http
            .getAxios()
            .get<ImageInfo[]>(`images/forperson/${userId}`, {
                params: { imagetype: imagetype },
            })
            .pipe(
                map((value) => value.data),
                map((images) => {
                    images.forEach((image) => {
                        image.uploadedAt = moment(image.uploadedAt);
                        image.validUntil = image.validUntil
                            ? ImageService.stringToDate(image.validUntil as unknown as string)
                            : undefined;
                    });
                    return images;
                }),
                map((images) => new PersonPortraits(userId, images)),
            );
    }

    public setImage(userId: string, image: File, imagetype: ImageType, forcompany: boolean): Observable<ImageInfo[]> {
        const data = new FormData();
        data.append('image', image, image.name);
        return http
            .getAxios()
            .post<ImageInfo[]>('images/' + (forcompany ? 'forcompany' : 'forperson') + '/' + userId, data, {
                params: { imagetype: imagetype },
            })
            .pipe(
                map((value) => value.data),
                map((images) => {
                    images.forEach((image) => {
                        image.uploadedAt = moment(image.uploadedAt);
                        image.validUntil = image.validUntil
                            ? ImageService.stringToDate(image.validUntil as unknown as string)
                            : undefined;
                    });
                    return images;
                }),
                tap((_image: ImageInfo[]) => {
                    PersonImagesStore.setOne(new PersonPortraits(userId, _image));
                }),
            );
    }

    public getPersonEmployments(personRef: string): Observable<PersonEmployments> {
        return http
            .getAxios()
            .get<Employment[]>(personRef + '/employments')
            .pipe(
                map((value) => value.data),
                map((employments) => {
                    return { userRef: personRef, employments: employments };
                }),
            );
    }

    public updatePerson(personRef: string, personUpdateWriteView: PersonUpdateWriteView): Observable<boolean> {
        return http
            .getAxios()
            .put<Person>(personRef, personUpdateWriteView)
            .pipe(
                tap((value) => (value.data ? PersonStore.setOne(value.data) : true)),
                map((response) => response.status < 400),
                catchError((error) => of(error.response.status)),
            );
    }

    public getPerson(personRef: string): Observable<Person> {
        return http
            .getAxios()
            .get<Person>(personRef)
            .pipe(
                map<AxiosResponse<Person>, Person>((user: AxiosResponse<Person>) => user.data),
                tap((user: Person) => PersonStore.setOne(user)),
            );
    }

    public searchPersons(query: string): Observable<Person[]> {
        return http
            .getAxios()
            .get<Person[]>('/persons/search?query=' + query)
            .pipe(map((response) => response.data));
    }

    public deletePerson(personRef: string): Observable<number> {
        return http
            .getAxios()
            .delete<number>(personRef)
            .pipe(
                map((response) => response.data),
                tap(() => PersonStore.removeOneById(personRef)),
                catchError((error) => {
                    return of(error.response.status);
                }),
            );
    }

    getFavouriteCompanies(personRef: string): Observable<PersonFavourites> {
        return http
            .getAxios()
            .get<string[]>(personRef + '/favourites')
            .pipe(map((response) => new PersonFavourites(personRef, response.data)));
    }

    setFavouriteCompany(personRef: string, companyUri: string): Observable<PersonFavourites> {
        return http
            .getAxios()
            .post(
                personRef + '/favourites',
                { companyUri },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .pipe(
                map((response) => new PersonFavourites(personRef, response.data)),
                tap((response) => PersonFavouritesStore.setOne(response)),
            );
    }

    deleteFavouriteCompany(personRef: string, companyId: string): Observable<unknown> {
        return http
            .getAxios()
            .delete(personRef + '/favourites/' + getUuidFromString(companyId))
            .pipe(
                map((response) => response.data),
                tap(() => PersonFavouritesStore.loadOne(personRef)),
            );
    }
}

export default new PersonService();
