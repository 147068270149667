import { Moment } from 'moment';
import { Modify } from 'util/helpers';

export enum SystemMessageType {
    TEST = 'TEST',
    EXPIRING_OFFER = 'EXPIRING_OFFER',
    EXPIRING_OFFERS = 'EXPIRING_OFFERS',
    EXPIRING_REQUEST = 'EXPIRING_REQUEST',
    EXPIRING_REQUESTS = 'EXPIRING_REQUESTS',
    EXPIRING_MEMBERSHIP = 'EXPIRING_MEMBERSHIP',
    EXPIRING_MEMBERSHIP_WITHOUT_INPUT = 'EXPIRING_MEMBERSHIP_WITHOUT_INPUT',
    NEW_COMPANY = 'NEW_COMPANY',
    NEW_PERSON = 'NEW_PERSON',
    EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION',
    ASSOCIATION_PROPOSAL = 'ASSOCIATION_PROPOSAL',
    PRODUCT_PROPOSAL = 'PRODUCT_PROPOSAL',
    MATCHING_OFFER = 'MATCHING_OFFER',
    MATCHING_REQUEST = 'MATCHING_REQUEST',
    INVALID_DATA = 'INVALID_DATA',
    CERTIFICATE_FILE_UPDATED = 'CERTIFICATE_FILE_UPDATED',
    UPDATED_MEMBERSHIP = 'UPDATED_MEMBERSHIP',
    GLOBAL = 'GLOBAL',
    NEW_CONSUMER_IN_REGION = 'NEW_CONSUMER_IN_REGION',
    NEW_COMPANY_IN_REGION = 'NEW_COMPANY_IN_REGION',
    OFFER_AMOUNT_REDUCED = 'OFFER_AMOUNT_REDUCED',
    OFFER_DEACTIVATED_BY_ZERO_AMOUNT = 'OFFER_DEACTIVATED_BY_ZERO_AMOUNT',
    OFFER_DEACTIVATED_BY_MIN_AMOUNT = 'OFFER_DEACTIVATED_BY_MIN_AMOUNT',
}

export interface SystemMessage {
    type: SystemMessageType;
    createdAt: Moment;
    expiresOn?: Moment;
    isDeleted?: boolean;
    systemMessageDisplayType: SystemMessageDisplayType;
    links: SystemMessageLinks;
}

export interface SystemMessageLinks {
    self: string;
    receiverPerson: string;
    receiverCompany: string;
}

// TEST
export type TestSystemMessage = SystemMessage;

// OFFER
export type OfferSystemMessage = Modify<
    SystemMessage,
    {
        amountInBaseUnit?: number;
        levelOfProcessing?: string;
        links: OfferSystemMessageLinks;
    }
>;

export interface OfferSystemMessageLinks extends SystemMessageLinks {
    offer: string;
    order?: string;
}

// EXPIRING_REQUEST
export type ExpiringRequestSystemMessage = Modify<
    SystemMessage,
    {
        links: ExpiringRequestSystemMessageLinks;
    }
>;

export interface ExpiringRequestSystemMessageLinks extends SystemMessageLinks {
    request: string;
}

// EXPIRING_MEMBERSHIP
export type ExpiringMembershipSystemMessage = Modify<
    SystemMessage,
    {
        links: ExpiringMembershipSystemMessageLinks;
    }
>;

export interface ExpiringMembershipSystemMessageLinks extends SystemMessageLinks {
    membership: string;
}

// EXPIRING_OFFERS
export interface ExpiringOffersSystemMessage extends SystemMessage {
    expiringOffersCount: number;
}

// EXPIRING_REQUESTS
export interface ExpiringRequestsSystemMessage extends SystemMessage {
    expiringRequestsCount: number;
}

// NEW_COMPANY
export type NewCompanySystemMessage = Modify<SystemMessage, { links: NewCompanySystemMessageLinks }>;

export interface NewCompanySystemMessageLinks extends SystemMessageLinks {
    company: string;
    person: string;
}

// NEW_PERSON
export type NewPersonSystemMessage = Modify<SystemMessage, { links: NewPersonSystemMessageLinks }>;

export interface NewPersonSystemMessageLinks extends SystemMessageLinks {
    person: string;
}

// ASSOCIATION_PROPOSAL
export interface AssociationProposalSystemMessage
    extends Modify<SystemMessage, { links: AssociationProposalSystemMessageLinks }> {
    text: string;
}

export interface AssociationProposalSystemMessageLinks extends SystemMessageLinks {
    company: string;
    person: string;
}

// PRODUCT_PROPOSAL
export interface ProductProposalSystemMessage
    extends Modify<SystemMessage, { links: ProductProposalSystemMessageLinks }> {
    text: string;
}

export interface ProductProposalSystemMessageLinks extends SystemMessageLinks {
    company: string;
    person: string;
}

// MATCHING_OFFER
export type MatchingOfferSystemMessage = Modify<SystemMessage, { links: MatchingOfferSystemMessageLinks }>;
export interface MatchingOfferSystemMessageLinks extends SystemMessageLinks {
    offer: string; // Found offer
    request: string; // Own request
}

// MATCHING_REQUEST
export type MatchingRequestSystemMessage = Modify<SystemMessage, { links: MatchingRequestSystemMessageLinks }>;
export interface MatchingRequestSystemMessageLinks extends SystemMessageLinks {
    request: string; // Found request
    offer: string; // Own offer
}

// GLOBAL
export class GlobalSystemMessageWriteView {
    constructor(
        public text: string,
        public link: string,
        public systemMessageDisplayType: SystemMessageDisplayType,
    ) {}
}

export type GlobalSystemMessage = Modify<
    SystemMessage,
    {
        text: string;
        links: GlobalSystemMessageLinks;
    }
>;

export enum SystemMessageDisplayType {
    STANDARD = 'STANDARD',
    BANNER = 'BANNER',
}

export interface GlobalSystemMessageLinks extends SystemMessageLinks {
    link: string;
}

// UPDATED MEMBERSHIP
export type UpdatedMembershipSystemMessage = Modify<SystemMessage, { links: UpdatedMembershipSystemMessageLinks }>;

export interface UpdatedMembershipSystemMessageLinks extends SystemMessageLinks {
    person: string;
    company: string;
    membership: string;
}

export type NewCompanyInRegionSystemMessage = Modify<SystemMessage, { links: NewCompanyInRegionSystemMessageLinks }>;

export interface NewCompanyInRegionSystemMessageLinks extends SystemMessageLinks {
    company: string;
}
