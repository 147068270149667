import http from 'api/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CompanyEmployments, Employment, EmploymentWriteView, PersonEmployments } from 'model/Employment';
import {
    CompanyContactsStore,
    CompanyEmploymentsStore,
    CompanyFeatureStore,
    CompanyStore,
    EmploymentStore,
    PersonEmploymentStore,
    PersonStore,
} from 'store';
import { AxiosResponse } from 'axios';
import { getUuidFromString } from 'util/helpers';

class EmploymentService {
    public updateEmployment(
        employmentRef: string,
        employment: EmploymentWriteView,
        companyId: string,
    ): Observable<Employment> {
        return http
            .getAxios()
            .put<Employment>(employmentRef, employment, { headers: { 'Current-Company': companyId } })
            .pipe(
                map((value) => value.data),
                tap((employment) => EmploymentStore.setOne(employment)),
                tap((employment) => {
                    const userRef = PersonStore.getSelected()?.links.self;
                    if (userRef && employment.links.person === userRef) {
                        PersonEmploymentStore.loadOne(userRef);
                    }
                }),
            );
    }

    public getEmployment(employmentRef: string): Observable<Employment> {
        return http
            .getAxios()
            .get<Employment>(employmentRef)
            .pipe(map((value) => value.data));
    }

    terminateEmployment(employmentRef: string, companyId: string): Observable<number> {
        return http
            .getAxios()
            .patch(`employments/${getUuidFromString(employmentRef)}/status/terminated`, {
                headers: { 'Current-Company': companyId },
            })
            .pipe(
                tap(() => EmploymentStore.loadOne(employmentRef)),
                tap(() => CompanyContactsStore.invalidateCache()),
                catchError((error) => {
                    return of(error.response.status);
                }),
            );
    }

    deleteEmployment(employment: Employment, companyId: string): Observable<number> {
        return http
            .getAxios()
            .delete<never>(employment.links.remove, { headers: { 'Current-Company': companyId } })
            .pipe(
                tap((response: AxiosResponse) => {
                    if (response.status >= 400) return;

                    EmploymentStore.removeOne(employment);

                    // Refresh the company's employments.
                    // Removed employees should not be listed in the company employee list.
                    const companyEmployment = CompanyEmploymentsStore.getOne(employment.links.company);
                    if (companyEmployment) {
                        const _employments = new CompanyEmployments(
                            companyEmployment.companyRef,
                            companyEmployment.employees.filter((value: string) => value !== employment.links.self),
                        );
                        CompanyEmploymentsStore.setOne(_employments);
                    }

                    // Refresh company contacts.
                    // Former employees should not be listed as company contacts any more on the company presentation.
                    // TODO: Filter offline, don't reload using network:
                    // TODO: Remove contact for deleted employment individually if there is any, potentially setting a default contact person if only one manager is left.
                    // TODO: Finding the cascading deleted contact could be achieved by returning it along with the deleted employment by the core endpoint.
                    CompanyContactsStore.loadOne(employment.links.company).subscribe();

                    // If the own employment was deleted
                    if (employment.links.person === PersonStore.getSelected()?.links.self) {
                        // Refresh own employments.
                        const personEmployment = PersonEmploymentStore.getOne(employment.links.person);
                        if (personEmployment) {
                            const _personEmployments = new PersonEmployments(
                                personEmployment.userRef,
                                personEmployment.employments.filter(
                                    (value) => value.links.self !== employment.links.self,
                                ),
                            );
                            PersonEmploymentStore.setOne(_personEmployments);

                            const activePersonEmployments = _personEmployments.employments.filter((employment) =>
                                ['MANAGER', 'EMPLOYEE'].includes(employment.status.toString()),
                            );
                            // Select another employment.
                            if (activePersonEmployments.length >= 1) {
                                CompanyStore.setSelected(activePersonEmployments[0].links.company);
                            } else {
                                CompanyStore.setSelected(undefined);
                            }
                        }

                        // Refresh company features.
                        const company = CompanyStore.getSelected();

                        // Get features for selected company
                        // or
                        // get Robinson features by selecting the empty string
                        // (selecting undefined wouldn't get Robinson features).
                        if (company) {
                            CompanyFeatureStore.setSelected(company.links.self);
                        } else {
                            CompanyFeatureStore.setSelected('');
                        }
                    }
                }),
                catchError((error) => {
                    return of(error.response.status);
                }),
            );
    }

    transferResponsibilities(
        employmentRef: string,
        replacementEmploymentRef: string,
        companyId: string,
    ): Observable<number> {
        return http
            .getAxios()
            .put(
                `employments/${getUuidFromString(employmentRef)}/transfer_to/` +
                    `${getUuidFromString(replacementEmploymentRef)}`,
                undefined,
                { headers: { 'Current-Company': companyId } },
            )
            .pipe(
                tap(() => EmploymentStore.removeOneById(employmentRef)),
                map((data) => data.status),
            );
    }

    getEmployees(companyRef: string): Observable<CompanyEmployments> {
        if (companyRef) {
            return http
                .getAxios()
                .get<Employment[]>(companyRef + '/employees')
                .pipe(
                    map((response) => response.data),
                    map(
                        (employments) =>
                            new CompanyEmployments(
                                companyRef,
                                employments.map((employment) => employment.links.self),
                            ),
                    ),
                );
        } else {
            throw new Error('Company has no ref..');
        }
    }
}

export default new EmploymentService();
