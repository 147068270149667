import * as React from 'react';
import { ReactElement } from 'react';
import Avatar from '@mui/material/Avatar';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ComponentBase } from 'resub';
import { Contact } from 'model/Contact';
import { Person } from 'model/Person';
import { ContactsStore, PersonImagesStore, PersonStore } from 'store';
import PersonIcon from '@mui/icons-material/Person';
import { ImageInfo } from 'model/Image';
import EmailComponent from 'components/shared/Popovers/EmailComponent';
import PhoneComponent from 'components/shared/Popovers/PhoneComponent';
import FaxComponent from 'components/shared/Popovers/FaxComponent';
import PublicContactMessageButton from 'components/messaging/PublicContactMessageButton';
import { withTranslation, WithTranslation } from 'react-i18next';

const _styles = () =>
    createStyles({
        avatar: {
            minWidth: 120,
            minHeight: 120,
        },
        avatarSmall: {
            minWidth: 86,
            minHeight: 86,
        },
        boldTypography: {
            fontWeight: 'bold',
        },
    });

interface PublicContactComponentProps extends WithStyles<typeof _styles>, WithTranslation {
    contactRef: string;
    isAbbreviated?: boolean;
    messageTopic?: string;
    offerRef?: string;
    requestRef?: string;
}

interface PublicContactComponentState {
    contact: Contact;
    person: Person;
    personPortrait?: ImageInfo | null;
}

class PublicContactComponent extends ComponentBase<PublicContactComponentProps, PublicContactComponentState> {
    protected _buildState(): Partial<PublicContactComponentState> | undefined {
        const newState: Partial<PublicContactComponentState> = {
            contact: ContactsStore.getOne(this.props.contactRef),
        };
        if (newState.contact) {
            newState.person = PersonStore.getOne(newState.contact.links.person);
            const userId = newState.contact.links.person.split('/').pop();
            if (userId) {
                newState.personPortrait = PersonImagesStore.getOne(userId)?.getLatestImage();
            }
        }
        return newState;
    }

    getProfileImage(): ReactElement {
        if (this.state.personPortrait) {
            return (
                <Avatar
                    className={this.props.isAbbreviated ? this.props.classes.avatarSmall : this.props.classes.avatar}
                    src={this.state.personPortrait.source}
                />
            );
        }

        return (
            <Avatar className={this.props.isAbbreviated ? this.props.classes.avatarSmall : this.props.classes.avatar}>
                <PersonIcon className={this.props.classes.avatar} />
            </Avatar>
        );
    }

    getResponsibilityArea(): ReactElement | undefined {
        if (this.state.contact.isSales && this.state.contact.isProcurement) {
            return (
                <Typography className={this.props.classes.boldTypography}>
                    {/*TODO: hardcoded translation ("&")*/}
                    {this.props.t('company:procurement') + ' & ' + this.props.t('company:sales')}
                </Typography>
            );
        } else if (this.state.contact.isSales) {
            return (
                <Typography className={this.props.classes.boldTypography}>{this.props.t('company:sales')}</Typography>
            );
        } else if (this.state.contact.isProcurement) {
            return (
                <Typography className={this.props.classes.boldTypography}>
                    {this.props.t('company:procurement')}
                </Typography>
            );
        }
    }

    getResponsibility(): ReactElement | undefined {
        if (!this.state.contact.responsibility) return undefined;

        return <Typography>{this.state.contact.responsibility}</Typography>;
    }

    getInfoButtons() {
        if (!this.state.contact.phone && !this.state.contact.fax && !this.state.contact.email) return undefined;

        return (
            <Grid container item spacing={0.5}>
                {this.state.contact.phone && (
                    <Grid item xs={this.props.isAbbreviated ? undefined : 12}>
                        <PhoneComponent
                            calledFrom={'PublicContactComponent'}
                            popover={this.props.isAbbreviated}
                            phone={this.state.contact.phone}
                        />
                    </Grid>
                )}
                {this.state.contact.fax && (
                    <Grid item xs={this.props.isAbbreviated ? undefined : 12}>
                        <FaxComponent popover={this.props.isAbbreviated} fax={this.state.contact.fax} />
                    </Grid>
                )}
                {this.state.contact.email && (
                    <Grid item xs={this.props.isAbbreviated ? undefined : 12}>
                        <EmailComponent
                            calledFrom={'PublicContactComponent'}
                            popover={this.props.isAbbreviated}
                            email={this.state.contact.email}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    render(): React.ReactElement | null {
        if (!this.state.contact || !this.state.person) return null;

        return (
            <>
                <Grid container>
                    <Grid item xs={4}>
                        {this.getProfileImage()}
                    </Grid>
                    <Grid container item xs={8} spacing={1} direction={'column'}>
                        <Grid item>
                            <Typography className={this.props.classes.boldTypography}>
                                {this.state.person.firstname} {this.state.person.lastname}
                            </Typography>
                            {this.getResponsibilityArea()}
                            {this.getResponsibility()}
                        </Grid>
                        {this.getInfoButtons()}
                        <Grid item>
                            <PublicContactMessageButton
                                calledFrom={'PublicContactComponent'}
                                offerRef={this.props.offerRef}
                                requestRef={this.props.requestRef}
                                messageTopic={this.props.messageTopic}
                                contactRef={this.state.contact.links.self}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withTranslation('company')(withStyles(_styles, { withTheme: true })(PublicContactComponent));
