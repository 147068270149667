import * as React from 'react';
import { ComponentBase } from 'resub';
import { IconButton, Theme } from '@mui/material';
import { WithStyles, WithTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { GraduatedPrice } from 'model/Offer';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DeleteIcon } from 'components/shared/Icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormValidationHelper, withFormValidationHelper, WithFormValidationHelper } from 'util/FormErrors';
import NumberFormat from 'react-number-format';

const styles = (theme: Theme) =>
    createStyles({
        deleteButton: {
            padding: theme.spacing(0.65),
        },
        textField: {
            maxWidth: 120,
        },
        textFieldPrice: {
            margin: theme.spacing(0),
        },
        errorField: {
            maxWidth: 120,
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.error.main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.error.main,
                },
            },
        },
        errorTypography: {
            color: theme.palette.error.main,
            fontSize: 13,
            fontWeight: '500',
            paddingBottom: theme.spacing(1),
        },
    });

interface GraduatedPricesProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    selectedUnit: string;
    graduatedPrice: GraduatedPrice;
    readOnly: boolean;
    isDuplicate: boolean;
    onRemove?: (gp: GraduatedPrice) => void;
    onUpdate?: (gp: GraduatedPrice) => void;
}

interface GraduatedPricesState extends WithFormValidationHelper {
    graduatedP: GraduatedPrice;
    key: string;
}

class GraduatedPricesComponent extends ComponentBase<GraduatedPricesProperties, GraduatedPricesState> {
    @withFormValidationHelper<GraduatedPricesComponent>('validate')
    protected _buildState(props: GraduatedPricesProperties): Partial<GraduatedPricesState> | undefined {
        return { graduatedP: props.graduatedPrice };
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.setState({
            // TODO: pseudo uuid
            key: Math.random().toFixed(length),
        });
    }

    validate(
        formHelper: FormValidationHelper,
        prevState: GraduatedPricesState,
        props: GraduatedPricesProperties,
    ): void {
        if (prevState.graduatedP.price < 0) {
            formHelper.setError('price', props.t('offer:errorInvalidPrice'));
        }
        if (prevState.graduatedP.amount <= 0) {
            formHelper.setError('amount', props.t('offer:errorInvalidAmount'));
        }
    }

    render(): React.ReactElement {
        const { t } = this.props;
        return (
            <Grid container direction={'column'}>
                <Grid container item spacing={1} style={{ margin: 0, padding: 0, alignItems: 'top' }}>
                    <Grid item md={2.5} style={{ paddingLeft: 0 }}>
                        <NumberFormat
                            key={this.state.key + 'price'}
                            id="price"
                            label={this.props.t('offer:pricePerUnit')}
                            className={this.props.classes.textFieldPrice}
                            value={this.state.graduatedP.price.toLocaleString('de-DE', {
                                style: 'currency',
                                currency: 'EUR',
                            })}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            customInput={TextField}
                            onValueChange={(numbers): void => {
                                if (numbers.floatValue !== undefined) {
                                    this.setState(
                                        (state: GraduatedPricesState) => {
                                            const gp = state.graduatedP;
                                            gp.price = numbers.floatValue!;
                                            return { graduatedP: gp };
                                        },
                                        () => this.props.onUpdate?.(this.state.graduatedP),
                                    );
                                }
                            }}
                            required
                            inputProps={{ readOnly: this.props.readOnly, min: 0 }}
                            {...this.state.formValidationHelper.getFormError('price')}
                        />
                    </Grid>
                    <Grid item md={2.5}>
                        <Typography>
                            {' '}
                            € {this.props.t('offer:per') + ' ' + this.props.t('units:' + this.props.selectedUnit)}{' '}
                            {this.props.t('offer:from')}
                        </Typography>
                    </Grid>
                    <Grid item md={2}>
                        <NumberFormat
                            key={this.state.key + 'amount'}
                            id="amount"
                            label={this.props.t('offer:amount')}
                            className={
                                this.props.isDuplicate ? this.props.classes.errorField : this.props.classes.textField
                            }
                            value={this.state.graduatedP.amount}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={1}
                            customInput={TextField}
                            onValueChange={(numbers): void => {
                                if (numbers.floatValue !== undefined) {
                                    this.setState(
                                        (state: GraduatedPricesState) => {
                                            const gp = state.graduatedP;
                                            gp.amount = numbers.floatValue!;
                                            return { graduatedP: gp };
                                        },
                                        () => this.props.onUpdate?.(this.state.graduatedP),
                                    );
                                }
                            }}
                            required
                            inputProps={{ readOnly: this.props.readOnly, min: 0 }}
                            {...this.state.formValidationHelper.getFormError('amount')}
                        />
                    </Grid>
                    <Grid item md={1}>
                        <Typography>{t(`shortunits:${this.props.selectedUnit}`)}</Typography>
                    </Grid>
                    <Grid item md={1}>
                        {!this.props.readOnly && (
                            <IconButton
                                onClick={(): void =>
                                    this.props.onRemove ? this.props.onRemove(this.props.graduatedPrice) : undefined
                                }
                                className={this.props.classes.deleteButton}
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid container item spacing={1} style={{ margin: 0, padding: 0, alignItems: 'top' }}>
                        {this.props.isDuplicate ? (
                            <Typography className={this.props.classes.errorTypography}>
                                {this.props.t('offer:errorDuplicateAmountGraduatedPrice')}
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation(['offer', 'units', 'shortunits'])(
    withStyles(styles, { withTheme: true })(GraduatedPricesComponent),
);
